const messages = {
    en: {
        translations: {

            literals: {
                name: 'Name',
                message: 'Message',
                email:         'Email',
                password:      'Password',
                language:      'Language',
                policyPrivacy: 'Privacy Policy',
                policyCookie:  'Cookie Policy',
                subject: 'Information',
                country: 'United Kingdom',
                address: 'Address',
            },

            header: {
                app2:  'DIVORCE PLANNING',
                app3:  'PERSONAL DIARY',
                app4:  'LIFE PLAN',
                app5:  'PASSWORD WALLET',
                book1: 'GUIDE TO DIVORCE FOR MEN',
            },

            trailer: {
                title1: 'Quick Links',
                title2: 'Help and support',
                title3: 'Other',
                option1: 'Terms of use',
                option2: 'Privacy Policy',
                option3: 'Contact',
                option4: 'Applications',
                option5: 'Home',
            },
            
            buttons: {
               ok: 'Ok',
               cancel: 'Cancel',
               login: 'LOGIN',
               send: 'Send',
               clear: 'Clear'
            },

            titles: {
                cardTitle: 'Software development',
                cardDescription: 'Code your Dreams',
                contact: 'Get in touch',
                product: 'Apps',
            },
            
            products: {
                app1Title: 'DIVORCE GUIDE',
                app1SubTitle: 'The app describes the main elements of a divorce and its consequences.',
                app1Description: 'It was created to teach, help and alert people who intend to undergo a divorce or who are already going through this procedure, but do not know what to expect or how to act at this time in their lives.',

                app2Title: 'DIVORCE PLANNING',
                app2SubTitle: 'Application to plan and monitor a divorce process.',
                app2Description: 'It was developed with the aim of providing software capable of helping its users plan, carry out, measure and monitor a divorce. It uses some of the concepts described in the book "The art of war" in its design. It also uses the PDCA cycle methodologies and SWOT analysis in its operating structure.',
            
                app3Title: 'PERSONAL DIARY',
                app3SubTitle: 'Personal diary to record dreams, ideas and situations that occurred in your life.',
                app3Description: "The application was developed to be a simple and easy-to-use digital diary, where a person's important experiences and events can be recorded.",
            
                app4Title: 'PLAN LIFE',
                app4SubTitle: 'Create a life plan to achieve the desired lifestyle.',
                app4Description: 'The app is a tool designed to help you create a life plan. A life plan is a personal journey document that helps you describe your goals, priorities, make decisions, and move toward the lifestyle you envision.',
                
                app5Title: 'PASSWORD WALLET',
                app5SubTitle: 'Manage and Protect Your Passwords and Documents in One Place.',
                app5Description: 'Keep all your documents and passwords safe with Password Wallet, your easily accessible personal vault. With just one master password, you can securely store and manage your important credentials and documents. Ideal for those seeking convenience and security, Password Wallet is the perfect solution for keeping your private information at your fingertips, yet away from prying eyes. Try it today and experience the peace of mind of having your digital life well protected.',
            },

           menu: {
               about:    'About',
               contact: 'Contact',
               application: 'Applications',
               login: 'Login'
           },

           sections: {
               title: 'Development of software',
               subtitle: 'Turn your projects into reality',
               about: 'Development of software for web, cell phones, tablets and other devices.',
               contact: 'Have some question?',
               message: 'Leave a message.',
               sent: 'Message sent.'
           },

           guide: {
              title: 'Marriages and Divorces',
              description1: 'The numbers presented when we talk about marriages or divorces are mathematical statistics that in many cases do not reflect the true reality of a city, state or nation. These numbers are invalid, under- or over-estimated, exceeded, or mixed with other values that produce unrealistic results. However, even with these deficiencies, we can use these statistical values to get a sense of what may be happening in human relationships.',
              crudeRate: 'Crude Rate',
              description2: 'Defines the frequency at which a disease or condition occurs in a population in a given period of time.',
              formula1: 'Crude Rate = (Quantity ÷ Population) x Constant',
              note: 'Constant is a multiple of 10 that defines the number of elements in the group.',
              crudeDivorceRate: 'Crude Divorce Rate',
              description3: 'It is the number of divorces for each group of 1000 people in a population. For example, if a city has a population of 10,000 and 50 couples who divorced within a year, we would have a crude divorce rate of 5 divorces for every group of 1000 people.',
              formula2: 'Crude Divorce Rate = (Number of divorces ÷ Population) x 1000',
              description4: 'The crude divorce rate can provide an overview of marriage, but it shows a false value because it uses people who cannot marry in its outcome. For example, children and singles are part of the calculation. In a place with a large number of children or single adults, the crude divorce rate may seem low. In a place with few children and single adults, the crude divorce rate can seem high.',
              divorceRate: 'Divorce Rate in the World',
              description5: 'Based on 2020-2021 data from the United Nations Department of Economic and Social Affairs Statistics Division, countries with the highest annual divorce rates per 1,000 people are as follows:',
              noteList: 'Data is most recent available per country. 2018-China; 2019-Cuba; all others 2020-21.​',
              description6: 'Based on 2017-2020 data, countries with the lowest annual divorce rates are as follows:',
              numberDivorces: 'Number of Divorces',
              numberWeddings: 'Number of Marriages',
              divorceUSA: 'Divorce in the U.S.A.',
              description7: 'According to the US National Center for Health Statistics, approximately 4-5 million people marry each year in the US and approximately 42-53% of these marriages end in divorce. See current numbers:',
              description8: 'Divorce happens all over the world. The statistics obtained in the US can serve as a basis for understanding how it works in other countries.',
              divorceCause: 'Divorce and its causes',
              description9: 'The reasons that lead people to stay together are well known: love, the desire to be close, the dream of a common future. But, after all what drives couples to seek separation? A study conducted by the University of Denver revealed the main reasons, described below:',
              divorceCause01: 'Lack of commitment - 75%',
              divorceCause02: 'Infidelity or extramarital affairs - 59.6%', 
              divorceCause03: 'Too much conflict and arguing - 57.7%',
              divorceCause04: 'Getting married too young - 45.1%',
              divorceCause05: 'Financial problems - 36.1%',
              divorceCause06: 'Substance abuse - 34.6%',
              divorceCause07: 'Domestic violence - 23.5%',
              divorceCause08: 'Health problems - 18.2%',
              divorceCause09: 'Lack of support from family - 17.3%',
              divorceCause10: 'Little or no premarital education and religious differences - 13.3%', 
              generalDivorce: 'General Divorce Statistics',
              general01: '8 years is the average for a first marriage to end in divorce.​',
              general02: '20% of marriages end within the first 5 years.​',
              general03: '32% of marriages end within the first 10 years.',
              general04: '30 years is the average age of people getting divorced for the first time.​',
              general05: '69% of women initiate divorce. This number shoots up to 90% for women with university degrees.​',
              general06: 'Women who had six or more sexual partners before marriage are three times less likely to be in a stable marriage.',
              general07: 'Watching and talking about romance movies reduces divorce rates by 13%.',
              general08: 'Being close friends with a divorcee increases the likelihood of getting divorced by 147%.',
              general09: 'Having strong religious beliefs decreases the likelihood of divorce by 14%.​',
              general10: 'Having a below average IQ increases the likelihood of divorce by 50%.​',
              general11: '67% of Americans believe divorce is better for children than witnessing the problems of a troubled marriage.',
              general12: 'Well-married parents decrease their children\'s likelihood of divorce by 14%.​',
              general13: 'In the US, research indicates that 22 million parents are targets of parental alienation behaviors and 3.8 million children are alienated by one of the spouses.',
              general14: 'Only two countries consider divorce illegal: the Philippines and Vatican City.',

              menu1: 'ABOUT',
              menu2: 'FEATURES',
              menu3: 'DOWNLOAD',
              menu4: 'CONTACT',
              menu5: 'EXTRA',
              menu6: 'AUTHOR',
              menu7: 'ACQUIRE',
              menu1Desc1: 'This application reveals the main elements of a divorce and its consequences.',
              menu1Desc2: 'He was raised to teach, help and alert people who intend to have a divorce or who are already going through this procedure, but we do not know what to expect at this moment in their lives.',
              menu1Desc3: 'The divorce represents the end of a union. ',
              menu1Desc4: 'Many people believe that this is just a bureaucratic process, where your wishes and rights will be respected, with freedom being restored, producing a happier life for everyone, especially your applicants.',
              menu1Desc5: 'This happens in films, books and novels, but in the real world it is not like that. ',
              menu1Desc6: 'Friendly divorces tend to work as expected, but when there is some disagreement between those involved, the scene changes completely and can produce terrible and unexpected consequences for everyone.',
              menu1Desc7: 'The application provides the information necessary to help its users carry out the procedures that will eliminate or mitigate their losses, pain and suffering resulting from non-consensual divorces.',
              menu2Desc: 'Designed to be simple, useful and easy to use. The functions can be accessed quickly and intuitively, eliminating the need for any type of manual or descriptive instructions.',
              menu2Title1: 'Multiple Languages',
              menu2Title1Desc: "To facilitate understanding and use, the application works with several languages that can be selected according to the user's preference.",
              menu2Title2: 'Guide',
              menu2Title2Desc: 'It describes the main elements contained in a divorce process and their respective variations and consequences.',
              menu2Title3: 'Statistics',
              menu2Title3Desc: 'Shows the main statistics related to divorce. Divorce and marriage counter.',
              menu2Title4: 'Incidents',
              menu2Title4Desc: 'It allows you to record incidents that occurred during and after the wedding with the generation of a report.',
              menu2Title5: 'Meter',
              menu2Title5Desc: 'It allows you to measure the consequences of divorce based on the data provided.',

              menu2Title6Desc: 'The application provides access to this book that can be acquired and read directly on your cell phone.',
              menu2Title6bDesc: 'The first chapter is available for review.',
              menu2Title6cDesc: 'Click on the image above for more information.',
              menu3Desc: 'The app is available for Android devices.',
              menu4Title: 'HAVE SOME QUESTION?',
              menu4Desc: 'Leave us a message.',
              menu5Desc1: 'Below are some applications that may be of interest to you.',
              bookDesc1: 'You can access the book and use it directly on your cell phone, using the application below:',
              bookDesc2: 'If you prefer, the book is also available on AMAZON.',
              bookDesc3: "DON'T EXPECT COMMON SENSE OR COMPASSION",
              bookDesc4: "YOU WILL HAVE TO SPEND MONEY TO PROVE THAT YOU DON'T HAVE MONEY",
              bookDesc5: "TODAY, MEN ARE ANNIHILATED IN DIVORCE",
              bookDesc6: "MEN RARELY HAVE THE RIGHT TO CUSTODY OF THEIR CHILDREN",
              bookDesc7: "DOWNLOAD THE APP AND RECEIVE A SUPER DISCOUNT",
           },

           planning: {
              menu1: 'ABOUT',
              menu2: 'FEATURES',
              menu3: 'DOWNLOAD',
              menu4: 'CONTACT',
              menu5: 'EXTRA',
              
              menu1Desc1:  'Application to plan and monitor a divorce process.',
              menu1Desc2:  'It was developed with the aim of providing software capable of helping its users plan, carry out, measure and monitor a divorce.',
              menu1Desc3:  'It uses some of the concepts described in the book "The Art of War" in its design.',
              menu1Desc4:  'It also uses the PDCA cycle and SWOT analysis methodologies in its operating structure.',
              menu1Desc5:  'Planning consists of a series of preparations to carry out a task in order to achieve an objective.',
              menu1Desc6:  'Many people do not know or do not want to carry out any type of planning.',
              menu1Desc7:  'They prefer to believe that everything will happen quickly and harmoniously with their rights being respected and demands being met.',
              menu1Desc8:  'Unfortunately this will not happen most of the time.',
              menu1Desc9:  'Relying on luck or the common sense of the judicial system or the skill of the lawyer selected is no guarantee of success or a happy ending.',
              menu1Desc10: 'Every divorce must be planned before its execution, even if there is consensus between the couple.',
              menu1Desc11: 'No one knows how others will react in situations of conflict or separation.',
              menu1Desc12: 'People that we carry in our arms and in which we invest our lives, can, without any explanation, become terrible and destructive enemies, where anything can be a reason for confrontation or dispute.',
              menu1Desc13: 'Divorce represents the end of one cycle and the beginning of another.',
              menu1Desc14: 'The application cannot guarantee any victory, but it can at least reduce your personal and financial losses so that a phase of harmony and happiness has a better chance of occurring in your new life.',
              
              menu2Desc: 'It was designed to be simple, useful and easy to use. The available resources can help your users to have a better understanding of the presented subject.',
              menu2Title1: 'Multiple Languages',
              menu2Title1Desc: "To facilitate understanding and use, the application works with several languages that can be selected according to the user's preference.",
              menu2Title2: 'SWOT Analysis',
              menu2Title2Desc: 'Perform SWOT analysis of your data. It is a methodology used to identify the strengths, opportunities, weaknesses and threats of a specific project to make the necessary adjustments that can guarantee the success of the enterprise.',
              menu2Title3: 'Financial Statement',
              menu2Title3Desc: 'It is possible to verify the result and cost of the planning and its execution.',
              menu2Title4: 'Scenario',
              menu2Title4Desc: 'Defines the divorce scenario based on the data entered about the marriage.',
              menu2Title5: 'Security',
              menu2Title5Desc: 'Keeps your data and planning safe from unauthorized access. Access via PIN or fingerprint.',
              menu3Desc: 'The Divorce Planning app is available for Android devices.',
              menu4Title: 'HAVE SOME QUESTION?',
              menu4Desc: 'Leave us a message.',
              menu5Desc: 'Below are some applications that may be of interest to you.',

              menu1ExtraTitle1: 'PDCA cycle',
              menu1ExtraTitle2: 'SWOT analysis',
              menu1ExtraTitle3: 'The art of war',
              menu1ExtraDesc1:  'The acronym PDCA means in English: Plan, Do, Check, Act. It is a methodology used to improve processes and solve problems. Its purpose is to assist the execution of an established strategy.',
              menu1ExtraDesc2:  'It is a methodology used to identify the strengths, opportunities, weaknesses and threats of a specific project to make the necessary adjustments that can guarantee the success of the enterprise.',
              menu1ExtraDesc3:  'It is a military treatise written during the 4th century BC. by the strategist known as "Sun Tzu". The treatise is composed of thirteen chapters, each one addressing an aspect of war strategy, in order to compose an overview of all the events and strategies that must be addressed in a rational combat.',
           },

           diary: {
            menu1: 'ABOUT',
            menu2: 'FEATURES',
            menu3: 'DOWNLOAD',
            menu4: 'CONTACT',
            menu5: 'EXTRA',
            menu1Desc1: 'Personal diary to record dreams, ideas and situations that have occurred in your life.',
            menu1Desc2: "The application was developed to be a simple and easy-to-use digital diary, where a person's important experiences and events can be recorded.",
            menu1Desc3: 'In addition, these records can be enriched with images, sounds and videos.',
            menu1Desc4: 'The functions can be accessed quickly and intuitively, without any type of manual or descriptive instructions.',
            menu1Desc5: 'You can create records of what happened in your life and the lessons you learned.',
            menu1Desc6: 'How many times would you like to remember exactly when a certain event occurred? With a diary you will always have these records at your disposal.',
            menu1Desc7: 'Writing a diary is a powerful method for putting your thoughts and feelings in order. You can register them without any type of restriction and consult them whenever you need.',
            menu1Desc8: 'Writing in a personal diary also stimulates our creativity. ',
            menu1Desc9: 'The very act of writing can spark inspiration, leading to new perspectives and innovative solutions. ',
            menu1Desc10: 'A personal journal offers a safe haven for self-expression. ',
            menu1Desc11: 'It allows us to freely articulate our thoughts, fears, dreams and deepest desires without judgment or inhibition',
            menu1Desc12: 'Personal journals offer a sacred space for self-reflection, self-expression, and personal growth. ',
            menu1Desc13: 'They serve as a testament to our unique journey, providing solace, clarity and inspiration. ',
            menu1Desc14: 'By embracing the power of personal journals, we can unlock the true essence of self-reflection and embark on a transformative path toward self-discovery and fulfillment.',
            menu1Desc15: 'In short, this application is a tool that allows you to record the most important thing there is.',
            menu1Desc16: 'Your life.',
            menu2Desc: 'Designed to be simple, useful and easy to use. The functions can be accessed quickly and intuitively, eliminating the need for any type of manual or descriptive instructions.',
            menu2Title1: 'Multiple Languages',
            menu2Title1Desc: "To facilitate understanding and use, the application works with several languages that can be selected according to the user's preference.",
            menu2Title2: 'Daily',
            menu2Title2Desc: 'Record the most important moments and events in your life to remember them in the future. Simply and quickly add any desired note with the corresponding title, description, date and time of its occurrence.',
            menu2Title3: 'Attachments',
            menu2Title3Desc: 'Add attachments to the records made. They can be of the type: image, sound or video. It is also possible to place descriptions in each of them that facilitate their understanding during the presentation stage.',
            menu2Title4: 'To look for',
            menu2Title4Desc: 'It is possible to search and locate records made by the user in a simple and effective way through their title or date of occurrence.',
            menu2Title5: 'Security',
            menu2Title5Desc: 'Keeps your data safe from unauthorized access. Access via PIN or fingerprint.',
            menu3Desc: 'The app is available for Android devices.',
            menu4Title: 'HAVE SOME QUESTION?',
            menu4Desc: 'Leave us a message.',
            menu5Desc: 'Below are some applications that may be of interest to you.',
         },

         lifeplan: {
            menu1: 'ABOUT',
            menu2: 'FEATURES',
            menu3: 'DOWNLOAD',
            menu4: 'CONTACT',
            menu5: 'EXTRA',

            menu1Desc: 'The app is a tool designed to help you create a life plan.',
            menu1bDesc: 'A life plan is a personal journey document that helps you describe your goals, priorities, make decisions, and move towards the lifestyle you envision.',
            menu1cDesc: 'It serves as a guiding document for navigating through life, providing clarity, direction, and purpose.',
            menu1dDesc: 'It helps you identify your passions, values and priorities, allowing you to focus your time, energy and resources on what really matters.',
            menu1eDesc: "A life plan allows you to set meaningful and achievable goals. Whether it's deciding on a career path, pursuing your education or making lifestyle choices, it will help you make decisions that contribute to your overall well-being and fulfillment.",
            menu1fDesc: 'A life plan reminds individuals of their purpose and motivates them to persevere through challenging times.',
            menu1gDesc: 'It encourages people to evaluate themselves and continually improve.',
            menu1hDesc: 'Promotes reflection on strengths, weaknesses and areas for growth.',
            menu1iDesc: 'Encourages individuals to prioritize self-care, relationships, and leisure activities alongside their professional and financial goals.',
            menu1jDesc: 'In short, a life plan serves as a powerful tool for people to define their purpose, set goals, and navigate life with intention and passion.',
            menu2Desc:  'Designed to be simple, useful and user-friendly. The functions can be accessed quickly and intuitively, without any type of manual or descriptive instructions.',
            menu2Title1: 'Goals',
            menu2Title1Desc: 'Allows you to describe and list the objectives to be achieved and separate them by category.',
            menu2Title2: 'Tasks',
            menu2Title2Desc: 'Allows you to describe and list the tasks that must be performed in order for the objectives to be achieved.',
            menu2Title3: 'Wish Board',
            menu2Title3Desc: 'Allows you to create a table of desires to visualize and stimulate the unconscious.',
            menu2Title4: 'Motivational',
            menu2Title4Desc: "Provides motivational phrases that inspire users to face life's adversities with more courage and achieve their goals and objectives.",
            menu2Title5: 'Security',
            menu2Title5Desc: 'Keeps data protected from unauthorized access. Access via PIN or fingerprint.',
            menu3Desc: 'The application is available for Android devices.',
            menu4Title: 'DO YOU HAVE ANY QUESTIONS?',
            menu4Desc: 'Leave us a message.',
            menu5Desc: 'Below are other applications that may be of interest to you.',
        },

         passwordwallet: {
            menu1: 'ABOUT',
            menu2: 'FEATURES',
            menu3: 'DOWNLOAD',
            menu4: 'CONTACT',
            menu5: 'EXTRA',

            menu1Desc: 'Password Wallet is an essential app for those seeking security and organization in one place. Designed to facilitate the management of your most sensitive information, this app securely stores documents, passwords, and confidential data using a single master password. Through an intuitive interface and a sleek design, you can quickly access everything you need without sacrificing security.',
            menu1bDesc:'With this App, forgetting a password or losing important documents are worries of the past. The app uses advanced encryption to ensure your information is protected against unauthorized access, while offering a smooth and pleasant user experience. Additionally, you can categorize your information into custom folders, making organization and quick access easy when needed.',
            menu1cDesc:'Ideal for professionals who need to keep a vast amount of data at their fingertips, or for anyone who values digital security, Password Wallet is more than just a simple password manager. It is a digital safe that accompanies you at all times, ensuring peace of mind and greater productivity. Simplify your digital life with Password Wallet, your reliable ally in the digital world.',
            menu2Desc:  'Designed to be simple, useful and user-friendly. The functions can be accessed quickly and intuitively, without any type of manual or descriptive instructions.',
            menu2Title1: 'Presentation',
            menu2Title1Desc: 'All your data is organized and displayed in a clear and accessible way. Each entry is represented by a visually distinct data card, which includes a title and a specific category. This approach allows users to quickly view relevant information, making it easier to identify and access the necessary data with efficiency and style.',
            menu2Title2: 'View',
            menu2Title2Desc: 'With an intuitive interface, the App allows you to expand each record with a simple touch. When you select a data card, its full contents are displayed, including additional stored details. This functionality is essential for efficient management of your information, allowing you to edit, update or delete data as necessary.',
            menu2Title3: 'Attachments',
            menu2Title3Desc: 'The attachment functionality in the App enriches the management of your information. You can easily add images to any record. Each attachment can be accompanied by a detailed description, providing additional context and ensuring that all relevant aspects of your information are accessible and well-organized.',
            menu2Title4: 'Organization',
            menu2Title4Desc: 'Organize your records more efficiently with categorization functionality. You can create custom categories to classify your data, making it easier to organize and quickly retrieve specific information. The category search allows you to quickly filter and find the records you need, optimizing your workflow and increasing your productivity.',
            menu2Title5: 'Security',
            menu2Title5Desc: 'The application is protected by a personal password, ensuring that only you have access to your confidential information. This master password is the key to unlocking all your data, which is encrypted with cutting-edge technology to provide additional protection against unauthorized access. Access via PIN or fingerprint.',
            menu3Desc: 'The application is available for Android devices.',
            menu4Title: 'DO YOU HAVE ANY QUESTIONS?',
            menu4Desc: 'Leave us a message.',
            menu5Desc: 'Below are other applications that may be of interest to you.',
        },
           msgs: {
               code1:  'INVALID EMAIL',
               code2:  'INVALID PASSWORD',
               code3:  'EMAIL NOT FOUND',            
               code4:  'INVALID NAME',  
               code5:  'INVALID DESCRIPTION',  
               code22: 'DUPLICATE NAME IS NOT ALLOWED', 
               code27: 'THERE ARE NO PREVIOUS PAGES', 
               code28: 'THERE ARE NO PAGES', 
               code29: 'NO SEARCH FOUND',  
               code99: 'ERROR UNDEFINED'  
           }
        }
    }
}

export { messages };