import styled from 'styled-components';

export const Container = styled.div`
    background:  #FFFFFF;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 0;
`

export const Title = styled.div`
    background: none;
    border: none;
    color: #5083A0;
    text-align: center;
    text-decoration: none;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
    font-size: 1.8rem;
    margin-top: 30px;
`

export const ContainerData = styled.div`
    background: none;
    width: 100%;
    margin-top:50px;

    display: flex;
    flex-direction: row;
    align-items: center;
`

export const CenterData = styled.div`
    background: none;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const SideLeft = styled.div`
    background: none;
    width: 25%;

    @media screen and (max-width: 960px) {
        width: 5%;
    }
`

export const SideRight = styled.div`
    background: none;
    width: 25%;

    @media screen and (max-width: 960px) {
        width: 5%;
    }
`

export const Description = styled.div`
    background: none;
    width: 100%;

    text-align: left;
    text-decoration: none;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    color: #696969;
`

export const SubTitle = styled.div`
    background: none;
    width: 100%;
    border: none;
    text-align: left;
    text-decoration: none;
    font-weight: bold;
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
    color: #696969;
`

export const Formula = styled.div`
    background: none;
    width: 100%;
 
    text-align: left;
    text-decoration: none;
    //font-weight: bold;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    color: #5083A0;
`

export const Note = styled.div`
    background: none;
    width: 100%;
 
    text-align: left;
    text-decoration: none;
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
    color: #696969;
    fontStyle: 'italic'
`

export const List = styled.div`
    background: none;
    width: 100%;
 
    text-align: left;
    text-decoration: none;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    color: #5083A0;
`

export const CounterType = styled.div`
    background: none;
    width: 100%;
    display: flex;
    flex-direction: row;
`

export const CounterFrameEmpty = styled.div`
    background: none;
    width: 15%;

    @media screen and (max-width: 960px) {
        width: 1%;
    }
`

export const CounterFrame = styled.div`
    background: #5083A0;
    width: 70%;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justifyContent: center;

    text-align: center;
    text-decoration: none;
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
    color: #FFFFFF;
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 5px;

    
    span[id=title_counter] {
        font-size: 18px;
        font-weight: bold;

        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        margin-top:5px;
        }

    span {
        font-size: 32px;
        font-weight: bold;

        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        }

    @media screen and (max-width: 960px) {
        width: 100%;
    }
`
