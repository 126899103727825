
import React, { useEffect, useState } from 'react';   
import * as S                         from './styles';
import util                           from '../../../../Utilities/Util';

function Attach() {
    
    const [access, setAccess] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)

        setAccess( util.checkGuideAccess() );
    }, [])

    return (
        <S.Container>
        { ( access )
            ?
            <>
            <S.Form> 

            <S.FormTitle>
                <span>ANEXO</span><br></br>
            </S.FormTitle>

            <S.FormText>
                <span>
                <h2>Aviso importante</h2>
                <br></br>
                <i>SOB NENHUMA CIRSCUNSTÂNCIA PRODUZA QUALQUER ATO DE VIOLENCIA FÍSICA OU PSICOLÓGICA CONTRA A OUTRA PARTE OU QUALQUER PESSOA ENVOLVIDA, ANTES OU DEPOIS DO DIVÓRCIO.<br></br><br></br> 
                NÃO PROCURE POR VINGANÇA OU QUALQUER TIPO DE RETALIAÇÃO. <br></br><br></br>
                MESMO QUE A JUSTIÇA SEJA DEFICIENTE, UTILIZE SEMPRE OS MEIOS LEGAIS E JUDICIAIS PARA SOLUCIONAR SEUS PROBLEMAS.<br></br><br></br> 
                PREFIRA SER FELIZ E RECONSTRUIR SUA VIDA.</i>
                <br></br><br></br>
                <br></br><br></br> 
                <h2>Ações e atitudes</h2>
                <br></br>
                Antes, durante e depois do divórcio mantenha um <b><u>diário</u></b> descrevendo todos os acontecimentos, com data, hora, nomes, detalhes, áudio, vídeo, fotos ou qualquer documento pertinente. <br></br><br></br>
                Isso será muito importante no futuro, principalmente como defesa de algum processo que possa receber.<br></br><br></br>
                Sempre digitalize todos os seus documentos, notas fiscais, emails e etc. Proteja tudo com senha e mantenha em lugar seguro e inacessível.<br></br><br></br>
                A tinta de suas notas fiscais e/ou outros documentos desaparece com o tempo, em poucos dias em alguns casos, por isso é importante digitalizá-los.<br></br><br></br>
                Tenha sempre uma cópia de tudo atualizado e armazenado na <i>"Nuvem"</i> de algum provedor.<br></br><br></br>
                Proteja seu computador e celular com senha, se possível, reformate ou troque de equipamento.<br></br><br></br>
                Modifique a senha de todas as suas contas de email, de preferência crie novas e apague as anteriores.<br></br><br></br>
                Encerre qualquer conta ou serviço na internet que possa difamar seu caráter, como: sites de encontros, de pornográfia, de apostas e etc.<br></br><br></br>
                Tome cuidado, dados falsos e comprometedores podem estar sendo gravados em seu equipamento sem o seu conhecimento com o propósito de desmoralizá-lo ou incriminá-lo no futuro.<br></br><br></br>
                Contas em sites de encontros ou em qualquer outro local que possa prejudicá-lo, podem estar sendo abertas em seu nome, por isso é importante ter novas contas de email. <br></br><br></br>
                Contudo, isso não impede a realização desse tipo de operação, por isso fique atento aos emails recebidos.<br></br><br></br>
                Seu telefone fixo, celular, computador ou qualquer outro equipamento de comunicação podem estar grampeados. <br></br><br></br>
                Discuta e troque informações sigilosas pessoalmente, através de telefones públicos ou qualquer outro meio seguro e confiável.<br></br><br></br>
                Caso descubra que seu equipamento está grampeado, utilize esse fato ao seu favor, passando informações falsas para confundir e/ou prejudicar o outro lado.<br></br><br></br>
                Cuidado com as palavras ditas ou escritas, elas também podem ser utilizadas contra você. <br></br><br></br>
                Por exemplo, você pode falar para alguém que "se acontecer determinada coisa isso irá acabar com a outra parte". <br></br><br></br>
                <u>Acabar</u> aqui tem o sentido de deixar a parte sem ação ou derrotada. <br></br><br></br>
                Contudo, essa conversa pode ter sido registrada e o advogado da parte pode utilizar esse fato para deturpar suas palavras e alegar que algo criminoso está sendo planejado por você. <br></br><br></br>
                Obtenha o máximo de informações possível sobre as ações e intenções da outra parte, se necessário contrate uma agência de investigação para orientá-lo e realizar esse serviço. <br></br><br></br>
                Atenção com as pessoas que entram e saem de sua residência, principalmente <u>empregadas domésticas</u>, amigos e parentes da parte e etc. <br></br><br></br>
                Essas pessoas serão usadas pela parte como testemunhas em futuros processos. <br></br><br></br>
                Irão efetuar falso testemunho sem qualquer constrangimento e relatar qualquer coisa que forem orientadas para dizer.<br></br><br></br>
                Faça o mesmo, coloque amigos e parentes dentro da sua residência, tenha o maior número de relacionamentos sociais possível com pessoas de confiança, e registre tudo em áudio, vídeo e redes sociais. <br></br><br></br>
                Fique atento contra atitudes ou ações dirigidas a você com o objetivo de tirá-lo da seriedade.<br></br><br></br>
                Principalmente após o divórcio, mantenha uma distância mínima de <u>um metro</u> entre você e a outra parte, <u>sempre</u> com as suas mãos para trás. <br></br><br></br>
                Nunca reaja ou tente segurar a parte caso seja atacado, empurrado ou ameaçado, se necessário saia correndo do local e peça ajuda. Caso contrário, qualquer coisa será um ato de violência produzido por você. <br></br><br></br>
                <u>Não caia nessa armadilha</u>. <br></br><br></br>
                Em termos financeiros, na medida do possível, elimine todas as dívidas, empréstimos, financiamentos, contas e boletos bancários em seu nome. <br></br><br></br>
                Também é importante realizar o divórcio com o menor número de bens a partilhar. 
                Por exemplo, se tiver um veículo financiado em seu nome, venda ele e quite a dívida. 
                Caso contrário, quando o divórcio for iniciado terá que continuar pagando o financiamento, e não poderá vendê-lo até o Juiz decidir seu destino.<br></br><br></br>
                Não corra atrás de um novo relacionamento apenas para demonstrar que tem alguém, que está livre e feliz.<br></br><br></br> 
                Deixe as coisas acontecerem de forma natural, e no tempo certo. 
                Não seja dominado pelo seu ego, orgulho ou opiniões alheias.<br></br><br></br> 
                
                <u><b>IMPORTANTE</b></u>:<br></br><br></br>
                Nunca faça alienação parental em seus filhos. Não permita que nenhum parente (avós, Irmãos, tios, primos, etc.), amigos ou pessoas próximas façam isso. Nunca coloque seus filhos contra a outra parte, não fale mal dela nem discuta os motivos do divórcio com eles. 
                Não crie ou comente sobre situações onde a parte possa ser responsável, mesmo que seja verdade. 
                Caso perguntem alguma coisa, diga que irá responder apenas quando eles forem adultos. 
                Em situações onde evolva dinheiro extra, como por exemplo: viagens, passeios, brinquedos e etc., nunca responsabilize a outra parte nem seja o vilão da história, caso sua realização não seja possível. 
                Fale que irá pensar e discuta a situação com a outra parte. Nenhum de vocês devem se apresentar como herói ou bandido nessa história, mesmo que apenas um seja responsável por sua efetivação ou impedimento. 
                <br></br><br></br>
                Não seja um alienador, não jogue suas magoas e ressentimentos em seus filhos. 
                Caso a outra parte não faça nada disso, reúna o máximo de provas testemunhais e documentais contra ela e ajuíze um processo de alienação parental para proteger seus filhos contra esse tipo de violência. 

                <br></br><br></br>
                <h2>Mensagem Final</h2>
                <br></br>
                <i>SE O DIVÓRCIO JÁ FOR UMA REALIDADE EM SUA VIDA. 
                CASO TENHA PERDIDO A GUARGA DE SEUS FILHOS, ESTEJA DESAMPARADO, TRISTE E HUMILHADO. 
                A SUA LUTA NÃO ACABA AQUI, HAVERÁ OUTRAS BATALHAS ONDE SERÁ O VENCEDOR. 
                O TEMPO IRÁ CORRIGIR TUDO ISSO, MAS DEPENDERÁ DE SUA ATITUDE E POSTURA PERANTE À VIDA.
                RECONSTRUA ELA POR MAIS DIFÍCIL QUE SEJA. 
                SE MAIS PROBLEMAS APARECEREM, CONTORNE-OS E SIGA EM FRENTE.
                CAMINHOS SERÃO ABERTOS, MAS VOCÊ NÃO IRÁ VÊ-LOS SE FICAR MERGULHADO NO VITIMISMO, ORGULHO, TRISTEZA E NO SEU PASSADO.
                TRANSFORME O POUCO TEMPO QUE AINDA TIVER COM SEUS FILHOS EM ALGO ESPECIAL, NA COISA MAIS IMPORTANTE DO MUNDO.
                NÃO QUEIRA SEDUZI-LOS COM COISAS MATERIAIS.
                SEJA UM PAI PRESENTE, O MELHOR AMIGO DELES.
                QUERENDO OU NÃO, O FUTURO VIRÁ.
                DEPENDE DE VOCÊ SE ELE SERÁ MARAVILHOSO OU NÃO.<br></br><br></br>
                TENHA FÉ, CORAGEM E DETERMINAÇÃO.<br></br><br></br>
                BOA SORTE!</i>
                <br></br><br></br>
                </span>
            </S.FormText>

            </S.Form>
            </>
            :
            <>
            <br></br>
            <br></br>
            <h3>&nbsp;</h3>
            <br></br>
            </>    }  
        </S.Container>
    )
}

export default Attach;