import React, {useState}                from 'react';
import * as S                           from './styles';
import guide22                          from '../Guide/Images/guide22.png'
import util                             from '../../../Utilities/Util'

function Element8() {

    const [english,    setEnglish]    = useState(false);
    const [spanish,    setSpanish]    = useState(false);
    const [portuguese, setPortuguese] = useState(false);

    React.useEffect(() => {
  
      const result = util.getLanguage();

      setEnglish   (result.english);
      setSpanish   (result.spanish);
      setPortuguese(result.portuguese);

      }, []);
 
    return (
        <S.Container>

            <S.FormImage><img src={guide22} alt=""></img></S.FormImage>

            { (portuguese) &&
            <S.ContainerData>
                <S.SideLeft>&nbsp;</S.SideLeft>
                <S.CenterData>
                    <S.Title>FUTURO</S.Title>
                    <S.FormDesc>
<span>O futuro de um casamento é o divórcio. <br></br><br></br>Atualmente, a cada três casamentos um termina dessa forma.<br></br><br></br>
Existem muitas razões que levam as pessoas a efetuar esse procedimento, mas independente dos reais motivos, todas desejam uma vida melhor e mais feliz após sua realização.<br></br><br></br>
Infelizmente, não é possível saber se as aspirações, planos e sonhos planejados irão se concretizar ao final dessa operação.<br></br><br></br>
Apenas uma coisa é certa, tudo irá depender do tipo de divórcio adotado.<br></br><br></br>
Qualquer que seja o tipo escolhido, irá finalizar uma fase da vida. Novas coisas irão surgir, poderão ser boas ou ruins, mas mudanças certamente irão ocorrer.<br></br><br></br>
Contudo, o maior obstáculo será os problemas, prejuízos e sequelas deixadas por divórcios, principalmente pelos não consensuais, que geram rancor e hostilidade permanente entre os envolvidos, liquida os patrimônios, cria encargos e obrigações financeiras, separa e afasta os filhos de seus genitores, e etc.<br></br><br></br>
<br></br><b>Estatísticas</b><br></br><br></br>
50%&nbsp;dos casamentos terminam em divórcio.<br></br>
70%&nbsp;dos pedidos de divórcio são feitos pelas mulheres.<br></br>
90%&nbsp;da guarda dos filhos são das mulheres.<br></br>
80%&nbsp;dos filhos sofrem alienação parental.<br></br><br></br><br></br>
Os homens serão os maiores prejudicados nos processos de divórcio desse tipo, conforme demonstram as estatísticas.<br></br><br></br>
Independente do sexo, a parte que retêm a guarda dos filhos, habitualmente utiliza esse privilégio para  <b><u>jubjugar e tiranizar a outra parte, bem como se proteger de futuros processos judiciais contra ela</u></b>.<br></br><br></br>
Além disso, também passam a ter <i>responsabilidade legal</i> sobre os filhos menores ou incapazes, determinando onde irão morar, estudar e tudo mais, sem a participação, opinião ou interferência da parte perdedora.<br></br><br></br>
Reverter a guarda é praticamente impossível, a parte derrotada não terá mais nenhum direito sobre eles, apenas o dever e a obrigação de sustentá-los através de uma pensão estabelecida, sendo severamente penalizado caso não o faça por qualquer razão.<br></br><br></br>
Por outro lado, qualquer abuso ou delito cometido pela parte vencedora, jamais poderá ser denunciado nem registrado, porque não existe nenhum órgão público com esse objetivo.<br></br><br></br>
Tudo deverá ser feito através de <b><u>processos judiciais</u></b> com apresentação de provas <b><u>documentais e testemunhais</u></b>, os quais irão demandar <b><u>muito tempo e dinheiro</u></b> para sua execução, produzindo resultados sem efeito prático na maioria dos casos.<br></br><br></br>
Infelizmente, divórcios litigiosos com filhos tendem a ser dolorosos, pois eles prejudicam as crianças e a parte perdedora da guarda, a qual não poderá participar diretamente da criação de seus filhos e terá sua convivência prejudicada e extremamente reduzida.<br></br><br></br>
Os filhos também <b><u>não</u></b> serão educados integralmente pela parte responsável pela guarda, pois ela não terá tempo suficiente para executar essa operação, pelo fato da antiga estrutura familiar ter sido desfeita, fazendo com que seus esforços sejam direcionados para a construção e manutenção de um novo lar.<br></br><br></br>
Logo, os filhos serão educados pelos avós, babás, empregadas domésticas, escolas, televisão, internet ou por qualquer meio ou pessoa que possa preencher esse espaço e função.</span><br></br><br></br>
<b>Ações e atitudes</b><br></br><br></br>
Antes, durante e depois do divórcio mantenha um diário descrevendo todos os acontecimentos, com data, hora, nomes, detalhes, áudio, vídeo, fotos ou qualquer documento pertinente. <br></br><br></br>
Isso será muito importante no futuro, principalmente como defesa de algum processo que possa receber.<br></br><br></br>
Nunca faça alienação parental em seus filhos. Não permita que nenhum parente, amigos ou pessoas próximas façam isso.<br></br><br></br><br></br><br></br>
<b>SOB NENHUMA CIRSCUNSTÂNCIA PRODUZA QUALQUER ATO DE VIOLENCIA FÍSICA OU PSICOLÓGICA CONTRA A OUTRA PARTE OU QUALQUER PESSOA ENVOLVIDA, ANTES OU DEPOIS DO DIVÓRCIO.<br></br><br></br>
NÃO PROCURE POR VINGANÇA OU QUALQUER TIPO DE RETALIAÇÃO. <br></br><br></br>
MESMO QUE A JUSTIÇA SEJA DEFICIENTE, UTILIZE SEMPRE OS MEIOS LEGAIS E JUDICIAIS PARA SOLUCIONAR SEUS PROBLEMAS. <br></br><br></br>
PREFIRA SER FELIZ E RECONSTRUIR SUA VIDA.</b>
                    </S.FormDesc>
                    <br></br> <br></br> <br></br>                     
                </S.CenterData>
                <S.SideRight>&nbsp;</S.SideRight>
            </S.ContainerData> 
            }

            { (spanish) &&
            <S.ContainerData>
                <S.SideLeft>&nbsp;</S.SideLeft>
                <S.CenterData>
                     <S.Title>FUTURO</S.Title>
                     <S.FormDesc>
<span>El futuro de un matrimonio es el divorcio. <br></br><br></br>Actualmente, uno de cada tres matrimonios acaba así.<br></br><br></br>
Son muchas las razones que llevan a las personas a realizarse este procedimiento, pero independientemente de las verdaderas razones, todos desean una vida mejor y más feliz después de realizarlo.<br></br><br></br>
Desafortunadamente, no es posible saber si las aspiraciones, planes y sueños planeados se harán realidad al final de esta operación.<br></br><br></br>
Solo una cosa es cierta, todo dependerá del tipo de divorcio que se adopte.<br></br><br></br>
Cualquiera que sea el tipo que elijas, terminará una fase de vida. Surgirán cosas nuevas, pueden ser buenas o malas, pero seguro que se producirán cambios.<br></br><br></br>
Sin embargo, el mayor obstáculo serán los problemas, pérdidas y consecuencias que dejan los divorcios, principalmente los no consensuales, que generan resentimiento y hostilidad permanente entre los involucrados, liquidan los bienes, crean cargas y obligaciones financieras, apartan y separan a los hijos de sus padres, etc.<br></br><br></br>
<br></br><b>Estadísticas</b><br></br><br></br>
El 50%&nbsp;de los matrimonios acaban en divorcio.<br></br>
El 70%&nbsp;de las solicitudes de divorcio son presentadas por mujeres.<br></br>
El 90%&nbsp;de la custodia de los hijos son mujeres.<br></br>
El 80%&nbsp;de los niños sufren alienación parental.<br></br><br></br><br></br>
Los hombres serán los mayores perdedores en los procesos de divorcio de este tipo, como muestran las estadísticas.<br></br><br></br>
Independientemente del sexo, la parte que retiene la custodia de los hijos utiliza habitualmente este privilegio para <b><u>juzgar y tiranizar a la otra parte, así como protegerse de futuros procesos judiciales en su contra</u></b>. <br></br><br></br>
Además, también tienen <i>responsabilidad legal</i> sobre sus hijos menores o incapaces, determinando dónde vivirán, estudiarán y todo lo demás, sin la participación, opinión o injerencia de la parte perdedora.<br></br><br></br>
Revertir la patria potestad es prácticamente imposible, la parte vencida ya no tendrá ningún derecho sobre ellos, sólo el deber y la obligación de alimentarlos a través de una pensión establecida, siendo severamente sancionado si no lo hace por cualquier causa.<br></br><br></br>
Por otro lado, cualquier abuso o delito cometido por la parte ganadora nunca podrá ser denunciado ni registrado, porque no existe ningún organismo público para tal fin.<br></br><br></br>
Todo deberá realizarse a través de <b><u>procedimiento judicial</u></b> con la presentación de prueba documental y testimonial, que exigirá demasiado tiempo y dinero para su ejecución, produciendo resultados sin efectos prácticos en la mayoría de los casos.<br></br><br></br>
Desafortunadamente, los divorcios contenciosos con hijos suelen ser dolorosos, pues perjudican a los hijos y a la parte perdedora, quienes no podrán participar directamente en la crianza de sus hijos y verán perjudicada y sumamente reducida su convivencia.<br></br><br></br>
Los niños tampoco <b><u>no</u></b> serán completamente educados por la parte responsable de la custodia, ya que no tendrá tiempo suficiente para realizar esta operación, debido a que la antigua familia estructura se ha deshecho, provocando que sus esfuerzos se dirijan a la construcción y mantenimiento de una nueva vivienda.<br></br><br></br>
Pronto, los niños serán educados por los abuelos, niñeras, mucamas, escuelas, televisión, internet o por cualquier medio o persona que pueda llenar este espacio y función.<br></br></span><br></br><br></br>
<b>Acciones y actitudes</b><br></br><br></br>
Antes, durante y después del divorcio, mantenga un diario que describa todos los eventos, con fecha, hora, nombres, detalles, audio, video, fotos o cualquier otro documento relevante.<br></br><br></br>
Esto será muy importante en el futuro, especialmente como defensa contra cualquier demanda que pueda recibir.<br></br><br></br>
Nunca hagas la alienación parental sobre tus hijos. No permitas que ningún familiar, amigos o personas cercanas hagan esto.<br></br><br></br><br></br><br></br>
<b>BAJO NINGUNA CIRCUNSTANCIA PRODUCIR NINGÚN ACTO DE VIOLENCIA FÍSICA O PSICOLÓGICA CONTRA LA OTRA PARTE O CUALQUIER PERSONA INVOLUCRADA, ANTES O DESPUÉS DEL DIVORCIO.<br></br><br></br>
NO BUSQUE VENGANZA NI NINGÚN TIPO DE REPRESALIAS.<br></br><br></br>
AUNQUE LA JUSTICIA SEA DEFICIENTE, UTILICE SIEMPRE LOS MEDIOS LEGALES Y JUDICIALES PARA SOLUCIONAR SUS PROBLEMAS.<br></br><br></br>
PREFIERE SER FELIZ Y RECONSTRUIR TU VIDA.</b>
                     </S.FormDesc>
                     <br></br> <br></br> <br></br>
                </S.CenterData>
                <S.SideRight>&nbsp;</S.SideRight>
            </S.ContainerData> 
            }           

            { (english) &&
            <S.ContainerData>
                <S.SideLeft>&nbsp;</S.SideLeft>
                <S.CenterData>
                    <S.Title>FUTURE</S.Title>
                    <S.FormDesc>
<span>The future of a marriage is divorce. <br></br><br></br>Currently every three marriages one will end this way.<br></br><br></br>
There are many reasons that lead people to perform this procedure, but regardless of the real reasons everyone wants a better and happier life after performing it.<br></br><br></br>
Unfortunately it is not possible to know if the planned aspirations, plans and dreams will come true at the end of this operation.<br></br><br></br>
Only one thing is certain, everything will depend on the type of divorce adopted.<br></br><br></br>
Whichever type you choose it will end a life phase. <br></br><br></br>
New things will emerge they may be good or bad, but changes will certainly occur.<br></br><br></br>
However, the biggest obstacle will be the problems, losses and consequences left by divorces, mainly by non-consensual ones, which generate resentment and permanent hostility between those involved, liquidate the assets, create financial burdens and obligations, separate the children from their parents and etc.<br></br><br></br>
<br></br><b>Statistics</b><br></br><br></br>
50%&nbsp;of marriages end in divorce.<br></br>
70%&nbsp;of divorce applications are made by women.<br></br>
90%&nbsp;of the custody of the children are women.<br></br>
80%&nbsp;of the children suffer parental alienation.<br></br><br></br><br></br>
Men will be the biggest losers in divorce proceedings of this type as statistics show.<br></br><br></br>
Regardless of sex, the party who retains custody of the children habitually uses this privilege to <b><u>judgment and tyrannize the other party, as well as protect themselves from future legal proceedings against them</u></b>. <br></br><br></br>
In addition, they also have <i>legal responsibility</i> over their minor or incapable children, determining where they will live, study and everything else, without the participation, opinion or interference of the losing party.<br></br><br></br>
Reversing custody is practically impossible the defeated party will no longer have any rights over them only the duty and obligation to support them through an established pension, being severely penalized if they do not do it for any reason.<br></br><br></br>
On the other hand, any abuse or crime committed by the winning party can never be denounced or registered because there is no public body for that purpose.<br></br><br></br>
Everything must be done through <b><u>judicial proceedings</u></b> with the presentation of documentary and testimonial evidence which will demand too much time and money for its execution producing results without practical effect in most cases.<br></br><br></br>
Unfortunately, contentious divorces with children tend to be painful as they harm the children and the losing party who will not be able to participate directly in the raising of their children and will have their coexistence impaired and extremely reduced.<br></br><br></br>
The children will also <b><u>not</u></b> be fully educated by the party responsible for the custody as she will not have enough time to perform this operation due to the fact that the old family structure has been undone causing that your efforts be directed towards the construction and maintenance of a new home.<br></br><br></br>
They will be educated by grandparents, nannies, maids, schools, television, internet or by any means or person that can fill this space and function.<br></br></span><br></br><br></br>
<b>Actions and attitudes:</b><br></br><br></br>
Before, during and after the divorce keep a diary describing all events, with date, time, names, details, audio, video, photos or any other relevant documents.<br></br><br></br>
This will be very important in the future, especially as a defense against any lawsuits you may receive.<br></br><br></br>
Never do parental alienation on your children. Do not allow any relative, friends or close people to do this.<br></br><br></br><br></br><br></br>
<b>UNDER NO CIRCUMSTANCES PRODUCE ANY ACT OF PHYSICAL OR PSYCHOLOGICAL VIOLENCE AGAINST THE OTHER PARTY OR ANY PERSON INVOLVED, BEFORE OR AFTER THE DIVORCE.<br></br><br></br>
DO NOT SEEK VENGEANCE OR ANY KIND OF RETALIATION.<br></br><br></br>
EVEN IF JUSTICE IS DEFICIENT, ALWAYS USE LEGAL AND JUDICIAL MEANS TO SOLVE YOUR PROBLEMS.<br></br><br></br>
PREFER TO BE HAPPY AND REBUILD YOUR LIFE.</b>
                    </S.FormDesc>
                    <br></br> <br></br> <br></br>
            </S.CenterData>
            <S.SideRight>&nbsp;</S.SideRight>
        </S.ContainerData> 
            }
        </S.Container>
    )
}

export default Element8;