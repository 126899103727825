import React   from 'react'
//import * as S  from './styles';

const htmlEn = (props) => (
  <>
             <br></br><br></br>
             KODASWARE created this website.<br></br>
             This service is provided at no charge and is intended for use as is.<br></br>
             This page is used to inform visitors of our policies with the collection, use and disclosure of
             information about our services.<br></br>
             If you choose to use our service, you consent to the collection and use of information in connection with this
             policy. <br></br>
             The personal information we collect is used to provide and improve the service. <br></br>
             We will not use or share your information with
             any person, except as described in this Privacy Policy.<br></br><br></br>
             <p><strong>Collection and Use of Information</strong></p> <br></br>
             Currently, the site does not collect, store or transmit any personal or financial information.<br></br>
             <div>
             The site uses third-party services that may collect information used to identify you.<br></br>
             Link to used third party service providers privacy policy
             via the app:<br></br><br></br>
             <ul>
             <li><a href="https://www.google.com/policies/privacy/" target="_blank" rel="noopener noreferrer">Google Play Services</a></li>
             <li><a href="https://support.google.com/admob/answer/6128543?hl=en" target="_blank" rel="noopener noreferrer">AdMob</a></li>
             </ul>
             </div>
             <br></br> <br></br>

             <p><strong>Cookies</strong></p>
             Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. <br></br>
             These are sent to your browser from the websites you visit and are stored in your device's internal memory.<br></br>
     
             This service does not explicitly use these “cookies”. <br></br>
             However, the application may use third-party code and libraries that use “cookies” to collect information and improve its services.<br></br>
             You have the option to accept or decline these cookies.<br></br>
             If you choose to refuse our cookies, you may not be able to use some parts of this service.<br></br> <br></br>
      
             <p><strong>Security</strong></p>
             We value your trust in providing us with your personal information.<br></br>
             Therefore, we are striving to commercially use
             acceptable means of protecting it. <br></br>
             Please remember that no method of transmission over the internet, or method of electronic transmission
             storage is 100% secure and reliable.<br></br>
             Therefore, we cannot guarantee its absolute security.<br></br> <br></br>

             <p><strong>Links to other websites</strong></p>
             This service may contain links to other websites.<br></br>
             If you click on a third-party link, you will be directed to that website. <br></br>
             Remember that these external sites are not operated by us.<br></br>
             Therefore, we strongly recommend that you review the Privacy Policy of these sites. <br></br>
             We assume no responsibility for the content, privacy policies or practices of any third party websites or
             services. <br></br><br></br>

             <p><strong>Changes to this privacy policy</strong></p>
             We may update our Privacy Policy from time to time. <br></br>
             Therefore, you are advised to review this page periodically for any changes.<br></br>
             We will notify you of any changes by posting the new Privacy Policy on this page.<br></br>
             This policy takes effect from 01-Sep-2022<br></br><br></br>
             <p><strong>Contact us</strong></p>
             If you have questions or suggestions about our
             Privacy Policy, please feel free to contact us at info@kodasware.com.
  </>
)
export default htmlEn