
import React, { useEffect, useState } from 'react';   
import * as S       from './styles';
import guide28      from '../../../../view/DivorceGuide/Guide/Images/guide28.png';
import formula1     from '../../../../view/DivorceGuide/Guide/Images/formula1.png';
import formula2     from '../../../../view/DivorceGuide/Guide/Images/formula2.png';
import util         from '../../../../Utilities/Util';

function GuidePart5() {
    
    const [access, setAccess] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)
        setAccess( util.checkGuideAccess() );
    }, [])

    return (
        <S.Container>
        { ( access )
            ?
            <>
            <S.Form> 

            <S.FormTitle>
                <span>PARTE5</span><br></br><br></br><br></br><span>A justiça na Prática</span><br></br><br></br>
            </S.FormTitle>

            <S.FormText>
                <span>

                Os textos jurídicos são verdadeiras obras primas literárias, capazes de enaltecer os valores humanos em sua busca pela verdade e justiça. <br></br><br></br>
                Na prática, a história é bem diferente. As leis são injustas, severas e incoerentes. Quando são administradas ou utilizadas por pessoas sem moral ou caráter, as leis são altamente destrutivas e colaboram ainda mais para o aumento da criminalidade e dos desajustes sociais existentes.  <br></br><br></br>
                No mundo real, as famílias compartilham os momentos felizes e infelizes. Quando as condições de vida se tornam difíceis ou precárias, elas costumam enfrentar esses períodos juntas. Elas cortam gastos, modificam seu padrão de vida e fazem sacrifícios para superar as dificuldades e seguir em frente. <br></br><br></br>
                No divórcio esse mecanismo e união são rompidos, e a realidade é modificada à favor dos vencedores dessa disputa. Eles passam a viver em universo paralelo, no mundo dos <i>"unicórnios"</i>, onde a lógica e o bom senso funcionam de forma diferente da realidade conhecida. <br></br><br></br>
                Os perdedores passam a ser eternos fornecedores ou <i>"escravos modernos"</i>, sempre em dívida com seus proprietários. Estar desempregado ou não ter dinheiro será uma situação inaceitável. <br></br><br></br>
                Se faltarem com suas obrigações financeiras serão punidos e destruídos até que os recursos devidos <i>"magicamente"</i> apareçam. <br></br><br></br>
                
                <br></br><br></br><br></br>
                <h2>Praticando</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide28} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                <b>Cálculo da Pensão</b><br></br><br></br>
                Existem duas modalidades de base para cálculo da pensão alimentícia, pelo rendimento bruto ou pelo rendimento líquido. <br></br><br></br>
                Quando o valor recai sobre o primeiro tipo, ele é determinado simplesmente aplicando-se o percentual definido sobre o valor bruto informado. <br></br><br></br>
                Contudo, se recair sobre o valor líquido, torna-se mais trabalhoso apurar o valor da pensão alimentícia, pois será necessário utilizar fórmulas de cálculo específicas. <br></br><br></br>
                Uma para se apurar o valor da pensão e outra para obter o valor total do IRRF que será descontado. Lembrando que o rendimento líquido é o resultado do bruto deduzido todos os descontos legais, tais como: INSS, IRRF, Contribuições: Sindical, Assistencial, Confederativa, e outros determinados pela categoria do funcionário. 
                
                <br></br><br></br>
                <S.FormTable><img src={formula1} alt=""></img></S.FormTable>

                <br></br><br></br><br></br>
                <b>Caso 1</b><br></br>
                Pensão de 33% pelo rendimento Bruto
                <br></br><br></br><br></br>

                <table border="1" width="100%">
                <tbody>
                <tr>
                <td bgcolor='#EAEAEA' id="cabec">Descrição<br></br>&nbsp;</td>
                <td bgcolor='#EAEAEA' id="cabec">Alíquota<br></br>&nbsp;</td>
                <td bgcolor='#EAEAEA' id="cabec">Proventos<br></br>&nbsp;</td>
                <td bgcolor='#EAEAEA' id="cabec">Desconto<br></br>&nbsp;</td>
                </tr>    
                <tr>
                <td ><b>Salário Bruto</b></td>
                <td >&nbsp;</td>
                <td id="detail">5.500,00</td>
                <td >&nbsp;</td>
                </tr>    
                <tr>
                <td ><b>INSS</b></td>
                <td id="detail">14%</td>
                <td >&nbsp;</td>
                <td id="detail">770,00</td>
                </tr>    
                <tr>
                <td ><b>IRFF</b></td>
                <td id="detail">27.5%</td>
                <td >&nbsp;</td>
                <td id="detail">643,14</td>
                </tr>    
                <tr>
                <td ><b>Pensão</b></td>
                <td >&nbsp;</td>
                <td >&nbsp;</td>
                <td id="detail">1.815,00</td>
                </tr>    
                </tbody>
                </table>
                <br></br><br></br>

                <table border="1" width="100%">
                <tbody>
                <tr>
                <td width="50%" id="total">Salário Líquido<br></br>2.271,86</td>
                <td width="50%" id="total">Descontos<br></br>3.228,14<br></br>58,69%</td>
                </tr>    
                </tbody>
                </table>
                <br></br><br></br>
                
                <b>Cálculo sobre o salário bruto:</b><br></br><br></br>
                <center><span>
                 Valor da Pensão = Salário Bruto * Percentual da Pensão<br></br>
                 Valor INSS = Salário Bruto * Alíquota do INSS<br></br> 
                 Valor IRRF = (Salário Bruto * Alíquota do IRRF) - Dedução do IRRF<br></br>
                 </span></center> 

                <br></br><br></br>
                <b>Caso 2</b><br></br>
                Pensão de 33% pelo rendimento Líquido
                <br></br><br></br><br></br>

                <table border="1" width="100%">
                <tbody>
                <tr>
                <td bgcolor='#EAEAEA' id="cabec">Descrição<br></br>&nbsp;</td>
                <td bgcolor='#EAEAEA' id="cabec">Alíquota<br></br>&nbsp;</td>
                <td bgcolor='#EAEAEA' id="cabec">Proventos<br></br>&nbsp;</td>
                <td bgcolor='#EAEAEA' id="cabec">Desconto<br></br>&nbsp;</td>
                </tr>    
                <tr>
                <td ><b>Salário Bruto</b></td>
                <td >&nbsp;</td>
                <td id="detail">5.500,00</td>
                <td >&nbsp;</td>
                </tr>    
                <tr>
                <td ><b>INSS</b></td>
                <td id="detail">14%</td>
                <td >&nbsp;</td>
                <td id="detail">770,00</td>
                </tr>    
                <tr>
                <td ><b>IRFF</b></td>
                <td id="detail">27,5%</td>
                <td >&nbsp;</td>
                <td id="detail">75,57</td>
                </tr>    
                <tr>
                <td ><b>Pensão</b></td>
                <td >&nbsp;</td>
                <td >&nbsp;</td>
                <td id="detail">1.671,26</td>
                </tr>    
                </tbody>
                </table>
                <br></br><br></br>

                <table border="1" width="100%">
                <tbody>
                <tr>
                <td width="50%" id="total">Salário Líquido<br></br>2.983,17</td>
                <td width="50%" id="total">Descontos<br></br>2.516,83<br></br>45,76%</td>
                </tr>    
                </tbody>
                </table>
                
                <br></br><br></br>
                <S.FormChat>
                <table border="0" width="100%">
                <tbody>
                <tr>
                <td id="formula"><b>Fórmula de Cálculo</b><br></br><br></br>
                É necessário utilizar um sistema de duas equações com duas incógnitas:<br></br><br></br>
                PENSAO = ( BRUTO - INSS - IRRF ) x PERCENTUAL DA PENSAO<br></br><br></br>
                BASE = BRUTO - INSS - PENSAO - ( qtde dependentes x DEDUCAODEPENDENTE )<br></br><br></br>
                IRRF = BASE x ALIQUOTA - DEDUCAO<br></br><br></br><br></br>
                <b>PENSAO = ( BRUTO - INSS - (( BRUTO - INSS - PENSAO - ( qtde dependentes x DEDUCAODEPENDENTE )) x ALIQUOTA ) - DEDUCAO ) x PERCENTAL DA PENSAO</b><br></br><br></br><br></br>
                (*) alíquota, dedução e valor de dedução por dependente obtidos da tabela de IRRF 
                </td>
                </tr>    
                </tbody>
                </table>
                <span>Figura 7 - Cálculo sobre Salário Líquido</span></S.FormChat>
                <br></br><br></br>

                Quando se aplica sobre o rendimento bruto, o alimentado (quem recebe) não é afetado com relação aos impostos. <br></br><br></br>
                Diferente das famílias normais que sofrem com as taxas e impostos existentes, devendo sempre se adaptar e cortar gastos toda vez que algum deles é modificado ou criado pelo governo. <br></br><br></br>
                Nessa situação o alimentante é privilegiado, ele fica isento e protegido das distorções e injustiças que ocorrem na sociedade.<br></br><br></br>
                Quando o alimentante não possuir carteira assinada, o valor cobrado será baseado em salários mínimos. <br></br><br></br>
                O alimentante sempre terá que pagar o valor definido, independentemente de sua saúde, situação trabalhista ou social.
                <br></br><br></br><br></br>

                <b>Pensão maior que o rendimento</b><br></br><br></br>
                Quando o alimentante procura um advogado para realizar sua defesa, muitas vezes irá escutar que o valor da pensão será no máximo de 30% de seus rendimentos. <br></br><br></br>
                Isso não é verdade. <br></br><br></br>É apenas um percentual de referência. Quem decide o valor e o critério de pagamento é o Juiz, que poderá decretar valores inferiores ou bem superiores a esse percentual. <br></br><br></br>
                Mesmo a pessoa estando empregada, ele poderá decretar que o valor à pagar será em salários mínimos. <br></br><br></br>
                Ele seguirá o critério que desejar, mas normalmente irá se basear no que a parte reclamante deseja. 
                <br></br><br></br><br></br>

                <b>Exemplo</b> :<br></br>
                Casamento realizado em comunhão parcial de bens.<br></br>
                O homem é o proprietário do imóvel onde reside com sua esposa e filhos.<br></br>
                O imóvel foi adquirido por ele antes do casamento.<br></br>
                A esposa solicitou o divórcio judicial e uma ação de separação de corpos.<br></br>
                Ele foi expulso de seu imóvel e do convívio com os filhos.<br></br>
                As dívidas de cartão de crédito ou empréstimos bancários realizados por ele antes da separação não foram consideradas pela justiça.<br></br>
                O juiz decretou o pagamento de uma pensão de 33% sobre o seu salário bruto.
                <br></br><br></br>

                <table border="1" width="100%">
                <tbody>
                <tr>
                <td width="70%"><b>Salário bruto</b></td>
                <td width="30%" id="detail">5.500,00</td>
                </tr>    
                <tr>
                <td ><b>Pensão de 33% do bruto</b></td>
                <td id="detail">1.815,00</td>
                </tr>    
                <tr>
                <td ><b>Salário líquido calculado</b></td>
                <td id="detail">2.271,86</td>
                </tr>  
                </tbody>  
                </table>

                <br></br><br></br>
                Sendo proprietário do imóvel terá que continuar pagando as despesas correspondentes, como: IPTU, condomínio e outros.<br></br><br></br>
                Terá que procurar outro local para morar, possivelmente sendo obrigado a pagar aluguel.<br></br><br></br>
                Também terá que continuar pagando os empréstimos pendentes adquiridos em seu nome durante o casamento. Dividas que podem ter sido realizadas em benefício da família, como: festa de aniversário, viagens e etc.<br></br><br></br>Seguindo com o exemplo teríamos:
                <br></br><br></br>

                <table border="1" width="100%">
                <tbody>
                <tr>
                <td width="70%"><b>Condomínio Apto</b></td>
                <td width="30%" id="detail">300,00</td>
                </tr>    
                <tr>
                <td ><b>IPTU Apto</b></td>
                <td id="detail">100,00</td>
                </tr>    
                <tr>
                <td ><b>Dívidas anteriores</b></td>
                <td id="detail">550,00</td>
                </tr>    
                <tr>
                <td ><b>Nova habitação</b></td>
                <td id="detail">800,00</td>
                </tr>    
                <tr>
                <td bgcolor='#EAEAEA' ><b>TOTAL</b></td>
                <td bgcolor='#EAEAEA' id="detail"><b>1.750,00</b></td>
                </tr>   
                </tbody> 
                </table>

                <br></br><br></br>
                <table border="1" width="100%">
                <tbody>
                <tr>
                <td width="50%" id="total"><b>Salário Líquido<br></br>521,86</b></td>
                <td width="50%" id="total"><b>Descontos<br></br>4.978,14<br></br>90,51%</b></td>
                </tr>   
                </tbody> 
                </table>

                <br></br><br></br>
                O valor líquido restante deverá ser utilizado para pagar as despesas de transporte, alimentação, água, luz, telefone, vestimenta, diversão e etc. <br></br><br></br>
                Fazendo com que elas sejam <b>superiores a 100%</b> de seu rendimento. <br></br><br></br>
                A parte irá ocupar o imóvel sem ter que pagar qualquer despesa ou aluguel, não tendo prazo para desocupação.<br></br><br></br> 
                O direito de propriedade não será respeitado, a não ser que seja gasto muito tempo e dinheiro para que esse direito seja estabelecido.<br></br><br></br>
                Terá que diminuir sua qualidade de vida e realizar mágica para sobreviver.
                <br></br><br></br>
                O exemplo descreve uma situação corriqueira nos processos de divórcio. <br></br><br></br>
                As despesas apresentadas não são consideradas pela justiça no momento de calcular e decretar o valor da pensão.<br></br><br></br> 
                O exemplo também demonstra que o binômio <b>POSSIBILIDADE x NECESSIDADE</b> é uma falácia, não sendo utilizado no mundo real.<br></br><br></br> 
                A situação e as possibilidades do alimentante não serão consideradas. <br></br><br></br>
                Ele sempre terá que pagar o valor decretado e as despesas existentes, tendo ou não condições de fazê-lo. 
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <b>Trinômio NECESSIDADE x POSSIBILIDADE x PROPORCIONALIDADE</b>
                <br></br> 
                <br></br>
                É mais uma fórmula elaborada pela justiça para determinar o valor da pensão que será paga pelo alimentante. O fator de proporcionalidade foi adicionado nesse processo, onde o custo de manutenção do filho deve ser dividido proporcionalmente entre as partes. Para melhor compreensão, vejamos o exemplo a seguir:
                <br></br>
                <br></br>
                Casal com um filho, onde o homem tem uma remuneração maior que o dá mulher. Devemos calcular a necessidade financeira desse filho com base no trinômio.
                <br></br>
                <br></br>
                <u>Despesas do Filho</u>
                <br></br><br></br>

                <table border="1" width="100%">
                <tbody>
                <tr>
                <td width="70%"><b>Escola</b></td>
                <td width="30%" id="detail">650,00</td>
                </tr>    
                <tr>
                <td ><b>Plano de Saúde</b></td>
                <td id="detail">450,00</td>
                </tr>    
                <tr>
                <td ><b>Transporte</b></td>
                <td id="detail">120,00</td>
                </tr>    
                <tr>
                <td ><b>Lazer</b></td>
                <td id="detail">200,00</td>
                </tr>    
                <tr>
                <td ><b>Outros</b></td>
                <td id="detail">280,00</td>
                </tr>    
                <tr>
                <td bgcolor='#EAEAEA' ><b>TOTAL</b></td>
                <td bgcolor='#EAEAEA' id="detail"><b>1.700,00</b></td>
                </tr>    
                </tbody>
                </table>

                <br></br><br></br>
                <u>Despesas da Habitação</u>
                <br></br><br></br>

                <table border="1" width="100%">
                <tbody>
                <tr>
                <td width="70%"><b>Consumo de Água</b></td>
                <td width="30%" id="detail">150,00</td>
                </tr>    
                <tr>
                <td ><b>Consumo de Luz</b></td>
                <td id="detail">250,00</td>
                </tr>    
                <tr>
                <td ><b>Consumo de Gás</b></td>
                <td id="detail">100,00</td>
                </tr>    
                <tr>
                <td ><b>Internet</b></td>
                <td id="detail">100,00</td>
                </tr>    
                <tr>
                <td ><b>Supermercado</b></td>
                <td id="detail">800,00</td>
                </tr>    
                <tr>
                <td ><b>Condomínio</b></td>
                <td id="detail">1.000,00</td>
                </tr>   
                <tr>
                <td ><b>Impostos</b></td>
                <td id="detail">200,00</td>
                </tr>    
                <tr>
                <td bgcolor='#EAEAEA' ><b>TOTAL</b></td>
                <td bgcolor='#EAEAEA' id="detail"><b>2.600,00</b></td>
                </tr>    
                <tr>
                <td bgcolor='#EAEAEA' ><b>TOTAL&nbsp;/&nbsp;2</b></td>
                <td bgcolor='#EAEAEA' id="detail"><b>1.300,00</b></td>
                </tr> 
                </tbody>   
                </table>

                <br></br>
                <br></br>
                <table border="1" width="100%">
                <tbody>
                <tr>
                <td width="33%" id="total"><b>Despesas do Filho<br></br><br></br>1.700,00</b></td>
                <td width="33%" id="total"><b>Despesas de Habitação<br></br><br></br>1.300,00</b></td>
                <td width="34%" id="total"><b>Necessidade do Filho<br></br><br></br>3.000,00</b></td>
                </tr>   
                </tbody> 
                </table>

                <br></br><br></br>
                <u>Proporcionalidade</u>
                <br></br><br></br>
              
                <table border="1" width="100%">
                <tbody>
                <tr>
                <td width="70%"><b>Necessidade do Filho</b></td>
                <td width="30%" id="detail">3.000,00</td>
                </tr>    
                <tr>
                <td ><b>Salário da Mãe</b></td>
                <td id="detail">3.000,00</td>
                </tr>    
                <tr>
                <td ><b>Salário do Pai</b></td>
                <td id="detail">6.000,00</td>
                </tr>    
                </tbody>  
                </table>
                <br></br><br></br>

                O pai ganha duas vezes mais que a mãe. <br></br><br></br> 
                Portanto, existe uma desproporção entre eles. A possibilidade de o pai contribuir é o dobro da mãe. <br></br><br></br><br></br>
                <i><b>Se ganhassem igual, as despesas seriam divididas igualitariamente. Se apenas o pai trabalhasse deveria pagar o valor total calculado. Se estivesse desempregado, o juiz determinaria um valor temporário de pagamento. </b></i><br></br><br></br><br></br>
               
                A pensão que o pai deverá pagar neste exemplo será <b>2x</b> (duas vezes mais), onde x :<br></br><br></br>  
                <S.FormChat><img src={formula2} alt=""></img></S.FormChat>
                <br></br>
                substituindo, temos: 3x = 3000&nbsp;&nbsp;<b>x = 1000</b> &nbsp;Portanto, 2 x 1000 = 2000
                <br></br><br></br>

                <table border="1" width="100%">
                <tbody>
                <tr>
                <td id="total" bgcolor='#EAEAEA' ><b>VALOR DA PENSÃO<br></br>2.000,00</b></td>
                </tr> 
                </tbody>     
                </table>
                <br></br><br></br>

                <i>Obs: Caso a <u>NECESSIDADE DO FILHO</u> aumente no próximo mês para $ 3.600,00 o valor da pensão será:<br></br>  
                ( 3600 / 3 ) x 2 =  $ 2.400,00. </i>

                <br></br><br></br><br></br>
                Considerações :
                <br></br><br></br>
                Caso o alimentante tenha estudado e adquirido uma profissão melhor remunerada, esse esforço pessoal não será considerado, muito pelo contrário, ele será penalizado por sua capacidade. <br></br><br></br>
                Ele pode inclusive estar realizando o dobro de esforço para obter esse rendimento, mas isso não é medido nem questionado. <br></br><br></br>
                Além disso, os seus custos pessoais não são levados em consideração, ele também terá despesas com moradia, água, luz, supermercado, combustível e etc.  <br></br><br></br>
                A fórmula de cálculo também favorece a outra parte, pois não é possível acreditar que um adulto gaste em supermercado o mesmo que uma criança, mas na planilha esse valor é simplesmente dividido por dois. <br></br><br></br>
                <b>Esse trinômio não é aplicado quando a mulher ganha mais que o homem.</b>
                
                <br></br><br></br><br></br>
                <b>Olhos Seletivos</b>
                <br></br><br></br> 
                A justiça tem olhos seletivos, ela enxerga apenas aquilo que é de seu interesse e nada mais. <br></br><br></br>
                Evidente que isso é uma figura de linguagem. Contudo, podemos avaliar suas decisões com base nesse pensamento. Utilizemos um caso real para ilustrar essa ideia. 
                
                <br></br><br></br>
                <u>Movimentação Financeira</u>
                <br></br><br></br>

                <table border="1" width="100%">
                <tbody>
                <tr>
                <td width="70%"><b>Saldo do mês anterior</b></td>
                <td width="30%" id="detail"><font color="red">-3.000,00</font></td>
                </tr>    
                <tr>
                <td ><b>Pagamento de contas</b></td>
                <td id="detail">-2.000,00</td>
                </tr>    
                <tr>
                <td ><b>Pagamento de contas</b></td>
                <td id="detail">-1.000,00</td>
                </tr>    
                <tr>
                <td ><b>Pagamento de contas</b></td>
                <td id="detail">-1.000,00</td>
                </tr>    
                <tr>
                <td ><b>DOC recebido</b></td>
                <td id="detail"><font color="blue">5.000,00</font></td>
                </tr>    
                <tr>
                <td ><b>Pagamento de contas</b></td>
                <td id="detail">-2.000,00</td>
                </tr>    
                <tr>
                <td ><b>Saque caixa eletrônico</b></td>
                <td id="detail">-3.000,00</td>
                </tr>    
                </tbody>  
                </table>

                <br></br><br></br>
                 Como uma pessoa comum enxerga esse extrato?
                <br></br><br></br>

                <table border="1" width="100%">
                <tbody>
                <tr>
                <td bgcolor='#EAEAEA' id="total"><b>A conta é devedora em<br></br><font color="red">-7.000,00</font></b></td>
                </tr>    
                </tbody>  
                </table>
                
                <br></br><br></br>
                Como a justiça enxerga esse extrato?
                <br></br><br></br>

                <table border="1" width="100%">
                <tbody>
                <tr>
                <td bgcolor='#EAEAEA' id="total"><b>A conta é credora em<br></br>5.000,00</b></td>
                </tr>    
                </tbody>  
                </table>
                <br></br><br></br>

                A justiça apenas considerá a <b><u>RECEITA</u></b> financeira, não analisa as despesas encontradas.<br></br>.<br></br>
                A justiça pode sem necessidade de autorização:<br></br><br></br>

                <ul>
                <li>Verificar e manipular os recursos financeiros de qualquer pessoa. </li>
                <li>Bloquear qualquer conta e debitar qualquer valor que achar necessário.</li> <br></br>
                </ul>

                <br></br><br></br>
                <b>Caso Real 1</b>
                <br></br><br></br>

                O exemplo mostrado anteriormente é um caso real. <br></br><br></br>
                A justiça analisou a movimentação financeira de um homem que estava desempregado para poder determinar qual seria o valor da pensão definitiva a ser paga. <br></br><br></br>
                O homem possuía dois filhos e já efetuava o pagamento de uma pensão temporária no valor de um salário mínimo para cada filho. <br></br><br></br>
                Seu estado financeiro era precário e sua conta bancária demonstrava um saldo negativo cada vez maior. <br></br><br></br>
                Contudo, a justiça determinou que seus rendimentos eram os créditos encontrados,  desconsiderando todo o resto. <br></br><br></br>
                Também decretou que o valor da pensão deveria ser <u>aumentado</u> para um salário mínimo e meio para cada filho, considerando que os valores negativos eram apenas de sua responsabilidade.  
                <br></br><br></br><br></br>

                <table border="1" width="100%">
                <tbody>
                <tr>
                <td width="70%"><b>Rendimentos</b></td>
                <td width="30%" id="detail">5.000,00</td>
                </tr>    
                <tr>
                <td ><b>Salário Mínimo 2019</b></td>
                <td id="detail">998,00</td>
                </tr>    
                <tr>
                <td ><b>Pensão a ser paga</b></td>
                <td id="detail">2.994,00</td>
                </tr>    
                </tbody>  
                </table>
                <br></br><br></br>

                Esse caso demonstra claramente que o binômio <b>POSSIBILIDADE x NECESSIDADE</b> é uma falácia, não sendo utilizado na prática. <br></br><br></br>
                Além disso, o valor da pensão decretado é de <b>59,8%</b> dos rendimentos atribuidos pela própria justiça. Um valor absurdo, principalmente para quem estava desempregado, e muito acima do percentual de referência de 30%.
                
                <br></br><br></br><br></br>
                <b>Verdade dos Fatos</b>
                <br></br><br></br> 
                Temos a impressão que tudo em um processo é analisado e verificado, que os documentos anexados a ele são validados e checados. <br></br><br></br>
                Que é função e dever dos componentes do judicial realizar essa tarefa, para que a verdade possa ser demonstrada e a justiça alcançada. <br></br><br></br>
                Contudo, qualquer coisa pode ser escrita ou anexada aos altos, não há verificação de sua autenticidade. <br></br><br></br>
                Atualmente, vivemos na era da informação, mas as pessoas não são capazes de interpretar um simples texto, nem de analisar o cenário que as cercam. Enxergam apenas o que é mostrado sem questionar ou verificar as informações apresentadas. Até mesmo pessoas portadoras de um vasto conhecimento acadêmico não são capazes de analisar ou questionar os fatos relatados. <br></br><br></br>
                Então, quando isso acontece temos uma justiça abstrata, onde qualquer coisa poderá ser decretada. Ficamos inteiramente à mercê da casualidade e da boa vontade de nossos avaliadores. Isso é a completa falência das instituições que deveriam nos ajudar e socorrer. 

                <br></br><br></br><br></br>
                <b>Caso Real 2<br></br>Parte 1</b>
                <br></br><br></br>
                Um homem estava desempregado quando sua esposa solicitou o divórcio. <br></br><br></br>
                Quando ocorreu a audiência para definição do valor da pensão, ele já estava empregado novamente. <br></br><br></br>
                Era um valor muito inferior aos seus rendimentos anteriores, mas dentro da realidade do mercado daquele momento. <br></br><br></br>
                O novo salário demonstrado através de documentos, <u>não foi aceito</u> pelos representantes da mulher. <br></br><br></br>
                Alegaram que os documentos eram falsos. <br></br><br></br>
                O juiz finalizou a audiência, concedendo a eles alguns dias para que provassem a falsidade dos documentos. <br></br><br></br>
                Esses dias se transformaram em anos e nenhuma prova foi apresentada.<br></br><br></br>
                Durante esses anos, anexaram apenas o rascunho de um contrato de serviço de vinte anos atrás. <br></br><br></br>
                Ele não tinha assinatura, carimbo, logotipo, nem legitimidade, era apenas um pedaço de papel que qualquer pessoa poderia ter produzido. <br></br><br></br>
                Era o esboço de um antigo contrato permitindo que uma empresa comercializasse um produto que o réu tinha desenvolvido muitos anos antes de seu casamento. <br></br><br></br>
                Foi um projeto que não deu certo, e nenhum contrato tinha sido assinado. <br></br><br></br>
                Mesmo assim, anexaram um papel sem autenticidade, relevância ou lógica ao processo, que foi aceito sem qualquer questionamento.<br></br><br></br>
                Era apenas um antigo rascunho de um contrato sem assinatura. Continha o nome de duas empresas, uma era fabricante de um produto, a outra responsável por sua comercialização e distribuição. <br></br><br></br>
                A primeira empresa pertencia ao réu dessa história, mas fazia muitos anos que já tinha sido encerrada. A segunda possuía outros proprietários, mas também não existia mais. <br></br><br></br>
                Eles nunca apresentaram qualquer prova contra os documentos da primeira audiência, ao contrário anexaram um papel sem valor e ainda exigiram que as empresas nele mencionadas fossem acionadas.<br></br><br></br>
                Não é possível explicar qual era o objetivo dessa atitude, nem tampouco dizer o que essas empresas tinham haver com o processo original e como comprovariam a falsidade dos documentos, mas durante anos oficiais de justiça foram enviados aos seus respectivos e antigos endereços para intimá-las a prestar esclarecimento sobre alguma coisa. <br></br><br></br>
                Essa operação foi repetida diversas vezes sem produzir qualquer resultado, além de terem ficado em um <u><b>CICLO IRRACIONAL</b></u>, nem ao menos verificaram a situação das empresas para saber se continuavam ativas e funcionando no mesmo endereço. <br></br><br></br>
                Tempo e dinheiro foram gastos em operações sem sentido com base em um documento sem nexo ou valor. <br></br><br></br>
                O homem dessa história foi acusado de falsificação, teve sua vida vasculhada e paralisada durante anos, e pagou por toda essa incompetência e insanidade. <br></br><br></br>
                A justiça e seus representantes não tiveram à capacidade de interpretar e analisar os dados apresentados, mesmo assim se consideraram capazes de julgar e decidir os destinos dos envolvidos sem utilizar à verdade dos fatos ou racionalidade.
                
                <br></br><br></br><br></br>
                <b>Justiça Mediúnica</b>
                <br></br><br></br>
                A justiça utiliza leis mediúnicas para penalizar aqueles que não são capazes de prever os acontecimentos futuros. Esse assunto já foi discutido anteriormente nesse material. Contudo, o caso a seguir poderá ilustrar o seu funcionamento prático. 
                <br></br><br></br><br></br>
                <b>Parte 2</b>
                <br></br><br></br>
                O réu devido a um documento sem nexo ou validade, ficou esperando durante anos o resultado do processo que definiria o valor de pensão definitiva. <br></br><br></br>
                O atraso foi provocado pela outra parte do processo, e principalmente por causa da incapacidade da justiça de lidar com os documentos e fatos apresentados. <br></br><br></br>
                Por exemplo, o valor temporário da pensão era de 1.000,00, mas sem qualquer explicação foi aumentado em 50%, passando o valor definitivo a ser de 1.500,00. <br></br><br></br>
                Como o novo valor é maior que o anterior, a lei mediúnica foi aplicada com rigor, porque era obrigação desse homem ter adivinhado qual valor seria decretado. <br></br><br></br>
                Portanto, não cumpriu com seu dever sendo penalizado por causa dessa infração, tendo que pagar a diferença corrigida com multa, mais as custas do processo. <br></br><br></br>
                Caso não fizesse sofreria as consequencias legais que seriam decretadas contra ele relativas ao não pagamento de pensão.

                <br></br><br></br><br></br>
                <table border="1" width="100%">
                <tbody>
                <tr>
                <td width="70%"><b>Valor pago </b></td>
                <td width="30%" id="detail">24.000,00</td>
                </tr>    
                <tr>
                <td><b>Valor corrigido</b></td>
                <td id="detail">42.382,88</td>
                </tr>  
                <tr>
                <td><b>Valor devido</b></td>
                <td id="detail">18.382,88</td>
                </tr>    
                </tbody>  
                </table>
                <br></br><br></br>

                <i>Atualização pelo índice IGP-M - Índ. Geral de Preços do Mercado, com juros compostos de 1,000% ao mês, pro-rata die.</i>
                <br></br><br></br><br></br>
                Infelizmente, esse caso é real e coisas piores acontecem todos os dias. Os componentes da justiça não sofrem quaisquer consequências por seus erros e incompetências. Alguém sempre terá que pagar as contas, e geralmente não será aqueles que causaram o problema, nem aqueles que não foram capazes de julgar. <br></br><br></br>
                <i><b>A lei na qual esse caso está subordinado é absoluta. Logo, não existe defesa nem justiça.</b></i>

               
                <br></br><br></br>
                </span>
            </S.FormText>

            </S.Form>
            </>
            :
            <>
            <br></br>
            <br></br>
            <h3>&nbsp;</h3>
            <br></br>
            </>    }  
        </S.Container>
    )
}

export default GuidePart5;