import styled from 'styled-components';

export const Container = styled.div`
    background:  #FFFFFF;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const FormText = styled.div`

        font-size: 16px;
        font-family: 'Roboto', sans-serif;
        text-align: left;
        margin-bottom: 50px;

    }
`