import styled               from 'styled-components';

export const Container = styled.div`
    background: none;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
`

export const ContainerTrailer = styled.div`
    background: #f3f6f4;
    width: 100%;
    height:330px;
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 960px) {
        flex-direction: column;
        height:640px;
    }
`

export const ContainerTrailerTitle = styled.div`
    background: none;
    width: 100%;
    display: flex;
    flex-direction: column;

    h1 {
        margin-left: 30%;
        margin-top: 80px;
        font-size: 28px;
        font-family: 'Roboto', sans-serif;
        font-weight: bold;
        text-decoration: none;
        color: #70AD47;

        @media screen and (max-width: 960px) {
            margin-left: 20%;
        }
        }

    @media screen and (max-width: 960px) {
        width: 100%;
    }
`

export const TrailerLink = styled.div`
    background: none;
    text-decoration: none;
    margin-top: 10px;
    margin-left: 30%;

    span {
        font-size: 18px;
        font-family: 'Roboto', sans-serif;
        text-decoration: none;
        color: #696969;
        cursor: pointer;

        &:hover {
            color: #70AD47;
            transaction: all 0.3s ease;
        }
        }

    @media screen and (max-width: 960px) {
        margin-top: 20px;
        margin-left: 20%;
    }
`

export const ContainerCopy = styled.div`
    background: none;
    width: 100%;
    margin-top: 60px;
    margin-bottom: 60px;
    display: flex;
    align-items: center;
    flex-direction: column;

    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    color: #696969;

    @media screen and (max-width: 960px) {
        width: 100%;
        margin-top:20px;
    }
`

export const MidiaContainer = styled.div`
    background: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 50px;
`

export const MidiaItem = styled.div`
    background: none;
    flex-direction: column;
    align-items: center;
    margin-left: 20px;
`

export const MidiaButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }
`