import React      from 'react';
import * as S     from './styles';
import midia2     from '../../assets/midia2.png';
import midia3     from '../../assets/midia3.png';
import midia4     from '../../assets/midia4.png';
import { i18n }   from '../../translate/i18n';

function Trailer() {

    function buttonMidia2() {
        window.open('https://www.youtube.com/channel/UCxwyQBSZnuLXtGd5R89m1-A', '_blank');
    }

    function buttonMidia3() {
        window.open('https://www.facebook.com/Kodasware', '_blank');
    }

    function buttonMidia4() {
        window.open('https://www.instagram.com/Kodasware', '_blank');
    }

    function buttonOption2() {
        window.open('https://www.kodasware.com/privacy', '_self');
    }

    function buttonOption3() {
        window.open('https://www.kodasware.com/contact', '_self');
    }

    function buttonOption4() {
        window.open('https://www.kodasware.com/product', '_self');
    }

    function buttonOption5() {
        window.open('https://www.kodasware.com/home', '_self');
    }

    return (

        <S.Container>

            <S.ContainerTrailer>
                <S.ContainerTrailerTitle>
                    <h1>{i18n.t('trailer.title1')}</h1>
                    <S.TrailerLink onClick={buttonOption5}><span>{i18n.t('trailer.option5')}</span></S.TrailerLink>
                    <S.TrailerLink onClick={buttonOption2}><span>{i18n.t('trailer.option2')}</span></S.TrailerLink>
                </S.ContainerTrailerTitle>

                <S.ContainerTrailerTitle>
                    <h1>{i18n.t('trailer.title2')}</h1>
                    <S.TrailerLink onClick={buttonOption3}><span>{i18n.t('trailer.option3')}</span></S.TrailerLink>
                </S.ContainerTrailerTitle>

                <S.ContainerTrailerTitle>
                    <h1>{i18n.t('trailer.title3')}</h1>
                    <S.TrailerLink onClick={buttonOption4}><span>{i18n.t('trailer.option4')}</span></S.TrailerLink>
                </S.ContainerTrailerTitle>
            </S.ContainerTrailer>
            
            <S.MidiaContainer>
                <S.MidiaItem><S.MidiaButton onClick={buttonMidia2}><img src={midia2} alt=''></img></S.MidiaButton></S.MidiaItem>
                <S.MidiaItem><S.MidiaButton onClick={buttonMidia3}><img src={midia3} alt=''></img></S.MidiaButton></S.MidiaItem>
                <S.MidiaItem><S.MidiaButton onClick={buttonMidia4}><img src={midia4} alt=''></img></S.MidiaButton></S.MidiaItem>
            </S.MidiaContainer>
            
            <S.ContainerCopy><span>2022&nbsp;&copy;&nbsp;KODASWARE&nbsp;&nbsp;All rights reserved.</span></S.ContainerCopy>

        </S.Container>

    );
    }
    
export default Trailer;
