import React        from 'react';
import KnowMore     from '../../../global/globalToKnowMore';
import * as GV      from '../../../global/globalVariables';

function ToKnowMorePasswordWallet() {
    
    return (
        <>
        <KnowMore appName={GV.APP_PASSWORD_WALLET}></KnowMore>
        </>
    )
}

export default ToKnowMorePasswordWallet;