
import React, { useEffect, useState } from 'react';   
import * as S       from './styles';
import guide29      from '../../../../view/DivorceGuide/Guide/Images/guide29.png';
import guide30      from '../../../../view/DivorceGuide/Guide/Images/guide30.png';
import guide31      from '../../../../view/DivorceGuide/Guide/Images/guide31.png';
import util         from '../../../../Utilities/Util';

function GuidePart6() {
    
    const [access, setAccess] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)
        setAccess( util.checkGuideAccess() );
    }, [])

    return (
        <S.Container>
        { ( access )
            ?
            <>
            <S.Form> 

            <S.FormTitle>
                <span>PART 6</span><br></br><br></br><br></br><span>Fight and Defend</span><br></br><br></br>
            </S.FormTitle>

            <S.FormText>
                <span>

                Most people live asleep. The reality is not perceptible to most of them. 
                They spend most of their time working and trying to survive. 
                There is no way to stop this ritual. There is always a problem or challenge that needs to be solved or overcome. 
                The pace of life is hectic and demanding with few moments of rest. Generally, used on useless and worthless things. <br></br><br></br>
                So it is difficult or almost impossible to analyze and understand what is happening. 
                Only in moments of pain or suffering do we realize everything bad and terrible that happens around us. 
                Unfortunately, it will be too late and any reaction will be practically impossible. <br></br><br></br>
                Terrible things are being done to us, but we are so caught up in our rituals or problems that we have no interest or inclination to know about them. 
                It is more comfortable and peaceful to remain in lies and ignorance.
                Because of this, evil and injustice always win. <br></br><br></br>
                Society and institutions are bankrupt. We cannot trust them when we need help or relief. <br></br><br></br>
                Ignorance and injustice have already reached catastrophic levels and continue to grow. 
                Falling asleep is no longer an option it is suicide. 
                Like it or not, you will get hit even if you stay in your comfort zone and think you are protected. <br></br><br></br>
                Divorce and sexist laws are an example of this evil. 
                Even if you don't have children or are not married this madness and injustice can hit you at any time. <br></br><br></br>
                
                <i><b>It is necessary to wake up to this reality fight and defend oneself from it</b></i>.
                
                <br></br><br></br><br></br>
                <h2>Divorce Game</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide29} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>
                

                Divorce has a set of steps to follow that define its future and outcome. 
                It can be compared to a board game where the position that the player occupies results in something positive or negative in the game that is being played.
                It is a game that can be defined by strategy, luck or a mix of both. <br></br><br></br>
                However, it is not a fair game governed solely by these attributes. 
                It is designed and manufactured so that only one side is the winner. Being a man and being able to win this game is practically impossible.
                It takes a lot of luck and being an exceptional tactician. <br></br><br></br>
                <i><b>Wanting to play this game without having a well-defined and elaborate strategy is a sure invitation to personal and financial defeat.</b></i> <br></br><br></br >
                Many believe that the institutions that run this game are impartial and would never allow cheating. 
                Terrible and disastrous mistake. One of them will always be favored while the other will always be at a disadvantage. <br></br><br></br>
                In addition to luck and strategy, the <i>character</i> of the most favored player can define the positive outcome of this game. <br></br><br></br>
                However, since one of them always has the advantage.
                It will be difficult to find someone with fair play who will play the game in a friendly way wishing only the best for everyone. <br></br><br></br>
                Therefore, we must not fool ourselves with these institutions or with the good will of the opponent. 
                In this way it is essential to know the flow of this game to understand how it works and know how to play it.
                <br></br><br></br><br></br>

                <h2>Asymmetric War</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide30} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>
               
                This type of war describes a confrontation between opponents who have various differences between them such as: military power, financial resources, organization, objectives, obedience to the rules, etc. 
                In general it represents a conflict between a large nation and a much smaller one. <br></br><br></br>
                The objective of asymmetric warfare is the same as that of conventional warfare, exhausting the enemy, imposing his will on him, wearing him down, weakening him and immobilizing him. 
                Even the immobilization of the opponent always means in a war the beginning of victory. <br></br><br></br>
                Divorce has now become asymmetrical warfare. Where the existing laws and support system provide one side of this dispute with <u><b>PRACTICALLY INVINCIBLE AND UNBEATABLE FIREPOWER</b></u>. <br></br><br></br>
                Earlier we compared divorce to a game where one of the opponents has all sorts of advantages while the other only has disadvantages. 
                Every war is also a game with the function of defeating your opponent and leaving him without reaction capacity. 
                These characteristics are present in current divorce processes. <br></br><br></br>
                Those who lose in these processes see their rights violated, injured, mutilated, enslaved and left without the possibility of reaction, remaining eternally subordinated to the victorious side of the conflict. 
                It still produces innocent victims (children), consequences and the massive destruction of personal and financial resources that existed before the dispute. <br></br><br></br>
                The similarity between a conflict between nations and what currently happens with marriages that end in divorce is undeniable. 
                Both produce destruction and devastating results.
                Where the important thing is simply to win under any circumstances and acquire the greatest amount of benefits possible. 
                No matter the future consequences and the trail of destruction left along the way.
                <br></br><br></br><br></br>

                <b>PRINCIPLES OF WAR</b>
                <br></br><br></br>
                The similarity between a divorce and an armed conflict between nations is so great that it is possible to use the same techniques and principles of war adopted by most military forces in the world today.
                <br></br><br></br>

                <table border="1" width="100%">
                <tbody>
                <tr><td bgcolor='#C2E3B4' ><b>OBJECTIVE</b></td><td width="70%">The objective is target that is intended to be achieved and that determines the direction of the effort of all military activity. <br></br>It must be feasible and enforceable.</td></tr>
                <tr><td bgcolor='#C2E3B4' ><b>OFFENSE</b></td><td>Offense means having initiative. It is necessary to obtain decisive results and preserve freedom of action. <br></br>Take the initiative and impose your will on the enemy.</td></tr>
                <tr><td bgcolor='#C2E3B4' ><b>MASS</b></td><td>It is to concentrate the combat potential in the decisive place and time. The correct application of this principle can allow numerically inferior forces to achieve success and victory at a crucial moment in the battle.</td></tr>
                <tr><td bgcolor='#C2E3B4' ><b>SECURITY</b></td><td>Do not allow the enemy to gain an unexpected advantage. Security is essential to maintain combat potential. It is denying the enemy information about the plans and actions of the force. It is to prevent surprise attacks and acts of sabotage.</td></tr>
                <tr><td bgcolor='#C2E3B4' ><b>ECONOMY OF FORCES</b></td><td>It is a corollary of the principle of mass. It is to carefully use the combat potential to allow the mission to be accomplished with a minimum of wear and tear on the available resources. It is to use only the essential of the resources in the secondary actions that must be carried out.</td></tr>
                <tr><td bgcolor='#C2E3B4' ><b>MANEUVER</b></td><td>It is placing forces in such a way that the enemy is at a disadvantage. It is to obtain an advantageous position that allows reaching the objective in a way that requires the least possible expenditure of resources.</td></tr>
                <tr><td bgcolor='#C2E3B4' ><b>UNITY OF COMMAND</b></td><td>Place available forces under a line of command. The application of all the available potential requires a great convergence of efforts through the coordinated action of all existing forces. Therefore, it is essential that there is no dispersion of energies and responsibilities. </td></tr>
                <tr><td bgcolor='#C2E3B4' ><b>SURPRISE</b></td><td>Means to hit the enemy unexpectedly. It is not essential to catch the enemy completely off guard, but it is enough that he does not have enough time to react effectively.</td></tr>
                <tr><td bgcolor='#C2E3B4' ><b>SIMPLICITY</b></td><td>Plans should be made, prepared and executed with the utmost clarity and understanding to minimize the margin of error, special care must be taken in the way orders are distributed. It must be clear and leave no doubt as to their execution.</td></tr>
                </tbody>
                </table>

                <br></br><br></br><br></br>
                <b>PRINCIPLES OF WAR IN DIVORCE</b>
                <br></br><br></br>
                The existing divorce scenario, described above in military language demonstrates its similarity to an armed struggle between enemy nations. 
                Therefore, we can use the principles of warfare adopted by the current military forces for the marriage dispute and gain a better understanding and some advantage in this dispute.
                <br></br><br></br><br></br>

                <table border="1">
                <tbody>
                <tr><td bgcolor='#C2E3B4' ><b>OBJECTIVE</b></td><td width="70%">The goal will always be divorce. <br></br>It can be done consensually or by litigation. It is more advisable to try to carry it out through diplomatic channels than through armed struggle even if it is through an agreement that is not very advantageous since the firepower granted to women is infinitely higher than that of men. If this is not possible, all necessary preparations must be made so that the dispute is favorable and balanced for your side.</td></tr>
                <tr><td bgcolor='#C2E3B4' ><b>OFFENSIVE</b></td><td>It is essential that the divorce process be requested by the man. Not only this one, but all the others that may exist. The opposing party may not have a chance to attack, they should always stay on the defensive.</td></tr>
                <tr><td bgcolor='#C2E3B4' ><b>MASS</b></td><td>All processes must be well designed with clear and realistic objectives. Time and money should not be wasted on processes that are not relevant or that do not offer any kind of advantage. Focus your resources only on those who have a plausible chance of winning and bringing some personal or financial benefit. </td></tr>
                <tr><td bgcolor='#C2E3B4' ><b>SECURITY</b></td><td>Divorce should be a project elaborated with the greatest possible secrecy. Losing the element of surprise or letting the enemy know your plans and firepower is fatal to anyone who wants victory. All your past life will be tracked by the opposing party. Any information even if it seems irrelevant can be used as a weapon and acquire unimaginable proportions in the legal field. Do not carry out any conversation through email, social networks, cell phone or any other means of communication that can be tracked or intercepted. Do not fight or say thoughtless things you may be being filmed, recorded or watched by people willing to testify against you. Change passwords on all your devices and keep all files safe. Do not trust or share information with your friends, family or anyone else. </td></tr>
                <tr><td bgcolor='#C2E3B4' ><b>ECONOMY OF FORCES</b></td><td>A litigious divorce process can be long and very expensive. It is necessary to have an economic reserve to be able to carry it out safely. Without it, you will inevitably run out of resources and be quickly defeated. It is critical to plan ahead and not go through unnecessary paperwork based on pride, anger, or revenge. You must conserve your forces and resources as much as possible. Your main objective will be to get out of this situation with the least amount of losses in order to rebuild your life. </td></tr>
                <tr><td bgcolor='#C2E3B4' ><b>MANEUVER</b></td><td>The place where the divorce will take place is in the civil justice family court. <br></br>It is completely unfavorable terrain for men. It takes good planning, a solid body of evidence and a great lawyer to try and win this spot. All the maneuvers carried out on it must be very well calculated. However, moving your opponent to terrain where he has lower mobility is one way to reduce his winning potential. Secondary processes must be created against the opposing party to erode its resources and lead to its defeat. These processes will be judged in common justice where the laws are not only favorable to a part of the controversy. Breaking their morale, hegemony and certainty of victory.</td></tr>
                <tr><td bgcolor='#C2E3B4' ><b>UNITY OF COMMAND</b></td><td>You need to know and trust the general who will command your army. He will coordinate the attack and defense of his territory. That is the role of a lawyer in a divorce case. He is the main element to achieve victory or defeat. In order for there to be a profit or as little loss as possible. It is necessary to select a highly qualified professional. Unfortunately, they are difficult to locate and expensive. The fate of a divorce should not be placed in anyone's hands, especially someone whose competence and performance are unknown. Currently, the laws are extremely severe and closed to men. Little or nothing can be done, turning these professionals into dispatchers of legal documents.
                That is why it is important to find and hire reputable professionals in the market. Only they are capable of finding or elaborating implausible solutions within this closed and static environment. Avoid inexperienced, cheap or do-it-all professionals. This is one of the worst mistakes men make, trying to save money with the person who will defend them. They must take into account that none of them will suffer any consequences for the actions carried out and they will always be paid regardless of the results obtained. Therefore, there is no merit in victory. It will be just another process. Unlike those who have a reputation and a name to uphold. It is true that even they cannot guarantee a victory, but they can certainly reduce the destruction and damage caused by this war.</td></tr>
                <tr><td bgcolor='#C2E3B4' ><b>SURPRISE</b></td><td>It is a decisive factor in a war. <br></br>In case of divorce it is one of the few weapons available to men. It is imperative that they make the first attack. Without this initiative, victory will almost certainly not be possible. It is necessary to develop tactical planning in advance before starting the divorce process. However, if it turns out that the enemy is going to attack you at any moment, all planning should be momentarily forgotten. It is essential to make the first move in this process even if you are not ready yet. The element of surprise cannot be lost under any circumstances.</td></tr>
                <tr><td bgcolor='#C2E3B4' ><b>SIMPLICITY</b></td><td>Justice uses language that is difficult for ordinary people to understand. It is the lawyer's job to translate and adapt this vocabulary so that it can be understood and there are no doubts about the subject matter. It is essential that everything requested or received is documented, understood and confirmed. There can be no unknown and unauthorized movements neither by the defender nor by the contracting party. Everything must be clear and without doubt or misinterpretation. Preferably, all issues and decisions must be recorded in electronic media that can be consulted, confirmed and authorized.</td></tr>
                </tbody>
                </table>

                <br></br><br></br><br></br>
                <b>WAR SCENARIO</b>
                <br></br><br></br><br></br>

                <table border="1" width="100%">
                <tbody>
                <tr><td bgcolor='#000000' width="30%"><b><font color="white">TERRAIN</font></b></td><td width="70%" >It is the place where the battle will be fought. In case of divorce, it will be in the family court of civil justice. It is the worst possible place for men. The chances of winning in this field are practically nil. For victory to occur it is necessary to change terrain or have a powerful strategic plan that makes it possible to operate on it.</td></tr>
                <tr><td bgcolor='#000000' ><b><font color="white">TACTICAL ADVANTAGE</font></b></td><td> The laws and components of justice are in favor Women's. This is a tactical advantage that is hard to match or beat. The only way to overcome or diminish this advantage is to make the first attack against the opponent.</td></tr>
                <tr><td bgcolor='#000000' ><b><font color="white">STRATEGY</font></b></td><td>Women possess unsurpassed firepower and they perform surprise attack on your opponents. Men are too passive and try to postpone the confrontation or resolve it through diplomatic means. Therefore, they are taken by surprise and destroyed or immobilized in the first attack. Remain throughout the conflict only defending yourself. Offense is the best defense.</td></tr>
                <tr><td bgcolor='#000000' ><b><font color="white">OFFENSIVE</font></b></td><td>Men take too long to request for divorce. They believe that things can change and postpone decisions until the last possible minute. That is why they are easily caught in surprise and high intensity attacks. They end up being easily wiped out on the first attack. To prevent this situation from happening they must be the first to request for divorce and make frequent offenses during the process and thus demoralize and wear down the enemy who does not expect this reaction.</td></tr>
                <tr><td bgcolor='#000000' ><b><font color="white">ALLIES</font></b></td><td>The maxim <i>"Women are victims, men are villains"</i>. For this reason, they receive all the support of society, friends, family and all those whom they can captivate with the victimhood that they will create and disseminate. You need to get as many allies as possible before the start of the conflict and gather hard evidence against them to change your image. Family, friends or acquaintances may not be trustworthy. It is essential not to reveal your plans to anyone. The people around you may be traitors or spies. </td></tr>
                </tbody>
                </table>

                <br></br><br></br><br></br>
                <b>RESULTS</b>
                <br></br><br></br><br></br>

                <table border="1" width="100%">
                <tbody>
                <tr><td bgcolor='#000000' width="30%"><b><font color="white">INHERITANCE</font></b></td><td width="70%" >As in most wars assets will be destroyed or damaged. Divorce is a war where the enemy army wants to plunder the resources of the invaded nation. The only way to protect it is through early prevention. The properties must be transferred or sold before the invasion takes place. Otherwise much of it will be lost. There is no defense against this fact. War does not diminish anything or anyone.</td></tr>
                <tr><td bgcolor='#000000' ><b><font color="white">FINANCIAL</font></b></td><td>Wars are expensive. Keeping them running could financially ruin nations. That is why the winners demand the payment of a tribute from the losers. They will be the ones who will have to pay at the cost of the lost war. Once defeated they will not be able to evade this obligation. If they try they will be punished in an exemplary way to discourage any insurrection that might exist. The only way to avoid this is to win or make a peace deal.
                A bad deal can often be less painful and damaging than a war against a powerful and heavily armed enemy. Sometimes it is better to live to be able to fight later in more favorable and feasible victory conditions.</td></tr>
                <tr><td bgcolor='#000000' ><b><font color="white">TRAUMA</font></b></td><td>War is such a terrible thing that no one can experience it without having any kind of trauma in the future. A divorce is a war that affects people physically and mentally. Many cannot bear its horrors and the obligations imposed after the ceasefire. Men not only lose their children and property, but many lose their dignity and self-respect. They suffer humiliation and systematic persecution.
                Your rights are totally ignored and not respected. They become eternal slaves and providers. They will be severely penalized if they do not comply with their obligations, whether or not they are in a position to do so. Many fall ill or commit suicide because they cannot find support or solutions to their problems. Divorce is a war capable of producing unimaginable destruction and consequences even after its conclusion.</td></tr>
                <tr><td bgcolor='#000000' ><b><font color="white">CAUSES</font></b></td><td>There is no such thing as a clean war. They all produce casualties on both sides including innocent civilians. In war, families are torn apart and mutilated. Children will bear the brunt of a divorce. Many will grow up without apparent problems, but all will develop some kind of emotional sequel such as insecurity, anger, depression, which will affect their future lives.
                In an unfair and competitive world they will be at a disadvantage compared to their competitors who have stronger emotional structures that come from a consistent family upbringing. Men are the most disadvantaged in this process as they rarely have the right to share or care for their children. In addition, 80% of them suffer from parental alienation produced by the party that won the war.</td></tr>
                </tbody>
                </table>

                <br></br><br></br><br></br>
                <h2>THE WAR ROOM</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide31} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                It is a place created by the British Prime Minister during World War II for confidential meetings and to discuss military strategy. <br></br><br></br>
                Currently, this concept has been adopted by companies with the aim of holding alignment meetings with their staff and defining goals to be achieved. <br></br><br></br>
                The legal system favors only one side. In this way, it is essential that men discuss and plan their actions before entering this totally unfavorable environment for them. <br></br><br></br>
                Many do not and are easily killed during this procedure. You need to list your resources and measure your strengths. However, many factors that determine victory in a war are imponderable and can hardly be anticipated. <br></br><br></br>
                However, to the extent possible they should be thought of related, measured and discussed before and during the divorce. <br></br><br></br>
                It is not enough to have a single strategy, it must be evaluated and modified according to the facts that arise. <br></br><br></br>
                <i><b> Therefore, it is essential to discuss and verify the progress of this war and take the necessary actions.</b></i><br></br><br></br>
                In addition to the war room concept where we must assess and plan our attacks various methodologies and military actions can be adapted to be used in the divorce process, as described below:

                <br></br><br></br><br></br>
                <b>OUTCOME OF THE WAR</b>
                <br></br><br></br>

                According to the military strategy taught and practiced by today's military forces, in order to predict the outcome of a war, we must analyze and compare our own conditions and those of our enemy, based on five factors.
                <br></br><br></br>

                <table border="1" width="100%">
                <tbody>
                <tr><td width="25%">ROUTE</td></tr>
                <tr><td>CLIMATE</td></tr>
                <tr><td>TERRAIN</td></tr>
                <tr><td>COMMAND</td></tr>
                <tr><td>DOCTRINE</td></tr>
                </tbody>
                </table>

                <br></br><br></br>
                <u>Translating to divorce:</u>
                <br></br><br></br>

                <table border="1" width="100%">
                <tbody>
                <tr><td width="25%" id="cabec">ROUTE</td><td>STRATEGY, ALLIES</td></tr>
                <tr><td id="cabec">CLIMATE</td><td>AVAILABLE TIME, FINANCIAL AND PERSONNEL STRUCTURE</td></tr>
                <tr><td id="cabec">TERRAIN</td><td>FAMILY LEGAL</td></tr>
                <tr><td id="cabec">COMMAND</td><td>LAWYER</td></tr>
                <tr><td id="cabec">DOCTRINE</td><td>ORGANIZATION, DEFINED OBJECTIVES</td></tr>
                </tbody>
                </table>

                <br></br><br></br><br></br>
                <b>RELATIVE POWER BETWEEN FORCES</b>
                <br></br><br></br>

                Based on the concept presented the forces can be compared and the relative power between them evaluated. <br></br><br></br>
                The following questions should be asked to do this:
                <br></br><br></br>

                <table border="1">
                <tbody>
                <tr><td>Which is the best way to go?</td></tr>
                <tr><td>Which commander has more skill?</td></tr>
                <tr><td>Which side has the advantage of climate and terrain?</td></tr>
                <tr><td>Which of the armies displays the most effective discipline?</td></tr>
                <tr><td>Which side has military superiority?</td></tr>
                <tr><td>Which side has the best-trained soldiers?</td></tr>
                <tr><td>Which side has a clearer and more defined system?</td></tr>
                </tbody>
                </table>
                <br></br><br></br>

                <u>Translating to divorce:</u>
                <br></br><br></br>

                <table border="1">
                <tbody>
                <tr><td>Who has the best strategies and allies?</td></tr>
                <tr><td>Which lawyer has more skill?</td></tr>
                <tr><td>Which side has the greater legal advantage?</td></tr>
                <tr><td>Who has the best financial, personal and time structure available?</td></tr>
                <tr><td>Which side exhibits more effective discipline?</td></tr>
                <tr><td>Which side has intellectual and emotional superiority?</td></tr>
                <tr><td>Which side has more support from friends and family?</td></tr>
                <tr><td>Who has clearer and more defined objectives?</td></tr>
                </tbody>
                </table>

                <br></br><br></br><br></br>
                The legal terrain favors women.<br></br>
                They have a tactical advantage of 50% or more in this war.
                <br></br><br></br><br></br>
                <b>DISSIMULATION</b>
                <br></br><br></br>
                It is a technique that aims to trick and deceive the opponent into making wrong and destructive decisions. <br></br><br></br>
                It is an imponderable factor that depends exclusively on the situation and the intelligence of the person who practices it. <br></br><br></br>
                There is no set rule on how and when to use it, but this technique is a fundamental basic quality of any military operation therefore it should be used in the divorce process to increase the chances of victory.
                <br></br><br></br>
                <u>Ways to act in a dispute</u> :
                <br></br><br></br>

                <table border="1">
                <tbody>
                <tr><td>If able, must pretend to be disabled</td></tr>
                <tr><td>If you're ready, you have to pretend you're not</td></tr>
                <tr><td>If you are close, you must pretend to be far away</td></tr>
                <tr><td>Offer decoys to deceive and lure the enemy</td></tr>
                <tr><td>Attacks the enemy when they are disordered</td></tr>
                <tr><td>If the enemy is proud, he must be provoked</td></tr>
                <tr><td>If the enemy is humble, arrogance should be encouraged from him</td></tr>
                <tr><td>If the enemy is rested, you have to wear him down</td></tr>
                </tbody>
                </table>

                <br></br><br></br>
                Related items are self-explanatory and should be used in Divorce.
                <br></br><br></br>

                <i><b>"A military commander must attack where the enemy does not know it and must use paths that for the enemy are unexpected".<br></br>Sun Tzu</b></i>
                <br></br><br></br><br></br>
                <b>NECESSARY PROVISIONS</b>
                <br></br><br></br>
                Any army that stays in the field for a long time will make the state's reserves at a certain point insufficient. <br></br><br></br>
                When the army is exhausted, its supplies insufficient, its troops demoralized and its resources exhausted, its enemies will take advantage of this situation and gain advantages to destroy it. <br></br><br></br>
                Therefore, no hostility should be prolonged beyond the necessary time and it should never be declared without the necessary provisions for its operation.
                
                <br></br><br></br><br></br>
                <u>Translating to divorce:</u>
                <br></br><br></br>

                <table border="1">
                <tbody>
                <tr><td>Increase financial reserves.</td></tr>
                <tr><td>Delete existing expenses.</td></tr>
                <tr><td>Do not make new expenses.</td></tr>
                <tr><td>Eliminate debts in your name.</td></tr>
                <tr><td>Having debts in the name of your spouse.</td></tr>
                <tr><td>Cause the spouse to incur more debt in her name.</td></tr>
                </tbody>
                </table>

                <br></br><br></br><br></br>
                <b>EXPLORE ADVANTAGES</b>
                <br></br><br></br>
                Having the ability to stop or move the enemy represents a great tactical advantage. For example, it is possible to deceive him by offering him some kind of benefit while leading him into a trap.
                As well as presenting some kind of threat to force him to stop his progress. 
                Taking advantage of the advantages that may exist, manipulating the movement of the enemy, represents a determining factor to obtain victory against him.<br></br><br></br>
                So, the ability of a military commander is to tire the enemy when he is rested; leave him hungry when he has provisions; move him when he is stopped.
                
                <br></br><br></br><br></br>
                <u>Translating to divorce:</u>
                <br></br><br></br>

                <table border="1">
                <tbody>
                <tr><td>If you have abundant financial resources, request numerous lawsuits against your spouse to wear them down financially and structurally. Do it gradually and steadily.</td></tr>
                <tr><td>Wear down your spouse physically, morally and financially.</td></tr>
                <tr><td>Create financial expenses on behalf of your spouse.</td></tr>
                <tr><td>Generate problems of all kinds according to your creativity and that are the sole responsibility of your spouse.</td>
                </tr>
                </tbody>
                </table>

                <br></br><br></br>
                <i><b>"A competent general moves the enemy and will not be manipulated by him."<br></br>Sun Tzu </b></i>  
                <br></br><br></br><br></br>
                <b>RIGHT TIME</b>
                <br></br><br></br>
                When a commander achieves an extraordinary victory against his enemy it is because he has prior knowledge and can foresee the course of the battle. <br></br><br></br>
                However, this foreknowledge cannot be obtained through magic or higher intellect. It must be obtained from people who clearly know the enemy's situations.
                
                <br></br><br></br>
                <u>Translating to divorce:</u>
                <br></br><br></br>

                <table border="1">
                <tbody>
                <tr><td>Hire and use private investigators.</td></tr>
                <tr><td>Obtain and record evidence of adultery for use in court.</td></tr>
                <tr><td>Don't be afraid to spy on your spouse.</td></tr>
                <tr><td>Use spy cameras and recorders.</td></tr>
                <tr><td>Get passwords from email, social networks, personal computers, etc.</td></tr>
                <tr><td>Listen and record telephone conversations from landlines and cell phones. </td></tr>
                </tbody>
                </table>

                <br></br><br></br>
                <i><b>"The use of spies is essential in warfare, and the army depends on this service in its movements.".<br></br>Sun Tzu</b></i>  
                <br></br><br></br>
                </span>
            </S.FormText>

            </S.Form>
            </>
            :
            <>
            <br></br>
            <br></br>
            <h3>&nbsp;</h3>
            <br></br>
            </>    }  
        </S.Container>
    )
}

export default GuidePart6;