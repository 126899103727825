import React, { useState }  from 'react';
//import {FaTimes, FaBars}    from 'react-icons/fa';
import * as S               from './styles';
import * as globalVariable  from '../../global/globalVariables';
import flag1                from '../../assets/gb.png';
import flag2                from '../../assets/es.png';
import flag3                from '../../assets/pt.png';
//import logo                 from '../../assets/logo.png';
//import { i18n }             from '../../translate/i18n';

function Navbar() {

    const [click, setClick]       = useState(false);
    const [language, setLanguage] = useState(window.localStorage.getItem(globalVariable.I18N_STORAGE_KEY));
    //const handleClick = () => { setClick(!click) }

    React.useEffect(() => {

        function handleResize() {
           
          if ( window.innerWidth > globalVariable.LIMIT_DESKTOP ) 
             setClick(false);
        }
    
        window.addEventListener('resize', handleResize);
    }, []);
    

    function handleChangeLanguage(event) {

       window.localStorage.setItem(globalVariable.I18N_STORAGE_KEY, event.target.value);
       
       if (!window.location.hash) {
           // eslint-disable-next-line
           window.location.href = window.location.href;
        } else {
           window.location.reload();
        }

       let language = window.localStorage.getItem(globalVariable.I18N_STORAGE_KEY);
       setLanguage(language);

    }

    function flagLanguage() { 
    
        let language = window.localStorage.getItem(globalVariable.I18N_STORAGE_KEY);

        if (language === 'es-ES') return flag2;
        if (language === 'pt-BR') return flag3;
        return flag1;
    }

    //function buttonContact() {
    //    window.open('https://www.kodasware.com/contact', '_self');
    //}

    //function buttonProduct() {
    //    window.open('https://www.kodasware.com/product', '_self');
    //}

    //function buttonLogin() {
    //    window.open('https://www.kodasware.com/login', '_self');
    //}

    return ( 
        <>
        <S.LanguageApp>
            <img src={flagLanguage()} alt=''></img>   
            <span>&nbsp;&nbsp;</span>
            <select onChange={handleChangeLanguage} value={language}>
                <option value="en-US">English  </option>
                <option value="es-ES">Español  </option>
                <option value="pt-BR">Português</option>
            </select>
            <span>&nbsp;&nbsp;</span>
        </S.LanguageApp>

        <br></br>
        <br></br>
        

        
        </>
     );

   }
   
export default Navbar;

// <S.NavItem><S.NavItemLink><a href="#contact">{i18n.t('menu.contact')}{ click ? null : <span>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>}</a></S.NavItemLink></S.NavItem>



/*
        <S.Nav>
            <S.NavbarContainer>
            
                <span style={{ width: '30px' }}>&nbsp;</span>
                <img src={logo} alt=''></img>

                <S.MobileIcon onClick={ handleClick }>
                    { click ? <FaTimes color={'#70AD47'} /> : <FaBars color={'#70AD47'} /> }
                </S.MobileIcon>

                <S.NavMenuContainer>
                    <S.NavMenu  click={click}>
                        <S.NavItem><S.NavItemLink click={click}><a href="#contact" onClick={buttonContact}>{i18n.t('menu.contact')}</a><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></S.NavItemLink></S.NavItem>
                        <S.NavItem><S.NavItemLink click={click}><a href="#application" onClick={buttonProduct}>{i18n.t('menu.application')}</a><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></S.NavItemLink></S.NavItem>
                        <S.NavItem><S.NavItemLink click={click}><a href="#login" onClick={buttonLogin}>{i18n.t('menu.login')}</a></S.NavItemLink></S.NavItem>
                    </S.NavMenu>
                 </S.NavMenuContainer>

            </S.NavbarContainer>
            
        </S.Nav>
*/