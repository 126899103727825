
import React                from 'react';
import * as S               from './styles';
import * as GV              from '../../global/globalVariables';
import { i18n }             from '../../translate/i18n';
import imageApp1            from '../../assets/app1.png';
import imageApp2            from '../../assets/app2.png';
import imageApp3            from '../../assets/app3.png';
import imageApp4            from '../../assets/app4.png';
import imageApp5            from '../../assets/app5.png';

function OthersApps({appName}) {

    function buttonDownload1() {
        window.open('https://kodasware.com/divorceguide', '_self');
    }
    
    function buttonDownload2() {
        window.open('https://kodasware.com/divorceplanning', '_self');
    }
        
    function buttonDownload3() {
        window.open('https://kodasware.com/personaldiary', '_self');
    }
      
    function buttonDownload4() {
        window.open('https://kodasware.com/lifeplan', '_self');
    }

    function buttonDownload5() {
        window.open('https://kodasware.com/passwordwallet', '_self');
    }

    return (

        <S.Container>
            
            { (appName === GV.APP_DIVORCE_GUIDE) &&
            <S.ParallaxContainer>

                <S.ParallaxPart1><S.MidiaButton onClick={buttonDownload1}><S.ContainerTechImage><img src={imageApp1} alt=''></img></S.ContainerTechImage></S.MidiaButton></S.ParallaxPart1>
                <S.ParallaxPart2>   
                    <S.TitleParallax>       {i18n.t('products.app1Title')}</S.TitleParallax>
                    <S.SubTitleParallax>    {i18n.t('products.app1SubTitle')}</S.SubTitleParallax>
                    <S.DescriptionParallax> {i18n.t('products.app1Description')}</S.DescriptionParallax>
                </S.ParallaxPart2>

            </S.ParallaxContainer>
            }
            
            { (appName === GV.APP_DIVORCE_PLANNING) &&
            <S.ParallaxContainer>

                <S.ParallaxPart1><S.MidiaButton onClick={buttonDownload2}><S.ContainerTechImage><img src={imageApp2} alt=''></img></S.ContainerTechImage></S.MidiaButton></S.ParallaxPart1>
                <S.ParallaxPart2>
                    <S.TitleParallax>       {i18n.t('products.app2Title')}</S.TitleParallax>
                    <S.SubTitleParallax>    {i18n.t('products.app2SubTitle')}</S.SubTitleParallax>
                    <S.DescriptionParallax> {i18n.t('products.app2Description')}</S.DescriptionParallax>
                </S.ParallaxPart2>

            </S.ParallaxContainer>
            }
            
            { (appName === GV.APP_PERSONAL_DIARY) &&
            <S.ParallaxContainer>

                <S.ParallaxPart1><S.MidiaButton onClick={buttonDownload3}><S.ContainerTechImage><img src={imageApp3} alt=''></img></S.ContainerTechImage></S.MidiaButton></S.ParallaxPart1>
                <S.ParallaxPart2>
                    <S.TitleParallax>       {i18n.t('products.app3Title')}</S.TitleParallax>
                    <S.SubTitleParallax>    {i18n.t('products.app3SubTitle')}</S.SubTitleParallax>
                    <S.DescriptionParallax> {i18n.t('products.app3Description')}</S.DescriptionParallax>
                </S.ParallaxPart2>
            </S.ParallaxContainer>
            }

            { (appName === GV.APP_LIFE_PLAN) &&
            <S.ParallaxContainer>

                <S.ParallaxPart1><S.MidiaButton onClick={buttonDownload4}><S.ContainerTechImage><img src={imageApp4} alt=''></img></S.ContainerTechImage></S.MidiaButton></S.ParallaxPart1>
                <S.ParallaxPart2>
                    <S.TitleParallax>       {i18n.t('products.app4Title')}</S.TitleParallax>
                    <S.SubTitleParallax>    {i18n.t('products.app4SubTitle')}</S.SubTitleParallax>
                    <S.DescriptionParallax> {i18n.t('products.app4Description')}</S.DescriptionParallax>
                </S.ParallaxPart2>
            </S.ParallaxContainer>
            }

            { (appName === GV.APP_PASSWORD_WALLET) &&
            <S.ParallaxContainer>

                <S.ParallaxPart1><S.MidiaButton onClick={buttonDownload5}><S.ContainerTechImage><img src={imageApp5} alt=''></img></S.ContainerTechImage></S.MidiaButton></S.ParallaxPart1>
                <S.ParallaxPart2>
                    <S.TitleParallax>       {i18n.t('products.app5Title')}</S.TitleParallax>
                    <S.SubTitleParallax>    {i18n.t('products.app5SubTitle')}</S.SubTitleParallax>
                    <S.DescriptionParallax> {i18n.t('products.app5Description')}</S.DescriptionParallax>
                </S.ParallaxPart2>
            </S.ParallaxContainer>
            }
        </S.Container>
    )
}

export default OthersApps;