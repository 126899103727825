import React    from 'react';
import * as S   from './style';

function AppTermsAndConditions() {

return (
    <S.Container>
        <S.Form>
        <>
        <h2>Terms &amp; Conditions</h2>
        <p>
        <a href="#spanish">Haga clic aquí para el idioma Español.</a><br></br><br></br>
        <a href="#portuguese">Clique aqui para o idioma Português.</a></p><br></br>

        <p>
        When purchasing this product, the terms described here will automatically apply to you - Therefore, read this document carefully before making the purchase.
        You are not authorized to copy or modify any part of the <b>DIVORCE GUIDE FOR MEN</b>, hereinafter referred to as the GUIDE. 
        You are not permitted to attempt to extract the source code of the GUIDE, nor should you attempt to translate the GUIDE into other languages or make derivative versions. 
        The GUIDE and the copyright and other intellectual property rights relating thereto belong to KODASWARE. 
        <p>
        The content of this GUIDE is based on personal opinions acquired empirically or obtained through public and government documents made available in the existing media. 
        The information accessible in this GUIDE is not guaranteed to be accurate, complete or up-to-date. It is the user's responsibility to follow or not their suggestions, 
        advice and opinions, and should always look for a specialized professional to solve their doubts and problems in a legal and satisfactory manner. 
        </p>
        The terms and information contained in the GUIDE may vary and be different depending on where it is purchased and/or used. 
        It is not our responsibility to translate or adapt the GUIDE for each country or region of use. 
        KODASWARE is not responsible for any loss, direct or indirect, that you experience as a result of any functionality or information provided by the GUIDE. 
        The GUIDE needs your device to have an internet connection for it to work. 
        We can also stop providing the GUIDE access and we may terminate its use at any time, without notice of termination to you. 
        Unless we tell you otherwise, upon any termination, the rights and licenses granted to you in these terms will end.
        <p><u>The GUIDE will only work on the device where it is installed, if you want to use it on another device, you will have to purchase it again.</u></p>
        </p><p>&nbsp;</p>
        <p><strong>Conditions</strong></p><p>
        These terms and conditions are effective from 01-Sep-2022</p> 
        <p><strong>Contact us</strong></p><p>
        If you have any questions or suggestions about our Terms and Conditions, do not hesitate to contact us at info@kodasware.com.</p>
        </>
        <br></br>
        <br></br>
        <br></br>
        <>
        <div id="spanish"></div>
        <h2>Términos &amp; Condiciones</h2>
        <p>
        Al adquirir este producto, se le aplicarán automáticamente los términos aquí descritos - Por lo tanto, lea atentamente este documento antes de realizar la compra. 
        No está autorizado a copiar o modificar ninguna parte de la <b>GUÍA DE DIVORCIO PARA HOMBRES</b>, en lo sucesivo, la GUÍA. 
        No se le permite intentar extraer el código fuente de la GUÍA, ni debe intentar traducir la GUÍA a otros idiomas o hacer versiones derivadas. 
        La GUÍA y los derechos de autor y otros derechos de propiedad intelectual relacionados con la misma pertenecen a KODASWARE. 
        <p>
        El contenido de esta GUÍA se basa en opiniones personales adquiridas empíricamente o obtenidas a través de documentos públicos y gubernamentales puestos a disposición en los medios de comunicación existentes. 
        No se garantiza que la información accesible en esta GUÍA sea precisa, completa o actualizada. 
        Es responsabilidad del usuario seguir o no sus sugerencias, consejos y opiniones, debiendo buscar siempre un profesional especializado para resolver sus dudas y problemas de forma legal y satisfactoria. 
        </p>
        Los términos y la información contenida en la GUÍA pueden variar y ser diferentes según el lugar de compra y/o uso. 
        No es nuestra responsabilidad traducir o adaptar la GUÍA para cada país o región de uso.
        KODASWARE no es responsable de ninguna pérdida, directa o indirecta, que experimente como resultado de cualquier funcionalidad o información proporcionada por la GUÍA.
        La GUÍA necesita que su dispositivo tenga una conexión a Internet para que funcione. 
        También podemos dejar de brindar acceso a la GUÍA y finalizar su uso en cualquier momento, sin previo aviso de finalización.
        A menos que le indiquemos lo contrario, en caso de rescisión, los derechos y licencias otorgados a usted en estos términos terminarán. 
        <p><u>La GUÍA solo funcionará en el dispositivo donde esté instalada, si desea utilizarla en otro dispositivo, deberá adquirirla nuevamente.</u></p>
        </p><p>&nbsp;</p>
        <p><strong>Condiciones</strong></p><p>
        Estos términos y condiciones son efectivos a partir del 01-sep-2022
        </p><p><strong>Contáctenos</strong></p><p>
        Si tiene alguna pregunta o sugerencia acerca de nuestro
        Términos y Condiciones, no dude en contactarnos
        en info@kodasware.com.</p>
        </>
        <br></br>
        <br></br>
        <br></br>
        <>
        <div id="portuguese"></div>
        <h2>Termos &amp; Condições</h2>
        <p>
        Ao adquirir esse produto, os termos aqui descritos serão automaticamente aplicados a você - Portanto, leia atentamente esse documento antes de efetuar à compra. 
        Você não é autorizado a copiar ou modificar qualquer parte do <b>GUIA DO DIVORCIO PARA HOMENS</b>, denominado doravante neste documento apenas como GUIA. 
        Você não tem permissão para tentar  extrair o código-fonte do GUIA, e você também não deve tentar traduzir o GUIA para outros idiomas ou fazer versões derivadas. 
        O GUIA e os direitos autorais e outros direitos de propriedade intelectual relacionados a ele, pertencem à KODASWARE.  
        <p>
        O conteúdo deste GUIA é baseado em opiniões pessoais adquiridas empiricamente ou obtidos por meio de documentos públicos e governamentais disponibilizados na mídia existente.  
        Não é garantido que as informações acessíveis neste GUIA sejam precisas, completas ou atualizadas. 
        É de responsabilidade do usuário seguir ou não suas sugestões, conselhos e opiniões, devendo sempre procurar um profissional especializado para solucionar suas dúvidas e problemas de forma legal e de maneira satisfatória. 
        </p>
        Os termos e as informações contidas no GUIA podem variar e serem diferentes de acordo com o local onde for adquirido e/ou utilizado. 
        Não é de nossa responsabilidade traduzir ou adaptar o GUIA para cada país ou região de utilização.  
        A KODASWARE não se responsabiliza por qualquer perda, direta ou indireta, que você experimente como resultado de qualquer funcionalidade ou informação fornecida pelo GUIA.  
        O GUIA necessita que seu dispositivo tenha uma conexão de internet para seu funcionamento.
        Podemos também parar de fornecer o acesso ao GUIA e encerrar seu uso a qualquer momento, sem aviso de rescisão a você. 
        A menos que lhe digamos o contrário, após qualquer rescisão, os direitos e licenças concedidos a você nestes termos terminarão.
        <p><u>O GUIA irá funcionar apenas no dispositivo onde ele for instalado, caso você queira utilizá-lo em outro dispositivo, terá que efetuar uma nova aquisição.</u></p> 
        </p><p>&nbsp;</p>
        <p><strong>Condições</strong></p><p>
        Estes termos e condições estão em vigor a partir de 01-Sep-2022
         </p><p><strong>Fale conosco</strong></p><p>
        Se você tiver alguma dúvida ou sugestão sobre nosso Termos e Condições, não hesite em contactar-nos em info@kodasware.com.</p>
        </>
        <br></br>
        <br></br>
        <br></br>
        </S.Form>
    </S.Container>
)
}

export default AppTermsAndConditions;