import { useState, useEffect } from "react";

function Counter( {interval, value1, value2, value3} ) {
    
 const [count, setCount] = useState(calculate);

  useEffect(() => {
    setTimeout(() => {
      setCount((count) => count + 1);
    }, interval);
  });

  function calculate() {

    var date2      = new Date();
    var date1      = new Date(date2.getFullYear(), 0, 1)
    var difference = date2.getTime() - date1.getTime();
    var days       = Math.trunc(difference / (1000 * 3600 * 24));
    var hours      = date2.getHours();
    var minutes    = date2.getMinutes();

    return Math.trunc( (days * value1) + (hours * value2) + (minutes * value3) );
  }

  return (
    <>&nbsp;{count}&nbsp;</>
  );
}

export default Counter;