import React, { useState }               from 'react';   
import * as S                            from './styles';
import * as GV                           from '../../global/globalVariables';
import * as G                            from '../../global/globalStyles';
import { useNavigate }                   from 'react-router-dom';
import { Navigate }                      from 'react-router-dom';
import { useDispatch }                   from 'react-redux'
import { useSelector }                   from 'react-redux';
import { userSiteLogin, userSiteLogout } from '../../store/userSlice'
import { i18n }                          from '../../translate/i18n';
import Navbar                            from '../../components/Navbar';
import Trailer                           from '../../components/Trailer';
import validate                          from '../../model/Signon';

function Login() {

    const [formPswd, setFormPswd]   = useState(null);
    const [formMail, setFormMail]   = useState(null);
    const [formEmsg, setFormEmsg]   = useState(null);
    const [error1,   setError1]     = useState(false);
    const [error2,   setError2]     = useState(false);
    const dispatch                  = useDispatch();
    const navigate                  = useNavigate();

    React.useEffect(() => {
        window.scrollTo(0, 0);
        window.localStorage.setItem(GV.I18N_STORAGE_KEY, "en-US");
    }, []);

    async function buttonLogin() {

        dispatch(userSiteLogout());
        setError1(false);
        setError2(false);

        let result = await validate.ValidateName(formMail)

        if ( result != null )
            {
            document.getElementById('formMail').focus();
            setFormEmsg(result)
            setError1(true);
            return;
            }

         result = await validate.validatePassword(formPswd)

        if ( result != null )
            {
            document.getElementById('formPswd').focus();
            setFormEmsg(result)
            setError2(true);
            return;
            }
       
        dispatch(userSiteLogin());
        navigate('/home', {replace: true});
     }

    return (

        <S.Container>

            { useSelector(state => state.userLogged.value) > 0 ? <Navigate to="/home" replace={true} /> : null }

            <Navbar></Navbar>

            <S.Form> 
            
            <S.FormTitle>
                <span>LOGIN</span>
            </S.FormTitle>

            <S.InputData error={error1}>
                <span>{i18n.t('literals.email')}</span>
                <input type="email"     id="formMail" maxlength="60" onChange={ e => setFormMail(e.target.value)} value={formMail}></input>
            </S.InputData>

            <S.InputData error={error2}>
                <span>{i18n.t('literals.password')}</span>
                <input type="password"  id="formPswd" maxlength="12" onChange={ e => setFormPswd(e.target.value)} value={formPswd}></input>
            </S.InputData>

            <S.SignButton>
                <button type="button" onClick={buttonLogin}>{i18n.t('buttons.login')}&nbsp;</button>
            </S.SignButton>

            <S.FormMessage><span>{formEmsg}</span></S.FormMessage>

            </S.Form>

            <G.ContainerSpace></G.ContainerSpace>

            <Trailer></Trailer>
      </S.Container>
    )
}

export default Login;