
import React, { useEffect, useState } from 'react';   
import * as S       from './styles';
import guide01      from '../../../../view/DivorceGuide/Guide/Images/guide01.png';
import guide02      from '../../../../view/DivorceGuide/Guide/Images/guide02.png';
import guide03      from '../../../../view/DivorceGuide/Guide/Images/guide03.png';
import guide04      from '../../../../view/DivorceGuide/Guide/Images/guide04.png';
import util         from '../../../../Utilities/Util';

function GuidePart1() {
    
    const [access, setAccess] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)
        setAccess( util.checkGuideAccess() );
    }, [])

    return (
        <S.Container>
        { ( access )
            ?
            <>
            <S.Form> 

            <S.FormTitle>
			<span>PARTE1</span><br></br><br></br><br></br><span>Características personales</span><br></br><br></br>            
			</S.FormTitle>

            <S.FormText>
                <span>

				¿Qué hace que una persona quiera o pida el divorcio? Es una respuesta personal que solo los involucrados pueden responder a esta pregunta.<br></br><br></br>
                Sin embargo, cuando hay amenaza o consumación de violencia física, psíquica o social por parte de uno de los cónyuges contra el otro, o contra los hijos, ya no es una elección, es asunto de la policía. La persona amenazada o agredida debe acudir a las autoridades competentes a la mayor brevedad y poner fin a esta destructiva y peligrosa relación. Pero cuando no existe tal violencia, los motivos pueden ser justos y correctos, así como injustos y sin sentido, haciéndolo doloroso para todos. <br></br><br></br>
                Cuando la pareja tiene respeto mutuo y está de común acuerdo, el divorcio realmente se lleva a cabo sin problemas y se convierte en una operación judicial de relativamente corto plazo. Sin embargo, cuando el camino elegido es otro, se convierte en un procedimiento futuro con consecuencias inciertas.<br></br><br></br>
                Muchos factores influirán y contribuirán a la elección del camino que se utilizará en un proceso de divorcio. Puede depender de la modalidad que se adopte en el régimen matrimonial, si hay hijos menores o dependientes, número de bienes, competencia del abogado, y principalmente de las características personales de aquellos involucrados como su carácter, género, estado emocional, finanzas, etc.<br></br><br></br>
                Por lo tanto, el divorcio es un evento que por estos factores y características, puede ser una operación tranquila o algo muy costoso, devastador y con un futuro incierto.
                <br></br><br></br><br></br><br></br>
                <h2>Carácter</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide01} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

				Es un conjunto de cualidades y defectos de una persona que determinarán su conducta, comportamiento y la forma en que actúa y reacciona ante las situaciones.<br></br><br></br>
                Las personas deshonestas que no muestran firmeza de principios y actitudes se clasifican como <b><u>PERSONA DE MAL CARÁCTER</u></b>, a diferencia de las denominadas como <b><u>PERSONA DE CARÁCTER</u> </b>, que no están motivados por mentiras y/o intereses personales, sino por firmeza moral y actitudes de alto valor.<br></br><br></br>
                Si uno de los cónyuges o ambos tienen mal carácter, el divorcio será sin duda un procedimiento doloroso y destructivo con consecuencias negativas para los involucrados y sus hijos. Un divorcio hostil pone a las partes bajo una enorme tensión emocional, donde se activa el instinto de supervivencia. Cuando eso sucede, todo se convierte en una amenaza y tiene una importancia poco realista. Cualquier cosa puede ser motivo de discusión y discordia, ya que de los beneficios y ventajas adquiridas dependerá la supervivencia futura. El carácter es el equilibrio que puede imponer límites y finalizar esta disputa de manera racional, honesta, realista y sobre todo ventajosa para todos. Pero cuando el carácter es bajo en uno de ellos, todo está permitido para ganar ese concurso y obtener tantas ventajas y beneficios como sea posible.<br></br><br></br>
                Las personas con las que convivimos durante años pueden convertirse en verdugos destructivos sin piedad, consideración, respeto o gratitud. Tu objetivo solo será la destrucción de tu oponente. Todo lo bueno que pasó en la relación será denigrado y adulterado. Todo será borrado y olvidado. Ningún sacrificio o acto noble realizado en el pasado será considerado por la justicia o la parte con mayor poder. La verdad y los hechos serán corrompidos y retirados del proceso. Todo este escenario puede inflamarse aún más según el defensor elegido. Hay estudios que demuestran que el <b><u>70% DE LOS DEFENSORES</u></b> tienen el mismo carácter que sus contratistas. Esto indica que existe una posibilidad muy significativa de que la parte mentirosa tenga un defensor del mismo carácter y personalidad. La producción de pruebas falsas y incriminatorias, así como la presencia de personas que darán falso testimonio, puede ser una terrible realidad.<br></br><br></br>
                Creer que las personas de alto carácter tienen ventaja sobre otras de menor carácter es una ilusión destructiva, y no tiene correspondencia con la realidad, solo en libros y producciones cinematográficas esto sucede. En el mundo real, la justicia no tiene ni la capacidad ni la competencia para evaluar la nobleza o la bondad de las personas. Muy por el contrario, se aferra a los informes recibidos, muchas veces inflamados con mentiras y difamaciones. Generalmente, desconfía de las personas benévolas y honestas, prefiriendo creer en su maldad y deshonestidad. Esto sucede porque lamentablemente estamos rodeados de sucesos y personas sin límites, escrúpulos o carácter. Por lo tanto, cuando una persona se presenta como de altos principios y nobles actitudes, parece ser un mentiroso o un estafador, a menos que pueda probar lo contrario de este pensamiento con pruebas incontestables. Al final, el divorcio muestra el verdadero carácter de las personas involucradas.<br></br><br></br>
                <b><i>El carácter es un factor determinante en el resultado de un proceso de divorcio, más importante que cualquier otro que pueda existir.</i></b>

                <br></br><br></br><br></br><br></br>
                <h2>Estado emocional</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide02} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

				Al contrario de lo que la gente cree no somos seres racionales somos seres emocionales. Todas nuestras elecciones incluso aquellas que parecen lógicas y racionales están impulsadas por nuestras emociones. No hay excepción a esta regla según la ciencia que estudia el funcionamiento del cerebro humano. <br></br><br></br>
                El divorcio es un hecho doloroso en la vida de cualquier persona, independientemente de cómo se lleve a cabo, representa el fin de una relación, sueños y proyectos idealizados por la pareja. El sentimiento presente en el divorcio puede ser similar al <b><u>LUTO</u></b> que representa una profunda tristeza por la pérdida de alguien. Quien solicita el fin de la unión pasa por un torbellino de sentimientos, muchas veces con el sentimiento de culpa por la situación establecida. Lo mismo ocurre con la persona que recibe el pedido, pero en este caso el sentimiento resultante es de enfado o rechazo. Pocos divorcios son amistosos y consensuales, pero cuando ocurren también están ligados a emociones como la pérdida y la frustración. <br></br><br></br>
                El período previo a la solicitud de divorcio suele estar rodeado de emociones y situaciones agitadas y negativas. Nadie emocionalmente equilibrado solicita un divorcio sin que estos eventos hayan ocurrido previamente. Es un período que suele ir acompañado de discusiones, irrespetos y mentiras entre las partes. En algunos casos, uno de ellos incluso menciona, sugiere o amenaza abiertamente el divorcio durante este período de conflicto mutuo. El miedo es un sentimiento mediador en esta etapa. Es un sentimiento paralizante que interrumpe las acciones y deseos de los involucrados. El miedo al futuro, a lo que será de su vida y la de sus hijos, muchas veces puede posponer las decisiones de la pareja o de uno de ellos. <br></br><br></br>
                Por eso, muchas veces se mantiene una situación desagradable con la esperanza de que se pueda modificar o para resguardar a los niños de cualquier daño para ellos. También es una fase muy peligrosa, donde los sentimientos pueden inflamarse y así permitir que uno de ellos actúe de forma irreflexiva y irracional, especialmente cuando se siente amenazado o acorralado de alguna manera en sus derechos y vida futura. Es una etapa conflictiva, no debe utilizarse para exponer las heridas y resentimientos reprimidos, a menos que sea para salvar o discutir sobre la relación en un intento de buscar soluciones positivas a la situación. Esto sólo debe hacerse si hay diálogo y respeto entre las partes. De lo contrario, podría desencadenar nuevos desacuerdos y hostilidades, lo que conduciría a una mayor disminución de la armonía familiar que aún puede existir.<br></br><br></br>
                Además, es muy probable que los involucrados en esta situación reciban sugerencias y consejos de personas ajenas a la relación, como familiares y amigos. Esto también es peligroso y no se recomienda en absoluto. Generalmente, son malos consejeros, principalmente porque no se ven perjudicados ni sufren las consecuencias de un divorcio. Suelen iluminar la situación y decir lo que el partido quiere escuchar. También es muy común que el partido desprecie las opiniones contrarias y acepte sólo las que están de acuerdo con lo que está sintiendo, pensando o con lo que ya está íntimamente decidido. <br></br><br></br>
                En raras ocasiones, dicho asesoramiento beneficia a la persona o pareja que ya se encuentra en una situación desarmonía. Es preferible buscar el consejo de personas que hayan pasado por esto o de profesionales en este campo, como psicólogos y terapeutas especialistas en relaciones. <br></br><br></br>
                Hoy en día muchas personas padecen algún <b><u>TIPO DE TRASTORNO MENTAL</u></b> y utilizan medicación para controlarlo. Es una situación que afecta el juicio y la parte emocional de estas personas. A muchos les resulta difícil tomar decisiones o entender claramente su situación dentro del matrimonio o de su propia vida. No saben lo que quieren y sus ideas y acciones siempre están cambiando de acuerdo a su estado emocional y eventos externos que no pueden interpretar clara y objetivamente. Tienden a convertir un escenario pacífico o una situación ordinaria en algo terrible y sin resolver. Son personas susceptibles a opiniones y influencias externas. <br></br><br></br>
                Generalmente negativa y motivada por los intereses individuales y financieros de otras personas, como amigos celosos o abogados. No miden las consecuencias de sus actos y acaban utilizando el divorcio como medio de escape de una situación desagradable para ellos que muchas veces no existe o fue creada por ellos mismos. Hacen las cosas por impulso, sin medir las consecuencias, generando arrepentimiento y consecuencias desagradables para todos. A menos que haya violencia o agresión, el divorcio no debe acelerarse por impulso. Debe ser un acto reflexivo basado en un objetivo real que cambie la vida. <br></br><br></br>
                Otro punto interesante y común es el hecho de que se desea el divorcio por la existencia de nuevas relaciones amorosas. Esto sucede por la quiebra de la relación o por diferentes motivos que no se pueden explicar de manera simple y genérica. Pero es una realidad presente y generalmente irreversible. Cuando esto sucede, inmediatamente la parte contraria se convierte en el hazmerreír y en un impedimento para la felicidad. Se vuelve descartable y cualquier acto de amor, bondad o gratitud realizado en el pasado es automáticamente olvidado o despreciado. Nadie es de nadie, todos tenemos derecho a enamorarnos de otras personas y cambiar nuestro destino. Sin embargo, no debemos hacerlo de manera irresponsable, especialmente cuando hay otras personas involucradas. Esto es <u><b>EXTREMADAMENTE PELIGROSO</b></u>, no sabemos cómo reaccionarán estas personas, ni siquiera ellos lo saben. Las noticias están llenas de trágicos ejemplos de personas que perdieron el control por razones inútiles y, a menudo, sin sentido. No es posible determinar si la otra parte lo merecía o si es víctima de las circunstancias, pero es recomendable terminar una relación con calma y cordialidad. Si hay niños de por medio, esto se vuelve fundamental, principalmente porque la otra parte siempre estará directa o indirectamente vinculada a ellos. Es una situación delicada y complicada y no debe llevarse a cabo con impaciencia. <br></br><br></br>
                El divorcio no es tan rápido como le dicen a la gente. Lamentablemente la justicia es lenta y burocrática, y provocar peleas y insatisfacción con la otra parte no ayuda en nada, al contrario, prolongará aún más la decisión del juez. Desde el momento en que se solicita el proceso, las personas involucradas ya no tienen ningún compromiso ni deberes conyugales, pudiendo experimentar otra relación sin ningún problema legal. Ella simplemente no puede unirse legalmente con su nueva pareja mientras no finalice el proceso y se cambie su estado civil. <br></br><br></br>
                Existen otros motivos emocionales responsables de activar la separación de una pareja. En todos ellos el factor emocional estará presente y controlará las acciones de los participantes. Es necesario comprender las emociones presentes en esta decisión para que sea posible controlarlas al máximo y así posibilitar la realización de un divorcio coherente dentro de una realidad tangible.
                
                <br></br><br></br><br></br><br></br>
                <h2>Estado Financiero</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide03} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

				Los divorcios amistosos son más rápidos y económicos, pero también hay que pagarlos. No creas en la justicia gratuita. Nada es gratis. Todo cuesta algo de dinero para las personas involucradas. Intentar llevar a cabo un divorcio hostil sin dinero es <u><b>SUICIDIO FINANCIERO</b></u>. 
                Generalmente, en estos casos, los ánimos son elevados y el estado emocional presente perjudica la correcta toma de decisiones de las partes involucradas. 
                Por lo general, uno o ambos quieren tener razón en sus afirmaciones y hacer todo lo posible para que sucedan. El valor y los costos involucrados dejan de ser importantes y el orgullo prevalece sobre la razón. Es el momento ideal para que profesionales sin escrúpulos ganen dinero. 
                Basta con inflamar los ánimos con promesas poco realistas que en su mayor parte no se cumplirán. Cuantos más procesos haya, mayor será la ganancia económica de estos profesionales. Además, la justicia está toda fragmentada. No es posible en un mismo proceso discutir múltiples puntos insólitos. Se necesita uno para definir el reparto de bienes, otro para definir el monto de la pensión, otro para definir la custodia de los hijos, etc. Cuanto mayor sea el número de problemas y requisitos, mayor será el número de casos a juzgar, con el pago de costas y abogados. Apelar las decisiones promulgadas también desperdicia recursos financieros. Además, el juez aún tiene el derecho y la facultad de decidir quién pagará las costas de cada proceso existente. <br></br><br></br>
                Es un sistema deficiente y injusto, no esperes sentido común ni comprensión y sobre todo compasión por tu situación personal y económica.<br></br><br></br>
                <b>Siempre alguien tendrá que pagar las facturas</b>. <br></br><br></br>
                De la misma forma que existe una industria matrimonial, existe una <u><b>INDUSTRIA DE LITIGIOS</b></u>, que fomenta la disputa entre las partes, ofreciendo ganancias fáciles y rápidas que en la mayoría de los casos nunca ocurrirá. Desafortunadamente, las personas se dan cuenta de esto demasiado tarde, generalmente después de que sus recursos financieros se hayan agotado con procesos y demandas inútiles. Y de nuevo vemos la importancia del dinero en este entorno. Es la materia prima que hace posible cualquier proceso. Las personas deben comprender que el tiempo y el dinero gastados en procedimientos legales nunca se pueden recuperar. Es común que las personas pierdan sus activos, muchas veces ganados con trabajo duro en este tipo de procedimiento.<br></br><br></br>
                <u><b>Es preferible hacer un MAL ACUERDO</b></u> que gastar tiempo y dinero en procesos agotadores y dolorosos que no producen el retorno o resultado esperado. Un divorcio litigioso es algo terrible y muy costoso, hay que hacer todo lo posible para evitarlo. Cuando esto no sea posible, no debe realizarse con ira, dolor o sentimientos inflamados. Los ánimos ya estarán muy altos con el final de la boda. Por lo que, en la medida de lo posible, habrá que hacerlo con frialdad y objetividad para conseguir resultados realistas. <br></br><br></br>
                También es muy importante aclarar y enfatizar que lamentablemente la justicia puede ser <b>ilógica, injusta y irracional</b>. En muchos casos el dinero tendrá que aparecer por decreto. De nada servirá acreditar mediante documentos fehacientes que no tienes dinero, estás desempleado o tienes otro tipo de impedimento válido. El dinero tendrá que aparecer mágicamente. Esto es una locura, pero lamentablemente estamos rodeados de personas así que determinan nuestras vidas sin ningún tipo de empatía o consideración. Cuando utilice la justicia para redimir sus derechos, descubrirá cómo funciona el mundo real y es posible que tenga la oportunidad de conocer personas con este nivel de irracionalidad. La equidad y los resultados pueden ser bastante diferentes de lo esperado o prometido. Por lo tanto, no malgastes tu tiempo y especialmente tus recursos económicos con la justicia. En la medida de lo posible resuelva sus diferencias sin tener que activarlo. 
                Usa estos recursos para reconstruir tu vida y no trates de demostrar que tienes razón. Úsalo para disfrutar de momentos con tus hijos y amigos, para viajar, estudiar, etc.<br></br><br></br>
                En muchas ocasiones es <b><u>MEJOR SER FELIZ QUE TENER LA RAZÓN</u></b>. No te dejes dominar por tu <b><u>EGO</u></b>, no gastes tu dinero en eso, en algo que no funciona.
                
				<br></br><br></br><br></br><br></br>
                <h2>Género</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide04} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                <i><b>El género de los cónyuges define el futuro y el resultado de un proceso de divorcio.</b></i> <br></br><br></br>
                Los hombres son los mayores perdedores en cualquier afirmación que hagan. Las leyes solo favorecen a las mujeres que probablemente no perderán ningún caso, a menos que existan distorsiones muy graves en sus acciones y actitudes que no puedan defenderse o justificarse. Cualquiera que afirme lo contrario miente. <br></br><br></br>
                Es incuestionable y sucede en la mayoría de los países con cultura occidental. Son muchas las razones que justifican esta realidad y sitúan a la mujer en una posición privilegiada y intocable en relación con el hombre. La principal es la maternidad. Esta habilidad biológica despoja automáticamente a las mujeres de cualquier imagen de criminalidad o inmoralidad. Convirtiendo a cualquiera de ellas en un ser dulce y emotivo, que cuando delinque lo hace por emoción, victimizando incluso a niños y acompañantes en estas acciones. Debido a esto, terminan recibiendo sentencias más leves y menores que los hombres que cometen delitos similares. <br></br><br></br>
                Las estadísticas muestran que son los máximos responsables de la violencia doméstica, pero los delitos de este tipo rara vez se denuncian en los medios, salvo cuando no se sigue el patrón de la normalidad y son excesivamente brutales. Aun así, estos delitos son tratados como pasionales y motivados por las actitudes del acompañante victimizado, lo que hace que el delito sea justificado y aplaudido por el público en general. Esta misma mentalidad se aplica al divorcio y sus resultados. <br></br><br></br>
                Siempre se etiqueta al hombre como inepto y violento, no pudiendo educar a sus hijos, dejándolo solo con la obligación de mantenerlos y nada más. Cualquier cosa que haya hecho en beneficio de sus hijos y pareja habrá sido una mera obligación y no se utilizará para evaluar su carácter o capacidad. Es por eso que las mujeres son los clientes preferidos de los abogados. La probabilidad de victoria es cercana al 100% y pueden inflamar los procesos con informes y reclamos absurdos sin necesidad de probarlos, porque saben que serán aceptados o al menos tomados en cuenta por los tribunales. A diferencia de los hombres, quienes rara vez ven satisfechas sus demandas, incluso con evidencia abrumadora a su favor.<br></br><br></br>
                Los hombres están siendo aniquilados física, moral, mental y legalmente. Es un proceso irreversible y acentuado. Las leyes ya han rebasado los límites de la equidad, la isonomía y el sentido común, y se siguen elaborando y implementando nuevas. Es un proceso mundial que no se limita a nuestro país ni a nuestras leyes. <br></br><br></br>
                Actualmente, <b><u>UNA MUJER PUEDE DESTRUIR LA VIDA DE UN HOMBRE CON EXTREMA FACILIDAD</u></b>, solo con una denuncia en su contra. Sin tener que demostrar absolutamente nada, basta con tu palabra. Después de todo, los hombres son sinvergüenzas, agresivos y violadores potenciales, por lo que deben ser tratados como delincuentes.
                Si logra demostrar su inocencia, nada le pasará a la mujer que lo denunció, a pesar de haber sido arrestado, humillado, pasado por todo tipo de vergüenzas y su vida arruinada. Sólo puede interponer una demanda por daño moral contra ella, que después de mucho tiempo resultará en algo insatisfactorio, sin jamás compensar ni minimizar toda la violencia sufrida y las marcas permanentes dejadas. El número de denuncias falsas presentadas contra hombres se ha elevado a miles en tan solo un año. Este fenómeno está ocurriendo en casi todos los países occidentales. Las acusaciones resultaron ser falsas porque los acusados ​​de alguna manera lograron demostrar su inocencia, pero esto lleva a un interrogatorio. Aquellos que no pudieron hacer esto, ¿son todos realmente culpables? El sistema ha perdido toda racionalidad y privilegia sólo a un lado. Muchos hombres todavía no se dan cuenta de esto. Creen que estas leyes no los afectarán y que fueron diseñadas para combatir el crimen y que no deben temer a la justicia.<br></br><br></br>
                <i><b>Terrible error, cuando las leyes son totalitarias no hay justicia. </b></i><br></br><br></br>
                Una parte importante de la población masculina ya se ha dado cuenta de esta situación y está abandonando las relaciones con mujeres para poder sobrevivir. Esta actitud está siendo llevada a cabo, incluso por personas más jóvenes, que ya se han dado cuenta de esta realidad y no quieren sufrir los mismos abusos y violencias que se cometen contra sus padres o personas que conocen. El problema es tan grave que las autoridades ya están preocupadas por las consecuencias futuras de todo ello. Pero no creas que habrá un retroceso en este sistema. Por el contrario, se están planificando nuevas leyes dirigidas a este grupo de personas. <br></br><br></br>
                Próximamente, no tener relaciones o estar soltero será considerado un delito contra la mujer.
                <br></br><br></br>
                
                </span>
            </S.FormText>

            </S.Form>
            </>
            :
            <>
            <br></br>
            <br></br>
            <h3>&nbsp;</h3>
            <br></br>
            </>    }  
        </S.Container>
    )
}

export default GuidePart1;