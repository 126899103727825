import React        from 'react';
import * as S       from './styles';
import { i18n }     from '../../translate/i18n';
import imageEmail   from '../../assets/email.png';

function Mail() {
   
    function buttonMail() {

        const subject = i18n.t('literals.subject');
        window.open(`mailto:info@kodasware.com?subject=${subject}`)
    }

    return (

        <S.Container>

            <S.ContainerMailImage><img src={imageEmail} alt=''></img></S.ContainerMailImage>
            <S.MailLink onClick={buttonMail}><span>info@kodasware.com</span></S.MailLink>

        </S.Container>

    );
    }
    
export default Mail;