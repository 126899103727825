
import React, { useEffect, useState } from 'react';   
import * as S       from './styles';
import guide35      from '../../../../view/DivorceGuide/Guide/Images/guide35.png';
import guide36      from '../../../../view/DivorceGuide/Guide/Images/guide36.png';
import guide37      from '../../../../view/DivorceGuide/Guide/Images/guide37.png';
import mgtow        from '../../../../view/DivorceGuide/Guide/Images/mgtow.png';
import util         from '../../../../Utilities/Util';

function GuidePart8() {

    const [access, setAccess] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)
        setAccess( util.checkGuideAccess() );
    }, [])
    
    return (
        <S.Container>
        { ( access )
            ?
            <>
            <S.Form> 

            <S.FormTitle>
                <span>PARTE8</span><br></br><br></br><br></br><span>Tú no estás solo</span><br></br><br></br>
            </S.FormTitle>

            <S.FormText>
                <span>

                Construir una familia es algo noble y divino. Es el deseo y el sueño de muchos hombres y mujeres. <br></br><br></br>
                La familia es el pilar central de una sociedad. <br></br><br></br>
                Es a través de ella que se desarrolla la salud física y mental de las personas. <br></br><br></br>
                Ser educado en una familia estructurada y feliz aumenta significativamente las posibilidades de éxito en la formación de adultos con altos valores éticos y morales, que colaborarán y influirán en el desarrollo de una sociedad con estas mismas características. <br></br><br></br>
                Actualmente, la sociedad moderna vive una crisis de valores sin precedentes, donde la tecnología disponible potencia y agrava aún más esta situación. Los ideales humanos están siendo olvidados y se están convirtiendo en sueños románticos del pasado. <br></br><br></br>
                A partir de las revoluciones culturales y sociales que tuvieron lugar en el siglo pasado, estos valores comenzaron a ser combatidos y modificados. Las crisis económicas colaboraron intensamente con estos cambios, produciendo una ruptura en el núcleo familiar establecido. <br></br><br></br>
                Los hombres ya no podían mantener a sus familias con sus ingresos. Esta tarea tuvo que ser compartida con sus mujeres quienes, en consecuencia, dejaron de ocuparse exclusivamente de sus hijos y comenzaron a incorporarse al mercado laboral, predominantemente masculino. <br></br><br></br>
                Uno de los primeros resultados de esta acción fue el aumento de la oferta de mano de obra disponible, contribuyendo aún más a la disminución de los salarios y al problema descrito. <br></br><br></br>
                Las mujeres no podían ocupar todos los puestos disponibles y debían ser protegidas y favorecidas con trabajos más ligeros y seguros, tendencia que se mantiene hasta la actualidad donde los hombres ocupan los trabajos más pesados, sucios y peligrosos. <br></br><br></br>
                Las mujeres comenzaron a invertir en sus carreras profesionales para ganar mejores salarios y obtener independencia financiera. Para ello tuvieron que priorizar sus carreras en detrimento del cuidado de sus hijos. <br></br><br></br>
                Comenzaron a ser educados por niñeras, mucamas, escuela, televisión, internet y demás. Estos nuevos elementos se hicieron responsables del desarrollo físico, psicológico y moral de estos nuevos individuos. <br></br><br></br>
                La ausencia del padre y de la madre se compensaba con consumos materiales de todo tipo de inutilidad, como juguetes, videojuegos, ropa de diseño, etc. <br></br><br></br>
                Pronto, tener algo se volvió más importante que ser algo. Esto produjo y produce personas tóxicas, débiles y sin valores morales, que a su vez terminan produciendo niños con las mismas carencias.<br></br><br></br>                
                
                <i><b>Es un ciclo repetitivo sin fin que terminará solo con el colapso de la sociedad</b></i>.<br></br><br></br>
                A pesar de este trágico escenario, muchos hombres aún sueñan con las ideas iniciales descritas, dedicando la mayor parte de su vida a las familias que con tanto esfuerzo lograron construir. <br></br><br></br>
                Trabajan incesantemente para pagar sus cuentas, sin tener tiempo de darse cuenta de la realidad que les rodea, gastando su poco tiempo libre en cosas inútiles y sin valor. <br></br><br></br>
                Las mujeres, contrariamente a lo descrito, no aceptan compartir sus ingresos por igual, ni aceptan en ningún caso apoyar a ningún hombre. <br></br><br></br>
                A diferencia de los hombres, sus ingresos son desconocidos y pertenecen exclusivamente a ellas y no al núcleo familiar como les sucede a ellos. <br></br><br></br>
                Raros son los casos contrarios a esta afirmación, que cuando se dan son de mujeres de generaciones anteriores o de alto carácter, heredados de una crianza familiar tradicional. <br></br><br></br>
                Los eventos de las últimas décadas han elevado y expuesto la hipergamia femenina y también han producido numerosas leyes y beneficios exclusivos para las necesidades y el bienestar de las mujeres. <br></br><br></br>
                Hoy en día, los hombres son fácilmente descartados y transformados rápidamente en proveedores eternos o de largo plazo, incluso los hombres solteros y sin hijos ya pueden ser capturados y obligados a suplir las necesidades de las mujeres. <br></br><br></br>
                Aun siendo desechados y aniquilados en procesos de divorcio o en la aplicación de leyes injustas y sexistas, muchos siguen dormidos ante el escenario denunciado. <br></br><br></br>
                En casos más graves, buscan el suicidio o producen actos de violencia que agravan aún más su situación, favoreciendo a las mujeres y empeorando la imagen de los hombres en la sociedad. <br></br><br></br>
                Otros, en cambio, se victimizan, dificultando su recuperación, creyéndose incluso responsables del fin de la unión. <br></br><br></br>
                De manera ingenua y romántica, algunos buscan nuevas relaciones en un intento de remediar sus frustraciones y rehacer sus vidas, pero terminan teniendo el mismo final trágico o peor que el anterior. <br></br><br></br>
                Finalmente, acaban despertando a la realidad que les rodea y intentando averiguar quién es el verdadero responsable de sus problemas. <br></br><br></br>
                Descubren que no están solos y que nada de lo que sucede es fruto del azar o malas decisiones. <br></br><br></br>
                Que sean víctimas de acciones planificadas provenientes de grupos malvados y inescrupulosos o de personas que se benefician de esta situación y de la degradación social en la que estamos insertos.                
                <br></br><br></br><br></br>
                <h2>Feminismo</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide35} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                Es un movimiento político, social y filosófico que lucha por liberar a las mujeres de la opresión masculina existente, a través de la creación de leyes y normas que garanticen derechos y oportunidades en todos los sectores de la sociedad. <br></br><br></br>
                Durante la historia humana, muchas mujeres han sufrido abusos y injusticias. <br></br><br></br>
                Sin embargo, muchos de estos delitos no ocurrieron exclusivamente por su género, sino por la maldad y la ignorancia humana existente en ambos sexos. <br></br><br></br>
                Si usáramos el género como causa de sufrimiento o injusticia, los hombres estarían al frente y nunca serían superados. <br></br><br></br>
                Es cierto que muchas mujeres han luchado, sufrido y realizado grandes cosas, no por su género, sino por sus propios méritos, ya sean intelectuales, artísticos, profesionales o de cualquier otra índole, pero todos adquiridos con trabajo, esfuerzo y dedicación. <br></br><br></br>
                Desde el siglo anterior hasta la actualidad, una enorme cantidad de literatura disponible busca con fuerza demostrar que las mujeres son despreciadas, maltratadas, despenalizadas y oprimidas por los hombres. <br></br><br></br>
                De hecho, desde el Paleolítico, las mujeres han sido protegidas y apoyadas por ellos. <br></br><br></br>
                Ellos fueron los que tuvieron que caminar durante días en un ambiente hostil, lleno de depredadores y innumerables peligros detrás de la caza que proporcionaba las grasas y proteínas necesarias para la supervivencia de la comunidad, mientras ellas se mantenían a salvo recolectando semillas y cuidando a sus hijos. . <br></br><br></br>
                Fue una vida dura y difícil para ambos, pero aun así tenían una gran ventaja sobre sus compañeros. Incluso cuando la comunidad fue atacada y conquistada por grupos rivales, todavía tenían la opción de unirse a los vencedores para sobrevivir, mientras que a los hombres solo les quedaba la muerte o la esclavitud que inevitablemente conduciría al mismo final. <br></br><br></br>
                Esta característica se ha repetido a lo largo de la evolución humana. A las mujeres siempre se les ha ahorrado la necesidad de hacer la guerra, realizar el servicio militar o realizar trabajos pesados ​​y peligrosos, independientemente de la cultura, la región geográfica o el período histórico. <br></br><br></br>
                Esta ventaja persiste hasta el día de hoy. Tienen prioridad de salvación en casos de accidentes o tragedias. Son los primeros en ser liberados en situaciones donde hay rehenes. En estos mismos casos, todavía hay hombres que se ofrecen como voluntarios para reemplazarlas, mientras que lo contrario nunca es cierto. <br></br><br></br>
                Todo el sufrimiento y la opresión sufrida por la mujer en la historia es infinitamente menor que todo el sufrimiento y la injusticia sufrida por el hombre. <br></br><br></br>
                Además, fueron los propios hombres quienes liberaron a las mujeres de estos períodos de opresión, muchos de los cuales dieron su vida para cumplir con esta tarea. <br></br><br></br>                
                
                Las mujeres desempeñaron un papel importante, pero fueron sobre todo los hombres quienes lucharon y murieron para construir nuestra civilización y siguen siendo ellos quienes la mantienen en funcionamiento.<br></br><br></br>
                Actualmente, las mujeres utilizan el período de estancamiento político y estancamiento militar para subyugar y arrancar a los hombres el mayor número posible de derechos y beneficios, ya sea directamente através de las leyes sexistas que les aplican o indirectamente a través de las ayudas que otorga el Estado que se nutre de los impuestos recaudados en la sociedad.<br></br><br></br>
                Las feministas queremos todo y un poco más. Lo importante es subyugar a los hombres y transformarlos en proveedores y servidores de sus deseos y necesidades. <br></br><br></br>
                No quieren cumplir con sus deberes y roles en la sociedad. Lo consideran una imposición del patriarcado. Quieren hacer lo que quieren, tener derechos sin obligación, libertad sin responsabilidad, poder cometer barbaridades sin castigo, no dar esos mismos derechos a los hombres. <br></br><br></br>
                Cualquiera que se rebele o intente sustraerse a su papel de proveedor será perseguido y destruido por la justicia o por la propia sociedad. Lo mismo ocurre con las mujeres que se declaran en contra de este movimiento, serán rechazadas, perseguidas y aniquiladas. <br></br><br></br>
                Es un movimiento contradictorio, irracional y incoherente en sus discursos y reivindicaciones, siendo un instrumento político planeado y utilizado por grupos perversos y codiciosos. Este es un golpe a la sociedad establecida. <br></br><br></br>
                Es un proceso irreversible de proporciones globales, que no puede ser interrumpido ni siquiera mitigado. De manera constante, gradual y imperceptible, se crean nuevas leyes y procedimientos para beneficiar aún más a las mujeres. <br></br><br></br>
                Muchos hombres ni siquiera tienen la capacidad de darse cuenta o reaccionar contra ella. Fueron educados para proteger y servir a las mujeres, considerando válidos y legítimos sus reclamos. Sacrifican su vida personal, profesional, financiera y incluso física en su favor.
                <br></br><br></br>
                Siendo un movimiento más contrario a la moral judeocristiana, uno de los responsables del desmantelamiento de la familia tradicional y de los valores morales ganados con esfuerzo que construyeron nuestra civilización. <br></br><br></br>
                Es una ofensa contra todos los hombres y mujeres que lucharon y murieron para salvaguardar a sus familias y los derechos y valores ganados con tanto esfuerzo que dieron forma a todo lo que somos hoy. <br></br><br></br>

                <br></br><br></br><br></br>
                <h2>La medicina amarga</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide36} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>
                
                El movimiento descrito es como un virus que se extiende y contamina a toda la sociedad, especialmente a los más jóvenes y menos ilustrados. <br></br><br></br>
                Sus consecuencias son terribles y dolorosas, afectando y aniquilando principalmente a la parte masculina de la población. <br></br><br></br>
                Las mujeres se convierten en portadoras sanas de esta enfermedad, contaminando y corrompiendo a otros individuos que entran en contacto con sus ideas y reivindicaciones. <br></br><br></br>
                También acarrean terribles consecuencias que afectan principalmente a su cerebro, tales como: irracionalidad, intolerancia, incoherencia, etc.
                Sin embargo, nada está más alterado que su hipergamia y hedonia que se elevan a niveles fuera de escala. <br></br><br></br>
                Como una enfermedad epidémica, debe ser combatida.
                Sin embargo, no existe ningún medicamento o tratamiento que pueda curarla o combatirla, salvo a través de la prevención. <br></br><br></br>
                Es una medicina amarga, sobre todo para las personas más jóvenes que no realizan procedimientos preventivos, siendo fácilmente captada y aniquilada por las mujeres afectadas por esta enfermedad. <br></br><br></br>
                Desafortunadamente, todavía hay mucho desacuerdo en la sociedad sobre esta enfermedad y sus consecuencias.
                Muchos creen que es algo pasajero y sin importancia, otros piensan que no se verán afectados y que no necesitan preocuparse, otros ni siquiera saben que el problema existe. <br></br><br></br>
                Estas divisiones y desconocimiento solo favorecen su proliferación que ya ha alcanzado niveles de pandemia.
                
                <br></br><br></br><br></br>
                <b>MRA - Movimiento por los derechos de los hombres</b>
                <br></br><br></br>

                El movimiento Activismo por los Derechos de los Hombres (Men’s Rights Activism – MRA) es un encuentro de personas y instituciones que abordan problemas sociales y acciones gubernamentales que afectan exclusivamente a los hombres. <br></br><br></br>
                Su objetivo es concienciar y alertar a la sociedad sobre los abusos y discriminaciones que sufren los hombres a través de leyes sexistas. <br></br><br></br>
                Muchos críticos y académicos lo describen como un movimiento opuesto al feminismo. Muchos de ellas también describen este movimiento como misógino y basado en la pérdida de privilegios no de derechos. <br></br><br></br>
                Es un movimiento fuertemente criticado y silenciado por los medios de comunicación y instituciones gubernamentales. La mayoría de los temas planteados están mal vistos y no son discutidos por los medios. <br></br><br></br>
                Durante años, los hombres fueron vistos como opresores de las mujeres, por lo que movimientos como este no se toman en serio y el público en general los ve con recelo. <br></br><br></br>
                Es una fuente de información para quienes buscan la verdad de los hechos. Muchas mentiras, abusos y atrocidades cometidas contra los hombres son reveladas por esta institución. <br></br><br></br>
                Sin embargo, durante su tiempo de existencia este movimiento, así como sus asociados, <b>NO produjeron ganancias significativas en beneficio de los hombres</b>.
                
                <br></br><br></br><br></br>
                <b>BLUE PILL - Píldora Azul</b>
                <br></br><br></br>

                No es un movimiento, es una clasificación. <br></br><br></br>
                Hace referencia a la película “Matrix” donde el héroe de la historia debe elegir entre dos pastillas, una azul y otra roja. Al tomar el primero, volvería a su antigua vida creyendo en las mismas cosas de siempre, mientras que el otro le mostraría cómo funcionaba el mundo real cambiando su vida para siempre. <br></br><br></br>
                La mayoría de los hombres en nuestra sociedad se clasifican como “Píldora Azul” o Blue Pill. <br></br><br></br>
                Desconocen la verdad de los hechos y creen lo que dicen los medios y instituciones. Todo para ellos es correcto y funciona correctamente. <br></br><br></br>
                No se preocupan por aprender o evolucionar, no tienen objetivos establecidos ni definidos. Actúan por impulso y dejan que las cosas sucedan. Aceptan convivir con mujeres tóxicas y opresivas. Aceptan las leyes y creen en la buena fe de las instituciones. <br></br><br></br>
                No hacen preguntas, ni tienen sentido crítico, repiten lo que hicieron sus padres y lo que sigue haciendo la sociedad.
                
                <br></br><br></br><br></br>
                <b>PURPLE PILL - Píldora Morada</b>
                <br></br><br></br>

                Está un rango por encima del anterior.
                También encarna una gran parte de la sociedad masculina. <br></br><br></br>
                Son los hombres tradicionalistas y conservadores. <br></br><br></br>
                Muchos de ellos ya participan en el movimiento por los derechos de los hombres y son conscientes de los problemas relacionados con el matrimonio y el divorcio.
                Son conscientes de que las cosas están mal, que las leyes no funcionan y que son sexistas.
                Saben que muchas mujeres son tóxicas y abusivas. <br></br><br></br>
                A pesar de este conocimiento, todavía creen en las instituciones y en que pueden arreglarse.
                Creen que encontrarán mujeres diferentes y especiales.
                Piensan que si hacen un uso correcto y preventivo de las leyes, estarán protegidos. <br></br><br></br>
                Fortaleza de hombres tipo "alfa", que creen en sus talentos y capacidades y que nunca serán traicionados o engañados por mujeres. <br></br><br></br>
                Los hombres que están en esta clasificación quieren seguir jugando con las viejas reglas, no se han adaptado a la nueva realidad. <br></br><br></br>
                Se están saboteando a sí mismos, es cuestión de tiempo que sean perjudicados por una mujer o una ley sexista.
                
                <br></br><br></br><br></br>
                <b>RED PILL - Píldora Roja</b>
                <br></br><br></br>

                Representa el extremo opuesto de la clasificación azul. <br></br><br></br>
                Solo un selecto grupo de hombres forman parte de ella. <br></br><br></br>
                Saben que todo anda mal y que las instituciones están en bancarrota y sin posibilidad de recuperación. <br></br><br></br>
                Saben que la justicia no funciona y que las leyes solo favorecen a las mujeres. <br></br><br></br>
                Que se han vuelto tóxicos y peligrosos, por lo que ya no están atrapados en visiones románticas de las relaciones. <br></br><br></br>
                Ya no quieren arriesgarse ni ser dañados. Están dispuestos a adaptarse a esta nueva realidad para poder sobrevivir. <br></br><br></br>
                A pesar de este conocimiento y actitud, muchos todavía se sienten atraídos por las mujeres y pueden ser fácilmente seducidos y engañados por ellas, cometiendo en consecuencia los mismos errores y siendo capturados por el sistema.
                
                <br></br><br></br><br></br>
                <b>MGTOW - Hombres que siguen su propio camino</b>
                <br></br><br></br>

                <table width="100%">
                <tbody>
                <tr>
                <td width="30%">Acrónimo inglés de <u>M</u>en <u>G</u>oing <u>T</u>heir <u>O</u>wn <u>W</u>ay, : hombres siguiendo su propio camino.<br></br><br></br>
                No es un movimiento social ni político, es una filosofía de vida. </td>
                <td width="70%"><S.FormImage><img src={mgtow} alt=""></img></S.FormImage></td>
                </tr>
                </tbody>
                </table>

                <br></br><br></br>
                No tienen personas, grupos o instituciones que los representen. No reclaman nada, ni quieren cambiar nada. Están dispersos, no se reúnen en clubes, ferias o congresos.<br></br><br></br>
                Son personas comunes y corrientes que no declaran a sus amigos o familiares la opción de vida que han adoptado. <br></br><br></br>
                Son personas conscientes de los problemas existentes, son los "REDPILL" que se han adaptado a esta nueva realidad. <br></br><br></br>
                Saben que las instituciones están rotas y no perderán el tiempo tratando de arreglarlas. <br></br><br></br>
                Y principalmente, no darán oportunidad de ser capturados por este sistema retrógrado y criminal. <br></br><br></br>
                No alimentarán la industria del divorcio, la manutención de los hijos, la violencia doméstica, las redes de abogados y otros mecanismos creados para destruir y esclavizar a los hombres y solo beneficiar a las mujeres y grupos afines. <br></br><br></br>
                Hay tres reglas fundamentales que no se pueden romper:
                <br></br><br></br>
                <b>
                1. NO CASARSE;<br></br>
                2. NO VIVIR JUNTOS;<br></br>
                3. NO DEJES EMBARAZADA A NINGUNA MUJER.
                </b>

                <br></br><br></br>
                Las mujeres ya no seducen ni engañan a los MGTOW. <br></br><br></br>
                No dependen de las mujeres, pueden vivir su vida sin ellas.<br></br><br></br>
                Lo más que hacen es tener relaciones superficiales o esporádicas con alguna de ellas, pero siempre respetando las tres reglas básicas y no dándoles la oportunidad de caer en algún tipo de trampa. <br></br><br></br>
                Saben que los hombres han sido condicionados desde la infancia para criar y mantener una familia. <br></br><br></br>
                Romper este condicionamiento es difícil y doloroso, pero extremadamente necesario para sobrevivir hoy. <br></br><br></br>
                Todas las instituciones están corrompidas y ya no pueden ayudarte. <br></br><br></br>
                Sus derechos ya no serán respetados y serán fácilmente aniquilados por las mujeres. Incluso los hombres ya no tienen derechos, solo obligaciones. <br></br><br></br>
                Entonces, no hay razón para formar una familia, construir un patrimonio o tener hijos. Eventualmente, perderán todo lo que han construido, incluidos sus hijos quienes serán alienados y tóxicos para ellos. <br></br><br></br>
                Es una locura alimentar este sistema, especialmente con vuestras vidas. Además, muchos hombres van más allá. Quieren alimentar todo el sistema político y social creado con lo mínimo posible y preferiblemente con nada. <br></br><br></br>
                Muchos comienzan a llevar una vida sencilla, sin dejarse seducir por el consumo, acumulando sólo lo necesario para su supervivencia y comodidad. Algunos se alejan de los grandes centros, produciendo su propio alimento, protección y energía, reduciendo aún más su contribución financiera al sistema descrito. <br></br><br></br>
                Si este estilo de vida fuera adoptado por el 20% de los hombres, provocaría un colapso en la sociedad, habría una caída sin precedentes en los ingresos fiscales. Las instituciones no podrían sobrevivir, ni apoyar a las mujeres con los beneficios que garantiza la ley. <br></br><br></br>
                Incluso, aunque no sea su objetivo ni su intención, <u>es el único proceso existente que puede combatir el feminismo</u>. <br></br><br></br>
                Pronto, este movimiento comenzó a ser perseguido y ridiculizado por mujeres y instituciones. Los hombres que siguen este movimiento son tildados de fracasados, afeminados y cualquier adjetivo que los degrade. <br></br><br></br>
                Sin embargo, como explicó, no es un movimiento social ni político con líderes y reglamentos. <br></br><br></br>
                Es una postura personal adoptada por un hombre, siendo una filosofía de vida seguida por él. Quienes siguen este estilo de vida no se identifican, ni comentan su vida con nadie, por lo tanto no pueden ser perseguidos. <br></br><br></br>
                Los MGTOW están fuera de este juego loco y sucio que se ha instalado en la sociedad y que destruye a los hombres y las familias que han construido.
                <br></br><br></br>

                Es una filosofía construida por niveles, a continuación se describen los principales y más importantes:
                <br></br><br></br>

                <table border="1" width="100%">
                <tbody>
                <tr><td bgcolor='#000000' width="20%"><b><font color="white">NIVEL 1</font></b></td><td width="80%">
                Sigue las tres reglas.<br></br>
                Rechazan las relaciones a largo plazo.<br></br>
                Relaciones basadas únicamente en el sexo.<br></br>
                <b>NO INTERACTÚES CON MADRES SOLTERAS</b>. (cuarta regla)<br></br>
                </td></tr>
                <tr><td bgcolor='#000000' width="20%"><b><font color="white">NIVEL 2</font></b></td><td width="80%">
                Rechazan las relaciones tanto a largo como a corto plazo.<br></br>
                Rechazan las interacciones con mujeres, incluido el sexo casual.<br></br>
                Mínimo contacto con las mujeres, simplemente cordial y profesional.<br></br>
                </td></tr>
                <tr><td bgcolor='#000000' width="20%"><b><font color="white">NIVEL3</font></b></td><td width="80%">
                Nivel 2 que comienza a realizar la desvinculación económica.<br></br>
                Buscan trabajos informales para evitar impuestos.<br></br>
                Intentan estar en el rango IR más bajo posible.<br></br>
                Empiezan a poner trabas al gasto estatal.<br></br>
                </td></tr>
                <tr><td bgcolor='#000000' width="20%"><b><font color="white">NIVEL 4 (GHOST)</font></b></td><td width="80%">
                Rechazan las interacciones con las mujeres y la sociedad.<br></br>
                Minimizan el contacto con las personas y los entornos urbanos.<br></br>
                Se independizan de la alimentación, la protección y la energía.<br></br>
                Pueden sobrevivir sin ayuda estatal.<br></br>
                No están influenciados por los conflictos sociales o la violencia urbana.<br></br>
                No se verán afectados por colapsos sociales, económicos o políticos que puedan ocurrir.<br></br>
                </td></tr>
                </tbody>
                </table>
                <br></br><br></br>

                <br></br><br></br><br></br>
                <h2>Fin del juego</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide37} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                El divorcio es un terrible y temible juego de guerra. Separa a las personas, aniquila el patrimonio, destruye vidas. Es un juego de cartas marcadas, donde ya se sabe el ganador. Y cada día que pasa sus reglas se vuelven más rígidas y injustas. <br></br><br></br>
                Es un fenómeno mundial <u><b>IRREVERSIBLE</b></u>. <br></br><br></br>
                En él, las familias se rompen y los hombres se aniquilan y se transforman en esclavos o sirvientes. Todo esto es triste, oscuro y horrible. La mayoría de la gente cree que esto es normal. Muchos están en un sueño profundo y perpetuo. No saben lo que sucede a su alrededor.<br></br><br></br>
                Se necesita mucho dolor y sufrimiento para despertar a la realidad. Aun así, muchos vuelven a dormir. La verdad no se acepta ni se desea. Es más fácil cerrar los ojos a todo. Cambiar las cosas requiere mucho trabajo y produce mucho dolor. Y nadie quiere eso. Es preferible extender esta situación el mayor tiempo posible para evitar esta aflicción. <br></br><br></br>
                Actualmente, nuestra sociedad se mueve por el dinero, el consumo, el individualismo, la mentira, el miedo, la violencia o cualquier otro valor degradante. Las instituciones no funcionan, porque están dirigidas por personas que siguen estos valores. Entonces, la verdad es algo inconveniente, que requiere trabajo, desperdicia tiempo, energía y nos recuerda lo que realmente debemos ser y hacer. <br></br><br></br>
                Y como se ha dicho, nadie quiere experimentar ninguna molestia. Es preferible el consuelo de la mentira y la ilusión. Es mejor creer que las cosas cambiarán, que la justicia funciona y prevalecerá, que el bien vencerá al mal, etc. <br></br><br></br>
                Pero todas estas son solo ilusiones creadas por nuestra mente que nos hacen evitar el sufrimiento a corto plazo. Y así prolongamos nuestros dolores con el fin de hacerlos más diluidos y llevaderos. <br></br><br></br>
                Además de eso, nuestros ojos también están cerrados debido a nuestras creencias. Estamos rodeados de mentiras. Hemos sido educados y condicionados para aceptarlas como verdades absolutas y incuestionables. De esta manera somos como ganado, siempre conducido por alguien, sin tener la capacidad de seguir nuestro propio camino. Ni siquiera sabemos que somos libres para hacerlo. Solo personas excepcionales o que han pasado por una situación de gran angustia o sufrimiento pueden despertar a esta realidad y darse cuenta de todo esto. <br></br><br></br>
                El divorcio tiene este poder. Es algo desgarrador y doloroso que hace que muchos hombres despierten. Sin embargo, a pesar del daño y dolor producido, algunos siguen apegados a sus viejas creencias y acaban cometiendo los mismos errores. Permaneciendo en un <u>bucle infinito</u> hasta que finalmente son aniquilados en cualquier otro proceso al que se sometan. Habiendo en ese momento sus creencias definitivamente sacudidas. <br></br><br></br>
                Cuando despiertan de este profundo sueño, descubren que las instituciones están en bancarrota. Que las leyes son injustas y sexistas. Que se gastan inmensos recursos para mantener sistemas que no funcionan. Se dan cuenta de que la gente es ignorante, deshonesta, cobarde y inmoral. Además de varios otros inadaptados que prueban que la sociedad actual es irrecuperable. <br></br><br></br>
                Hay muchas personas honestas, inteligentes, valientes, con altos valores morales, que son responsables directa o indirectamente de evitar el desmantelamiento de la sociedad. Sin embargo, no pueden evitar su colapso total, ni corregir los problemas y desviaciones morales que se acumulan. <br></br><br></br>
                Muchos de los que tienen los atributos descritos creen que pueden resolver este caos a través de la política, la economía o las nuevas tecnologías. Lamentablemente, estos elementos no hicieron más que <u>aumentar y facilitar</u> la situación descrita, principalmente la tecnología, que se convirtió en una poderosa arma de control y alienación. <br></br><br></br>
                Pronto todos estarán etiquetados. Toda la individualidad y privacidad restantes serán eliminadas. <br></br><br></br>
                Estas mismas personas también creen en el sentido común de los líderes y las instituciones. Que todo es cuestión de tiempo para que las virtudes humanas superen la degradación instalada. <br></br><br></br> 
                Terrible error, no hay precedentes en toda la historia. Ningún imperio, gobierno u organización se volvió justo, pacífico o virtuoso a través de la adquisición del sentido común por parte de sus componentes. Siempre colapsaban o eran destruidos antes de que surgiera uno nuevo en su lugar. Ningún dictador o líder dejó el cargo por caridad o altruismo. Murieron o fueron depuestos. Los que tienen el control de la situación no quieren cambios ni mejoras, a menos que sea para aumentar aún más su dominio y poder. <br></br><br></br>
                Por lo tanto, es factible decir que los niveles de locura, degradación y injusticia aumentarán cada vez más. Y dentro de este contexto, los hombres serán los más <u>perjudicados y perseguidos</u>. Tendrán que apoyar a las mujeres directa o indirectamente, tengan o no las condiciones físicas o económicas para hacerlo. Los recursos necesarios para esta tarea tendrán que aparecer por arte de magia, sea cual sea la situación del mercado laboral o el escenario político y financiero existente. <br></br><br></br>
                No habrá sentido común ni caridad, como no los hay hoy. Bajo ninguna circunstancia ningún político, legislador o persona de influencia se pronunciará o actuará en contra de cualquier ley o subsidio que beneficie a las mujeres y perjudique a los hombres. Aunque sea algo injusto, irracional o inmoral. <br></br><br></br>
                Permanecerán inertes sin hacer nada. Si alguien tiene el coraje o la audacia de intentarlo, será silenciado y aniquilado rápidamente. Ser tildado de misógino o peor aún, arruinar tu carrera política o profesional. <br></br><br></br>
                Por lo tanto, no hay forma de revertir este escenario, es inmutable. Siendo el movimiento feminista el gran triunfador de esta historia. Logró destruir valores familiares y sociales conquistados durante siglos. Seguirá creciendo y produciendo nuevas leyes, beneficios y reclamos injustos y dementes sin que nadie pueda detenerlo. <br></br><br></br>    
           
                <i><b>No hay, ni hará ninguna ley o programa que beneficie a los hombres.</b></i>. <br></br><br></br>
                Solo pueden denunciar los abusos y injusticias de las leyes vigentes, pero nada se hará para revertir esta situación. Incluso las mujeres que se oponen al movimiento feminista o que simpatizan con los problemas que enfrentan los hombres tampoco harán nada. Ninguna mujer renuncia a los beneficios existentes y futuros, no aceptan una relación con un hombre de clase o salario más bajos y mucho menos apoyar a nadie. <br></br><br></br>
                El discurso de la igualdad no es válido en el mundo real, solo sirve como argumento para lograr beneficios para las mujeres. Los hombres siempre son vistos como violentos, opresores y violadores potenciales. Como castigo deben proteger y apoyar a todas las mujeres de la sociedad. Cualquiera que intente escapar de este destino será severamente penalizado. <br></br><br></br>
                Además de todo lo descrito, las mujeres también se han vuelto extremadamente exigentes con los hombres. Sólo una pequeña minoría de ellos es capaz de alcanzar los altos niveles que exige la mujer actual. La gran mayoría de los hombres fueron dejados de lado, sirviendo solo como un posible <u>reemplazo</u> en caso de que no encuentren el tipo ideal o cuando ya no sean atractivos. Muchos hombres necesitados y solitarios aceptan mujeres de este tipo y terminan volviéndose aún más débiles y pasivos. <br></br><br></br>
                Otros, en cambio, no se relacionan seriamente con las mujeres por temor a ser atrapados y destruidos por leyes sexistas. Entonces, tenemos un escenario donde las personas ya no forman lazos afectivos duraderos, ni generan hijos. <br></br><br></br>
                Los niveles de embarazo están cayendo en todo el mundo, pronto alcanzarán niveles catastróficos. Muchas mujeres también están prefiriendo invertir en sus carreras profesionales, dejando de cumplir con su rol maternal. Cuando lo hacen, intentan sin éxito hacer malabarismos con todos sus objetivos al mismo tiempo. Culpan a los hombres de su fracaso, cansancio y falta de estructura. No quieren ser responsables por el desempeño de su familia. <br></br><br></br>
                El divorcio se convierte en una solución sencilla a este problema. Pueden mantener su nivel de vida y educar a sus hijos como deseen, sin que sus cónyuges les cobren ni presionen. <br></br><br></br>
                Al fin y al cabo, como ya se ha dicho exhaustivamente, los hijos pertenecen exclusivamente a las mujeres, pudiendo servirles de escudo o argumento para los más variados fines, principalmente el de <u>valorarlos</u> en la sociedad. <br></br><br></br>
                Las mujeres solteras, sin perspectivas de matrimonio, prefieren tener hijos a través de la inseminación artificial. Esto <u>agrega</u> valor a su lugar en la sociedad y genera individuos que los sostendrán en el futuro. En muchos países, mujeres como estas reciben varios beneficios del gobierno para educar a sus hijos, pagados a través de impuestos recaudados de hombres casados ​​y solteros. Es justo imaginar que en un futuro cercano se rompa el secreto de estas instituciones que realizan inseminaciones humanas, para pasar esta obligación a los padres biológicos. <br></br><br></br>
                Las mujeres se volvieron <u>tóxicas y opresivas</u> y los hombres se volvieron <u>débiles y pasivos</u>. Las instituciones se han vuelto criminales, malas y destructivas, no se pueden salvar ni corregir. <br></br><br></br>
                Actualmente, tenemos el conocimiento y la tecnología para vivir en un mundo rico y feliz. Sin embargo, seguimos siendo impulsados ​​por nuestros egos o por intereses oscuros. <br></br><br></br>
                Los hombres y las mujeres son diferentes y nunca pueden ser iguales, pero definitivamente son iguales en sueños y necesidades. Y pueden ser iguales en derechos y obligaciones. <br></br><br></br>
                Mientras exista un desequilibrio entre estas fuerzas y sobre todo en el carácter de las personas, seguiremos jugando a este juego sucio que destruye familias y produce personas solitarias, reprimidas y infelices, donde solo hay perdedores. <br></br><br></br><br></br> 
                
                <b>¿Qué debemos hacer?</b><br></br><br></br>
                Si también crees que algo anda mal. Que tus sueños y tu futuro se ven amenazados por el hecho de ser hombre, entonces: borra tus condicionamientos y despierta a la realidad. <br></br><br></br>
                Busca la verdad, estudia y sé con tu mente en el presente, pero planifica tu futuro. <br></br><br></br>
                Busca por tu salvación, por tu salud, protección y bienestar, por tu libertad personal y financiera. <br></br><br></br>
                No intentes recuperar o salvar instituciones fallidas, no pierdas tu tiempo ni tu energía en ello. <br></br><br></br>
                Haz todo esto en silencio. No te expongas, no quieras educar a los que no quieren aprender y <u><b>NUNCA</b></u> reveles tus planes.<br></br><br ></br>
                Ten fe, coraje y determinación.
                <br></br><br></br><br></br>

                En 1983, los cines proyectaron una película de ciencia ficción, donde un adolescente tiene acceso a una inteligencia artificial que controlaba todo el sistema militar estadounidense. <br></br><br></br>
                Pensando que solo está jugando un sofisticado juego de computadora, activa el protocolo ofensivo militar. Esto podría desencadenar una verdadera guerra nuclear, porque la computadora no conocía la diferencia entre lo real y lo virtual y había sido programada para jugar y ganar. <br></br><br></br>
                Era necesario enseñarle que no habría ganadores en el mundo real. La película termina con la computadora llegando a una conclusión fáctica.
                <br></br><br></br><br></br>
                Mientras existan desajustes en las leyes y en la sociedad actual, <u><b>NO</b></u> es posible garantizar un matrimonio saludable y feliz. Se fomentará y facilitará el divorcio, perjudicando siempre a sus miembros, particularmente a los hombres y sus hijos que serán los mayores perjudicados. <br></br><br></br>
                Por lo tanto, la única forma de ganar este juego es seguir la conclusión a la que llegó la computadora a partir de la película descrita:
                <br></br><br></br><br></br>
                <h2><b>Es un juego extraño, la única forma de ganar es no jugar.</b></h2>

                </span>
            </S.FormText>

            </S.Form>
            </>
            :
            <>
            <br></br>
            <br></br>
            <h3>&nbsp;</h3>
            <br></br>
            </>    }  
        </S.Container>
    )
}

export default GuidePart8;