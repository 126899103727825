import React                from 'react';
import * as S               from './styles';
import * as G               from '../../global/globalStyles';
import * as GV              from '../../global/globalVariables';
//import Trailer              from '../../components/Trailer';
import Navbar               from '../../components/Navbar';
import { i18n }             from '../../translate/i18n';
import Others               from '../../components/OthersApps'

//            <Trailer></Trailer>
function Product() {
    
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
      
    return (

        <S.Container>
            
            <Navbar></Navbar>

            <S.HeaderParallax>{i18n.t('titles.product')}</S.HeaderParallax>

            <Others appName={GV.APP_DIVORCE_GUIDE}></Others>  
            <Others appName={GV.APP_DIVORCE_PLANNING}></Others>  
            <Others appName={GV.APP_PERSONAL_DIARY}></Others>  
            <Others appName={GV.APP_LIFE_PLAN}></Others>  
            <Others appName={GV.APP_PASSWORD_WALLET}></Others>  

            <G.ContainerSpace></G.ContainerSpace>
            <G.ContainerSpace></G.ContainerSpace>
            


        </S.Container>
    )
}

export default Product;