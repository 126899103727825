import React        from 'react';
import KnowMore     from '../../../global/globalToKnowMore';
import * as GV      from '../../../global/globalVariables';

function ToKnowMore() {
    
    return (
        <>
        <KnowMore appName={GV.APP_PERSONAL_DIARY}></KnowMore>
        </>
    )
}

export default ToKnowMore;