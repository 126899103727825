import React   from 'react'
//import * as S  from './styles';

const htmlPt = (props) => (
 <>
            <br></br> <br></br> 
            A KODASWARE criou este site.<br></br> 
            Este serviço é prestado sem custo e destina-se ao uso como é.<br></br>
            Esta página é usada para informar aos visitantes sobre nossas políticas com a coleta, uso e divulgação de
            informações de nossos serviços.<br></br>
            Se você optar por usar nosso serviço, você concorda na coleta e uso de informações em relação a esta
            política. <br></br>
            As informações pessoais que coletamos são usadas para fornecer e melhorar o serviço. <br></br> 
            Não usaremos ou compartilharemos suas informações com
            qualquer pessoa, exceto conforme descrito nesta Política de Privacidade.<br></br><br></br>
            <p><strong>Coleta e uso de informações</strong></p> <br></br> 
            Atualmente, o site não coleta, armazena nem transmite qualquer informação pessoal ou financeira.<br></br> 
            <div> 
            O site usa serviços de terceiros que podem coletar informações usadas para identificá-lo.<br></br> 
            Link para a política de privacidade de provedores de serviços terceirizados usados
            pelo aplicativo:<br></br><br></br>  
            <ul>
            <li><a href="https://www.google.com/policies/privacy/" target="_blank" rel="noopener noreferrer">Google Play Services</a></li>
            <li><a href="https://support.google.com/admob/answer/6128543?hl=en" target="_blank" rel="noopener noreferrer">AdMob</a></li>
            </ul>
            </div> 
            <br></br> <br></br> 

            <p><strong>Cookies</strong></p>  
            Cookies são arquivos com uma pequena quantidade de dados que são comumente usados ​​como identificadores exclusivos anônimos. <br></br> 
            Estes são enviados  para o seu navegador a partir dos sites que você visita e são armazenados na memória interna do seu dispositivo.<br></br> 
     
            Este serviço não usa esses “cookies” explicitamente. <br></br> 
            No entanto, o aplicativo pode usar código de terceiros e bibliotecas que usam “cookies” para coletar informações e melhorar seus serviços.<br></br> 
            Você tem a opção de aceitar ou recusar esses cookies.<br></br>  
            Se você optar por recusar nossos cookies, talvez você não consiga usar alguns partes deste serviço.<br></br> <br></br> 
      
            <p><strong>Segurança</strong></p>
            Valorizamos sua confiança em nos fornecer suas informações pessoais.<br></br>  
            Portanto, estamos nos esforçando para usar comercialmente
            meios aceitáveis ​​de protegê-la. <br></br> 
            Lembre-se que nenhum método de transmissão pela internet, transmissão eletrônica ou metodo de 
            armazenamento é 100% seguro e confiável.<br></br>
            Por isso, não podemos garantir sua segurança absoluta.<br></br> <br></br> 

            <p><strong>Links para outros sites</strong></p>
            Este serviço pode conter links para outros sites.<br></br>  
            Se você clicar em um link de terceiros, você será direcionado para esse site. <br></br> 
            Lembre-se que esses sites externos não são operados por nós.<br></br> 
            Portanto, recomendamos fortemente que você revise a Política de privacidade desses sites. <br></br> 
            Nós não assumimos nenhuma responsabilidade pelo conteúdo, políticas de privacidade ou práticas de quaisquer sites de terceiros ou
            serviços. <br></br><br></br>

            <p><strong>Alterações nesta política de privacidade</strong></p>
            Podemos atualizar nossa Política de Privacidade de tempos em tempos. <br></br>
            Assim, você é aconselhado a rever esta página  periodicamente para quaisquer alterações.<br></br> 
            Notificaremos você sobre quaisquer alterações publicando a nova Política de Privacidade nesta página.<br></br>
            Esta política entra em vigor a partir de 01-Sep-2022<br></br><br></br>
            <p><strong>Fale conosco</strong></p>
            Se você tiver dúvidas ou sugestões sobre nossos
            Política de Privacidade, não hesite em nos contatar em info@kodasware.com.
            <br></br>
            <br></br>
            <br></br>
  </>
)
export default htmlPt