import React                from 'react';
import * as S               from '../../PersonalDiary/Home/styles';
import midia2               from '../../../assets/midia2.png';

function MenuGuideTrailer() {

    function buttonMidia2() {
        window.open('https://youtu.be/mJDRG4oDDJk', '_self');
    }

    //function linkSite() {
    //    window.open('https://www.kodasware.com/', '_self');
    //    return false;
    //}

    return ( 
        <>
        <S.MidiaContainer>
            <S.MidiaItem><S.MidiaButton onClick={buttonMidia2}><img src={midia2} alt=''></img></S.MidiaButton></S.MidiaItem>
        </S.MidiaContainer>

        <S.ContainerCopy><span>2023 &copy;&nbsp;KODASWARE</span></S.ContainerCopy>
        </>
     );
}

export default MenuGuideTrailer;
//        <S.ContainerCopy><span>2022 &copy;&nbsp;<a href="#" onClick={linkSite}>KODASWARE</a>&nbsp;&nbsp;All rights reserved.</span></S.ContainerCopy>