
import React, { useEffect, useState } from 'react';   
import * as S       from './styles';
import guide29      from '../../../../view/DivorceGuide/Guide/Images/guide29.png';
import guide30      from '../../../../view/DivorceGuide/Guide/Images/guide30.png';
import guide31      from '../../../../view/DivorceGuide/Guide/Images/guide31.png';
import util         from '../../../../Utilities/Util';

function GuidePart6() {
    
    const [access, setAccess] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)
        setAccess( util.checkGuideAccess() );
    }, [])

    return (
        <S.Container>
        { ( access )
            ?
            <>
            <S.Form> 

            <S.FormTitle>
                <span>PARTE6</span><br></br><br></br><br></br><span>Luchar y Defender</span><br></br><br></br>
            </S.FormTitle>

            <S.FormText>
                <span>

                La mayoría de la gente vive dormida. La realidad no es perceptible para la mayoría de ellos. Pasan la mayor parte de su tiempo trabajando y tratando de sobrevivir. No hay forma de detener este ritual, siempre hay un problema o un desafío que debe resolverse o superarse. El ritmo de vida es frenético y exigente, con pocos momentos de descanso. Generalmente, usado en cosas inútiles y sin valor. <br></br><br></br>
                Entonces, es difícil o casi imposible analizar y entender lo que está pasando. Solo en momentos de dolor o sufrimiento nos damos cuenta de todo lo malo y terrible que sucede a nuestro alrededor. Desafortunadamente, será demasiado tarde y cualquier reacción será prácticamente imposible. <br></br><br></br>
                Se nos están haciendo cosas terribles, pero estamos tan atrapados en nuestros rituales o problemas que no tenemos interés ni inclinación por saber acerca de ellos. Es más cómodo y pacífico permanecer en la mentira y la ignorancia. Y debido a esto el mal y la injusticia siempre ganan. <br></br><br></br>
                La sociedad y las instituciones están en quiebra. No podemos confiar en ellos cuando necesitamos ayuda o socorro. <br></br><br></br>
                La ignorancia y la injusticia ya han alcanzado niveles catastróficos y continúan creciendo. Quedarse dormido ya no es una opción, es un suicidio. Te guste o no serás golpeado, incluso si permaneces en tu zona de confort y crees que estás protegido. <br></br><br></br>
                El divorcio y las leyes sexistas son un ejemplo de este mal. Incluso si no tienes hijos o no estás casado esta locura y injusticia pueden golpearte en cualquier momento. <br></br><br></br>
                
                <i><b>Es necesario despertar a esta realidad, luchar y defenderse de ella</b></i>. 
                
                <br></br><br></br><br></br>
                <h2>Juego de Divorcio</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide29} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>
                

                El divorcio tiene un conjunto de pasos a seguir que definen su futuro y resultado. Se puede comparar con un juego de mesa, donde la posición que ocupa el jugador resulta en algo positivo o negativo en el partido que se está jugando.
                Es un juego que se puede definir por estrategia, suerte o una mezcla de ambos. <br></br><br></br>
                Sin embargo, no es un juego imparcial regido únicamente por estos atributos. Está diseñado y fabricado para que solo un lado sea el ganador. Ser hombre y poder ganar este juego es prácticamente imposible.
                Se necesita mucha suerte y ser un estratega excepcional. <br></br><br></br>
                <i><b>Querer jugar este juego sin tener una estrategia bien definida y elaborada es una invitación segura a la derrota personal y financiera.</b></i> <br></br><br></br>
                Muchos creen que las instituciones que manejan este juego son imparciales y nunca permitirían hacer trampa. Terrible y desastroso error. Uno de ellos siempre saldrá favorecido, mientras que el otro siempre estará en desventaja. <br></br><br></br>
                Además de la suerte y la estrategia, el <i>carácter</i> del jugador más favorecido puede definir el resultado positivo de este juego. <br></br><br></br>
                Sin embargo, como siempre uno de ellos lleva la ventaja, será difícil encontrar a alguien con deportividad que juegue el partido de forma amistosa, deseando sólo lo mejor para todos. <br></br><br></br>
                Por lo tanto, no debemos engañarnos con estas instituciones ni con la buena voluntad del oponente. De esta forma, es fundamental conocer el flujo de este juego para entender cómo funciona y saber jugarlo.
                <br></br><br></br><br></br>

                <h2>Guerra Asimétrica</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide30} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>
               
                Este tipo de guerra describe un enfrentamiento entre oponentes que tienen varias diferencias entre ellos, tales como: poder militar, recursos financieros, organización, objetivos, obediencia a las reglas, etc. En general, representa un conflicto entre una nación grande y otra mucho más pequeña. <br></br><br></br>
                El objetivo de la guerra asimétrica es el mismo que el de la guerra convencional, agotar al enemigo, imponerle la voluntad, desgastarlo, debilitarlo y inmovilizarlo. Incluso, la inmovilización del oponente siempre significa en una guerra, el comienzo de la victoria. <br></br><br></br>
                Actualmente, el divorcio se ha convertido en una guerra asimétrica, donde las leyes existentes y el sistema de apoyo brindan un lado de esta disputa con <u><b>POTENCIA DE FUEGO PRÁCTICAMENTE INVENCIBLE Y INSUPERABLE</b></u>. <br></br><br></br>
                Anteriormente comparamos el divorcio con un juego en el que uno de los oponentes tiene todo tipo de ventajas mientras que el otro solo tiene desventajas. Cada guerra es también un juego con la función de derrotar a tu oponente y dejarlo sin capacidad de reacción. Estas características están presentes en los procesos de divorcio actuales. <br></br><br></br>
                Los vencidos en estos procesos ven sus derechos violados, heridos, mutilados, esclavizados y dejados sin posibilidad de reacción, quedando eternamente subordinados al lado victorioso del conflicto. Y al final, todavía produce víctimas inocentes (niños), consecuencias y la destrucción masiva de recursos personales y financieros que existían antes de la disputa. <br></br><br></br>
                La similitud entre un conflicto entre naciones y lo que ocurre actualmente con los matrimonios que acaban en divorcio es innegable. Ambos producen destrucción y resultados devastadores. Donde lo importante es simplemente ganar bajo cualquier circunstancia y adquirir la mayor cantidad de beneficios posibles. No importan las consecuencias futuras y el rastro de destrucción dejado en el camino.
                <br></br><br></br><br></br>

                <b>PRINCIPIOS DE LA GUERRA</b>
                <br></br><br></br>
                La similitud entre un divorcio y un conflicto armado entre naciones es tan grande que es posible utilizar las mismas técnicas y principios de guerra adoptados por la mayoría de las fuerzas militares en el mundo actual.
                <br></br><br></br>

                <table border="1" width="100%">
                <tbody>
                <tr><td bgcolor='#C2E3B4' ><b>OBJETIVO</b></td><td width="70%">El objetivo es una meta que se pretende alcanzar y que determina la dirección del esfuerzo de toda actividad militar. <br></br>Debe ser factible y exigible.</td></tr>
                <tr><td bgcolor='#C2E3B4' ><b>OFENSIVA</b></td><td>Ofensiva significa tener iniciativa. Es necesario obtener resultados decisivos y preservar la libertad de acción. <br></br>Toma la iniciativa y impone tu voluntad al enemigo.</td></tr>
                <tr><td bgcolor='#C2E3B4' ><b>MASA</b></td><td>Es concentrar el potencial de combate en el lugar y tiempo decisivo. La correcta aplicación de este principio puede permitir que fuerzas numéricamente inferiores alcancen el éxito y la victoria en un momento crucial de la batalla.</td></tr>
                <tr><td bgcolor='#C2E3B4' ><b>SEGURIDAD</b></td><td>No permitir que el enemigo obtenga una ventaja inesperada. La seguridad es esencial para mantener el potencial de combate. Es negar al enemigo información sobre los planes y acciones de la fuerza. Es para evitar ataques por sorpresa y actos de sabotaje.</td></tr>
                <tr><td bgcolor='#C2E3B4' ><b>ECONOMÍA DE FUERZAS</b></td><td>Es un corolario del principio de masa. Es emplear cuidadosamente el potencial de combate para permitir que la misión se cumpla con un mínimo de desgaste de los medios disponibles. Es utilizar sólo lo esencial de los recursos en las acciones secundarias que se deben realizar.</td></tr>
                <tr><td bgcolor='#C2E3B4' ><b>MANIOBRA</b></td><td>Es colocar fuerzas de tal forma que el enemigo quede en desventaja. Es obtener una posición ventajosa que permita alcanzar el objetivo de forma que requiera el menor gasto posible de recursos.</td></tr>
                <tr><td bgcolor='#C2E3B4' ><b>UNIDAD DE MANDO</b></td><td>Es colocar las fuerzas disponibles bajo una línea de mando. La aplicación de todo el potencial disponible requiere una gran convergencia de esfuerzos, mediante la acción coordinada de todas las fuerzas existentes. Por eso, es fundamental que no haya dispersión de energías y responsabilidades. </td></tr>
                <tr><td bgcolor='#C2E3B4' ><b>SORPRESA</b></td><td>Significa golpear al enemigo inesperadamente. No es imprescindible coger completamente desprevenido al enemigo, pero basta con que no tenga el tiempo suficiente para reaccionar con eficacia.</td></tr>
                <tr><td bgcolor='#C2E3B4' ><b>SIMPLICIDAD</b></td><td>Los planes deben elaborarse, prepararse y ejecutarse con la máxima claridad y comprensión. Para reducir al máximo el margen de error, se debe tener especial cuidado en la forma en que se distribuyen las órdenes, deben ser claras y no deben dejar dudas en cuanto a su ejecución.</td></tr>
                </tbody>
                </table>

                <br></br><br></br><br></br>
                <b>PRINCIPIOS DE GUERRA EN EL DIVORCIO</b>
                <br></br><br></br>
                El escenario de divorcio existente, descrito anteriormente en lenguaje militar, demuestra su similitud con una lucha armada entre naciones enemigas. Por lo tanto, podemos usar los principios de guerra adoptados por las fuerzas militares actuales para la disputa del matrimonio y obtener una mejor comprensión y alguna ventaja en esta disputa.
                <br></br><br></br><br></br>

                <table border="1">
                <tbody>
                <tr><td bgcolor='#C2E3B4' ><b>OBJETIVO</b></td><td width="70%">El objetivo siempre será el divorcio. <br></br>Puede realizarse consensuada o litigiosamente. Es más aconsejable intentar llevarlo a cabo por la vía diplomática que por la lucha armada, aunque sea a través de un acuerdo que no es muy ventajoso, ya que la potencia de fuego otorgada a las mujeres es infinitamente superior a la de los hombres. Si esto no es posible, se deben hacer todos los preparativos necesarios para que la disputa sea favorable y equilibrada para su lado.</td></tr>
                <tr><td bgcolor='#C2E3B4' ><b>OFENSIVA</b></td><td>Es fundamental que el proceso de divorcio sea solicitado por el hombre. No sólo éste, sino todos los demás que puedan llegar a existir. La parte contraria no puede tener la oportunidad de atacar, siempre debe mantenerse a la defensiva.</td></tr>
                <tr><td bgcolor='#C2E3B4' ><b>MASSA</b></td><td>Todos los procesos deben estar bien diseñados con objetivos claros y realistas. No se debe desperdiciar tiempo y dinero en procesos que no son relevantes o que no ofrecen ningún tipo de ventaja. Concentre sus recursos solo en aquellos que tienen una posibilidad plausible de ganar y traer algún beneficio personal o financiero. </td></tr>
                <tr><td bgcolor='#C2E3B4' ><b>SEGURIDAD</b></td><td>El divorcio debe ser un proyecto elaborado con el mayor secreto posible. Perder el factor sorpresa o permitir que el enemigo conozca tus planes y potencia de fuego es fatal para cualquiera que quiera la victoria. Toda tu vida pasada será rastreada por la parte contraria. Cualquier información, aunque parezca irrelevante, puede ser utilizada como arma y adquirir proporciones inimaginables en el ámbito legal. No lleve a cabo ninguna conversación a través de correo electrónico, redes sociales, teléfono celular o cualquier otro medio de comunicación que pueda ser rastreado o interceptado. No pelees ni digas cosas irreflexivas, puedes estar siendo filmado, grabado o observado por personas dispuestas a testificar en tu contra. Cambie las contraseñas en todos sus dispositivos y mantenga todos los archivos seguros. No confíes ni compartas información con tus amigos, familiares o cualquier otra persona. </td></tr>
                <tr><td bgcolor='#C2E3B4' ><b>ECONOMÍA DE FUERZAS</b></td><td>Un proceso de divorcio litigioso puede ser largo y muy costoso. Es necesario disponer de una reserva económica para poder llevarlo a cabo con seguridad. Sin él, inevitablemente se quedará sin recursos y será derrotado rápidamente. Es fundamental planificar con antelación y no realizar trámites innecesarios basados ​​en el orgullo, la ira o la venganza. Debes conservar tus fuerzas y recursos tanto como sea posible. Tu principal objetivo será salir de esta situación con la menor cantidad de pérdidas para poder reconstruir tu vida. </td></tr>
                <tr><td bgcolor='#C2E3B4' ><b>MANIOBRA</b></td><td>El lugar donde se llevará a cabo el divorcio es en el juzgado de familia de justicia civil. <br></br>Es un terreno totalmente desfavorable para los hombres. Se necesita una buena planificación, un cuerpo sólido de evidencia y un gran abogado para tratar de ganar este lugar. Todas las maniobras que se realicen sobre él deben estar muy bien calculadas. Sin embargo, mover a tu oponente a un terreno donde tiene una movilidad inferior es una forma de reducir su potencial ganador. Se deben crear procesos secundarios contra la parte contraria para erosionar sus recursos y conducir a su derrota. Estos procesos serán juzgados en la justicia común, donde las leyes no sólo son favorables a una parte de la controversia. Rompiendo su moral, hegemonía y certeza de victoria.</td></tr>
                <tr><td bgcolor='#C2E3B4' ><b>UNIDAD DE MANDO</b></td><td>Necesitas conocer y confiar en el general que comandará tu ejército. Coordinará el ataque y la defensa de su territorio. Ese es el papel de un abogado en un caso de divorcio. Él es el elemento principal para lograr la victoria o la derrota. Para que haya ganancia o la menor pérdida posible, es necesario seleccionar un profesional altamente calificado. Desafortunadamente, son difíciles de localizar y costosos. No se debe poner el destino de un divorcio en manos de nadie, especialmente de alguien cuya competencia y desempeño se desconocen. Actualmente, las leyes son extremadamente severas y cerradas a los hombres. Muy poco o casi nada se puede hacer, convirtiendo a estos profesionales en despachadores de documentos legales.
                Por eso es importante encontrar y contratar profesionales de renombre en el mercado. Solo ellos son capaces de encontrar o elaborar soluciones inverosímiles dentro de este entorno cerrado y estático. Evite los profesionales sin experiencia, baratos o que lo hacen todo. Este es uno de los peores errores que cometen los hombres, tratando de ahorrar dinero con la persona que los defenderá. Deben tener en cuenta que ninguno de ellos sufrirá consecuencia alguna por las actuaciones realizadas y siempre serán retribuidos, independientemente de los resultados obtenidos. Por lo tanto, no hay mérito en la victoria, será solo otro proceso. A diferencia de aquellos que tienen una reputación y un nombre que conservar. Es cierto que incluso ellos no pueden garantizar una victoria, pero ciertamente pueden reducir la destrucción y el daño causado por esta guerra.</td></tr>
                <tr><td bgcolor='#C2E3B4' ><b>SORPRESA</b></td><td>Es un factor decisivo en una guerra. <br></br>En caso de divorcio es una de las pocas armas de que disponen los hombres. Es imperativo que hagan el primer ataque. Sin esta iniciativa, es casi seguro que la victoria no será posible. Es necesario desarrollar una planificación táctica con anticipación antes de iniciar el proceso de divorcio. Sin embargo, si resulta que el enemigo te va a atacar en cualquier momento, toda planificación debe olvidarse momentáneamente. Es fundamental hacer el primer movimiento de este proceso, aunque aún no estés preparado. El elemento sorpresa no se puede perder bajo ninguna circunstancia.</td></tr>
                <tr><td bgcolor='#C2E3B4' ><b>SIMPLICIDAD</b></td><td>La justicia utiliza un lenguaje que dificulta la comprensión de la gente común. Es función del abogado traducir y adaptar este vocabulario para que sea posible su comprensión y no queden dudas sobre el tema tratado. Es fundamental que todo lo solicitado o recibido sea documentado, entendido y confirmado. No puede haber movimientos desconocidos y no autorizados, ni del defensor ni del contratante. Todo debe ser claro y sin lugar a dudas o malas interpretaciones. Y de preferencia, todos los temas y decisiones deberán constar en medios electrónicos que puedan ser consultados, confirmados y autorizados.</td></tr>
                </tbody>
                </table>

                <br></br><br></br><br></br>
                <b>ESCENARIO DE GUERRA</b>
                <br></br><br></br><br></br>

                <table border="1" width="100%">
                <tbody>
                <tr><td bgcolor='#000000' width="30%"><b><font color="white">TIERRA</font></b></td><td width="70%" >Es el lugar donde se librará la batalla. En caso de divorcio, será en el juzgado de familia de justicia civil. Es el peor lugar posible para los hombres. Las posibilidades de ganar en este terreno son prácticamente nulas. Para que se produzca la victoria es necesario cambiar de terreno o tener un plan estratégico potente que haga posible operar sobre él.</td></tr>
                <tr><td bgcolor='#000000' ><b><font color="white">VENTAJA TÁCTICA</font></b></td><td> Las leyes y componentes de la justicia están a favor de las mujeres. Esta es una ventaja táctica que es difícil de igualar o superar. La única forma de superar o disminuir esta ventaja es realizar el primer ataque contra el oponente.</td></tr>
                <tr><td bgcolor='#000000' ><b><font color="white">ESTRATEGIA</font></b></td><td>Las mujeres poseen una potencia de fuego insuperable y, a menudo, realizan ataque sorpresa contra tus oponentes. Los hombres son demasiado pasivos y tratan de posponer la confrontación o resolverla por medios diplomáticos. Por ello, son tomados por sorpresa y destruidos o inmovilizados en el primer ataque. Permanecer durante todo el conflicto solo defendiéndose. El ataque es la mejor defensa.</td></tr>
                <tr><td bgcolor='#000000' ><b><font color="white">OFENSIVA</font></b></td><td>Los hombres tardan demasiado en solicitar el divorcio. Creen que las cosas pueden cambiar y posponen las decisiones hasta el último minuto posible. Es por eso que son fácilmente atrapados en ataques sorpresa y de alta intensidad. Terminan siendo aniquilados fácilmente en el primer ataque. Para evitar que esta situación suceda, deben ser los primeros en pedir el divorcio y realizar ofensas frecuentes durante el proceso y así desmoralizar y desgastar al enemigo que no espera esta reacción.</td></tr>
                <tr><td bgcolor='#000000' ><b><font color="white">ALIADOS</font></b></td><td>La máxima <i>"Las mujeres son víctimas, los hombres son villanos"</i>. Por ello, reciben todo el apoyo de la sociedad, amigos, familiares y de todos aquellos a quienes puedan cautivar con el victimismo que crearán y difundirán. Es necesario conseguir tantos aliados como sea posible antes del inicio del conflicto y reunir pruebas contundentes en su contra para modificar su imagen. Los familiares, amigos o conocidos pueden no ser confiables. Es fundamental no revelar sus planes a nadie. Las personas que te rodean pueden ser traidores o espías. </td></tr>
                </tbody>
                </table>

                <br></br><br></br><br></br>
                <b>RESULTADOS</b>
                <br></br><br></br><br></br>

                <table border="1" width="100%">
                <tbody>
                <tr><td bgcolor='#000000' width="30%"><b><font color="white">HERENCIA</font></b></td><td width="70%" >Como en la mayoría de las guerras, los activos serán destruidos o dañados. El divorcio es una guerra donde el ejército enemigo quiere saquear los recursos de la nación invadida. La única manera de protegerlo es a través de la prevención temprana. Las propiedades deben transferirse o venderse antes de que se produzca la invasión, de lo contrario, gran parte se perderá. No hay defensa contra este hecho. La guerra no disminuye nada ni a nadie.</td></tr>
                <tr><td bgcolor='#000000' ><b><font color="white">FINANCIERO</font></b></td><td>Las guerras son caras. Mantenerlos en funcionamiento podría arruinar financieramente a las naciones. Por eso los vencedores exigen el pago de un tributo a los perdedores. Serán ellos los que tendrán que pagar a costa de la guerra perdida. Una vez derrotados no podrán sustraerse a esta obligación, si lo intentan serán castigados ejemplarmente, para desalentar cualquier insurrección que pudiera existir. La única manera de evitar esto es ganar o hacer un trato de paz.
                Un mal trato a menudo puede ser menos doloroso y dañino que una guerra contra un enemigo poderoso y fuertemente armado. A veces es mejor vivir para poder luchar más tarde en condiciones de victoria más favorables y factibles.</td></tr>
                <tr><td bgcolor='#000000' ><b><font color="white">TRAUMA</font></b></td><td>La guerra es algo tan terrible que nadie puede vivirla sin tener ningún tipo de trauma en el futuro. Un divorcio es una guerra que afecta a las personas física y mentalmente. Muchos no pueden soportar sus horrores y las obligaciones impuestas tras el alto el fuego. Los hombres no solo pierden a sus hijos y propiedades, sino que muchos pierden su dignidad y el respeto por sí mismos. Sufren humillaciones y persecuciones sistemáticas.
                Sus derechos son totalmente ignorados y no respetados. Se convierten en eternos esclavos y proveedores. Serán severamente sancionados si no cumplen con sus obligaciones, estén o no en condiciones de hacerlo. Muchos se enferman o se suicidan porque no encuentran apoyo ni soluciones a sus problemas. El divorcio es una guerra capaz de producir una destrucción y secuelas inimaginables, incluso después de su conclusión.</td></tr>
                <tr><td bgcolor='#000000' ><b><font color="white">VÍCTIMAS</font></b></td><td>No existe una guerra limpia. Todos producen víctimas en ambos bandos, incluidos civiles inocentes. En la guerra, las familias son destrozadas y mutiladas. Los niños serán los más afectados por un divorcio. Muchos crecerán sin problemas aparentes, pero todos desarrollarán algún tipo de secuela emocional, como inseguridad, ira, depresión, que afectará su vida futura.
                En un mundo injusto y competitivo, estarán en desventaja frente a sus competidores, quienes cuentan con estructuras emocionales más sólidas provenientes de una crianza familiar consistente. Los hombres son los más desfavorecidos en este proceso, ya que rara vez tienen derecho a compartir o cuidar a sus hijos. Además, el 80% de ellos sufre la alienación parental producida por el partido ganador de la guerra.</td></tr>
                </tbody>
                </table>

                <br></br><br></br><br></br>
                <h2>Sala de Guerra</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide31} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                <b>The War Room</b> es un lugar creado por el primer ministro británico durante la Segunda Guerra Mundial para reuniones confidenciales y para discutir la estrategia militar. <br></br><br></br>
                Actualmente, este concepto ha sido adoptado por las empresas con el objetivo de realizar reuniones de alineación con su personal y definir metas a alcanzar. <br></br><br></br>
                Hoy, el sistema legal favorece a un solo lado. De esta manera, es fundamental que los hombres discutan y planifiquen sus acciones antes de ingresar a este entorno totalmente desfavorable para ellos. <br></br><br></br>
                Muchos no lo hacen y son fácilmente aniquilados durante este procedimiento. Es necesario enumerar sus recursos y medir sus fortalezas. Sin embargo, muchos factores que determinan la victoria en una guerra son imponderables y difícilmente pueden anticiparse. <br></br><br></br>
                Sin embargo, en la medida de lo posible, deben ser pensadas, relacionadas, medidas y discutidas antes y durante el divorcio. <br></br><br></br>
                No basta con tener una sola estrategia, hay que evaluarla y modificarla según los hechos que se presenten. <br></br><br></br>
                <i><b> Por lo tanto, es fundamental discutir y verificar el progreso de esta guerra y tomar las acciones necesarias.</b></i><br></br><br></br>
                Además del concepto de sala de guerra, donde debemos evaluar y planificar nuestros ataques, se pueden adaptar varias metodologías y acciones militares para ser utilizadas en el proceso de divorcio, como se describe a continuación:

                <br></br><br></br><br></br>
                <b>RESULTADO DE LA GUERRA</b>
                <br></br><br></br>

                De acuerdo con la estrategia militar enseñada y practicada por las fuerzas militares actuales, para predecir el resultado de una guerra, debemos analizar y comparar nuestras propias condiciones y las de nuestro enemigo, con base en cinco factores.
                <br></br><br></br>

                <table border="1" width="100%">
                <tbody>
                <tr><td width="25%">CAMINO</td></tr>
                <tr><td>CLIMA</td></tr>
                <tr><td>TERRENO</td></tr>
                <tr><td>MANDO</td></tr>
                <tr><td>DOCTRINA</td></tr>
                </tbody>
                </table>

                <br></br><br></br>
                <u>Traduciendo al divorcio:</u>  
                <br></br><br></br>

                <table border="1" width="100%">
                <tbody>
                <tr><td width="25%" id="cabec">CAMINO</td><td>ESTRATEGIA, ALIADOS</td></tr>
                <tr><td id="cabec">CLIMA</td><td>TIEMPO DISPONIBLE, ESTRUCTURA FINANCIERA Y PERSONAL</td></tr>
                <tr><td id="cabec">TERRENO</td><td>JURÍDICO FAMILIAR</td></tr>
                <tr><td id="cabec">MANDO</td><td>ABOGADO</td></tr>
                <tr><td id="cabec">DOCTRINA</td><td>ORGANIZACIÓN, OBJETIVOS DEFINIDOS</td></tr>
                </tbody>
                </table>

                <br></br><br></br><br></br>
                <b>PODER RELATIVO ENTRE FUERZAS</b>
                <br></br><br></br>

                Con base en el concepto presentado, se pueden comparar las fuerzas y evaluar el poder relativo entre ellas. <br></br><br></br>
                Para ello, se deben realizar las siguientes preguntas:
                <br></br><br></br>

                <table border="1">
                <tbody>
                <tr><td>¿Cuál es el mejor camino a seguir?</td></tr>
                <tr><td>¿Qué comandante tiene más habilidad?</td></tr>
                <tr><td>¿Qué lado tiene la ventaja del clima y terreno?</td></tr>
                <tr><td>¿Cuál de los ejércitos manifiesta una disciplina más eficaz?</td></tr>
                <tr><td>¿De qué lado tiene superioridad militar?</td></tr>
                <tr><td>¿Qué lado tiene los soldados mejor entrenados?</td></tr>
                <tr><td>¿Qué lado tiene un sistema más claro y definido?</td></tr>
                </tbody>
                </table>
                <br></br><br></br>

                <u>Traduciendo al divorcio:</u>  
                <br></br><br></br>

                <table border="1">
                <tbody>
                <tr><td>¿Quién tiene las mejores estrategias y aliados?</td></tr>
                <tr><td>¿Qué abogado tiene más habilidad?</td></tr>
                <tr><td>¿Qué lado tiene la mayor ventaja legal?</td></tr>
                <tr><td>¿Quién tiene la mejor estructura financiera, personal y tiempo disponible?</td></tr>
                <tr><td>¿Qué lado manifiesta una disciplina más efectiva?</td></tr>
                <tr><td>¿Qué lado tiene la superioridad intelectual y emocional?</td></tr>
                <tr><td>¿Qué lado tiene más apoyo de amigos y familiares?</td></tr>
                <tr><td>¿Quién tiene metas y objetivos más claros y definidos?</td></tr>
                </tbody>
                </table>

                <br></br><br></br><br></br>
                El terreno legal favorece a las mujeres.<br></br>
                Tienen una ventaja táctica del 50% o más en esta guerra.
                <br></br><br></br><br></br>
                <b>DISIMULACIÓN</b>
                <br></br><br></br>
                Es una técnica que tiene como objetivo engañar al oponente para que tome decisiones equivocadas y destructivas. <br></br><br></br>
                Es un factor imponderable que depende exclusivamente de la situación y de la inteligencia de quien lo practica. <br></br><br></br>
                No hay una regla establecida sobre cómo y cuándo usarlo. Pero esta técnica es una cualidad básica fundamental de cualquier operación militar, por lo tanto, debe utilizarse en el proceso de divorcio para aumentar las posibilidades de victoria.
                <br></br><br></br>
                <u>Formas de actuar en una disputa</u> :
                <br></br><br></br>

                <table border="1">
                <tbody>
                <tr><td>Si es capaz, debe fingir estar discapacitado</td></tr>
                <tr><td>Si estás preparado, debes fingir que no lo estás</td></tr>
                <tr><td>Si estás cerca, debes fingir estar lejos</td></tr>
                <tr><td>Ofrecer señuelos para engañar y atraer al enemigo</td></tr>
                <tr><td>Ataca al enemigo cuando está desordenado</td></tr>
                <tr><td>Si el enemigo es orgulloso, debe ser provocado</td></tr>
                <tr><td>Si el enemigo es humilde, se debe alentar su arrogancia</td></tr>
                <tr><td>Si el enemigo está descansado, hay que desgastarlo</td></tr>
                </tbody>
                </table>

                <br></br><br></br>
                Los elementos relacionados se explican por sí mismos y deben usarse en Divorcio.
                <br></br><br></br>

                <i><b>"Un comandante militar debe atacar donde el enemigo no lo sabe y debe usar caminos que, para el enemigo, son inesperados".<br></br>Sun Tzu</b></i>
                <br></br><br></br><br></br>
                <b>DISPOSICIONES NECESARIAS</b>
                <br></br><br></br>
                Cualquier ejército que se mantenga en el campo durante mucho tiempo hará que las reservas del estado en un cierto punto sean insuficientes. <br></br><br></br>
                Cuando el ejército esté agotado, sus provisiones insuficientes, sus tropas desmoralizadas y sus recursos agotados, sus enemigos aprovecharán esta situación y obtendrán ventajas para destruirlo. <br></br><br></br>
                Por tanto, ninguna hostilidad debe prolongarse más allá del tiempo necesario y nunca debe declararse sin las disposiciones necesarias para su funcionamiento.
                
                <br></br><br></br><br></br>
                <u>Traduciendo al divorcio:</u>  
                <br></br><br></br>

                <table border="1">
                <tbody>
                <tr><td>Aumentar las reservas financieras.</td></tr>
                <tr><td>Eliminar gastos existentes.</td></tr>
                <tr><td>No realizar nuevos gastos.</td></tr>
                <tr><td>Elimina deudas a tu nombre.</td></tr>
                <tr><td>Tener deudas a nombre de su cónyuge.</td></tr>
                <tr><td>Hacer que el cónyuge contraiga más deudas a su nombre.</td></tr>
                </tbody>
                </table>

                <br></br><br></br><br></br>
                <b>EXPLORA VENTAJAS</b>
                <br></br><br></br>
                Tener la capacidad de detener o mover al enemigo representa una gran ventaja táctica. Por ejemplo, es posible engañarlo ofreciéndole algún tipo de beneficio, mientras lo conduce a una trampa. Además de presentar algún tipo de amenaza para obligarlo a detener su progreso. Aprovechar las ventajas que puedan existir, manipulando el movimiento del enemigo, representa un factor determinante para obtener la victoria contra él.<br></br><br></br>
                Entonces, la habilidad de un comandante militar es cansar al enemigo cuando está descansado; déjalo con hambre cuando tenga provisiones; muévalo cuando esté parado.
                
                <br></br><br></br><br></br>
                <u>Traduciendo al divorcio:</u>  
                <br></br><br></br>

                <table border="1">
                <tbody>
                <tr><td>Si tiene abundantes recursos financieros, presente numerosas demandas contra su cónyuge para desgastarlo financiera y estructuralmente. Hazlo de manera gradual y constante.</td></tr>
                <tr><td>Usar medios no legales para desgastar a su cónyuge.</td></tr>
                <tr><td>Crear gastos financieros en nombre de su cónyuge.</td></tr>
                <tr><td>Genera problemas de todo tipo según tu creatividad y que son responsabilidad exclusiva de tu cónyuge.</td>
                </tr>
                </tbody>
                </table>

                <br></br><br></br>
                <i><b>"Um general competente movimenta o inimigo y não será manipulado por ele."<br></br>Sun Tzu </b></i>  
                <br></br><br></br><br></br>
                <b>MOMENTO OPORTUNO</b>
                <br></br><br></br>
                Cuando un comandante logra una victoria extraordinaria contra su enemigo, es porque tiene conocimiento previo y puede prever el curso de la batalla. <br></br><br></br>
                Sin embargo, este conocimiento previo no puede obtenerse por medio de la magia o un intelecto superior. Debe obtenerse de personas que conocen claramente las situaciones del enemigo.
                
                <br></br><br></br>
                <u>Traduciendo al divorcio:</u>  
                <br></br><br></br>

                <table border="1">
                <tbody>
                <tr><td>Contratar y utilizar investigadores privados.</td></tr>
                <tr><td>Obtener y registrar evidencia de adulterio para usar en la corte.</td></tr>
                <tr><td>No tengas miedo de espiar a tu cónyuge.</td></tr>
                <tr><td>Usar cámaras y grabadoras espía.</td></tr>
                <tr><td>Obtenga contraseñas de correo electrónico, redes sociales, computadoras personales, etc.</td></tr>
                <tr><td>Escucha y graba conversaciones telefónicas desde teléfonos fijos y celulares. </td></tr>
                </tbody>
                </table>

                <br></br><br></br>
                <i><b>"El uso de espías es esencial en la guerra y el ejército depende de este servicio en sus movimientos".<br></br>Sun Tzu</b></i>  
                <br></br><br></br>
                </span>
            </S.FormText>

            </S.Form>
            </>
            :
            <>
            <br></br>
            <br></br>
            <h3>&nbsp;</h3>
            <br></br>
            </>    }  
        </S.Container>
    )
}

export default GuidePart6;