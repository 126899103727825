import React    from 'react';
import Terms    from '../../../global/globalTermsOfUse';
import * as GV  from '../../../global/globalVariables';

function TermsOfUse() {
    
    return (
      <>
      <Terms name={GV.APP_LIFE_PLAN}></Terms>
      </>
    )
}

export default TermsOfUse;