
import React, { useEffect, useState } from 'react';   
import * as S       from './styles';
import guide05      from '../../../../view/DivorceGuide/Guide/Images/guide05.png';
import guide06      from '../../../../view/DivorceGuide/Guide/Images/guide06.png';
import guide07      from '../../../../view/DivorceGuide/Guide/Images/guide07.png';
import guide08      from '../../../../view/DivorceGuide/Guide/Images/guide08.png';
import guide09      from '../../../../view/DivorceGuide/Guide/Images/guide09.png';
import guide10      from '../../../../view/DivorceGuide/Guide/Images/guide10.png';
import chart1       from '../../../../view/DivorceGuide/Guide/Images/chart1.png';
import util         from '../../../../Utilities/Util';

function GuidePart2() {
    
    const [access, setAccess] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)
        setAccess( util.checkGuideAccess() );
    }, [])

    return (
        <S.Container>
        { ( access )
            ?
            <>
            <S.Form> 

            <S.FormTitle>
                <span>PARTE2</span><br></br><br></br><br></br><span>A realidade onde estamos inseridos</span><br></br><br></br>
            </S.FormTitle>

            <S.FormText>
                <span>

                A realidade é um conceito amplamente debatido no campo da filosofia. É algo que possui diferentes definições e que varia de acordo com o contexto onde ela é estudada e debatida. Para facilitar nosso entendimento, podemos dizer que a realidade é como as coisas de fato são. <br></br><br></br>
                As pessoas de uma forma geral, desconhecem sua própria realidade. Agem e tomam decisões com base em ilusões criadas em suas mentes. Normalmente alimentadas e produzidas pela sociedade onde vivem. Formam suas ideias e conceitos com base nos filmes, novelas e demais meios de comunicação existentes. Acreditam que em um julgamento todos lutam pela verdade e justiça, que seus defensores irão fazer de tudo por elas, que o bem sempre vence o mal, e assim por diante. <br></br><br></br>
                Infelizmente, o mundo real é sombrio e injusto para a maioria das pessoas, privilegiando apenas uma minoria. Ninguém procura se informar ou buscar a verdade. Preferem o conforto da mentira e da ilusão para não terem o trabalho de modificar suas crenças e costumes, nem a sociedade em que estão inseridas. Porém, são compulsoriamente obrigadas a conviver com a verdade quando algo é quebrado em suas vidas. <br></br><br></br>
                São justamente nesses momentos de dor e desorientação que elas descobrem que estavam vivendo uma mentira. Percebem que as instituições, as leis e seus componentes não funcionam. Que pessoas ao seu redor não eram confiáveis, nem amigas. Sendo forçadas a despertar para a realidade que desconheciam ou queriam evitar.

                <br></br><br></br><br></br><br></br>
                <h2>Diferenças entre Homens e Mulheres</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide05} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                <i><b>As leis são sexistas e favorecem apenas as mulheres.</b></i> <br></br><br></br>
                Os homens são os maiores perdedores em processos de divórcio. Raramente ganham algum processo, principalmente se houverem filhos envolvidos. O máximo que conseguem é terem suas perdas mitigadas. E isso acontece apenas se tiverem sorte e um planejamento antecipado a favor deles. Quando houver filhos, tudo dependerá do caráter da mulher envolvida na disputa. Porque nesses casos os homens não possuem qualquer direito sobre eles. Possuem apenas o dever de pagar as despesas e de serem punidos quando não cumprirem com essa obrigação. Estar desempregado, doente ou qualquer outro impeditivo, nunca será atenuado. Qualquer trégua concedida pela justiça, terá que ser reparada com juros, correção e multa após sua normalização. <br></br><br></br> 
                <b>Terá que gastar muito dinheiro para provar que não tem dinheiro</b>.<br></br><br></br>
                Após o divórcio, mesmo de forma silenciosa, os homens serão tratados como criminosos pela sociedade e instituições. Qualquer abuso sofrido pela parte vencedora nunca será levado em consideração. Não há qualquer lugar ou instituição para denunciá-las. As delegacias de polícia não aceitam registrar qualquer violação de seus direitos, que como já descrito anteriormente, não existem.<br></br><br></br>
                É necessário realizar um novo processo jurídico, que consome tempo e dinheiro para se tentar alguma coisa. Quando são acatados apenas geram advertências a parte culpada e nada mais.<br></br><br></br> 
                As mulheres atualmente estão cercadas de privilégios e direitos que não param de crescer. Já faz muito tempo que o ponto de equilíbrio foi superado. Esse desequilíbrio irá produzir cada vez mais sofrimento para as partes envolvidas em um processo de divórcio, juntamente com seus filhos e familiares<br></br><br></br>
                <i><b>Os princípios constitucionais de igualdade não são mais respeitados.</b></i><br></br><br></br>  
                Os homens são apenas provedores em um sistema escravocrata.<br></br><br></br> 
                Homens e mulheres devem ser iguais em direitos e obrigações, é um dos principais pilares da igualdade jurídica preconizada como direito fundamental por todas as nações democráticas do mundo. <br></br><br></br>
                Contudo, esses princípios não são mais respeitados nem utilizados. As mulheres se apresentam como vítimas exigindo cada vez mais leis e politícas de proteção. <br></br><br></br>
                Argumentam que recebem salários menores em comparação aos homens, mas não apresentam uma única vítima de tal afirmação. Já existem leis trabalhistas severas que impedem esse tipo de abuso ou distorção, mas isso é ignorado por elas que exigem a criação de cotas nas vagas disponíveis.<br></br><br></br> 
                Atualmente, elas já ocupam os melhores empregos, basta olhar para as empresas existentes. O chão de fábrica, que é a parte mais suja, pesada e menos remunerada é ocupada majoritariamente por homens, enquanto a parte administrativa, que é mais limpa, tranquila e melhor remunerada é ocupada predominantemente por mulheres.<br></br><br></br> 
                Alegam serem as maiores vítimas da violência doméstica. Porém, todos os artigos, pesquisas e documentários que mostram o contrário, são imediatamente retirados dos meios de comunicação. Apenas um lado dessa história é aceito como verdade, enquanto os próprios números demonstram outra realidade que nunca é debatida ou divulgada.<br></br><br></br> 
                Elas recebem sentenças menores e mais suaves em comparação aos homens que cometeram delitos de igual gravidade. Se aposentam primeiro, embora sua expectativa de vida seja maior. São beneficiadas em programas de saúde exclusivos, diferentemente de homens portadores das mesmas moléstias largados à própria sorte. Não são obrigadas a prestar serviço militar ou defender o país em situações de conflito.<br></br><br></br> 
                Além disso tudo, as justificativas utilizadas por elas <u><b>NÃO PODEM JAMAIS SEREM CONTESTADAS NEM CRITICADAS</b></u>, qualquer pessoa que assim o fizer será ridicularizada ou desacreditada, mesmo sendo outra mulher. 

                <br></br><br></br><br></br><br></br>
                <h2>Indústria do Litígio</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide06} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                Enquanto o casamento fala de amor, o divórcio fala apenas de dinheiro. Atualmente, um a cada três casamentos termina em divórcio. Ele se tornou uma indústria lucrativa que gera milhões ao ano. Nos Estados Unidos gera algo entorno de US$50 bilhões por ano. Não há como apurar esses números em outros paises, mas não resta dúvida que devem ser números bem parecidos. Há um milhão de divórcios nos Estados Unidos por ano. Isto é, um em cada 36 segundos, quase 2.400 por dia e 16.800 por semana. Os números surpreendem e demonstram que é <u><b>UM NEGÓCIO MUITO LUCRATIVO</b></u>. <br></br><br></br>
                Um processo de divórcio não consensual <u><b>CUSTA CARO E CONSOME TEMPO</b></u> dos envolvidos. Quem passa por essa experiência perde em média 70% do patrimônio ou valores maiores em casos mais graves. Muitas disputas são intencionalmente inflamadas e prolongadas. Isso resulta na fabricação desnecessária de problemas que poderiam ser solucionados rapidamente por meios extrajudiciais mais pacíficos, rápidos e efetivos. Mas isso não é interessante nem lucrativo.<br></br><br></br>
                Existe uma indústria a ser alimentada, que segue uma linha de produção que deve funcionar de acordo com as normas estabelecidas. Tudo é feito através de papel, raramente algo é dito ou perguntado, já está pronto e escrito apenas seus dados são modificados e tudo é realizado a distância sem sua presença. As pessoas que definem sua vida, não olham nos seus olhos e nem sabem se você tem pernas e braços. Não existe empatia, os valores humanos não são respeitados nem considerados. Não existe humanidade, quando existe é hipócrita, existe apenas tecnicidade. <br></br><br></br>
                Os resultados já estão definidos, esperam apenas para serem protocolados e despachados para que um novo tome o lugar vago. Igual a linha de produção de uma fábrica. Se tiver sorte o seu processo será lido e julgado adequadamente, de vez enquanto é necessário demonstrar que o sistema funciona, e assim parecer que as injustiças relatadas por pessoas descontentes não passam de exceções infelizes.<br></br><br></br>
                <br></br><br></br>
                <u><b>Filiais</b></u>
                <br></br><br></br>
                Toda indústria gera empregos diretos e indiretos porque é necessário criar toda uma estrutura paralela de apoio e manutenção. Isso propicia o surgimento de segmentos menores ou correlatos que fornecem à matéria prima, componentes ou serviços suplementares para que essa indústria possa funcionar, formando um sistema integrado. Quanto maior for a produção maior será o lucro de todo esse sistema. O mesmo acontece com a indústria do litígio, ela fornece e também necessita do material e dos serviços prestados de seus associados. <br></br><br></br>
                A indústria do casamento é um segmento que movimenta bilhões anualmente. É o principal fornecedor de matéria prima da indústria do litígio, que por sua vez acaba também alimentando essa indústria em um círculo sem fim. <br></br><br></br>
                Em todo sistema existe uma perda de energia para que as engrenagens possam funcionar. Nem todo casamento termina em divórcio e nem todo divórcio vai produzir novos casamentos. Contudo, os números atuais ainda são capazes de gerar imenso lucro e manter essa roda girando. Além disso, também é possível criar outras indústrias similares que utilizam e aproveitam os refugos de produção. <br></br><br></br>
                Atualmente, temos a indústria da paternidade que obriga os homens a sustentarem filhos que não são seus. Como também a indústria do estrupro que gera milhares de denúncias falsas e obriga os homens a gastar muito dinheiro para se defender de suas acusadoras. Novas leis e dificuldades são elaboradas periodicamente para mantê-lo em funcionamento perpétuo.<br></br><br></br> 
                Por fim, é um sistema maligno, lucrativo e muito criativo.

                <br></br><br></br><br></br><br></br>
                <h2>Psicopatas e Sociopatas</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide07} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                A não ser que a parte afetada esteja comendo moscas, os distúrbios mentais têm pouca ou nenhuma relevância no meio jurídico.  Muito irá depender da habilidade do defensor em utilizar essa informação e peculiaridade a favor de seu cliente. Além do fato de prolongar e encarecer demasiadamente os processos por envolver profissionais da saúde que irão atestar e confirmar os problemas relatados. As instituições e as pessoas que as compõem, não estão preparadas para lidar com esse tipo de gente ou situação, por isso os psicopatas e sociopatas são <u><b>IGNORADOS E DESCONSIDERADOS</b></u> pela justiça. <br></br><br></br>
                Ao contrário da crença popular essas pessoas não são necessariamente violentas. Existem níveis desses transtornos de comportamento que podem ser altos ou baixos. Mas o desrespeito pelas pessoas e normas sociais estabelecidas é um fator dominante em qualquer nível. A psicopatia é uma predisposição genética, a pessoa nasce com essa alteração cerebral, por isso tendem a formar relações artificiais mantidas apenas por interesse. Enquanto a sociopatia é um problema provocado por fatores  ambientais, como a educação recebida ou traumas sofridos, gerando dificuldades em se relacionar ou formar vínculos. <br></br><br></br>
                Ambos são compostos por pessoas inteligentes, sedutoras e manipuladoras e por causa desses atributos tendem a ocupar cargos importantes nas empresas e instituições, e assim influenciar nossas vidas direta ou indiretamente. Podem ser juízes, advogados, médicos, psicólogos, professores, políticos, militares, cientistas, religiosos, qualquer pessoa, inclusive aquela com que vivemos. São capazes de criar leis injustas, normas e procedimentos desastrosos, agravar os problemas existentes, levar nações à guerra ou pessoas ao suicídio. Não há limites na destruição que podem provocar. <br></br><br></br>
                Segundo as estatísticas, os advogados estão em segundo lugar no ranking das profissões com mais psicopatas. Os ambientes de poder, como o <u><b>EMPRESARIAL, JURÍDICO E POLÍTICO</b></u>, são os locais com mais probabilidade de se encontrar pessoas desse tipo. 
                <br></br><br></br><br></br>
                <i><b>No Brasil, a cada 25 pessoas uma é psicopata.</b></i>
                <br></br><br></br><br></br>
                <u>Algumas características de um Psicopata/Sociopata</u>:
                <br></br><br></br>
                <ul>
                <li>&nbsp;Capazes de manipular e seduzir as pessoas que os rodeiam.</li><br></br>
                <li>&nbsp;Mentem e enganam o tempo todo mesmo quando flagrados.</li><br></br>
                <li>&nbsp;Negligenciam os sentimentos dos outros, não sentem culpa nem remorso.</li><br></br>
                <li>&nbsp;Não possuem consciência moral nem ética, as pessoas são utilizadas como meios para se atingir um fim.</li><br></br>
                <li>&nbsp;Comportamento impulsivo, podendo ser agressivos e perigosos.</li><br></br>
                <li>&nbsp;Não pedem desculpas nem se corrigem, mas se apresentam amáveis, educados e prestativos.</li><br></br>
                <li>&nbsp;São egocêntricos e egoístas, apenas seu bem-estar é importante, não se preocupam com a segurança dos outros.</li><br></br>
                <li>&nbsp;São irresponsáveis e podem não cumprir com as obrigações financeiras.</li><br></br>
                <li>&nbsp;São covardes, atacam aqueles que não podem se defender.</li><br></br>
                </ul>
                <br></br>
                Os psicopatas somam 4% da população mundial, desses 3% são homens e 1% mulheres. São 20% da população carcerária e 86,5% de assassinos seriais. São portadores de um transtorno de personalidade e podem estar presentes em todas as classes sociais. Quando a psicopatia ocorre em mulheres, a sua identificação parece ser mais difícil. Acredita-se, que essas mulheres podem estar sendo pouco investigadas e até mesmo não diagnosticadas. <br></br><br></br>
                Como a justiça privilegia as mulheres e trata os homens como criminosos, é quase certo que esse fator contribui significativamente para esconder e beneficiar mulheres portadoras desse tipo de comportamento. Percentualmente, a parte masculina em uma relação parece ter mais chances de possuir esse distúrbio. Contudo, como não existem pesquisas concretas e definitivas sobre esse assunto devemos por garantia presumir que essa possibilidade seja igual em ambos os sexos. <br></br><br></br>
                Independente do gênero, estar ligado de alguma forma a uma pessoa desse tipo é algo terrível para a parte oposta, e pior ainda será o processo de desligamento. Essas pessoas não aceitam perder ou serem contrariadas. Não permitem que a parte libertada reconstrua sua vida, tenha novos relacionamentos ou que seja feliz. Geralmente, os homens psicopatas  utilizam de violência física contra suas companheiras para garantir que sua vontade seja respeitada, e infelizmente os noticiários estão cheios de casos de mulheres vitimadas por eles. 
                Os homens também sofrem agressões desse tipo por parte de suas companheiras, mas esses casos são omitidos ou pouco divulgados pelos meios de comunicação, a não ser quando ultrapassam os níveis de <i><b>"normalidade"</b></i> suportados, como se fosse possível aceitar ou justificar qualquer ato de violência entre homens e mulheres. <br></br><br></br>
                No caso de mulheres psicopatas o cenário muda completamente. Como as mulheres são beneficiadas pela justiça, as psicopatas encontram o ambiente perfeito para a realização de suas perversidades. Elas adquirem poderes sem limites sobre seus companheiros, principalmente se houverem filhos na relação, os quais certamente serão utilizados como arma e escudo em qualquer reinvindicação ou confronto futuro. São inteligentes, manipuladoras e dificilmente irão perder algum processo movido contra elas. 
                Levam com extrema facilidade e sem nenhum remorso, seus companheiros a ruina, prisão e ao suicídio. Mas esse tipo de violência não é contabilizado nem demonstrado em qualquer estatística.

                <br></br><br></br><br></br><br></br>
                <h2>Cenário</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide08} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                Desde o final da segunda guerra mundial até a presente data a sociedade humana vem passando por significativas transformações que modificam nossa forma de vida e valores. <br></br><br></br>
                <b>A família é a principal unidade de uma sociedade</b>. <br></br><br></br>
                Ela fornece educação, segurança, valores e princípios morais aos seus membros. Antes da idade média a família era apenas uma convenção social para reprodução e transferência de poder. A partir desse período da história, a família passou a ser uma importante instituição responsável por promover a educação dos filhos e seu comportamento no meio social. 
                O papel da família no desenvolvimento de cada indivíduo tornou-se de fundamental importância. 
                Agora os valores as tradições e os costumes podiam ser transmitidos e perpetuados através de gerações. Seus membros tinham a quem recorrer para solicitar ajuda e proteção, ao contrário de tempos anteriores onde estariam a própria sorte. <br></br><br></br> 
                Atualmente, existem grupos poderosos que disputam o poder mundial. Querem garantir sua sobrevivência assumindo o controle das instituições globais e governos estabelecidos. Para isso pretendem destruir as leis e os valores morais existentes. Valores estes conseguidos com muito sacrifício durante o desenvolvimento da sociedade humana. 
                Tentam através de todos os meios destruir as famílias existentes e impedir que novas sejam criadas. Porque, indivíduos sem família são mais fáceis de serem manipulados e não terem a quem pedir ajuda e proteção, ficando dependentes e à mercê do estado vigente. <br></br><br></br>
                Esses grupos criam leis injustas, vendem ideias inválidas e destrutivas através dos meios de comunicação que são por eles controlados, facilitam e incentivam o adultério e a desarmonia entre os casais, incentivam as drogas, emburrecem as pessoas e diversos outros procedimentos nefastos. 
                Profissionais de elevado caráter e conhecimento estão gradativamente sendo substituídos por pessoas sem qualificação e princípios morais consistentes. Pessoas essas educadas em instituições de ensino deficientes e por famílias desfeitas ou em desarmonia.  Muitos são analfabetos funcionais e não conseguem interpretar um simples texto. 
                Estarão em breve dirigindo nossas vidas, criando normas e procedimentos, e julgando nossas ações com base em seus valores deturpados.<br></br><br></br> 
                <i><b> Nos últimos anos, de forma sinistra, sutil e invisível, o papel de pai vem sendo removido da sociedade humana.</b></i> <br></br><br></br> 
                Pesquisas e estatísticas demonstram que existe uma relação direta entre a ausência da figura paterna na educação dos filhos e a criminalidade. Isso acontece porque o pai tem um <u><b>PAPEL FUNDAMENTAL NA FORMAÇÃO DO CARÁTER DOS FILHOS</b></u>. <br></br><br></br>
                As mães fornecem carinho, amor e proteção. Os pais fornecem exemplos, estrutura, coragem e o <u>caráter</u>. Quando este é ausente ou impedido de exercer sua paternidade, geralmente os filhos são portadores de algum tipo de transtorno emocional que irá afetar de alguma forma o seu futuro. <br></br><br></br>
                O divórcio é um importante instrumento utilizado pelos grupos descritos anteriormente. Além dele ser facilitado e incentivado, é usado para criar desafetos perpétuos entre as partes envolvidas, gerando mais processos jurídicos e desentendimentos futuros. 
                Ele também destrói financeiramente uma das partes ou ambas, impedindo sua recuperação ou superação a curto prazo ou definitivamente. Provoca algum tipo de trauma nos filhos existentes, como insegurança e rejeição, dificultando seus relacionamentos na fase adulta. <br></br><br></br>
                Por fim, o divórcio retira ou dificulta o direito dos homens de exercer sua paternidade de forma plena e consistente. Ele é propositalmente feito para incapacitar os homens, como muitas outras coisas que nos cercam e não damos conta. 

                <br></br><br></br><br></br><br></br>
                <h2>Inveja</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide09} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                Vivemos em uma sociedade capitalista e competitiva. As oportunidades são reduzidas e temos que nos esforçar cada vez mais para conseguir realizar nossos sonhos e metas. Querendo ou não, estamos cercados de pessoas que nos invejam e torcem contra nosso sucesso e felicidade. 
                Elas invejam nossa carreira profissional, nossa situação financeira, nossa saúde, nosso casamento e etc. <br></br><br></br>
                Psicólogos e sociólogos ainda não sabem exatamente o que é a inveja. Na história humana, ela tem sido a causa de grandes problemas e conflitos que terminaram em morte e destruição. Ela faz parte da estrutura do psiquismo humano e atua sobre nossa cultura e organização social. <br></br><br></br>
                É muito comum que casamentos sejam desfeitos por causa dela. Ter um cônjuge, filhos, uma família alegre e feliz, é algo muito invejado pelas pessoas em nossa sociedade. Elas simplesmente não suportam a felicidade alheia e não aceitam que outras pessoas tenham aquilo que elas não possuem. <br></br><br></br>
                Dentro desse quadro, é comum estarmos cercados de falsos amigos e familiares que cobiçam nossa felicidade. Quando um casamento está abalado por inúmeras razões, são eles que se apresentam como sábios conselheiros, sempre prontos a nos ajudar. Geralmente, inflamam esses desafetos e aconselham o divórcio como solução para o problema. 
                Muitos inclusive são responsáveis pelo surgimento do problema original. Costumam colocar suspeitas nas mentes dos cônjuges, promovem desafetos, incentivam a traição em nome da liberdade e do prazer, e inúmeros outros desvios de comportamento familiar. Parece uma novela de ficção, mas isso é mais comum do que as pessoas imaginam. <br></br><br></br>
                Uma pesquisa efetuada pela <u>Universidade Brown</u> revela, inclusive que o divórcio é contagioso. Que a chance de uma pessoa se divorciar aumenta em <b>75%</b> quando ele se relaciona diretamente com algum indivíduo que está se divorciando ou acabou de passar por essa experiência.<br></br><br></br> 
                <i><b>O divórcio deve ser uma atitude pessoal e individual, deve ser efetuado pelas razões corretas.</b></i><br></br><br></br> 
                É recomendável o aconselhamento efetuado por profissionais isentos ao casal, como psicólogos e terapeutas, e não por amigos ou familiares. Mas é muito mais aconselhável obter e assistir depoimentos de pessoas desconhecidas que realizaram divórcios bons e ruins antes de tomar qualquer atitude. 
                A inveja produz aconselhamentos indevidos ou maliciosos que são capazes de incentivar ou ativar a produção de um divórcio.

                <br></br><br></br><br></br><br></br>
                <h2>Justiça</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide10} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                <i><b>A justiça é formada por um trinômio, ela é ilógica, injusta e irracional.</b></i><br></br><br></br> 
                Atualmente, inúmeras pessoas estão presas sem julgamento, outras continuam livres mesmo depois de condenadas e ainda produzindo novas vítimas, outras tem suas vidas destruídas a espera dela, outras arruinadas por sentenças e decisões errôneas ou perversas. <br></br><br></br>
                Os processos se acumulam e os funcionários do judiciário não dão conta. Muitos adoecem na tentativa de fazê-la funcionar, outros não dão a menor importância. A justiça é lenta, formada por procedimentos deficientes, por leis ultrapassadas e injustas. 
                Ela é composta por grupos que se beneficiam de sua irracionalidade e burocracia. É uma indústria lucrativa que não para de crescer. Processos são manipulados ou esquecidos conforme interesses pessoais. <br></br><br></br>
                <u><b>A JUSTIÇA FOI PROPOSITALMENTE CRIADA PARA NÃO FUNCIONAR E PRIVILEGIAR UMA PEQUENA PARCELA QUE À CONTROLA</b></u>. <br></br><br></br>
                Criam-se dificuldades para depois vender facilidades. É composta por um sistema que utiliza grandes recursos financeiros para funcionar. <br></br><br></br>
                São gastos bilhões dos cofres públicos anualmente, apenas em sua manutenção. Dinheiro que poderia estar sendo empregado em benefício da população, mas é utilizado para manter um sistema corrompido, deficiente e injusto funcionando, que não realiza o objetivo pelo qual foi criado. 
                Sendo apenas uma ilusão criada para enganar as pessoas e transmitir uma sensação de segurança e amparo. Fazê-las acreditar que seus problemas serão corrigidos. <br></br><br></br>
                Reduto de psicopatas, pessoas inescrupulosas e sem caráter. Muitos disfarçados e revestidos de títulos, poderes e mordomias, mas não passam de pessoas medíocres e inaptas. Devido as dificuldades, tempo e dinheiro necessários na formação de juízes, advogados e demais profissionais da área jurídica, tem se a impressão de que eles são pessoas excepcionais dotadas de grande inteligência e capacidades, que lutam a todo custo pela verdade e justiça. <br></br><br></br>
                Na realidade, são apenas pessoas comuns portadores de qualidades e defeitos. Podem ser profissionais brilhantes dotados de grande capacidade intelectual e empatia, como também podem ser ignorantes e facínoras da pior espécie. 
                Em um país mergulhado na ignorância e corrupção, com analfabetismo funcional elevado no ensino superior, com inúmeras distorções morais de todos os tipos. <br></br><br></br>
                <b><i>Que tipo de profissional você acha que tem mais chance de encontrar em seu caminho?<br></br><br></br>
                Como acha que seus processos serão tratados e julgados?</i></b><br></br><br></br>

                <br></br><br></br>
                <S.FormChat><img src={chart1} alt=""></img><span>Figura 1 - Despesas do Judiciário em 2018</span></S.FormChat>
                <br></br><br></br>

                </span>
            </S.FormText>

            </S.Form>
            </>
            :
            <>
            <br></br>
            <br></br>
            <h3>&nbsp;</h3>
            <br></br>
            </>    }  
        </S.Container>
    )
}

export default GuidePart2;