import React                from 'react';
import * as S               from '../../Book/Home/styles';
//import midia2               from '../../../../assets/midia2.png';

function BookTrailer() {

    //function buttonMidia1() {
    //    window.open('https://www.facebook.com/', '_blank');
    //}

    //function buttonMidia2() {
    //    window.open('https://www.youtube.com/watch?v=08XyBTUqk9Q', '_blank');
   // }

    function linkSite() {
        window.open('https://www.kodasware.com/', '_blank');
        return false;
    }

    return ( 
        <>
        <S.ContainerCopy><span>2022 &copy;&nbsp;<a href="#" onClick={linkSite}>KODASWARE</a>&nbsp;&nbsp;</span></S.ContainerCopy>
        </>
     );
}

export default BookTrailer;  

//<S.MidiaContainer>
//<           S.MidiaItem><S.MidiaButton onClick={buttonMidia2}><img src={midia2} alt=''></img></S.MidiaButton></S.MidiaItem>
//        </S.MidiaContainer>

//<S.ContainerCopy><span>2022 &copy;&nbsp;<a href="#" onClick={linkSite}>KODASWARE</a>&nbsp;&nbsp;All rights reserved.</span></S.ContainerCopy>