import React, {useState}                from 'react';
import * as S                           from './styles';
import guide24                          from '../Guide/Images/guide24.png'
import util                             from '../../../Utilities/Util'

function Element7() {

    const [english,    setEnglish]    = useState(false);
    const [spanish,    setSpanish]    = useState(false);
    const [portuguese, setPortuguese] = useState(false);

    React.useEffect(() => {
  
      const result = util.getLanguage();

      setEnglish   (result.english);
      setSpanish   (result.spanish);
      setPortuguese(result.portuguese);

      }, []);
 
    return (
        <S.Container>

            <S.FormImage><img src={guide24} alt=""></img></S.FormImage>

            { (portuguese) &&
            <S.ContainerData>
                <S.SideLeft>&nbsp;</S.SideLeft>
                <S.CenterData>
                    <S.Title>CONSEQUÊNCIAS</S.Title>
                    <S.FormDesc>
<span>O casamento de modo geral é um projeto de vida que visa um futuro melhor e mais feliz para um casal. O divórcio é o rompimento e falência desse ideal.<br></br><br></br><br></br>
<b>ELE É UM PROCESSO DESGASTANTE, DOLOROSO E ESTRESSANTE PARA AS PARTES ENVOLVIDAS E SEUS FILHOS</b>.<br></br><br></br><br></br>
É motivo de alegria e satisfação apenas quando finalizar uma relação de ódio, violência ou de sofrimento fisico e/ou mental.<br></br><br></br>
Não deve ser tratado como algo fútil ou de fácil realização.<br></br><br></br>
Pode vir acompanhado de grandes prejuízos pessoais e financeiros como também de sérios problemas emocionais decorrentes de sua realização.<br></br><br></br>
Baixa estima, medo, angustia, insegurança, insônia, isolamento são alguns dos problemas e emoções que ele pode produzir nos primeiros meses e/ou anos após sua concretização.<br></br><br></br>
O desenvolvimento de uma família é o resultado natural de um casamento. O grupo familiar fortalece e protege seus integrantes dos acontecimentos presentes e futuros.<br></br><br></br>
Atualmente, fazemos parte de uma sociedade de consumo, onde tudo é descartável, na qual procuramos nos desfazer de tudo aquilo que não queremos mais, como um sapato, uma roupa ou alguém que deixou de satisfazer as nossas necessidades emocionais e/ou pessoais.<br></br><br></br>
Essas ações são facilitadas e encorajadas por grupos e pessoas que não suportam a felicidade alheia, transformando esse procedimento em um negócio lucrativo, corriqueiro e de fácil utilização.<br></br><br></br><br></br>
<b>OS FILHOS SERÃO OS MAIORES PREJUDICADOS QUANDO UMA FAMÍLIA É DESFEITA</b>.<br></br><br></br><br></br>
O divórcio tira um direito básico de toda criança, o de sentir-se segura e protegida.<br></br><br></br>
O mundo é um lugar assustador para elas, a família completa é um lugar onde pode se sentir cuidada, orientada e consolada.<br></br><br></br>
Muitos filhos de pais separados irão crescer felizes e sem problemas aparentes, mas todos irão desenvolver algum tipo de sequela emocional, como insegurança, raiva, depressão, e etc., que afetará suas vidas futuras.<br></br><br></br>
Em um mundo injusto e competitivo estarão em desvantagem em relação aos seus concorrentes portadores de estruturas emocionais mais sólidas provenientes de uma educação familiar consistente.<br></br><br></br>
Nos últimos anos, de forma sinistra, sutil e invisível, <b><u>o papel do pai</u></b> vem sendo removido da sociedade humana.<br></br><br></br>
Pesquisas e estatísticas demonstram que existe uma relação direta entre a ausência da figura paterna na educação dos filhos e a criminalidade, mas as mesmas pesquisas não encontraram nenhuma relação quando existe a ausência da figura materna.<br></br><br></br>
Isso acontece porque o pai tem um papel fundamental na formação do caráter dos filhos.<br></br><br></br><br></br>
<b>AS MÃES FORNECEM AMOR, CARINHO E PROTEÇÃO</b>.<br></br><br></br>
<b>OS PAIS FORNECEM EXEMPLOS, ESTRUTURA, CORAGEM E CARÁTER</b>.<br></br><br></br><br></br>
Quando o pai é ausente ou impedido de exercer sua paternidade, geralmente os filhos são portadores de algum tipo de transtorno emocional que irá afetar de alguma forma sua personalidade.<br></br><br></br>
Além disso, os homens tendem a ser os maiores prejudicados nesse processo, pois raramente tem direito ao compartilhamento ou a guarda dos filhos, acentuando dessa forma o problema descrito.<br></br><br></br>
As consequências de um divórcio são imprevisíveis, podem produzir resultados satisfatórios ou não, mas o final de um relacionamento pode ser utilizado como uma oportunidade de avaliação e revisão de nossos papéis e atitudes perante a vida.<br></br><br></br></span>
                    </S.FormDesc>
                    <br></br> <br></br> <br></br>                     
                </S.CenterData>
                <S.SideRight>&nbsp;</S.SideRight>
            </S.ContainerData> 
            }

            { (spanish) &&
            <S.ContainerData>
                <S.SideLeft>&nbsp;</S.SideLeft>
                <S.CenterData>
                     <S.Title>CONSECUENCIAS</S.Title>
                     <S.FormDesc>
<span>El matrimonio en general es un proyecto de vida dirigido a un futuro mejor y más feliz para una pareja. El divorcio es la ruptura y la quiebra de este ideal.<br></br><br></br><br></br>
<b>ES UN PROCESO AGOTADOR, DOLOROSO Y ESTRESANTE PARA LAS PARTES IMPLICADAS Y SUS HIJOS</b>.<br></br><br></br><br></br>
Es motivo de alegría y satisfacción sólo al terminar una relación de odio, violencia o sufrimiento físico y/o psíquico.<br></br><br></br>
No debe ser tratado como inútil o fácil de lograr.<br></br><br></br>
Puede ir acompañado de grandes pérdidas personales y económicas, así como de graves problemas emocionales derivados de su realización.<br></br><br></br>
Baja autoestima, miedo, angustia, inseguridad, insomnio, aislamiento son algunos de los problemas y emociones que puede producir en los primeros meses y/o años después de su finalización.<br></br><br></br>
El desarrollo de una familia es el resultado natural de un matrimonio. El grupo familiar fortalece y protege a sus miembros de los acontecimientos presentes y futuros.<br></br><br></br>
Actualmente, somos parte de una sociedad de consumo, donde todo es desechable, en la que tratamos de deshacernos de todo lo que ya no queremos, como un zapato, un atuendo o alguien que ya no satisface nuestras necesidades emocionales y/o personales.<br></br><br></br>
Estas acciones son facilitadas y fomentadas por grupos y personas que no soportan la felicidad de los demás, transformando este procedimiento en un negocio rentable, cotidiano y fácil de usar.<br></br><br></br><br></br>
<b>LOS NIÑOS SERÁN LOS MAYORES PERJUDICADOS CUANDO UNA FAMILIA SE DESPRENDA</b>.<br></br><br></br><br></br>
El divorcio le quita un derecho básico a todo niño, a sentirse seguro y protegido.<br></br><br></br>
El mundo es un lugar aterrador para ellos, toda la familia es un lugar donde pueden sentirse cuidados, guiados y reconfortados.<br></br><br></br>
Muchos hijos de padres divorciados crecerán felices y sin problemas aparentes, pero todos desarrollarán algún tipo de secuela emocional, como inseguridad, ira, depresión, etc., que afectará a sus vidas futuras.<br></br><br></br>
En un mundo injusto y competitivo, estarán en desventaja frente a sus competidores con estructuras emocionales más sólidas provenientes de una crianza familiar consistente.<br></br><br></br>
En los últimos años, de forma siniestra, sutil e invisible, <b><u>el papel del padre</u></b> ha sido eliminado de la sociedad humana.<br></br><br></br>
Investigaciones y estadísticas muestran que existe una relación directa entre la ausencia de la figura paterna en la educación de los hijos y la criminalidad, pero las mismas encuestas no han encontrado relación cuando falta la figura materna.<br></br><br></br>
Esto sucede porque el padre tiene un papel fundamental en la formación del carácter de los hijos.<br></br><br></br><br></br>
<b>LAS MADRES BRINDAN AMOR, CUIDADO Y PROTECCIÓN</b>.<br></br><br></br>
<b>LOS PADRES DAN EJEMPLO, ESTRUCTURA, VALENTÍA Y CARÁCTER</b>.<br></br><br></br><br></br>
Cuando el padre está ausente o impedido para ejercer su paternidad, los hijos suelen tener algún tipo de trastorno emocional que afectará de alguna manera a su personalidad.<br></br><br></br>
Además, los hombres suelen ser los más afectados en este proceso, ya que rara vez tienen derecho a compartir o custodiar a sus hijos, acentuando así el problema descrito.<br></br><br></br>
Las consecuencias de un divorcio son impredecibles, pueden producir resultados satisfactorios o no, pero el final de una relación puede ser utilizado como una oportunidad para evaluar y revisar nuestros roles y actitudes ante la vida.<br></br></span>
                     </S.FormDesc>
                     <br></br> <br></br> <br></br>
                </S.CenterData>
                <S.SideRight>&nbsp;</S.SideRight>
            </S.ContainerData> 
            }           

            { (english) &&
            <S.ContainerData>
                <S.SideLeft>&nbsp;</S.SideLeft>
                <S.CenterData>
                    <S.Title>CONSEQUENCES</S.Title>
                    <S.FormDesc>
<span>Marriage in general is a life project aimed at a better and happier future for a couple. Divorce is the breakup and bankruptcy of this ideal.<br></br><br></br><br></br>
<b>IT IS AN EXHAUSTING, PAINFUL AND STRESSING PROCESS FOR THE PARTIES INVOLVED AND THEIR CHILDREN</b>.<br></br><br></br><br></br>
It is a reason for joy and satisfaction only when ending a relationship of hatred, violence or physical and/or mental suffering.<br></br><br></br>
It should not be treated as futile or easily accomplished.<br></br><br></br>
It can be accompanied by great personal and financial losses as well as serious emotional problems resulting from its accomplishment.<br></br><br></br>
Low esteem, fear, anguish, insecurity, insomnia, isolation are some of the problems and emotions that it can produce in the first months and/or years after its completion.<br></br><br></br>
The development of a family is the natural result of a marriage. The family group strengthens and protects its members from present and future events.<br></br><br></br>
Currently, we are part of a consumer society where everything is disposable in which we try to get rid of everything we no longer want, such as a shoe, an outfit or someone who no longer meets our emotional and/or personal needs.<br></br><br></br>
These actions are facilitated and encouraged by groups and people who cannot stand the happiness of others, transforming this procedure into a profitable, everyday and easy-to-use business.<br></br><br></br><br></br>
<b>CHILDREN WILL BE THE BIGGEST HARMED WHEN A FAMILY IS TORN UP</b>.<br></br><br></br><br></br>
Divorce takes away a basic right of every child, to feel safe and secure.<br></br><br></br>
The world is a scary place for them, the whole family is a place where they can feel cared for, guided and comforted.<br></br><br></br>
Many children of divorced parents will grow up happy and without any apparent problems, but they will all develop some kind of emotional sequel, such as insecurity, anger, depression, etc., which will affect their future lives.<br></br><br></br>
In an unfair and competitive world, they will be at a disadvantage in relation to their competitors with more solid emotional structures coming from a consistent family upbringing.<br></br><br></br>
In recent years, in a sinister, subtle and invisible way, <b><u>the role of the father</u></b> has been removed from human society.<br></br><br></br>
Research and statistics show that there is a direct relationship between the absence of a father figure in children\'s education and criminality, but the same surveys have not found any relationship when there is a lack of a mother figure.<br></br><br></br>
This happens because the father has a fundamental role in the formation of the children\'s character.<br></br><br></br><br></br>
<b>MOTHERS PROVIDE LOVE, CARE AND PROTECTION</b>.<br></br><br></br>
<b>FATHERS PROVIDE EXAMPLES, STRUCTURE, COURAGE AND CHARACTER</b>.<br></br><br></br><br></br>
When the father is absent or prevented from exercising his paternity the children usually have some kind of emotional disorder that will affect their personality in some way.<br></br><br></br>
In addition men tend to be the most affected in this process as they rarely have the right to share or custody of their children, thus accentuating the problem described.<br></br><br></br>
The consequences of a divorce are unpredictable they can produce satisfactory results or not, but the end of a relationship can be used as an opportunity to evaluate and review our roles and attitudes towards life.<br></br><br></br></span>
                    </S.FormDesc>
                    <br></br> <br></br> <br></br>
            </S.CenterData>
            <S.SideRight>&nbsp;</S.SideRight>
        </S.ContainerData> 
            }
        </S.Container>
    )
}

export default Element7;