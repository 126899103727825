import React, {useEffect, useState}   from 'react';
import * as S               from './styles';
import * as G               from '../../../global/globalStyles';
import * as globalVariable  from '../../../global/globalVariables';
import Navbar               from '../../../components/Navbar';
import Trailer              from '../../../components/Trailer';
import { i18n }             from '../../../translate/i18n';
import PoliceEn             from './htmlEn';
import PoliceEs             from './htmlEs';
import PolicePt             from './htmlPt';

function PolicePrivacy() {
    
    const [htmlEn, setHtmlEn] = useState(false);
    const [htmlEs, setHtmlEs] = useState(false);
    const [htmlPt, setHtmlPt] = useState(false);

    useEffect(() => {
        //window.scrollTo(0, 0);
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setLanguage();
      }, []);

      function setLanguage() { 
    
        setHtmlEn(false);
        setHtmlEs(false);
        setHtmlPt(false);

        let language  = window.localStorage.getItem(globalVariable.I18N_STORAGE_KEY);

        if (language === 'es-ES') {
            setHtmlEs(true);
            return;
        } 
        
        if (language === 'pt-BR') {
            setHtmlPt(true);
            return;
        } 

        setHtmlEn(true);
    }

    return (
        <S.Container>
            <Navbar></Navbar>

            <G.Title><span>{i18n.t('literals.policyPrivacy')}</span></G.Title>

            <G.FormText>
            { htmlEn && <PoliceEn></PoliceEn> }
            { htmlEs && <PoliceEs></PoliceEs> }
            { htmlPt && <PolicePt></PolicePt> }
            </G.FormText>

            <G.ContainerSpace></G.ContainerSpace>
            <G.ContainerSpace></G.ContainerSpace>

            <Trailer></Trailer>
        </S.Container>
    )
}

export default PolicePrivacy;