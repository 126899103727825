import React, { useState, useEffect } from 'react';   
import * as S       from './styles';
import guide11      from '../../../../view/DivorceGuide/Guide/Images/guide11.png';
import guide12      from '../../../../view/DivorceGuide/Guide/Images/guide12.png';
import guide13      from '../../../../view/DivorceGuide/Guide/Images/guide13.png';
import guide14      from '../../../../view/DivorceGuide/Guide/Images/guide14.png';
import guide15      from '../../../../view/DivorceGuide/Guide/Images/guide15.png';
import guide16      from '../../../../view/DivorceGuide/Guide/Images/guide16.png';
import guide17      from '../../../../view/DivorceGuide/Guide/Images/guide17.png';
import guide18      from '../../../../view/DivorceGuide/Guide/Images/guide18.png';
import guide19      from '../../../../view/DivorceGuide/Guide/Images/guide19.png';
import guide20      from '../../../../view/DivorceGuide/Guide/Images/guide20.png';
import util         from '../../../../Utilities/Util';

function GuidePart3() {

    const [access, setAccess] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)
        setAccess( util.checkGuideAccess() );
    }, [])

    return (
        <S.Container>
        { ( access )
            ?
            <>
            <S.Form> 

            <S.FormTitle>
				<span>PART 3</span><br></br><br></br><br></br><span>Components of Justice</span><br></br><br></br>            
			</S.FormTitle>

            <S.FormText>
                <span>

                Justice is an abstract concept. It is an ideal to achieve, something immaterial that cannot be seen or touched. What exists are institutions that try to give substance to this concept in the real world. <br></br><br></br>
                Therefore, several components rules and procedures are developed to make it visible and its use possible for everyone. People will be added to this group to control and manage its operation. Hierarchies, roles and titles will be created and distributed among them. <br></br><br></br>
                In short, it will be a system established with the aim of trying to reproduce and apply in reality the justice dreamed of and idealized by society.<br></br><br></br>
                <b><i> However, it is only a human attempt to materialize it.</i></b><br></br><br></br>
                No State, person or machine has the capacity to carry out and reproduce justice in its essence. There will always be failures and distortions. Including the production of its antonym <b>injustice</b>. <br></br><br></br>
                Furthermore, these institutions are controlled by people. They are motivated by personal interests many times different from the main objective for which this system was created and from what society wants and needs. <br></br><br></br>
                Over time, this system could become something totally different from the original both in principles and in objectives. Something endowed with great power over people's lives and destiny, but can never be questioned or controlled by them. 
                Only by restricted and unknown groups of people or institutions directly or indirectly linked to it. <br></br><br></br>
                It can also grow too large, requiring large financial resources to operate. Producing delays and distortions in their results. It can become a faulty, corrupt and non-functioning system. 
                It will be the only place where people will be able to seek compensation and protection of their rights. <br></br><br></br> 
                Therefore, it is essential to know some of its components in order to know how to act in situations and events generated by this system.
                
                <br></br><br></br><br></br><br></br>
                <h2>Civil Law</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide11} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                Law is the mechanism created by human society that studies and applies laws so that justice is achieved and implemented in a certain sector. 
                It is the mechanism that regulates the rights and obligations of a private nature with respect to people, their rights and obligations, assets and their relationships with other individuals in society. <br></br><br></br>
                Divorce is a procedure regulated by the civil law of each country which may vary according to the geographical region, culture and morality of the society that defined it.
     
                <br></br><br></br><br></br><br></br>
                <h2>Spouses</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide12} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                They are the people who are in a matrimonial relationship agreed upon and ratified by the courts. This agreement implies rights and duties for both that are regulated and defined by the Civil Law of each country.<br></br><br></br>
                For these reciprocal rights and duties to cease to exist it is necessary for a divorce process to be approved by the court.<br></br><br></br>
                When in the marital relationship there are minor or incapable children, the rights and duties towards them subsist until the age of majority or court ruling.

                <br></br><br></br><br></br><br></br>
                <h2>Family</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide13} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                It is a specific human group made up of common ancestors or people united by emotional ties who normally live together and work for their own benefit. <br></br><br></br>
                <i><b>The family is the basic unit of society</b></i>. <br></br><br></br>
                It is through it that individuals acquire their psychic structure and develop their character. Generally formed by a heterosexual couple and their children. <br></br><br></br>
                Currently, this concept is being modified and families are being grouped in different ways, namely:<br></br><br></br>
                
                <table border="1">
                <tbody>
                <tr><td>&nbsp;<b>Marriage</b>&nbsp;</td><td>&nbsp;Family formed by marriage, both between heterosexual and same-sex couples.&nbsp;</td></tr>
                <tr><td>&nbsp;<b>Informal</b>&nbsp;</td><td>&nbsp;Family formed by stable union, both between heterosexual and homosexual couples.&nbsp;</td></tr>
                <tr><td>&nbsp;<b>Single parent</ b>&nbsp;</td><td>&nbsp;Family formed by a single father and his descendants. &nbsp;</td></tr>
                <tr><td>&nbsp;<b>Anaparental</b>&nbsp;</td><td>&nbsp;Family formed only by siblings without parents.&nbsp;</td></tr>
                <tr><td>&nbsp;<b>Single</b>&nbsp;</td><td>&nbsp;Family made up of one person.&nbsp;</td></tr>
                <tr><td>&nbsp;<b>Homoaffective</b>&nbsp;</td><td>&nbsp;Family formed only by people of the same sex.&nbsp;</td></tr>
                <tr><td>&nbsp;<b>Mosaic</b>&nbsp;</td><td>&nbsp;Family formed by the union of families of separated parents.&nbsp;</td></tr>
                <tr><td>&nbsp;<b>Parallel</b>&nbsp;</td><td>&nbsp;People who are in more than one stable relationship.&nbsp;</td></tr>
                <tr><td>&nbsp;<b>Eudemonist</b>&nbsp;</td><td>&nbsp;Family made up of socio-affective people.&nbsp;</td></tr>
                </tbody>
                </table>

                <br></br><br></br><br></br><br></br>
                <h2>Children</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide14} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                It is the designation given to the descendants of a couple. They represent the continuity of their parents and individuals in a society. <br></br><br></br>
                <i><b>Children are the main and most important components of a family</b></i>. <br></br><br></br>
                The family provides the environment and all the necessary structure for the physical and mental development of the couple's children. These factors will be responsible for the positive and safe emotional development of children, so that they learn to face different situations in life. <br></br><br></br>
                That's why it's important to grow up in a home where both parents are present and offer unconditional support, comfort and protection, but when a family is torn apart they are the ones who suffer the most. <br></br><br></br>
                Even on an unconscious level, they feel that they have lost the family protection they had even though a new and better structure is created. Many children of separated parents will grow up happily and without apparent problems, but all will develop some kind of emotional sequelae such as insecurity, anger, depression, which will affect their future lives. <br></br><br></br>
                In an unfair and competitive world they will be at a disadvantage compared to their competitors who have stronger emotional structures that come from a consistent family upbringing. <br></br><br></br>
                <b><u>MEN ARE THE MOST AFFECTED IN THIS PROCESS AND VERY FEW HAVE THE RIGHT TO SHARE OR CUSTODY OF THEIR CHILDREN</u></b>. <br></br><br></br>
                When the father is absent or is prevented from exercising his role as a father the children are usually carriers of some type of emotional disorder that will affect their future in some way. <br></br><br></br>
                This happens because <b><u>THE FATHER HAS A FUNDAMENTAL ROLE IN THE FORMATION OF THE CHARACTER OF THE CHILDREN</u></b>.

                <br></br><br></br><br></br><br></br>
                <h2>Lawyer</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide15} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

				Unlike books and movies we cannot exercise self-defense it is mandatory to use a lawyer. <br></br><br></br>
                Whatever the type of divorce, friendly or contentious the presence of a lawyer is mandatory. <br></br><br></br>
                Amicable divorces tend to be quick and cheap, however when there is any kind of disagreement especially if children are involved the whole picture becomes even more uncertain. 
                In this case, the chosen defender becomes a key player in this dispute.<br></br><br></br>
                <b><i> It is the main element to produce victory or defeat</i></b>. <br></br><br></br>
                Selecting a highly qualified professional is necessary for the lowest possible gain or loss.<br></br><br></br> 
                Unfortunately, they are difficult to locate and expensive. <br></br><br></br>
                Finding a lawyer through some indication is one way to select someone qualified. 
                However, great care must be taken in this operation. 
                It is necessary to verify if the indicated professional is specialized in family justice and if he has a high or at least reasonable rate of profit. <br></br><br></br>
                It is essential to reject inexperienced, cheap and/or multifaceted lawyers. Those who do everything specialize in nothing. <br></br><br></br>
                No one should put the fate of their lives and processes in the hands of any professional who comes along. 
                Especially when their competence and performance is unknown. <br></br><br></br>
                It is essential to reject family members who carry out this activity to avoid family problems or embarrassment when discarding them. <br></br><br></br>
                Keep in mind that none of them will suffer any consequences for the actions carried out and they will always be paid regardless of the results obtained.<br></br><br></br>
                <i><b>Never, under any circumstances, seek a lawyer to advise you on the decision of whether or not to go through with a divorce.</b></i>

                <br></br><br></br>
                Seek a qualified professional <u><b>ONLY</b></u> when this procedure has already been decided and there is no turning back.

                <br></br><br></br><br></br><br></br>
                <h2>Law Judge</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide16} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                It is the person selected by the State to mediate and resolve the existing conflict between the parties. <br></br><br></br>
                He will listen to those involved and their witnesses as well as study and evaluate the documents presented impartially and in accordance with current regulations and laws. 
                To carry out this task he has administrative and judicial powers. 
                The administrative ones regulate the development of the process such as: expel any person from the room, order the detention of witnesses, etc.
                The judicial ones regulate the development of the process such as: request for documents and clarifications, appointment of experts, issuance of orders, issuance of verdicts, etc. <br></br><br></br>
                The judge must always practice equal treatment of the parties and to the extent possible resolve the problem as quickly as possible. He must also enforce his orders and avoid unnecessary procedural acts.<br></br><br></br>
                Everything is very beautiful on paper and in theory. However, in practice everything is different. There has been no equality in justice for a long time. The laws are severe and destructive only for one side and mild and advantageous for the other. <br></br><br></br>
                There are exceptional and insightful judges, but they are a restricted minority. What predominates are professionals without apathy who live in a parallel universe beyond the reality that surrounds us. 
                Their decisions are patterned, irrational and backed by unfair and destructive laws.
                There is no mercy or humanity in them.<br></br><br></br><b>
                They never suffer the consequences of their decisions</b>.<br></br><br></br >
                <br></br><br></br>                
				
				<b>Rotation of Judges</b><br></br><br></br>
                Cases keep changing judges all the time. Every process must be judged by the same person until its conclusion, but in practice this does not happen. <br></br><br></br>
                The Justice considers that the documents presented and everything that appears in the request is sufficient for decision-making which can be carried out by any selected person. 
                This can be beneficial when switching to a more qualified professional. However, the opposite could also occur. <br></br><br></br>
                The completion time and the quality of the result will be greatly affected by this rotation. 
                The new judge will have to study and evaluate the case again may require further clarification or other actions which may require time and money. <br></br><br></br>
                You can even modify decrees that have already been established. Any empathy that may have occurred in an in-person hearing will be erased. 
                As he will not know the spouses any misspelled or misinterpreted words in the lawsuit may be responsible for erroneous conclusions. <br></br><br></br>
                Not to mention it's hard and exhausting work and no one will do it of their own free will. Therefore, decisions are more likely to be standardized. <br></br><br></br>
                The defender has the possibility of requesting in the body of the case that he be judged by a specific judge for reasons of affinity with the data of the case. <br></br><br></br>
                However, they do not do so because the court usually does not comply with this request or they themselves do not want to disagree with the judge selected for the case. 
                It is up to you to require this action from your defense if you believe that the initial judge was in your favor or proved to be fair and competent.

                <br></br><br></br><br></br><br></br>                
				<h2>Time</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide17} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                Time is abstract to us. It is something that we cannot see or manipulate we can only feel its effects. 
                Through this perception we can create mechanisms and measures to establish the duration of a certain event and based on the values obtained determine if they are acceptable or harmful. <br></br><br></br>
                When we talk about justice the measured values are simply unacceptable. <br></br><br></br>
                The time it takes to complete a claim is very high.
                The consequences of all this slowness of the judiciary is the production of enormous personal and economic losses.<br></br><br></br>
                When there is no justice there is no punishment. Thus the doors remain open for new violations and impunity generating new processes that will further impact the time for justice producing an infinite circle of frustrations leaving lives tied up and indefinite. <br></br><br></br>
                When the decisions finally arrive they are either innocuous or insufficient to repair the damage and pain caused. 
                Victims will be the most harmed in this system and offenders will benefit the most. <br></br><br></br>
                It is a complete reversal of the values and purposes for which justice was created.

                <br></br><br></br><br></br><br></br>
                <h2>Rates and Costs</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide18} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                <i><b>Money is the raw material of justice.</b></i><br></br><br></br>
                It is not possible to perform any procedure without it. It will be obtained from the parties in dispute through the collection of fees, costs, fines, rates, emoluments or any other denomination. <br></br><br></br>
                These rates are prepared by the courts themselves and vary depending on the region and the jurisdictional body in charge of their creation and collection. <br></br><br></br>
                Generally, they are encapsulated with protection measures that always keep them above inflation rates or any financial loss.
                There is no standardization of the collection systems for these procedural fees. Each scenario changes its name and required value. <br></br><br></br>
                They are created by specific laws to make them legal and fit for use. They are endowed with various collection and sanction mechanisms that aim to prevent their evasion and make their collection more efficient. <br></br><br></br>
                These charges never expire and are always subject to penalties, interest and different types of correction. 
                Where small amounts can become numbers in the order of thousands or millions in a few months or years surpassing any existing financial application on the planet. <br></br><br></br>
                <i><b>In short, justice uses its own currency creates the charges it needs through exclusive laws.</b></i>

                <br></br><br></br><br></br><br></br>
                <h2>Laws</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide19} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>
                Life in society needs rules of conduct that discipline the interactions between people in order to achieve a common good. <br></br><br></br>
                It is the function of the State to regulate the individuals of a society through a set of written regulations developed for this purpose. <br></br><br></br>
                We call this set <u><b>LAW</b></u>. <br></br><br></br>
                They can vary according to the historical period, the geographical region and the customs of a society. 
                They must be administered by the State which must ensure that all the members it represents are <u>equal in rights and obligations</u>.
                Thus, justice can be defined as compliance with the laws of a society.<br></br><br></br>
                However, in practice the reality is quite different. Many laws are outdated and do not meet current needs. Many laws are <b>illogical, irrational and unfair</b> causing incalculable material and personal damage. <br></br><br></br>
                The cost of a law for society is not measured. A new law takes at least ten years to reach a point of pacification and interpretation in the courts, but that is not considered. <br></br><br></br>
                Laws are created and implemented without their cost or impact being properly calculated. One law can be in disagreement with another generating financial exhaustion and misinterpretations. <br></br><br></br>
                The laws should be simple and egalitarian, but currently they are produced only to favor minorities with high costs for their operation. They are being customized to serve and favor specific groups.
                Its creators are immune to the effects and consequences of these laws because they are in a privileged political and social situation. <br></br><br></br>
                We spend billions a year just to maintain a system that doesn't work and doesn't produce satisfactory results. <br></br><br></br>
                Money that could be used for the benefit of society as a whole. Producing truly satisfactory results, improving living conditions generating more opportunities, building a more just, peaceful and cordial environment, significantly reducing the numbers of violence and crime. <br></br><br></br>
                Even through indisputable numbers and evidence that this gigantic legal structure does not work and that it probably will never work we prefer to keep quiet and believe that it is only a matter of adjustment of tougher laws with greater penalties or of creating new laws <br></br><br></br><br></br>
                <i><b>Currently, the laws incorporated into the judicial system are sexist and do not obey the principles of equality and presumption of innocence basic for the creation of justice in any Democratic State of Law.</b></i>

                <br></br><br></br><br></br><br></br>
                <h2>Ceremonial</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide20} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                Justice has its own language of communication and behavior. <br></br><br></br>
                There are rituals and attitudes that must be used among its components. It is essential to know them to know how to behave and act against these elements. <br></br><br></br>
                Some cases require the parties to appear before a judge while others do not. All processes must be face-to-face. 
                It is very comfortable to legislate without the presence of the parties not to be affected by their looks or appeals or observe their reactions to sentences whether or not they are unfair. <br></br><br></br>
                Most Family Court cases consist solely of written language that is exchanged between judges and attorneys. The truth does not matter and it is not obtained from testimonials or face-to-face reactions. <br></br><br></br>
                On paper, everyone can be a victim or a villain everything will depend on what is written and the imagination of whoever writes or reads it.
                Usually, the characters in these stories are already known as well as their plot and ending, but the first chapters of it are carried out with the presence of the parties and their representatives. <br></br><br></br>
                Unfortunately, justice levels its professionals down. Most of them do not need to know how to speak nor have an eloquence that can change the destiny of their clients.<br></br><br></br>
                They usually don't say anything just ask a few questions or answer a few standard questions. They will not defend them at all costs nor will they disagree with the judge. <br></br><br></br>
                It will be a silent execution since the parties cannot speak only their defenders who as has already been said do not say anything. <br></br><br></br>
                Procedures to follow:
                <br></br><br></br>

				<table border="1">
                <tbody>
                <tr><td bgcolor='#EAEAEA' ><b>Timeliness&nbsp;&nbsp;&nbsp;</b></td><td>Check with the lawyer about the place and time of the hearing. <br></br>Request by email that this information be documented. <br></br>Arrive at the hearing location early, preferably an hour in advance.</td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>Material</b></td><td>It will be recorded in the forum entry where the hearing will take place. <br></br>Do not carry any type of weapon such as: knife, penknife, scissors, etc. <br></br>Do not take anything that could compromise you in any way.</td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>Appearance</b></td><td>Wear simple, clean and formal clothing. <br></br>Avoid showing signs of wealth or ostentation. <br></br>Be clean, combed and shaved. <br></br>Bring damp cloths to wipe the sweat from your face before entering the courtroom and other necessary accessories to maintain your presentation.</td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>Attitude</b></td><td>Speak only when asked. <br></br>Keep an upright posture. <br></br>Never lower your head. <br></br>Look people directly in the eye. <br></br>Do not look away from those who ask. <br></br>Speak firmly and calmly. <br></br>Don't use slang or profanity when speaking. <br></br>Always use the title by which the judge wants to be called. <br></br>
                If you don't know, use <u>sir or ma'am</u>, but never stop using polite words. <br></br>Use simple and direct language don't try to embellish it. <br></br>
                Don't stutter. If it happens take a deep breath and speak slowly. <br></br>The attorney for the party may make unfounded accusations or try to put you out of your mind. <br></br>
                Don't play this game don't show reactions answer calmly.<br></br>Always tell the truth. <br></br>If lying is necessary be careful with <b>body language</b>.</td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>Checklist</b></td><td>Always carry a list of all items that need to be addressed at the hearing. <br></br>Do not miss out on all of them having been discussed with a completion date and defined penalties. <br></br>
                They must be described and completed in the sentence that the judge will issue at the end of the hearing. <br></br><br></br><b>Never stop doing this</b>. <br></br><br></br>They are always forgotten, and end up leaving many gaps in the sentence. <br></br>Don't worry about hearing time. <br></br>
                This is an uncomfortable situation and we want the hearing to end soon.<br></br>Take as much time as you feel necessary.<br></br> <b>Your life is on the line</b>.<br></br></td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>Defense</b></td><td>If the lawyer is unfit break all protocol. <br></br>Forget all the guidance described and make your own defense. <br></br>Only you will suffer the consequences of this hearing and no one else. <br></br><b>If necessary fight to survive never be silent.</b></td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>Language</b></td><td>Justice uses technical language that can confuse ordinary people who are not used to it. <br></br>It is interesting to know her to be able to defend herself. <br></br>In addition, Western justice is based on Greco-Roman culture. <br></br>Because of this many terms are written in Latin making it difficult to understand.</td></tr>
                </tbody>
                </table>

                <br></br><br></br>
                <b>Body Language</b><br></br><br></br>
                <u>Body signs that indicate a lie</u>:<br></br><br></br>

				<table border="1">
                <tbody>
                <tr><td>&nbsp;Cover or place hand over mouth when speaking.&nbsp;</td></tr>
                <tr><td>&nbsp;Avoid eye contact.&nbsp;</td></tr>
                <tr><td>&nbsp;Pinch lips.&nbsp;</td></tr>
                <tr><td>&nbsp;Look up and to the right.&nbsp;</td></tr>
                <tr><td>&nbsp;Frown.&nbsp;</td></tr>
                <tr><td>&nbsp;It becomes static. Restrict hand and arm movements.</td></tr>
                <tr><td>&nbsp;Having stiff, repetitive movements.&nbsp;</td></tr>
                <tr><td>&nbsp;Shrink the head.&nbsp;</td></tr>
                <tr><td>&nbsp;Change one's own body many times.&nbsp;</td></tr>
                <tr><td>&nbsp;Shrug just one shoulder.&nbsp;</td></tr>
                <tr><td>&nbsp;Move away from the interlocutor.&nbsp;</td></tr>
                </tbody>
                </table>

                <br></br><br></br>
                </span>
            </S.FormText>

            </S.Form>
            </>
            :
            <>
            <br></br>
            <br></br>
            <h3>&nbsp;</h3>
            <br></br>
            </>    }  
        </S.Container>
    )
}

export default GuidePart3;