
import React, { useEffect, useState } from 'react';   
import * as S       from './styles';
import util         from '../../../../Utilities/Util';

function Attach() {
    
    const [access, setAccess] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)
        setAccess( util.checkGuideAccess() );
    }, [])

    return (
        <S.Container>
        { ( access )
            ?
            <>
            <S.Form> 

            <S.FormTitle>
                <span>ADJUNTO</span><br></br>
            </S.FormTitle>

            <S.FormText>
                <span>
				<h2>Aviso importante</h2>
                <br></br>
                <i> BAJO NINGUNA CIRCUNSTANCIA PRODUCIR NINGÚN ACTO DE VIOLENCIA FÍSICA O PSICOLÓGICA CONTRA LA OTRA PARTE O CUALQUIER PERSONA INVOLUCRADA, ANTES O DESPUÉS DEL DIVORCIO.<br></br><br></br>
                NO BUSQUE VENGANZA NI NINGÚN TIPO DE REPRESALIAS. <br></br><br></br>
                AUNQUE LA JUSTICIA SEA DEFICIENTE, UTILICE SIEMPRE LOS MEDIOS LEGALES Y JUDICIALES PARA SOLUCIONAR SUS PROBLEMAS.<br></br><br></br>
                PREFIERE SER FELIZ Y RECONSTRUIR TU VIDA.</i>
                <br></br><br></br>
                <br></br><br></br>
                <h2>Acciones y actitudes</h2>
                <br></br>
                Antes, durante y después del divorcio, lleve un <b><u>diario</u></b> describiendo todos los eventos, con fecha, hora, nombres, detalles, audio, video, fotos o cualquier otro documento relevante. <br></br><br></br>
                Esto será muy importante en el futuro, especialmente en defensa de cualquier demanda que pueda recibir.<br></br><br></br>
                Escanee siempre todos sus documentos, facturas, correos electrónicos, etc. Proteja todo con contraseña y guárdelo en un lugar seguro y inaccesible.<br></br><br></br>
                La tinta de tus facturas y/u otros documentos desaparece con el tiempo, en unos días en algunos casos, por lo que es importante escanearlos.<br></br><br></br>
                Ten siempre una copia de todo actualizada y almacenada en la <i>"Cloud"</i> de algún proveedor.<br></br><br></br>
                Proteja su computadora y celular con contraseña, si es posible, vuelva a formatear o cambie de equipo.<br></br><br></br>
                Cambia la contraseña de todas tus cuentas de correo electrónico, preferiblemente crea nuevas y elimina las anteriores.<br></br><br></br>
                Cierre cualquier cuenta o servicio en Internet que pueda difamar a su personaje, como: sitios de citas, pornografía, sitios de apuestas, etc.<br></br><br></br>
                Tenga cuidado, se pueden estar registrando datos falsos y comprometedores en su equipo sin su conocimiento con el fin de desmoralizarlo o incriminarlo en el futuro.<br></br><br></br>
                Es posible que se abran cuentas a tu nombre en sitios de citas, o en cualquier otro lugar que pueda dañarte, por lo que es importante tener nuevas cuentas de correo electrónico. <br></br><br></br>
                Sin embargo, esto no impide que se lleven a cabo este tipo de operaciones, por lo que debe estar atento a los correos electrónicos recibidos.<br></br><br></br>
                Su teléfono fijo, celular, computadora o cualquier otro equipo de comunicación puede ser intervenido. <br></br><br></br>
                Discuta y intercambie información confidencial en persona, a través de teléfonos públicos o cualquier otro medio seguro y confiable.<br></br><br></br>
                Si descubre que su equipo tiene micrófonos ocultos, use este hecho a su favor, transmitiendo información falsa para confundir y/o dañar a la otra parte.<br></br><br></br>
                Tenga cuidado con las palabras habladas o escritas, también pueden usarse en su contra. <br></br><br></br>
                Obtenga la mayor información posible sobre las acciones y intenciones de la otra parte, si es necesario contrate una agencia de investigación para que lo guíe y realice este servicio. <br></br><br></br>
                Presta atención a las personas que entran y salen de tu residencia, especialmente <u>trabajadoras del hogar</u>, amigos y familiares del otro lado, etc. <br></br><br></br>
                Estas personas serán utilizadas por la parte como testigos en procedimientos futuros. <br></br><br></br>
                Darán falso testimonio sin ningún tipo de vergüenza y informarán cualquier cosa que se les ordene decir.<br></br><br></br>
                Haz lo mismo, coloca amigos y familiares dentro de tu residencia, ten la mayor cantidad de relaciones sociales posibles con personas de confianza y graba todo en audio, video y redes sociales. <br></br><br></br>
                Esté atento a actitudes o acciones dirigidas a usted con el objetivo de sacarlo de la seriedad.<br></br><br></br>
                Especialmente después del divorcio, mantén una distancia mínima de <u>un metro</u> entre tú y la otra parte, <u>siempre</u> con las manos a la espalda. <br></br><br></br>
                Nunca reaccione o trate de sujetar la parte si es atacado, empujado o amenazado, si es necesario, huya del lugar y pida ayuda. De lo contrario, cualquier cosa será un acto de violencia producido por ti. <br></br><br></br>
                <u>No caigas en esa trampa</u>. <br></br><br></br>
                En términos financieros, en la medida de lo posible, elimine todas las deudas, préstamos, financiaciones, facturas y boletas bancarias a su nombre. <br></br><br></br>
                También es importante llevar a cabo el divorcio con el menor número de bienes a compartir.
                Por ejemplo, si tienes un vehículo financiado a tu nombre, véndelo y liquida la deuda.
                De lo contrario, cuando se presente el divorcio, deberá seguir pagando la hipoteca, y no podrá venderla hasta que el Juez decida su destino.<br></br><br></br>
                No corras detrás de una nueva relación solo para demostrar que tienes a alguien, que eres libre y feliz.<br></br><br></br>
                Deje que las cosas sucedan naturalmente y en el momento adecuado.
                No te dejes dominar por tu ego, orgullo u opiniones de otras personas.<br></br><br></br>
                
				<u><b>IMPORTANTE</b></u>:<br></br><br></br>
                Nunca hagas la alienación de los padres sobre tus hijos. No permitas que ningún familiar (abuelos, hermanos, tíos, primos, etc.), amigos o personas cercanas hagan esto. Nunca ponga a sus hijos en contra de la otra parte, no hable mal de ella, ni discuta con ellos las razones del divorcio.
                No cree ni comente situaciones en las que la parte pueda ser responsable, incluso si es cierto.
                Si te preguntan algo, diles que solo responderás cuando sean adultos.
                En situaciones en las que se trate de dinero extra, tales como: viajes, paseos, juguetes, etc., nunca culpe a la otra parte ni sea el villano de la historia, si no es posible su realización.
                Diga que lo pensará y discutirá la situación con la otra parte. Ninguno de ustedes debe presentarse como héroe o villano en esta historia, aunque solo uno de ustedes sea responsable de su eficacia o impedimento.
                <br></br><br></br>
                No seas alienador, no descargues tus heridas y resentimientos en tus hijos.
                Si la otra parte no hace nada de esto, reunir la mayor cantidad de pruebas testimoniales y documentales en su contra y presentar una demanda de alienación parental para proteger a sus hijos contra este tipo de violencia.

                <br></br><br></br>
                <h2>Mensaje final</h2>
                <br></br>
                <i>SI EL DIVORCIO YA ES UNA REALIDAD EN TU VIDA.
                SI HABÉIS PERDIDO LA GUARDIA DE VUESTROS HIJOS, SÉ AUXILIADOS, TRISTE Y HUMILLADO.
                TU LUCHA NO TERMINA AQUÍ, HABRÁ OTRAS BATALLAS DONDE SERAS EL GANADOR.
                EL TIEMPO CORREGIRÁ TODO ESO, PERO DEPENDERÁ DE TU ACTITUD Y POSTURA ANTE LA VIDA.
                RECONSTRUYELO POR DIFÍCIL QUE SEA.
                SI APARECEN MÁS PROBLEMAS, ALREDEDE DE ELLOS Y SIGA ADELANTE.
                SE ABRIRÁN CAMINO, PERO NO LOS VERÁS SI TE QUEDAS ABIERTO EN EL VICTIMISMO, EL ORGULLO, LA TRISTEZA Y EN TU PASADO.
                CONVIERTE EL POCO TIEMPO QUE AÚN TIENES CON TUS HIJOS EN ALGO ESPECIAL, EN LO MÁS IMPORTANTE DEL MUNDO.
                NO QUIERA SEDUCIRLOS CON COSAS MATERIALES.
                SER UN PADRE PRESENTE, SU MEJOR AMIGO.
                QUIERA O NO, EL FUTURO LLEGARÁ.
                DE TI DEPENDE SI SERÁ MARAVILLOSO O NO.<br></br><br></br>
                TENER FE, VALOR Y DETERMINACIÓN.<br></br><br></br>
                ¡BUENA SUERTE!</i>
                <br></br><br></br>
                </span>
            </S.FormText>

            </S.Form>
            </>
            :
            <>
            <br></br>
            <br></br>
            <h3>&nbsp;</h3>
            <br></br>
            </>    }  
        </S.Container>
    )
}

export default Attach;