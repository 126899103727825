
import React, { useEffect, useState } from 'react';   
import * as S       from './styles';
import guide05      from '../../../../view/DivorceGuide/Guide/Images/guide05.png';
import guide06      from '../../../../view/DivorceGuide/Guide/Images/guide06.png';
import guide07      from '../../../../view/DivorceGuide/Guide/Images/guide07.png';
import guide08      from '../../../../view/DivorceGuide/Guide/Images/guide08.png';
import guide09      from '../../../../view/DivorceGuide/Guide/Images/guide09.png';
import guide10      from '../../../../view/DivorceGuide/Guide/Images/guide10.png';
import util         from '../../../../Utilities/Util';

function GuidePart2() {
    
    const [access, setAccess] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)
        setAccess( util.checkGuideAccess() );
    }, [])

    return (
        <S.Container>
        { ( access )
            ?
            <>
            <S.Form> 

            <S.FormTitle>
				<span>PARTE 2</span><br></br><br></br><br></br><span>La realidad en la que estamos insertos</span><br></br><br></br>
             </S.FormTitle>

             <S.FormText>
                <span>
                La realidad es un concepto ampliamente debatido en el campo de la filosofía. Es algo que tiene distintas definiciones y que varía según el contexto donde se estudie y debata. Para facilitar nuestra comprensión, podemos decir que la realidad es cómo son las cosas en realidad. <br></br><br></br>
                Las personas en general desconocen su propia realidad. Actúan y toman decisiones basados ​​en ilusiones creadas en sus mentes. Suelen ser alimentados y producidos por la sociedad donde viven. Forman sus ideas y conceptos a partir de películas, telenovelas y otros medios existentes. Creen que en un juicio todos luchan por la verdad y la justicia, que sus defensores harán cualquier cosa por ellos, que el bien siempre triunfa sobre el mal, etc. <br></br><br></br>
                Desafortunadamente, el mundo real es sombrío y injusto para la mayoría de las personas y solo privilegia a una minoría. Nadie busca información ni busca la verdad. Prefieren el consuelo de la mentira y la ilusión para no tener que cambiar sus creencias y costumbres, ni la sociedad en la que están insertos. Sin embargo, están obligatoriamente obligados a vivir con la verdad cuando algo se rompe en sus vidas. <br></br><br></br>
                Es precisamente en esos momentos de dolor y desorientación cuando descubren que han estado viviendo una mentira. Se dan cuenta de que las instituciones, las leyes y sus componentes no funcionan. Que las personas a tu alrededor no eran de confianza, ni amigos. Ser forzados a despertar a la realidad que no conocían o querían evitar.

                <br></br><br></br><br></br><br></br>
                <h2>Diferencias entre hombres y mujeres</h2>                
				<br></br><br></br>
                <S.FormImage><img src={guide05} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                <i><b>Las leyes son sexistas y solo favorecen a las mujeres.</b></i> <br></br><br></br>
                Los hombres son los mayores perdedores en los procesos de divorcio. Raramente ganan una demanda, especialmente si hay niños involucrados. Lo máximo que pueden hacer es mitigar sus pérdidas y eso solo ocurre si tienen suerte y adelantan la planificación a su favor. Cuando hay hijos, todo dependerá del carácter de la mujer involucrada en la disputa. 
                Porque en tales casos los hombres no tienen derecho sobre los niños. Sólo tienen el deber de pagar los gastos y ser sancionados cuando no cumplan con esta obligación. Estar desempleado, enfermo o cualquier otro impedimento nunca será mitigado. Toda tregua otorgada por la justicia deberá ser reparada con intereses, corrección y multa después de su normalización. <br></br><br></br>
                <b>Tendrás que gastar mucho dinero para demostrar que no tienes dinero</b>.<br></br><br></br>
                Después del divorcio, incluso en silencio, la sociedad y las instituciones tratarán a los hombres como criminales. Cualquier abuso sufrido por la parte ganadora nunca será tenido en cuenta. No hay lugar ni institución para denunciarlos. Las comisarías se niegan a registrar cualquier vulneración de sus derechos, los cuales, como se ha descrito anteriormente, no existen.<br></br><br></br>
                Es necesario realizar un nuevo proceso legal, que consume tiempo y dinero para intentar cualquier cosa. Cuando se siguen, solo generan advertencias al culpable y nada más.<br></br><br></br>
                La mujer actualmente se encuentra rodeada de privilegios y derechos que no dejan de crecer. Ha pasado mucho tiempo desde que se superó el punto de equilibrio. Este desequilibrio producirá cada vez más sufrimiento a las partes involucradas en un proceso de divorcio, así como a sus hijos y familiares<br></br><br></br>
                <i><b>Ya no se respetan los principios constitucionales de igualdad.</b></i><br></br><br></br>
                Los hombres son solo proveedores en un sistema de esclavitud.<br></br><br></br>
                Hombres y mujeres deben ser iguales en derechos y obligaciones, es uno de los principales pilares de la igualdad jurídica defendida como derecho fundamental por todas las naciones democráticas del mundo. <br></br><br></br>
                Sin embargo, estos principios ya no se respetan ni se utilizan. Las mujeres se presentan como víctimas exigiendo cada vez más leyes y políticas de protección. <br></br><br></br>
                Argumentan que reciben salarios más bajos en comparación con los hombres, pero no presentan una sola víctima de este reclamo. Ya existen leyes laborales estrictas que previenen este tipo de abuso o distorsión, pero esto es ignorado por aquellas que exigen la creación de cuotas sobre las vacantes disponibles.<br></br><br></br>
                Actualmente, ya ocupan los mejores puestos de trabajo, basta con mirar las empresas existentes. El piso de la fábrica, que es la parte más sucia, pesada y menos pagada, está mayoritariamente ocupado por hombres, mientras que la parte administrativa, que es más limpia, tranquila y mejor pagada, está predominantemente ocupada por mujeres.<br></br>< br ></br>
                Dicen ser las mayores víctimas de la violencia doméstica. Sin embargo, todos los artículos, encuestas y documentales que muestren lo contrario son inmediatamente retirados de los medios. Solo se acepta como cierto un lado de esta historia, mientras que los números mismos demuestran otra realidad que nunca se debate ni se publica.<br></br><br></br>
                Reciben sentencias más cortas y leves en comparación con los hombres que han cometido delitos igualmente graves. Se jubilan primero, aunque su esperanza de vida es mayor. Ellas se benefician de programas de salud exclusivos, a diferencia de los hombres con las mismas enfermedades que deben valerse por sí mismos. No están obligados a realizar el servicio militar ni a defender al país en situaciones de conflicto.<br></br><br></br>
                Además, las justificaciones utilizadas por ellas <u><b>NUNCA PUEDEN SER CUESTIONADAS NI CRÍTICAS</b></u>, cualquiera que lo haga será ridiculizado o desprestigiado, aunque sea otra mujer.

                <br></br><br></br><br></br><br></br>
				<h2>Industria de litigios</h2>
				<br></br><br></br>
			    <S.FormImage><img src={guide06} alt=""></img></S.FormImage>
				<br></br><br></br><br></br>

				Mientras que el matrimonio se trata de amor, el divorcio se trata de dinero. Actualmente, uno de cada tres matrimonios termina en divorcio. Se ha convertido en una industria lucrativa que genera millones al año. En Estados Unidos genera alrededor de US$50 mil millones al año. No hay manera de calcular estos números en otros países, pero no hay duda de que deben ser números muy similares. Hay un millón de divorcios en los Estados Unidos cada año. Es decir, uno cada 36 segundos, casi 2.400 al día y 16.800 a la semana. Los números sorprenden y demuestran que es <u><b>UN NEGOCIO MUY RENTABLE</b></u>. <br></br><br></br>
				Un proceso de divorcio no consensuado es <u><b>COSTO Y CONSUMO DE TIEMPO</b></u> para los involucrados. Quienes pasan por esta experiencia pierden en promedio el 70% de su patrimonio o cantidades mayores en los casos más graves. Muchas disputas son inflamadas y prolongadas intencionalmente. Esto da como resultado la fabricación innecesaria de problemas que podrían resolverse rápidamente por medios extrajudiciales más pacíficos, rápidos y efectivos. Pero esto no es ni interesante ni rentable.<br></br><br></br>
				Hay una industria que alimentar, que sigue una línea de producción que debe funcionar de acuerdo a las normas establecidas. Todo se hace en papel, pocas veces se dice o pide algo, ya está listo y escrito, solo se modifican tus datos y todo se hace a distancia sin tu presencia. Las personas que definen tu vida no te miran a los ojos y ni siquiera saben si tienes brazos o piernas. No hay empatía, no se respetan ni se tienen en cuenta los valores humanos. No hay humanidad, cuando existe es hipócrita, solo hay tecnicismo. <br></br><br></br>
				Los resultados ya están definidos, solo esperan ser registrados y despachados para que uno nuevo ocupe el lugar vacante. Al igual que una línea de producción de fábrica. Si tiene suerte, su archivo será leído y juzgado correctamente, de vez en cuando es necesario demostrar que el sistema funciona, por lo que parece que las injusticias denunciadas por personas descontentas no son más que desafortunadas excepciones.<br></ br><br></br>
				<br></br><br></br>
				<u><b>Sucursales</b></u>
				<br></br><br></br>
				Toda industria genera empleos directos y indirectos porque es necesario crear toda una estructura paralela de apoyo y mantenimiento. Esto permite el surgimiento de segmentos menores o afines que suministren materias primas, componentes o servicios complementarios para que esta industria pueda funcionar, formando un sistema integrado. Cuanto mayor sea la producción, mayor será la ganancia de todo este sistema. Lo mismo ocurre con la industria de litigios, que proporciona y también necesita el material y los servicios que brindan sus asociados. <br></br><br></br>
				La industria de las bodas es un segmento que mueve miles de millones anualmente. Es el principal proveedor de materia prima de la industria de litigios, que a su vez también termina alimentando a esta industria en un círculo sin fin. <br></br><br></br>
				En todo sistema hay una pérdida de energía para que los engranajes puedan funcionar. No todos los matrimonios terminan en divorcio y no todos los divorcios producirán nuevas nupcias. Sin embargo, los números actuales todavía son capaces de generar ganancias inmensas y mantener esa rueda girando. Además, también es posible crear otras industrias similares que utilicen y aprovechen los residuos de producción. <br></br><br></br>
				Hoy tenemos la industria de la paternidad que obliga a los hombres a mantener a niños que no son suyos. Así como la industria de la violación que genera miles de acusaciones falsas y obliga a los hombres a gastar mucho dinero para defenderse de sus acusadores. Periódicamente se crean nuevas leyes y dificultades para mantenerlo en perfecto estado de funcionamiento.<br></br><br></br>
				Finalmente, es un sistema malvado, rentable y muy creativo.
				<br></br><br></br><br></br><br></br>

				<h2>Psicópatas y sociópatas</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide07} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                A menos que la parte afectada esté comiendo moscas, los trastornos mentales tienen poca o ninguna relevancia legal. Mucho dependerá de la habilidad del defensor para usar esta información y peculiaridad a favor de su cliente. Además de que alarga y encarece los procesos al involucrar a profesionales de la salud que certificarán y confirmarán los problemas denunciados. Las instituciones y las personas que las componen no están preparadas para hacer frente a este tipo de personas o situaciones, por lo que los psicópatas y sociópatas son <u><b>IGNORADOS Y DESCONOCIDOS</b></u> por los tribunales. <br></br><br></br>
                Contrariamente a la creencia popular, estas personas no son necesariamente violentas. Hay niveles de estos trastornos del comportamiento que pueden ser altos o bajos. Pero el desprecio por las personas y las normas sociales establecidas es un factor dominante en cualquier nivel. La psicopatía es una predisposición genética, la persona nace con esta alteración cerebral, por lo que tiende a formar relaciones artificiales mantenidas solo por interés. Mientras que la sociopatía es un problema causado por factores ambientales, como la educación recibida o los traumas sufridos, generando dificultades en relacionarse o formar vínculos. <br></br><br></br>
                Ambos están compuestos por personas inteligentes, seductoras y manipuladoras y por estos atributos suelen ocupar puestos importantes en empresas y instituciones y así influir en nuestras vidas directa o indirectamente. Pueden ser jueces, abogados, médicos, psicólogos, maestros, políticos, militares, científicos, religiosos, cualquiera, incluso con quien convivimos. Son capaces de crear leyes injustas, normas y procedimientos desastrosos, agravar los problemas existentes, llevar a las naciones a la guerra o las personas al suicidio. No hay límites para la destrucción que pueden causar. <br></br><br></br>
                Según las estadísticas, los abogados ocupan el segundo lugar en el ranking de profesiones con más psicópatas. Los entornos de poder, como <u><b>NEGOCIO, JURÍDICO Y POLÍTICO</b></u>, son los lugares donde es más probable encontrar personas de este tipo.
                <br></br><br></br><br></br>
                <u>Algunas características de un psicópata/sociópata</u>:
                <br></br><br></br>
                <ul>
                <li>&nbsp;Capaz de manipular y seducir a las personas que le rodean.</li><br></br>
                <li>&nbsp;Mienten y engañan todo el tiempo, incluso cuando los atrapan.</li><br></br>
                <li>&nbsp;Descuide los sentimientos de los demás, no sienta culpa ni remordimiento.</li><br></br>
                <li>&nbsp;No tienen conciencia moral ni ética, las personas son utilizadas como medios para un fin.</li><br></br>
                <li>&nbsp;Comportamiento impulsivo, puede ser agresivo y peligroso.</li><br></br>
                <li>&nbsp;No se disculpan ni se corrigen a sí mismos, pero se presentan como amables, educados y serviciales.</li><br></br>
                <li>&nbsp;Son egocéntricos y egoístas, solo su bienestar es importante, no les importa la seguridad de los demás.</li><br></br>
                <li>&nbsp;Son irresponsables y pueden no cumplir con las obligaciones financieras.</li><br></br>
                <li>&nbsp;Son cobardes, atacan a los que no se pueden defender.</li><br></br>
                </ul>
                <br></br>
				Los psicópatas constituyen el 4% de la población mundial, de los cuales el 3% son hombres y el 1% son mujeres. Son el 20% de la población carcelaria y el 86,5% de los asesinos en serie. Tienen un trastorno de personalidad y pueden estar presentes en todas las clases sociales. Cuando la psicopatía se presenta en mujeres, su identificación parece ser más difícil. Se cree que estas mujeres pueden ser poco investigadas y incluso no diagnosticadas. <br></br><br></br>
                Como la justicia privilegia a las mujeres y trata a los hombres como delincuentes, es casi seguro que este factor contribuye significativamente a ocultar y beneficiar a las mujeres que realizan este tipo de conductas. En cuanto al porcentaje, la parte masculina de una relación parece ser más propensa a tener este trastorno. Sin embargo, al no existir una investigación concreta y definitiva sobre este tema, debemos como garantía asumir que esta posibilidad es igual en ambos sexos. <br></br><br></br>
                Independientemente del género, estar apegado de alguna manera a esa persona es terrible para la otra parte. Aún peor será el proceso de desconexión. Estas personas no aceptan perder o ser contradichas. No permiten que la parte liberada reconstruya su vida, tenga nuevas relaciones o sea feliz. Generalmente, los hombres psicópatas utilizan la violencia física contra sus parejas para lograr que se respete su voluntad y lamentablemente las noticias están llenas de casos de mujeres victimizadas por ellos.
                Los hombres también sufren este tipo de agresiones por parte de sus parejas, pero estos casos son omitidos o poco difundidos por los medios, excepto cuando superan los niveles de <i><b>"normalidad"</b></i > soportados, como si fuera posible aceptar o justificar cualquier acto de violencia entre hombres y mujeres. <br></br><br></br>
                En el caso de las mujeres psicópatas, el escenario cambia por completo. Así como las mujeres se benefician de la justicia, las psicópatas encuentran el ambiente perfecto para llevar a cabo sus perversidades. Adquieren poderes ilimitados sobre sus compañeros, especialmente si en la relación hay hijos que sin duda serán utilizados como arma y escudo en cualquier reclamación o enfrentamiento futuro. 
                Los psicópatas son inteligentes, manipuladores y difícilmente perderán cualquier demanda presentada en su contra.
                Con extrema facilidad y sin ningún remordimiento, llevan a sus compañeros a la ruina, al encarcelamiento y al suicidio. Pero este tipo de violencia no se contabiliza ni se muestra en ninguna estadística.
                <br></br><br></br><br></br><br></br>
                <h2>Escenario</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide08} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

				Desde el final de la Segunda Guerra Mundial hasta la actualidad, la sociedad humana ha venido experimentando importantes transformaciones que modifican nuestra forma de vida y valores. <br></br><br></br>
                <b>La familia es la unidad principal de una sociedad</b>. <br></br><br></br>
                Brinda educación, seguridad, valores y principios morales a sus miembros. Antes de la Edad Media, la familia era solo una convención social para la reproducción y transferencia de poder. A partir de este período de la historia, la familia se convierte en una importante institución encargada de promover la educación de los niños y su comportamiento en el medio social.
                El papel de la familia en el desarrollo de cada individuo ha adquirido una importancia fundamental.
                Ahora los valores, tradiciones y costumbres podrían transmitirse y perpetuarse a través de generaciones. Sus miembros tenían a quien acudir en busca de ayuda y protección, a diferencia de épocas anteriores donde su propia suerte estaría. <br></br><br></br>
                Actualmente, existen grupos poderosos que se disputan el poder mundial. Quieren asegurar su supervivencia tomando el control de instituciones globales y gobiernos establecidos. Para hacerlo, pretenden destruir las leyes y los valores morales existentes. 
                Estos valores se lograron con mucho sacrificio durante el desarrollo de la sociedad humana.
                Intentan por todos los medios destruir las familias existentes y impedir que se creen otras nuevas. Porque, los individuos sin familia son más fáciles de manipular y no tienen a quien pedir ayuda y protección, volviéndose dependientes y a merced del estado actual. <br></br><br></br>
                Estos grupos crean leyes injustas, venden ideas inválidas y destructivas a través de los medios que controlan, facilitan y fomentan el adulterio y la discordia entre parejas, fomentan las drogas, enmudecen y otros procedimientos nefastos.
                Los profesionales de alto carácter y conocimiento están siendo reemplazados gradualmente por personas sin calificaciones y principios morales consistentes. Estas personas fueron educadas en instituciones educativas deficientes y por familias rotas o discordantes. Muchos son analfabetos funcionales y no pueden interpretar un texto simple.
                Pronto estarán dirigiendo nuestras vidas, creando normas y procedimientos. También estarán juzgando nuestras acciones en base a sus valores distorsionados.<br></br><br></br>
                <i><b> En los últimos años, de manera ominosa, sutil y invisible, el papel de padre ha sido eliminado de la sociedad humana.</b></i> <br></br><br></br >
                Investigaciones y estadísticas muestran que existe una relación directa entre la ausencia de una figura paterna en la educación de los hijos y la delincuencia. Esto sucede porque el padre tiene un <u><b>PAPEL FUNDAMENTAL EN LA FORMACIÓN DEL CARÁCTER DE LOS HIJOS</b></u>. <br></br><br></br>
                Las madres brindan cuidado, amor y protección. Los padres brindan modelos a seguir, estructura, coraje y <u>carácter</u>. Cuando el padre está ausente o se ve impedido de ejercer su rol de padre, los hijos suelen ser portadores de algún tipo de trastorno emocional que afectará de alguna manera su futuro. <br></br><br></br>
                El divorcio es un instrumento importante utilizado por los grupos descritos anteriormente. Además de ser facilitado y fomentado, se utiliza para crear un descontento perpetuo entre las partes involucradas, generando más procesos legales y futuros desacuerdos.
                También destruye financieramente a una o ambas partes, impidiendo su recuperación o superación a corto plazo o de forma permanente. Provoca algún tipo de trauma en los niños existentes, como inseguridad y rechazo, dificultando sus relaciones en la edad adulta. <br></br><br></br>
                Finalmente, el divorcio elimina o impide el derecho de los hombres a ejercer plena y consistentemente su paternidad. Está hecho a propósito para incapacitar a los hombres, como muchas otras cosas que nos rodean y de las que no somos conscientes.

                <br></br><br></br><br></br><br></br>
				<h2>Envidia</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide09} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>
                Vivimos en una sociedad capitalista y competitiva. Las oportunidades se reducen y tenemos que esforzarnos cada vez más para alcanzar nuestros sueños y metas. Nos guste o no, estamos rodeados de personas que nos envidian y se oponen a nuestro éxito y felicidad.
                Envidian nuestra carrera profesional, nuestra situación financiera, nuestra salud, nuestro matrimonio, etc. <br></br><br></br>
                Los psicólogos y sociólogos todavía no saben exactamente qué es la envidia. En la historia humana, ha sido la causa de grandes problemas y conflictos que terminaron en muerte y destrucción. Forma parte de la estructura del psiquismo humano y actúa sobre nuestra cultura y organización social. <br></br><br></br>
                Es muy común que los matrimonios se rompan por su culpa. Tener un cónyuge, hijos, una familia alegre y feliz, es algo que la gente de nuestra sociedad siente mucha envidia. Simplemente no soportan la felicidad de los demás y no aceptan que los demás tengan lo que ellos no tienen. <br></br><br></br>
                En este marco, es común estar rodeado de falsos amigos y familiares que codician nuestra felicidad. Cuando un matrimonio se tambalea por innumerables motivos, son ellos quienes se presentan como sabios consejeros, siempre dispuestos a ayudarnos. Generalmente, inflaman a estos enemigos y aconsejan el divorcio como solución al problema.
                Muchos son incluso responsables de la aparición del problema original. Tienden a sembrar sospechas en la mente de los cónyuges, promueven el desamor, alientan la traición en nombre de la libertad y el placer, y otras innumerables desviaciones del comportamiento familiar. Suena como una telenovela ficticia, pero esto es más común de lo que la gente cree. <br></br><br></br>
                La investigación de la <u>Universidad de Brown</u> incluso revela que el divorcio es contagioso. Que la probabilidad de que una persona se divorcie aumenta en un <b>75%</b> cuando tiene una relación directa con una persona que se divorcia o acaba de pasar por esa experiencia.<br></br><br></br>
                <i><b>El divorcio debe ser una actitud personal y individual, debe llevarse a cabo por las razones correctas.</b></i><br></br><br></br>
                Se recomienda el asesoramiento brindado por profesionales exentos de la pareja, como psicólogos y terapeutas, y no por amigos o familiares. Pero es mucho más recomendable obtener y ver testimonios de personas desconocidas que han tenido buenos y malos divorcios antes de tomar cualquier acción.
                La envidia produce consejos indebidos o maliciosos que son capaces de incentivar o activar la producción de un divorcio.

                <br></br><br></br><br></br><br></br>
                <h2>Justicia</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide10} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                <i><b>La justicia está formada por un trinomio, es ilógica, injusta y irracional.</b></i><br></br><br></br>
                Actualmente, innumerables personas se encuentran encarceladas sin juicio, otras permanecen en libertad aún después de haber sido condenadas y siguen produciendo nuevas víctimas, otras tienen la vida destrozada esperando por ello, otras arruinadas por sentencias y decisiones erróneas o perversas. <br></br><br></br>
                Las demandas se acumulan y los funcionarios judiciales no pueden manejarlo. Muchos se enferman tratando de que funcione, a otros no les importa. La justicia es lenta, formada por procedimientos deficientes, por leyes anticuadas y injustas.
                Está formado por grupos que se benefician de su irracionalidad y burocracia. Es una industria lucrativa que sigue creciendo. Los procesos son manipulados u olvidados según intereses personales. <br></br><br></br>
                <u><b>LA JUSTICIA FUE CREADA PARA NO FUNCIONAR Y PRIVILEGIAR UNA PEQUEÑA PARTE QUE LA CONTROLA</b></u>. <br></br><br></br>
                Se crean dificultades para luego vender instalaciones. Está compuesto por un sistema que utiliza grandes recursos financieros para funcionar. <br></br><br></br>
                Miles de millones de las arcas públicas se gastan anualmente sólo en su mantenimiento. Dinero que podría ser utilizado en beneficio de la población, pero es utilizado para mantener funcionando un sistema corrupto, deficiente y injusto, que no logra el objetivo para el cual fue creado.
                Siendo solo una ilusión creada para engañar a las personas y transmitir una sensación de seguridad y apoyo. Hágales creer que sus problemas se solucionarán. <br></br><br></br>
                Baluarte de psicópatas, gente sin escrúpulos y sin carácter. Muchos disfrazados y revestidos de títulos, poderes y mayordomías, pero no son más que gente mediocre y inepta. Debido a las dificultades, tiempo y dinero que requiere la formación de jueces, abogados y otros profesionales del derecho, se tiene la impresión de que se trata de personas excepcionales dotadas de una gran inteligencia y capacidades, que luchan a toda costa por la verdad y la justicia. <br></br><br></br>
                En realidad, son solo personas comunes con fortalezas y debilidades. Pueden ser brillantes profesionales con gran capacidad intelectual y empatía, pero también pueden ser ignorantes y criminales de la peor calaña.
                En un país sumido en la ignorancia y la corrupción, con un alto analfabetismo funcional en la educación superior, con innumerables distorsiones morales de todo tipo. <br></br><br></br>
                <b><i>¿Qué tipo de profesional cree que es más probable que encuentre en su camino?<br></br><br></br>
                ¿Cómo cree que se manejarán y juzgarán sus demandas?</i></b><br></br><br></br>

                <br></br><br></br>
                </span>
            </S.FormText>

            </S.Form>
            </>
            :
            <>
            <br></br>
            <br></br>
            <h3>&nbsp;</h3>
            <br></br>
            </>    }  
        </S.Container>
    )
}

export default GuidePart2;