import styled               from 'styled-components';

export const Container = styled.div`
    background: none;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
`

export const ContainerMailImage = styled.div`
    background: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15%;

    img {
        width: 90%;
        }
        
    @media screen and (max-width: 960px) {
        margin-top: 15%;
        img {
            width: 75%;
            }
`

export const MailImage = styled.img.attrs(({ src }) => ({
    src: src,
    alt: '',
  }))`
    margin-top: 30px;
    margin-bottom: 30px;

    &:hover {
        opacity: 0.7;
    }

    @media screen and (max-width: 960px) {
    }
`

export const MailLink = styled.div`
    background: none;
    margin-bottom: 30px;

    span {
        font-size: 24px;
        font-family: 'Roboto', sans-serif;
        text-decoration: none;
        font-weight: bold;
        color: #696969;
        cursor: pointer;

        &:hover {
            color: #70AD47;
            transaction: all 0.3s ease;
        }

        @media screen and (max-width: 960px) {
            font-size: 18px;
        }
        }
`
