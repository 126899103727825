import React    from 'react';
import Terms    from '../../../global/globalTermsOfUse';
import * as GV  from '../../../global/globalVariables';

function TermsOfUsePlanning() {
    
    return (
      <>
      <Terms name={GV.APP_DIVORCE_PLANNING}></Terms>
      </>
    )
}

export default TermsOfUsePlanning;