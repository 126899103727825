import React, {useState}                from 'react';
import * as S                           from './styles';
import guide15                          from '../Guide/Images/guide15.png'
import util                             from '../../../Utilities/Util'

function Element6() {

    const [english,    setEnglish]    = useState(false);
    const [spanish,    setSpanish]    = useState(false);
    const [portuguese, setPortuguese] = useState(false);

    React.useEffect(() => {
  
      const result = util.getLanguage();

      setEnglish   (result.english);
      setSpanish   (result.spanish);
      setPortuguese(result.portuguese);

      }, []);
 
    return (
        <S.Container>

            <S.FormImage><img src={guide15} alt=""></img></S.FormImage>

            { (portuguese) &&
            <S.ContainerData>
                <S.SideLeft>&nbsp;</S.SideLeft>
                <S.CenterData>
                    <S.Title>DEFESA</S.Title>
                    <S.FormDesc>
<span>Diferentemente dos livros e filmes, <b><u>não podemos exercer a livre defesa, é obrigatório a utilização de um advogado</u></b>.<br></br><br></br>
A lei possibilita realizar um divórcio amigável entre casais sem filhos menores ou incapazes, diretamente em um cartório de notas, mas exige a utilização de um advogado para que este possa elaborar e assinar o processo, juntamente com o casal solicitante.<br></br><br></br>
Independentemente do tipo de divórcio, amigável ou litigioso, a presença de um advogado é uma exigência legal.<br></br><br></br>
Divórcios amigáveis tendem a serem rápidos e baratos, contudo, quando existir algum tipo de desacordo, principalmente se houver filhos envolvidos, todo quadro se torna ainda mais incerto.<br></br><br></br><br></br>
<b>NESTE CASO, O DEFENSOR ESCOLHIDO (Advogado) PASSA A SER PEÇA CHAVE NESTA DISPUTA</b>.<br></br><br></br>
<b>É O PRINCIPAL ELEMENTO NA PRODUÇÃO DA VITÓRIA OU DERROTA</b>.<br></br><br></br><br></br>
Para que haja ganho ou menor prejuízo possível, é necessário selecionar um profissional qualificado.<br></br><br></br>
Infelizmente, eles são difíceis de serem localizados e custam caro.<br></br><br></br>
Encontrar um advogado através de alguma indicação, é um caminho para selecionar alguém competente.<br></br><br></br>
Porém, deve-se tomar <b><u>muito cuidado</u></b> nessa operação.<br></br><br></br>
É necessário verificar se o profissional indicado <b><u>é especializado em justiça familiar</u></b>, e se possui um índice de vitórias alto ou no mínimo razoável.<br></br><br></br>
Fuja de profissionais inexperientes, baratos ou que fazem de tudo. Não coloque o destino de sua vida nas mãos de qualquer pessoa, principalmente em alguém que você não conhece sua competência e atuação.<br></br><br></br>
Também descarte parentes que exerçam essa atividade, para evitar problemas familiares ou de ficar constrangido em descartá-los.<br></br><br></br>
Tenha em mente que <b><u>nenhum deles sofrerá qualquer consequência das ações tomadas</u></b>, e eles <b><u>SEMPRE</u></b> serão pagos, independentemente dos resultados obtidos.<br></br><br></br>
Atualmente, as leis <b><u>não obedecem</u></b> o princípio da equidade e favorecem apenas um dos lados na maioria dos processos existentes. Por causa disso, os advogados podem fazer muito pouco em defesa de seus clientes de sexo oposto.<br></br><br></br>
Então, diante de um cenário desfavorável como este, porque ainda deveriamos buscar defensores mais qualificados e dispendiosos?<br></br><br></br>
<i>Resposta</i>: Para evitar prejuízos e problemas <b><u>ainda</u></b> maiores em sua vida. Muitos tem a capacidade de piorar a situação, por omissão e/ou incompetência. Lembre-se que eles sempre serão remunerados, <b><u>ganhando ou perdendo</u></b>.<br></br><br></br><br></br>
<b>ALÉM DISSO, SENDO VOCÊ HOMEM OU MULHER, SOB NENHUMA CIRCUNSTÂNCIA, ACONSELHE-SE COM UM ADVOGADO SOBRE A DECISÃO DE REALIZAR OU NÃO UM DIVÓRCIO</b>.<br></br><br></br><br></br>
Procure alguém qualificado, apenas quando isso já tiver sido decidido por você e não haja mais retorno.<br></br><br></br></span>
                    </S.FormDesc>
                    <br></br> <br></br> <br></br>                     
                </S.CenterData>
                <S.SideRight>&nbsp;</S.SideRight>
            </S.ContainerData> 
            }

            { (spanish) &&
            <S.ContainerData>
                <S.SideLeft>&nbsp;</S.SideLeft>
                <S.CenterData>
                     <S.Title>DEFENSA</S.Title>
                     <S.FormDesc>
<span>A diferencia de los libros y las películas, <b><u>no podemos ejercer una defensa gratuita, es obligatorio utilizar un abogado</u></b>.<br></br><br></br>
Independientemente del tipo de divorcio, amistoso o contencioso, la presencia de un abogado es un requisito legal.<br></br><br></br>
Los divorcios amistosos tienden a ser rápidos y baratos, sin embargo, cuando hay algún tipo de desacuerdo, especialmente si hay niños involucrados, todo el panorama se vuelve aún más incierto.<br></br><br></br><br></br>
<b>EN ESTE CASO, EL DEFENSOR ELEGIDO (Abogado) SE CONVIERTE EN UNA PARTE CLAVE EN ESTE CONFLICTO</b>.<br></br><br></br>
<b>ES EL ELEMENTO PRINCIPAL EN LA PRODUCCIÓN DE VICTORIA O DERROTA</b>.<br></br><br></br><br></br>
Para tener ganancia o la menor pérdida posible, es necesario seleccionar un profesional calificado.<br></br><br></br>
Desafortunadamente, son difíciles de localizar y costosos.<br></br><br></br>
Encontrar un abogado a través de alguna referencia es una forma de seleccionar a alguien competente.<br></br><br></br>
Sin embargo, debe tener <b><u>mucho cuidado</u></b> en esta operación.<br></br><br></br>
Es necesario verificar que el profesional indicado sea <b><u>especializado en justicia de familia</u></b>, y tenga un alto o al menos razonable win rate.<br></br><br></br>
Escápate de profesionales sin experiencia, baratos o que lo hacen todo. No pongas el destino de tu vida en manos de nadie, especialmente de alguien de quien no conoces su competencia y desempeño.<br></br><br></br>
Descartar también a los familiares que ejerzan esta actividad, para evitar problemas familiares o pasar vergüenza de descartarlos.<br></br><br></br>
Tenga en cuenta que <b><u>ninguno de ellos sufrirá ninguna consecuencia de las acciones realizadas</u></b>, y serán <b><u>SIEMPRE</u></b> pagados independientemente de los resultados obtenidos.<br></br><br></br>
Actualmente, las leyes <b><u>no obedecen</u></b> al principio de equidad y favorecen a un solo lado en la mayoría de los procesos existentes. Debido a esto, los abogados pueden hacer muy poco en defensa de sus clientes del sexo opuesto.<br></br><br></br>
Entonces, ante un escenario desfavorable como este, ¿por qué deberíamos seguir buscando defensores más calificados y costosos?<br></br><br></br>
<i>Respuesta</i>: Para evitar daños y <b><u>problemas aún mayores</u></b> en su vida. Muchos tienen la capacidad de empeorar la situación, por omisión y/o incompetencia. Recuerde que siempre serán remunerados, <b><u>gane o pierda</u></b>.<br></br><br></br><br></br>
<b>ADEMÁS, SEA HOMBRE O MUJER, BAJO NINGUNA CIRCUNSTANCIA CONSULTE A UN ABOGADO SOBRE LA DECISIÓN DE DIVORCIARSE O NO</b>.<br></br><br></br>
Busque a alguien calificado, solo cuando esto ya lo haya decidido usted y no haya más retorno.<br></br><br></br></span>
                     </S.FormDesc>
                     <br></br> <br></br> <br></br>
                </S.CenterData>
                <S.SideRight>&nbsp;</S.SideRight>
            </S.ContainerData> 
            }           

            { (english) &&
            <S.ContainerData>
                <S.SideLeft>&nbsp;</S.SideLeft>
                <S.CenterData>
                    <S.Title>DEFENSE</S.Title>
                    <S.FormDesc>
<span>Amicable divorces tend to be quick and cheap, however, when there is some sort of disagreement, especially if there are children involved, the whole picture becomes even more uncertain.<br></br><br></br>
<b>IN THIS CASE THE CHOSEN DEFENDER (Lawyer) BECOME A KEY PART IN THIS DISPUTE</b>.<br></br><br></br>
<b>IT IS THE MAIN ELEMENT IN THE PRODUCTION OF VICTORY OR DEFEAT</b>.<br></br><br></br><br></br>
In order to have gain or the least possible loss it is necessary to select a qualified professional.<br></br><br></br>
Unfortunately they are difficult to locate and expensive.<br></br><br></br>
Finding a lawyer through some referral is a way to select someone competent.<br></br><br></br>
However you should be <b><u>very careful</u></b> in this operation.<br></br><br></br>
It is necessary to verify that the indicated professional is <b><u>specialized in family justice</u></b> and has a high or at least a reasonable win rate.<br></br><br></br>
Escape from inexperienced cheap or do-it-all professionals. Do not put the destiny of your life in the hands of anyone, especially someone you do not know your competence and performance.<br></br><br></br>
Also discard relatives who exercise this activity, to avoid family problems or to be embarrassed to discard them.<br></br><br></br>
Keep in mind that <b><u>none of them will suffer any consequences from the actions taken</u></b> and they will <b><u>ALWAYS</u></b> be paid regardless of the results obtained.<br></br><br></br>
Currently laws <b><u>do not obey</u></b> the principle of equity and favor only one side in most existing processes. Because of this lawyers can do very little in defense of their opposite-sex clients.<br></br><br></br>
So faced with an unfavorable scenario like this why should we still look for more qualified and expensive defenders?<br></br><br></br>
<i>Answer</i>: To avoid harm and <b><u>even greater</u></b> problems in your life. Many have the ability to make the situation worse through omission and/or incompetence. Remember that they will always be remunerated <b><u>win or lose</u></b>.<br></br><br></br>
<b>FURTHERMORE WHETHER YOU ARE MALE OR FEMALE UNDER NO CIRCUMSTANCES CONSULT A LAWYER ABOUT THE DECISION WHETHER OR NOT TO DIVORCE</b>.<br></br><br></br> <br></br>
Look for someone qualified only when this has already been decided by you and there is no more return.<br></br><br></br></span>
                    </S.FormDesc>
                    <br></br> <br></br> <br></br>
            </S.CenterData>
            <S.SideRight>&nbsp;</S.SideRight>
        </S.ContainerData> 
            }
        </S.Container>
    )
}

export default Element6;