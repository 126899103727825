
import React, { useState, useEffect } from 'react';   
import * as S       from './styles';
import guide22      from '../../../../view/DivorceGuide/Guide/Images/guide22.png';
import guide23      from '../../../../view/DivorceGuide/Guide/Images/guide23.png';
import guide24      from '../../../../view/DivorceGuide/Guide/Images/guide24.png';
import guide25      from '../../../../view/DivorceGuide/Guide/Images/guide25.png';
import guide26      from '../../../../view/DivorceGuide/Guide/Images/guide26.png';
import util         from '../../../../Utilities/Util';

function GuidePart4() {
    
    const [access, setAccess] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)
        setAccess( util.checkGuideAccess() );
    }, [])

    return (
        <S.Container>
        { ( access )
            ?
            <>
            <S.Form> 

            <S.FormTitle>
                <span>PARTE4</span><br></br><br></br><br></br><span>Procesos y Procedimientos Legales</span><br></br><br></br>
            </S.FormTitle>

            <S.FormText>
                <span>
                Contrariamente a lo que la gente cree, el divorcio no es un trámite aislado, fácil de realizar y de rápida conclusión.
                Viene con varios elementos secundarios que facilitan o dificultan su ejecución. Muchos de ellos pueden generar desafección, consecuencias negativas y trastornos a largo plazo en la vida de los involucrados.
                Además de producir costos y pérdidas no planificadas que nunca serán repuestas. Todo dependerá siempre de las características de la pareja, de los profesionales contratados, del tipo de divorcio que se adopte y principalmente de los miembros del tribunal seleccionados para llevarlo a cabo.
                <br></br><br></br>Conocer los componentes del divorcio es una tarea imprescindible y necesaria para evitar errores y sorpresas innecesarias.
    
                <br></br><br></br><br></br><br></br>
                <h2>Divorcio</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide22} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                Es la disolución definitiva del matrimonio.<br></br><br></br>

                <br></br><br></br><br></br><br></br>
                <b>Estadísticas</b>
                 <br></br><br></br><br></br>

                Numeros de divorcio:<br></br><br></br>

                <table border="1" >
                <tbody>
                <tr> 
                <td>53%</td> 
                <td>ESTADOS UNIDOS</td>
                </tr>
                <tr>
                <td>55 %</td>
                <td>FRANCIA</td>
                </tr>
                <tr>
                <td>58 %</td>
                <td>ESTONIA</td>
                </tr>
                <tr>
                <td>63 %</td>
                <td>ESPAÑA</td>
                </tr>
                <tr>
                <td>67 %</td>
                <td>HUNGRÍA</td>
                </tr>
                <tr>
                <td>68 %</td>
                <td>PORTUGAL</td>
                </tr>
                <tr>
                <td>71 %</td>
                <td>BÉLGICA</td>
                </tr>
                </tbody>
                </table>

                <br></br><br></br>
                Numeros de divorcio en los Estados Unidos:<br></br><br></br> 
               
                <table border="1" >
                <tbody> 
                <tr> 
                <td>66 %</td>
                <td>de los divorcios son presentados por mujeres</td>
                </tr>
                <tr>
                <td>43 %</td>
                <td>de los niños crecen sin la presencia de su padre</td>
                </tr>
                <tr>
                <td>41 %</td>
                <td>del primer matrimonio termina en divorcio</td>
                </tr>
                <tr>
                <td>60 %</td>
                <td>de los segundos matrimonios terminan en divorcio</td>
                </tr>
                <tr>
                <td>73 %</td>
                <td>del tercer matrimonio termina en divorcio</td>
                </tr>
                <tr>
                <td>08</td>
                <td>años es la duración media del matrimonio</td>
                </tr>
                <tr>
                <td>13</td>
                <td>segundos es el intervalo entre solicitudes de divorcio</td>
                </tr>
                </tbody>
                </table>

                <br></br><br></br><br></br><br></br>
                <b>Divorcio Judicial</b><br></br><br></br>
                 Es el divorcio llevado a cabo en los tribunales. Puede ser consensual o litigioso. Será un juez quien valore la solicitud y dicte sentencia describiendo los derechos y obligaciones de las partes intervinientes.
                 Las parejas con hijos están obligadas a realizar el divorcio judicial, aunque las partes estén de acuerdo en todo.

                 <br></br><br></br><br></br>
                 <b>Divorcio Consensual</b><br></br><br></br>
                 Es un divorcio amistoso, donde las partes están de acuerdo en todos los puntos y quieren llevar a cabo la disolución legal del matrimonio de manera cordial, pacífica y civilizada. <br></br><br></br>
                 Suelen traer pocas o ninguna consecuencia lamentable para las partes y los niños. <br></br><br></br>
                 <i><b>Es la mejor manera de llevar a cabo un divorcio, evitando pérdidas de tiempo y económicas para ambos.</b></i> <br></br><br></br>
                 Muchas parejas después de este tipo de divorcio continúan relacionándose y ayudándose, algo muy importante para el desarrollo físico y mental de sus hijos y para sus vidas.

                 <br></br><br></br><br></br>
                 <b>Divorcio Litigioso</b><br></br><br></br>
                 Es todo divorcio legal en el que las partes no están de acuerdo con alguno o todos los puntos del proceso.
                 Corresponde al juez analizar y emitir un dictamen final sobre todos ellos, incluyendo la custodia de los hijos y la pensión alimenticia. <br></br><br></br>
                 Es el tipo de divorcio más costoso y lento que existe, con consecuencias impredecibles para las partes existentes y los hijos. 
                 Antes de lanzarse a la disputa legal, es importante ser consciente del tema financiero. <br></br><br></br><u>
                 No es un tipo de divorcio recomendable, es preferible hacer un mal trato entre las partes que intentar un resultado más satisfactorio en los tribunales</u>.<br></br><br></br>
                 Como puede llevar mucho tiempo y tardar años en completarse, el juez decreta la custodia temporal de los hijos a una de las partes y también una pensión temporal de una cantidad calculada por él.     
                
                 <br></br><br></br><br></br>
                <b>Divorcio extrajudicial</b><br></br><br></br>
                 Se realiza sólo cuando la pareja no tiene hijos menores de edad o incapaces y cuando los cónyuges están de acuerdo en todo lo relacionado con el divorcio. Se lleva a cabo en un notario sin la presencia de un juez. Debe ser redactado por un abogado para preservar los derechos de las partes involucradas y evitar cualquier daño futuro. 
                 Sin embargo, si la mujer está embarazada, el divorcio debe hacerse en forma judicial.<br></br><br></br>

                 <b>No todos los países tienen este tipo de divorcio en su sistema legal</b>.

                <br></br><br></br><br></br><br></br>
                <h2>Partición de Bienes</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide23} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                La partición de bienes no es obligatoria, la pareja puede mantener la propiedad en un condominio. En caso contrario, la repartición o división de los bienes de la pareja deberá hacerse teniendo en cuenta el régimen matrimonial que se adoptó al celebrarse el matrimonio. <br></br><br></br>
                Posteriormente, es necesario identificar y calificar los bienes que se compartirán. Cuáles son privados y cuáles fueron adquiridos durante el matrimonio.
                Además de los bienes, las deudas contraídas durante el matrimonio deben dividirse entre los cónyuges siguiendo los mismos criterios descritos.
                <br></br><br></br><br></br>

                <b>Régimen Matrimonial</b><br></br><br></br>
                Es el conjunto de normas que regulan los efectos patrimoniales resultantes del matrimonio. 
                Los cónyuges definen previamente el régimen de propiedad de los bienes gananciales. 
                Este arreglo se puede cambiar en cualquier momento durante el matrimonio.<br></br><br></br>
                <b>No todos los países tienen los mismos tipos de régimen matrimonial en su sistema legal</b>.
                <br></br><br></br><br></br>
                
                <b>Universal</b><br></br><br></br>
                En este régimen matrimonial se dividirán todos los bienes del matrimonio, a excepción de los recibidos por herencia o donados con cláusula intransferible, o los adquiridos en efectivo por la venta de bienes heredados o donados, o de uso personal y uso profesional. <br></br><br></br>
                Dependiendo de la cantidad y de la forma en que la pareja posea los bienes, estos pueden dividirse sin necesidad de venderlos, evitando la depreciación de los bienes y el tiempo dedicado a este trámite.
                <br></br><br></br><br></br>

                <b>Parcial</b><br></br><br></br>
                En este régimen matrimonial no se dividirán los bienes adquiridos antes del matrimonio o los que se recibieron por herencia o donación, sólo se dividirán los que se adquirieron durante el matrimonio, se repartirán en partes iguales (50% para cada uno), incluso que la contribución económica de los cónyuges a la adquisición de los bienes fue desigual.
                <br></br><br></br><br></br>

                <b>Separación Total</b><br></br><br></br>
                En este régimen matrimonial no se dividirán los bienes adquiridos antes y durante el matrimonio, salvo que estén a nombre de ambos. 
                Cada cónyuge será también responsable de las deudas que haya contraído, salvo las destinadas al beneficio de la familia.

                <br></br><br></br><br></br><br></br>
                <h2>Custodia de los Hijos</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide24} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                Un cambio de vida ocurre cuando una pareja termina una relación. Los deberes mutuos dejan de existir y cada uno podrá hacer lo que quiera con su vida sin rendir cuentas al otro. <br></br><br></br>
                Sin embargo, cuando de esta unión surjan hijos menores o incapaces, los derechos y deberes de los padres para con ellos permanecen inalterables. Es necesario definir cómo se protegerá y educará a los niños, así como determinar cómo se distribuirá esta tarea entre los responsables. <br></br><br></br>
                Es el gobierno el que define estas funciones y responsabilidades, aunque exista consenso entre los cónyuges al respecto. Es un juez quien ratifica el acuerdo entre los padres. Determina quién tiene la custodia de los hijos y esta función puede ser compartida entre los padres, según él decida.
                <br></br><br></br><br></br> 

                <b>Custodia Unilateral</b><br></br><br></br>
                Es la custodia donde solo una de las partes tiene todos los derechos sobre los hijos, mientras que la otra solo tiene la obligación de mantenerlos con pensiones y nada más. <br></br><br></br>
                <u><b>LAS MUJERES SON LAS DUEÑAS DE SUS HIJOS</b></u>. <br></br><br></br>
                Eventualmente, pueden perder la custodia de los niños cuando el padre prueba el comportamiento inapropiado de la madre que afecta el bienestar de los niños, como actitudes irresponsables o agresivas que les provoquen inseguridad, peligros físicos o psicológicos.
                Las pruebas deben ser consistentes y muchas de ellas analizadas y verificadas a través de los peritos y colaboradores auxiliares designados por la sentencia. <br></br><br></br>
                Esta es la modalidad más rentable para la industria del divorcio, ya que genera numerosos desacuerdos entre las partes y produce varios procesos en un intento de subsanarlos, con poco o ningún resultado concreto para el solicitante, apenas perdiendo tiempo y dinero.
                <br></br><br></br><br></br>

                <b>Custodia Compartida</b><br></br><br></br>
                Es cuando ambos padres tienen la custodia legal de sus hijos y la custodia física puede o no alternarse, dependiendo de las condiciones económicas de las partes. <br></br><br></br>
                En esta modalidad firman de manera conjunta cualquier documento relacionado con sus hijos, como escolar, de salud, etc. De esa manera ambos están al tanto de lo que les sucede a sus hijos. <br></br><br></br>
                <i><b>En este tipo de custodia, la obligación del padre de mantenerlos a través de la pensión no está exenta ni compartida.</b></i><br></br><br></ br>
                También es necesario que las partes estén en perfecta sintonía para que sea autorizado por el Juez. <br></br><br></br>
                Es un tipo de custodia muy importante, principalmente para evitar prejuicios a los padres en la educación de sus hijos y para corregir las desviaciones y injusticias de la custodia unilateral. <br></br><br></br>
                
                <br></br><br></br>
                <b>Custodia Alterna</b><br></br><br></br>
                Es un tipo de custodia en la que las partes alternan cada cierto tiempo la residencia de los hijos. Una vez con el padre, otra con la madre. <br></br><br></br>
                <b>No todos los países cuentan con este tipo de custodia en su ordenamiento jurídico</b>.<br></br><br></br>
                “Según los especialistas”, la custodia alterna no es recomendable, ya que puede ser perjudicial para la salud psíquica del niño, haciendo confusas ciertas referencias importantes en la fase inicial de su formación. <br></br><br></br>
                Según ellos, <u><b>LA DIRECCIÓN ES MÁS IMPORTANTE QUE LA PRESENCIA DEL PADRE EN LA EDUCACIÓN DE LOS HIJOS</b></u>. <br></br><br></br>
                Los traumas que ya son causados ​​por el divorcio son aceptables, pero cualquier trauma causado por un cambio de residencia no es aceptable. <br></br><br></br>
                <br></br><br></br><br></br>

                <b>Alienación Parental</b><br></br><br></br>
                <i><b>Es un delito cometido contra los hijos por uno de los padres, por ambos o por parientes cercanos.</b></i> <br></br><br></br>
                El síndrome de alienación parental es la manipulación continua de uno de los padres sobre el hijo para que deje de amar al otro progenitor. <br></br><br></br>
                Manipula psicológicamente al niño que comienza a mostrar miedo, falta de respeto u hostilidad hacia la otra parte o sus amigos, nuevos cónyuges y otros miembros de su familia, como tíos, abuelos, etc. <br></br><br></br>
                Generalmente, la parte tutelar utiliza su posición privilegiada para llevar a cabo la enajenación de los hijos, creando relatos despectivos y mentirosos que provocan su repudio y descontento con la parte alienada. <br></br><br></br>
                Es un acto cobarde y traicionero, propio de gente sin carácter y con problemas. Actos muchas veces dignos de psicópatas por su grado de frialdad, destrucción y perversidad. <br></br><br></br>
                Esta acción delictiva provoca graves daños al niño con efectos a largo plazo, la mayoría de las veces irreversibles.<br></br><br></br>
                Se pueden identificar algunos síntomas en el niño víctima de esta situación, tales como: ansiedad, nerviosismo, agresividad, depresión, trastorno de identidad, desorganización, aislamiento, inseguridad, dificultades de aprendizaje, sentimientos de culpa, desesperación y varios otros sentimientos destructivos. <br></br><br></br>
                El afectado debe interponer acción de Alienación parental contra el alienante. <br></br><br></br>
                Será necesaria la presentación de pruebas consistentes, así como la utilización de peritos seleccionados por el juez para validar y dar fe de la situación denunciada. <br></br><br></br>
                Es un proceso que cuesta tiempo y dinero y una vez concluido, el juez puede, por ley, aplicar al alienante una o más de las siguientes sanciones:
                <br></br><br></br>

                <table border="1" >
                <tbody>
                <tr><td>Declarar la ocurrencia de la alienación parental y advertir el responsable</td> </tr>
                <tr><td>Ampliar el régimen de convivencia familiar a favor del progenitor alienado</td> </tr>
                <tr><td>Estipular multa para el alienante</td> </tr>
                <tr><td>Determinar seguimiento psicológico y/o biopsicosocial</td> </tr>
                <tr><td>Determinar el cambio del tipo de guarda o su inversión</td> </tr>
                <tr><td>Determinar el marco cautelar del domicilio del niño o adolescente</td> </tr>
                <tr><td>Declarar suspensión de la custodia</td> </tr>
                </tbody>
                </table> 

                <br></br><br></br>
                A pesar de ser un delito de naturaleza atroz y con consecuencias destructivas ya menudo irreversibles, no prevé el encarcelamiento del responsable, que suele terminar sólo con una amonestación o una multa y nada más. <br></br><br></br>
                <b>La mayoría de los países del mundo no tienen ninguna legislación para hacer frente a este problema</b>.<br></br><br></br>
                Actualmente, esta ley se encuentra bajo una gran presión para ser derogada en los países donde se aplica y existen proyectos de ley pendientes al respecto.<br></br><br></br>
                El concepto de alienación parental fue formulado en la década de 1980 por el psiquiatra estadounidense Richard Gardner, quien defendió la existencia de un síndrome que afecta a niños y adolescentes de padres divorciados.<br></br><br></br>
                En los Estados Unidos, alrededor del 80% de los hijos de padres divorciados ya han sufrido algún tipo de alienación parental.<br></br><br></br>
                Se estima que más de 20 millones de niños en el mundo sufren este tipo de violencia.

                <br></br><br></br><br></br><br></br>
                <h2>Pensión Alimenticia</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide25} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                Es una cantidad obligatoria a pagar para cubrir los gastos de quienes no cuentan con medios propios de subsistencia. 
                Dentro de esta cantidad, que será calculada y designada por un juez, se encuentran los gastos de alimentación, salud, transporte, vestido, esparcimiento, educación y cualquier otro que sea necesario para la subsistencia del solicitante. <br></br><br></br>
                Es un beneficio que se otorga preferentemente a los hijos y/o excónyuges, pero también se puede otorgar a familiares mayores o incapaces, como padres y abuelos, cuando lo soliciten. <br></br><br></br>
                La pensión alimenticia se considera una obligación sagrada y debe pagarse incluso si el pagador está desempleado o no puede hacerlo por cualquier motivo. <br></br><br></br>
                La responsabilidad de criar y mantener a los hijos es de ambos padres, no solo de uno de ellos, ni de sus antepasados ​​o parientes cercanos. 
                Sin embargo, la pensión es tratada como una obligación moral y responsabilidad de <u>uno solo de ellos</u>. <br></br><br></br>
                La pensión alimenticia está rodeada de discursos políticamente correctos que tratan de justificar su obligación. <br></br><br></br>
                <i><b>Represente siempre a la parte ganadora como la víctima y a la parte perdedora como el villano</b></i>. <br></br><br></br>
                Hace tiempo que se superó el punto de equilibrio entre las partes. 
                No hay nada sagrado en este procedimiento, es cruel, inhumano y sobre todo imponente. 
                Comparado solo con los sistemas esclavistas de épocas pasadas.
                
                <br></br><br></br><br></br>
                <b>Alimentos para embarazadas</b><br></br><br></br>
                Es una ley que da derecho a una madre embarazada a recibir asistencia económica del padre <u>indicado</u> por ella. <br></br><br></br>
                Es su obligación presentar la <i>"prueba de paternidad"</i> a través de fotografías, testigos, cartas o cualquier otro medio utilizado como prueba. <br></br><br></br>
                <b>Se ignora por completo el principio de presunción de inocencia.</b><br></br><br></br>
                Una vez convencido de la existencia de pruebas de paternidad, el juez definirá el monto de la pensión alimenticia que se pagará hasta que nazca el niño de acuerdo con las necesidades de la madre y las posibilidades del padre. <br></br><br></br>
                El monto definido deberá ser suficiente para cubrir los gastos adicionales durante el período del embarazo y derivados desde la concepción hasta el parto, incluidos los referentes a alimentación especial, asistencia médica y psicológica, exámenes complementarios, hospitalizaciones, parto, medicamentos y cualquier otro que el juez considere necesario. <br></br><br></br>
                Si el presunto padre no tuviere las condiciones económicas para cumplir con esta obligación, la carga se trasladará a los presuntos abuelos paternos y en su defecto a los parientes hasta el segundo grado. <br></br><br></br>
                Durante el período de gestación no se permitirá la realización de pruebas médicas, como el ADN que puedan acreditar la paternidad del presunto padre. Después de un nacido vivo, el valor se convierte automáticamente en pensión alimenticia a favor del menor hasta que una de las partes solicite su revisión o sobreseimiento. <br></br><br></br>
                Se permitirá la exención si el hombre puede probar que no es el padre biológico del hijo nacido. Por tanto, su obligación como deudor de alimentos serían irregulares. <br></br><br></br>
                En estas dos acciones no se aplica el efecto retroactivo, o sea, no se devolverá el dinero gastado. Si después de brindar los pagos requeridos, el presunto padre logra confirmar la negación de la paternidad puede presentar una demanda por daños materiales en contra de la madre presentando documentos que acrediten los gastos que indebidamente le fueron impuestos, pudiendo también añadir a esta acción la solicitud de daño moral y le corresponde probar el daño psíquico que ha sufrido. <br></br><br></br>
                Sin embargo, este tipo de proceso no produce ningún resultado significativo. En la práctica, nada le sucederá a la mujer que intencionalmente produjo esta situación. No será detenida ni sufrirá ningún tipo de sanción.<br></br><br></br>
                 <b>Esta ley no existe en todos los países, es un ejemplo más de los abusos legales que se cometen contra los hombres</b>.
                
                <br></br><br></br><br></br>
                <b>Pensión del cónyuge</b><br></br><br></br>
                 El excónyuge puede solicitar pensión alimenticia en los casos en que se acredite la dependencia económica, así como otros factores. Un argumento muy utilizado es declarar haber dedicado años de su vida a su familia, renunciando a su educación, vida personal, profesional y otros. <br></br><br></br>
                 <i><b>Por lo tanto, siguiendo la lógica de este tipo de argumentos, la parte perdedora nunca se dedicó a la familia, ni renunció a su educación, vida personal, profesional, etc. Era solo un aprovechado.</b></i>
                
                <br></br><br></br><br></br>
                <b>Valor de la pensión</b><br></br><br></br>
                El valor debe definirse teniendo en cuenta los ingresos y la posibilidad de la persona que paga y las necesidades de la persona que lo solicita. <br></br><br></br>
                Normalmente, los valores rondan el 10%, 20% o 30% del rendimiento bruto o neto de la parte que paga. De hecho, no hay nada en la legislación sobre estos porcentajes. <br></br><br></br>
                <i><b>Es un mito, una referencia utilizada por muchos magistrados</b></i>. <br></br><br></br>
                No existe una regla fija y objetiva para el arbitraje la pensión. Por lo tanto, se puede decretar cualquier porcentaje o monto. <br></br><br></br>
                <i>Incluyendo porcentajes muy superiores a los descritos.</i> <br></br><br></br>
                Otros elementos pueden influir en la determinación de este porcentaje o valor, como hijos de otra unión, gastos con los padres, salud, etc. <br></br><br></br>
                Cuando la parte no tenga un trabajo estable, sea empresario o trabajador por cuenta propia, corresponderá al juez analizar su vida personal y económica para fijar el monto de la pensión basado en su análisis. 
                
                <br></br><br></br><br></br>
                <b>Revisión del monto de la pensión</b><br></br><br></br>
                El monto y las condiciones de la pensión pueden ser revisados ​​por cualquiera de las partes involucradas en cualquier momento deseado. <br></br><br></br>
                Es un procedimiento que se lleva a cabo a través de una acción con ese objetivo. <br></br><br></br>
                Como toda acción, implica tiempo y dinero, sin garantías de éxito y incluso puede producir el efecto contrario al deseado. <br></br><br></br>
                Cuando el pagador pierde su trabajo o cambia su nivel de vida puede solicitar una disminución en la cantidad decretada. Asimismo, si el pagador mejora su situación de vida el perceptor puede solicitar un aumento de esta cantidad.
                <br></br><br></br><br></br>
           
                <b>Duración de la pensión</b><br></br><br></br>
                La duración de la obligación de pensión puede variar de un caso a otro. La pensión definida para el excónyuge suele tener una fecha de vencimiento estipulada. <br></br><br></br>
                Sin embargo, en algunos casos pueden volverse permanentes. Esto sucede cuando la persona ya no tiene la edad suficiente para ingresar al mercado laboral o tiene problemas de salud. <br></br><br></br>
                Además, por el tecnicismo y la lentitud de la justicia puede durar para siempre sin un plazo definido para su realización. <br></br><br></br>
                Aún en el tema de los hijos, vemos que existen otros casos excepcionales, como los casos de invalidez, enfermedad, necesidad de medicación y cualquier supuesta dificultad que contribuirá a la continuidad de la pensión por tiempo indefinido. <br></br><br></br>
                El juez siempre tendrá la última palabra, mientras crea que debe pagarse el deudor tendrá que pagar la cantidad pactada. Este valor siempre puede variar para más o para menos.
                
                <br></br><br></br><br></br>
                <b>Exoneración de pensión alimenticia </b><br></br><br></br>
                Es una acción que el pagador debe interponer para que su obligación deje de existir. <br></br><br></br>
                Sin este procedimiento, el pago obligatorio no terminará, incluso si el hijo alcanza la mayoría de edad. Sólo el juez puede decretar el fin de la pensión. Aun así, sólo después de haber recibido la solicitud, oído y analizado la situación de la fed. <br></br><br></br>
                Por ejemplo, si el hijo prueba que es estudiante y necesita la pensión para pagar sus estudios esta obligación se mantendrá.
                Si después de eso, él sigue sin poder mantenerse con su propio trabajo y recursos se mantendrá la pensión. <br></br><br></br>
                Probablemente indefinidamente, ya que es un tipo de argumento o situación creada por personas débiles, complacientes y sin carácter. <br></br><br></br>
                <i><b>Por lo tanto, sólo mediante una acción de exoneración aprobada por el juez se extinguirá el pago obligatorio de alimentos.</b></i>

                <br></br><br></br><br></br><br></br>
                <h2>Falta de pago de pensión alimenticia</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide26} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                Normalmente, la falta de pago puede resultar en el arresto del pagador. <br></br><br></br>
                <i><b>El dinero debe aparecer por arte de magia o decreto cualquiera que sea la condición del deudor.</b></i> <br></br><br></br>
                Debe explicar al juez el motivo de su indisponibilidad, pero no existe un lugar específico para realizar este trámite. <br></br><br></br>
                Entonces, se debe realizar a través de la justicia con el pago de costas y abogado, es decir, <u><b>TENDRÁS QUE GASTAR DINERO PARA DEMOSTRAR QUE NO TIENES DINERO</b></u> . <br></br><br></br>
                Es muy razonable, no tiene dinero para pagar la pensión alimenticia, pero tendrá dinero para pagar la demanda y así probar que no tiene dinero para pagar la pensión alimenticia. <br></br><br></br>
                Un proceso de este tipo solo da como resultado cuotas o aplazamiento de la deuda, pero habrá que saldarla de alguna manera. 
                Los pagos atrasados ​​están sujetos a una multa y corrección. <br></br><br></br>
                Se pueden utilizar varias medidas para obligar al deudor a pagar esta deuda. <br></br><br></br>
                Algunas personas pueden encontrar que tales medidas son extremas y injustas. Sin embargo, lo que se vislumbra es que el destinatario tenga su derecho protegido. <br></br><br></br>
                Después de todo, estar desempleado o no tener dinero es un crimen atroz, ya que tenemos la suerte de vivir en un mundo lleno de oportunidades y salarios generosos, donde no hay desempleo, ignorancia o leyes injustas o mal formuladas hechas personas que viven fuera de la realidad.
             
                <br></br><br></br><br></br><br></br>
                </span>
            </S.FormText>

            </S.Form>
            </>
            :
            <>
            <br></br>
            <br></br>
            <h3>&nbsp;</h3>
            <br></br>
            </>    }  
        </S.Container>
    )
}

export default GuidePart4;