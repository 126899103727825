
import React, { useEffect, useState } from 'react';   
import * as S       from './styles';
import guide28      from '../../../../view/DivorceGuide/Guide/Images/guide28.png';
import util         from '../../../../Utilities/Util';

function GuidePart5() {
    
    const [access, setAccess] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)
        setAccess( util.checkGuideAccess() );
    }, [])

    return (
        <S.Container>
        { ( access )
            ?
            <>
            <S.Form> 

            <S.FormTitle>
                <span>PART 5</span><br></br><br></br><br></br><span>Justice in Practice</span><br></br><br></br>
            </S.FormTitle>

            <S.FormText>
                <span>
                Legal texts are true literary masterpieces capable of exalting human values in their search for truth and justice. <br></br><br></br>
                In practice, the story is quite different. The laws are unfair, harsh and inconsistent. When they are administered or used by people without morals or character the laws are highly destructive and contribute even more to the increase in crime and existing social imbalances. <br></br><br></br>
                In the real world, families share happy and unhappy moments. When living conditions become difficult or precarious, they often face these periods together. They cut expenses, change their standard of living and make sacrifices to overcome difficulties and get ahead. <br></br><br></br>
                In divorce, this mechanism and union is broken and reality is modified in favor of the winners of this dispute. They begin to live in a parallel universe in the world of <i>"unicorns"</i> where logic and common sense work differently from known reality. <br></br><br></br>
                Losers become eternal providers or <i>"modern slaves"</i> always in debt to their owners. Being unemployed or having no money will be an unacceptable situation. <br></br><br></br>
                If they fail to meet their financial obligations they will be penalized and destroyed until the resources owed <i>"magically"</i> appear. <br></br><br></br>
                
                <br></br><br></br><br></br>
                <h2>Practicing</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide28} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                The examples described belong to the author's country and cannot be translated or used. 
                Each country has its own legislation with its respective errors and successes.<br></br><br></br>
                However, it is possible to verify that in most Western countries the laws only favor women.
                <br></br><br></br><br></br>

                </span>
            </S.FormText>

            </S.Form>
            </>
            :
            <>
            <br></br>
            <br></br>
            <h3>&nbsp;</h3>
            <br></br>
            </>    }  
        </S.Container>
    )
}

export default GuidePart5;