import React, {useState}                from 'react';
import * as S                           from './styles';
import guide16                          from '../Guide/Images/guide16.png'
import util                             from '../../../Utilities/Util'

function Element5() {

    const [english,    setEnglish]    = useState(false);
    const [spanish,    setSpanish]    = useState(false);
    const [portuguese, setPortuguese] = useState(false);

    React.useEffect(() => {
  
      const result = util.getLanguage();

      setEnglish   (result.english);
      setSpanish   (result.spanish);
      setPortuguese(result.portuguese);

      }, []);
 
    return (
        <S.Container>

            <S.FormImage><img src={guide16} alt=""></img></S.FormImage>

            { (portuguese) &&
            <S.ContainerData>
                <S.SideLeft>&nbsp;</S.SideLeft>
                <S.CenterData>
                    <S.Title>ESTRUTURA</S.Title>
                    <S.FormDesc>
<span>É aquilo que dá sustentação para alguma coisa.<br></br><br></br>
No sentido figurado, significa ter condições de manter fisicamente ou emocionalmente alguma coisa.<br></br><br></br>
Uma família tradicional é composta por um pai, uma mãe e filhos.<br></br><br></br>
Eles formam a estrutura familiar, responsável pelo fortalecimento e auxilio mútuo de seus componentes, e principalmente pelo desenvolvimento físico e mental dos filhos.<br></br><br></br><br></br>
<b>EM UM DIVÓRCIO AMISTOSO OU LITIGIOSO, ESSA ESTRUTURA SERÁ DESFEITA</b>.<br></br><br></br><br></br>
Poderá trazer consequências positivas ou negativas aos envolvidos. Haverá alivio ou arrependimento, mas sempre algo irá ruir ou será desfeito.<br></br><br></br>
São os filhos menores ou incapazes que sofrem as maiores consequências dessa quebra estrutural.<br></br><br></br>
Mesmo a nível inconsciente, sentem que perderam a proteção familiar que tinham, mesmo que uma nova e melhor estrutura seja criada.<br></br><br></br>
Além dos fatores emocionais, existem os fatores físicos e financeiros que também serão dissolvidos ou modificados nessa quebra produzida pelo divórcio.<br></br><br></br>
O casal que realiza esse procedimento deve ter consciência que eles também serão separados e divididos. Serão vidas diferentes, despesas diferentes, moradias diferentes, e etc.<br></br><br></br>
Essa divisão com a criação de novas estruturas individuais poderá custar muito tempo e dinheiro.<br></br><br></br>
Isso poderá aumentar ainda mais o desafeto entre as partes quando não existir nenhum planejamento ou acordo previamente estabelecido entre elas.<br></br><br></br></span>
                    </S.FormDesc>
                    <br></br> <br></br> <br></br>                     
                </S.CenterData>
                <S.SideRight>&nbsp;</S.SideRight>
            </S.ContainerData> 
            }

            { (spanish) &&
            <S.ContainerData>
                <S.SideLeft>&nbsp;</S.SideLeft>
                <S.CenterData>
                     <S.Title>ESTRUCTURA</S.Title>
                     <S.FormDesc>
<span>Es lo que da soporte a algo.<br></br><br></br>
En sentido figurado, significa ser capaz de mantener algo física o emocionalmente.<br></br><br></br>
Una familia tradicional se compone de un padre, una madre y los hijos.<br></br><br></br>
Forman la estructura familiar, responsables del fortalecimiento y ayuda mutua de sus componentes, y principalmente del desarrollo físico y mental de los hijos.<br></br><br></br><br></br>
<b>EN UN DIVORCIO AMISTOSO O LITIGIOSO ESTA ESTRUCTURA SERÁ DESTRUIDA</b>.<br></br><br></br><br></br>
Puede tener consecuencias positivas o negativas para los involucrados. Habrá alivio o arrepentimiento, pero siempre algo se derrumbará o se deshará.<br></br><br></br>
Son los niños menores de edad o incapaces los que sufren las mayores consecuencias de este quiebre estructural.<br></br><br></br>
Incluso a nivel inconsciente, sienten que han perdido la protección familiar que tenían, incluso si se crea una nueva y mejor estructura.<br></br><br></br>
Además de los factores emocionales, están los factores físicos y económicos que también se disolverán o modificarán en esta ruptura que produce el divorcio.<br></br><br></br>
La pareja que realiza este procedimiento debe ser consciente de que ellos también serán separados y divididos. Serán vidas diferentes, gastos diferentes, viviendas diferentes, etc.<br></br><br></br>
Esta división con la creación de nuevas estructuras individuales puede costar mucho tiempo y dinero.<br></br><br></br>
Esto puede aumentar aún más la desafección entre las partes cuando no existe una planificación o acuerdo previamente establecido entre ellas.<br></br><br></br></span>
                     </S.FormDesc>
                     <br></br> <br></br> <br></br>
                </S.CenterData>
                <S.SideRight>&nbsp;</S.SideRight>
            </S.ContainerData> 
            }           

            { (english) &&
            <S.ContainerData>
                <S.SideLeft>&nbsp;</S.SideLeft>
                <S.CenterData>
                    <S.Title>STRUCTURE</S.Title>
                    <S.FormDesc>
<span>It is what gives support to something.<br></br><br></br>
In a figurative sense it means being able to physically or emotionally maintain something.<br></br><br></br>
A traditional family consists of a father, a mother and children.<br></br><br></br>
They form the family structure responsible for the strengthening and mutual assistance of its components and mainly for the physical and mental development of the children.<br></br><br></br><br></br>
<b>IN A FRIENDLY OR LITIGIOUS DIVORCE, THIS STRUCTURE WILL BE DESTROYED</b>.<br></br><br></br><br></br>
It may have positive or negative consequences for those involved. There will be relief or regret, but always something will fall apart or be undone.<br></br><br></br>
It is the minor or incapable children who suffer the greatest consequences of this structural break.<br></br><br></br>
Even at an unconscious level they feel they have lost the family protection they had even if a new and better structure is created.<br></br><br></br>
In addition to the emotional factors there are the physical and financial factors that will also be dissolved or modified in this break produced by the divorce.<br></br><br></br>
The couple who performs this procedure must be aware that they too will be separated and divided. It will be different lives, different expenses, different housing etc.<br></br><br></br>
This division with the creation of new individual structures can cost a lot of time and money.<br></br><br></br>
This may further increase the disaffection between the parties when there is no planning or agreement previously established between them.<br></br><br></br></span>
                    </S.FormDesc>
                    <br></br> <br></br> <br></br>
            </S.CenterData>
            <S.SideRight>&nbsp;</S.SideRight>
        </S.ContainerData> 
            }
        </S.Container>
    )
}

export default Element5;