import React, {useState}                from 'react';
import * as S                           from './styles';
import guide34                          from '../Guide/Images/guide34.png'
import util                             from '../../../Utilities/Util'

function Element3() {

    const [english,    setEnglish]    = useState(false);
    const [spanish,    setSpanish]    = useState(false);
    const [portuguese, setPortuguese] = useState(false);

    React.useEffect(() => {
  
      const result = util.getLanguage();

      setEnglish   (result.english);
      setSpanish   (result.spanish);
      setPortuguese(result.portuguese);

      }, []);
 
    return (
        <S.Container>

            <S.FormImage><img src={guide34} alt=""></img></S.FormImage>

            { (portuguese) &&
            <S.ContainerData>
                <S.SideLeft>&nbsp;</S.SideLeft>
                <S.CenterData>
                    <S.Title>PLANEJAMENTO</S.Title>
                    <S.FormDesc>
<span>Planejar é decidir o que será feito, onde, quando e de que forma será realizado para que um objetivo possa ser atingido.<br></br><br></br>
Através de um planejamento é possível perceber a realidade, avaliar as alternativas e possibilidades disponíveis, construir um referencial do caminho a ser percorrido e analisar toda a situação de forma racional e realista.<br></br><br></br>
Portanto, é um processo intelectual exigente que requer trabalho, tempo, e conhecimento em sua elaboração.<br></br><br></br>
É um documento que permite relacionar nossos limites, ganhos e ações possíveis de serem efetuadas.<br></br><br></br>
A maioria das pessoas transfere esse trabalho e responsabilidade para terceiros.<br></br><br></br>
Alegam não terem tempo, conhecimento sobre o assunto ou ideia do que deve ser feito.<br></br><br></br>
São imediatistas, acham que seu divórcio será realizado rapidamente, acreditam no bom senso das instituições, que possuem direitos inabaláveis, que as pessoas contratadas irão realizar um bom trabalho, e que estarão preocupadas o tempo todo com elas.<br></br><br></br>
Lamento informar que isso <b><u>não é verdade</u></b>.<br></br><br></br>
O divórcio é um procedimento que deve ser planejado com antecedência, onde as perdas e ganhos devem ser mensurados de acordo com a realidade, e não através promessas ou devaneios que podem não se concretizar.<br></br><br></br>
Os profissionais envolvidos, as instituições, bem como parentes e amigos que opinam sobre sua vida, <b><u>nunca</u></b> irão perder nem sofrer as consequências das ações sugeridas e/ou executadas por eles.<br></br><br></br>
Até o mais simples e amistoso divórcio deve ser planejado.<br></br><br></br>
<b>NÃO SABEMOS COMO SEREMOS JULGADOS E TRATADOS PELOS ENVOLVIDOS OU INSTITUIÇÕES, PESSOAS COM AS QUAIS CONVIVEMOS DURANTE ANOS, PODEM SE TORNAR TERRÍVEIS E DESTRUTIVOS ALGOZES, E INFELIZMENTE A HISTÓRIA HUMANA ESTÁ REPLETA DE EXEMPLOS ASSIM</b>.</span>
                    </S.FormDesc>
                    <br></br> <br></br> <br></br>                     
                </S.CenterData>
                <S.SideRight>&nbsp;</S.SideRight>
            </S.ContainerData> 
            }

            { (spanish) &&
            <S.ContainerData>
                <S.SideLeft>&nbsp;</S.SideLeft>
                <S.CenterData>
                     <S.Title>PLANEAMIENTO</S.Title>
                     <S.FormDesc>
<span>La planificación es decidir qué se hará, dónde, cuándo y cómo se llevará a cabo para lograr un objetivo.<br></br><br></br>
A través de la planificación es posible percibir la realidad, evaluar las alternativas y posibilidades disponibles, construir una referencia del camino a seguir y analizar toda la situación de forma racional y realista.<br></br><br></br>
Por lo tanto, es un proceso intelectual exigente que requiere trabajo, tiempo y conocimiento en su elaboración.<br></br><br></br>
Es un documento que nos permite enumerar nuestros límites, ganancias y posibles acciones a tomar.<br></br><br></br>
La mayoría de las personas transfiere este trabajo y responsabilidad a otros.<br></br><br></br>
Afirman que no tienen tiempo, conocimiento sobre el tema o idea de lo que se debe hacer.<br></br><br></br>
Son inmediatos, creen que su divorcio se hará rápido, creen en el sentido común de las instituciones, que tienen derechos inquebrantables, que las personas que contraten harán un buen trabajo, y que se preocuparán por ellos todo el tiempo.<br></br><br></br>
Lamento informarle que esto <b><u>no es cierto</u></b>.<br></br><br></br>
El divorcio es un trámite que debe ser planificado con anticipación, donde las pérdidas y ganancias deben medirse de acuerdo a la realidad, y no a través de promesas o ensoñaciones que pueden no materializarse.<br></br><br></br>
Los profesionales implicados, las instituciones, así como los familiares y amigos que opinan sobre su vida, <b><u>nunca</u></b> perderán ni sufrirán las consecuencias de las acciones sugeridas y/o realizadas por ellos.<br></br><br></br>
Incluso el divorcio más sencillo y amistoso debe planificarse.<br></br><br></br>
<b>NO SABEMOS COMO SEREMOS JUZGADOS Y TRATADOS POR LOS IMPLICADOS O LAS INSTITUCIONES, LAS PERSONAS CON LAS QUE HEMOS CONVIVIDO DURANTE AÑOS, PUEDEN CONVERTIRSE EN ESTAFADORES TERRIBLES Y DESTRUCTIVOS, Y LAMENTABLEMENTE LA HISTORIA HUMANA ESTÁ LLENA DE EJEMPLOS COMO ESTE</b>.</span>
                     </S.FormDesc>
                     <br></br> <br></br> <br></br>
                </S.CenterData>
                <S.SideRight>&nbsp;</S.SideRight>
            </S.ContainerData> 
            }           

            { (english) &&
            <S.ContainerData>
                <S.SideLeft>&nbsp;</S.SideLeft>
                <S.CenterData>
                    <S.Title>PLANNING</S.Title>
                    <S.FormDesc>
<span>Planning is deciding what will be done, where, when and how it will be carried out so that an objective can be achieved.<br></br><br></br>
Through planning it is possible to perceive the reality, evaluate the alternatives and possibilities available, build a reference of the way to be followed and analyze the whole situation in a rational and realistic way.<br></br><br></br>
Therefore, it is a demanding intellectual process that requires work, time, and knowledge in its elaboration.<br></br><br></br>
It is a document that allows us to list our limits, gains and possible actions to be taken.<br></br><br></br>
Most people transfer this work and responsibility to others.<br></br><br></br>
They claim they do not have the time, knowledge on the subject or idea of what should be done.<br></br><br></br>
They are immediate, they think their divorce will be done quickly, they believe in the common sense of the institutions, that they have unshakable rights, that the people they hire will do a good job, and that they will be worried about them all the time.<br></br><br></br>
I regret to inform you that this is <b><u>not true</u></b>.<br></br><br></br>
Divorce is a procedure that must be planned in advance, where the losses and gains must be measured according to reality, and not through promises or daydreams that may not materialize.<br></br><br></br>
The professionals involved, the institutions, as well as relatives and friends who give their opinion on their lives, <b><u>never</u></b> will lose or suffer the consequences of the actions suggested and/or performed by them.<br></br><br></br>
Even the simplest and most amicable divorce must be planned.<br></br><br></br>
<b>WE DO NOT KNOW HOW WE WILL BE JUDGED AND TREATED BY THOSE INVOLVED OR INSTITUTIONS, PEOPLE WITH WHOM WE HAVE LIVED FOR YEARS, CAN BECOME TERRIBLE AND DESTRUCTIVE SCAMMERS, AND UNFORTUNATELY HUMAN HISTORY IS FILLED WITH EXAMPLES LIKE THIS</b>.</span>
                    </S.FormDesc>
                    <br></br> <br></br> <br></br>
            </S.CenterData>
            <S.SideRight>&nbsp;</S.SideRight>
        </S.ContainerData> 
            }
        </S.Container>
    )
}

export default Element3;