import React    from 'react';
import * as S   from '../global/style';
import * as GV  from '../global/globalVariables';

function AppPrivacy({name}) {
    
    return (
        <S.Container>
            <S.Form>
            <>
            <h2>Privacy Policy</h2>
            <p>
            <a href="#spanish">Haga clic aquí para el idioma Español.</a><br></br><br></br>
            <a href="#portuguese">Clique aqui para o idioma Português.</a></p><br></br>

            <p>
            KODASWARE created the {name} application as
            an ad-supported application. This service is provided by
            KODASWARE is free of charge and is intended for use as is.
            </p> <p>
            This page is used to inform visitors about our
            policies with the collection, use and disclosure of
            Information if someone has decided to use our service.
            </p> <p>
            If you choose to use our service, you agree to
            the collection and use of information in relation to this
            policy. The information we collect is
            used to provide and improve the service. We will not use or share your information with
            any person, except as described in this Privacy Policy.
            </p> <p>
            The terms used in this Privacy Policy have the same meanings
            as in our Terms and Conditions, which are accessible at
            {name}, unless otherwise defined in this Privacy Policy.

            </p> <p><strong>Collection and use of information</strong></p> <p>

            { ( name === GV.APP_DIVORCE_GUIDE) ? 
            <>
            The app does not collect, store or transmit any personal or financial information.
            </> : null }    
            
            { ( name === GV.APP_DIVORCE_PLANNING) ? 
            <>
            For a better experience, when using our Service, we may require you to provide us with certain personally identifiable information, including, 
            but not limited to, name, address, phone number, email address, sex, income, relationship, personality and others. 
            The information that we request will be retained by us and used as described in this privacy policy.
            The app does not transmit any personal or financial information.
            </> : null } 

            </p> <div><p>

            The app uses third-party services that may collect
            information used to identify you.
            </p> <p>
            Link to used third party service providers privacy policy
            by app:
            </p>
            <ul>
            <li><a href="https://www.google.com/policies/privacy/" target="_blank" rel="noopener noreferrer">Google Play Services</a></li>
            <li><a href="https://support.google.com/admob/answer/6128543?hl=en" target="_blank" rel="noopener noreferrer">AdMob</a></li>
            </ul></div>
            <p><strong>Cookies</strong></p> <p>
            Cookies are files with a small amount of data that are
            commonly used as anonymous unique identifiers. These are shipped
            to your browser from the websites you visit and are
            stored in your device's internal memory.
            </p> <p>
            This service does not explicitly use these “cookies”. However,
            the application may use third-party code and libraries that use
            “cookies” to collect information and improve our services.
            You have the option to accept or decline these cookies. If you
            choose to refuse our cookies, you may not be able to use some
            parts of this service.
            </p> <p><strong>Security</strong></p>
            <p>We value your trust in providing us with your
            personal information. Therefore, we are striving to commercially use
            acceptable means of protecting it. Remember that no method of transmission over the Internet,
            electronic transmission or storage method is 100% secure and reliable.
            Therefore, we cannot guarantee its absolute security.</p>
            <p><strong>Links to other websites</strong></p>
            <p>This service may contain links to other websites. If you click on
            a third-party link, you will be directed to that site. Observation
            that these external sites are not operated by us.
            Therefore, we strongly recommend that you review the
            Privacy policy of these sites. We assume no responsibility for the content,
            privacy policies or practices of any third-party websites or
            services.</p>
            <p><strong>Children's privacy</strong></p>
            <div><p>These services are not intended for children under 13 years of age.
            We do not knowingly collect personally identifiable information from children under the age of 13.
            If we discover that a child under the age of 13 has provided us with personal information, we will immediately
            we delete it from our servers.</p></div>
            <p><strong>Changes to this privacy policy</strong></p>
            <p>We may update our Privacy Policy from time to time. Thus, you are advised to review this page
            periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
            <p>This policy is effective from 01-Sep-2022</p>
            <p><strong>Contact us</strong></p>
            <p>If you have questions or suggestions about our
            Privacy Policy, please feel free to contact us at info@kodasware.com.</p>
            </>
            <br></br>
            <br></br>
            <br></br>
            <>
            <div id="spanish"></div>
            <h2>Política de Privacidad</h2>
            <p>
            KODASWARE creó la aplicación {name} como
            una aplicación con publicidad. Este servicio es proporcionado por
            KODASWARE es gratuito y está diseñado para usarse tal cual.
            </p> <p>
            Esta página se utiliza para informar a los visitantes sobre nuestros
            políticas con la recopilación, el uso y la divulgación de
            Información si alguien ha decidido utilizar nuestro servicio.
            </p> <p>
            Si elige utilizar nuestro servicio, acepta
            la recopilación y el uso de información en relación con este
            política. La información que recopilamos es
            utilizados para prestar y mejorar el servicio. No utilizaremos ni compartiremos su información con
            cualquier persona, excepto como se describe en esta Política de Privacidad.
            </p> <p>
            Los términos utilizados en esta Política de Privacidad tienen los mismos significados
            como en nuestros Términos y Condiciones, a los que se puede acceder en
            {name}, a menos que se defina lo contrario en esta Política de privacidad.
            </p> <p><strong>Recopilación y uso de información</strong></p> <p>

            { ( name === GV.APP_DIVORCE_GUIDE) ? 
            <>
            La aplicación no recopila, almacena ni transmite ninguna información personal o financiera.
            </> : null }    
            
            { ( name === GV.APP_DIVORCE_PLANNING) ? 
            <>
            Para una mejor experiencia, al usar nuestro Servicio, podemos solicitarle que nos proporcione cierta información de identificación personal, 
            que incluye, pero no limitado a, nombre, dirección, número de teléfono, dirección de correo electrónico, sexo, ingresos, relación, personalidad y otros.
            La información que solicitamos será retenida por nosotros y utilizada como se describe en esta política de privacidad.
            La aplicación no transmite ninguna información personal o financiera.
            </> : null } 
            
            </p> <div><p>
            La aplicación utiliza servicios de terceros que pueden recopilar
            información utilizada para identificarlo.
            </p> <p>
            Enlace a la política de privacidad de proveedores de servicios de terceros usados
            por aplicación:
            </p>
            <ul>
            <li><a href="https://www.google.com/policies/privacy/" target="_blank" rel="noopener noreferrer">Servicios de Google Play</a></li>
            <li><a href="https://support.google.com/admob/answer/6128543?hl=es" target="_blank" rel="noopener noreferrer">AdMob</a></li>
            </ul></div>
            <p><strong>Cookies</strong></p> <p>
            Las cookies son archivos con una pequeña cantidad de datos que se
            comúnmente utilizados como identificadores únicos anónimos. estos son enviados
            a su navegador desde los sitios web que visita y son
            almacenados en la memoria interna de su dispositivo.
            </p> <p>
            Este servicio no utiliza explícitamente estas "cookies". Sin embargo,
            la aplicación puede usar código de terceros y bibliotecas que usan
            “cookies” para recopilar información y mejorar nuestros servicios.
            Tiene la opción de aceptar o rechazar estas cookies. Si tu
            elige rechazar nuestras cookies, es posible que no pueda utilizar algunas
            partes de este servicio.
            </p> <p><strong>Seguridad</strong></p>
            <p>Valoramos su confianza al proporcionarnos su
            informaciones personales. Por lo tanto, nos esforzamos por utilizar comercialmente
            medio aceptable de protegerlo. Recuerde que ningún método de transmisión a través de Internet,
            El método de transmisión o almacenamiento electrónico es 100% seguro y confiable.
            Por lo tanto, no podemos garantizar su absoluta seguridad.</p>
            <p><strong>Enlaces a otros sitios web</strong></p>
            <p>Este servicio puede contener enlaces a otros sitios web. Si hace clic en
            un enlace de un tercero, será dirigido a ese sitio. Observación
            que estos sitios externos no son operados por nosotros.
            Por lo tanto, le recomendamos encarecidamente que revise el
            Política de privacidad de estos sitios. No asumimos ninguna responsabilidad por el contenido,
            políticas o prácticas de privacidad de cualquier sitio web de terceros o
            servicios.</p>
            <p><strong>Privacidad infantil</strong></p>
            <div><p>Estos servicios no están destinados a niños menores de 13 años.
            No recopilamos a sabiendas información de identificación personal de niños menores de 13 años.
            Si descubrimos que un niño menor de 13 años nos ha proporcionado información personal, inmediatamente
            lo eliminamos de nuestros servidores.</p></div>
            <p><strong>Cambios a esta política de privacidad</strong></p>
            <p>Podemos actualizar nuestra Política de privacidad de vez en cuando. Por lo tanto, se recomienda revisar esta página.
            periódicamente para cualquier cambio. Le notificaremos cualquier cambio publicando la nueva Política de Privacidad en esta página.</p>
            <p>Esta política es efectiva a partir del 01 de septiembre de 2022</p>
            <p><strong>Contáctenos</strong></p>
            <p>Si tiene preguntas o sugerencias sobre nuestro
            Política de privacidad, no dude en contactarnos en info@kodasware.com.</p>
            </>
            <br></br>
            <br></br>
            <br></br>
            <>
            <div id="portuguese"></div>
            <h2>Política de Privacidade</h2>
            <p>
            A KODASWARE criou o aplicativo {name} como
            um aplicativo suportado por anúncios. Este serviço é prestado por
            KODASWARE sem custo e destina-se ao uso como é.
            </p> <p>
            Esta página é usada para informar os visitantes sobre nossos
            políticas com a coleta, uso e divulgação de
            Informações se alguém decidiu usar nosso serviço.
            </p> <p>
            Se você optar por usar nosso serviço, você concorda com 
            a coleta e uso de informações em relação a esta
            política. As informações que coletamos são
            usadas para fornecer e melhorar o serviço. Não usaremos ou compartilharemos suas informações com
            qualquer pessoa, exceto conforme descrito nesta Política de Privacidade.
            </p> <p>
            Os termos usados ​​nesta Política de Privacidade têm os mesmos significados
            como em nossos Termos e Condições, que estão acessíveis em
            {name}, a menos que definido de outra forma nesta Política de Privacidade.
            </p> <p><strong>Coleta e uso de informações</strong></p> <p>
            
            { ( name === GV.APP_DIVORCE_GUIDE) ? 
            <>
            O aplicativo não coleta, armazena nem transmite qualquer informação pessoal ou financeira.
            </> : null }    
            
            { ( name === GV.APP_DIVORCE_PLANNING) ? 
            <>
            Para uma melhor experiência, ao usar nosso Serviço, podemos exigir que você nos forneça algumas informações de identificação pessoal, incluindo,
            mas não limitado a, nome, endereço, número de telefone, endereço de e-mail, sexo, renda, relacionamento, personalidade e outros.
            As informações que solicitamos serão retidas por nós e usadas conforme descrito nesta política de privacidade.
            O aplicativo não transmite nenhuma informação pessoal ou financeira.
            </> : null } 

            </p> <div><p>
            O aplicativo usa serviços de terceiros que podem coletar
            informações usadas para identificá-lo.
            </p> <p>
            Link para a política de privacidade de provedores de serviços terceirizados usados
            pelo aplicativo:
            </p> 
            <ul>
            <li><a href="https://www.google.com/policies/privacy/" target="_blank" rel="noopener noreferrer">Google Play Services</a></li>
            <li><a href="https://support.google.com/admob/answer/6128543?hl=en" target="_blank" rel="noopener noreferrer">AdMob</a></li>
            </ul></div> 
            <p><strong>Cookies</strong></p> <p>
            Cookies são arquivos com uma pequena quantidade de dados que são
            comumente usados ​​como identificadores exclusivos anônimos. Estes são enviados
            para o seu navegador a partir dos sites que você visita e são
            armazenados na memória interna do seu dispositivo.
            </p> <p>
            Este serviço não usa esses “cookies” explicitamente. No entanto,
            o aplicativo pode usar código de terceiros e bibliotecas que usam
            “cookies” para coletar informações e melhorar seus serviços.
            Você tem a opção de aceitar ou recusar esses cookies. Se você
            optar por recusar nossos cookies, talvez você não consiga usar alguns
            partes deste serviço.
            </p> <p><strong>Segurança</strong></p>
            <p>Valorizamos sua confiança em nos fornecer suas
            informações pessoais. Portanto, estamos nos esforçando para usar comercialmente
            meios aceitáveis ​​de protegê-la. Lembre-se que nenhum método de transmissão pela internet, 
            transmissão eletrônica ou metodo de armazenamento é 100% seguro e confiável.
            Por isso, não podemos garantir sua segurança absoluta.</p>
            <p><strong>Links para outros sites</strong></p>
            <p>Este serviço pode conter links para outros sites. Se você clicar em
            um link de terceiros, você será direcionado para esse site. Observação
            que esses sites externos não são operados por nós.
            Portanto, recomendamos fortemente que você revise a
            Política de privacidade desses sites. Nós não assumimos nenhuma responsabilidade pelo conteúdo, 
            políticas de privacidade ou práticas de quaisquer sites de terceiros ou
            serviços.</p>
            <p><strong>Privacidade das crianças</strong></p>
            <div><p>Estes serviços não atendem a menores de 13 anos.
            Nós não coletamos conscientemente informações identificáveis ​​de crianças menores de 13 anos. 
            Se descobrimos que uma criança com menos de 13 anos nos forneceu informações pessoais, nós imediatamente
            excluimos isso de nossos servidores.</p></div>
            <p><strong>Alterações nesta política de privacidade</strong></p>
            <p>Podemos atualizar nossa Política de Privacidade de tempos em tempos. Assim, você é aconselhado a rever esta página
            periodicamente para quaisquer alterações. Notificaremos você sobre quaisquer alterações publicando a nova Política de Privacidade nesta página.</p>
            <p>Esta política entra em vigor a partir de 01-Sep-2022</p>
            <p><strong>Fale conosco</strong></p>
            <p>Se você tiver dúvidas ou sugestões sobre nossos
            Política de Privacidade, não hesite em nos contatar em info@kodasware.com.</p>
            </>
            <br></br>
            <br></br>
            <br></br>
            </S.Form>
        </S.Container>
    )
}

export default AppPrivacy;