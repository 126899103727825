import React, { useState }              from 'react';   
import * as S                           from './styles';
import * as GV                          from '../../global/globalVariables';
import { i18n }                         from '../../translate/i18n';
import validate                         from '../../model/Validate';
import util                             from '../../Utilities/Util';
import axios                            from 'axios';
  
function Form({local}) {
 
    const [formName, setFormName] = useState(null);
    const [formMail, setFormMail] = useState(null);
    const [formDesc, setFormDesc] = useState(null);
    const [message,  setMessage ] = useState(null);
    const [send,     setSend    ] = useState(false);

    const [error1, setError1]     = useState(false);
    const [error2, setError2]     = useState(false);
    const [error3, setError3]     = useState(false);
    const [green,  setGreen]      = useState(true);
   
    React.useEffect(() => {
        if (local === GV.CONTACT_LOCAL_KODASWARE)
            setGreen(true);
        else 
            setGreen(false);   

        if (local === GV.CONTACT_LOCAL_BOOK_DIVORCE_GUIDE)
            window.localStorage.setItem(GV.I18N_STORAGE_KEY, "pt-BR");
    }, [local]);

    async function buttonSend() {

        setError1(false);
        setError2(false);
        setError3(false);
        setMessage('');

        let result = await validate.validateFormName(formName)

        if ( result != null )
            {
            document.getElementById('formName').focus();
            setMessage(result);
            setError1(true);
            return;
            }

        result = await validate.validateFormMail(formMail)

        if ( result != null )
            {
            document.getElementById('formMail').focus();
            setMessage(result);
            setError2(true);
            return;
            }
    
        result = await validate.validateFormDescription(formDesc)

        if ( result != null )
            {
            document.getElementById('formDesc').focus();
            setMessage(result);
            setError3(true);
            return;
            }

        buttonClear();
        setSend(true);

        var value = 
        "?contactId="      + contactId() + 
        "&contactName="    + formName + 
        "&contactMail="    + formMail +
        "&contactMessage=" + formDesc + 
        "&contactLocal="   + local +
        "&contactDate="    + util.getCurrentDateToFirebase() +
        "&contactTime="    + util.getCurrentTimeToFirebase();
        
        axios({
            method: "get",
            url: "https://us-central1-kodasware.cloudfunctions.net/putContactWeb" + value,
            responseType: "json"
          }).then((response) => {
             console.log(response.data);
           })
           .catch((error) => {
             console.log(error);
           });

    }

     async function buttonClear() {

        setError1(false);
        setError2(false);
        setError3(false);
        setMessage('');
        setFormName('');
        setFormMail('');
        setFormDesc('');

     }

    function contactId() {
        var rString   = randomString(16, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
        var timestamp = new Date().getTime();
        return rString + timestamp;
    }

    function randomString(length, chars) {
        var result = '';
        for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
        return result;
    }
    
    return (
        <S.Container>

            { send ? <h1>{i18n.t('sections.sent')}</h1> :
            <S.Form> 

                <S.ContactDescTitle>{i18n.t('sections.contact')}</S.ContactDescTitle>
                <S.ContactDesc>{i18n.t('sections.message')}</S.ContactDesc>

                <S.InputData>
                    <span>{i18n.t('literals.name')}</span>
                    <input type="text"  id="formName" maxlength="30" 
                    onChange={ e => setFormName(e.target.value)} value={formName}></input>
                    {error1 && <h3 style={{color: 'red'}}>{message}</h3>}
                </S.InputData>

                <S.InputData>
                    <span>{i18n.t('literals.email')}</span>
                    <input type="email" id="formMail" maxlength="80" 
                    onChange={ e => setFormMail(e.target.value)} value={formMail}></input>
                    {error2 && <h3 style={{color: 'red'}}>{message}</h3>}
                </S.InputData>

                <S.InputData>
                    <span>{i18n.t('literals.message')}</span>
                    <textarea id="formDesc" name="formDesc" rows="10" cols="50" maxlength="500" 
                    onChange={ e => setFormDesc(e.target.value)} value={formDesc}></textarea>
                    {error3 && <h3 style={{color: 'red'}}>{message}</h3>}
                </S.InputData>

                <S.ContainerButton>
                    <S.FormButton green={green}>
                        <button type="button" onClick={buttonSend}>{i18n.t('buttons.send')}&nbsp;</button>
                    </S.FormButton>
                    <S.FormButton green={green}>
                        <button type="button" onClick={buttonClear}>{i18n.t('buttons.clear')}&nbsp;</button>
                    </S.FormButton>
                </S.ContainerButton>
            </S.Form>
            }

        </S.Container>
 )
}

export default Form;