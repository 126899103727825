import styled               from 'styled-components';
import {Container}          from '../../global/globalStyles';
import {Link}               from 'react-router-dom';

export const Nav = styled.div`
    background: #FFFFFF;
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;
`

export const NavbarContainer = styled.div`
    background: none;
    height: 100px;
    width: 100%;
    display: flex;
    ##justify-content: space-between;
    border-collapse: separate;
    border-spacing: 0;
    //box-shadow: 0px 2px 2px 3px rgba(0, 0, 0, 0.2);
    border-radius: 0px;
    margin-left: 2px;
    margin-right: 2px;
    ${Container}
`

export const NavLink = styled(Link)`
    text-decoration: none;
    cursor: pointer;
`

export const NavLinkContact = styled.div`

    a:link {
        text-decoration: none;
        color:#FFFFFF;
    }
    
    a:visited {
        text-decoration: none;
        color:#FFFFFF;
    }
    
    a:hover {
        text-decoration: none;
        color:#FFFFFF;
    }
    
    a:active {
        text-decoration: none;
        color:#FFFFFF;
    }
`

export const MobileIcon = styled.div`
    display: none;

    @media screen and (max-width: 960px) {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }
`

export const NavMenuContainer = styled.div`
    background: none;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: right;
    margin-right: 150px;
`

export const NavMenu = styled.ul`
    background: none;
    display: flex;
    //align-items: stretch;
    align-items: center;
    list-style: none;
     
    @media screen and (max-width: 960px) {
        display: flex;
        flex-direction: column;
        width: 100%;
        top: 95px;
        
        position: fixed;
        left: ${ ( {click} ) => ( click ? 0 : '-100%' ) }; 
        opacity: 1;
        transaction: all 0.5s ease;
        background: ${ ( {click} ) => ( click ? '#70AD47' : '#fff' ) }; 
        display: ${ ( {click} ) => ( click ? 'flex' : 'none' ) }; 
    }
`

export const NavItem = styled.li`

    @media screen and (max-width: 960px) {
        width: 100%;
        padding: 1.5rem 1rem;

        &:hover {
            border: none;
        }
    }
`

export const NavItemLink = styled.div`

    a:link {
        text-decoration: none;
        color:#385723;
    }
    
    a:visited {
        text-decoration: none;
        color:#385723;
    }
    
    a:hover {
        text-decoration: none;
        //color:#70AD47;
        //color:#FFFFFF;
        color: ${ ( {click} ) => ( click ? '#FFFFFF' : '#70AD47' ) }; 
    }
    
    a:active {
        text-decoration: none;
        color:#385723;
    }
`

export const NavButton = styled.button`
    background: none;
    border: none;
    color: #696969;
    text-align: center;
    text-decoration: none;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
    font-size: 1.0rem;
    margin-top: 0.7rem;
    cursor: pointer;
    width: 130px;
    height: 100%;

    &:hover {
        color: #70AD47;
    }

    @media screen and (max-width: 960px) {

        color:#FFFFFF;
        text-align: left;

        &:hover {
            color: #70AD47;
            transaction: all 0.3s ease;
        }
    }
`

export const NavContact = styled.button`
    background: #385723;
    border: none;
    color: #FFFFFF;
    text-align: center;
    text-decoration: none;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
    font-size: 1.0rem;
    margin-top: 0.7rem;
    margin-left: 80px;
    cursor: pointer;
    width: 130px;
    height: 100%;
    border-collapse: separate;
    border-spacing: 0;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2);
    border-radius: 5px;

    &:hover {
        color: #70AD47;
    }

    @media screen and (max-width: 960px) {

        background: none;
        color:#FFFFFF;
        text-align: left;
        margin-left: 0px;

        &:hover {
            color: #70AD47;
            transaction: all 0.3s ease;
        }
    }
`

export const LanguageApp = styled.div`
    margin-top: 10px;
    margin-right: 10px;
    display: flex;
    flex-direction: row;
    justifyContent: flex-end;
    alignItems: flex-end;
    textAlign: right;
    position:absolute;
    right:0;

    span {
        font-size: 12px;
        font-family: 'Roboto', sans-serif;
        }

    select {
        width: 90px;
        font-size: 11px;
        font-family: 'Roboto', sans-serif;
        border-collapse: separate;
        border-spacing: 0;
        border-radius: 5px;
        }

    }
`
