import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const Form = styled.div`
    width: 50%;
    margin-top: 30px;
    background: #FFFFFF;

    @media screen and (max-width: 960px) {
        width: 90%;
    }
`

export const FormToKnowMore = styled.div`
    width: 50%;
    margin-top: 50px;
    align-items: center;
    justify-content: center;

    h2 {
        width: 100%;
        text-align:center;
    }
    
    h3 {
        width: 100%;
        text-align:center;
    }

    @media screen and (max-width: 960px) {
        width: 90%;
    }
`

export const FormImage = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    }
`