
import React, { useEffect, useState } from 'react';   
import * as S       from './styles';
import guide32      from '../../../../view/DivorceGuide/Guide/Images/guide32.png';
import guide33      from '../../../../view/DivorceGuide/Guide/Images/guide33.png';
import guide34      from '../../../../view/DivorceGuide/Guide/Images/guide34.png';
import util         from '../../../../Utilities/Util';

function GuidePart7() {
    
    const [access, setAccess] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)
        setAccess( util.checkGuideAccess() );
    }, [])

    return (
        <S.Container>
        { ( access )
            ?
            <>
            <S.Form> 

            <S.FormTitle>
                <span>PART 7</span><br></br><br></br><br></br><span>Victory and Planning</span><br></br><br></br>
            </S.FormTitle>

            <S.FormText>
                <span>

                Fighting for equality is an inherent right of all human beings. <br></br><br></br>
                Our history is full of conflicts in this regard. There have always been injustices and inequalities that were fought by those who did not accept or disagree with them. <br></br><br></br>
                However, when this fight loses its balance it has the opposite effect. So those who initially fought for these values end up becoming terrible executioners without limits or compassion. Producing pain, suffering, inequality and injustice, many times greater or worse than those that were originally fought. <br></br><br></br>
                Although they are victorious in their claims, they are never satisfied with the results obtained and continue to demand more and more rights and benefits in their favor. <br></br><br></br>
                The laws and procedures that regulated the separation of couples were questioned and modified to eliminate existing inequalities and injustices. <br></br><br></br>
                However, the break-even point in this procedure has long since been passed and now it only tilts to one side and this discrepancy does not stop growing. <br></br><br></br>
                Currently, men are the most affected and their chances of getting divorced do not exceed 5% of all the processes carried out. <br></br><br></br>
                <b><i>If there is any victory when a family breaks up</i></b>.<br></br><br></br>
                Even so, laws that only favor women are growing and the few that can produce any kind of control or justice for men are being eliminated or violated. <br></br><br></br>
                Like shared custody that is systematically denied and the parental alienation law that is fought through bills that want to remove it from the civil code. <br></br><br></br>
                This situation tends to get worse even single men without children are already being affected and persecuted. <br></br><br></br>
                Men can only plan their actions to try to mitigate their losses in a divorce proceeding.
                
                <br></br><br></br><br></br>
                <h2>Victoria</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide33} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                Victory is subjective. We think it's about beating someone or something and getting rewarded for it. <br></br><br></br>
                While this statement is true its value and outcome may vary due to the situation or importance it represents. <br></br><br></br>
                For example, being healthy can represent a victory for someone who has had some type of illness. How to prefer to study instead of stealing is a great victory for someone who lives in an environment where poverty and crime prevail. <br></br>
                In divorce, victory is also subjective. <br></br><br></br>
                Going through this procedure and managing to maintain your health, integrity and part of your economic resources will undoubtedly be a great victory in the face of an unfavorable and unfair environment created on purpose to defeat men.
                <br></br><br></br><br></br>
                Victory and defeat in divorce:
               <br></br><br></br>

                <table border="1" width="100%">
                <tbody>
                <tr><td bgcolor='#000000' ><b><font color="white">JUDICIAL<br></br>CONSENSUAL</font></b></td>
                <td width="70%">Mandatory for couples with children.<br></br>It is the best way to accomplish a divorce in court.<br></br><br></br><font font color="blue"><b>VICTORY</b></font>
                </td></tr>
                <tr><td bgcolor='#000000' ><b><font color="white">JUDICIAL<br></br>LITIGIOUS</font></b></td>
                <td width="70%">It is the worst possible divorce for everyone.<br></br>It is preferable to make a bad agreement and make it consensual.<br></br><br></br><font color="red"><b>DEFEAT</b></font>        
                </td></tr>
                </tbody>
                </table>

                <br></br><br></br><br></br>
                <h2>Planning</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide34} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                Currently, being married and having children is a bad business. <br></br><br></br>
                It is not possible to use justice to ensure that your rights are respected simply because they do not exist. <br></br><br></br>
                However, it is necessary to use it to dissolve the established marriage. 
                It can be an expensive and painful procedure with the end already determined and known. <br></br><br></br>
                One must have serious and incontestable arguments to achieve something concrete and favorable, but the most common thing is to be harmed in some way in this procedure. <br></br><br></br>
                Men are at a disadvantage in relation to the main attributes that determine the winner of a conflict.
                <br></br><br></br><br></br>

                <table border="1" width="100%">
                <tbody>
                <tr><td bgcolor='#000000' ><b><font color="white">TIME</font></b></td>
                <td width="80%">Women can to request for divorce anytime they want. <br></br>
                They do not require careful planning or large financial resources.</td></tr>
                <tr><td bgcolor='#000000' ><b><font color="white">JUSTICE</font></b></td>
                <td width="80%">The entire legal environment and existing laws exclusively favor women leaving little room for men to do anything meaningful. <br></br>
                Litigation is a very lucrative industry these days.</td></tr>
                <tr><td bgcolor='#000000' ><b><font color="white">LAWYER</font></b></td>
                <td width="80%">It is a segment of the aforementioned industry. <br></br>
                They will always win regardless of the results obtained without suffering any consequences for the work done. <br></br>
                They can do very little for men. They end up just being dispatchers. </td></tr>
                </tbody>
                </table>

                <br></br><br></br><br></br>
                <b> The Art of War</b><br></br><br></br>
                <i>Sun Tzu was a Chinese philosopher, strategist and general. Author of a military treatise called <b>"The Art of War"</b></i>.<br></br><br></br>
                At the end of the VI century a. C., he was hired by King Hu Lu of the Kingdom of Wu to command his army against the neighboring kingdom of Chu which had recently declared war. <br></br><br></br>
                China at that time was not unified and was divided into several small rival kingdoms that fought each other to expand their territories. <br></br><br></br>
                Sun Tzu, after passing a test evaluating his abilities became supreme commander of an army of thirty thousand men against an opponent better prepared and numerically <u>ten times greater</u>. <br></br><br></br>
                Instead of waiting for the first attack he ignored the military procedures of the time and did something unlikely and unexpected, he attacked first. <br></br><br></br>
                He did not make a direct attack, but instead moved his opponent's troops to certain places and always made surprise attacks on his opponent's weak points until he managed to weaken him and gradually reduce his contingent thus winning the war. <br></br><br></br>
                Sun Tzu never lost any of the battles he faced and describes his achievements and knowledge in the aforementioned book.
                <br></br><br></br>
                As explained above, divorce has the same characteristics as a war and as such it must be an action prepared in advance without feelings and with a defined, realistic and totally feasible objective. Its planning being a mandatory element that requires time, discipline, patience and dissimulation of its creator. <br></br><br></br>
                That the divorce should not be brought forward because of our feelings, the opinions of friends or relatives, or because of the suggestions of consulted professionals. <br></br><br></br>
                It was also outlined that the period leading up to filing for divorce should not be wasted with useless fighting or confrontation. <br></br><br></br>
                It should be used to improve the strategic plan and obtain the necessary financial and personnel resources. <br></br><br></br>
                That we should also use this time to monitor and supervise the other party, because in a war it is essential to know the movements of the enemy as it is to not be surprised by him. <br></br><br></br>
                In the story described, the kingdom of Chu revealed his goals in advance when he made an early declaration of war, but due to his arrogance and numerical superiority he believed that there would be no resistance from his opponent and that victory was assured, so he didn't bother to hide his true intentions from him. <br></br><br></br>
                Sun Tzu demonstrated that victory is possible even in precarious and inferior conditions using surprise as a strong and decisive ally.
                <br></br><br></br>
                The worst strategy according to him is the <u>measurement of forces</u>. <br></br>
                The way out is to focus on the opponent's weaknesses to unbalance his strengths and thus prepare for the attack. <br></br><br></br>
                He explains that when we are at a disadvantage in some attribute the best tactic is to lure the opponent into a scenario where we have an advantage.<br></br><br></br>
                Men cannot win this conflict as long as they fight in this hostile and unfavorable environment. It is necessary to use unconventional weapons and take the battle to a more propitious place where women cannot use their children as a shield nor use sexist laws. <br></br><br></br>
                This is the secret of victory, whoever says otherwise will be lying. <br></br><br></br>
                Therefore, do not believe people who promise that there will be justice in your case. They rarely happen. <br></br><br></br>
                Prior planning is necessary, accumulating financial resources and taking concrete actions, but all this has as its main objective only to minimize your losses which will already be a great victory in the face of an unfair and destructive scenario.

                <br></br><br></br><br></br>
                <h2>Golden Tip</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide32} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                Understanding and using the military strategy described above can decrease your losses and increase your chances of divorce just like in any other area of your life where this concept applies. <br></br><br></br>
                However, the elements that produce victory or defeat in a battle are circumstantial and vary from case to case and the results may vary and be uncertain, but when this concept is applied correctly it will always produce some kind of advantageous benefit. <br></br><br></br>
                Unfortunately, it is not possible to add more clear and precise examples in this document related to divorce. The concepts must be understood so that they can be applied on a case-by-case basis and in a confidential manner without the knowledge of the opponent. <br></br><br></br>
                In addition to knowing and applying these teachings some tips can be useful and worth gold if used.
                <br></br><br></br><br></br>
                <b>WHAT TO DO?</b>
                <br></br><br></br>
                No one can answer that question or make a decision for you. It is a personal decision. You will have to assume the consequences of your actions. Some of the possible possibilities are:
                <br></br><br></br>

                <table border="1">
                <tbody>
                <tr><td bgcolor='#000000' ><b><font COLOR="white">WAIT</font></b></td><td width="80%">Many men want a family and participate in raising their children. 
                Many have invested much of their personal life and financial resources in this pursuit. 
                When they perceive the signs that indicate the end of the relationship they prefer to ignore them in the hope that the scenario will change over time. 
                They know that the divorce will be a break in that desire.
                So, they naively prefer to believe in the common sense of their spouses. After all, a divorce would be bad for everyone especially the children. 
                Unfortunately, most men think and act this way and are totally destroyed by this decision. 
                Women are hypergamous by nature this is part of their instinct in addition to being encouraged by society and having the legal system in their favor.
                Therefore, they think and act completely opposite to what is described. Therefore, waiting is the worst possible choice.
                </td></tr>
                <tr><td bgcolor='#000000' ><b><font COLOR="white">DEFENDER</font></b></td><td width="80%">It is a higher stage of that described above. 
                Many men know that the relationship will end in divorce, but they prefer to wait for their spouses to take the first step in that direction. 
                Many do not have the financial resources or even the time to think and plan for this.
                They are stuck with debt and family commitments. They put off this decision until things get better for them. 
                If it happens sooner than expected they believe that justice will be on their side and they will look favorably on their work and sacrifice. 
                He is a present and hardworking father. Unlike those men who appear in the newspapers who mistreat their wives and children. 
                It will be a fatal error. Justice will not value your work or your character. 
                Anything you do or have done will be treated as an obligation and will not produce any benefit. 
                You will waste time and money defending yourself and everything you've earned or saved will be gone.
                </td></tr>
                <tr><td bgcolor='#FFD700' ><b><font COLOR="white">ATTACK</font></b></td><td width="80%">Most men does not accept the reality of the facts described in the previous tables. 
                They are passive and believe that things can be accomplished through diplomatic means. This explains the low rate of men seeking divorce.
                Yes, wars are destructive and costly and should be avoided in every possible way. 
                However, there are situations where diplomacy does not work and can be disastrous for one side. 
                It could be a way for your enemy to arm himself and buy time while he pretends to conduct peace negotiations. <br></br><br></br>
                <font color='#FFD700'><b><u>It is better to attack than to defend yourself</u>.</b></font> <br></br><br></br>
                Attacking first does not guarantee victory, but it can reduce your losses and split enemy troops. 
                In the case of men where all the elements are against them. This decision could decrease your losses and increase your chances of some kind of victory.
                </td></tr>
                </tbody>
                </table>

                <br></br><br></br><br></br>
                <b>NOT HAVE CONDITIONS</b>
                <br></br><br></br>
                Security is a superstition. It does not exist in nature. No army or person will ever be 100% ready. 
                Not having money is a serious problem and almost a guarantee of defeat in a divorce process. <br></br><br></br>
                Waiting for it or waiting for all the ideal conditions to occur is madness and a sure failure. <br></br><br></br>
                You will have to carry out the planning and various preparations in advance, but you will have to do it whether you have the conditions or not. 
                Immobility is not an option. <br></br><br></br>
                You will have to take out loans, sell your assets, etc. 
                Remember that doing this after the divorce has been finalized will be impossible. 
                Your assets and resources will not be available until the decisions are enacted. <br></br><br></br>
                Being in a situation where a divorce could happen at any time is terrible, but trying to save money right now is stupid. 
                You could lose hundreds of times what you tried to keep. 
                Not having economic or personal conditions is something common these days. <br></br><br></br>
                However, you should not be paralyzed by this. 
                Human history is full of real cases of people who had nothing and were victorious in adverse situations. <br></br><br></br>
                There is no example of people who have been victorious by doing nothing.
                
                <br></br><br></br><br></br>
                <b>TERRIBLE CONCLUSION</b>
                <br></br><br></br>
                Women are natural strategists. <br></br><br></br>
                Many divorces are planned well in advance some even before the wedding. 
                They are capable of manipulating men with incredible skill and dexterity getting them to do what they want. <br></br><br></br>
                They are hypergamous and easily end up getting married when men do not provide them with the required advantages. 
                None of them agrees to support any man or share the expenses equally with them. <br></br><br></br>
                All the money acquired by him must be used for the benefit of the family while the woman's belongs exclusively to her. <br></br><br></br>
                There are exceptions, but they are becoming rarer. None of them waive existing legal rights, unfair or not. <br></br><br></br>
                Men are supposed to be the eternal providers for women and fulfill their desires on all levels. 
                When they stop being advantageous they are quickly discarded serving only as a source of income. <br></br><br></br>
                It is during the divorce that your true character and your partner's intentions are revealed. 
                Many men realize they have been used and lied to for years. 
                Some take longer, but end up coming to the same terrible conclusion. <br></br><br></br>
                Because men are easy to manipulate they spend most of their time working to solve the problems created by their marriage especially their wives. 
                Because of that, they are not able to see reality. <br></br><br></br>
                When they are discarded they end up finding out how much they were cheated on during the period they were married or even before. <br></br><br></br>
                Even after the divorce many will continue to be used and manipulated by them. 
                Laws and children used as a shield and guarantee make it possible. 
                In these cases it will not be just manipulation and imposed obligation. It will be pure evil to satisfy their egos and frustrations. <br></br><br></br>
                Many will be driven to suicide or total ruin. It will be difficult for them to recover financially and everything possible will be done to make that happen. 
                They can't get a chance to react or defend themselves. Like in a war. Where the enemy must be totally overwhelmed and can never be repaired again.
                <br></br><br></br>
                Several men agree to a divorce, but want to resolve existing issues first before going through with it. 
                Many still have loans, financing, debts and obligations derived from the marriage. <br></br><br></br>
                They may also be sick, unemployed, etc. If they have children they will worry about their future and well-being. <br></br><br></br>
                Mainly, with the financial resources that will be diverted in this operation. 
                Resources that can be more useful for the benefit of children than unnecessary expenses. <br></br><br></br>
                For this reason, they prefer to have their problems solved and their finances up to date rather than spend money on legal matters. 
                They wait for an ideal condition or an equilibrium point that can be supported.
                
                <br></br><br></br>
                Women don't act or think that way. <br></br><br></br>
                <b><u>SOCIETY AND JUSTICE ALLOW THEM TO REQUEST A DIVORCE AT THE TIME THEY WANT.</u></b><br></br><br></br>
                They will have no cost nor will they go through any embarrassment. <br></br><br></br>
                They will be victims and men will be villains. <br></br><br></br>
                They will have their children and property. <br></br><br></br>
                Men will be left with the debts and the obligation to maintain them. <br></br><br></br>
                The full cost of this operation will be paid by the men. <br></br><br></br>
                They will not suffer any financial loss. <br></br><br></br>
                Any problem that occurs will be the fault of the men and they will have to fix it. <br></br><br></br>
                <b><u>So, <br></br>DO NOT WAIT OR PLAN TOO LONG BEFORE DOING ANYTHING.</u></b>

                <br></br><br></br>
                </span>
            </S.FormText>

            </S.Form>
            </>
            :
            <>
            <br></br>
            <br></br>
            <h3>&nbsp;</h3>
            <br></br>
            </>    }  
        </S.Container>
    )
}

export default GuidePart7;