
import React, { useEffect, useState } from 'react';   
import * as S       from './styles';
import me           from '../../../../view/DivorceGuide/Guide/Images/me.png'
import util         from '../../../../Utilities/Util';

function Author() {
    
    const [access, setAccess] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)
        setAccess( util.checkGuideAccess() );
    }, [])

    return (
        <S.Container>
        { ( access )
            ?
            <>
            <S.Form> 

            <S.FormTitle>
                <span>AUTHOR</span><br></br>
            </S.FormTitle>

            <S.ContainerCards>
            <S.FormImage><img src={me} alt=""></img></S.FormImage>
            <S.FormText>
                <span>
                <i>
                I am the result of a silent war taking place before us, but we don't realize or care until it's too late.<br></br><br></br>
                <b>I am not a doctor, psychologist, jurist or lawyer</b>.<br></br><br></br>
                I am someone who through much suffering and disappointment has acquired a vast knowledge of how to survive, fight and even win this invisible and destructive war.<br></br><br></br>
                I do not give guarantees or promise victories. I only offer the truth and the mechanisms to survive, but the fight will be for you.<br></br><br></br>
                Offering the truth is already a great victory and advantage over your opponents and for your personal growth, but it also teaches you to avoid many mistakes and unnecessary suffering.<br></br><br></br>
                <b>Believe! None of the <u>experts</u> you will turn to will do this.</b><br></br><br></br>
                <br></br><br></br>
                </i>
                </span>
            </S.FormText>
            </S.ContainerCards>
            </S.Form>
            </>
            :
            <>
            <br></br>
            <br></br>
            <h3>&nbsp;</h3>
            <br></br>
            </>    }  
        </S.Container>
    )
}

export default Author;