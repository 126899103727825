import React, {useState}                from 'react';
import * as S                           from './styles';
import guide30                          from '../Guide/Images/guide30.png'
import util                             from '../../../Utilities/Util'

function Element2() {

    const [english,    setEnglish]    = useState(false);
    const [spanish,    setSpanish]    = useState(false);
    const [portuguese, setPortuguese] = useState(false);

    React.useEffect(() => {
  
      const result = util.getLanguage();

      setEnglish   (result.english);
      setSpanish   (result.spanish);
      setPortuguese(result.portuguese);

      }, []);
 
    return (
        <S.Container>

            <S.FormImage><img src={guide30} alt=""></img></S.FormImage>

            { (portuguese) &&
            <S.ContainerData>
                <S.SideLeft>&nbsp;</S.SideLeft>
                <S.CenterData>
                    <S.Title>REALIZAÇÃO</S.Title>
                    <S.FormDesc>
<span>Uma guerra é algo terrível que deve ser evitado a todo custo.<br></br><br></br>
Quando for a última opção disponível, deve ser executada e finalizada o mais rápido possível, excluindo o máximo de perdas humanas e materiais.<br></br><br></br>
<b>O DIVÓRCIO POSSUI AS MESMAS CARACTERÍSTICAS DE UMA GUERRA E DEVE SER REALIZADO APENAS COMO ÚLTIMO RECURSO</b>.<br></br><br></br>
Ele consome os sentimentos, o patrimônio, o tempo e o dinheiro dos envolvidos.<br></br><br></br>
Recursos esses que <b><u>jamais serão recuperados novamente</u></b>.<br></br><br></br>
Não acredite nos filmes, romances ou na opinião de amigos e familiares, não serão eles que irão passar por essa desagradável experiência, nem tampouco irá ocorrer com você as coisas positivas ou negativas que foram relatadas por pessoas que passaram por esse processo.<br></br><br></br>
Cada caso é único, e poderá ocorrer com você, o oposto do que imagina ou espera.<br></br><br></br>
Muito dependerá de seu planejamento, mas muita coisa também dependerá de fatores imponderáveis que não estarão sob seu controle.<br></br><br></br>
Por isso, todas as opções devem ser esgotadas antes de realizá-lo. <br></br><br></br>
Inclusive, deve-se utilizar ajuda de profissionais habilitados em salvar relações abaladas e aparentemente irrecuperáveis.<br></br><br></br>
Contudo, quando em seu casamento faltar compreensão, existir cobranças, graves problemas financeiros, dificuldades de comunicação, ausência, adultério, desrespeito, segredos, mentiras, violência, agressões e outros desajustes que<br></br> <b><u>não podem ser corrigidos</u></b>.<br></br><br></br>
Esteja preparado.<br></br><br></br>
São sinais iminentes de uma situação irreversível que precisa ser finalizada através de uma operação como essa.<br></br><br></br>
Se não houver filhos nem patrimônio, ele seguramente poderá ser realizado sem maiores problemas e sem custos financeiros elevados.<br></br><br></br>
Inclusive, poderá ser realizado diretamente em um cartório de notas sem a presença de um juiz de direito.<br></br><br></br>
<b>PORÉM, QUANDO EXISTIR UM DESSES ELEMENTOS, PRINCIPALMENTE FILHOS, O CENÁRIO MUDA DRASTICAMENTE.</b><br></br><br></br>
Tudo irá depender do caráter e afinidade das pessoas envolvidas.<br></br><br></br>
Se houver respeito, amizade e bom senso, tudo tende a ocorrer de forma satisfatória e tranquila, contudo, se um desses atributos estiver em desarmonia é quase certo que uma dolorosa disputa será travada entre os envolvidos.<br></br><br></br>
Quando apenas uma das partes quer o divórcio ou quando as duas querem, mas não concordam com as condições estabelecidas, ele recebe a designação de <b>LITIGIOSO</b>.<br></br><br></br>
É o pior deles, devendo ser evitado o máximo possível.<br></br><br></br>
Para os homens as chances de vitória ou de obter a guarda de seus filhos são mínimas.<br></br><br></br>
As leis são sexistas e favorecem exclusivamente as mulheres.<br></br><br></br>
É necessário apresentar provas e argumentos contundentes para modificar essa preferência.<br></br><br></br>
<b>OS HOMENS NECESSITAM DE UM PLANEJAMENTO BASTANTE METICULOSO PARA AUMENTAR SUAS CHANCES DE VITÓRIA OU PELO MENOS PARA MITIGAR SUAS PERDAS</b>.<br></br><br></br></span>
                    </S.FormDesc>
                    <br></br> <br></br> <br></br>                     
                </S.CenterData>
                <S.SideRight>&nbsp;</S.SideRight>
            </S.ContainerData> 
            }

            { (spanish) &&
            <S.ContainerData>
                <S.SideLeft>&nbsp;</S.SideLeft>
                <S.CenterData>
                     <S.Title>REALIZACIÓN</S.Title>
                     <S.FormDesc>
<span>Una guerra es algo terrible que debe evitarse a toda costa.<br></br><br></br>
Cuando sea la última opción disponible, debe ejecutarse y terminarse lo antes posible, excluyendo la mayor cantidad posible de pérdidas humanas y materiales.<br></br><br></br>
<b>EL DIVORCIO TIENE LAS MISMAS CARACTERÍSTICAS DE UNA GUERRA Y DEBE REALIZARSE ÚNICAMENTE COMO ÚLTIMO RECURSO</b>.<br></br><br></br>
Consume los sentimientos, bienes, tiempo y dinero de los involucrados.<br></br><br></br>
Recursos que <b><u>nunca más se recuperarán</u></b>.<br></br><br></br>
No creas en las películas, novelas o la opinión de amigos y familiares, ellos no serán los que pasarán por esta desagradable experiencia, ni les pasarán las cosas positivas o negativas que han relatado las personas que han pasado por este proceso.<br> </br><br></br>
Cada caso es único y puede ocurrirte lo contrario de lo que imaginas o esperas.<br></br><br></br>
Mucho dependerá de tu planificación, pero mucho también dependerá de imponderables que escapen a tu control.<br></br><br></br>
Por lo tanto, se deben agotar todas las opciones antes de realizarlo. <br></br><br></br>
Incluso la ayuda de profesionales calificados debe usarse para salvar relaciones dañadas y aparentemente irrecuperables.<br></br><br></br>
Sin embargo, cuando en tu matrimonio hay falta de entendimiento, hay exigencias, serios problemas económicos, dificultades de comunicación, ausencia, adulterio, falta de respeto, secretos, mentiras, violencia, agresiones y otros desajustes que<b><u> no se puede corregir</u></b>.<br></br><br></br>
Entoces, esté preparado.<br></br><br></br>
Estos son signos inminentes de una situación irreversible que necesita ser terminada a través de una operación como esta.<br></br><br></br>
Si no hay hijos ni bienes, seguro que se puede llevar a cabo sin mayores problemas y sin elevados costes económicos.<br></br><br></br>
<b>PERO, CUANDO EXISTE ALGUNO DE ESTOS ELEMENTOS, PRINCIPALMENTE LOS NIÑOS, EL ESCENARIO CAMBIA DRÁSTICAMENTE.</b><br></br><br></br>
Todo dependerá del carácter y afinidad de las personas involucradas.<br></br><br></br>
Si hay respeto, amistad y sentido común, todo tiende a transcurrir de manera satisfactoria y pacífica, sin embargo, si uno de estos atributos está en desarmonía, es casi seguro que se librará una dolorosa disputa entre los involucrados.<br></br><br></br>
Cuando sólo una de las partes quiere el divorcio o cuando ambas lo quieren, pero no están de acuerdo con las condiciones establecidas, recibe la designación de <b>LIGICIOSO</b>.<br></br><br></br>
Es el peor de ellos y debe evitarse tanto como sea posible.<br></br><br></br>
Para los hombres, las posibilidades de ganar u obtener la custodia de sus hijos son mínimas.<br></br><br></br>
Las leyes son sexistas y favorecen exclusivamente a las mujeres.<br></br><br></br>
Es necesario presentar evidencia y argumentos fuertes para modificar esta preferencia.<br></br><br></br>
<b>LOS HOMBRES NECESITAN UNA PLANIFICACIÓN MUY METICULAR PARA AUMENTAR SUS POSIBILIDADES DE GANAR O AL MENOS MITIGAR SUS PÉRDIDAS</b>.<br></br><br></br></span>
                     </S.FormDesc>
                     <br></br> <br></br> <br></br>
                </S.CenterData>
                <S.SideRight>&nbsp;</S.SideRight>
            </S.ContainerData> 
            }           

            { (english) &&
            <S.ContainerData>
                <S.SideLeft>&nbsp;</S.SideLeft>
                <S.CenterData>
                    <S.Title>REALIZATION</S.Title>
                    <S.FormDesc>
<span>A war is a terrible thing that must be avoided at all costs.<br></br><br></br>
When it is the last option available it must be executed and finished as soon as possible excluding as much human and material losses as possible.<br></br><br></br>
<b>DIVORCE HAS THE SAME CHARACTERISTICS OF A WAR AND SHOULD ONLY BE CARRIED OUT AS A LAST REMEDY</b>.<br></br><br></br>
It consumes the feelings, goods, time and money of those involved.<br></br><br></br>
Resources that <b><u>will never be recovered again</u></b>.<br></br><br></br>
Do not believe the movies, novels or the opinion of friends and family they will not be the ones who will go through this unpleasant experience nor will the positive or negative things that have been reported by people who have gone through this process happen to you.<br></br><br></br>
Each case is unique and the opposite of what you imagine or expect may happen to you.<br></br><br></br>
Much will depend on your planning, but much will also depend on imponderables beyond your control.<br></br><br></br>
Therefore all options must be exhausted before performing it. <br></br><br></br>
Even the help of qualified professionals should be used to save shaken and apparently irretrievable relationships.<br></br><br></br>
However when in your marriage there is a lack of understanding, there are demands, serious financial problems, communication difficulties, absence, adultery, disrespect, secrets, lies, violence, aggressions and other maladjustment that <b><u>cannot be corrected</u></b>.<br></br><br></br>
Be prepared.<br></br><br></br>
These are imminent signs of an irreversible situation that needs to be ended through an operation like this.<br></br><br></br>
If there are no children or property it can certainly be carried out without major problems and without high financial costs.<br></br><br></br>
<b>BUT, WHEN ONE OF THESE ELEMENTS EXISTS, MAINLY CHILDREN, THE SCENARIO CHANGES DRASTICALLY.</b><br></br><br></br>
Everything will depend on the character and affinity of the people involved.<br></br><br></br>
If there is respect, friendship and common sense, everything tends to occur in a satisfactory and peaceful way. However if one of these attributes is in disharmony it is almost certain that a painful dispute will be fought between those involved.<br></br><br></br>
When only one of the parties wants a divorce or when both want it, but they do not agree with the established conditions, it receives the designation of <b>LIGITIOUS</b>.<br></br><br></br>
It is the worst of them and should be avoided as much as possible.<br></br><br></br>
For men the chances of winning or obtaining custody of their children are minimal.<br></br><br></br>
The laws are sexist and exclusively favor women.<br></br><br></br>
It is necessary to present evidence and strong arguments to modify this preference.<br></br><br></br>
<b>MEN NEED VERY METICULAR PLANNING TO INCREASE THEIR CHANCES OF WINNING OR AT LEAST MITIGATE THEIR LOSSES</b>.<br></br><br></br></span>
                    </S.FormDesc>
                    <br></br> <br></br> <br></br>
            </S.CenterData>
            <S.SideRight>&nbsp;</S.SideRight>
        </S.ContainerData> 
            }
        </S.Container>
    )
}

export default Element2;