import React   from 'react'
//import * as S  from './styles';

const htmlEs = (props) => (
  <>
            <br></br> <br></br> 
             KODASWARE creó este sitio web.<br></br>
             Este servicio se proporciona sin cargo y está diseñado para usarse tal cual.<br></br>
             Esta página se utiliza para informar a los visitantes de nuestras políticas con la recopilación, uso y divulgación de
             información sobre nuestros servicios.<br></br>
             Si elige utilizar nuestro servicio, acepta la recopilación y el uso de información en relación con este
             política. <br></br>
             La información personal que recopilamos se utiliza para proporcionar y mejorar el servicio. <br></br>
             No utilizaremos ni compartiremos su información con
             cualquier persona, excepto como se describe en esta Política de Privacidad.<br></br><br></br>
             <p><strong>Recopilación y uso de información</strong></p> <br></br>
             Actualmente, el sitio no recopila, almacena ni transmite ninguna información personal o financiera.<br></br>
             <div>
             El sitio utiliza servicios de terceros que pueden recopilar información utilizada para identificarlo.<br></br>
             Enlace a la política de privacidad de proveedores de servicios de terceros usados
             a través de la aplicación:<br></br><br></br>
             <ul>
             <li><a href="https://www.google.com/policies/privacy/" target="_blank" rel="noopener noreferrer">Servicios de Google Play</a></li>
             <li><a href="https://support.google.com/admob/answer/6128543?hl=es" target="_blank" rel="noopener noreferrer">AdMob</a></li>
             </ul>
             </div>
             <br></br> <br></br>

             <p><strong>Cookies</strong></p>
             Las cookies son archivos con una pequeña cantidad de datos que se utilizan comúnmente como identificadores únicos anónimos. <br></br>
             Estos se envían a su navegador desde los sitios web que visita y se almacenan en la memoria interna de su dispositivo.<br></br>
     
             Este servicio no utiliza explícitamente estas "cookies". <br></br>
             Sin embargo, la aplicación puede utilizar código y bibliotecas de terceros que utilizan "cookies" para recopilar información y mejorar sus servicios.<br></br>
             Tiene la opción de aceptar o rechazar estas cookies.<br></br>
             Si elige rechazar nuestras cookies, es posible que no pueda utilizar algunas partes de este servicio.<br></br> <br></br>
      
             <p><strong>Seguridad</strong></p>
             Valoramos su confianza al proporcionarnos su información personal.<br></br>
             Por lo tanto, nos esforzamos por utilizar comercialmente
             medio aceptable de protegerlo. <br></br>
             Recuerde que ningún método de transmisión por Internet o método de transmisión electrónica
             el almacenamiento es 100% seguro y confiable.<br></br>
             Por lo tanto, no podemos garantizar su absoluta seguridad.<br></br> <br></br>

             <p><strong>Enlaces a otros sitios web</strong></p>
             Este servicio puede contener enlaces a otros sitios web.<br></br>
             Si hace clic en un enlace de un tercero, será dirigido a ese sitio web. <br></br>
             Recuerde que estos sitios externos no son operados por nosotros.<br></br>
             Por lo tanto, le recomendamos enfáticamente que revise la Política de privacidad de estos sitios. <br></br>
             No asumimos ninguna responsabilidad por el contenido, las políticas de privacidad o las prácticas de los sitios web de terceros o
             servicios. <br></br><br></br>

             <p><strong>Cambios a esta política de privacidad</strong></p>
             Es posible que actualicemos nuestra Política de privacidad de vez en cuando. <br></br>
             Por lo tanto, le recomendamos que revise esta página periódicamente para ver si hay cambios.<br></br>
             Le notificaremos cualquier cambio publicando la nueva Política de privacidad en esta página.<br></br>
             Esta política entra en vigencia a partir del 01 de septiembre de 2022<br></br><br></br>
             <p><strong>Contáctenos</strong></p>
             Si tiene preguntas o sugerencias acerca de nuestro
             Política de privacidad, no dude en contactarnos en info@kodasware.com.
  </>
)
export default htmlEs