
import React, { useEffect, useState } from 'react';   
import * as S       from './styles';
import guide01      from '../../../../view/DivorceGuide/Guide/Images/guide01.png';
import guide02      from '../../../../view/DivorceGuide/Guide/Images/guide02.png';
import guide03      from '../../../../view/DivorceGuide/Guide/Images/guide03.png';
import guide04      from '../../../../view/DivorceGuide/Guide/Images/guide04.png';
import util         from '../../../../Utilities/Util';

function GuidePart1() {
    
    const [access, setAccess] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)
        setAccess( util.checkGuideAccess() );
    }, [])

    return (
        <S.Container>
        { ( access )
            ?
            <>
            <S.Form> 

            <S.FormTitle>
			<span>PART 1</span><br></br><br></br><br></br><span>Personal Characteristics</span><br></br><br></br>            
			</S.FormTitle>

            <S.FormText>
                <span>

                What makes a person want or request for a divorce? It is a personal answer that only those involved can answer this question.<br></br><br></br>
                However, when there is a threat or consummation of physical, mental or social violence by one of the spouses against the other or against the children. It is no longer a choice. It is a matter for the police. 
                The person threatened or assaulted must go to the competent authorities as soon as possible and put an end to this destructive and dangerous relationship, but when there is no such violence, the motives can be just and right as well as unfair and meaningless making it painful for everyone. <br></br><br></br>
                When the couple have mutual respect and agreement the divorce really goes smoothly and becomes a relatively short-term court operation. However, when the chosen is another it becomes a future procedure with uncertain consequences.<br></br><br></br>
                Many factors will influence and contribute to the choice of path to be used in a divorce proceeding. It may depend on the modality adopted in the matrimonial. If there are minor or dependent children, number of assets, competence of the lawyer and mainly on the personal characteristics of those involved such as their character, gender, emotional state, finances, etc.<br></br><br></br>
                Therefore, divorce is an event that due to these factors and characteristics can be a quiet operation or something very costly, devastating and with an uncertain future.
                 <br></br><br></br><br></br><br></br>
                <h2>Character</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide01} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                It is a set of qualities and defects of a person that will determine their conduct behavior and the way they act and react to situations.<br></br><br></br>
                Dishonest people who do not show firm principles and attitudes are classified as <b><u>PERSON OF BAD CHARACTER</u></b> unlike those known as <b><u>PERSON OF CHARACTER</ u> </b> who are not motivated by lies and/or personal interests, but by moral firmness and high-value attitudes.<br></br><br></br>
                If one or both of the spouses have a bad character the divorce will undoubtedly be a painful and destructive procedure with negative consequences for those involved and their children. 
                A hostile divorce puts the parties under enormous emotional stress where the survival instinct is activated. When that happens, everything becomes threatening and of unrealistic importance. 
                Anything can be a reason for discussion and discord since future survival will depend on the benefits and advantages acquired. Character is the balance that can set limits and end this dispute in a rational, honest, realistic and mostly advantageous for all, but when the character is low in one of them everything is allowed to win that contest and get as many advantages and benefits as possible.<br></br><br></br>
                People we live with for years can become destructive executioners without mercy, consideration, respect or gratitude. Your objective will only be the destruction of your opponent. Everything good that happened in the relationship will be denigrated and adulterated. Everything will be erased and forgotten. No sacrifice or noble act performed in the past will be considered by justice or the party with the greatest power. 
                The truth and facts will be corrupted and removed from the process. This whole scenario can be further inflamed depending on the defender chosen. Studies show that <b><u>70% OF DEFENDERS</u></b> have the same character as their contractors. This indicates that there is a very significant possibility that the lying party has an advocate of the same character and personality. 
                The production of false and incriminating evidence as well as the presence of people who will give false testimony can be a terrible reality.<br></br><br></br>
                Believing that people of high character have an advantage over others of lesser character is a destructive illusion and it has no correspondence with reality only in books and film productions does this happen. 
                In the real world, justice has neither the capacity nor the competence to assess the nobility or goodness of people. Quite the contrary, it clings to the reports received often inflamed with lies and defamation. 
                Generally, he distrusts benevolent and honest people preferring to believe in their wickedness and dishonesty. 
                This happens because unfortunately we are surrounded by events and people without limits, scruples or character. Therefore, when a person presents himself as having high principles and noble attitudes he appears to be a liar or a swindler, unless he can prove the opposite of this thought with incontestable evidence. In the end, divorce shows the true character of the people involved.<br></br><br></br>
                <b><i>Character is a determining factor in the outcome of a divorce process more important than any other that may exist.</i></b>
                <br></br><br></br><br></br><br></br>
                <h2>Emotional State</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide02} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                Contrary to what people believe we are not rational beings we are emotional beings. All of our choices, even those that seem logical and rational are driven by our emotions. 
                There is no exception to this rule according to the science that studies the functioning of the human brain. <br></br><br></br>
                Divorce is a painful fact in the life of any person regardless of how it is carried out. 
                It represents the end of a relationship, dreams and projects idealized by the couple. The feeling present in divorce can be similar to <b><u>MOURNING</u></b> which represents deep sadness at the loss of someone.
                Whoever requests the end of the union goes through a whirlwind of feelings often with a feeling of guilt for the established situation. 
                The same happens with the person who receives the order, but in this case the resulting feeling is anger or rejection. 
                Few divorces are amicable and consensual, but when they do occur, they are also tied to emotions like loss and frustration. <br></br><br></br>
                The period leading up to filing for divorce is often surrounded by hectic and negative emotions and situations. 
                No one emotionally balanced request for a divorce without these events having previously occurred. 
                It is a period that is usually accompanied by discussions, disrespect and lies between the parties. 
                In some cases, one of them even openly mentions, suggests or threatens divorce during this period of mutual conflict. 
                Fear is a mediating feeling at this stage. It is a paralyzing feeling that interrupts the actions and desires of those involved. 
                Fear of what will become of your life and that of your children can often postpone the decisions of the couple or of one of them. <br></br><br></br>
                For this reason, an unpleasant situation is often maintained in the hope that it can be modified or to protect children from any harm to them. 
                It is also a very dangerous phase. Where feelings can flare up and thus allow one of them to act thoughtlessly and irrationally. 
                Especially when he feels threatened or cornered in some way in her rights and future life. It is a conflictive stage. 
                It should not be used to expose repressed hurts and resentments unless it is to save or discuss the relationship in an attempt to find positive solutions to the situation. 
                This should only be done if there is dialogue and respect between the parties. 
                Failure to do so could trigger new disagreements and hostilities leading to further diminution of any family harmony that may still exist.<br></br><br></br>
                In addition, it is very likely that those involved in this situation receive suggestions and advice from people outside the relationship such as family and friends. This is also dangerous and not recommended at all. 
                Generally they are bad advisers mainly because they are not harmed or suffer the consequences of a divorce. They tend to illuminate the situation and say what the party wants to hear. 
                It is also very common for the party to despise contrary opinions and accept only those that agree with what they are feeling, thinking or with what is already intimately decided. <br></br><br></br>
                On rare occasions such counseling benefits the person or couple already in disharmony. It is preferable to seek the advice of people who have been through this or professionals in this field, such as psychologists and relationship therapists. <br></br><br></br>
                Currently many people suffer from some <b><u>TYPE OF MENTAL DISORDER</u></b> and use medication to control it. It is a situation that affects the judgment and the emotional part of these people. Many find it difficult to make decisions or clearly understand their situation within their marriage or their own lives. 
                They don't know what they want and their ideas and actions are always changing according to their emotional state and external events that they cannot interpret clearly and objectively. 
                They tend to turn a peaceful scenario or an ordinary situation into something terrible and unresolved. They are people susceptible to opinions and external influences. <br></br><br></br>
                Generally negative and motivated by the individual and financial interests of other people such as jealous friends or lawyers. 
                They do not measure the consequences of their actions and end up using divorce as a means of escape from an unpleasant situation for them that often does not exist or was created by themselves. 
                They do things on impulse without considering the consequences. Generating regret and unpleasant consequences for everyone. 
                Unless there is violence or assault the divorce should not be rushed on impulse. It should be a thoughtful act based on a real life changing goal. <br></br><br></br>
                Another interesting and common point is the fact that a divorce is desired due to the existence of new love relationships. 
                This happens due to the bankruptcy of the relationship or for different reasons that cannot be explained in a simple and generic way, but it is a present and generally irreversible reality. 
                When this happens the opposing party immediately becomes a laughing stock and an impediment to happiness. It becomes disposable and any act of love, kindness or gratitude done in the past is automatically forgotten or despised. 
                Nobody belongs to anyone. We all have the right to fall in love with other people and change our destiny. However, we should not do it irresponsibly. Especially when other people are involved. 
                This is <u><b>EXTREMELY DANGEROUS</b></u> we don't know how these people will react even they don't know. 
                The news is full of tragic examples of people losing control for useless and often meaningless reasons. It is not possible to determine if the other party deserved it or if they are a victim of circumstances, but it is advisable to end a relationship calmly and cordially. 
                If there are children involved this becomes essential mainly because the other party will always be directly or indirectly linked to them. 
                It is a delicate and complicated situation and should not be carried out impatiently. <br></br><br></br>
                Divorce is not as quick as people are told. Unfortunately justice is slow and bureaucratic and causing fights and dissatisfaction with the other party does not help at all. On the contrary. 
                It will further prolong the judge's decision. When divorce is requested the people involved no longer have any commitment or conjugal duties being able to experience another relationship without any legal problem. She simply cannot legally join her new partner until she completes the process and changes her marital status. <br></br><br></br>
                There are other emotional reasons responsible for activating the separation of a couple. In all of them the emotional factor will be present and will control the actions of the participants. It is necessary to understand the emotions present in this decision so that it is possible to control them as much as possible and thus make it possible to carry out a coherent divorce within a tangible reality.                
                <br></br><br></br><br></br><br></br>
                <h2>Financial State</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide03} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

				Amicable divorces are faster and cheaper, but you also have to pay for them. Do not believe in gratuitous justice. Nothing is free. 
                It all costs some money for the people involved. Attempting to carry out a hostile divorce without money is <u><b>FINANCIAL SUICIDE</b></u>. 
                Generally in these cases spirits are high and the emotional state present impairs the correct decision-making of the parties involved. 
                Usually one or both of them want to be right in their claims and do whatever they can to make them happen. 
                The value and costs involved are no longer important and pride prevails over reason. 
                It is the ideal time for unscrupulous professionals to earn money. 
                It is enough to inflame tempers with unrealistic promises that for the most part will not be fulfilled. 
                The more processes there are the greater the economic gain of these professionals.
                In addition, justice is all fragmented. It is not possible in the same process to discuss multiple unusual points. 
                One is needed to define the distribution of assets another to define the amount of the pension another to define the custody of the children, etc. 
                The greater the number of problems and requirements the greater the number of cases to judge with the payment of costs and lawyers. 
                Appealing enacted decisions also wastes financial resources. In addition, the judge still has the right and power to decide who will pay the costs of each existing process. <br></br><br></br>
                It is a deficient and unfair system. Do not expect common sense or understanding and above all compassion for your personal and economic situation.<br></br><br></br>
                <b>Someone will always have to pay the bills</b>. <br></br><br></br>
                In the same way that there is a matrimonial industry there is a <u><b>LITIGATION INDUSTRY</b></u> which encourages disputes between the parties offering easy and quick profits which in most cases it will never happen. 
                Unfortunately, people realize this too late usually after their financial resources have been depleted with useless lawsuits. 
                Once again we see the importance of money in this environment.
                It is the resource that makes any process possible. People need to understand that time and money spent on legal proceedings can never be recovered. 
                It is common for people to lose their assets often hard-earned in this type of proceeding.<br></br><br></br>
                <u><b>It is preferable to make a BAD DEAL</b></u> than to spend time and money on exhausting and painful processes that do not produce the expected return or result. 
                A contested divorce is something terrible and very expensive. You have to do everything possible to avoid it. When this is not possible it should not be done in anger, pain, or inflamed feelings. 
                The spirits will already be very excited with the end of the marriage. Therefore as far as possible it will have to be done coldly and objectively to achieve realistic results. <br></br><br></br>
                It is also very important to clarify and emphasize that justice can unfortunately be <b>illogical, unfair and irrational</b>. In many cases the money will have to appear by decree. 
                It will be useless to prove through reliable documents that you do not have money, are unemployed or have another type of valid impediment. The money will have to magically appear. This is crazy, but unfortunately we are surrounded by people like this who determine our lives without any kind of empathy or consideration. 
                When you use justice to redeem your rights you will discover how the real world works and you may have the opportunity to meet people with this level of irrationality. 
                The fairness and results may be quite different from what is expected or promised. Therefore, do not waste your time and especially your financial resources with justice. As far as possible resolve your differences without having to activate it. 
                Use these resources to rebuild your life and don't try to prove yourself right. Use it to enjoy time with your kids and friends for traveling, studying, etc.<br></br><br></br>
                On many occasions it is <b><u>BETTER TO BE HAPPY THAN TO BE RIGHT</u></b>. Do not be dominated by your <b><u>EGO</u></b>. Don't waste your money on something that does not work.                
			    <br></br><br></br><br></br><br></br>
                <h2>Gender</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide04} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                <i><b>The gender of the spouses defines the future and the outcome of a divorce process.</b></i> <br></br><br></br>
                Men are the biggest losers in any claim they make. The laws only favor women who are unlikely to lose a case unless there are very serious distortions in their actions and attitudes that cannot be defended or justified. <br></br><br></br>
                It is unquestionable and it happens in most countries with Western culture. There are many reasons that justify this reality and place women in a privileged and untouchable position in relation to men.
                The main one is motherhood. This biological ability automatically strips women of any image of criminality or immorality. Turning any of them into a sweet and emotional being who when committing crimes does so out of emotion even victimizing children and companions in these actions. 
                Because of this the women end up receiving lighter and lesser sentences than men who commit similar crimes. <br></br><br></br>
                The statistics show that they are the most responsible for domestic violence, but crimes of this type are rarely reported in the media except when they do not follow the pattern of normality and are excessively brutal. 
                Even so, these crimes are treated as passionate and motivated by the attitudes of the victimized companion which makes the crime justified and applauded by the general public. 
                This same mindset applies to divorce and its results. <br></br><br></br>
                Man is always labeled as inept, violent and incapable of educating his children, having only the obligation to support them and nothing else. 
                Anything he has done for the benefit of his children and partner will have been a mere obligation and will not be used to assess his character or ability. 
                That is why women are the preferred clients of lawyers. The probability of victory is close to 100% and they can inflame processes with absurd reports and claims without the need to prove them, because they know that they will be accepted or at least taken into account by the courts. 
                Unlike men who rarely have their claims met even with overwhelming evidence in their favor.<br></br><br></br>
                Men are being annihilated physically, morally, mentally and legally. It is an irreversible and accentuated process. Laws have already exceeded the limits of fairness, isonomy and common sense and new ones continue to be developed and implemented. 
                It is a global process that is not limited to our country or our laws. <br></br><br></br>
                Currently, <b><u>A WOMAN CAN DESTROY THE LIFE OF A MAN WITH EXTREME EASE</u></b> just with a complaint against him without having to prove absolutely anything just your word. 
                After all men are scoundrels, aggressive and potential rapists, so they should be treated like criminals. If he manages to prove her innocence nothing will happen to the woman who denounced him despite having been arrested, humiliated gone through all kinds of embarrassment and his life ruined. 
                You can only request a claim for moral damages against her which after a long time will result in something unsatisfactory without ever compensating or minimizing all the violence suffered and the permanent marks left by her. 
                The number of false complaints filed against men has risen to thousands in just one year. This phenomenon is occurring in almost all Western countries. 
                The accusations turned out to be false because the defendants somehow managed to prove their innocence, but this leads to an interrogation. 
                Those who could not do this, are they all really guilty? The system has lost all rationality and privileges only one side. 
                Many men still don't realize this. They believe that these laws will not affect them and that they were designed to fight crime and that they should not fear justice.<br></br><br></br>
                <i><b>Terrible mistake. When the laws are totalitarian there is no justice. </b></i><br></br><br></br>
                An important part of the male population has already realized this situation and is abandoning relationships with women in order to survive. 
                This attitude is being carried out even by younger people who have already realized this reality and do not want to suffer the same abuse and violence that is committed against their parents or people they know. 
                The problem is so serious that the authorities are already worried about the future consequences of all this, but do not think that there will be a setback in this system. On the contrary. New laws targeting this group of people are being planned. <br></br><br></br>
                Soon, not having relationships or being single will be considered a crime against women.
                <br></br><br></br>
                
                </span>
            </S.FormText>

            </S.Form>
            </>
            :
            <>
            <br></br>
            <br></br>
            <h3>&nbsp;</h3>
            <br></br>
            </>    }  
        </S.Container>
    )
}

export default GuidePart1;