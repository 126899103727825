
import React, { useState, useEffect } from 'react';   
import * as S       from './styles';
import guide22      from '../../../../view/DivorceGuide/Guide/Images/guide22.png';
import guide23      from '../../../../view/DivorceGuide/Guide/Images/guide23.png';
import guide24      from '../../../../view/DivorceGuide/Guide/Images/guide24.png';
import guide25      from '../../../../view/DivorceGuide/Guide/Images/guide25.png';
import guide26      from '../../../../view/DivorceGuide/Guide/Images/guide26.png';
import util         from '../../../../Utilities/Util';

function GuidePart4() {
    
    const [access, setAccess] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)
        setAccess( util.checkGuideAccess() );
    }, [])

    return (
        <S.Container>
        { ( access )
            ?
            <>
            <S.Form> 

            <S.FormTitle>
                <span>PART 4</span><br></br><br></br><br></br><span>Legal Processes and Procedures</span><br></br><br></br>
            </S.FormTitle>

            <S.FormText>
                <span>
                Contrary to what people believe divorce is not an isolated process easy to carry out and quickly concluded.
                It comes with various child elements that make it easier or harder to run. Many of them can generate disaffection, negative consequences and long-term disorders in the lives of those involved.
                In addition to producing costs and unplanned losses that will never be replaced. Everything will always depend on the characteristics of the couple the professionals hired the type of divorce that is adopted and mainly the members of the court selected to carry it out.
                 <br></br><br></br>Knowing the components of divorce is an essential and necessary task to avoid unnecessary mistakes and surprises.
    
                <br></br><br></br><br></br><br></br>
                <h2>Divorce</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide22} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                It is the final dissolution of marriage.<br></br><br></br>

                <br></br><br></br><br></br><br></br>
                <b>Estadísticas</b>
                 <br></br><br></br><br></br>

                 Divorce numbers:<br></br><br></br>

                <table border="1" >
                <tbody>
                <tr> 
                <td>53%</td> 
                <td>UNITED STATES</td>
                </tr>
                <tr>
                <td>55%</td>
                <td>FRANCE</td>
                </tr>
                <tr>
                <td>58%</td>
                <td>ESTONIA</td>
                </tr>
                <tr>
                <td>63%</td>
                <td>SPAIN</td>
                </tr>
                <tr>
                <td>67%</td>
                <td>HUNGARY</td>
                </tr>
                <tr>
                <td>68%</td>
                <td>PORTUGAL</td>
                </tr>
                <tr>
                <td>71%</td>
                <td>BELGIUM</td>
                </tr>
                </tbody>
                </table>

                <br></br><br></br>
                Divorce numbers in the United States:<br></br><br></br> 
               
                <table border="1" >
                <tbody> 
                <tr> 
                <td>66%</td>
                <td>of divorces are filed by women</td>
                </tr>
                <tr>
                <td>04%</td>
                <td>of military personnel request for divorce each year</td>
                </tr>
                <tr>
                <td>43%</td>
                <td>of children grow up without the presence of their father</td>
                </tr>
                <tr>
                <td>41%</td>
                <td>of the first marriage ends in divorce</td>
                </tr>
                <tr>
                <td>60%</td>
                <td>of second marriages end in divorce</td>
                </tr>
                <tr>
                <td>73%</td>
                <td>of the third marriage ends in divorce</td>
                </tr>
                <tr>
                <td>08</td>
                <td>years is the average length of marriage</td>
                </tr>
                <tr>
                <td>13</td>
                <td>seconds is the interval between divorce requests</td>
                </tr>
                </tbody>
                </table>

                <br></br><br></br><br></br><br></br>
                <b>Judicial Divorce</b><br></br><br></br>
                 It is the divorce carried out in court. It can be consensual or litigious. It will be a judge who assesses the request and issues a sentence describing the rights and obligations of the parties involved.
                 Couples with children are obliged to carry out a judicial divorce even if the parties agree on everything.

                 <br></br><br></br><br></br>
                 <b>Consensual Divorce</b><br></br><br></br>
                 It is an amicable divorce where the parties agree on all points and want to carry out the legal dissolution of the marriage in a cordial, peaceful and civilized manner. <br></br><br></br>
                 They usually bring few or no unfortunate consequences for the parties and children. <br></br><br></br>
                 <i><b>It is the best way to carry out a divorce avoiding loss of time and money for both.</b></i> <br></br><br></br>
                 Many couples after this type of divorce continue to relate and help each other something very important for the physical and mental development of their children and for their lives.

                 <br></br><br></br><br></br>
                 <b>Litigious Divorce</b><br></br><br></br>
                 It is any legal divorce in which the parties do not agree with some or all the points of the process.
                 It is up to the judge to analyze and issue a final ruling on all of them, including child custody and alimony. <br></br><br></br>
                 It is the most expensive and time-consuming type of divorce there is with unpredictable consequences for existing parties and children.
                 Before launching into the legal dispute it is important to be aware of the financial issue. <br></br><br></br><u>
                 It is not a recommended type of divorce it is preferable to make a bad deal between the parties than to try for a more satisfactory result in court</u>.<br></br><br></br>
                 As it can take a long time and take years to complete. The judge orders temporary custody of the children to one of the parties and also a temporary alimony of an amount calculated by him.  
                
                 <br></br><br></br><br></br>
                 <b>Out-of-court Divorce</b><br></br><br></br>
                 It is done only when the couple does not have minor or disabled children and when the spouses agree on everything related to the divorce. It is carried out at a notary without the presence of a judge. 
                 It must be drafted by a lawyer to preserve the rights of the parties involved and prevent any future damage.
                 However, if the woman is pregnant the divorce must be done judicially.<br></br><br></br>

                 <b>Not all countries have this type of divorce in their legal system</b>.

                <br></br><br></br><br></br><br></br>
                <h2>Partition of Assets</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide23} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                The division of assets is not mandatory, the couple can keep the property in a condominium. Otherwise, the distribution or division of the couple's assets must be made taking into account the nuptial pact that was signed at the time of marriage. <br></br><br></br>
                Subsequently, it is necessary to identify and qualify the assets to be shared. Which ones are private and which ones were acquired during the marriage.
                In addition to assets, debts incurred during the marriage must be divided between the spouses following the same criteria described.
                <br></br><br></br><br></br>

                <b>Marriage Regime</b><br></br><br></br>
                It is the set of rules that regulate the property effects resulting from marriage.
                The spouses previously define the property regime of community assets.
                This arrangement can be changed at any time during the marriage.<br></br><br></br>
                <b>Not all countries have the same types of matrimonial regimes in their legal system</b>.
                <br></br><br></br><br></br>
                
                <b>Universal</b><br></br><br></br>
                In this matrimonial regime all the assets of the marriage will be divided with the exception of those received by inheritance or donated with a non-transferable clause or those acquired in cash from the sale of inherited or donated assets or for personal and professional use. <br></br><br></br>
                Depending on the amount and how the couple owns the assets they can be divided without the need to be sold avoiding the depreciation of the assets and the time spent on this procedure.
                <br></br><br></br><br></br>

                <b>Partial</b><br></br><br></br>
                In this matrimonial regime the assets acquired before the marriage or those that were received by inheritance or donation will not be divided only those that were acquired during the marriage will be divided. 
                They will be divided in equal parts (50% for each one) even if the economic contribution of the spouses to the acquisition of assets was unequal.
                <br></br><br></br><br></br>

                <b>Total Separation</b><br></br><br></br>
                In this matrimonial regime the assets acquired before and during the marriage will not be divided, unless they are in the name of both.
                Each spouse will also be responsible for the debts they have contracted except those intended for the benefit of the family.

                <br></br><br></br><br></br><br></br>
                <h2>Child Custody</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide24} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                A life change occurs when a couple ends a relationship. Mutual duties cease to exist and each one will be able to do what he wants with her life without being accountable to the other. <br></br><br></br>
                However, when minor or incapable children arise from this union the rights and duties of the parents towards them remain unalterable. 
                It is necessary to define how children will be protected and educated as well as to determine how this task will be distributed among those responsible. <br></br><br></br>
                It is the government that defines these functions and responsibilities although there is consensus between the spouses in this regard. 
                It is a judge who ratifies the agreement between the parents. He determines who has custody of the children and this role can be shared between the parents as he decides.
                <br></br><br></br><br></br> 

                <b>Unilateral Custody</b><br></br><br></br>
                It is custody where only one of the parties has all the rights over the children while the other only has the obligation to support them with pensions. <br></br><br></br>
                <u><b>WOMEN OWN THEIR CHILDREN</b></u>. <br></br><br></br>
                Eventually, they may lose custody of the children when the father proves the mother's inappropriate behavior that affects the well-being of the children such as irresponsible or aggressive attitudes that cause them insecurity, physical or psychological dangers.
                The evidence must be consistent and many of it analyzed and verified through the expert witnesses and auxiliary collaborators designated by the Judgment. <br></br><br></br>
                This is the most profitable modality for the divorce industry since it generates numerous disagreements between the parties and produces several processes in an attempt to correct them with little or no concrete results for the applicant. 
                Just waste of time and money.
                <br></br><br></br><br></br>

                <b>Shared Custody</b><br></br><br></br>
                This is when both parents have legal custody of their children. The housing can also be altered depending on the couple's financial conditions. <br></br><br></br>
                In this modality they jointly sign any document related to their children such as school, health, etc. That way they are both aware of what happens to their children. <br></br><br></br>
                <i><b>In this type of custody the father's obligation to support them through alimony is neither exempt nor shared.</b></i><br></br><br></ br>
                It is also necessary that the parties are in perfect harmony for it to be authorized by the Judge. <br></br><br></br>
                It is a very important type of custody mainly to avoid prejudice to parents in the education of their children and to correct the deviations and injustices of unilateral custody. <br></br><br></br>
                <br></br><br></br><br></br>
                
                <b>Alternate Custody</b><br></br><br></br>
                It is a type of custody in which the parties alternate from time to time the residence of the children. Once with the father once with the mother. <br></br><br></br>
                <b>Not all countries have this type of custody in their legal system</b>.<br></br><br></br>
                "According to specialists" alternate custody is not recommended as it can be detrimental to the mental health of the child confusing certain important references in the initial phase of their formation. <br></br><br></br>
                In other words according to them <u><b>THE ADDRESS IS MORE IMPORTANT THAN THE FATHER'S PRESENCE IN CHILDREN'S EDUCATION</b></u>. <br></br><br></br>
                Traumas that are already caused by the divorce are acceptable, but any trauma caused by a change of residence is not acceptable. <br></br><br></br>
                <br></br><br></br><br></br>

                <b>Parental Alienation</b><br></br><br></br>
                <i><b>It is a crime committed against children by one of the parents by both or by close relatives.</b></i> <br></br><br></br>
                The alienator tries to turn his children against the other party. <br></br><br></br>
                Psychologically manipulates the child who begins to show fear, disrespect or hostility towards the other party. <br></br><br></br>
                Generally, the guardian party uses her privileged position to carry out the alienation of the children creating derogatory and lying stories that cause her repudiation and discontent with the alienated party. <br></br><br></br>
                It is a cowardly and treacherous act typical of people without character and with problems. Acts often worthy of psychopaths due to their degree of coldness, destruction and perversity. <br></br><br></br>
                This criminal action causes serious damage to the child with long-term effects most of the time irreversible.<br></br><br></br>
                Some symptoms can be identified in the child victim of this situation such as: anxiety, nervousness, aggressiveness, depression, identity disorder, disorganization, isolation, insecurity, learning difficulties, feelings of guilt, despair and various other destructive feelings. <br></br><br></br>
                The affected party must request a lawsuit against the alienator. <br></br><br></br>
                It will be necessary to present consistent evidence as well as the use of experts selected by the judge to validate and attest to the reported situation. <br></br><br></br>
                It is a process that costs time and money and once concluded the judge can apply to the transferor one or more of the following sanctions:
                <br></br><br></br>

                <table border="1" >
                <tbody>
                <tr><td>Declare the occurrence of parental alienation and warn the alienator</td></tr>
                <tr><td>Extend the regime of family coexistence in favor of the alienated parent</td></tr>
                <tr><td>Stipulate a fine for the transferor</td></tr>
                <tr><td>Determine psychological and/or biopsychosocial monitoring</td></tr>
                <tr><td>Determine the change in the type of guard</td></tr>
                <tr><td>Declare suspension of parental authority</td></tr>
                </tbody>
                </table> 

                <br></br><br></br>
                Despite being a crime of atrocious nature and with destructive and often irreversible consequences it does not provide for the imprisonment of the alienator which usually ends only with a reprimand or a fine and nothing more. <br></br><br></br>
                <b>Most countries in the world do not have any legislation to deal with this problem</b>.<br></br><br></br>
                Currently, this law is under great pressure to be repealed in the countries where it is applied and there are even pending bills in this regard.<br></br><br></br>
                The concept of parental alienation was formulated in the 1980s by the American psychiatrist Richard Gardner who defended the existence of a syndrome that affects children and adolescents of divorced parents.<br></br><br></br>
                In the United States about 80% of the children of divorced parents have already experienced some form of parental alienation.<br></br><br></br>
                It is estimated that more than 20 million children in the world suffer this type of violence.

                <br></br><br></br><br></br><br></br>
                <h2>Alimony</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide25} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                It is a mandatory amount to pay to cover the expenses of those who do not have their own means of subsistence.
                Within this amount, which will be calculated and designated by a judge are the expenses of food, health, transportation, clothing, recreation, education and any other that is necessary for the subsistence of the applicant. <br></br><br></br>
                It is a benefit that is granted preferably to children and/or ex-spouses, but it can also be granted to elderly or disabled relatives such as parents and grandparents when they request it. <br></br><br></br>
                Alimony is considered a sacred obligation and must be paid even if the payer is unemployed or unable to do so for any reason. <br></br><br></br>
                The responsibility to raise and support children rests with both parents not just one of them nor with their ancestors or close relatives.
                However, the pension is treated as a moral obligation and responsibility of <u>only one of them</u>. <br></br><br></br>
                Alimony is surrounded by politically correct speeches that try to justify its obligation. <br></br><br></br>
                <i><b>Always portray the winning party as the victim and the losing party as the villain</b></i>. <br></br><br></br>
                The break-even point between the parties has long since been exceeded.
                There is nothing sacred in this procedure it is cruel, inhuman and above all imposing.
                Compared only to the slave systems of ages past.
                
                <br></br><br></br><br></br>
                <b>Food for pregnant women</b><br></br><br></br>
                It is a law that entitles a pregnant mother to receive economic assistance from the father <u>indicated</u> by her. <br></br><br></br>
                It is your obligation to present the <i>"paternity test"</i> through photographs, witnesses, letters or any other means used as evidence. <br></br><br></br>
                <b>The principle of presumption of innocence is completely ignored.</b><br></br><br></br>
                Once convinced of the existence of paternity tests the judge will define the amount of alimony that will be paid until the child is born, according to the needs of the mother and the possibilities of the father. <br></br><br></br>
                The defined amount must be sufficient to cover the additional expenses during the pregnancy period and derived from conception until childbirth including those related to special food, medical and psychological assistance, complementary examinations, hospitalizations, medicines and any other that the judge deems necessary. <br></br><br></br>
                If the presumed father does not have the economic conditions to comply with this obligation the burden will be transferred to the presumed paternal grandparents and failing that to the relatives up to the second degree. <br></br><br></br>
                During the gestation period medical tests such as DNA that can prove the paternity of the alleged father will not be allowed. After a live birth, the value is automatically converted into alimony in favor of the minor until one of the parties requests its review or dismissal. <br></br><br></br>
                The exemption will be allowed if the man can prove that he is not the biological father of the child born. Therefore, his obligation as a maintenance debtor would be irregular. <br></br><br></br>
                In these two actions the retroactive effect is not applied, that is the money spent will not be returned. If after providing the required payments the presumed father manages to confirm the denial of paternity he can request a lawsuit for material damages against the mother presenting documents that prove the expenses that were improperly imposed and can also add to this action the request for non-pecuniary damage and it is up to him to prove the psychological damage he has suffered. <br></br><br></br>
                However, this type of process does not produce any significant results. In practice, nothing will happen to the woman who intentionally caused this situation. She will not be arrested or suffer any type of sanction.<br></br><br></br>
                <b>This law does not exist in all countries, it is one more example of the legal abuses committed against men</b>.
                
                <br></br><br></br><br></br>
                <b>Spouse's pension</b><br></br><br></br>
                The ex-spouse can request alimony in cases where economic dependency is proven as well as other factors. 
                A widely used argument is to declare having dedicated years of his life to her family renouncing her education personal and professional life and others. <br></br><br></br>
                <i><b>Therefore, following the logic of this type of argument the losing party never dedicated himself to the family nor did he renounce his education personal or professional life, etc. He was just a freeloader.</b></i>
                
                <br></br><br></br><br></br>
                <b>Pension value</b><br></br><br></br>
                The value must be defined taking into account the income and the ability of the person who pays and the needs of the person who requests it. <br></br><br></br>
                Normally, the values are around 10%, 20% or 30% of the gross or net return of the paying party. In fact, there is nothing in the legislation about these percentages. <br></br><br></br>
                <i><b>It is a myth a reference used by many magistrates</b></i>. <br></br><br></br>
                There is no fixed and objective rule for pension arbitration. Therefore, any percentage or amount can be decreed. <br></br><br></br>
                <i>Including percentages much higher than those described.</i> <br></br><br></br>
                Other elements may influence the determination of this percentage or value such as children from another union expenses with parents, health, etc. <br></br><br></br>
                When the party does not have a stable job is a businessman or self-employed worker it will be up to the judge to analyze her personal and economic life and set the amount of the pension based on her analysis.
                
                <br></br><br></br><br></br>
                <b>Revision of the pension amount</b><br></br><br></br>
                The amount and conditions of the pension can be reviewed by any of the parties involved at any desired time. <br></br><br></br>
                It is a procedure that is carried out through an action with that objective. <br></br><br></br>
                Like any awsuit it involves time and money with no guarantee of success and can even produce the opposite effect than desired. <br></br><br></br>
                When the payor loses his job or his standard of living changes he can request a decrease in the amount ordered. 
                Also, if the payer improves his living situation the recipient can request an increase in this amount.
                <br></br><br></br><br></br>
           
                <b>Pension period</b><br></br><br></br>
                The duration of the pension obligation may vary from case to case. The defined pension for the ex-spouse usually has a stipulated expiration date. <br></br><br></br>
                However, in some cases they can become permanent. This happens when the person is no longer old enough to enter the labor market or has health problems. <br></br><br></br>
                In addition, due to the technicality and the slowness of justice it can last forever without a defined period for its completion. <br></br><br></br>
                There are other exceptional conditions such as cases of disability, illness, need for medication and any alleged difficulty that will contribute to the continuity of the pension indefinitely. <br></br><br></br>
                The judge will always have the last word as long as he believes that it must be paid the debtor will have to pay the agreed amount. 
                This value can always vary for more or less.
                
                <br></br><br></br><br></br>
                <b>Alimony exemption </b><br></br><br></br>
                It is an action that the payer must request so that his obligation ceases to exist. <br></br><br></br>
                Without this procedure the compulsory payment will not end even if the child reaches the age of majority. 
                Only the judge can decree the end of the pension. Even so, only after having received the request heard and analyzed the situation of the fed. <br></br><br></br>
                For example, if the child proves that he is a student and needs the pension to pay for his studies this obligation will continue.
                If after that, he is still unable to support himself with his own work and resources he will keep the pension. <br></br><br></br>
                Probably indefinitely, since it's some kind of argument or situation created by weak, complacent, characterless people. <br></br><br></br>
                <i><b>Therefore, only through an exoneration action approved by the judge will the mandatory payment of maintenance be extinguished.</b></i>

                <br></br><br></br><br></br><br></br>
                <h2>Failure to pay alimony</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide26} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                Normally, non-payment can result in the payer's arrest or any other type of penalty. <br></br><br></br>
                <i><b>The money must appear by magic or decree whatever the condition of the debtor.</b></i> <br></br><br></br>
                You must explain to the judge the reason for his unavailability, but there is no specific place to carry out this procedure. <br></br><br></br>
                Then, it must be done through justice with the payment of costs and a lawyer. That is <u><b>YOU WILL HAVE TO SPEND MONEY TO PROVE THAT YOU HAVE NO MONEY</b></u> . <br></br><br></br>
                It is very reasonable, he does not have money to pay alimony, but he will have money to pay the lawsuit and thus prove that he does not have money to pay alimony. <br></br><br></br>
                A process of this type only results in installments or deferral of the debt, but it will have to be paid off somehow.
                Late payments are subject to a penalty and correction. <br></br><br></br>
                Various measures can be used to force the debtor to pay this debt. <br></br><br></br>
                <br></br><br></br><br></br><br></br>
                </span>
            </S.FormText>

            </S.Form>
            </>
            :
            <>
            <br></br>
            <br></br>
            <h3>&nbsp;</h3>
            <br></br>
            </>    }  
        </S.Container>
    )
}

export default GuidePart4;