import React    from 'react';
import Polite   from '../../../global/globalPrivacy';
import * as GV  from '../../../global/globalVariables';

function PrivacyPlanning() {
    
    return (
        <>
        <Polite name={GV.APP_DIVORCE_PLANNING}></Polite>
        </>
    )
}

export default PrivacyPlanning;