import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const Form = styled.div`
    width: 50%;
    margin-top: 60px;

    @media screen and (max-width: 960px) {
        width: 90%;
    }
`

export const InputData = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    padding-left: 25%;

    span {
        color: #707070;
        margin-top: 50px;
        margin-bottom: 5px;
        font-size: 14px;
        font-family: 'Roboto', sans-serif;
        }

    input {
        font-size: 14px;
        font-family: 'Roboto', sans-serif;
        padding-left: 10px;
        height: 30px;

        &:focus {
            outline: none;
            border-color: ${ ( {error} ) => ( error ? '#FF0000' : '#000000' ) }; 
            box-shadow: 0px 0px 0px ${ ( {error} ) => ( error ? 'red' : 'black' ) }; ;
     }
        }

    @media screen and (max-width: 960px) {
        width: 80%;
        padding-left: 10%;
        }

    }
`

export const InputWithIcon = styled.input`
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    padding-left: 40px;
    height: 30px;
    position: relative;

    i {
        position: absolute;
        left: 0;
        top: 8px;
        padding: 9px 8px;
    } 
`

export const SignButton = styled.div`
    width: 50%;
    margin-top: 50px;
    padding-left: 25%;

    button {
        width: 100%;
        background: #70AD47;
        //border: 5px;
        border-width: 0.5px;
        border-color: #385723;
        font-weight: bold;
        font-size: 20px;
        color: #FFF;
        padding: 20px;
        border-radius: 20px;
        cursor: pointer;

        //border-style: solid;
        //border-right-color: transparent;
        //border-top-color: #385723;
        border-top-style: outset;

        &:hover {
            opacity: 0.7;
        }
    }
`

export const FormTitle = styled.div`
    display: flex;
    flex-direction: row;
    padding-left: 25%;
    margin-top: 60px;

    span {
        font-size: 20px;
        font-family: 'Roboto', sans-serif;
        font-weight: bold;
        color: #385723;
        }

    @media screen and (max-width: 960px) {
        padding-left: 10%;
    }
    }
`

export const FormImage = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
        width: 60%;
        }

    }
`
export const FormMessage = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    padding-left: 25%;

    span {
        color: #FF0000;
        margin-top: 30px;
        margin-bottom: 5px;
        font-size: 18px;
        font-family: 'Roboto', sans-serif;
        //font-weight: bold;
        }

    @media screen and (max-width: 960px) {
        width: 80%;
        padding-left: 10%;
    }
    }
`