import React, {useState}                from 'react';
import * as S                           from './styles';
import guide14                          from '../Guide/Images/guide14.png'
import util                             from '../../../Utilities/Util'

function Statistic() {

    const [english,    setEnglish]    = useState(false);
    const [spanish,    setSpanish]    = useState(false);
    const [portuguese, setPortuguese] = useState(false);

    React.useEffect(() => {
  
      const result = util.getLanguage();

      setEnglish   (result.english);
      setSpanish   (result.spanish);
      setPortuguese(result.portuguese);

      }, []);
 
    return (
        <S.Container>

            <S.FormImage><img src={guide14} alt=""></img></S.FormImage>

            { (portuguese) &&
            <S.ContainerData>
                <S.SideLeft>&nbsp;</S.SideLeft>
                <S.CenterData>
                    <S.FormDesc>
                    Os filhos serão os maiores prejudicados em um processo de divórcio. 
                    Muitos irão crescer sem problemas aparentes, mas todos irão desenvolver algum tipo de sequela emocional que afetará suas vidas futura.<br></br><br></br>
                    <u><b>Eles são vítimas inocentes e não devem pagar por desacordos ou problemas entre seus pais</b></u>. <br></br><br></br>
                    Ações que devem ser seguidas para evitar danos na educação de seus filhos antes e depois do divórcio:
                    </S.FormDesc>
                    
                    <S.Title>NUNCA peça ao seus filhos para mentir</S.Title>
                    <S.FormDesc>
                    É um ato covarde e alienador que afetará o bem-estar psíquico de seu filho. 
                    É a forma de um dos genitores esconder alguma informação do outro. 
                    Isso induz o cérebro da criança a um estado progressivo de dessensibilização. 
                    Dessensibilização é tornar algo <i>“normal”</i> devido a muitas repetições. 
                    A formação de uma personalidade desonesta será um dos resultados dessa prática vil.
                    Omitir uma informação é uma mentira silenciosa e produz o mesmo dano mencionado. <br></br><br></br>
                    Caso isso esteja acontecendo, procure as autoridades competentes para penalizar os responsáveis o mais rápido possível.
                    </S.FormDesc>

                    <S.Title>Não brigue ou fale mal de alguém na frente de seus filhos</S.Title>
                    <S.FormDesc>
                    Muitos casais continuam em conflito após o divórcio. 
                    Principalmente, quando houver filhos resultantes do matrimonio. 
                    Acabam discutindo sobre seus problemas na presença deles ou indiretamente através do telefone ou em conversas paralelas, onde declaram suas magoas ou ressentimentos que são ouvidas e captadas por eles. 
                    Qualquer problema entre as partes deve ser discutido e resolvido sem a presença e o conhecimento dos filhos existentes.
                    </S.FormDesc>
                    
                    <S.Title>Fale sempre a verdade para seus filhos</S.Title>
                    <S.FormDesc>
                    Não fale de seus problemas pessoais ou financeiros com seus filhos menores. 
                    Principalmente, não fale nada relacionado ao divórcio e seus motivos. 
                    Sempre seja sincero e fale a verdade na medida do possível. 
                    Omita apenas as informações ou assuntos que não forem pertinentes para a idade deles. 
                    Diga que irá responder suas perguntas quando tiverem idade para entender. 
                    </S.FormDesc>
                    
                    <S.Title>Esteje presente na vida de seus filhos</S.Title>
                    <S.FormDesc>
                    Muitos pais que não possuem a guarda de seus filhos acabam se afastando deles. 
                    Podem não ter condições financeiras, tempo ou estarem emocionalmente afetados. 
                    Inclusive, seus filhos podem estar alienados, ressentidos ou agressivos com eles. 
                    A parte responsável pela guarda também pode dificultar as coisas criando problemas de todos os tipos.  
                    Você deve <b>eliminar</b> todos os obstáculos que aparecerem. 
                    Por exemplo:<br></br> <br></br> 
                    1-Não tem informações sobre a educação do seu filho? Vá para a escola e exija saber. <br></br> 
                    2-Não tem informações sobre a saúde dele? Vá até o pediatra responsável e exija saber. <br></br> 
                    3-Seu filho participa de algum evento e você não foi convidado? Vá assim mesmo. Não pode entrar? Seja visto a distância e mostre que estava lá. <br></br>  
                    4-Fale com seu filho sempre que possível. Não tem como? Compre um celular para ele. Não é possível? Mande uma carta. <br></br> <br></br> 
                    Não importa qual seja a humilhação, dor ou dificuldade que tenha que passar, esteje presente na vida de seu filho. 
                    Demonstre que ele pode sempre contar com você.
                    </S.FormDesc>
                    
                    <S.Title>Não coloque seus filhos contra alguém que você não goste</S.Title>
                    <S.FormDesc>
                    Isso é chamado de <b><u>alienação parental</u></b>. 
                    É colocar ideias, medos ou raiva na mente de uma criança sobre uma determinada pessoa. 
                    Isso geralmente acontece entre os pais separados ou seus parentes próximos. 
                    Isso é feito de maneira direta ou indireta, por exemplo: <br></br> <br></br> 
                    1-Falar mal dá pessoa o tempo todo na frente da criança. <br></br> 
                    2-Culpar a pessoa por alguma coisa que ocorreu. <br></br> 
                    3-Fazer com que a criança espere por algo, mas sabendo que a pessoa não terá condições de realizar. <br></br> <br></br> 
                    Essa ação criminosa provoca prejuízos sérios na criança com efeitos a longo prazo, na maioria das vezes <b>irreversíveis</b>. 
                    Alguns sintomas podem ser identificados na criança vítima desta situação, tais como: ansiedade, nervosismo, agressividade, depressão, transtorno de identidade, desorganização, isolamento, insegurança, dificuldades de aprendizado, sentimento de culpa, desespero, e diversos outros sentimentos destrutivos.
                    </S.FormDesc>

                    <S.Title>Não permita que coloquem seus filhos contra você</S.Title>
                    <S.FormDesc>
                    Nunca faça alienação parental em seus filhos. 
                    Não permita que façam isso com você. 
                    Caso esteja acontecendo, reúna todas as provas necessárias e procure as autoridades competentes para penalizar os responsáveis o mais rápido possível. 
                    Faça o que for necessário para ter seus direitos respeitados e seus filhos livre dessa violência. 
                    Acione os meios de comunicação, policia, judicial e etc. 
                    Não fique jamais em silêncio.
                    </S.FormDesc>
                    
                    <S.Title>Não seduza seus filhos com presentes ou dinheiro</S.Title>
                    <S.FormDesc>
                    É um dos maiores malefícios dos dias atuais. 
                    Os pais não sabem como educar seus filhos. 
                    Por causa disso, usam de artifícios para mantê-los sobre controle. 
                    Esse problema se agrava com pais separados. 
                    Eles competem pela atenção e amor de seus filhos. 
                    Geralmente, a parte mais abastada utiliza seus recursos para humilhar e menosprezar a parte menos favorecida. 
                    Isso tudo é altamente prejudicial. 
                    Eles serão educados para acreditar que ter é melhor que ser. 
                    Seduza seus filhos com momentos de alegria, com coisas que o dinheiro não pode comprar.
                    </S.FormDesc>
                    
                    <S.Title>Brinque com seus filhos</S.Title>
                    <S.FormDesc>
                    Invista uma parte do seu tempo para brincar com os seus filhos. 
                    Brincar não é perder tempo. 
                    Brincar com eles irá produzir muitos benefícios. 
                    É um momento precioso para estar, conhecer e educar seus filhos. 
                    Nas brincadeiras sempre aparecem situações que devem ser resolvidas. 
                    Isso ajudará seus filhos a solucionar problemas no mundo real. 
                    Além de outras inúmeras vantagens. 
                    Eles nunca esquecerão os bons momentos que compartilharam com você.
                    </S.FormDesc>
                    
                    <S.Title>Faça os momentos que tiver com seus filhos especiais</S.Title>
                    <S.FormDesc>
                    Caso não tenha a guarda de seus filhos e seu tempo com eles seja limitado. 
                    Transforme cada instante que tiver com eles em algo especial e inesquecível. 
                    Seja o melhor amigo/a deles. 
                    Não perca seu tempo com magoas ou ressentimentos. 
                    Seus filhos irão crescer e serão esses momentos que serão lembrados por eles.
                    </S.FormDesc>
                    
                    <S.Title>Registre todos os momentos com seus filhos</S.Title>
                    <S.FormDesc>
                    Anote e registre todos os momentos com seus filhos. 
                    Para recordá-los e desfrutá-los no futuro. 
                    Hoje a tecnologia fornece meios gratuitos para realizar essa operação. 
                    Esses registros também podem ser muito importantes, principalmente como defesa de algum processo que possa receber.
                    </S.FormDesc>
                                                            
                    <S.Title>Os pais são responsáveis pela educação dos filhos</S.Title>
                    <S.FormDesc>
                    Atualmente, os pais trabalham fora e os filhos passaram a ser educados por babás, empregadas domésticas, escola, televisão, internet e etc. 
                    Esses novos elementos se tornaram os responsáveis pelo desenvolvimento físico, psíquico e moral dos filhos do casal. 
                    A ausência dos pais foi compensada com consumo material de toda espécie de inutilidade, como brinquedos, jogos de vídeo game, roupas de grife, e etc.  
                    Isso produz pessoas tóxicas, fracas e sem valores morais elevados. 
                    Os pais são responsáveis pela educação dos filhos. <br></br><br></br>
                    <b><u>Essa tarefa e obrigação não pode ser transferida para mais ninguém</u></b>.
                    </S.FormDesc>

                    <S.Title>Informe-se e estude como educar seus filhos</S.Title>
                    <S.FormDesc>
                    Os filhos não nascem com manual de instrução. 
                    Educar é uma arte que precisa ser refinada constantemente. 
                    Felizmente, existem diversos materiais disponíveis com essa finalidade. 
                    Procure sempre se aperfeiçoar nesse assunto. 
                    Principalmente, estude como educar filhos de pais separados. 
                    </S.FormDesc>
                    
                    <S.Title>Seja um modelo para seus filhos, torne-se uma pessoa melhor</S.Title>
                    <S.FormDesc>
                    Filhos significam mudança de vida. 
                    Os genitores são responsáveis pela educação de seus filhos. 
                    Eles serão imagens de seus pais, copiando seus comportamentos, ações a atitudes. 
                    Portanto, para uma boa educação os pais devem ser coerentes no dizem e fazem. 
                    Quando as crianças vivenciam os pais mentindo, sendo rudes, desorganizados e etc. acabam reproduzindo essas mesmas ações inconscientemente. 
                    Logo, os pais devem minimizar ou eliminar seus defeitos e potencializar suas qualidades para que possam ser passadas aos seus filhos. 
                    Seja um modelo para seus filhos. 
                    Seja aquilo que deseja que eles sejam.
                    </S.FormDesc>
                                        
                    <S.Title>Não use seus filhos para entregar recados</S.Title>
                    <S.FormDesc>
                    Muitos casais com filhos não querem conversar após o divórcio. 
                    Alguns utilizam seus filhos como mensageiros entre eles. 
                    Não coloque seus filhos para realizar essa tarefa. 
                    Eles não devem passar por esse tipo de constrangimento ou pressão. 
                    Principalmente, não devem estar cientes de alguns assuntos que podem estar sendo comunicados, nem devem presenciar as reações de quem recebe a mensagem. 
                    As crianças devem ser crianças e os pais devem ser adultos.
                    </S.FormDesc>
                                        
                    <S.Title>Não confie em ninguém</S.Title>
                    <S.FormDesc>
                    Atualmente, muitas pessoas possuem valores deturpados e incoerentes. 
                    Alguns são analfabetos funcionais e não conseguem interpretar um simples texto. 
                    Muitos vivem na fronteira da sanidade e loucura. 
                    Estão entre nós e podem ser médicos, advogados, caixas de bancos, professores, atendentes de supermercado, policiais, e etc. 
                    Ninguém além dos pais é responsável pela segurança e bem-estar dos filhos. <br></br><br></br>
                    <b><u>Tome cuidado com as pessoas e instituições que fazem parte da vida de seus filhos</u></b>. <br></br><br></br>
                    Fique sempre atento. 
                    Não confie em ninguém, nem mesmo em profissionais de renome. 
                    Nunca deixe seus filhos sozinhos com nenhum deles. 
                    Por exemplo, caso seu filho tenha que ser operado exija estar na sala de cirurgia com ele.
                    </S.FormDesc>
                                        
                    <S.Title>Não abandone seus filhos</S.Title>
                    <S.FormDesc>
                    Muitos divórcios são destrutivos. 
                    Geralmente, quem perde a guarda dos filhos acaba sofrendo traumas financeiros e pessoais. 
                    A parte responsável pela guarda também pode dificultar sua convivência com eles e criar problemas de todos os tipos. 
                    Por causa disso, muitos procuram novos relacionamentos para minimizar suas frustrações. 
                    Acabam gerando novas famílias e filhos nessa ação. 
                    Como resultado, a maior parte de sua atenção e esforço são agora direcionados para sua nova vida. 
                    Então, os filhos de seu relacionamento anterior acabam sendo colocados em segundo plano ou até mesmo esquecidos. <br></br><br></br>
                    Todo mundo tem o direito de reconstruir sua vida e de cria novos vínculos. 
                    Contudo, filhos são responsabilidades para <b>toda vida</b> e não apenas quando as condições ao seu redor são benéficas e favoráveis. 
                    Não importa as dificuldades ou injustiças sofridas, jamais abandone seus filhos. <br></br><br></br>
                    <b><u>Demonstre mesmo a distância que você está presente na vida deles</u></b>.
                    </S.FormDesc>

                    <br></br> <br></br> <br></br>                     
                </S.CenterData>
                <S.SideRight>&nbsp;</S.SideRight>
            </S.ContainerData> 
            }

            { (spanish) &&
            <S.ContainerData>
                <S.SideLeft>&nbsp;</S.SideLeft>
                <S.CenterData>
                    <S.FormDesc>
                     Los niños serán los más afectados en un proceso de divorcio.  
                     Muchos crecerán sin problemas aparentes, pero todos desarrollarán algún tipo de cicatriz emocional que afectará sus vidas futuras. <br></br><br></br>
                     <u><b>Son víctimas inocentes y no deben pagar por desavenencias o problemas entre sus padres</b></u>. <br></br><br></br>
                     Acciones que se deben seguir para evitar daños en la educación de sus hijos antes y después del divorcio:
                    </S.FormDesc>
                    
                    <S.Title>NUNCA pida a sus hijos que mientan</S.Title>
                     <S.FormDesc>
                     Es un acto cobarde y alienante que afectará el bienestar psíquico de su hijo.
                     Es una forma de que uno de los padres oculte cierta información al otro.
                     Esto induce al cerebro del niño a un estado de desensibilización progresiva.
                     La desensibilización es hacer algo <i>“normal”</i> debido a demasiadas repeticiones.
                     La formación de una personalidad deshonesta será uno de los resultados de esta vil práctica.
                     Omitir información es una mentira silenciosa y produce el mismo daño que el mencionado. <br></br><br></br>
                     Si esto está sucediendo, busque que las autoridades competentes sancionen a los responsables lo antes posible.
                     </S.FormDesc>
                     
                     <S.Title>No pelees ni hables mal de nadie delante de tus hijos</S.Title>
                     <S.FormDesc>
                     Muchas parejas continúan luchando después del divorcio.
                     Sobre todo cuando hay hijos fruto del matrimonio.
                     Terminan discutiendo sus problemas en su presencia o indirectamente por teléfono o en conversaciones paralelas, donde declaran sus heridas o resentimientos que son escuchados y captados por ellos.
                     Cualquier problema entre las partes debe ser discutido y resuelto sin la presencia y el conocimiento de los niños existentes.
                     </S.FormDesc>
                    
                     <S.Title>Di siempre la verdad a tus hijos</S.Title>
                     <S.FormDesc>
                     No hables de tus problemas personales o económicos con tus hijos menores.
                     Sobre todo, no diga nada relacionado con el divorcio y sus razones.
                     Sea siempre sincero y diga la verdad tanto como sea posible.
                     Solo omita información o temas que no sean relevantes para su edad.
                     Digamos que responderá a sus preguntas cuando tengan la edad suficiente para entender.
                     </S.FormDesc>
                    
                     <S.Title>Hazte presente en la vida de tus hijos</S.Title>
                     <S.FormDesc>
                     Muchos padres que no tienen la custodia de sus hijos acaban alejándose de ellos.
                     Pueden no tener condiciones económicas, de tiempo o estar afectados emocionalmente.
                     Incluso sus hijos pueden estar alienados, resentidos o agresivos con ellos.
                     La parte que tiene la custodia también puede dificultar las cosas al crear problemas de todo tipo.
                     Debes <b>eliminar</b> todos los obstáculos que aparecen.
                     Por ejemplo:<br></br> <br></br>
                     1-¿No tienes información sobre la educación de tu hijo? Ir a la escuela y exigir saber. <br></br>
                     2-¿No tienes información sobre su salud? Acude al pediatra responsable y exige saber. <br></br>
                     3-¿Su hijo participa en un evento y usted no fue invitado? Ve de todos modos. ¿No puedes entrar? Déjate ver desde la distancia y demuestra que estuviste allí. <br></br>
                     4-Hable con su hijo siempre que sea posible. ¿No tiene como? Cómprale un celular. ¿No es posible? Enviar una carta. <br></br> <br></br>
                     No importa la humillación, el dolor o la dificultad que tenga que atravesar, esté presente en la vida de su hijo.
                     Demuéstrale que siempre puede contar contigo.
                     </S.FormDesc>
                     
                     <S.Title>No enfrentes a tus hijos con alguien que no te gusta</S.Title>
                     <S.FormDesc>
                     Esto se llama <b><u>alienación de los padres</u></b>.
                     Es poner ideas, miedos o ira en la mente de un niño sobre cierta persona.
                     Esto suele ocurrir entre padres separados o sus parientes cercanos.
                     Esto se hace directa o indirectamente, por ejemplo: <br></br> <br></br>
                     1-Hablar mal le da a la persona todo el tiempo delante del niño. <br></br>
                     2-Culpar a la persona por algo que sucedió. <br></br>
                     3-Hacer esperar algo al niño, pero sabiendo que la persona no podrá hacerlo. <br></br> <br></br>
                     Esta acción delictiva provoca graves daños al niño con efectos a largo plazo, la mayoría de las veces <b>irreversibles</b>.
                     Se pueden identificar algunos síntomas en el niño víctima de esta situación, tales como: ansiedad, nerviosismo, agresividad, depresión, trastorno de identidad, desorganización, aislamiento, inseguridad, dificultades de aprendizaje, sentimientos de culpa, desesperación y varios otros sentimientos destructivos.
                     </S.FormDesc>

                     <S.Title>No dejes que tus hijos se vuelvan contra ti</S.Title>
                     <S.FormDesc>
                     Nunca hagas alienación parental con tus hijos.
                     No dejes que te hagan esto.
                     Si está ocurriendo, reúna todas las pruebas necesarias y busque a las autoridades competentes para sancionar a los responsables lo antes posible.
                     Haz lo que sea necesario para que se respeten tus derechos y tus hijos estén libres de esta violencia.
                     Activar los medios de comunicación, policía, juzgados, etc.
                     Nunca te quedes callado.
                     </S.FormDesc>
                    
                     <S.Title>No seduzcas a tus hijos con regalos o dinero</S.Title>
                     <S.FormDesc>
                     Es uno de los mayores males de la actualidad.
                     Los padres no saben cómo educar a sus hijos.
                     Por eso, utilizan trucos para mantenerlos bajo control.
                     Este problema se ve agravado por los padres separados.
                     Compiten por la atención y el amor de sus hijos.
                     Generalmente, la parte más acomodada utiliza sus recursos para humillar y menospreciar a la parte menos favorecida.
                     Todo esto es muy dañino.
                     Serán educados para creer que tener es mejor que ser.
                     Seduce a tus hijos con momentos de alegría, con cosas que el dinero no puede comprar.
                     </S.FormDesc>
                    
                     <S.Title>Juega con tus hijos</S.Title>
                     <S.FormDesc>
                     Invierte parte de tu tiempo en jugar con tus hijos.
                     Jugar no es perder el tiempo.
                     Jugar con ellos te reportará muchos beneficios.
                     Es un momento precioso para estar, conocer y educar a vuestros hijos.
                     En los juegos siempre hay situaciones que hay que resolver.
                     Esto ayudará a sus hijos a resolver problemas en el mundo real.
                     Además de otras numerosas ventajas.
                     Nunca olvidarán los buenos momentos que compartieron contigo.
                     </S.FormDesc>

                     <S.Title>Haz especiales los momentos que pasas con tus hijos</S.Title>
                     <S.FormDesc>
                     Si no tienes la custodia de tus hijos y tu tiempo con ellos es limitado.
                     Convierte cada momento que pases con ellos en algo especial e inolvidable.
                     Ser el mejor amigo de ellos.
                     No pierda su tiempo con sentimientos heridos o resentimientos.
                     Tus hijos crecerán y estos momentos serán recordados por ellos.
                     </S.FormDesc>
                    
                     <S.Title>Graba todos los momentos con tus hijos</S.Title>
                     <S.FormDesc>
                     Anota y graba cada momento con tus hijos.
                     Para recordarlos y disfrutarlos en el futuro.
                     Hoy la tecnología proporciona medios gratuitos para llevar a cabo esta operación.
                     Estos registros también pueden ser muy importantes, especialmente como defensa contra cualquier demanda que pueda recibir.
                     </S.FormDesc>
                                                            
                     <S.Title>Los padres son responsables de la educación de sus hijos</S.Title>
                     <S.FormDesc>
                     Los padres actualmente trabajan fuera del hogar y los niños son educados por niñeras, mucamas, escuela, televisión, internet, etc.
                     Estos nuevos elementos se convirtieron en los responsables del desarrollo físico, psíquico y moral de los hijos de la pareja.
                     La ausencia de los padres se compensaba con el consumo material de todo tipo de cosas inútiles, como juguetes, videojuegos, ropa de diseño, etc.
                     Esto produce personas tóxicas, débiles y sin altos valores morales.
                     Los padres son responsables de la educación de sus hijos. <br></br><br></br>
                     <b><u>Esta tarea y obligación no puede ser transferida a nadie más</u></b>.
                     </S.FormDesc>

                     <S.Title>Descubre y estudia cómo educar a tus hijos</S.Title>
                     <S.FormDesc>
                     Los niños no nacen con un manual de instrucciones.
                     Educar es un arte que necesita ser perfeccionado constantemente.
                     Afortunadamente, hay varios materiales disponibles para este propósito.
                     Siempre trata de mejorar en este tema.
                     Principalmente, estudiar cómo criar hijos de padres separados.
                     </S.FormDesc>
                    
                     <S.Title>Sea un modelo a seguir para sus hijos, conviértase en una mejor persona</S.Title>
                     <S.FormDesc>
                     Los niños significan un cambio de vida.
                     Los padres son responsables de la educación de sus hijos.
                     Serán imágenes de sus padres, copiando sus comportamientos, acciones y actitudes.
                     Por eso, para una buena educación, los padres deben ser consecuentes en lo que dicen y hacen.
                     Cuando los niños experimentan que los padres mienten, son groseros, desorganizados, etc. terminan reproduciendo esas mismas acciones inconscientemente.
                     Por tanto, los padres deben minimizar o eliminar sus defectos y potenciar sus cualidades para que puedan ser transmitidos a sus hijos.
                     Sea un modelo a seguir para sus hijos.
                     Se lo que quieras que sean.
                     </S.FormDesc>

                     <S.Title>No utilices a tus hijos para hacer mandados</S.Title>
                     <S.FormDesc>
                     Muchas parejas con hijos no quieren hablar después del divorcio.
                     Algunos usan a sus hijos como mensajeros entre ellos.
                     No ponga a sus hijos a realizar esta tarea.
                     No deberían pasar por ese tipo de vergüenza o presión.
                     Principalmente, no deben estar al tanto de algunos temas que se puedan estar comunicando, ni deben ser testigos de las reacciones de quienes reciben el mensaje.
                     Los niños deben ser niños y los padres deben ser adultos.
                     </S.FormDesc>
                                        
                     <S.Title>No confíes en nadie</S.Title>
                     <S.FormDesc>
                     Actualmente, muchas personas tienen valores tergiversados ​​e inconsistentes.
                     Algunos son analfabetos funcionales y no pueden interpretar un texto simple.
                     Muchos viven al borde de la cordura y la locura.
                     Están entre nosotros y pueden ser médicos, abogados, cajeros de banco, maestros, dependientes de supermercado, policías, etc.
                     Nadie más que los padres es responsable de la seguridad y el bienestar de sus hijos. <br></br><br></br>
                     <b><u>Ten cuidado con las personas e instituciones que forman parte de la vida de tus hijos</u></b>. <br></br><br></br>
                     Siempre sea consciente.
                     No confíes en nadie, ni siquiera en profesionales de renombre.
                     Nunca dejes a tus hijos solos con ninguno de ellos.
                     Por ejemplo, si su hijo necesita una operación, exija estar en el quirófano con él.
                     </S.FormDesc>
                                        
                     <S.Title>No abandones a tus hijos</S.Title>
                     <S.FormDesc>
                     Muchos divorcios son destructivos.
                     Generalmente, quienes pierden la custodia de sus hijos terminan sufriendo un trauma económico y personal.
                     El responsable de la custodia también puede dificultarte la convivencia con él y crearte problemas de todo tipo.
                     Debido a esto, muchos buscan nuevas relaciones para minimizar sus frustraciones.
                     Terminan generando nuevas familias e hijos en esta acción.
                     Como resultado, la mayor parte de su atención y esfuerzo ahora se dirige hacia su nueva vida.
                     Entonces, los hijos de su relación anterior terminan siendo relegados a un segundo plano o incluso olvidados. <br></br><br></br>
                     Toda persona tiene derecho a reconstruir su vida y crear nuevos lazos.
                     Sin embargo, los niños son responsabilidades para toda la vida y no sólo cuando las condiciones a su alrededor son beneficiosas y favorables.
                     No importan las dificultades o las injusticias sufridas, nunca abandonéis a vuestros hijos. <br></br><br></br>
                     <b><u>Muestra hasta la distancia que estás presente en sus vidas</u></b>.
                     </S.FormDesc>

                     <br></br> <br></br> <br></br>
                </S.CenterData>
                <S.SideRight>&nbsp;</S.SideRight>
            </S.ContainerData> 
            }           

            { (english) &&
            <S.ContainerData>
                <S.SideLeft>&nbsp;</S.SideLeft>
                <S.CenterData>
                <S.FormDesc>
                Children will be the most affected in a divorce process.  
                Many will grow up with no apparent problems, but all will develop some kind of emotional scar that will affect their future lives.<br></br><br></br> 
                <u><b>They are innocent victims and should not pay for disagreements or problems between their parents</b></u>. <br></br><br></br>
                Actions that must be followed to avoid damage to your children's education before and after divorce:
                </S.FormDesc>
            
                <S.Title>NEVER ask your children to lie</S.Title>
                <S.FormDesc>
                It is a cowardly and alienating act that will affect your child's psychic well-being.
                It is a way for one of the parents to hide some information from the other.
                This induces the child's brain into a state of progressive desensitization.
                Desensitization is making something <i>“normal”</i> due to too many repetitions.
                The formation of a dishonest personality will be one of the results of this vile practice.
                Omitting information is a silent lie and produces the same damage as mentioned. <br></br><br></br>
                If this is happening, look for the competent authorities to penalize those responsible as soon as possible.
                </S.FormDesc>

                <S.Title>Don't fight or badmouth someone in front of your children</S.Title>
                <S.FormDesc>
                Many couples continue to struggle after divorce.
                Especially when there are children resulting from the marriage.
                They end up discussing their problems in their presence or indirectly over the phone or in parallel conversations, where they declare their hurts or resentments that are heard and captured by them.
                Any issue between the parties must be discussed and resolved without the presence and knowledge of existing children.
                </S.FormDesc>
            
                <S.Title>Always speak the truth to your children</S.Title>
                <S.FormDesc>
                Don't talk about your personal or financial problems with your minor children.
                Mainly, don't say anything related to the divorce and its reasons.
                Always be sincere and speak the truth as much as possible.
                Only omit information or subjects that are not relevant to their age.
                Say you'll answer their questions when they're old enough to understand.
                </S.FormDesc>  
                      
                <S.Title>Be present in your children's lives</S.Title>
                <S.FormDesc>
                Many parents who do not have custody of their children end up moving away from them.
                They may not have financial conditions, time or be emotionally affected.
                Even their children may be alienated, resentful, or aggressive toward them.
                The custodial party can also make things difficult by creating problems of all kinds.
                You must <b>eliminate</b> all the obstacles that appear.
                For example:<br></br> <br></br>
                1-Do you have any information about your child's education? Go to school and demand to know. <br></br>
                2-Do you have any information about his health? Go to the responsible pediatrician and demand to know. <br></br>
                3-Does your child participate in an event and you were not invited? Go anyway. Can't enter? Be seen from a distance and show you were there. <br></br>
                4-Talk to your child whenever possible. There is no way to? Buy him a cell phone. It's not possible? Send a letter. <br></br> <br></br>
                No matter what humiliation, pain or difficulty you have to go through, be present in your child's life.
                Demonstrate that he can always count on you.
                </S.FormDesc>
            
                <S.Title>Don't pit your children against someone you don't like</S.Title>
                <S.FormDesc>
                This is called <b><u>parental alienation</u></b>.
                It is putting ideas, fears or anger in a child's mind about a certain person.
                This usually happens between separated parents or their close relatives.
                This is done directly or indirectly, for example: <br></br> <br></br>
                1-Talking badly about the person all the time in front of the child. <br></br>
                2-Blaming the person for something that happened. <br></br>
                3-Make the child wait for something, but knowing that the person will not be able to do it. <br></br> <br></br>
                This criminal action causes serious damage to the child with long-term effects, most often <b>irreversible</b>.
                Some symptoms can be identified in the child victim of this situation, such as: anxiety, nervousness, aggressiveness, depression, identity disorder, disorganization, isolation, insecurity, learning difficulties, feelings of guilt, despair, and several other destructive feelings.
                </S.FormDesc>

                <S.Title>Don't let your children turn against you</S.Title>
                <S.FormDesc>
                Never do parental alienation on your children.
                Don't let them do this to you.
                If it is happening, gather all the necessary evidence and look for the competent authorities to penalize those responsible as soon as possible.
                Do whatever is necessary to have your rights respected and your children free of this violence.
                Activate the media, police, court, etc.
                Never be silent.
                </S.FormDesc>
            
                <S.Title>Do not seduce your children with gifts or money</S.Title>
                <S.FormDesc>
                It is one of the greatest evils of the present day.
                Parents do not know how to educate their children.
                Because of this, they use tricks to keep them under control.
                This problem is compounded by separated parents.
                They compete for their children's attention and love.
                Generally, the more affluent part uses its resources to humiliate and belittle the less favored part.
                This is all highly harmful.
                They will be brought up to believe that having is better than being.
                Seduce your children with moments of joy, with things money can't buy.
                </S.FormDesc>

                <S.Title>Play with your children</S.Title>
                <S.FormDesc>
                Invest some of your time to play with your children.
                Playing is not wasting time.
                Playing with them will yield many benefits.
                It is a precious moment to be with, to know and to educate your children.
                In games there are always situations that must be resolved.
                This will help your kids solve problems in the real world.
                In addition to numerous other advantages.
                They will never forget the good times they shared with you.
                </S.FormDesc>
            
                <S.Title>Make the moments you have with your children special</S.Title>
                <S.FormDesc>
                If you do not have custody of your children and your time with them is limited.
                Turn every moment you have with them into something special and unforgettable.
                Be their best friend.
                Don't waste your time with hurt feelings or resentments.
                Your children will grow up and these moments will be remembered by them.
                </S.FormDesc>
            
                <S.Title>Record all the moments with your children</S.Title>
                <S.FormDesc>
                Write down and record every moment with your children.
                To remember and enjoy them in the future.
                Today technology provides free means to carry out this operation.
                These records can also be very important, especially as a defense against any lawsuits you may receive.
                </S.FormDesc>
                                                    
                <S.Title>Parents are responsible for the education of their children</S.Title>
                <S.FormDesc>
                Currently, parents work outside the home and children are educated by nannies, maids, school, television, internet and so on.
                These new elements became responsible for the physical, psychic and moral development of the couple's children.
                The absence of parents was compensated with material consumption of all sorts of useless things, such as toys, video games, designer clothes, and so on.
                This produces toxic, weak people without high moral values.
                Parents are responsible for the education of their children. <br></br><br></br>
                <b><u>This task and obligation cannot be transferred to anyone else</u></b>.
                </S.FormDesc>

                <S.Title>Find out and study how to educate your children</S.Title>
                <S.FormDesc>
                Children are not born with an instruction manual.
                Educating is an art that needs to be constantly refined.
                Fortunately, there are several materials available for this purpose.
                Always try to improve on this subject.
                Mainly, study how to raise children of separated parents.
                </S.FormDesc>
            
                <S.Title>Be a role model for your children, become a better person</S.Title>
                <S.FormDesc>
                Children mean life change.
                Parents are responsible for their children's education.
                They will be images of their parents, copying their behaviors, actions and attitudes.
                Therefore, for a good education, parents must be consistent in what they say and do.
                When children experience parents lying, being rude, disorganized and so on. They end up reproducing those same actions unconsciously.
                Therefore, parents must minimize or eliminate their defects and enhance their qualities so that they can be passed on to their children.
                Be a role model for your children.
                Be what you want them to be.
                </S.FormDesc>

                <S.Title>Don't use your children to run errands</S.Title>
                <S.FormDesc>
                Many couples with children do not want to talk after divorce.
                Some use their children as messengers between them.
                Do not put your children to perform this task.
                They shouldn't be put through that kind of embarrassment or pressure.
                Mainly, they must not be aware of some issues that may be being communicated, nor must they witness the reactions of those who receive the message.
                Children should be children and parents should be adults.
                </S.FormDesc>
                                
                <S.Title>Do not trust anyone</S.Title>
                <S.FormDesc>
                Currently, many people have misrepresented and inconsistent values.
                Some are functionally illiterate and cannot interpret a simple text.
                Many live on the borderline of sanity and madness.
                They are among us and can be doctors, lawyers, bank tellers, teachers, supermarket attendants, police officers, and so on.
                No one but parents is responsible for the safety and well-being of their children. <br></br><br></br>
                <b><u>Be careful with the people and institutions that are part of your children's lives</u></b>. <br></br><br></br>
                Always be aware.
                Trust no one, not even renowned professionals.
                Never leave your children alone with any of them.
                For example, if your child has to be operated on, demand that you be in the operating room with him.
                </S.FormDesc>
                                
                <S.Title>Do not abandon your children</S.Title>
                <S.FormDesc>
                Many divorces are destructive.
                Generally, those who lose custody of their children end up suffering financial and personal trauma.
                The party responsible for custody can also make it difficult for you to live with them and create problems of all kinds.
                Because of this, many look for new relationships to minimize their frustrations.
                They end up generating new families and children in this action.
                As a result, most of your attention and effort is now directed towards your new life.
                So, the children of your previous relationship end up being put on the back burner or even forgotten. <br></br><br></br>
                Everyone has the right to rebuild their lives and create new bonds.
                However, children are <b>lifetime</b> responsibilities and not just when conditions around them are beneficial and favorable.
                No matter the difficulties or injustices suffered, never abandon your children. <br></br><br></br>
                <b><u>Show even the distance that you are present in their lives</u></b>.
                </S.FormDesc>
                
                <br></br> <br></br> <br></br>
                </S.CenterData>
                <S.SideRight>&nbsp;</S.SideRight>
            </S.ContainerData> 
            }
        </S.Container>
    )
}

export default Statistic;