import React                from 'react';
import * as S               from '../../LifePlan/Home/styles';
import * as GV              from '../../../global/globalVariables';
import { i18n }             from '../../../translate/i18n';
import Others               from '../../../components/OthersApps';

function OthersLifePlan() {

    return ( 
        <>
        <S.DescOption>{i18n.t('guide.menu5Desc1')}</S.DescOption>

        <Others appName={GV.APP_DIVORCE_GUIDE}></Others>  
        <Others appName={GV.APP_DIVORCE_PLANNING}></Others>  
        <Others appName={GV.APP_PERSONAL_DIARY}></Others>  
        <Others appName={GV.APP_PASSWORD_WALLET}></Others>  
        </>
     );
}

export default OthersLifePlan;