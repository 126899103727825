
import React, {useEffect, useState }  from 'react';   
import * as S                         from './styles';
import guide                          from '../../../../view/DivorceGuide/Guide/Images/guide.png'
import util                           from '../../../../Utilities/Util';

function GuideIntroduction() {
    
    const [access, setAccess] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)
        setAccess( util.checkGuideAccess() );
    }, [])

    return (
        <S.Container>
        { ( access )
            ?
            <>
            <S.Form> 
           
            <S.FormTitle>
                <span>INTRODUÇÃO</span><br></br><br></br>
            </S.FormTitle>

            <S.FormText>
                <span>

                A justiça é um conceito abstrato que possui componentes e representantes inseridos na sociedade que buscam torná-la algo real e aplicável no mundo onde vivemos. Para que isso seja possível, diversos elementos são construídos em nossa realidade física nos dando uma referência palpável de sua existência e assim nos fazendo acreditar que ela de fato exista. <br></br><br></br>
                Prédios são erguidos, regulamentos são criados, normas e procedimentos são formulados, pessoas são selecionadas, nomeadas e treinadas em locais criados para realizar essa tarefa, e diversos outros componentes secundários que seguem esse mesmo roteiro são montados ao seu redor para proteger e manter seu funcionamento. <br></br><br></br>
                A partir de sua fundação no mundo real, essa instituição passa a fazer parte da sociedade onde está inserida, ditando suas normas e deveres. Os novos indivíduos que nascerem após esse acontecimento, irão automaticamente aceitar essas instituições como algo normal, presente e necessário. Acreditando que foram elaboradas para solucionar e corrigir os problemas existentes com o objetivo de ajudar as pessoas a resolver seus conflitos e produzir o equilíbrio entre suas ações, desejos e direitos.<br></br><br></br>
                Contudo, ela é apenas representada na realidade e jamais poderá ser exercida plenamente, existindo apenas como um ideal ou uma meta a ser alcançada, pois nenhum homem ou instituição tem a capacidade de produzir a real justiça.<br></br><br></br> 
                Atualmente, ela é representada pelo poder do estado que se encarrega de aplicar as leis. <br></br><br></br>
                É um sistema complexo e de grandes proporções que necessita de grandes recursos humanos e financeiros para o seu funcionamento. Porém, os resultados oferecidos a sociedade são ínfimos e altamente questionáveis. Mesmo dispondo de uma receita financeira generosa, tecnologia de ponta, pessoal treinado e qualificado, esse sistema não consegue oferecer um serviço de qualidade, nem tampouco produzir a justiça desejada.<br></br><br></br> 
                Os números da violência e da criminalidade não param de crescer, sobrecarregando o sistema judiciário com novos processos. Obviamente, movidos pelas vítimas dessa ineficiência.  É um sistema que gasta muito e produz pouco. Além disso, anos podem ser gastos até que um resultado real seja produzido. Pessoas tem suas vidas destruídas ou paralisadas a espera dele, muitas vezes de valor inócuo ou insignificante devido ao tempo decorrido. Isso favorece os criminosos e prejudica ainda mais suas vítimas que não conseguem a almejada justiça para ressarcir ou minimizar suas perdas e sofrimento. <br></br><br></br>
                Esses resultados além de serem demorados também são altamente questionáveis, por duas razões: A primeira, são as leis existentes. Muitas são ultrapassadas, ilógicas, injustas e conflitantes, dificultando demasiadamente sua interpretação e aplicabilidade. A segunda razão está relacionada com a quantidade de processos. O volume de novos processos que chegam diariamente e dos que estão pendentes de resolução, tornam sua diligência impossível. Mesmo liberando um processo por hora, a velocidade ainda seria insuficiente, mas é necessário afirmar que isso seria inválido e altamente prejudicial. <br></br><br></br>
                Eles não podem ser despachados a toque de caixa. Os processos são todos compostos por textos descritivos de difícil entendimento e documentos anexados a eles. Em teoria, tudo têm que ser meticulosamente estudado e avaliado para se chegar a um parecer justo e satisfatório para os envolvidos. <br></br><br></br>
                Contudo, a quantidade de documentos a serem processados e analisados não tem fim, tornando essa tarefa praticamente impossível, até mesmo para profissionais excepcionais. Um texto mal interpretado, uma palavra má compreendida, até mesmo a omissão de uma vírgula, podem mudar todo o significado de uma acusação ou defesa, produzindo resultados desastrosos. <br></br><br></br>
                Portanto, a justiça não pode se tornar uma fábrica com uma linha de produção orientada por metas ou demandas. Precisa ter consciência e zelo em suas decisões. Afinal, vidas dependem disso e são afetadas por ela. <br></br><br></br>
                Infelizmente, os problemas, distorções e as limitações descritas produzem apenas uma coisa:<br></br><br></br><br></br>
                <center><b>I N J U S T I Ç A</b></center><br></br><br></br>
                O sistema judiciário não funciona e provalvemente jamais irá funcionar. A quantidade de falhas e desajustes não podem mais ser corrigidas, e qualquer tentativa de solução apenas produz frustração, gastos ou mais problemas. Além disso, parece não haver interesses concretos na resolução dos problemas existentes, mesmo daqueles mais simples, pois esse sistema também se tornou uma ferramenta de grande poder e gerador de muito dinheiro para as pessoas desonestas, inescrupulosas e até mesmo malignas que estão integradas direta ou indiretamente a ele. <br></br><br></br>
                Quanto mais problemas, crimes e desajustes houverem em nossa sociedade, mais esse sistema será procurado na tentativa de solucioná-los, promovendo com isso um círculo perpétuo sem solução. Por consequência, gerando mais poder e riqueza para aqueles que manipulam e são favorecidos por ele. <br></br><br></br>
                É necessário conhecer esse terrível cenário no qual estamos inseridos para poder fazer alguma coisa em nossa defesa. Não podemos mais manter nossos olhos fechados e continuar acreditando e mantendo instituições que não funcionam e beneficiam apenas aqueles de seu interesse.

                <br></br><br></br><br></br><br></br>
                <h2>O Guia</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                Esse documento deve ser utilizado como uma bússola que mostra o caminho em um ambiente desconhecido e nebuloso para a maioria das pessoas, principalmente para os homens. Muitos deles ainda acreditam nas instituições e na sua recuperação, e por isso são facilmente enganados e aniquilados por elas. Acreditam na igualdade, nas leis e no bom senso dos profissionais envolvidos. Já faz muito tempo que esses atributos não existem na justiça e esse desiquilíbrio não para de crescer. <br></br><br></br>
                Os homens são os maiores perdedores, o máximo que podem conseguir é apenas diminuir seus prejuízos, o que já será uma grande vitória. Eles são vistos e tratados apenas como fornecedores.<br></br><br></br>
                Todos os procedimentos e decisões realizadas serão na verdade apenas uma grande representação. Os resultados já estarão prontos, mas isso não poderá ser demonstrado abertamente, então será necessário montar um teatro para mascarar esse fato, e serão os homens que pagarão por todo o custo dessa encenação. <br></br><br></br>
                Na verdade, quando houver caráter e bom senso entre os cônjuges em um processo de divórcio, tudo tende a ocorrer de forma tranquila e satisfatória, e a justiça dará sinais que funciona e não irá demonstrar a sua verdadeira face. Mas quando isso não acontecer, os homens serão julgados e condenados de acordo com a ideia de que as mulheres são vítimas, e homens vilões. Não existem leis que favorecem os homens, mas existem diversas que favorecem apenas as mulheres, e novas leis não param de ser criadas e aprovadas.  <br></br><br></br>
                Os homens não são julgados com justiça ou bom senso, e sempre terão que pagar as contas, tendo condições ou não de realizar essa tarefa. Caso não possam ou se recusem, serão presos, condenados e destruídos, independentemente de qual seja o cenário político e social do momento. <br></br><br></br>
                Atualmente, não ter emprego ou dinheiro é um crime hediondo e deverá ser tratado com extremo rigor. Infelizmente, essa é a realidade e os profissionais envolvidos sabem disso. Por essa razão tudo já está pronto e relacionado, apenas seus dados serão modificados nos documentos que serão utilizados. As audiências e os resultados serão os mesmos, apenas em poucos casos algo ocorrerá diferente.<br></br><br></br><br></br>
                <center><b><i>As leis são rígidas e foram desenvolvidas para derrotar os homens.</i></b></center> <br></br><br></br>
                Muitos homens ainda discordam desse cenário e acreditam na justiça, principalmente porque conhecem pessoas que realizaram o divórcio sem qualquer problema. Inclusive, alguns já realizaram esse procedimento mais de uma vez e nada de ruim ou desastroso aconteceu, mesmo havendo filhos na relação. <br></br><br></br>
                Cada caso é único e não significa que será desastroso ou tranquilo para todos. Sempre dependerá das circunstâncias e principalmente dos valores morais dos envolvidos. Mas se o pior acontecer será realmente algo aterrorizante e destrutivo. Ninguém sabe como os outros irão reagir em situações de conflito. Pessoas que carregamos em nossos braços e nas quais investimos nossa vida, podem sem qualquer explicação se tornarem terríveis e destrutivos algozes. Existem inúmeros casos na história humana que confirmam isso, além de crimes, injustiças e barbarias de todo tipo que acontecem diariamente. <br></br><br></br>
                A grande maioria dos homens não aceita essa realidade preferindo acreditar que tudo isso são apenas casos infelizes e circunstanciais, que são falhas da justiça que eventualmente poderão ser corrigidas. Acreditam que com eles tudo irá ocorrer de forma rápida e tranquila, que não se deve perder tempo nem dinheiro com qualquer tipo de preparação.<br></br><br></br> 
                No final, será um engano fatal para aqueles que agirem e pensarem dessa forma.  <br></br><br></br>
                Portanto, esse guia mostra essa realidade e os elementos envolvidos nessa operação, demonstra as leis e os procedimentos existentes, e fornece algumas soluções que devem ser seguidas para evitar ou reduzir as perdas pessoais e financeiras produzidas pelo divórcio. 
                <br></br><br></br><br></br><br></br>
                <i><b>O CONTEÚDO DESSE GUIA É BASEADO EM OPINIÃO PESSOAL ADQUIRIDA DE FORMA EMPÍRICA OU OBTIDA ATRAVÉS DE DOCUMENTOS PÚBLICOS E GOVERNAMENTAIS DISPONIBILIZADOS NOS MEIOS DE COMUNICAÇÃO EXISTENTES.
                <br></br><br></br>NÃO GARANTIDO QUE A INFORMAÇÃO ACESSÍVEL NESTE DOCUMENTO É PRECISA, COMPLETA OU ESTEJA ATUALIZADA.
                <br></br><br></br>SENDO DE RESPONSABILIDADE DO LEITOR SEGUIR OU NÃO SUAS SUGESTÕES, CONSELHOS E OPINIÕES, DEVENDO ESTE <u>SEMPRE</u> PROCURAR PROFISSIONAL AUTORIZADO E ESPECIALIZADO PARA SOLUCIONAR SUAS DÚVIDAS E PROBLEMAS DE FORMA LEGAL E SATISFATÓRIA.</b></i>
                <br></br><br></br><br></br>

                </span>
            </S.FormText>

            </S.Form>
            </>
            :
            <>
            <br></br>
            <br></br>
            <h3>&nbsp;</h3>
            <br></br>
            </>    }  
        </S.Container>
    )
}

export default GuideIntroduction;