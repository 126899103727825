
import React, { useEffect, useState } from 'react';   
import * as S       from './styles';
import guide29      from '../../../../view/DivorceGuide/Guide/Images/guide29.png';
import guide30      from '../../../../view/DivorceGuide/Guide/Images/guide30.png';
import guide31      from '../../../../view/DivorceGuide/Guide/Images/guide31.png';
import war1         from '../../../../view/DivorceGuide/Guide/Images/war1.png';
import war2         from '../../../../view/DivorceGuide/Guide/Images/war2.png';
import war3         from '../../../../view/DivorceGuide/Guide/Images/war3.png';
import war4         from '../../../../view/DivorceGuide/Guide/Images/war4.png';
import util         from '../../../../Utilities/Util';

function GuidePart6() {
    
    const [access, setAccess] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)
        setAccess( util.checkGuideAccess() );
    }, [])

    return (
        <S.Container>
        { ( access )
            ?
            <>
            <S.Form> 

            <S.FormTitle>
                <span>PARTE6</span><br></br><br></br><br></br><span>Lutar e Defender</span><br></br><br></br>
            </S.FormTitle>

            <S.FormText>
                <span>

                A maioria das pessoas vive adormecida. A realidade não é perceptível para a maioria delas. Elas gastam grande parte do seu tempo trabalhando e tentando sobreviver. Não há como parar com esse ritual, sempre existe um problema ou um desafio que precisa ser resolvido ou superado. O ritmo de vida é frenético e exigente, com poucos momentos de descanso. Geralmente, gastos com coisas inúteis e sem valor. <br></br><br></br>
                Então, fica difícil ou quase impossível analisar e perceber o que está acontecendo. Apenas em momentos de dor ou sofrimento é que reparamos tudo de errado e terrível que está acontecendo ao nosso redor. Infelizmente já será tarde demais e qualquer reação será praticamente impossível. <br></br><br></br>
                Há coisas terríveis sendo realizadas contra nós, mas estamos presos em nossos rituais ou problemas que não temos interesse nem disposição em conhecê-las. É mais cômodo e tranquilo permanecer na mentira e na ignorância. E por causa disso, o mal e a injustiça sempre acabam vencendo. <br></br><br></br>
                A sociedade e as instituições estão falidas. Não podemos contar com elas quando precisarmos de assistência ou socorro. <br></br><br></br>
                A ignorância e a injustiça já atingiram níveis catastróficos e não param de crescer. Ficar adormecido não é mais uma opção é suicídio. Querendo ou não será atingido, mesmo que fique em sua zona de conforto e acredite estar protegido. <br></br><br></br>
                O divórcio e as leis sexistas são um exemplo desse mal. Mesmo que não tenha filhos ou não seja casado, essa insanidade e injustiça poderá atingi-lo a qualquer momento.  <br></br><br></br>
                <i><b>É necessário despertar para essa realidade, lutar e se defender dela</b></i>. 
                
                <br></br><br></br><br></br>
                <h2>Jogo do Divórcio</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide29} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                O divórcio possui um conjunto de passos a serem seguidos que definem seu futuro e resultado. Pode ser comparado a um jogo de tabuleiro, onde à posição ocupada pelo jogador resulta em algo positivo ou negativo na partida que está sendo realizada. 
                É um jogo que pode ser definido pela estratégia, sorte ou por uma mistura de ambos. <br></br><br></br>
                Contudo, não é um jogo imparcial regido apenas por esses atributos. Ele é desenhado e elaborado para que apenas um dos lados seja vencedor. Ser homem e conseguir vencer esse jogo é algo praticamente impossível. 
                É necessário ter muita sorte e ser um estrategista excepcional. <br></br><br></br>
                <i><b>Querer jogar esse jogo sem ter uma estratégia definida e muito bem elaborada, é um convite certo para a derrota pessoal e financeira.</b></i> <br></br><br></br>
                Muitos acreditam nas instituições que gerenciam esse jogo são imparciais e jamais permitiriam trapaças. Terrível e desastroso engano. Um deles sempre será favorecido, enquanto o outro sempre será prejudicado. <br></br><br></br>
                Além da sorte e da estratégia, <i>o caráter</i> do jogador mais favorecido poderá definir o resultado positivo desse jogo. <br></br><br></br>
                Contudo, como um deles sempre está em vantagem, dificilmente encontraremos alguém com espirito esportivo que disputará a partida amigavelmente, desejando apenas o melhor para todos. <br></br><br></br>
                Portanto, não devemos nos iludir com essas instituições nem com a boa vontade do oponente. Dessa forma, é primordial conhecer o fluxo desse jogo para entender seu funcionamento e saber como jogá-lo.

                <br></br><br></br><br></br>
                <h2>Guerra Assimétrica</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide30} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>
               
                Esse tipo de guerra descreve um confronto entre oponentes que apresentam diversas diferenças entre si, como: poder militar, recursos financeiros, organização, objetivos, obediência as regras, e etc. Em geral, representa um conflito entre uma grande nação e outra de tamanho bem inferior. <br></br><br></br>
                O objetivo da guerra assimétrica é o mesmo da guerra convencional, o de exaurir o inimigo, impor sua vontade sobre ele, desgastá-lo, enfraquecê-lo e imobilizá-lo. Inclusive, a imobilização do adversário sempre significa em uma guerra, o começo da vitória. <br></br><br></br>
                Atualmente, o divórcio se tornou uma guerra assimétrica, onde as leis existentes e o sistema de apoio fornecem a um dos lados dessa disputa, <u><b>UM PODER DE FOGO PRATICAMENTE INVENCÍVEL E INSUPERÁVEL</b></u>. <br></br><br></br>
                Anteriormente comparamos o divórcio a um jogo, onde um dos oponentes possui todo tipo de vantagens enquanto o outro apenas desvantagens. Toda guerra também  é um jogo com a função de derrotar seu adversário e deixá-lo sem capacidade de reação. Essas características estão presentes nos atuais processos de divórcio. <br></br><br></br>
                Os derrotados nesses processos são violados em seus direitos, feridos, mutilados, escravizados e deixados sem possibilidade de reação, sendo eternamente subordinados ao lado vitorioso do conflito. E no final, ainda produz vítimas inocentes (filhos), sequelas e a destruição massiva dos recursos pessoais e financeiros que existiam antes da disputa. <br></br><br></br>
                É inegável a semelhança de um conflito entre nações e o que acontece atualmente com os matrimônios que terminam em divórcio. Ambos produzem destruição e resultados devastadores. Onde o importante é apenas vencer sob qualquer circunstância e adquirir o maior número de benefícios possível. Não importando as consequências futuras e o rastro de destruição deixado pelo caminho.

                <br></br><br></br><br></br>
                <S.FormChat><img src={war1} alt=""></img><span>Figura 7 - Desiquilíbrio de Forças</span><br></br>
                <span>O desiquilíbrio de forças é evidente nos processos de divórcio atuais. </span>
                </S.FormChat>
                <br></br><br></br><br></br>

                <b>PRINCÍPIOS DA GUERRA</b>
                <br></br><br></br>
                A semelhança é tão grande entre um divórcio e um conflito armado entre nações que é possível utilizar as mesmas técnicas e princípios de guerra adotados pela maioria das forças militares do mundo atual.
                <br></br><br></br>

                <table border="1" width="100%">
                <tbody>
                <tr><td bgcolor='#C2E3B4' ><b>OBJETIVO</b></td><td width="70%">O objetivo é uma meta ou alvo que se pretende atingir e que determina a direção do esforço de toda a atividade militar. <br></br>Deve ser factível e exequível.</td></tr>
                <tr><td bgcolor='#C2E3B4' ><b>OFENSIVA</b></td><td>Ofensiva significa ter iniciativa. Ela é necessária para se obterem resultados decisivos e para conservar a liberdade de ação. <br></br>Tomar a iniciativa e impor a sua vontade ao inimigo.</td></tr>
                <tr><td bgcolor='#C2E3B4' ><b>MASSA</b></td><td>É concentrar o potencial de combate no local e hora decisivos. A aplicação correta desse princípio pode permitir que forças numericamente inferiores obtenham êxito e vitória em um momento crucial da batalha.</td></tr>
                <tr><td bgcolor='#C2E3B4' ><b>SEGURANÇA</b></td><td>É não permitir que o inimigo adquira uma vantagem inesperada. A segurança é essencial para a conservação do potencial de combate. É negar ao inimigo informações sobre os planos e as ações da força. É evitar ataques surpresa e atos de sabotagem.</td></tr>
                <tr><td bgcolor='#C2E3B4' ><b>ECONOMIA DE FORÇAS</b></td><td>É um corolário ao princípio de massa. É empregar cuidadosamente o potencial de combate para permitir cumprir a missão com um mínimo de desgaste dos meios disponíveis.  É utilizar apenas o essencial de recursos nas ações secundárias que devem ser realizadas.</td></tr>
                <tr><td bgcolor='#C2E3B4' ><b>MANOBRA</b></td><td>É colocar as forças de forma a que o inimigo fique em situação de desvantagem. É obter uma posição vantajosa que permita atingir o objetivo de forma a exigir o menor dispêndio possível de recursos.</td></tr>
                <tr><td bgcolor='#C2E3B4' ><b>UNIDADE DE COMANDO</b></td><td>É colocar as forças disponíveis sob uma linha de comando. A aplicação de todo o potencial disponível exige uma grande convergência de esforços, através da ação coordenada de todas as forças existentes. Portanto, é fundamental que não haja dispersão de energia e responsabilidades. </td></tr>
                <tr><td bgcolor='#C2E3B4' ><b>SURPRESA</b></td><td>Significa atingir o inimigo de forma inesperada. Não é essencial apanhar o inimigo completamente desprevenido, mas basta que ele não tenha tempo suficiente para reagir com eficácia.</td></tr>
                <tr><td bgcolor='#C2E3B4' ><b>SIMPLICIDADE</b></td><td>Os planos devem ser elaborados, preparados e executados com à máxima clareza e compreensão. Para reduzir ao máximo a margem de erro, deve-se ter especial cuidado na forma como as ordens são distribuídas, elas devem ser claras e não devem deixar dúvidas quanto à sua execução.</td></tr>
                </tbody>
                </table>

                <br></br><br></br><br></br>
                <b>PRINCÍPIOS DA GUERRA NO DIVÓRCIO</b>  
                <br></br><br></br>
                O cenário existente no divórcio, descrito anteriormente em linguagem militar, comprova sua similaridade com uma luta armada entre nações inimigas. Portanto, podemos utilizar os princípios de guerra adotados pelas forças militares atuais ao litígio do matrimônio, e obter uma melhor compreensão e alguma vantagem nessa disputa.
                <br></br><br></br><br></br>

                <table border="1">
                <tbody>
                <tr><td bgcolor='#C2E3B4' ><b>OBJETIVO</b></td><td width="70%">O objetivo será sempre o divórcio. <br></br>Poderá ser realizado de forma consensual ou litigiosa. Tentar realizá-lo de forma diplomática é mais aconselhável do que através da luta armada, mesmo sendo através de um acordo não muito vantajoso, uma vez que o poder de fogo dado as mulheres são infinitamente superiores ao dos homens. Caso isso não seja possível, todos os preparativos necessários deverão ser realizados para tornar a disputa favorável e equilibrada para o seu lado.</td></tr>
                <tr><td bgcolor='#C2E3B4' ><b>OFENSIVA</b></td><td>É fundamental que o processo de divórcio seja solicitado pelo homem. Não apenas este, mas todos os demais que poderão vir a existir. A parte oponente não pode ter a oportunidade de atacar, deve ser sempre mantida na defensiva.</td></tr>
                <tr><td bgcolor='#C2E3B4' ><b>MASSA</b></td><td>Todos os processos devem ser bem elaborados com objetivos claros e realistas. Não se deve perder tempo e dinheiro em processos sem relevância ou que não ofereçam algum tipo de vantagem. Concentre seus recursos apenas naqueles com alguma chance plausível de vitória e que traga algum benefício pessoal ou financeiro. </td></tr>
                <tr><td bgcolor='#C2E3B4' ><b>SEGURANÇA</b></td><td>O divórcio deverá ser um projeto elaborado com o máximo de sigilo possível. Perder o fator surpresa ou permitir que o inimigo conheça seus planos e poder de fogo é fatal para quem deseja à vitória. Toda sua vida passada será vasculhada pela parte oponente. Qualquer informação mesmo que pareça irrelevante poderá ser usada como arma e tomar proporções inimagináveis no ambiente jurídico. Não realize nenhuma conversa por email, mídias sociais, celular ou qualquer outro meio de comunicação que possa ser rastreado ou grampeado. Não brigue ou fale coisas impensadas, poderá estar sendo filmado, gravado ou vigiado por pessoas dispostas a testemunhar contra você. Mude a senha de todos os seus equipamentos e mantenha todos os arquivos protegidos. Não confie nem troque informações com seus amigos, parentes ou qualquer pessoa. </td></tr>
                <tr><td bgcolor='#C2E3B4' ><b>ECONOMIA DE FORÇAS</b></td><td>Um processo de divórcio litigioso pode ser demorado e muito dispendioso. É necessário possuir uma reserva financeira para poder realizá-lo com segurança. Sem isso fatalmente ficará sem recursos e será rapidamente derrotado. É imprescindível ter um planejamento antecipado e não realizar procedimentos desnecessários, baseados em orgulho, raiva ou vingança. Deverá  economizar suas forças e recursos o máximo que for possível. Seu principal objetivo será o de sair dessa situação com o mínimo de perda para poder reconstruir sua vida.  </td></tr>
                <tr><td bgcolor='#C2E3B4' ><b>MANOBRA</b></td><td>O local onde o divórcio será realizado é na vara de família da justiça civil. <br></br>É um terreno totalmente desfavorável aos homens. É necessário um bom planejamento, um conjunto de provas consistentes, e um ótimo advogado para tentar conseguir uma vitória nesse local. Todas manobras nele realizadas, devem ser muito bem calculadas. Contudo, mover seu oponente para um terreno onde ele tem mobilidade inferior, é uma forma de reduzir seu potencial de vitória. Deve-se criar processos secundários contra a parte oponente para desgastar seus recursos e levá-la a derrota. Esses processos serão julgados na justiça comum, onde as leis não são favoráveis apenas a um dos lados da disputa. Quebrando sua moral, hegemonia e certeza de vitória.</td></tr>
                <tr><td bgcolor='#C2E3B4' ><b>UNIDADE DE COMANDO</b></td><td>É necessário conhecer e confiar no general que irá comandar seu exército. Ele irá coordenar o ataque e a defesa de seu território. Essa é a função de um advogado em um processo de divórcio. Ele é o principal elemento na produção da vitória ou derrota. Para que haja ganho ou o menor prejuízo possível, é necessário selecionar um profissional altamente qualificado. Infelizmente, eles são difíceis de serem localizados e custam caro.  Não se deve colocar o destino de um divórcio nas mãos de qualquer um, principalmente em alguém cuja sua competência e atuação são desconhecidas. Atualmente, as leis são extremamente severas e fechadas para os homens. Muito pouco ou quase nada pode ser feito, tornando esses profissionais em despachantes de documentos jurídicos. 
                Por isso é importante localizar e contratar profissionais de renome no mercado. Apenas eles são capazes de encontrar ou elaborar soluções mirabolantes dentro desse ambiente fechado e estático. Deve-se fugir de profissionais inexperientes, baratos ou que fazem de tudo. Esse é um dos piores erros cometidos pelos homens, tentar economizar com a pessoa que irá defendê-los. Devem ter em mente que nenhum deles sofrerá qualquer consequência das ações tomadas, e sempre serão pagos, independentemente dos resultados obtidos. Sendo assim, não existe mérito na vitória, será apenas mais um processo. Ao contrário, daqueles que tem uma reputação e um nome a ser preservado. É certo que mesmo eles, não podem garantir uma vitória, mas podem com certeza, reduzir a destruição e o prejuízo causado por essa guerra.</td></tr>
                <tr><td bgcolor='#C2E3B4' ><b>SURPRESA</b></td><td>É um fator decisivo em uma guerra. <br></br>No caso do divórcio é uma das poucas armas disponíveis para os homens. É imprescindível que eles realizem o primeiro ataque. Sem essa iniciativa, é quase certo que a vitória não será possível. É necessário elaborar com antecedência um planejamento tático antes de inicializar o processo de divórcio. Contudo, se for constatado que o inimigo irá atacá-lo a qualquer momento, todo planejamento deverá ser momentaneamente esquecido. É fundamental efetuar o primeiro movimento desse processo, mesmo ainda não estando preparado. O elemento surpresa não  pode ser perdido sob nenhuma circunstância.</td></tr>
                <tr><td bgcolor='#C2E3B4' ><b>SIMPLICIDADE</b></td><td>A justiça utiliza um linguajar que dificulta seu entendimento pelas pessoas comum. É função do advogado traduzir e adaptar esse vocabulário para que sua compreensão seja possível, e não haja duvidas sobre o assunto debatido. É essencial que tudo que seja solicitado ou recebido seja documentado, entendido e confirmado. Não poderá haver movimentos desconhecidos e não autorizados, tanto do defensor como do contratante. Tudo deve ser claro e sem margem para dúvidas ou errôneas interpretações. E preferencialmente, todos os tópicos e decisões devem estar registradas em meios eletrônicos que possam ser consultados, confirmados e autorizados.</td></tr>
                </tbody>
                </table>

                <br></br><br></br><br></br>
                <b>CENÁRIO DA GUERRA</b>  

                <br></br><br></br>
                <S.FormChat><img src={war2} alt=""></img><span>Figura 8 - Dados de Guerra</span>
                <span>Dados de guerra adaptados para o divórcio.</span>
                </S.FormChat>
                <br></br><br></br><br></br>

                <table border="1" width="100%">
                <tbody>
                <tr><td bgcolor='#000000'  width="30%"><b><font color="white">TERRENO</font></b></td><td width="70%">É o local onde a batalha será travada. No caso do divórcio será na vara da família da justiça civil. É o pior local possível para homens. As chances de vitória nesse terreno são praticamente nulas. Para que a vitória ocorra é necessário mudar de terreno ou ter um planejamento estratégico poderoso que possibilite operar nele.</td></tr>
                <tr><td bgcolor='#000000' ><b><font color="white">VANTAGEM TÁTICA</font></b></td><td> As leis e os componentes da justiça estão a favor das mulheres. Isso é uma vantagem tática difícil de igualar ou superar. A única maneira de vencer ou diminuir essa vantagem é realizando o primeiro ataque contra o adversário.</td></tr>
                <tr><td bgcolor='#000000' ><b><font color="white">ESTRATÉGIA</font></b></td><td>As mulheres possuem poder de fogo insuperável, e geralmente realizam um ataque massivo e de surpresa contra seus oponentes. Os homens são passivos demais e tentam adiar o confronto ou resolvê-lo por meios diplomáticos. Por causa disso, são pegos de surpresa e destruídos ou imobilizados logo no primeiro ataque.  Ficando durante todo o conflito apenas se defendendo. O ataque é a melhor defesa.</td></tr>
                <tr><td bgcolor='#000000' ><b><font color="white">OFENSIVA</font></b></td><td>Os homens demoram demais para solicitar o divórcio. Acreditam que as coisas podem mudar e adiam as decisões para o último minuto possível. Por isso são pegos facilmente em ataques surpresa e de grande intensidade. Acabam sendo aniquilados facilmente no primeiro ataque. Para impedir que esse quadro se realize, devem ser os primeiros a solicitar o divórcio e realizar ofensivas frequentes durante o processo e assim desmoralizar e desgastar o inimigo que não espera por essa reação.</td></tr>
                <tr><td bgcolor='#000000' ><b><font color="white">ALIADOS</font></b></td><td>Vale a máxima <i>"Mulheres são vítimas, homens são vilões"</i>. Por causa disso, elas recebem todo apoio da sociedade, amigos, família e de todos que puder cativar com o vitimismo que irão criar e difundir. É necessário obter o máximo aliados possíveis antes do início do conflito, e juntar provas contundentes contra ela para modificar sua imagem. Parentes, amigos ou pessoas conhecidas podem não ser de confiança. É fundamental não revelar seus planos a ninguém. Pessoas a sua volta poderão ser traidores ou espiões.  </td></tr>
                </tbody>
                </table>

                <br></br><br></br><br></br>
                <S.FormChat><img src={war3} alt=""></img><span>Figura 9 - Armamentos utilizados</span>
                <span>Poder de fogo adaptado para o divórcio.</span>
                </S.FormChat>
                <br></br><br></br><br></br>

                <table border="1" width="100%">
                <tbody>
                <tr><td bgcolor='#000000'  width="30%"><font color="white"><b>NUCLEARES</b></font></td><td width="70%">O poder de destruição do inimigo é extremamente alto, equivalente ao das armas nucleares. Algumas leis são capazes de aniquilar com os homens sem à necessidade de provas ou testemunhas. É suficiente apenas a palavra da requerente.  É uma inversão do ônus da prova, é contra a constituição e os princípios da justiça, mas acontece com frequência e pode ser utilizada para vencer a disputa.  Uma denúncia de maus tratos ou violência doméstica são prontamente acatadas sem qualquer questionamento. Caberá ao acusado provar o contrário, mas será tarde demais. Terá sofrido todo tipo de abuso e constrangimento, carregando marcas e sequelas por toda vida, como os sobreviventes de ataques nucleares. 
                É necessário manter provas inequívocas sobre seu caráter para poder se defender. E novamente, a melhor defesa será ter realizado o primeiro ataque e não ficar esperando as coisas acontecerem.</td></tr>
                <tr><td bgcolor='#000000' ><b><font color="white">BIÓLOGICOS</font></b></td><td>Armamento disponível que possui o mesmo poder de destruição que as armas nucleares. A diferença encontra-se no fato de poder ser utilizada contra homens solteiros. A única defesa que existe é a prevenção.  Uma vez que a contaminação ocorra, terá que perder tempo e dinheiro em sua recuperação, que poderá vir acompanhada de diversas sequelas.</td></tr>
                <tr><td bgcolor='#000000' ><b><font color="white">EXÉRCITO</font></b></td><td>O exército inimigo é numericamente superior. Além de possuir armamento sofisticado expresso através das leis sexistas, também conta com o total apoio dos componentes da justiça. É necessário possuir uma estratégica superior para poder mitigar ou vencer esse exército superior. Não será possível confrontá-lo frente a frente. Ele terá que ser confundido e espalhado para que seu poder seja diminuído e possa ser combatido. É necessário conhecer muito bem as leis e suas artimanhas para realizar essa tarefa. Por isso à figura do advogado de defesa é importante e fundamental nessa batalha. </td></tr>
                <tr><td bgcolor='#000000' ><b><font color="white">PODER DE FOGO</font></b></td><td>O exército de defesa é numericamente inferior e encontra-se sucateado. Não existem leis que favorecem os homens. Cada vez mais seu poder de fogo e de mobilidade estão sendo limitados. O pouco que ainda resta deve ser aplicado com muita inteligência e destreza. Não faz sentido entrar em batalha sem condições de vitória. Infelizmente, é um caminho árduo e demorado. O inimigo tem que perder seu poder gradativamente. Tem que ser enganado e desgastado sistematicamente. A vitória será possível apenas no futuro, quando suas forças forem equivalentes ou inferiores. </td></tr>
                </tbody>
                </table>

                <br></br><br></br><br></br>
                <S.FormChat><img src={war4} alt=""></img><span>Figura 10 - Saldos de Guerra</span>
                <span>Prejuízos da guerra adaptados para o divórcio.</span>
                </S.FormChat>
                <br></br><br></br><br></br>

                <table border="1" width="100%">
                <tbody>
                <tr><td bgcolor='#000000'  width="30%"><b><font color="white">PATRIMÔNIO</font></b></td><td width="70%">Como na maioria das guerras, o patrimônio será destruído ou danificado. O divórcio é uma guerra onde o exército inimigo deseja saquear os recursos da nação invadida. A única forma de protege-lo e através da prevenção antecipada. As propriedades devem ser transferidas ou vendidas antes que a invasão ocorra, caso contrário grande parte dele será perdida. Não existe defesa contra esse fato. A guerra não pouca nada nem ninguém.</td></tr>
                <tr><td bgcolor='#000000' ><b><font color="white">FINANCEIRO</font></b></td><td>Guerras custam caro. Mantê-las em funcionamento podem levar as nações à falência financeira. Por isso os vencedores exigem pagamento de tributos dos perdedores. Serão eles que terão que pagar às custas da guerra perdida. Uma vez que forem derrotados não poderão escapar dessa obrigação, caso tentem serão punidos exemplarmente, para desencorajar qualquer insurreição que possa existir. A única maneira de evitar isso é vencer ou fazer um acordo de paz. 
                Um acordo ruim muitas vezes pode ser menos doloroso e prejudicial que uma guerra contra um inimigo poderoso e fortemente armado. As vezes é melhor viver para poder lutar mais tarde em condições mais favoráveis e factíveis de vitória.</td></tr>
                <tr><td bgcolor='#000000' ><b><font color="white">TRAUMAS</font></b></td><td>A guerra é algo tão terrível que ninguém pode vivencia-la sem no futuro portar algum tipo de trauma. Um divórcio é uma guerra que afeta as pessoas fisicamente e mentalmente. Muitos não conseguem suportar seus horrores e as obrigações impostas após o cessar fogo. Os homens não perdem apenas seus filhos e patrimônio, muitos perdem sua dignidade e respeito próprio. Sofrem sistemáticas humilhações e perseguições. 
                Seus direitos são totalmente ignorados e desrespeitados. Tornam-se eternos escravos e fornecedores. Serão rigorosamente penalizados caso não cumpram com suas obrigações, tendo ou não condições de realiza-las. Muitos adoecem ou comentem suicido por não encontrarem apoio ou solução para seus problemas. O divórcio é uma guerra capaz de produzir destruição e sequelas inimagináveis, mesmo depois de sua conclusão.</td></tr>
                <tr><td bgcolor='#000000' ><b><font color="white">VÍTIMAS</font></b></td><td>Não existe guerra limpa. Todas elas produzem vítimas de ambos os lados, inclusive de civis inocentes. Em uma guerra, famílias são desfeitas e mutiladas. Os filhos serão os maiores prejudicados em um divórcio. Muitos irão crescer sem problemas aparentes, mas todos irão desenvolver algum tipo de sequela emocional, como insegurança, raiva, depressão, que afetará suas vidas futuras. 
                Em um mundo injusto e competitivo estarão em desvantagem em relação aos seus concorrentes, portadores de estruturas emocionais mais sólidas provenientes de uma educação familiar consistente. Os homens são os maiores prejudicados nesse processo, raramente tem direito ao compartilhamento ou a guarda dos filhos. Além disso, 80% deles sofrem de alienação parental produzida pela parte vencedora da guerra.</td></tr>
                </tbody>
                </table>

                <br></br><br></br><br></br>
                <h2>Sala de Guerra</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide31} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                A sala de guerra é um local criado pelo primeiro ministro inglês durante a segunda guerra mundial para reuniões confidenciais e discutir estratégias militares. <br></br><br></br>
                Atualmente, esse conceito foi adotado pelas empresas com o objetivo de realizar reuniões de alinhamento com o seu pessoal e definir metas a serem alcançadas. <br></br><br></br>
                Hoje, o sistema jurídico privilegia apenas um dos lados. Desta forma é fundamental para os homens discutirem e planejarem suas ações antes de entrar nesse ambiente totalmente desfavorável a eles. <br></br><br></br>
                Muitos não fazem isso, e são facilmente aniquilados durante esse procedimento. É necessário relacionar seus recursos e medir suas forças. Porém, muitos fatores que determinam à vitória em uma guerra são imponderáveis e dificilmente podem ser antecipados. <br></br><br></br>
                Contudo, na medida do possível, eles devem ser pensados, relacionados, medidos e discutidos antes e durante a realização do divórcio. <br></br><br></br>
                Não basta ter apenas uma estratégia, ela precisa ser avaliada e modificada de acordo com os fatos que vierem acontecer. <br></br><br></br>
                <i><b>  Portanto, é imprescindível discutir e verificar o andamento dessa guerra, e realizar as ações que se fizerem necessárias.</b></i><br></br><br></br>
                Além do conceito de sala de guerra, onde devemos avaliar e planejar nossos ataques, diversas metodologias e ações militares podem ser adaptadas para serem empregadas no processo de divórcio, como algumas descritas a seguir: 

                <br></br><br></br><br></br>
                <b>RESULTADO DA GUERRA</b>  
                <br></br><br></br>

                De acordo com estratégia militar ensinada e praticada pelas forças militares atuais, para prever o resultado de uma guerra, devemos analisar e comparar as nossas próprias condições e as de nosso inimigo, baseados em cinco fatores.
                <br></br><br></br>

                <table border="1" width="100%">
                <tbody>
                <tr><td width="25%">CAMINHO</td></tr>
                <tr><td>CLIMA</td></tr>
                <tr><td>TERRENO</td></tr>
                <tr><td>COMANDO</td></tr>
                <tr><td>DOUTRINA</td></tr>
                </tbody>
                </table>

                <br></br><br></br>
                <u>Traduzindo para o divórcio :</u>  
                <br></br><br></br>

                <table border="1" width="100%">
                <tbody>
                <tr><td width="25%" id="cabec">CAMINHO</td><td>ESTRATÉGIA, ALIADOS</td></tr>
                <tr><td id="cabec">CLIMA</td><td>TEMPO DISPONÍVEL, ESTRUTURA FINANCEIRA E PESSOAL</td></tr>
                <tr><td id="cabec">TERRENO</td><td>JURÍDICO FAMILIAR</td></tr>
                <tr><td id="cabec">COMANDO</td><td>ADVOGADO</td></tr>
                <tr><td id="cabec">DOUTRINA</td><td>ORGANIZAÇÃO, OBJETIVOS DEFINIDOS</td></tr>
                </tbody>
                </table>

                <br></br><br></br><br></br>
                <b>PODER RELATIVO ENTRE FORÇAS</b>  
                <br></br><br></br>

                Com base no conceito apresentado, pode-se comparar as forças e avaliar o poder relativo entre elas. <br></br><br></br>
                Para isso, deve-se realizar as seguintes perguntas:
                <br></br><br></br>

                <table border="1">
                <tbody>
                <tr><td>Qual é o melhor caminho para seguir?</td></tr>
                <tr><td>Qual comandante tem mais habilidade?</td></tr>
                <tr><td>Qual dos lados tem a vantagem do clima e do terreno?</td></tr>
                <tr><td>Qual dos exércitos manifesta uma disciplina mais efetiva?</td></tr>
                <tr><td>Qual dos lados possui superioridade militar?</td></tr>
                <tr><td>Qual dos lados tem os soldados mais bem treinados?</td></tr>
                <tr><td>Qual dos lados possui um sistema mais claro e definido?</td></tr>
                </tbody>
                </table>
                <br></br><br></br>

                <u>Traduzindo para o divórcio</u> : 
                <br></br><br></br>

                <table border="1">
                <tbody>
                <tr><td>Quem possui as melhores estratégias e aliados?</td></tr>
                <tr><td>Qual advogado tem mais habilidade?</td></tr>
                <tr><td>Qual dos lados tem maior vantagem no terreno jurídico?</td></tr>
                <tr><td>Quem tem melhor estrutura financeira, pessoal e tempo disponível?</td></tr>
                <tr><td>Qual dos lados manifesta uma disciplina mais efetiva?</td></tr>
                <tr><td>Qual dos lados possui superioridade intelectual e emocional?</td></tr>
                <tr><td>Qual dos lados tem mais apoio de amigos e familiares?</td></tr>
                <tr><td>Quem possui objetivos e metas mais claras e definidas?</td></tr>
                </tbody>
                </table>

                <br></br><br></br><br></br>
                O terreno jurídico favorece as mulheres.<br></br> 
                Elas possuem uma vantagem tática de 50% ou mais nessa guerra. 
                <br></br><br></br><br></br>
                <b>DISSIMULAÇÃO</b> 
                <br></br><br></br> 
                É uma técnica que tem como objetivo enganar e iludir o adversário de forma que ele tome decisões equivocadas e destrutivas. <br></br><br></br>
                É um fator imponderável que depende exclusivamente da situação e da inteligência de seu praticante. <br></br><br></br>
                Não existe uma regra definida de como e quando utilizá-la. Mas essa técnica é uma qualidade básica fundamental de qualquer operação militar, portanto, deve ser utilizada no processo de divórcio para potencializar as chances de vitória. 
                <br></br><br></br>
                <u>Formas de atuar em uma disputa</u> : 
                <br></br><br></br>

                <table border="1">
                <tbody>
                <tr><td>Se estiver capacitado, deve fingir estar incapacitado</td></tr>
                <tr><td>Se estiver preparado, deve fingir estar despreparado</td></tr>
                <tr><td>Se estiver próximo, deve fingir estar longe</td></tr>
                <tr><td>Oferecer iscas para iludir e atrair o inimigo</td></tr>
                <tr><td>Atacar o inimigo quando ele estiver desordenado</td></tr>
                <tr><td>Se o inimigo for orgulhoso, deve ser provocado</td></tr>
                <tr><td>Se o inimigo for humilde, sua arrogância deve ser incentivada</td></tr>
                <tr><td>Se o inimigo estiver descansado, deve ser desgastado</td></tr>
                </tbody>
                </table>

                <br></br><br></br>
                Os itens relacionados são auto explicativos e devem ser utilizados no Divórcio.
                <br></br><br></br>

                <i><b>"Um comandante militar deve atacar onde o inimigo está desprevenido e deve utilizar caminhos que, para o inimigo, são inesperados."<br></br>Sun Tzu</b></i>  
                <br></br><br></br><br></br>
                <b>PROVISÕES NECESSÁRIAS</b>  
                <br></br><br></br>
                Qualquer exército que for mantido muito tempo em campanha, fará com que as reservas do Estado em determinado momento serão insuficientes. <br></br><br></br>
                Quando o exército estiver com suas forças desgastadas, com suas provisões insuficientes, com suas tropas desmoralizadas e com seus recursos exauridos, seus inimigos tirarão proveito desta situação e obterão vantagens para destrui-lo. <br></br><br></br>
                Portanto, nenhuma hostilidade deve ser mantida além do tempo necessário, e nunca deverá ser declarada sem que haja as provisões necessárias para seu funcionamento.
                <br></br><br></br><br></br>
                <u>Traduzindo para o divórcio :</u>  
                <br></br><br></br>

                <table border="1">
                <tbody>
                <tr><td>Aumentar as reservas financeiras.</td></tr>
                <tr><td>Eliminar as despesas existentes.</td></tr>
                <tr><td>Não realizar novas despesas.</td></tr>
                <tr><td>Eliminar as dívidas em seu nome.</td></tr>
                <tr><td>Manter dívidas em nome do seu cônjuge.</td></tr>
                <tr><td>Fazer o cônjuge contrair mais dividas em nome dele.</td></tr>
                </tbody>
                </table>

                <br></br><br></br><br></br>
                <b>EXPLORANDO VANTAGENS</b>  
                <br></br><br></br>
                Ter a capacidade de parar ou movimentar o inimigo representa uma grande vantagem tática. Por exemplo, é possível enganá-lo oferendo algum tipo de benefício, enquanto o leva para uma armadilha. Como também apresentar algum tipo de ameaça para obrigá-lo a interromper seu avanço. Explorar as vantagens que possam existir, manipulando o movimento do inimigo, representa um fator determinante na obtenção da vitória contra ele.<br></br><br></br>
                Então, a habilidade de um comandante militar consiste em cansar o inimigo quando este estiver descansado; deixá-lo com fome quando estiver com provisões; movê-lo quando estiver parado. 
                <br></br><br></br><br></br>
                <u>Traduzindo para o divórcio :</u>  
                <br></br><br></br>

                <table border="1">
                <tbody>
                <tr><td>Se possuir recursos financeiros abundantes, ajuíze inúmeros processos contra seu cônjuge para desgastá-lo financeiramente e estruturalmente. Faça isso de forma gradativa e constante.</td></tr>
                <tr><td>Utilize meios não jurídicos para desgastar seu cônjuge.</td></tr>
                <tr><td>Crie despesas financeiras em nome de seu cônjuge.</td></tr>
                <tr><td>Gere problemas de todos os tipos de acordo com sua criatividade, e que sejam apenas de responsabilidade de seu cônjuge.</td>
                </tr>
                </tbody>
                </table>

                <br></br><br></br>
                <i><b>"Um general competente movimenta o inimigo e não será manipulado por ele."<br></br>Sun Tzu </b></i>  
                <br></br><br></br><br></br>
                <b>MOMENTO OPORTUNO</b>  
                <br></br><br></br>
                Quando um comandante obtém uma vitória extraordinária contra seu inimigo, é porque eles detêm o conhecimento prévio e pode antever o desenrolar da batalha. <br></br><br></br>
                No entanto, este conhecimento prévio não pode ser obtido por meio de mágica nem de intelecto superior. Deve ser obtido das pessoas que claramente conhecem as situações do inimigo. 
                <br></br><br></br>
                <u>Traduzindo para o divórcio :</u>  
                <br></br><br></br>

                <table border="1">
                <tbody>
                <tr><td>Contratar e utilizar detetives particulares.</td></tr>
                <tr><td>Obter e registrar as provas de adultério para serem utilizadas na justiça.</td></tr>
                <tr><td>Não tenha medo de espionar seu cônjuge.</td></tr>
                <tr><td>Utilizar câmeras e gravadores espiões.</td></tr>
                <tr><td>Obter senhas de email, redes sociais, computadores pessoais, e etc.</td></tr>
                <tr><td>Ouvir e registrar as conversas telefônicas de aparelhos fixos e celulares. </td></tr>
                </tbody>
                </table>

                <br></br><br></br>
                <i><b>"O uso de espiões é essencial na guerra, e o exército depende desse serviço nos seus movimentos."<br></br>Sun Tzu</b></i>  
                <br></br><br></br>
                </span>
            </S.FormText>

            </S.Form>
            </>
            :
            <>
            <br></br>
            <br></br>
            <h3>&nbsp;</h3>
            <br></br>
            </>    }  
        </S.Container>
    )
}

export default GuidePart6;