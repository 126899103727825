import styled from 'styled-components';

export const Container = styled.div`
    background: none;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const LogoImage = styled.div`
    background: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;

    img {
        width: 60%;
        }

    }
`

export const HeaderApp = styled.div`
    background: none;
    font-size: 58px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    text-decoration: none;
    font-weight: bold;
    color: #65767F;
    margin-top: 30px;
    margin-bottom: 10px;

    @media screen and (max-width: 960px) {
        font-size: 38px;
    }
    }
`

export const LanguageApp = styled.div`
    background: none;
    display: flex;
    flex-direction: row;
    justifyContent: flex-end;
    alignItems: flex-end;
    textAlign: right;
    position:absolute;
    right:0;

    span {
        font-size: 12px;
        font-family: 'Roboto', sans-serif;
        }

    select {
        width: 90px;
        font-size: 11px;
        font-family: 'Roboto', sans-serif;
        border-collapse: separate;
        border-spacing: 0;
        border-radius: 5px;
        }

    }
`

export const MenuContainer = styled.div`
    background: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: ${props => (props.size ? 80 : 20)}px;
    margin-left: 2px;
    margin-right: 2px;

    span {
        font-size: 12px;
        font-family: 'Roboto', sans-serif;
        color: #65767F;
        }
`

export const MenuOption1 = styled.div`
    background: none;
    text-align: right;
    text-decoration: none;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
    font-size: 0.9rem;
    color: #65767F;
    cursor: pointer;
    width: 105px;
`

export const MenuOption2 = styled.div`
    background: none;
    text-align: center;
    text-decoration: none;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
    font-size: 0.9rem;
    color: #65767F;
    cursor: pointer;
    width: 110px;
`

export const MenuOption3 = styled.div`
    background: none;
    text-align: left;
    text-decoration: none;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
    font-size: 0.9rem;
    color: #65767F;
    cursor: pointer;
    width: 105px;
`

export const MenuLink = styled.div`

    font-weight: bold;
    font-family: 'Roboto', sans-serif;
    font-size: 1.0rem;
    color:#65767F;

    a:link {
        text-decoration: none;
        color:#65767F;
    }
    
    a:visited {
        text-decoration: none;
        color:#65767F;
    }
    
    a:hover {
        text-decoration: none;
        color:#65767F;
    }
    
    a:active {
        text-decoration: none;
        color:#65767F;
    }
`

export const TopImage = styled.div`
    background: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 90px;

    img {
        width: 100%;
        }

    }
`

export const TitleOption = styled.div`
    background: none;
    text-align: center;
    text-decoration: none;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
    font-size: 2.0rem;
    color: #65767F;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 140px;
`

export const DescOptionTitle = styled.div`
    background: none;
    text-align: center;
    text-decoration: none;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-left: 5%;
    margin-right: 5%;
    margin-top: 60px;
`

export const DescOption = styled.div`
    background: none;
    text-align: center;
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    margin-left: 5%;
    margin-right: 5%;
`

export const AboutImage = styled.div`
    background: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;

    img {
        width: 75%;
        }

`

export const GuideContainer = styled.div`
    background: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
    width: 100%;
`

export const GuideImage = styled.div`
    background: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 30px;

    img {
        width: 70%;
        }

    @media screen and (max-width: 960px) {
        margin-left: 0%;
        margin-right: 0%;

        img {
            width: 100%;
            }
    }    
`

export const GuideDescTitle = styled.div`
    background: none;
    text-align: center;
    text-decoration: none;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
    font-size: 1.5rem;
    color: #65767F;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
`

export const GuideDesc = styled.div`
    background: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 20px;
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
    font-size: 1.3rem;
    }    
`

export const ContactContainer = styled.div`
    background: none;
    width: 100%;
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;

    @media screen and (max-width: 960px) {
        flex-direction: column;
    } 
`

export const ContactSide = styled.div`
    background: none;
    width: 100%;
    display: flex;
    flex-direction: column;
`

export const OtherContainer = styled.div`
    background:  none;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;

    img {
        width: 60%;
        border: 0px solid black;

        &:hover {
            opacity: 0.7;
        }

        @media screen and (max-width: 960px) {
            width: 60%;
        }
        }
`

export const MidiaContainer = styled.div`
    background: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 150px;
`

export const MidiaItem = styled.div`
    background: none;
    flex-direction: column;
    align-items: center;
    margin-left: 20px;
`

export const MidiaButton = styled.button`
    background: none;
    border: none;

    &:hover {
        opacity: 0.7;
    }
`

export const MidiaPlay = styled.button`
    background: none;
    border: none;
    margin-top: 50px;
    margin-bottom: 250px;

    &:hover {
        opacity: 0.7;
    }
`

export const ContainerCopy = styled.div`
    background: none;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;

    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    color: #696969;

    a:link {
        text-decoration: none;
        color:#696969;
    }
    
    a:visited {
        text-decoration: none;
        color:#696969;
    }
    
    a:hover {
        text-decoration: none;
        color:#BB86FC;
    }
    
    a:active {
        text-decoration: none;
        color:#696969;
    }
`

export const DivisionLine = styled.div`
    background: #696969;
    width: 100%;
    height: 1px;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
`