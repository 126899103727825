
import React, {useEffect, useState }  from 'react';   
import * as S                         from './styles';
import guide                          from '../../../../view/DivorceGuide/Guide/Images/guide.png'
import util                           from '../../../../Utilities/Util';

function GuideIntroduction() {
    
    const [access, setAccess] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)
        setAccess( util.checkGuideAccess() );
    }, [])

    return (
        <S.Container>
        { ( access )
            ?
            <>
            <S.Form> 
           
            <S.FormTitle>
                <span>INTRODUCTION</span><br></br><br></br>
            </S.FormTitle>

            <S.FormText>
                <span>

                Justice is an abstract concept that has components and representatives inserted in society that seek to make it something real and applicable in the world in which we live. For this to be possible various elements are built into our physical reality giving us a tangible reference to its existence and making us believe that it really exists. <br></br><br></br>
                Buildings are erected, regulations are created, rules and procedures are formulated, people are selected, appointed and trained in places created to carry out this task and various other secondary components are assembled around them that follow this same script to protect and maintain its operation. <br></br><br></br>
                From its foundation in the real world, this institution becomes part of the society where it is inserted, dictating its rules and duties. The new individuals that are born after this event will automatically accept these institutions as something normal, present and necessary. Believing that they were designed to solve and correct existing problems with the aim of helping people to resolve their conflicts and produce a balance between their actions, desires and rights.<br></br><br></br>
                However, it is only represented in reality and can never be fully exercised existing only as an ideal or a goal to be achieved since no man or institution has the capacity to produce real justice.<br></br><br></br>
                Currently, it is represented by the power of the state that is in charge of applying the laws. <br></br><br></br>
                It is a complex and large-scale system that requires large human and financial resources for its operation. However, the results it offers to society are insignificant and highly questionable. Even with generous economic income, state-of-the-art technology, trained and qualified personnel, this system is incapable of offering a quality service nor does it produce the desired justice.<br></br><br></ br>
                The numbers of violence and crime do not stop growing, overloading the judicial system with new processes. Obviously driven by the victims of this inefficiency. It is a system that spends a lot and produces little. Furthermore, it can take years for a real result to be produced. People have their lives destroyed or paralyzed waiting for it, often of innocuous or insignificant value due to the time that has elapsed. This favors criminals and further harms their victims who do not obtain the desired justice to compensate or minimize their losses and suffering. <br></br><br></br>
                These results, in addition to being very time consuming are also highly questionable for two reasons: the first is the existing laws. Many are outdated, illogical, unfair and contradictory making its interpretation and applicability too difficult. The second reason is related to the number of processes. The volume of new cases that arrive daily and those that are pending resolution make due diligence impossible. Even releasing one process per hour the speed would still be insufficient, but it should be noted that this would be invalid and highly harmful. <br></br><br></br>
                They cannot be finished quickly. The processes are all made up of descriptive texts that are difficult to understand and documents attached to them. In theory, everything has to be meticulously studied and evaluated to reach a fair and satisfactory opinion for those involved. <br></br><br></br>
                However, the amount of documents to process and analyze is endless, making this task practically impossible, even for exceptional professionals. A misinterpreted text, a misunderstood word, even the omission of a comma can change the entire meaning of an accusation or defense producing disastrous results. <br></br><br></br>
                Therefore, justice cannot become a factory with a production line driven by goals or demands. It need to be conscious and zealous in your decisions. After all, lives depend on it and are affected by it. <br></br><br></br>
                Unfortunately, the described problems, distortions and limitations only produce one thing:<br></br><br></br><br></br>
                <center><b>I N J U S T I C E</b></center><br></br><br></br>
                The judicial system doesn't work and probably never will. The number of failures and mismatches can no longer be corrected and any attempt to fix it only results in frustration, expense, or more problems. In addition, there does not seem to be a concrete interest in solving existing problems even the simplest ones since this system has also become a powerful tool that generates a lot of money for dishonest, unscrupulous and even perverse people who are directly or indirectly integrated into it. <br></br><br></br>
                The more problems, crimes and imbalances there are in our society the more this system will be sought to try to solve them, thus promoting a perpetual circle without a solution. Consequently, generating more power and wealth for those who manipulate it and are favored by it. <br></br><br></br>
                It is necessary to know this terrible scenario in which we are inserted to be able to do something in our defense. We can no longer keep our eyes closed and continue to believe and maintain institutions that do not work and benefit only those who are interested.
                <br></br><br></br><br></br><br></br>
                <h2>THE GUIDE</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                This document should be used as a compass that shows the way in an unknown and foggy environment for most people especially men. Many of them continue to believe in the institutions and its recovery. So they are easily deceived and killed by them. They believe in equality in the laws and in the common sense of the professionals involved. 
                These attributes have not existed in justice for a long time and this imbalance has not stopped growing. <br></br><br></br>
                Men are the biggest losers, the most they can do is cut their losses which is already a huge win. They are seen and treated only as suppliers.<br></br><br></br>
                All the procedures and decisions made will really just be a big representation. The results will already be ready, but this cannot be demonstrated openly. So it will be necessary to set up a theater to mask this fact and it will be the men who will pay the entire cost of this staging. <br></br><br></br>
                In fact, when there is character and common sense between the spouses in a divorce process everything tends to run smoothly and satisfactorily and justice will show signs that it is working and will not show its true face, but when that doesn't happen men will be judged and sentenced based on the idea that women are victims and men are villains. 
                There are no laws that favor men, but there are several that favor only women and new laws are constantly being created and passed. <br></br><br></br>
                Men are not judged fairly or with common sense and will always have to pay the bills whether or not they are capable of carrying out this task. 
                If they can't or refuse they will be arrested, sentenced and destroyed regardless of the political and social scene of the moment. <br></br><br></br>
                Currently, not having a job or money is a heinous crime and must be treated with extreme rigor. Unfortunately, this is the reality and the professionals involved know it. 
                For this reason everything is ready and related only your data will be modified in the documents that will be used. 
                The audiences and the results will be the same only in some cases something will be different.<br></br><br></br><br></br>
                <center><b><i>The laws are strict and designed to defeat men.</i></b></center> <br></br><br></br>
                Many men still do not agree with this scenario and believe in justice mainly because they know people who got divorced without any problem. 
                Some have even done this procedure more than once and nothing bad or disastrous has happened despite the fact that there are children in the relationship. <br></br><br></br>
                Each case is unique and does not mean that it will be disastrous or peaceful for everyone. It will always depend on the circumstances and mainly on the moral values of those involved, but if the worst happens it will be truly terrifying and destructive. 
                Nobody knows how others will react in conflict situations. The people we carry in our arms and in whom we invest our lives can without any explanation become terrible and destructive executioners. 
                There are innumerable cases in the history of humanity that confirm this in addition to the crimes, injustices and atrocities of all kinds that happen daily. <br></br><br></br>
                The vast majority of men do not accept this reality preferring to believe that all this is just unfortunate and circumstantial cases. 
                That they are failures of justice that can eventually be corrected. 
                They believe that everything will happen quickly and smoothly with them, that no time or money should be wasted on any kind of preparation.<br></br><br></br>
                In the end it will be a fatal mistake for those who act and think in this way. <br></br><br></br>
                Therefore, this guide shows this reality and the elements that intervene in this operation demonstrates the existing laws and procedures and provides some solutions that must be followed to avoid or reduce the personal and economic losses caused by divorce.
                <br></br><br></br><br></br><br></br>
                <i><b>THE CONTENT OF THIS GUIDE IS BASED ON PERSONAL OPINION ACQUIRED EMPIRICALLY OR OBTAINED THROUGH PUBLIC AND GOVERNMENT DOCUMENTS AVAILABLE IN THE EXISTING MEDIA.
                <br></br><br></br>NO WARRANTY THAT THE INFORMATION ACCESSIBLE IN THIS DOCUMENT IS ACCURATE, COMPLETE OR UP-TO-DATE.
                <br></br><br></br>BEING THE READER'S RESPONSIBILITY TO FOLLOW OR NOT YOUR SUGGESTIONS, ADVICE AND OPINIONS, YOU SHOULD <u>ALWAYS</u> LOOK FOR AN AUTHORIZED AND SPECIALIZED PROFESSIONAL TO RESOLVE YOUR DOUBTS AND PROBLEMS IN A LEGAL AND SATISFACTORY MANNER.</b></i>
                <br></br><br></br><br></br>

                </span>
            </S.FormText>

            </S.Form>
            </>
            :
            <>
            <br></br>
            <br></br>
            <h3>&nbsp;</h3>
            <br></br>
            </>    }  
        </S.Container>
    )
}

export default GuideIntroduction;