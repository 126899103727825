
import React, { useEffect, useState } from 'react';   
import * as S       from './styles';
import me           from '../../../../view/DivorceGuide/Guide/Images/me.png'
import util         from '../../../../Utilities/Util';

function Author() {
    
    const [access, setAccess] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)
        setAccess( util.checkGuideAccess() );
    }, [])

    return (
        <S.Container>
        { ( access )
            ?
            <>
            <S.Form> 

            <S.FormTitle>
                <span>AUTHOR</span><br></br>
            </S.FormTitle>

            <S.ContainerCards>
            <S.FormImage><img src={me} alt=""></img></S.FormImage>
            <S.FormText>
                <span>
                <i>
                Sou o resultado de uma guerra silenciosa que ocorre diante de nós, mas não damos conta nem importância até que seja tarde.<br></br><br></br>
                <b>Não sou médico, psicólogo, jurista ou advogado</b>.<br></br><br></br>
                Sou alguém que através de muito sofrimento e desilusão adquiriu vasto conhecimento de como sobreviver, lutar e até mesmo vencer essa guerra invisível e destrutiva.<br></br><br></br>
                Não forneço garantias nem prometo vitórias, ofereço apenas a verdade e mecanismos para sobreviver, mas a luta será travada por você.<br></br><br></br>
                Oferecer a verdade já é uma grande vitória e vantagem contra seus adversários e para seu crescimento pessoal, mas também ensino a evitar muitos erros e sofrimentos desnecessários.<br></br><br></br>
                <b>Acredite! Nenhum dos <u>especialistas</u> que você irá procurar, fará isso.</b><br></br><br></br>
                <br></br><br></br>
                </i>
                </span>
            </S.FormText>
            </S.ContainerCards>
            </S.Form>
            </>
            :
            <>
            <br></br>
            <br></br>
            <h3>&nbsp;</h3>
            <br></br>
            </>    }  
        </S.Container>
    )
}

export default Author;