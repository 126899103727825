import React, {useState}                from 'react';
import * as S                           from './styles';
import guide                            from '../Guide/Images/guide.png'
import util                             from '../../../Utilities/Util'

function Element1() {

    const [english,    setEnglish]    = useState(false);
    const [spanish,    setSpanish]    = useState(false);
    const [portuguese, setPortuguese] = useState(false);

    React.useEffect(() => {
  
      const result = util.getLanguage();

      setEnglish   (result.english);
      setSpanish   (result.spanish);
      setPortuguese(result.portuguese);

      }, []);
 
    return (
        <S.Container>

            <S.FormImage><img src={guide} alt=""></img></S.FormImage>

            { (portuguese) &&
            <S.ContainerData>
                <S.SideLeft>&nbsp;</S.SideLeft>
                <S.CenterData>
                    <S.Title>INTRODUÇÃO</S.Title>
                    <S.FormDesc>
<span>O divórcio é um ato jurídico que é realizado com o consentimento mútuo ou por apenas um dos cônjuges.<br></br><br></br>
Muitos acreditam que se trata apenas de um ato burocrático, onde seus desejos e direitos serão respeitados, com a liberdade sendo restaurada, produzindo uma vida mais próspera, satisfatória e feliz para todos ou para a pessoa que o solicitou.<br></br><br></br>
Isso acontece nos filmes, livros e novelas, mas no mundo real a coisa não é bem assim.<br></br><br></br>
Divórcios amistosos tendem a funcionar de forma tranquila e produzir algumas vezes aquilo que se espera, mas quando existe algum desafeto ou desacordo entre os envolvidos, o cenário muda completamente podendo gerar problemas terríveis e inesperadas a todos, produzindo consequências e sequelas que acabam se estendendo por anos ou até mesmo por toda a vida, atingindo e afetando de forma direta ou indireta, não apenas o casal, mas também seus filhos, parentes, amigos e relacionamentos futuros.<br></br><br></br>
Atualmente, vivemos em uma sociedade de consumo onde tudo é acessível, rápido e descartável. Não poderia ser diferente com o divórcio. Ele é amplamente incentivado pela mídia, sociedade e instituições, sendo hoje algo casual e corriqueiro.<br></br><br></br>
Famílias são facilmente destruídas, lares são desfeitos, filhos são separados, conjuges são massacrados, sonhos são perdidos e patrimônios pulverizados em nome de um ideal de liberdade e felicidade que na maioria das vezes nunca será atingido nem concretizado.<br></br><br></br>
Os relacionamentos humanos são compostos de momentos tristes e felizes, de situações de conflito e harmonia, sendo a convivência entre as partes algo difícil e desafiador.<br></br><br></br>
O divórcio não pode ser usado como solução para animosidade ou desconfortos existentes na relação do casal uma vez que eles, mais cedo ou mais tarde, sempre irão ocorrer. <br></br><br></br>
Outras alternativas devem ser procuradas e excecutadas antes dessa ação ser realmente formalizada, principalmente se existirem filhos decorrentes da união.<br></br><br></br>
Quando o casal possui respeito mútuo e está em comum acordo, o divórcio é realizado de forma tranquila e passa a ser apenas uma operação judicial de prazo relativamente curto.<br></br><br></br>
Contudo, quando esses elementos não existirem, ele passa a ser um procedimento de futuro e consequências indefinidas.<br></br><br></br>
Nesse caso, muitos fatores e variáveis irão influenciar sua execução. Por exemplo, dependerá da modalidade adotada no regime do casamento, se existirem filhos menores ou incapazes, número de bens, órgão público selecionado, competência do advogado e etc.<br></br><br></br>
Dependerá principalmente das características pessoais dos envolvidos, como seu caráter, gênero, estado emocional e situação financeira.<br></br><br></br>
Portanto, o divórcio é um acontecimento que devido a esses fatores e características poderá ser uma operação tranquila ou algo muito dispendioso, devastador e de futuro incerto.<br></br><br></br><br></br>
<b>PARA MELHOR ENTENDER ESSA SISTEMÁTICA ESTE APLICATIVO VISA DESCREVER OS PRINCIPAIS ELEMENTOS CONTIDOS EM UM PROCESSO DE DIVÓRCIO COM SUAS RESPECTIVAS VARIAÇÕES E CONSEQUÊNCIAS.</b></span>
                    </S.FormDesc>
                    <br></br> <br></br> <br></br>                     
                </S.CenterData>
                <S.SideRight>&nbsp;</S.SideRight>
            </S.ContainerData> 
            }

            { (spanish) &&
            <S.ContainerData>
                <S.SideLeft>&nbsp;</S.SideLeft>
                <S.CenterData>
                     <S.Title>INTRODUCCIÓN</S.Title>
                     <S.FormDesc>
<span>El divorcio es un acto jurídico que se realiza de común acuerdo o por uno solo de los cónyuges.<br></br><br></br>
Muchos creen que es solo un acto burocrático, donde se respetarán sus deseos y derechos, restableciéndose la libertad, produciendo una vida más próspera, satisfactoria y feliz para todos o para la persona que la solicitó.<br></br><br></br>
Sucede en películas, libros y novelas, pero en el mundo real no es así.<br></br><br></br>
Los divorcios amistosos suelen funcionar sin problemas y en ocasiones producen lo esperado, pero cuando existe algún descontento o desacuerdo entre los involucrados, el escenario cambia por completo y puede generar problemas terribles y inesperados para todos, produciendo consecuencias y secuelas que terminan prolongándose durante años o más. incluso para toda la vida, alcanzando y afectando, directa o indirectamente, no sólo a la pareja, sino también a sus hijos, familiares, amigos y futuras relaciones.<br></br><br></br>
Actualmente vivimos en una sociedad de consumo donde todo es accesible, rápido y desechable. No podría ser diferente con el divorcio. Es muy fomentado por los medios de comunicación, la sociedad y las instituciones, y hoy es algo casual y común.<br></br><br></br>
Las familias se destruyen fácilmente, los hogares se destrozan, los niños se separan, los cónyuges son masacrados, los sueños se pierden y los bienes se pulverizan en nombre de un ideal de libertad y felicidad que en la mayoría de los casos nunca se logrará o materializará.<br></br><br></br>
Las relaciones humanas se componen de momentos tristes y felices, situaciones de conflicto y armonía, siendo la convivencia entre las partes algo difícil y desafiante.<br></br><br></br>
El divorcio no puede ser utilizado como solución a animosidades o malestares existentes en la relación de pareja ya que tarde o temprano siempre se producirán. <br></br><br></br>
Deben buscarse y ejecutarse otras alternativas antes de que esta acción sea realmente formalizada, especialmente si hay hijos producto de la unión.<br></br><br></br>
Cuando la pareja tiene respeto mutuo y está de común acuerdo, el divorcio se lleva a cabo sin problemas y se convierte en una operación legal de relativamente corto plazo.<br></br><br></br>
Sin embargo, cuando estos elementos no existen, se convierte en un procedimiento de futuro y consecuencias indefinidas.<br></br><br></br>
En este caso, muchos factores y variables influirán en su ejecución. Por ejemplo, dependerá de la modalidad adoptada en el régimen matrimonial, si hay hijos menores o incapaces, número de bienes, competencia del abogado, etc.<br></br><br></br>
Dependerá principalmente de las características personales de los involucrados, como su carácter, género, estado emocional y económico.<br></br><br></br>
Por lo tanto, el divorcio es un evento que por estos factores y características, puede ser una operación tranquila o algo muy costoso, devastador y con un futuro incierto.<br></br><br></br><br></br>
<b>PARA ENTENDER MEJOR ESTE SISTEMA, ESTA APLICACIÓN TIENE POR DESCRITO LOS PRINCIPALES ELEMENTOS QUE CONTIENEN UN PROCESO DE DIVORCIO CON SUS RESPECTIVAS VARIACIONES Y CONSECUENCIAS.</b></span>
                     </S.FormDesc>
                     <br></br> <br></br> <br></br>
                </S.CenterData>
                <S.SideRight>&nbsp;</S.SideRight>
            </S.ContainerData> 
            }           

            { (english) &&
            <S.ContainerData>
                <S.SideLeft>&nbsp;</S.SideLeft>
                <S.CenterData>
                    <S.Title>INTRODUCTION</S.Title>
                    <S.FormDesc>
<span>Divorce is a legal act practiced by mutual agreement or by only one of the spouses.<br></br><br></br>
Many believe that it is just a bureaucratic act, where their wishes and rights will be respected, freedom will be restored, producing a more prosperous, satisfying and happy life for everyone or for those who requested it.<br></br><br></br>
This happens in movies, books and novels, but not in the real life.<br></br><br></br>
Amicable divorces usually work smoothly and sometimes produce what is expected, but when there is some dissatisfaction or disagreement between those involved, the scenario changes completely and can generate terrible and unexpected problems for everyone, producing consequences and sequels that end up lasting for years or lifelong, reaching and affecting directly or indirectly not only the couple, but also their children, relatives, friends and future relationships.<br></br><br></br>
We currently live in a consumer society where everything is accessible, fast and disposable. It could not be different with divorce. It is widely publicized by the media, society and institutions and today it is something casual and common.<br></br><br></br>
Families are easily destroyed, homes broken, children separated, spouses massacred, dreams lost and assets pulverized in the name of an ideal of freedom and happiness that in most cases will never be achieved or materialized.<br></br><br></br>
Human relationships are made up of sad and happy moments, situations of conflict and harmony and the coexistence between the parties is difficult and challenging.<br></br><br></br>
Divorce cannot be used as a solution to animosities or discomforts existing in the couple\'s relationship, as sooner or later they will always occur. <br></br><br></br>
Other alternatives must be found and implemented before this action is actually formalized, especially if there are children of the union.<br></br><br></br>
When the couple has mutual respect and agreement, divorce goes smoothly and becomes a relatively short-term legal operation.<br></br><br></br>
However, when these elements do not exist it becomes a future procedure with undefined consequences.<br></br><br></br>
In this case, many factors and variables will influence its execution. For example, it will depend on the modality adopted in the matrimonial regime, whether there are minor or incapacitated children, number of goods, competence of the lawyer and etc.<br></br><br></br>
However, it will mainly depend on the personal characteristics of those involved, such as character, gender, emotional and economic condition, etc.<br></br><br></br>
Therefore, divorce is an event that due to these factors and characteristics it can be a smooth operation or something very expensive, devastating and with an uncertain future.<br></br><br></br><br></br>
<b>TO UNDERSTAND THIS SYSTEM BETTER, THIS APPLICATION DESCRIBES THE MAIN ELEMENTS THAT CONTAIN A DIVORCE PROCESS WITH THEIR RESPECTIVE VARIATIONS AND CONSEQUENCES.</b></span>

                    </S.FormDesc>
                    <br></br> <br></br> <br></br>
            </S.CenterData>
            <S.SideRight>&nbsp;</S.SideRight>
        </S.ContainerData> 
            }
        </S.Container>
    )
}

export default Element1;