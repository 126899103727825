
import React, { useState, useEffect } from 'react';   
import * as S       from './styles';
import guide11      from '../../../../view/DivorceGuide/Guide/Images/guide11.png';
import guide12      from '../../../../view/DivorceGuide/Guide/Images/guide12.png';
import guide13      from '../../../../view/DivorceGuide/Guide/Images/guide13.png';
import guide14      from '../../../../view/DivorceGuide/Guide/Images/guide14.png';
import guide15      from '../../../../view/DivorceGuide/Guide/Images/guide15.png';
import guide16      from '../../../../view/DivorceGuide/Guide/Images/guide16.png';
import guide17      from '../../../../view/DivorceGuide/Guide/Images/guide17.png';
import guide18      from '../../../../view/DivorceGuide/Guide/Images/guide18.png';
import guide19      from '../../../../view/DivorceGuide/Guide/Images/guide19.png';
import guide20      from '../../../../view/DivorceGuide/Guide/Images/guide20.png';
import chart2       from '../../../../view/DivorceGuide/Guide/Images/chart2.png';
import chart3       from '../../../../view/DivorceGuide/Guide/Images/chart3.png';
import PopUp        from '../../../../view/DivorceGuide/Guide/Alert/';
import util         from '../../../../Utilities/Util';

function GuidePart3() {

    const [click1, setClick1] = useState(false);
    const [click2, setClick2] = useState(false);
    const handleClick1 = () => { setClick1(!click1); }
    const handleClick2 = () => { setClick2(!click2); }
    const [access, setAccess] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)
        setAccess( util.checkGuideAccess() );
    }, [])

    return (
        <S.Container>
        { ( access )
            ?
            <>
            <S.Form> 

            <S.FormTitle>
                <span>PARTE3</span><br></br><br></br><br></br><span>Componentes da Justiça</span><br></br><br></br>
            </S.FormTitle>

            <S.FormText>
                <span>

                A justiça é um conceito abstrato. É um ideal a ser alcançado, algo imaterial que não pode ser visto nem tocado. O que existe são instituições que tentam dar substância a esse conceito no mundo real. <br></br><br></br>
                Por causa disso vários componentes, regras e procedimentos são desenvolvidos para torná-la visível e sua utilização possível a todos. Pessoas serão adicionadas a esse conjunto para controlar e gerenciar seu funcionamento. Hierarquias, funções e títulos serão criados e distribuídos entre elas. <br></br><br></br>
                Por fim, será um sistema montado com o objetivo de tentar reproduzir e aplicar na realidade à justiça sonhada e idealizada pela sociedade.<br></br><br></br>
                <b><i> Contudo, é apenas uma tentativa humana de materializá-la.</i></b><br></br><br></br> 
                Nenhum estado, pessoa ou máquina têm capacidade de realizar e reproduzir a justiça em sua essência. Sempre haverá falhas e distorções. Inclusive com a produção de seu antônimo <b>à injustiça</b>. <br></br><br></br>
                Além disso, essas instituições são controladas por pessoas. Elas por sua vez são movidas por interesses pessoais, muitas vezes diferentes do objetivo primário pelo qual esse sistema foi criado e daquilo que a sociedade almeja e necessita. <br></br><br></br> 
                Com o passar do tempo, esse sistema poderá tornar-se algo totalmente diferente do original, tanto em princípios como em metas. Algo dotado de grande poder sobre as vidas e destinos das pessoas, mas nunca podendo ser questionado nem controlado por elas. Apenas por grupos restritos e desconhecidos de pessoas ou instituições ligadas direta ou indiretamente a ele. <br></br><br></br> 
                Também poderá crescer demasiadamente, necessitando de grandes recursos financeiros para seu funcionamento. Produzindo atrasos e distorções em seus resultados. Sendo alvo de pessoas desonestas e inescrupulosas que tentam lucrar nesse cenário. Pode se tornar um sistema falho, corrupto e que não funciona. E mesmo assim, será o único local onde as pessoas poderão procurar para obter ressarcimento e proteção de seus direitos. <br></br><br></br> 
                Portanto, é essencial conhecer alguns de seus componentes para saber como agir diante das situações e acontecimentos gerados por esse sistema
                
                <br></br><br></br><br></br><br></br>
                <h2>Direito Civil</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide11} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                Direito é o mecanismo criado pela sociedade humana que estuda e aplica as leis para que a justiça em um determinado setor possa ser alcançada e concretizada. Direito Civil é o mecanismo que regula direitos e obrigações de ordem privada relativo às pessoas, seus direitos e obrigações, aos bens e às suas relações com outros indivíduos da sociedade. <br></br><br></br>
                O divórcio é um procedimento regulado pelo direito civil de cada país, podendo variar devido a região geográfica, cultura e moral da sociedade que o definiu. <br></br><br></br>
                O direito Civil possui princípios norteadores que servem como regras mestras que auxiliam os operadores das leis na resolução de conflitos.<br></br><br></br> 
                Os três princípios básicos norteadores da justiça são:<br></br><br></br>

                <table border="1">
                <tbody>
                <tr><td >&nbsp;<b>Eticidade</b>&nbsp;</td><td >&nbsp;Utilização dos valores e das leis morais para melhor condução da vida em sociedade. &nbsp;</td></tr>
                <tr><td >&nbsp;<b>Socialidade</b>&nbsp;</td><td >&nbsp;Os direitos e deveres coletivos estão em prevalência frente aos direitos individuais, sem a perda do valor fundamental da pessoa humana.&nbsp;</td></tr>
                <tr><td >&nbsp;<b>Operabilidade</b>&nbsp;</td><td >&nbsp;O Direito tem de ser o mais concreto e efetivo possível.&nbsp;</td></tr>
                </tbody>
                </table>

                <br></br><br></br> 
                Com base nesses princípios, tanto a interpretação quanto a aplicação das leis devem estar a agir em sintonia e concordância com eles, para que haja uma aplicação justa e igualitária do Direito. <br></br><br></br>
                Porém, muitos operadores do Direito ignoram esses princípios, seja pela busca de poder e dinheiro ou mesmo por terem formação moral deficiente.<br></br><br></br>
                <br></br>
                <b>Direito de Família</b><br></br><br></br>
                É um ramo do direito civil que contém normas e procedimentos judiciais relativos à estrutura, organização e proteção da família. Trata e gerencia os direitos e deveres dos membros decorrentes dessa organização social. 
                O direito de família trata principalmente dos problemas envolvendo o casamento e o divórcio de instituições familiares, indiferente da modalidade adotada pelos membros da unidade familiar.
                <br></br><br></br><br></br>
                <b>Código Civil</b><br></br><br></br>
                Código civil é um conjunto de normas que determinam os direitos e deveres das pessoas, com base na Constituição Nacional.  
                O atual Código Civil Brasileiro, foi instituído pela Lei nº 10.406, de 10 de janeiro de 2002, entrando em vigor em todo o território nacional a partir de 11 de janeiro de 2003. No total, o Código Civil Brasileiro contém 2.046 artigos, responsáveis por nortear os direitos e obrigações das pessoas.
                <br></br><br></br><br></br>
                <b>CPC - Código de Processo Civil</b><br></br><br></br>
                Diferente do Código Civil, ele se destina aos processos judiciais, regulando o funcionamento dos tribunais em relação a determinado processo de natureza civil. O atual Código de Processo Civil Brasileiro foi instituído pela lei nº 13.105, de 16 de março de 2015.
                <br></br><br></br><br></br>
                <b>CNJ - Conselho Nacional de Justiça</b><br></br><br></br>
                É um órgão ligado ao Poder Judiciário com o objetivo de definir, melhorar e fiscalizar o funcionamento do judiciário, bem como realizar punições à funcionários ou autoridades públicas pela prática de atos ilegais.  
                <br></br><br></br><br></br>
                <u>Algumas de suas atribuições são</u>:<br></br><br></br>

                <table border="1" >
                <tbody>
                <tr><td >&nbsp;Planejamento estratégico e proposição de políticas judiciárias.&nbsp;</td></tr>
                <tr><td >&nbsp;Modernização tecnológica do judiciário.&nbsp;</td></tr>
                <tr><td >&nbsp;Ampliação do acesso à justiça, pacificação e responsabilidade social.&nbsp;</td></tr>
                <tr><td >&nbsp;Garantia de efetivo respeito às liberdades públicas e execuções penais.&nbsp;</td></tr>
                </tbody>
                </table>

                <br></br><br></br>
                É possível efetuar através da internet ou correio e sem a necessidade de um advogado, reclamações ou petições sobre alguma questão relacionada a alguma falta ou omissão de responsabilidade do Judiciário, como por exemplo demora na condução de um processo ou no seu sentenciamento. <br></br><br></br><br></br>
                <b><u>NÃO PRODUZ NENHUM RESULTADO PRÁTICO, A DENUNCIA SERÁ ENCAMINHADA PARA O PRÓPRIO JUÍZ RESPONSÁVEL PELA CAUSA E NADA ALÉM DISSO</u></b>.
                <br></br><br></br>
                Apesar de ser um procedimento inócuo, ele deve ser efetuado. É uma forma de pressionar as autoridades responsáveis. 
                O juiz será obrigado a emitir uma justificativa, normalmente dizendo que existe um volume de serviço muito alto que dificulta o andamento do processo. 
                Espere alguns dias e/ou poucos meses após receber a resposta e repita a operação quantas vezes forem necessárias até que o processo em questão receba o sentenciamento final. 
                Enquanto o processo estiver pendente, sua vida estará paralisada, aguardando um resultado para efetuar alguma ação. O importante é finalizar o processo, sendo o resultado favorável ou não.

                <br></br><br></br><br></br><br></br>
                <h2>Cônjuges</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide12} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                São os indivíduos que estão em uma relação conjugal acordada e homologada pela justiça. Esse acordo implica em direitos e deveres para ambos que são regulamentados e definidos pelo Código Civil.<br></br><br></br>
                Art. 1.566 do Código Civil, <u>são deveres de ambos os cônjuges</u>:<br></br><br></br>

                <table border="1" >
                <tbody>
                <tr><td >&nbsp;Fidelidade recíproca&nbsp;</td></tr>
                <tr><td >&nbsp;Vida em comum, no domicílio conjugal&nbsp;</td></tr>
                <tr><td >&nbsp;Mútua assistência&nbsp;</td></tr>
                <tr><td >&nbsp;Sustento, guarda e educação dos filhos&nbsp;</td></tr>
                <tr><td >&nbsp;Respeito e consideração mútuos&nbsp;</td></tr>
                </tbody>
                </table> 

                <br></br><br></br>
                A infração desses deveres, impostos a ambos os cônjuges, configura o adultério, indicando a falência da moral familiar, além de agravar a honra do outro cônjuge. <br></br><br></br>A quebra de um deles não representa crime passível de multa ou detenção. <br></br><br></br>
                Contudo, será passível de indenização por dano moral. Para que esses direitos e deveres recíprocos deixem de existir é necessário que um processo de divórcio seja homologado pela justiça.
                 Quando existir filhos menores ou incapazes na relação matrimonial, os direitos e deveres para com eles permanecem até a maioridade ou decisão judicial. <br></br><br></br>
                Em caso de omissão comprovada dos deveres advindos do poder familiar a eles, incidem os crimes de abandono material e intelectual, previstos nos artigos 244 e 246 do Código Penal. 

                <br></br><br></br><br></br><br></br>
                <h2>Família</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide13} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                É um agrupamento humano específico composto por ancestrais em comum ou pessoas ligadas por laços afetivos que normalmente vivem juntas e trabalham em benefício próprio. <br></br><br></br>
                <i><b>A família é a unidade básica de uma sociedade</b></i>. <br></br><br></br>
                É através dela que os indivíduos adquirem sua estrutura psíquica e desenvolvem seu caráter. Normalmente, formada por um casal heterossexual e seus filhos. <br></br><br></br>
                Atualmente, esse conceito vem sendo modificado e as famílias estão sendo agrupadas em diferentes modalidades, a saber:<br></br><br></br>
                
                <table border="1" >
                <tbody>
                <tr><td >&nbsp;<b>Matrimonial</b>&nbsp;</td><td >&nbsp;Família formada pelo casamento, tanto entre casais heterossexuais quanto homoafetivos.&nbsp;</td></tr>
                <tr><td >&nbsp;<b>Informal</b>&nbsp;</td><td >&nbsp;Família formada por uma união estável, tanto entre casais heterossexuais quanto homoafetivos.&nbsp;</td></tr>
                <tr><td >&nbsp;<b>Monoparental</b>&nbsp;</td><td >&nbsp;Família formada apenas por um dos pais e seus descendentes.&nbsp;</td></tr>
                <tr><td >&nbsp;<b>Anaparental</b>&nbsp;</td><td >&nbsp;Família formada apenas pelos irmãos sem os pais.&nbsp;</td></tr>
                <tr><td >&nbsp;<b>Unipessoal</b>&nbsp;</td><td >&nbsp;Família formada apenas por uma pessoa.&nbsp;</td></tr>
                <tr><td >&nbsp;<b>Homoafetiva</b>&nbsp;</td><td >&nbsp;Família formada apenas por pessoas do mesmo sexo.&nbsp;</td></tr>
                <tr><td >&nbsp;<b>Mosaico</b>&nbsp;</td><td >&nbsp;Família formada pela junção de famílias de pais separados.&nbsp;</td></tr>
                <tr><td >&nbsp;<b>Paralela</b>&nbsp;</td><td >&nbsp;Indivíduos que possuem mais de uma relação estável.&nbsp;</td></tr>
                <tr><td >&nbsp;<b>Eudemonista</b>&nbsp;</td><td >&nbsp;Família formada por pessoas socioafetivas.&nbsp;</td></tr>
                </tbody>
                </table> 

                <br></br><br></br><br></br><br></br>
                <h2>Filhos</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide14} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                É a designação dada aos descendentes de um casal. Eles representam a continuidade de seus pais e dos indivíduos de uma sociedade. <br></br><br></br>
                <i><b>São os principais e os mais importantes componentes de uma família</b></i>. <br></br><br></br>
                A família fornece o ambiente e toda estrutura necessária para seu desenvolvimento físico e psíquico dos filhos do casal. Esses fatores serão responsáveis pelo desenvolvimento emocional positivo e seguro dos filhos, para que aprendam a lidar com as diversas situações da vida. <br></br><br></br>
                Por isso é importante crescer em um lar no qual pai e mãe estejam presentes e ofereçam apoio incondicional, conforto e proteção. Mas quando uma família é desfeita são eles os maiores prejudicados. São os filhos menores ou incapazes que sofrem as maiores consequências dessa quebra. <br></br><br></br>
                Mesmo a nível incosciente, sentem que perderam a proteção familiar que tinham, mesmo que uma nova e melhor estrutura seja criada. Muitos filhos de pais separados irão crescer felizes e sem problemas aparentes, mas todos irão desenvolver algum tipo de sequela emocional, como insegurança, raiva, depressão, que afetará suas vidas futuras. <br></br><br></br>
                Em um mundo injusto e competitivo estarão em desvantagem em relação aos seus concorrentes, portadores de estruturas emocionais mais sólidas provenientes de uma educação familiar consistente. <br></br><br></br>
                <b><u>OS HOMENS SÃO OS MAIORES PREJUDICADOS NESSE PROCESSO, RARAMENTE TEM DIREITO AO COMPARTILHAMENTO OU A GUARDA DOS FILHOS</u></b>. <br></br><br></br>
                Quando este é ausente ou impedido de exercer sua paternidade, geralmente os filhos são portadores de algum tipo de transtorno emocional que irá afetar de alguma forma o seu futuro. <br></br><br></br>
                Isso acontece porque <b><u>O PAI TEM UM PAPEL FUNDAMENTAL NA FORMAÇÃO DO CARÁTER DOS FILHOS</u></b>. 

                <br></br><br></br><br></br><br></br>
                <h2>Advogado</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide15} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                Diferentemente dos livros e filmes, não podemos exercer a livre defesa, é obrigatório a utilização de um advogado. <br></br><br></br>
                A lei possibilita realizar um divórcio amigável entre casais sem filhos menores ou incapazes, diretamente em um cartório de notas, mas exige a utilização de um advogado para que ele possa elaborar e assinar o processo, juntamente com o casal solicitante. <br></br><br></br>
                Seja qual for o tipo de divórcio, amigável ou litigioso, a presença de um advogado é obrigatória. <br></br><br></br>
                Divórcios amigáveis tendem a serem rápidos e baratos, contudo, quando existir algum tipo de desacordo, principalmente se houver filhos envolvidos, todo quadro se torna ainda mais incerto. Neste caso, o defensor escolhido passa a ser peça chave nesta disputa.<br></br><br></br>
                <b><i> É o principal elemento na produção da vitória ou derrota</i></b>. <br></br><br></br>
                Para que haja ganho ou o menor prejuízo possível, é necessário selecionar um profissional altamente qualificado. Infelizmente, eles são difíceis de serem localizados e custam caro. <br></br><br></br>
                Encontrar um advogado através de alguma indicação, é um caminho para selecionar alguém qualificado. Porém, deve-se tomar muito cuidado nessa operação. É necessário verificar se o profissional indicado é especializado em justiça familiar, e se possui um índice de vitórias alto ou no mínimo razoável. <br></br><br></br>
                É fundamental rejeitar os advogados inexperientes, baratos e/ou que fazem de tudo. Aqueles que fazem de tudo não são especializados em nada. Ninguém deve colocar o destino de sua vida e de seus processos nas mãos de qualquer profissional que aparecer. Principalmente quando sua competência e atuação são desconhecidas. <br></br><br></br>
                É fundamental rejeitar parentes que exerçam essa atividade, para evitar problemas familiares ou constrangimentos quando precisar descartá-los. <br></br><br></br>
                Tenha em mente que nenhum deles sofrerá qualquer consequência das ações tomadas, e eles sempre serão pagos, independentemente dos resultados obtidos.<br></br><br></br>
                <i><b>Jamais, sob nenhuma circunstância, procure um advogado para se aconselhar sobre a decisão de realizar ou não um divórcio.</b></i><br></br><br></br>
                                 
                {click1 ? <><S.AlertButton><button onClick={ handleClick1 } >Fechar Alerta</button></S.AlertButton><PopUp id="msg01"></PopUp></> : <><S.AlertButton><button onClick={ handleClick1 } >Abrir Alerta</button></S.AlertButton></> }
                
                <br></br><br></br>
                Procure um profissional qualificado, <u><b>APENAS</b></u> quando esse procedimento já tiver sido decidido e não haja mais retorno. 

                <br></br><br></br><br></br><br></br>
                <h2>Juiz de Direito</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide16} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                É a pessoa selecionada pelo Estado para intermediar e resolver o conflito existente entre as partes. <br></br><br></br>
                Ele irá ouvir os envolvidos e suas testemunhas, bem como estudar e avaliar os documentos apresentados de forma imparcial, e de acordo com as normas e leis vigentes. Para realizar essa tarefa, ele possui poderes administrativos e judiciais. Os administrativos regulam o andamento do processo, como: expulsar qualquer indivíduo da sala de audiência, mandar prender testemunhas e etc. 
                Os judiciais regulam o andamento do processo, como: solicitar documentos e esclarecimentos, nomear peritos, emitir mandados, emitir veredito e etc. <br></br><br></br>
                O Juiz sempre deverá praticar à igualdade de tratamento das partes, e dentro do possível solucionar o problema o mais rápido possível. Também deverá exigir o cumprimento dos seus despachos e evitar atos processuais desnecessários.<br></br><br></br>
                A atividade jurisdicional não é concentrada unicamente no juiz, é composta por órgãos e auxiliares que juntos compõem a estrutura do poder judiciário. Esse poder é dividido entre os Estados e à União. No topo dele encontram-se dois Tribunais: Superior Tribunal de Justiça e o Superior Tribunal Federal. <br></br><br></br>
                Tudo é muito bonito no papel e na teoria. Contudo, na prática tudo é diferente. Já faz tempo que à igualdade não existe na justiça. As leis são severas e destrutivas para apenas um dos lados, e suave e vantajosa para o outro. <br></br><br></br>
                Existem juízes excepcionais e de grande discernimento, mas correspondem a uma restrita minoria. O que predomina são profissionais sem qualquer apatia que vivem em um universo paralelo, além da realidade que nos cerca. Suas decisões são padronizadas, irracionais e apoiadas por leis injustas e destrutivas. 
                Não existe piedade nem humanidade nelas. Eles jamais sofrem as consequências de suas decisões.<br></br><br></br>
                <br></br><br></br>
                <b>Rodízio de Juízes</b><br></br><br></br>
                Os processos ficam mudando de juiz a todo momento. Qualquer processo deveria ser julgado pela mesma pessoa até sua conclusão, mas na prática isso não acontece. <br></br><br></br>
                A justiça considera que os documentos apresentados e tudo que está escrito nos autos é suficiente para à tomada de decisões, podendo ser efetuado por qualquer pessoa selecionada. Isso pode ser benéfico quando ocorre uma troca para um profissional de melhor qualificação. Porém, o inverso disso também poderá ocorrer. <br></br><br></br>
                O tempo de conclusão e a qualidade do resultado serão extremamente prejudicados por causa desse rodízio. O novo juiz terá que estudar e avaliar o caso novamente, poderá requerer outros esclarecimentos ou outras ações, que podem demandar tempo e dinheiro. <br></br><br></br>
                Inclusive, poderá modificar decretos que já foram estabelecidos. Toda empatia que possa ter ocorrido em uma audiência presencial será apagada. Como ele não irá conhecer as partes, qualquer palavra mal escrita ou mal interpretada poderá ser responsável por errôneas conclusões. <br></br><br></br>
                Sem contar que é um trabalho penoso e desgastante, e ninguém irá realiza-lo de boa vontade. Logo, é mais provável que as decisões sejam padronizadas. <br></br><br></br>
                O defensor tem a possibilidade de solicitar no corpo do processo que ele seja julgado por um determinado juiz, por razões de afinidade com os dados do processo. <br></br><br></br>
                Contudo, não fazem isso porque, geralmente à justiça não acata esse pedido ou eles mesmos não querem se indispor com o juiz selecionado do caso. Cabe a você exigir essa ação de seu defensor se considerar que o juiz inicial estava a seu favor ou demonstrava ser justo e competente.
                <br></br><br></br><br></br>
                <b>Agravo de instrumento</b><br></br><br></br>
                Um procedimento que permite recorrer de qualquer decisão expedida por um juiz. O advogado deverá elaborar esse documento explicando os motivos do porque a decisão promulgada não pode ser cumprida pelo réu. <br></br><br></br>
                Esse recurso não será julgado pelo juiz da causa e sim por uma comissão que irá avaliar a decisão e o recurso para verificar a legitimidade da questão levantada. Hoje, o prazo para a sua interposição é de 15 dias.

                <br></br><br></br><br></br><br></br>
                <h2>Tempo</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide17} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                O tempo é abstrato para nós. É algo que não podemos ver nem manipular, apenas podemos sentir seus efeitos. Através dessa percepção, podemos criar mecanismos e medidas para estabelecer a duração de um determinado evento. E com base nos valores obtidos, determinar se eles são aceitáveis ou prejudiciais. <br></br><br></br>
                Quando falamos de justiça, os valores medidos são simplesmente inaceitáveis. <br></br><br></br>
                O tempo que um processo judicial necessita para ser concluído é muito alto. Para se ter uma ideia, o Conselho Nacional de Justiça (CNJ) mapeou o tempo de tramitação dos processos nos tribunais de todo o país. O menor tempo obtido para a execução de um processo desde o momento de sua distribuição inicial até a sentença em primeira instância, foi de um ano e nove meses, e o maior tempo registrado de sete anos. <br></br><br></br>
                A primeira instância é quando alguém entra com uma ação inicialmente na Justiça, e ela segue até o seu primeiro veredito. Quando a sentença do juiz de primeiro grau, não é aceita, qualquer uma das partes pode entrar com um recurso contra a decisão proferida e o processo é transferido para a segunda instância que também demanda tempo considerável para sua conclusão. <br></br><br></br>
                As consequências de toda essa lentidão do judiciário é a produção de enormes prejuízos pessoais e financeiros.<br></br><br></br>
                Quando não há justiça, não há punição. Então, as portas continuam abertas para novas violações e impunidades, gerando novos processos, que irão impactar ainda mais o tempo da justiça, produzindo um círculo infinito de frustrações, deixando vidas amarradas e indefinidas. <br></br><br></br>
                Quando as decisões finalmente chegam, elas são inócuas ou insuficientes para reparar o dano e a dor causada. As vítimas serão as maiores prejudicadas nesse sistema e os infratores os maiores beneficiados. <br></br><br></br>
                É uma total inversão de valores e dos objetivos pelos quais a justiça foi criada. <br></br><br></br>
                <br></br><br></br>
                <b>Processos Baixados</b><br></br><br></br>
                De acordo com a Resolução CNJ n. 76/2009, consideram-se baixados os processos que foram remetidos para as instâncias superiores ou inferiores, arquivados definitivamente, processos em que houve decisões que transitaram em julgado, e onde iniciou-se a liquidação, cumprimento ou execução. 
                <br></br><br></br><br></br>
                <b>Processos Pendentes</b><br></br><br></br>
                São os processos que nunca receberam movimento de baixa, em cada uma das fases analisadas. <br></br><br></br>
                Podem existir situações em que autos já baixados retornam à tramitação sem figurar como um novo processo. 
                Eles não são contabilizados como pendentes, mas estão nessa situação, ou seja, são como fantasmas que irão atrapalhar o andamento da justiça, mas serão imvisíveis nas estatísticas da justiça. <br></br><br></br>
                São os casos de sentenças anuladas na instância superior, de remessas e retornos de autos. Desde 2009, o número de processos pendentes continua aumentando. O crescimento acumulado no período foi de 31,2%, representa um acréscimo de 18,9 milhões de novos processos.
                
                <br></br><br></br><br></br>
                <S.FormChat><img src={chart2} alt=""></img><span>Figura 2 - Volume Processual do Judiciário</span></S.FormChat>

                <br></br><br></br><br></br>
                <b>Fases</b><br></br><br></br>
                São etapas ou estádios que um processo deve cumprir na justiça até sua finalização.<br></br><br></br>
                Duas fases principais do trâmite processual:
                <br></br><br></br>

                <table border="1">
                <tbody>
                <tr><td>&nbsp;<b>Conhecimento</b>&nbsp;</td><td>&nbsp;Fase onde o juiz toma conhecimento dos fatos a partir de provas e testemunhos. <br></br><br></br>&nbsp;É a fase mais complexa e demorada do processo. <br></br><br></br>&nbsp;O juiz deverá avaliar todos os dados disponíveis antes de emitir a sentença.&nbsp;</td></tr>
                <tr><td>&nbsp;<b>Execução</b>&nbsp;</td><td>&nbsp;Fase onde ocorre a execução do processo, ou seja, é o cumprimento da sentença ou título extrajudicial.&nbsp;</td></tr>
                </tbody>
                </table> 

                <br></br><br></br>
                Segundo a análise dos dados, a maior parte dos tribunais apresenta um paradoxo entre essas fases. <br></br><br></br>
                A fase de execução que deveria ser a mais rápida, leva mais do que o triplo do tempo para ser realizada do que a fase do conhecimento que deveria ser a mais complexa e demorada, principalmente por envolver sentenças que irão determinar os destinos das pessoas.<br></br><br></br>

                <br></br><br></br>
                <S.FormChat><img src={chart3} alt=""></img><span>Figura 3 - Tempo das Fases Processuais</span></S.FormChat>
                <br></br><br></br><br></br>

                <b>Medições</b>
                <br></br><br></br>
                <b>2017</b>
                <br></br><br></br>
                O Poder Judiciário brasileiro finalizou o ano de 2016 com 79,7 milhões de processos em tramitação. Em média, a cada grupo de 100 mil habitantes, 12,907 mil ingressaram com uma ação judicial no ano de 2016. 
                Ingressaram 29,4 milhões de novos processos, crescimento de 5,6% em relação a 2015. Foram baixados 29 milhões de processos, crescimento de 2,7% em relação a 2015.<br></br><br></br>
                A taxa de congestionamento, que mede o percentual de processos em tramitação que não baixou durante 2016, permanece alta, com percentual de 73%. Isso quer dizer que foram solucionados apenas 27% de todos os processos. 
                A taxa de congestionamento líquida, que desconsidera casos suspensos, sobrestados ou em arquivo provisório aguardando alguma situação jurídica futura, também se mantém alta, em 69,3%.
                <br></br><br></br><br></br>
                <b>2018</b>
                <br></br><br></br>
                O número de processos em tramitação apresentou o menor crescimento da série histórica, com variação de 0,3% e o Poder Judiciário chegou ao final do ano de 2017 com um acervo de 80,1 milhões de processos que aguardam solução definitiva. 
                Entre 2009 e 2017 houve uma variação acumulada de 31,9% no estoque, correspondendo a um crescimento médio de 4% ao ano.<br></br><br></br>
                Durante o ano de 2017, ingressaram 29,1 milhões processos e foram finalizados 31 milhões, ou seja, o Poder Judiciário decidiu 6,5% a mais de processos do que a demanda de casos novos. 
                Apesar da alta produtividade, não houve redução do estoque processual devido à reativação de casos que já haviam sido arquivados em anos anteriores e retornaram à tramitação no ano de 2017.

                <br></br><br></br><br></br><br></br>
                <h2>Taxas e Custas</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide18} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                <i><b>O dinheiro é a matéria prima da justiça.</b></i><br></br><br></br>
                Não é possível realizar qualquer procedimento sem ele. Ele será obtido das partes envolvidas em um processo judicial, através da cobrança de taxas, custas, multas, alíquotas, emolumentos, ou qualquer outra denominação. <br></br><br></br>
                Essas taxas são elaboradas pela própria justiça e variam de acordo com a região e órgão jurisdicional responsável por sua criação e captação. <br></br><br></br>
                Geralmente, são encapsuladas com medidas de proteção que sempre as mantém acima dos índices inflacionários ou de qualquer perda financeira. 
                Não existe uma uniformização dos sistemas de recolhimento dessas taxas processuais. Cada cenário muda sua denominação e valor exigido. <br></br><br></br>
                Por exemplo, a UFESP, unidade de medida criada em 1989 pela Lei Estadual nº 6.374 para atualizar os valores de contratos, de tributos e de impostos, tanto da cidade quanto do Estado de São Paulo.
                Em outros Estados existem outras taxas com outros valores, regras e denominações. <br></br><br></br>
                São criadas por leis específicas com o objetivo de torná-las legais e cabíveis de utilização. São aparelhadas com variados mecanismos de cobrança e punição que visam impedir sua sonegação e tornar sua coleta mais eficiente. <br></br><br></br>
                Esses encargos nunca prescrevem e sempre sofrem a incidência de multa, juros e diferentes tipos de correção. Onde pequenos valores podem se transformar em números da ordem de milhares ou milhões em poucos meses ou anos, superando qualquer aplicação financeira existente no planeta. <br></br><br></br>
                <i><b>Resumindo, a justiça utiliza a sua própria moeda, cria os encargos de que precisa através de leis exclusivas.</b></i> 
                <br></br><br></br><br></br>
                <b>Justiça Gratuita</b><br></br><br></br>
                Toda pessoa natural ou jurídica, brasileira ou estrangeira, com insuficiência de recursos para pagar as custas, as despesas processuais e os honorários advocatícios têm direito à justiça gratuita, conforme as leis atuais:
                <br></br><br></br>

                <table border="1" >
                <tbody>
                <tr><td><b>justiça gratuita </b></td><td>a lei dispensa o jurisdicionado do pagamento de custas, taxas e emolumentos em razão de sua situação de hipossuficiência (arts. 98 a 102 do CPC/2015). Em outras palavras, a Justiça gratuita é a isenção de taxa judiciária e custas do processo.&nbsp;</td></tr>
                <tr><td><b>assistência jurídica gratuita </b></td><td>a obrigação do Estado de fornecer um advogado (defensor público) para aqueles que desejem ingressar no Judiciário (art. 134 CF/88). Em outras palavras, assistência jurídica gratuita consiste na defesa dos direitos dos hipossuficientes financeiros, daqueles que não podem custear honorários advocatícios.&nbsp;</td></tr>
                </tbody>
                </table> 

                <br></br><br></br>
                A declaração de hipossuficiência, também conhecida como declaração de pobreza, é um documento usado para comprovar que uma pessoa não tem condições financeiras suficientes para arcar com as despesas judiciais.<br></br><br></br>
                Cabe ao juiz decidir se concede ou não esse direito. Caso haja deslealdade no pedido de atendimento jurídico gratuito, o solicitante está sujeito à condenação ao pagamento de multas que podem chegar a até dez vezes o valor das despesas existentes. <br></br><br></br>
                Toda pessoa com renda familiar menor que o limite de isenção do Imposto de Renda, tem direito à assistência jurídica gratuita. <br></br><br></br>Em casos especiais como: o indivíduo comprovar gastos extraordinários, como pensão alimentícia e medicamentos, por exemplo, o serviço gratuito também poderá ser concedido.
                <br></br><br></br>
                <u>A gratuidade da justiça aplica-se</u>:
                <br></br><br></br>

                <table border="1" >
                <tbody>
                <tr><td>&nbsp;as taxas ou as custas judiciais.&nbsp;</td></tr>
                <tr><td>&nbsp;os selos postais.&nbsp;</td></tr>
                <tr><td>&nbsp;as despesas com publicação na imprensa oficial, dispensando-se a publicação em outros meios.&nbsp;</td></tr>
                <tr><td>&nbsp;a indenização devida à testemunha que, quando empregada, receberá do empregador salário integral, como se em serviço estivesse.&nbsp;</td></tr>
                <tr><td>&nbsp;as despesas com a realização de exame de código genético - DNA e de outros exames considerados essenciais.&nbsp;</td></tr>
                <tr><td>&nbsp;os honorários do advogado e do perito e a remuneração do intérprete ou do tradutor nomeado para apresentação de versão em português de documento redigido em língua estrangeira.&nbsp;</td></tr>
                <tr><td>&nbsp;o custo com a elaboração de memória de cálculo, quando exigida para instauração da execução.&nbsp;</td></tr>
                <tr><td>&nbsp;os depósitos previstos em lei para interposição de recurso, para propositura de ação e para a prática de outros atos processuais inerentes ao exercício da ampla defesa e do contraditório.&nbsp;</td></tr>
                <tr><td>&nbsp;os emolumentos devidos a notários ou registradores em decorrência da prática de registro, averbação ou qualquer outro ato notarial necessário à efetivação de decisão judicial ou à continuidade de processo judicial no qual o benefício tenha sido concedido.&nbsp;</td></tr>
                </tbody>
                </table> 

                <br></br><br></br><br></br>
                <b>Valor da causa</b>
                <br></br><br></br>
                Todo processo deverá possuir um valor atribuído a ele, mesmo que a causa não envolva valores econômicos. Um valor pretendido ou estimado deverá será calculado e informado. Esse valor será utilizado pela justiça para atribuir multas, taxas, custas ou qualquer encargo necessário, bem como determinar competência de juízo, foros e etc.  
                <br></br><br></br>
                <u>Com base no novo CPC, temos</u>:
                <br></br><br></br>
                Art. 291. A toda causa será atribuído valor certo, ainda que não tenha conteúdo econômico imediatamente aferível.<br></br><br></br>
                Art. 292. O valor da causa constará da petição inicial ou da reconvenção e será:
                <br></br><br></br>

                <table border="1" >
                <tbody>
                <tr><td>&nbsp;na ação de cobrança de dívida, a soma monetariamente corrigida do principal, dos juros de mora vencidos e de outras penalidades, se houver, até a data de propositura da ação.&nbsp;</td></tr>
                <tr><td>&nbsp;na ação que tiver por objeto a existência, a validade, o cumprimento, a modificação, a resolução, a resilição ou a rescisão de ato jurídico, o valor do ato ou o de sua parte controvertida.&nbsp;</td></tr>
                <tr><td>&nbsp;na ação de alimentos, a soma de 12 (doze) prestações mensais pedidas pelo autor.&nbsp;</td></tr>
                <tr><td>&nbsp;na ação de divisão, de demarcação e de reivindicação, o valor de avaliação da área ou do bem objeto do pedido.&nbsp;</td></tr>
                <tr><td>&nbsp;na ação indenizatória, inclusive a fundada em dano moral, o valor pretendido.&nbsp;</td></tr>
                <tr><td>&nbsp;na ação em que há cumulação de pedidos, a quantia correspondente à soma dos valores de todos eles.&nbsp;</td></tr>
                <tr><td>&nbsp;na ação em que os pedidos são alternativos, o de maior valor.&nbsp;</td></tr>
                <tr><td>&nbsp;na ação em que houver pedido subsidiário, o valor do pedido principal.&nbsp;</td></tr>
                </tbody>
                </table> 

                <br></br><br></br>
                § 1o Quando se pedirem prestações vencidas e vincendas, considerar-se-á o valor de umas e outras.<br></br><br></br>
                § 2o O valor das prestações vincendas será igual a uma prestação anual, se a obrigação for por tempo indeterminado ou por tempo superior a 1 (um) ano, e, se por tempo inferior, será igual à soma das prestações.<br></br><br></br>
                § 3o O juiz corrigirá, de ofício e por arbitramento, o valor da causa quando verificar que não corresponde ao conteúdo patrimonial em discussão ou ao proveito econômico perseguido pelo autor, caso em que se procederá ao recolhimento das custas correspondentes.<br></br><br></br>
                Art. 293. O réu poderá impugnar, em preliminar da contestação, o valor atribuído à causa pelo autor, sob pena de preclusão, e o juiz decidirá a respeito, impondo, se for o caso, a complementação das custas.


                <br></br><br></br><br></br><br></br>
                <h2>Leis</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide19} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>
                A vida em sociedade necessita de regras de conduta que disciplinem as interações entre as pessoas com o objetivo de alcançar um bem comum. <br></br><br></br>
                É função do Estado, regular os indivíduos de uma sociedade através de um conjunto de normas escritas e desenvolvidas para essa finalidade. <br></br><br></br>
                A esse conjunto damos o nome de <u><b>LEI</b></u>. <br></br><br></br>
                Elas podem variar de acordo com o período histórico, região geográfica e costumes de uma sociedade. Elas devem ser administradas pelo Estado que deve assegurar que todos os membros que ele representa sejam <u>iguais em direitos e obrigações</u>. 
                Então, a justiça pode ser definida como o cumprimento das leis de uma sociedade.<br></br><br></br> 
                Porém na prática a realidade é bem diferente. Muitas leis estão ultrapassadas e não atendem as necessidades atuais. Muitas delas são <b>ilógicas, irracionais e injustas</b>, provocando danos materiais e pessoais incalculáveis. <br></br><br></br>
                O custo que uma lei acarreta para a sociedade não é medido. Uma nova lei leva no mínimo dez anos até atingir um ponto de pacificação e de interpretação nos tribunais, mas isso não é considerado. <br></br><br></br>
                As leis são criadas e implementadas sem que o seu custo ou impacto seja devidamente calculado.  Uma lei pode estar em desacordo com outra, gerando desgastes financeiros e errôneas interpretações. <br></br><br></br>
                As leis deveriam ser simples e igualitárias, mas atualmente são produzidas apenas para favorecer minorias com elevados custos para o seu funcionamento. Elas estão sendo customizadas para atender e favorecer grupos específicos. 
                Seus criadores estão imunes aos efeitos e consequências dessas leis por estarem em uma situação política e social privilegiada. <br></br><br></br>
                Gastamos bilhões anualmente apenas para manter um sistema que não funciona, e não produz resultados satisfatórios. <br></br><br></br>
                Dinheiro esse que poderia estar sendo empregado em benefício de toda sociedade. Produzindo resultados verdadeiramente satisfatórios, melhorando as condições de vida, gerando mais oportunidades, construindo um ambiente mais justo, pacífico e cordial, diminuindo significativamente os números da violência e criminalidade. <br></br><br></br>
                Mesmo através de números e provas incontestáveis que essa estrutura jurídica gigantesca não funciona, e que provavelmente nunca irá, preferimos manter o silêncio e acreditar que é apenas uma questão de ajuste, de tornar as leis mais rígidas com penas maiores ou de criar novas leis. <br></br><br></br><br></br>
                <i><b> Atualmente, as leis incorporadas no sistema judicial são sexistas e não obedecem aos princípios de igualdade e da presunção da inocência, básicos para a criação de justiça em qualquer Estado Democrático de Direito.</b></i> 
                <br></br><br></br><br></br>

                <table border="1" >
                <tbody>
                <tr><td bgcolor='#EAEAEA' ><b>LEI Nº 13104/2015           <br></br>Feminicídio</b></td><td>Aumenta a pena do homicida caso a vítima do homicídio tenha sido mulher.&nbsp;</td></tr>    
                <tr><td bgcolor='#EAEAEA' ><b>LEI Nº 8.213/1991           <br></br>Benefícios da Previdência Social</b></td><td>Determina tempo de contribuição e idade mínima da mulher para obter aposentadoria inferior ao do homem.&nbsp;</td></tr> 
                <tr><td bgcolor='#EAEAEA' ><b>LEI Nº 11.340/2006          <br></br>Maria da Penha</b></td><td>Amplia a caracterização de atos como violência e dá maiores penas quando a vítima for mulher.&nbsp;</td></tr> 
                <tr><td bgcolor='#EAEAEA' ><b>LEI Complementar Nº 150/2015<br></br>Trabalho doméstico</b></td><td>Rescisão por culpa do empregador quando uma violência for feita contra uma mulher.&nbsp;</td></tr> 
                <tr><td bgcolor='#EAEAEA' ><b>LEI Nº 5.948/2006           <br></br>Tráfico de Pessoas</b></td><td>Aumenta a punição caso a pessoa traficada seja mulher e prevê maior assistência a mulher.&nbsp;</td></tr>                      
                <tr><td bgcolor='#EAEAEA' ><b>LEI Nº 13.239/2015          <br></br>Reparo aos danos causados por violência</b></td><td>Esta Lei dispõe sobre a oferta e a realização, no âmbito do Sistema Único de Saúde - SUS, de cirurgia plástica reparadora de sequelas de lesões causadas por atos de violência contra a mulher.&nbsp;</td></tr> 
                <tr><td bgcolor='#EAEAEA' ><b>LEI Nº 10.778/2003          <br></br>Notificação compulsória</b></td><td>Estabelece a notificação compulsória do caso de violência contra a mulher que for atendida em serviços de saúde públicos ou privados.&nbsp;</td></tr>                                                   
                <tr><td bgcolor='#EAEAEA' ><b>LEI Nº 11.977/2009          <br></br>Programa minha Casa Minha Vida</b></td><td>Dá prioridade e preferência de efetivação de contratos e registros em nome da mulher.&nbsp;</td></tr>                   
                <tr><td bgcolor='#EAEAEA' ><b>LEI Nº 11.804/2008          <br></br>Alimentos Gravídicos</b></td><td>Determina que despesas referentes a alimentação especial, assistência médica e psicológica, exames complementares, internações, parto, medicamentos e demais prescrições preventivas e terapêuticas indispensáveis deverão ser custeadas pelo <u>hipotético pai</u>.&nbsp;</td></tr> 
                <tr><td bgcolor='#EAEAEA' ><b>LEI Nº 11.489/2007          <br></br>Institui data comemorativa</b></td><td>Institui o dia 6 de dezembro como o Dia Nacional de Mobilização dos Homens pelo Fim da Violência contra as Mulheres.&nbsp;</td></tr>     
                <tr><td bgcolor='#EAEAEA' ><b>LEI Nº 10.836/2004          <br></br>Programa Bolsa Família</b></td><td>Determina que o pagamento do benefício seja feito preferencialmente à mulher.&nbsp;</td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>LEI Nº 4.375/1964           <br></br>Serviço Militar</b></td><td>Prevê obrigatoriedade de prestação de serviço militar do homem e isenta a mulher do serviço militar.&nbsp;</td></tr>
                </tbody>
                </table>

                <br></br><br></br><br></br><br></br>
                <b>Princípio da Igualdade</b>
                <br></br><br></br>
                A Constituição Federal de 1988 dispõe em seu artigo 5º, caput, sobre o princípio constitucional da igualdade, perante a lei, nos seguintes termos: 
                <br></br><br></br>
                Artigo 5º. Todos são iguais perante a lei, sem distinção de qualquer natureza, garantindo-se aos brasileiros e aos estrangeiros residentes no País a inviolabilidade do direito à vida, à liberdade, à igualdade, à segurança e à propriedade, nos termos seguintes. 
                <br></br><br></br>
                I - Homens e mulheres são <b><u>IGUAIS</u></b> em direitos e obrigações, nos termos desta Constituição;<br></br>
                <b>OBS:</b><i> As leis relacionadas anteriormente, não seguem esse princípio.</i>

                <br></br><br></br><br></br>
                <b>Princípio da presunção da inocência</b>
                <br></br><br></br>
                É um princípio jurídico de ordem constitucional, previsto pelo artigo 5º, inciso LVII, da Constituição Federal, que preceitua que ninguém será considerado culpado até o trânsito em julgado de sentença penal condenatória.  <br></br><br></br>
                O acusado deve ser tratado como inocente durante todo o decorrer do processo, e o encargo de provar as acusações que pesarem sobre o acusado é inteiramente do acusador, não se admitindo que recaia sobre o indivíduo acusado o ônus de provar a sua inocência. 
                Trata-se de uma garantia individual fundamental e lógica do Direito.

                <br></br><br></br><br></br>
                <b>Leis Mediúnicas</b>
                <br></br><br></br>

                Vivemos em um país espiritualista, onde pessoas portadoras de talentos especiais são capazes de realizar e produzir feitos incríveis que não podem ser explicados pela ciência convencional, como: prever acontecimentos futuros, mover objetos com a força do pensamento, materialização de artefatos, comunicação com seres de outra dimensão e etc. 
                Inclusive, uma carta psicografada já foi aceita como prova em uma audiência criminal. <br></br><br></br>
                Diante de fatos incontestáveis como esses, à justiça de forma <i>"sensata"</i> elaborou e implementou leis que possam punir aqueles que <u><b>NÃO UTILIZAM ESSES PODERES</b></u> em benefício de seus filhos e dependentes. <br></br><br></br>
                Por exemplo, em um processo de divórcio quando não é possível calcular o valor da pensão devido a diferentes fatores como estar desempregado, ser autônomo ou outras razões, o Juiz decreta um valor temporário a ser pago, normalmente baseado no salário mínimo, até que o valor definitivo possa ser calculado. <br></br><br></br>
                Caso o valor decretado seja maior que o valor temporário pago, o alimentado tem o direito de receber a diferença relativa aos meses anteriores em que recebeu um valor menor. O inverso não é verdadeiro. <br></br><br></br>
                Como era obrigação do alimentante ter utilizado seus poderes mediúnicos e adivinhado qual seria o valor definitivo calculado pelo Juiz, e não o fez. Então, ele deverá ser duramente <u><b>PENALIZADO</b></u> e devolver essa diferença com juros, correção e <u><b>MULTA</b></u> correspondente a essa penalidade. <br></br><br></br>
                O juiz que decretou o valor temporário jamais deverá ser penalizado, nem sofrer as consequências de sua decisão anterior, que foi cumprida pelo alimentante. <br></br><br></br>
                A culpa deverá recair sobre o alimentante que utilizou o valor originalmente decretado. Afinal ele foi omisso na utilização de seus poderes e deveria ter tido uma premonição do que iria acontecer. <br></br><br></br>
                Apesar do sarcasmo, o exemplo é real e as leis mediúnicas realmente existem, estão disfarçadas em leis que parecem sérias, mas que na verdade apenas prejudicam as pessoas sem qualquer racionalidade ou benevolência, exigindo delas ações e atitudes que não podem ser reproduzidas no mundo real.<br></br><br></br>

                <br></br><br></br><br></br><br></br>
                <h2>Cerimonial</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide20} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                A justiça possui uma linguagem própria de comunicação e comportamento. <br></br><br></br>
                Existem rituais e atitudes que devem ser utilizados entre seus componentes. É fundamental conhecê-los para saber como se comportar e agir diante desses elementos. <br></br><br></br>
                Alguns processos exigem a presença das partes diante do juiz, enquanto outros não. Todos os processos deveriam ser presenciais. É muito cômodo, legislar sem a presença das partes, não ser afetado por seus olhares ou apelos, nem tampouco observar suas reações diante de sentenças, sejam elas injustas ou não.  <br></br><br></br>
                A maioria dos processos da Justiça de Família são compostos apenas de linguagem escrita que são trocadas entre juízes e advogados. A verdade não tem importância, e não é obtida como base nos depoimentos ou reações presenciais. <br></br><br></br>
                No papel cada um pode ser vítima ou vilão, tudo dependerá do que está escrito e do imaginário de quem escreve ou lê. 
                Normalmente, os personagens dessas histórias já são conhecidos, bem como seu enredo e final. Mas os primeiros capítulos dela são realizados com a presença das partes e de seus representantes. <br></br><br></br>
                Infelizmente, a justiça nivela seus profissionais por baixo. A maioria deles não precisa saber falar, nem possuir qualquer eloquência que possa modificar o destino de seus clientes.<br></br><br></br>
                Geralmente, não dizem nada, apenas fazem alguns questionamentos ou respondem algumas perguntas de praxe. Não irão defendê-los a todo custo, nem se indispor com o juiz. <br></br><br></br>
                Será uma execução silenciosa, uma vez que as partes não podem se manifestar, apenas seus defensores, que como já foi dito, nada dizem. <br></br><br></br>
                Procedimentos a serem seguidos: 
                <br></br><br></br>

                <table border="1" >
                <tbody>
                <tr><td bgcolor='#EAEAEA' ><b>Pontualidade&nbsp;&nbsp;&nbsp;</b></td><td>Se informar com o advogado o local e horário da audiência. <br></br>Solicitar via e-mail para ter essa informação documentada. <br></br>Chegar no local com antecedência, se possível uma hora antes.</td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>Material</b></td><td>Você será revistado na entrada do fórum, onde ocorrerá a audiência. <br></br>Não leve nenhum tipo de arma, como: faca, canivete, tesoura e etc. <br></br>Não leve nada que possa compromete-lo de alguma forma. <br></br>É obrigatório levar algum documento de identificação com foto.</td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>Aparência</b></td><td>Utilize roupa simples, limpa e formal. <br></br>Evite dar sinais de riqueza ou ostentação. <br></br>Esteja limpo, cabelo penteado e barba feita. <br></br>Leve panos umedecidos para tirar o suor do rosto antes de entrar na sala de audiência, e demais acessórios necessários para cuidar de sua apresentação.</td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>Local</b></td><td>O seu advogado irá informar onde deverá sentar. O cerimonial exige que o acusador sente a direita, e o acusado ou réu sente a esquerda do juiz.</td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>Postura</b></td><td>Fale apenas quando for chamado. <br></br>Mantenha uma postura reta. <br></br>Nunca abaixe a cabeça. <br></br>Olhe diretamente nos olhos das pessoas. <br></br>Não desvie o olhar de quem pergunta. <br></br>Fale de forma firme e serena. <br></br>Não utilize gírias nem palavrões ao falar. <br></br>Sempre utilize o título pelo qual o juiz quer ser chamado. <br></br>Se não souber, utilize <u>excelência ou senhor/senhora</u>, mas nunca deixe de utilizar essas palavras. <br></br>Utilize um linguajar simples e direto, não tente enfeita-lo. <br></br>Não gagueje. Se acontecer, respire fundo e fale pausadamente. <br></br>O advogado da parte poderá fazer acusações infundadas ou tentar tirá-lo do sério. <br></br>Não entre nesse jogo, não demonstre reação, retruque de forma tranquila.<br></br>Fale a verdade sempre. <br></br>Caso seja necessário mentir, cuidado com a <b>linguagem corporal</b>.</td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>Check List</b></td><td>Sempre leve uma lista com todos os itens que devem ser tratados na audiência. <br></br>Não saia dela sem que todos eles tenham sido discutidos, com data de conclusão e penalidades definidas. <br></br>Devem estar descritos e concluídos na sentença que será emitida pelo juiz ao final da audiência. <br></br><br></br><b>Nunca deixe de fazer isso</b>. <br></br><br></br>Eles sempre são esquecidos, e acabam deixando muitas brechas na sentença. <br></br>Não se preocupe com o tempo da audiência. <br></br>É uma situação constrangedora e queremos que a audiência acabe logo.<br></br>Leve o tempo que achar necessário. É sua vida que está em jogo.<br></br></td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>Defesa</b></td><td>Se o advogado for inapto, quebre todo protocolo. <br></br>Esqueça toda orientação descrita e faça a própria defesa. <br></br>Apenas você sofrerá as consequências dessa audiência e ninguém mais. <br></br>Se necessário, lute para sobreviver, jamais fique calado.</td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>Linguagem</b></td><td>A justiça utiliza uma linguagem técnica que pode confundir as pessoas comuns que não estão acostumadas com ela. <br></br>É interessante conhecê-la para poder se defender. <br></br>Além disso, a justiça ocidental é baseada na cultura greco-romana. <br></br>Por causa disso muitos termos estão escritos em Latim, dificultando seu entendimento.</td></tr>
                </tbody>
                </table>
                <br></br><br></br>

                {click2 ? <><S.AlertButton><button onClick={ handleClick2 } >Fechar Alerta</button></S.AlertButton><PopUp id="msg02"></PopUp></> : <><S.AlertButton><button onClick={ handleClick2 } >Abrir Alerta</button></S.AlertButton></> }
                
                <br></br><br></br>
                <b>Linguagem Corporal</b><br></br><br></br>
                <u>Sinais corporais que indicam uma mentira</u>:<br></br><br></br> 

                <table border="1" >
                <tbody>
                <tr><td>&nbsp;Tapar ou colocar a mão na boca quando fala.&nbsp;</td></tr>
                <tr><td>&nbsp;Evitar contato visual.&nbsp;</td></tr>
                <tr><td>&nbsp;Comprimir os lábios.&nbsp;</td></tr>
                <tr><td>&nbsp;Olhar para cima e para direita.&nbsp;</td></tr>
                <tr><td>&nbsp;Franzir a testa.&nbsp;</td></tr>
                <tr><td>&nbsp;Ficar estático. Restringir os movimentos de mãos e braços.&nbsp;</td></tr>
                <tr><td>&nbsp;Ter movimentos rígidos e repetitivos.&nbsp;</td></tr>
                <tr><td>&nbsp;Encolher a cabeça.&nbsp;</td></tr>
                <tr><td>&nbsp;Trocar muitas vezes o próprio corpo.&nbsp;</td></tr>
                <tr><td>&nbsp;Encolher apenas um dos ombros.&nbsp;</td></tr>
                <tr><td>&nbsp;Se afastar do interlocutor.&nbsp;</td></tr>
                </tbody>
                </table> 

                <br></br><br></br>
                <b>Linguagem Judicial</b><br></br><br></br>
                <u>Glossário de alguns termos técnicos utilizados</u>:<br></br><br></br>

                <table border="1" >
                <tbody>
                <tr><td bgcolor='#EAEAEA' ><b>ABANDONO MATERIAL         </b></td><td>Falta de assistência ou de fornecimento de recursos à subsistência da pessoa de que se tem o dever de sustentar ou manter.</td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>ABANDONO MORAL            </b></td><td>Ausência, indiferença, falta de afeto ou de cuidado com a pessoa responsável.</td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>AÇÃO                      </b></td><td>Direito de demandar ou pleitear em juízo, perante os tribunais, qualquer coisa que seja devida.</td></tr> 
                <tr><td bgcolor='#EAEAEA' ><b>AÇÃO RESCISÓRIA           </b></td><td>Ação que visa anular uma decisão judicial transitada em julgado substituindo-a por outra.</td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>AGRAVADO                  </b></td><td>Parte recorrida no recurso de agravo.</td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>AGRAVANTE                 </b></td><td>Parte que recorre no recurso de agravo.</td></tr> 
                <tr><td bgcolor='#EAEAEA' ><b>AGRAVO                    </b></td><td>Recurso contra decisão interlocutória ou contra despacho de juiz ou membro de tribunal.</td></tr> 
                <tr><td bgcolor='#EAEAEA' ><b>AGRAVO DE INSTRUMENTO     </b></td><td>Recurso contra os despachos de juízes de primeiro grau.</td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>ALIMENTOS                 </b></td><td>Nome dado sob o ponto de vista jurídico para a pensão alimentícia.</td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>APELAÇÃO                  </b></td><td>Recurso contra sentença proferida em primeiro grau que extingue o processo.</td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>APELADO                   </b></td><td>Para quem se recorre (apela) por não aceitar sua decisão.</td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>APELANTE                  </b></td><td>Parte que apela.</td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>AUDIÊNCIA                 </b></td><td>Uma reunião solene, presidida pelo juiz, para realização de atos processuais.</td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>AUTOR                     </b></td><td>Uma das partes do processo, em contraposição ao réu. É quem propôs a ação para ver seu direito reconhecido.</td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>AUTOS                     </b></td><td>Conjunto ordenado das peças de um processo judicial.</td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>BAIXA DOS AUTOS           </b></td><td>Devolução dos arquivos e documentos de um processo ao cartório.</td></tr> 
                <tr><td bgcolor='#EAEAEA' ><b>CARTÓRIO OU VARA JUDICIAL </b></td><td>Local onde são praticados os atos judiciais relativos ao processamento e procedimento dos feitos civis e criminais.</td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>CITAÇÃO                   </b></td><td>Chamamento a juízo do réu ou do interessado, a fim de que defenda seus direitos e interesses.</td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>DECISÃO                   </b></td><td>Pronunciamento do juiz com o resultado da questão.</td></tr> 
                <tr><td bgcolor='#EAEAEA' ><b>DECLINAR DA COMPETÊNCIA   </b></td><td>Quando não há competência do órgão para decidir sobre o discutido no processo.</td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>DESEMBARGADOR             </b></td><td>Nome dado a juízes de segunda instância.</td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>DESPACHO                  </b></td><td>São todos os atos do juiz que não sejam sentença nem decisões interlocutórias.</td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>EFEITO SUSPENSIVO         </b></td><td>Sentença que não pode ser executada até que o recurso seja julgado.</td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>EMBARGOS                  </b></td><td>Autorização legal para suspender um ato.</td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>EMOLUMENTOS               </b></td><td>São as taxas devidas ou cobradas por serviços prestados.</td></tr>	
                <tr><td bgcolor='#EAEAEA' ><b>FORO JUDICIAL             </b></td><td>Local público onde funciona a justiça.</td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>IMPEDIMENTO               </b></td><td>Circunstância que impossibilita o juiz de exercer legalmente sua jurisdição em determinado momento ou em relação a determinada causa.</td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>IMPETRADO                 </b></td><td>Designação do réu num mandado de segurança.</td></tr> 
                <tr><td bgcolor='#EAEAEA' ><b>IMPETRANTE                </b></td><td>Designação do autor no mandado de segurança.</td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>IMPETRAR                  </b></td><td>Ajuizar o mandado de segurança ou habeas corpus.</td></tr> 
                <tr><td bgcolor='#EAEAEA' ><b>IMPUTAÇÃO                 </b></td><td>Acusar alguém por meio de queixa crime ou denúncia do órgão público pela prática de um delito.</td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>IMPUTÁVEL                 </b></td><td>Aquele que pode receber acusação por meio de queixa crime ou denúncia do órgão público pela prática de um delito a partir dos 18 anos de idade.</td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>ININPUTÁVEL               </b></td><td>Aquele que não pode ser responsabilizado por delitos cometidos.</td></tr> 
                <tr><td bgcolor='#EAEAEA' ><b>INSTÂNCIA                 </b></td><td>Grau de jurisdição na hierarquia judiciária.</td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>INTIMAÇÃO                 </b></td><td>Ato judicial pelo qual se notifica determinada pessoa dos termos ou atos de um processo.</td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>JUIZADO                   </b></td><td>Designa o cargo ou ofício do juiz.</td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>JURISDIÇÃO                </b></td><td>Poder de um órgão de aplicar o direito, utilizando a força do Estado para que suas decisões sejam eficazes.</td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>LIDE                      </b></td><td>Trata-se do conflito de interesses manifestado em juízo. É o processo judicial.</td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>LITIGANTE                 </b></td><td>Quem é parte de um processo judicial.</td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>MAGISTRATURA              </b></td><td>Corpo de juízes que constitui o Poder Judiciário.</td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>MANDADO                   </b></td><td>Trata-se de uma ordem emitida por juiz que deve ser cumprida.</td></tr> 
                <tr><td bgcolor='#EAEAEA' ><b>MEDIADOR                  </b></td><td>Pessoa que age por mediação ou serve de corretor ou intermediário de negócios.</td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>MEDIDA CAUTELAR           </b></td><td>Procedimento que visa evitar prejuízo imediato ou futuro.</td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>MÉRITO                    </b></td><td>Questão ou questões fundamentais de fato ou de direito que constituem o principal objeto da lide.</td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>NOTIFICAÇÃO               </b></td><td>Ordem dada a alguém para que pratique ou deixe de praticar determinado ato, sob pena de poder sofrer ônus previstos na lei.</td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>PACTO ANTENUPCIAL         </b></td><td>Escritura lavrada em cartório, antes da celebração do casamento, quando os noivos escolhem o regime da comunhão universal de bens, onde todos os bens passados e futuros unem-se num único patrimônio, ou da separação total de bens, onde nenhum dos cônjuges tem direito sobre os bens do outro.</td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>PARECER                   </b></td><td>Opinião expressa por assessor jurídico em orientação administrativa.</td></tr> 
                <tr><td bgcolor='#EAEAEA' ><b>PARTE                     </b></td><td>Cada pessoa que figura num processo como autor ou réu.</td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>PAUTA                     </b></td><td>Relação dos processos a serem julgados em órgão de primeiro e segundo grau, afixado no átrio da sede do juízo, para ciência dos interessados.</td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>PETIÇÃO                   </b></td><td>Solicitação escrita dirigida ao juiz ou membro de tribunal.</td></tr> 
                <tr><td bgcolor='#EAEAEA' ><b>PETIÇÃO INICIAL           </b></td><td>Primeiro ato para a formação do processo judicial.</td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>PODER FAMILIAR            </b></td><td>Conjunto de direitos e de deveres que os pais exercem em relação aos filhos menores de 21 anos ou aos portadores de incapacidade mental.</td></tr> 
                <tr><td bgcolor='#EAEAEA' ><b>PRIMEIRA INSTÃNCIA        </b></td><td>Local onde tem início os processos.</td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>PROPONENTE                </b></td><td>Autor de um contrato ou proposta oficial.</td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>RECURSO                   </b></td><td>Meio processual para obter a anulação ou a reforma, total ou parcial, de uma decisão.</td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>REQUERENTE                </b></td><td>Quem propôs a ação para ver seu direito reconhecido.</td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>REQUERIDO                 </b></td><td>Réu da ação, contra o qual o pedido do autor é apresentado.</td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>RÉU                       </b></td><td>Aquele que é chamado em juízo para responder por ação cível ou por crime.</td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>SEGREDO DE JUSTIÇA        </b></td><td>Característica de certos atos processuais, que são desprovidos de conhecimento público, por exigência do decoro ou interesse social.</td></tr> 
                <tr><td bgcolor='#EAEAEA' ><b>SEGUNDA INSTÂNCIA         </b></td><td>Tribunal onde são julgados os recursos dos processos decididos na Primeira Instância.</td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>SENTENÇA                  </b></td><td>Ato do juiz que decide pela condenação ou absolvição do acusado.</td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>SUCUMBÊNCIA               </b></td><td>Parte perdedora da ação, na qual recai o ônus das custas operacionais e honorário de advogado da parte vencedora.</td></tr> 
                <tr><td bgcolor='#EAEAEA' ><b>SUSPEIÇÃO                 </b></td><td>Quando um juiz se declara impedido para julgar determinado processo.</td></tr> 
                <tr><td bgcolor='#EAEAEA' ><b>TRÂNSITO EM JULGADO       </b></td><td>Situação da sentença que se tornou indiscutível, que não cabe mais recurso.</td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>ÚLTIMA INSTÂNCIA          </b></td><td>Final de um processo onde a decisão não cabe mais recurso, salvo o extraordinário, na forma da lei.</td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>VALOR DA CAUSA            </b></td><td>Valor que o autor dá à causa. Determina a velocidade e as custas do processo.</td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>VARA                      </b></td><td>Jurisdição de um juiz, correspondendo a um tribunal ou a um desdobramento de um tribunal.</td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>VISTA                     </b></td><td>Direito de tomar conhecimento de tudo que está contido no processo.</td></tr> 
                </tbody>
                </table>

                <br></br><br></br>
                </span>
            </S.FormText>

            </S.Form>
            </>
            :
            <>
            <br></br>
            <br></br>
            <h3>&nbsp;</h3>
            <br></br>
            </>    }  
        </S.Container>
    )
}

export default GuidePart3;