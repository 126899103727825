
import React, { useEffect, useState } from 'react';   
import * as S       from './styles';
import guide35      from '../../../../view/DivorceGuide/Guide/Images/guide35.png';
import guide36      from '../../../../view/DivorceGuide/Guide/Images/guide36.png';
import guide37      from '../../../../view/DivorceGuide/Guide/Images/guide37.png';
import mgtow        from '../../../../view/DivorceGuide/Guide/Images/mgtow.png';
import util         from '../../../../Utilities/Util';

function GuidePart8() {

    const [access, setAccess] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)
        setAccess( util.checkGuideAccess() );
    }, [])
    
    return (
        <S.Container>
        { ( access )
            ?
            <>
            <S.Form> 

            <S.FormTitle>
                <span>PARTE8</span><br></br><br></br><br></br><span>Você não está sozinho</span><br></br><br></br>
            </S.FormTitle>

            <S.FormText>
                <span>

                Construir uma família é algo nobre e divino. É o desejo e o sonho de muitos homens e mulheres. <br></br><br></br>
                A família é o pilar central de uma sociedade. <br></br><br></br>
                É através dela que a saúde física e psíquica das pessoas é desenvolvida. <br></br><br></br>
                Ser educado em uma família estruturada e feliz, aumenta significativamente as chances de sucesso na produção de adultos com valores éticos e morais elevados, que irão colaborar e influenciar no desenvolvimento de uma sociedade com essas mesmas características. <br></br><br></br>
                Atualmente, a sociedade moderna vive uma crise de valores sem precedentes, onde a tecnologia disponível potencializa e agrava essa situação ainda mais. Os ideais humanos estão sendo esquecidos e tornando-se sonhos românticos do passado. <br></br><br></br>
                A partir das revoluções culturais e sociais ocorridas no século passado, esses valores começaram a ser combatidos e modificados. As crises econômicas colaboraram intensamente com essas mudanças produzindo uma quebra no núcleo familiar estabelecido. <br></br><br></br>
                Os homens não puderam mais sustentar suas famílias com os seus rendimentos. Essa tarefa teve que ser dividida com suas mulheres que por consequência deixaram de cuidar exclusivamente de seus filhos e passaram a ingressar no mercado de trabalho, predominantemente masculino. <br></br><br></br>
                Um dos primeiros resultados dessa ação foi o aumento da oferta de mão de obra disponível, colaborando ainda mais para a diminuição dos salários e do problema descrito. <br></br><br></br>
                As mulheres não estavam aptas para ocupar todos os postos disponíveis e tiveram que ser protegidas e favorecidas com os trabalhos mais leves e seguros, tendência que permanece até os dias atuais, onde os homens ocupam os empregos mais pesados, sujos e perigosos. <br></br><br></br>
                As mulheres começaram a investir em suas carreiras profissionais para conquistar melhores salários e obter independência financeira. Para isso tiveram que priorizar suas carreiras em detrimento de cuidar de seus filhos. <br></br><br></br>
                Eles passaram a ser educados por babás, empregadas domésticas, escola, televisão, internet e etc. Esses novos elementos se tornaram os responsáveis pelo desenvolvimento físico, psíquico e moral desses novos indivíduos. <br></br><br></br>
                A ausência do pai e da mãe foram compensadas com consumo material de toda espécie de inutilidade, como brinquedos, jogos de vídeo game, roupas de grife, e etc. <br></br><br></br>
                Logo, ter algo se tornou mais importante do que ser algo. Isso produziu e produz pessoas tóxicas, fracas e sem valores morais, que por sua vez acabam produzindo filhos com as mesmas deficiências.<br></br><br></br>
                <i><b>É um ciclo repetitivo sem fim que irá terminar apenas com o colapso da sociedade</b></i>.<br></br><br></br>
                Apesar desse trágico cenário, muitos homens ainda continuam sonhando com as ideias iniciais descritas, dedicam a maior parte de suas vidas as famílias que duramente conseguiram construir. <br></br><br></br>
                Trabalham incessantemente para pagar suas contas, não tendo tempo para perceber a realidade ao seu redor, gastando seu pouco tempo livre com coisas inúteis e sem valor. <br></br><br></br>
                As mulheres ao contrário do que foi descrito, não aceitam dividir seus rendimentos de forma igualitária, nem tampouco aceitam em nenhuma circunstância sustentar qualquer homem que seja. <br></br><br></br>
                Diferentemente dos homens, seus rendimentos são desconhecidos e pertencem exclusivamente a elas, e não ao núcleo familiar como acontece com eles. <br></br><br></br>
                Raro são os casos contrários a essa afirmação, que quando ocorrem são provenientes de mulheres de gerações anteriores ou de caráter elevado, herdado de uma educação familiar tradicional. <br></br><br></br>
                Os acontecimentos das últimas décadas elevaram e expuseram a hipergamia feminina, e também produziram inúmeras leis e benefícios exclusivos para as necessidades e bem-estar das mulheres. <br></br><br></br>
                Atualmente, os homens são facilmente descartados e rapidamente transformados em fornecedores eternos ou de longo prazo, até mesmo homens solteiros e sem filhos já podem ser capturados e obrigados a suprir as necessidades femininas.  <br></br><br></br>
                Mesmo sendo descartados e aniquilados nos processos de divórcio ou na aplicação de leis injustas e sexistas, muitos ainda continuam adormecidos diante do cenário relatado. <br></br><br></br>
                Em casos mais graves, procuram o suicídio ou produzem atos de violência que agravam ainda mais sua situação, favorecendo as mulheres e piorando a imagem dos homens na sociedade. <br></br><br></br>
                Outros por sua vez, se vitimizam dificultando sua recuperação, inclusive acreditando serem responsáveis pelo final da união. <br></br><br></br>
                De forma ingênua e romanceada, alguns procuram novos relacionamentos na tentativa de sanar suas frustrações e refazer suas vidas, mas acabam tendo o mesmo final trágico ou pior que o anterior. <br></br><br></br>
                Finalmente, acabam despertando para a realidade ao seu redor e procuram descobrir os verdadeiros responsáveis pelos seus problemas. <br></br><br></br>
                Descobrem que não estão sozinhos e que nada que acontece é fruto da casualidade, do azar ou de escolhas erradas. <br></br><br></br>
                Que são vítimas de ações planejadas, provenientes de grupos ou pessoas malignas e inescrupulosas que se beneficiam com essa situação e com a degradação social em que estamos inseridos.   
                
                <br></br><br></br><br></br>
                <h2>Feminismo</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide35} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                É um movimento político, social e filosófico que luta para libertar as mulheres da opressão masculina existente, através da criação de leis e normas que garantam direitos e oportunidades em todos os setores da sociedade. <br></br><br></br>
                Durante a história humana muitas mulheres sofreram abusos e injustiças. <br></br><br></br>
                Contudo, muitos desses delitos não ocorreram exclusivamente por causa de seu gênero, mas por causa da maldade e ignorância humana existente em ambos os sexos. <br></br><br></br>
                Se fôssemos utilizar o gênero como causa de sofrimento ou injustiça, os homens estariam na frente e jamais seriam ultrapassados. <br></br><br></br>
                É certo que muitas mulheres, lutaram, sofreram e realizaram grandes feitos, não por causa de seu gênero, e sim por causa de seus próprios méritos, sejam eles intelectuais, artísticos, profissionais ou qualquer outro tipo, mas todos adquiridos através de trabalho, esforço e dedicação. <br></br><br></br>
                Do século anterior até a presente data, uma enorme quantidade de literatura disponível procura fortemente demonstrar que as mulheres são desprezadas, maltratadas, descriminalizadas e oprimidas pelos homens. <br></br><br></br>
                Na verdade, desde o período paleolítico, as mulheres são protegidas e sustentadas por eles. <br></br><br></br>
                Eram eles que tinham que andar por dias em um ambiente hostil, repleto de predadores e inúmeros perigos atrás da caça que fornecia a gordura e a proteína necessária para a sobrevivência da comunidade, enquanto elas ficavam em segurança coletando sementes e cuidando dos filhos. <br></br><br></br>
                Era uma vida dura e difícil para ambos, mesmo assim elas ainda levavam enorme vantagem em relação aos seus companheiros. Até mesmo quando a comunidade era atacada e conquistada por grupos rivais, elas ainda tinham a opção de se unir aos vencedores para poder sobreviver, enquanto aos homens restava apenas à morte ou escravidão que fatalmente levaria ao mesmo fim. <br></br><br></br>
                Essa característica se repetiu durante toda evolução humana. As mulheres sempre foram poupadas da necessidade de guerrear, de prestar serviço militar ou de realizar trabalhos pesados e perigosos, independentemente da cultura, região geográfica ou período histórico. <br></br><br></br>
                Essa vantagem persiste até os dias atuais. Elas tem prioridade de salvação em casos de acidentes ou tragédias. São as primeiras a serem libertadas em situações onde existam reféns. Nesses mesmos casos, ainda há homens que se voluntariam para substituí-las, enquanto o inverso jamais é verdadeiro. <br></br><br></br>
                Todo sofrimento e opressão sofrida na história pelas mulheres é infinitamente menor que todo sofrimento e injustiça sofrida pelos homens. <br></br><br></br>
                Além disso, foram os próprios homens que libertaram as mulheres desses períodos de opressão, muitos deram a própria vida na realização dessa tarefa. <br></br><br></br>
                As mulheres tiveram importante participação, mas foram principalmente os homens que lutaram e morreram para construir nossa civilização, e ainda são eles que a mantém funcionando.<br></br><br></br>
                Atualmente, elas utilizam o período de estagnação política e de impasse militar para subjugar e extrair o máximo possível de direitos e benefícios dos homens, seja diretamente através de leis sexistas aplicadas contra eles ou indiretamente através de auxílios concedidos pelo Estado, o qual é alimentado pelos impostos recolhidos na sociedade.<br></br><br></br>
                As feministas querem tudo e um pouco mais, o importante é subjugar os homens e transformá-los em fornecedores e serviçais de seus desejos e necessidades. <br></br><br></br>
                Não querem cumprir com os seus deveres e papéis na sociedade, consideram isso uma imposição do patriarcado, querem fazer o que quiser, terem direitos sem obrigação, liberdade sem responsabilidade, poder cometer barbaridades sem punição, não dando esses mesmos direitos aos homens. <br></br><br></br>
                Qualquer um que se rebelar ou tentar escapar de seu papel de provedor, será perseguido e destruído pela justiça ou pela própria sociedade. O mesmo ocorre com as mulheres que se declaram contrárias a esse movimento, serão rechaçadas, perseguidas e aniquiladas. <br></br><br></br>
                É um movimento contraditório, irracional e incoerente em seus discursos e reinvindicações, sendo um instrumento político planejado e utilizado por grupos perversos e gananciosos. Trata-se de um golpe contra a sociedade estabelecida. <br></br><br></br>
                É um processo irreversível e de proporção global, não pode ser mais interrompido, nem mesmo amenizado. De forma constante, gradativa e imperceptível, novas leis e procedimentos estão sendo criados para beneficiar ainda mais as mulheres. <br></br><br></br>
                Muitos homens nem sequer tem a capacidade de perceber ou reagir contra isso. Foram educados para proteger e servir as mulheres, consideram suas reinvindicações válidas e legitimas. Sacrificam suas vidas pessoais, profissionais, financeiras e até mesmo física a favor delas.
                <br></br><br></br>
                Sendo mais um movimento contrário a moral judaico-cristão, um dos responsáveis pelo desmantelamento da família tradicional e dos valores morais duramente conquistados que construíram nossa civilização. <br></br><br></br>
                É uma ofensa contra todos os homens e mulheres que lutaram e morreram para salvaguardar suas famílias e os direitos e valores duramente conquistados que moldaram tudo que somos hoje. <br></br><br></br>
                
                <br></br><br></br><br></br>
                <h2>O remédio amargo</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide36} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>
                O movimento descrito é como um vírus que se espalha e contamina toda sociedade, principalmente os mais jovens e menos esclarecidos. <br></br><br></br>
                Suas consequências são terríveis e dolorosas, afetam e aniquilam principalmente a parte masculina da população. <br></br><br></br>
                As mulheres tornam-se portadores sãos dessa doença, contaminando e corrompendo outros indivíduos que tiverem contato com suas ideias e reinvindicações. <br></br><br></br>
                Também carregam terríveis sequelas que afetam principalmente seus cérebros, como: irracionalidade, intolerância, incoerência e etc. 
                Porém, nada é mais alterado que sua hipergamia e hedonia que são elevadas a níveis fora da escala. <br></br><br></br>
                Como uma doença epidêmica, deve ser combatida.  
                Contudo, não existe nenhum medicamento ou tratamento que possa curá-la ou combate-la, a não ser através da prevenção. <br></br><br></br>
                É um remédio amargo, principalmente para os mais jovens que não realizam os procedimentos preventivos, sendo facilmente capturados e aniquilados pelas mulheres afetadas por essa doença. <br></br><br></br>
                Infelizmente, ainda existe muita discórdia em toda sociedade sobre essa doença e suas consequências.  
                Muitos acreditam se tratar de algo passageiro e sem importância, outros acham que não serão afetados, e que não precisam se preocupar, outros nem sabem que o problema existe. <br></br><br></br>
                Essas divisões e falta de conhecimento apenas favorecem sua proliferação que já atingiu níveis de pandemia. 
                <br></br><br></br><br></br>
                <b>MRA - Movimento de direitos dos Homens</b>
                <br></br><br></br>
                O movimento de direitos dos homens (em inglês, Men’s Rights Activism – MRA), é uma concentração de pessoas e instituições que tratam de questões sociais e ações governamentais que atingem exclusivamente os homens. <br></br><br></br>
                Seu objetivo é conscientizar e alertar a sociedade sobre os abusos e descriminações sofridas pelos homens através das leis sexistas. <br></br><br></br>
                É descrito por muitos críticos e estudiosos como um movimento de oposição ao feminismo. Muitos deles também descrevem esse movimento como misógino e fundamentado na perda de privilégios, e não de direitos. <br></br><br></br>
                É um movimento duramente criticado e silenciado pela mídia e instituições governamentais. A maioria dos assuntos levantados são malvistos, e não são discutidos pelos meios de comunicação. <br></br><br></br>
                Os homens foram durante anos tachados como opressores das mulheres, por isso movimentos como esse não são levados a sério, e vistos com desconfiança pelo público em geral. <br></br><br></br>
                É uma fonte de informações para quem busca a verdade dos fatos. Muitas mentiras, abusos e atrocidades cometida contra homens são por eles reveladas. <br></br><br></br>
                Contudo, durante seu tempo de existência, esse movimento, bem como seus associados, <b>NÃO produziu qualquer ganho significativo em benefício dos homens</b>.
                <br></br><br></br><br></br>
                <b>BLUE PILL - Pílula Azul</b>
                <br></br><br></br>
                Não é um movimento, é uma classificação. <br></br><br></br>
                Faz referência ao filme “Matrix” onde o herói da história deveria escolher entre duas pílulas, uma azul e outra vermelha. Ao tomar a primeira, voltaria para sua antiga vida acreditando nas mesmas coisas de sempre, enquanto que a outra, mostraria como o mundo real funcionava, modificando para sempre sua vida. <br></br><br></br>
                A maioria dos homens em nossa sociedade é classificado como “Blue Pill” ou Pílula Azul. <br></br><br></br>
                Não sabem a verdade dos fatos e acreditam no que é dito pela mídia e instituições. Tudo para eles está certo e funcionando corretamente. <br></br><br></br>
                Não se preocupam em aprender ou evoluir, não possuem objetivos traçados ou definidos. Agem por impulso e deixam as coisas acontecerem. Aceitam conviver com mulheres tóxicas e opressoras. Aceitam as leis e acreditam na boa fé das instituições. <br></br><br></br>
                Não fazem nenhum questionamento, nem possuem senso crítico, repetem o que seus pais fizeram, e que toda sociedade continua fazendo.
                <br></br><br></br><br></br>
                <b>PURPLE PILL - Pílula Púrpura / Roxa</b>
                <br></br><br></br>
                É uma classificação acima da anterior. 
                Também incorpora uma grande parte da sociedade masculina. <br></br><br></br>
                São os homens tradicionalistas e conservadores. <br></br><br></br>
                Muitos deles já participam do movimento dos direitos dos homens, e estão cientes dos problemas relacionados ao casamento e divórcio. 
                Estão cientes que as coisas estão ruins, que as leis não funcionam e são sexistas.  
                Sabem que muitas mulheres são tóxicas e abusivas. <br></br><br></br>
                Apesar desse conhecimento, ainda acreditam nas instituições, e que elas podem ser corrigidas. 
                Acreditam que irão encontrar mulheres diferentes e especiais. 
                Acham que se utilizarem as leis de forma correta e preventiva estarão protegidos. <br></br><br></br>
                Reduto dos homens do tipo "alfa", que acreditam em seus dotes e capacidades, e que nunca serão traídos ou enganados pelas mulheres. <br></br><br></br>
                Os homens que estão nessa classificação querem continuar jogando com as regras antigas, não se adaptaram para a nova realidade. <br></br><br></br>
                Estão se auto sabotando, é uma questão de tempo até serem prejudicados por uma mulher ou lei sexista.
                <br></br><br></br><br></br>
                <b>RED PILL - Pílula Vermelha</b>
                <br></br><br></br>
                Representa o extremo oposto da classificação azul. <br></br><br></br>
                Apenas um grupo seleto de homens fazem parte dela. <br></br><br></br>
                Eles sabem que tudo está errado e que as instituições estão falidas e não podem ser recuperadas. <br></br><br></br>
                Sabem que a justiça não funciona, e que as leis favorecem apenas as mulheres. <br></br><br></br>
                Que elas se tornaram tóxicas e perigosas, por isso eles não estão mais presos as visões romanceadas dos relacionamentos. <br></br><br></br>
                Não querem mais arriscar nem serem prejudicados. Estão dispostos a se adaptar a essa nova realidade para poder sobreviver. <br></br><br></br>
                Apesar desse conhecimento e postura, muitos ainda são atraídos pelas mulheres, podendo ser seduzidos e enganados com facilidade por elas, consequentemente, cometendo os mesmos erros e sendo capturados pelo sistema.
                <br></br><br></br><br></br>
                <b>MGTOW - Homens Seguindo Seu Próprio Caminho</b>
                <br></br><br></br>

                <table width="100%">
                <tbody>
                <tr>
                <td width="30%">Sigla do inglês para <u>M</u>en <u>G</u>oing <u>T</u>heir <u>O</u>wn <u>W</u>ay, em português: homens seguindo seu próprio caminho.<br></br><br></br>
                Não é um movimento social ou político, é uma filosofia de vida. </td>
                <td width="70%"><S.FormImage><img src={mgtow} alt=""></img></S.FormImage></td>
                </tr>
                </tbody>
                </table>

                <br></br><br></br>
                Não possuem pessoas, grupos ou instituições que os represente. Não reivindicam nada, nem querem modificar nada. Estão espalhados, não se reúnem em clubes, feiras ou congressos.<br></br><br></br>
                São pessoas comuns que não declaram para seus amigos ou familiares a opção de vida que adotaram. <br></br><br></br>
                São pessoas conscientes dos problemas existentes, são os "REDPILL" que se adaptaram a essa nova realidade. <br></br><br></br>
                Sabem que as instituições estão falidas e não irão perder seu tempo tentando corrigi-las. <br></br><br></br>
                E principalmente, não irão dar oportunidade de serem capturados por esse sistema retrogrado e criminoso. <br></br><br></br>
                Não irão alimentar a indústria do divórcio, da pensão alimentícia, da violência doméstica, redes de advogados e outros mecanismos criados para destruir e escravizar os homens e apenas beneficiar as mulheres e grupos correlatos. <br></br><br></br>
                Para isso seguem três regras fundamentais que não podem ser quebradas: 
                <br></br><br></br>
                <b>
                1.	NÃO CASAR;<br></br>
                2.	NÃO MORAR JUNTO;<br></br>
                3.	NÃO ENGRAVIDAR NENHUMA MULHER.
                </b>

                <br></br><br></br>
                Os MGTOWs não são mais seduzidos, nem enganados pelas mulheres. <br></br><br></br>
                Não são dependentes delas, podem passar suas vidas sem elas.<br></br><br></br>
                O máximo que fazem é ter relacionamentos superficiais ou eventuais com alguma delas, mas sempre respeitando as três regras básicas, e não dando oportunidade para serem capturados em algum tipo de armadilha. <br></br><br></br>
                Sabem que os homens foram condicionados desde a infância para desenvolver e prover uma família. <br></br><br></br>
                Quebrar esse condicionamento é algo difícil e doloroso, mas extremamente necessário para a sobrevivência nos dias atuais. <br></br><br></br>
                As instituições estão todas corrompidas e não podem mais ajuda-los. <br></br><br></br>
                Seus direitos não serão mais respeitados, e serão facilmente aniquilados pelas mulheres. Inclusive, os homens já não possuem mais direitos, apenas obrigações. <br></br><br></br>
                Então, não existe motivo para constituir uma família, construir um patrimônio ou ter filhos. Eventualmente, irão perder tudo que construíram, inclusive seus filhos que serão alienados e se tornarão tóxicos para eles. <br></br><br></br>
                É insano alimentar esse sistema, principalmente com suas vidas. Além disso, muitos deles vão mais além. Desejam alimentar todo o sistema político e social criado com o menos possível, e preferivelmente com nada. <br></br><br></br>
                Muitos passam a ter uma vida simples, não sendo seduzidos pelo consumo, acumulando apenas o necessário para sua sobrevivência e conforto. Alguns se afastam dos grandes centros, produzindo seu próprio alimento, proteção e energia, reduzindo ainda mais sua contribuição financeira para o sistema descrito. <br></br><br></br>
                Se esse estilo de vida fosse adotado por 20% dos homens provocaria um colapso na sociedade, haveria uma queda na receita tributária sem precedentes. As instituições não conseguiriam sobreviver, nem sustentar as mulheres com os benefícios assegurados pelas leis. <br></br><br></br>
                Inclusive, mesmo não sendo seu objetivo ou intensão, <u>é o único processo existente que pode combater o feminismo</u>. <br></br><br></br>
                Logo, esse movimento passou a ser perseguido e ridicularizado pelas mulheres e instituições. Os homens que seguem esse movimento são tachados como fracassados, afeminados e qualquer adjetivo que possa rebaixa-los. <br></br><br></br>
                Contudo, como foi explicado, não é um movimento social ou político com líderes e regulamentos. <br></br><br></br>
                É uma postura pessoal adotada por um homem, sendo uma filosofia de vida seguida por ele. Os que seguem esse estilo de vida, não são identificados, nem comentam sobre suas vidas com ninguém, portanto, não podem ser perseguidos. <br></br><br></br>
                Os MGTOWs estão fora desse jogo insano e imundo que foi instalado na sociedade, e que destrói os homens e as famílias que eles construíram. 
                <br></br><br></br>

                É uma filosofia construída em níveis, os principais e mais importantes estão descritos a seguir:
                <br></br><br></br>

                <table border="1" width="100%">
                <tbody>
                <tr><td bgcolor='#000000' width="20%"><b><font color="white">NÍVEL 1</font></b></td><td width="80%">
                Seguem as três regras.<br></br>
                Rejeitam relacionamentos de longo prazo.<br></br>
                Relacionamentos baseados apenas no sexo.<br></br>
                <b>NÃO INTERAGEM COM MÃES SOLTEIRAS</b>. (quarta regra)<br></br>
                </td></tr>
                <tr><td bgcolor='#000000' width="20%"><b><font color="white">NÍVEL 2</font></b></td><td width="80%">
                Rejeitam relacionamentos de longo e curto prazo.<br></br>
                Rejeitam interações com as mulheres, incluindo sexo casual.<br></br>
                Contato mínimo com elas, apenas cordial e profissional.<br></br>
                </td></tr>
                <tr><td bgcolor='#000000' width="20%"><b><font color="white">NÍVEL3</font></b></td><td width="80%">
                Nível 2 que começa a realizar o desengajamento econômico.<br></br>
                Procuram trabalhos informais para evitar tributação.<br></br>
                Procuram estar na faixa de IR mais baixa possível.<br></br>
                Começam a representar empecilhos para os gastos do Estado.<br></br>
                </td></tr>
                <tr><td bgcolor='#000000' width="20%"><b><font color="white">NÍVEL 4 (GHOST)</font></b></td><td width="80%">
                Rejeitam interações com as mulheres e a sociedade.<br></br>
                Minimizam o contato com as pessoas e os meios urbanos.<br></br>
                Tornam-se independentes de alimentos, proteção e energia.<br></br>
                Podem sobreviver sem o auxílio do Estado.<br></br>
                Não sofrem influência de conflitos sociais ou violências urbanas.<br></br>
                Não serão afetados por colapsos sociais, econômicos ou políticos que vierem a ocorrer.<br></br>
                </td></tr>
                </tbody>
                </table>
                <br></br><br></br>

                <br></br><br></br><br></br>
                <h2>Fim de Jogo</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide37} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                O divórcio é um terrível e temível jogo de guerra. Ele separa as pessoas, aniquila com os patrimônios, destrói vidas. É um jogo de cartas marcadas, onde o vencedor já é conhecido. E cada dia que passa suas regras se tornam mais rígidas e injustas. <br></br><br></br>
                É um fenômeno mundial <u><b>IRREVERSÍVEl</b></u>. <br></br><br></br>
                Nele, as famílias são desfeitas e os homens aniquilados e transformados em escravos ou serviçais. Isso tudo é triste, sombrio e horripilante. A maioria das pessoas acreditam que isso é normal. Muitas estão em um sono perpétuo e profundo. Não sabem o que está acontecendo ao seu redor.<br></br><br></br> 
                É necessário muita dor e sofrimento para que despertem para a realidade. Mesmo assim, muitas voltam a dormir. A verdade não é aceita, nem desejada. É mais fácil fechar os olhos para tudo. Mudar as coisas dá muito trabalho e produz muita dor. E ninguém quer isso. É preferível estender essa situação o máximo possível para evitar essa aflição. <br></br><br></br>
                Atualmente, nossa sociedade é movida pelo dinheiro, consumo, individualismo, mentira, medo, violência ou qualquer outro valor degradante. As instituições não funcionam, porque são administradas por pessoas que seguem esses valores. Então, a verdade é algo inconveniente, que dá trabalho, gasta tempo e energia, e nos faz lembrar daquilo que realmente deveríamos ser e fazer. <br></br><br></br>
                E como já foi dito, ninguém quer sentir nenhum desconforto. É preferível o conforto da mentira e da ilusão. É melhor acreditar que as coisas irão mudar, que a justiça funciona e prevalecerá, que o bem vencerá o mal, e assim por diante. <br></br><br></br>
                Mas isso tudo são apenas ilusões criadas por nossa mente que nos faz evitar sofrimentos de curto prazo. E dessa forma, prolongamos nossas dores com o objetivo de torná-las mais diluídas e suportáveis. <br></br><br></br>
                Além disso tudo, nossos olhos também estão fechados por causa de nossas crenças. Estamos cercados de mentiras, e fomos educados e condicionados para aceitá-las como verdades absolutas e não questionáveis. Dessa forma somos como gado, sempre conduzidos por alguém, sem termos a capacidade de seguir nosso próprio caminho. Nem mesmo sabemos que somos livres para fazê-lo. Apenas pessoas excepcionais ou que passaram por uma situação de muita angustia ou sofrimento, podem despertar para essa realidade e perceber tudo isso. <br></br><br></br>
                O divórcio tem esse poder, ele é algo angustiante e doloroso, fazendo muitos homens despertarem. Contudo, apesar do estrago e dor produzida, alguns ainda continuam presos à suas antigas crenças e acabam produzindo os mesmos erros. Ficando em um <u>loop infinito</u> até serem finalmente aniquilados em qualquer outro processo que sofrerem. Tendo nesse momento suas crenças definitivamente abaladas. <br></br><br></br>
                Ao acordarem desse sono profundo, descobrem que as instituições estão falidas. Que as leis são injustas e sexistas. Que são gastos imensos recursos para manter sistemas e instituições que não funcionam. Percebem que as pessoas são ignorantes, desonestas, covardes e imorais. Além de diversos outros desajustes que comprovam que a sociedade atual é irrecuperável. <br></br><br></br>
                Existem muitas pessoas honestas, inteligentes, corajosas, de elevados valores morais, que são responsáveis diretos ou indiretos por impedir o desmantelamento da sociedade. Porém, não podem impossibilitar seu total colapso, nem corrigir os problemas e os desvios morais que se acumulam. <br></br><br></br>
                Muitos que possuem os atributos descritos, acreditam que podem resolver esse caos através da política, da economia ou com novas tecnologias. Infelizmente esses elementos apenas <u>aumentaram e facilitaram</u> à situação descrita, principalmente à tecnologia, que se transformou em uma poderosa arma de controle e alienação. <br></br><br></br>
                Em breve todas as pessoas serão marcadas. Toda individualidade e privacidade ainda existente serão eliminadas. <br></br><br></br>
                Essas mesmas pessoas também acreditam no bom senso dos dirigentes e das instituições. Que tudo é uma questão de tempo para que as virtudes humanas superem a degradação instalada. <br></br><br></br>
                Terrível engano, não existem precedentes em toda história. Nenhum império, governo ou organização se transformou em justa, pacífica ou virtuosa através da aquisição de bom senso por parte de seus componentes. Sempre entraram em colapso ou foram destruídas antes que uma nova surgisse em seu lugar. Nenhum ditador ou dirigente deixou seu cargo por caridade ou altruísmo. Morreram ou foram depostos. Quem está no controle da situação não quer mudanças, nem melhorias, a não ser que seja para aumentar ainda mais seu domínio e poder. <br></br><br></br>
                Logo, é factível afirmar que os níveis de insanidade, degradação e injustiça irão aumentar cada vez mais. E dentro desse contexto os homens serão os mais <u>prejudicados e perseguidos</u>. Terão que sustentar as mulheres direta ou indiretamente, tendo ou não condições físicas ou financeiras de fazê-lo. Os recursos necessários para essa tarefa terão que aparecer por mágica, não importando qual seja a situação do mercado de trabalho ou do cenário político e financeiro existente. <br></br><br></br>
                Não haverá bom senso nem caridade, da mesma forma como não existe hoje. Sob nenhuma circunstância, nenhum político, legislador ou pessoa de influência irá realizar qualquer pronunciamento ou ação contra qualquer lei ou subsidio que beneficia as mulheres e penaliza os homens. Mesmo sendo algo injusto, irracional ou imoral. <br></br><br></br>
                Ficarão inertes sem nada fazer. Caso alguém tenha a coragem ou ousadia em tentar, será silenciado e aniquilado rapidamente. Sendo tachado como misógino ou algo pior, tendo sua carreira política ou profissional arruinada. <br></br><br></br>
                Portanto, não há como reverter esse cenário, ele é imutável. Sendo o movimento feminista o grande vencedor dessa história. Ele conseguiu destruir os valores familiares e sociais conquistados durante séculos. Continuará crescendo e produzindo novas leis, benefícios e reivindicações injustas e insanas sem que ninguém possa detê-lo. <br></br><br></br>
                <i><b>Não existe, e não haverá nenhuma lei ou programa que beneficie os homens</b></i>. <br></br><br></br>
                Eles podem apenas relatar os abusos e injustiças das leis vigentes, mas nada será feito para reverter esse quadro. Mesmo as mulheres contrárias ao movimento feminista ou solidárias com os problemas enfrentados pelos homens, também nada irão fazer. Nenhuma delas abre mão dos benefícios existentes e futuros, não aceitam se relacionar com um homem de classe ou salário inferior, muito menos sustentar qualquer um que seja. <br></br><br></br>
                O discurso de igualdade não é válido no mundo real, serve apenas como argumento de conquista de benefícios para as mulheres. Os homens são sempre tachados como violentos, opressores e estupradores em potenciais. Como punição devem proteger e sustentar todas as mulheres da sociedade. Qualquer um que tentar fugir desse destino deverá ser duramente penalizado. <br></br><br></br>
                Além de tudo que foi descrito, as mulheres também se tornaram extremamente exigentes em relação aos homens. Apenas uma pequena minoria deles é capaz de atingir os altos níveis requeridos pelas mulheres atuais. A grande maioria dos homens foi posta de lado, servindo apenas como um possível <u>sobressalente</u>, caso elas não encontrem o tipo idealizado ou quando não forem mais atraentes. Muitos homens carentes e solitários aceitam mulheres desse tipo e acabam se tornando ainda mais fracos e passivos. <br></br><br></br>
                Outros por sua vez, estão deixando de se relacionar seriamente com as mulheres por medo de serem pegos e destruídos por leis sexistas. Então, temos um cenário onde as pessoas não estão mais formando vínculos afetivos duradouros, nem gerando filhos. <br></br><br></br>
                Os níveis de gestação estão caindo em todo mundo, em breve atingirão valores catastróficos. Muitas mulheres também estão preferindo investir em suas carreiras profissionais, deixando de efetuar seu papel materno. Quando fazem isso, tentam sem sucesso, conciliar todos seus objetivos ao mesmo tempo. Culpam os homens por seu fracasso, cansaço e falta de estrutura. Elas não querem ser cobradas por seu desempenho familiar. <br></br><br></br>
                O divórcio torna-se uma solução simples para esse problema. Elas podem manter seu padrão de vida e educar seus filhos como quiserem, sem serem cobradas ou pressionadas por seus cônjuges. <br></br><br></br>
                Afinal, como já foi dito exaustivamente, os filhos pertencem exclusivamente as mulheres, e podem servir para elas como escudo ou argumento para os mais variados fins, principalmente o de <u>valorizá-las</u> na sociedade. <br></br><br></br>
                Mulheres solteiras, sem perspectiva de casamento, estão preferindo ter filhos através de inseminação artificial. Isso <u>adiciona</u> valor em seu lugar na sociedade, e gera indivíduos que irão sustentá-las no futuro. Em muitos países, mulheres como estas recebem diversos benefícios do governo para educar seus filhos, pagos através de impostos recolhidos de homens casados e solteiros. É licito imaginar que em um futuro próximo, o sigilo dessas instituições que realizam inseminações humanas possa ser quebrado, para passar essa obrigação aos pais biológicos. <br></br><br></br>
                As mulheres se tornaram <u>tóxicas e opressoras</u>, e os homens se tornaram <u>fracos e passivos</u>. As instituições se tornaram criminosas, malignas e destrutivas, não podem ser salvas ou corrigidas. <br></br><br></br>
                Atualmente, temos conhecimento e tecnologia para vivermos em um mundo farto e feliz. Contudo, continuamos movidos por nossos egos ou por interesses sombrios. <br></br><br></br>
                Homens e mulheres são diferentes e nunca poderão ser iguais, mas definitivamente são iguais em sonhos e necessidades. E podem ser iguais em direitos e obrigações. <br></br><br></br>
                Porém, enquanto houver desiquilíbrio entre essas forças, e principalmente no caráter das pessoas, continuaremos jogando esse jogo sujo, que destrói as famílias e produz pessoas solitárias, recalcadas e infelizes, onde só existe perdedores.  <br></br><br></br><br></br>
                <b>O que devemos fazer?</b><br></br><br></br>
                Se você também acredita que existe algo de errado acontecendo. Que seus sonhos e futuro estão ameaçados pelo fato de ser homem, então: apague seu condicionamento e acorde para a realidade. <br></br><br></br>
                Procure a verdade, estude e esteja com sua mente no presente, mas planeje seu futuro. <br></br><br></br>
                Busque por sua salvação, por sua saúde, proteção e bem estar, por sua liberdade pessoal e financeira. <br></br><br></br>
                Não tente recuperar ou salvar instituições falidas, não gaste seu tempo ou energia com isso. <br></br><br></br>
                Realize tudo isso em silêncio. Não se exponha, não queira educar quem não quer aprender, e <u><b>NUNCA</b></u> revele seus planos.<br></br><br></br>
                Tenha fé, coragem e determinação.
                <br></br><br></br><br></br>

                Em 1983 os cinemas exibiram um filme de ficção científica, onde um adolescente tem acesso a uma inteligência artificial que controlava todo o sistema militar americano. <br></br><br></br>
                Achando estar jogando apenas um jogo de computador sofisticado, ele aciona o protocolo de ofensiva militar. Isso poderia ocasionar uma guerra nuclear de verdade, porque o computador não sabia a diferença entre real e virtual, e tinha sido programado para jogar e vencer. <br></br><br></br>
                Era necessário ensina-lo que não haveria vencedores no mundo real. O filme termina com o computador chegando a uma factual conclusão.  
                <br></br><br></br><br></br>
                Enquanto houver os desajustes nas leis e na sociedade atual, <u><b>NÃO</b></u> é possível garantir um casamento saudável e feliz. O divórcio será incentivado e facilitado, sempre prejudicando seus membros, principalmente os homens e seus filhos que serão os maiores perdedores. <br></br><br></br>
                Portanto, a única forma de vencer esse jogo é seguir a conclusão obtida pelo computador do filme descrito:
                <br></br><br></br><br></br>
                <h2><b>É um jogo estranho, a única forma de vencer é não jogar.</b></h2>

                </span>
            </S.FormText>

            </S.Form>
            </>
            :
            <>
            <br></br>
            <br></br>
            <h3>&nbsp;</h3>
            <br></br>
            </>    }  
        </S.Container>
    )
}

export default GuidePart8;