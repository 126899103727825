import React   from 'react';
 
class Util extends React.Component {
    
    getCurrentDateToFirebase() {

        var today = new Date();
        return this.fillZeros(today.getDate()) + '-' + this.fillZeros((today.getMonth() + 1)) + '-' + today.getFullYear();
    }

    fillZeros(value) {

         if ( value < 10 )
            return "0" + value;
         else
            return value;   
    }

    getCurrentTimeToFirebase() {

        var today = new Date();
        return  this.fillZeros(today.getHours()) + ':' + this.fillZeros(today.getMinutes()) + ':' + this.fillZeros(today.getSeconds());
    }

    checkGuideAccess() {

        let form  = new URLSearchParams(window.location.search);
        let guide = form.get('lng');
        
        if (guide === null)
           return false;

        if (guide !== 'guide')
           return false;

        return true;
    }

    getLanguage() {

      var english    = false;
      var spanish    = false;
      var portuguese = false;

      let form     = new URLSearchParams(window.location.search);
      let language = form.get('lng');

      if (language === null) 
          english = true;
      else
          {
          language = language.toLowerCase();

          if ( language === 'es-es')
              spanish = true;
          else  
              if ( language === 'pt-br') 
                  portuguese = true;
              else 
                  english = true;   
          }

      return{
         english   :english,
         spanish   :spanish,
         portuguese:portuguese
      }        
   }     

}

export default new Util();