import React,{useState}     from 'react';
import * as S               from '../Home/styles';
import * as GV              from '../../../global/globalVariables';
import logo                 from '../../../assets/divorceguide.png';
import imageTop1            from '../../../assets/divorceguidetop2.png';
import imageTop2            from '../../../assets/divorceguidetop2.png';
import image2               from '../../../assets/divorceguide2.png';
import image3               from '../../../assets/divorceguide3.png';
import image4               from '../../../assets/divorceguide4.png';
import image5               from '../../../assets/divorceguide5.png';
import image6               from '../../../assets/divorceguide6.png';
import image7               from '../../DivorceGuide/Book/Images/image01.jpg';
import googleplay           from '../../../assets/googleplay.png';
import MenuGuide            from '../../DivorceGuide/Home/menu';
import Others               from '../../DivorceGuide/Home/others';
import Trailer              from '../../DivorceGuide/Home/trailer';
import { i18n }             from '../../../translate/i18n';
import Contact              from '../../../components/Mail';

function Home() {
    
    const [odd, setOdd] = useState(false);
    
    React.useEffect(() => {
        window.scrollTo(0, 0);
        setOdd( isOdd(randomNumberInRange(1, 9)) );
    }, []);
    
    function randomNumberInRange(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    function isOdd(num) {
        return Math.abs(num % 2) === 1;
    }

    function buttonDownload() {
        window.open('https://play.google.com/store/apps/details?id=com.kodasware.divorceguide', '_self');
    }

    function buttonGuide() {
        window.open('https://www.kodasware.com/divorceguide/book', '_self');
    }

    return (
        <S.Container>
            <S.LogoImage><img src={logo} alt=''></img></S.LogoImage>
            <MenuGuide></MenuGuide>

            { odd ? <S.TopImage><img src={imageTop1} alt=''></img></S.TopImage> : <S.TopImage><img src={imageTop2} alt=''></img></S.TopImage> }
            
            <div id="about"></div>
            <S.TitleOption>{i18n.t('guide.menu1')}</S.TitleOption>
            <S.DescOption>{i18n.t('guide.menu1Desc1')}</S.DescOption>
            <S.DescOption>{i18n.t('guide.menu1Desc2')}</S.DescOption>
            <S.DescOption>{i18n.t('guide.menu1Desc3')}</S.DescOption>
            <S.DescOption>{i18n.t('guide.menu1Desc4')}</S.DescOption>
            <S.DescOption>{i18n.t('guide.menu1Desc5')}</S.DescOption>
            <S.DescOption>{i18n.t('guide.menu1Desc6')}</S.DescOption>
            <S.DescOption>{i18n.t('guide.menu1Desc7')}</S.DescOption>
            <S.AboutImage></S.AboutImage>
            <S.DivisionLine></S.DivisionLine>
            <div id="features"></div>
            <S.TitleOption>{i18n.t('guide.menu2')}</S.TitleOption>
            <S.DescOption>{i18n.t('guide.menu2Desc')}</S.DescOption>
                
            <S.GuideContainer>
                <S.GuideImage><img src={image2} alt=''></img></S.GuideImage>
                <S.GuideDescTitle>{i18n.t('guide.menu2Title1')}</S.GuideDescTitle>
                <S.GuideDesc>{i18n.t('guide.menu2Title1Desc')}</S.GuideDesc>
            </S.GuideContainer>

            <S.GuideContainer>
                <S.GuideImage><img src={image3} alt=''></img></S.GuideImage>
                <S.GuideDescTitle>{i18n.t('guide.menu2Title2')}</S.GuideDescTitle>
                <S.GuideDesc>{i18n.t('guide.menu2Title2Desc')}</S.GuideDesc>
            </S.GuideContainer>

            <S.GuideContainer> 
                <S.GuideImage><img src={image4} alt=''></img></S.GuideImage>
                <S.GuideDescTitle>{i18n.t('guide.menu2Title3')}</S.GuideDescTitle>
                <S.GuideDesc>{i18n.t('guide.menu2Title3Desc')}</S.GuideDesc>
            </S.GuideContainer>
            
            <S.GuideContainer>
                <S.GuideImage><img src={image5} alt=''></img></S.GuideImage>
                <S.GuideDescTitle>{i18n.t('guide.menu2Title4')}</S.GuideDescTitle>
                <S.GuideDesc>{i18n.t('guide.menu2Title4Desc')}</S.GuideDesc>
            </S.GuideContainer>
            
            <S.GuideContainer>
                <S.GuideImage><img src={image6} alt=''></img></S.GuideImage>
                <S.GuideDescTitle>{i18n.t('guide.menu2Title5')}</S.GuideDescTitle>
                <S.GuideDesc>{i18n.t('guide.menu2Title5Desc')}</S.GuideDesc>
            </S.GuideContainer>


            <br></br><br></br><br></br>

            <S.DivisionLine></S.DivisionLine>
            <div id="download"></div>
            <S.TitleOption>{i18n.t('guide.menu3')}</S.TitleOption>
            <S.DescOption>{i18n.t('guide.menu3Desc')}</S.DescOption>
            <br></br>
            <S.MidiaPlay onClick={buttonDownload}><img src={googleplay} alt=''></img></S.MidiaPlay>
            <S.DivisionLine></S.DivisionLine>
            <div id="contact"></div>
            <S.TitleOption>{i18n.t('guide.menu4')}</S.TitleOption>
            <S.GuideDescTitle>{i18n.t('guide.menu4Title')}</S.GuideDescTitle>
            <S.GuideDesc>{i18n.t('guide.menu4Desc')}</S.GuideDesc>
            <Contact local={GV.CONTACT_LOCAL_APP_DIVORCE_GUIDE}></Contact>
            <br></br>
            <br></br>
            <S.DivisionLine></S.DivisionLine>
            <div id="others"></div>
            <S.TitleOption>{i18n.t('guide.menu5')}</S.TitleOption>

            <Others></Others>

            <Trailer></Trailer>
        </S.Container>
    )
}

export default Home;

//<S.GuideContainer>
//<S.GuideImageMidiaPlay onClick={buttonGuide}><img src={image7} alt=''></img></S.GuideImageMidiaPlay>
//<S.GuideDescTitle>{i18n.t('header.book1')}</S.GuideDescTitle>
//<S.GuideDesc>{i18n.t('guide.menu2Title6Desc')}</S.GuideDesc>
//<S.GuideDesc>{i18n.t('guide.menu2Title6bDesc')}</S.GuideDesc>
//<S.GuideDesc><h3>{i18n.t('guide.menu2Title6cDesc')}</h3></S.GuideDesc>
//</S.GuideContainer>