import React    from 'react';
import * as S   from '../global/style';

function AppTermsOfUse({name}) {
    
    return (
        <S.Container>
            <S.Form>
            <>
            <h2>Terms &amp; Conditions</h2>
            <p>
            <a href="#spanish">Haga clic aquí para el idioma Español.</a><br></br><br></br>
            <a href="#portuguese">Clique aqui para o idioma Português.</a></p><br></br>

                <p>
                By downloading or using the app, the terms described herein will be
                   automatically applied to you - Therefore,
                   Please read carefully before using the app. You are not
                   authorized to copy or modify the app, any part of the app or
                   our trademarks in any form. You are not allowed to try
                   extract the app's source code, and you shouldn't try either
                   translate the app into other languages or make derivative versions.
                   The app itself and all trademarks, copyrights,
                   database rights and other related intellectual property rights
                   to him, still belong to KODASWARE.
                 </p>

                 <p>
                   The content of this app is based on personal opinions acquired empirically or
                   obtained through public and government documents made available in the existing media.
                   The information accessible in this app is not guaranteed to be accurate,
                   complete or updated. It is the user's responsibility to follow or not
                   suggestions, advice and opinions, always looking for a
                   specialized professional to solve your doubts and problems in a legal and
                   in a satisfactory way.
                 </p>

                 <p>
                   KODASWARE is committed to ensuring that the app is
                   as useful and efficient as possible. For that reason, 
                   we reserve the right to make changes to the app or charge for
                   its services, at any time and for any reason. We will never go
                   charge for the app or its services without specifying what you are paying for.
                 </p>
                
                 <p>
                   The app {name} stores and processes data that
                   you have provided to us, to carry out our
                   services. It is your responsibility to keep your phone and
                   secure app access. Therefore, we recommend that you
                   do not unlock or remove software restrictions and limitations imposed by
                   official operating system of your device. Could make your
                   phone vulnerable to malware/viruses/malicious programs,
                   compromise your phone's security features and that could mean
                   that the app {name} will not work correctly or will not work at all.
                 </p>
                 <div><p>
                     The app uses third-party services that declare their
                     Terms and conditions. Link to Third-Party Service Terms and Conditions
                     providers used by the app:
                   </p><br></br>
                   <ul>
                   <li><a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">Google Play Services</a></li>
                   <li><a href="https://developers.google.com/admob/terms" target="_blank" rel="noopener noreferrer">AdMob</a></li>
                   </ul>
                   </div><br></br> <p>
                   Certain app functions will require the app to have a
                   Internet connection. Connection can be Wi-Fi or provided
                   by your mobile network provider, but KODASWARE
                   cannot take responsibility for the app not working fully
                   if you don't have access to Wi-Fi and don't have any data allowance left.
                 </p> <p></p> <p>
                   If you are using the app outside of a Wi-Fi area, you
                   remember that the terms of the agreement with your
                   mobile network provider will still apply. As a result, you can
                   be charged by your cellular provider for the cost of data for
                   the duration of the connection when accessing the app, or
                   other third-party charges. By using the app, you are accepting
                   responsibility for such charges, including data roaming
                   charged if you use the app outside your territory
                   (i.e. region or country) without disabling data roaming.
                 </p> <p>
                   KODASWARE is not responsible for any
                   loss, direct or indirect, that you experience as a result of
                   any functionality or information provided by the app.
                 </p> <p>
                   At some point, we may want to update the app. The app is
                   currently available on Android - the requirements for
                   system can change and you will need to download updates if you want to continue
                   using the app. KODASWARE does not promise that
                   will always update the app so that it is relevant to you
                   and/or work with the Android version you have
                   installed on your device. Yet you always promise
                   accept app updates when offered to you. <br></br><br></br>We can
                   also stop providing the app and we may terminate its use at any time,
                   without notice of termination to you.
                   Unless we tell you otherwise, upon any termination, <br></br>(a) the
                   the rights and licenses granted to you in these terms will end;
                   <br></br>(b) you must stop using the app and (if necessary) delete it
                   from your device.<br></br><br></br>
                 </p> <p><strong>Changes to these Terms and Conditions</strong></p> <p>
                   We may update our Terms and Conditions
                   from time to time. Thus, you are advised to review this page
                   periodically for any changes. We will
                   notify you of any changes by posting the new Terms and
                   Conditions on this page.
                 </p> <p>
                   These terms and conditions are effective from 01-Sep-2022
                 </p> <p><strong>Contact us</strong></p> <p>
                   If you have any questions or suggestions about our
                   Terms and Conditions, do not hesitate to contact us
                   at info@kodasware.com.</p>
            </>
            <br></br>
            <br></br>
            <br></br>
            <>
            <div id="spanish"></div>
            <h2>Términos &amp; Condiciones</h2>
            <p>
                Al descargar o utilizar la aplicación, los términos aquí descritos serán
                aplicado automáticamente a usted - Por lo tanto,
                lea atentamente antes de usar la aplicación. Tu no eres
                autorizado para copiar o modificar la aplicación, cualquier parte de la aplicación o
                nuestras marcas registradas en cualquier forma. No tienes permitido probar
                extraiga el código fuente de la aplicación, y tampoco debería intentarlo
                traducir la aplicación a otros idiomas o hacer versiones derivadas.
                La aplicación en sí y todas las marcas comerciales, derechos de autor,
                derechos de bases de datos y otros derechos de propiedad intelectual relacionados
                para él, todavía pertenecen a KODASWARE.
              </p>

              <p>
                El contenido de esta aplicación se basa en opiniones personales adquiridas empíricamente o
                obtenidos a través de documentos públicos y gubernamentales puestos a disposición en los medios de comunicación existentes.
                No se garantiza que la información accesible en esta aplicación sea precisa,
                completa o actualizada. Es responsabilidad del usuario seguir o no
                sugerencias, consejos y opiniones, buscando siempre una
                profesional especializado para resolver sus dudas y problemas de manera legal y
                de manera satisfactoria.
              </p>

              <p>
                KODASWARE se compromete a garantizar que la aplicación sea
                lo más útil y eficiente posible. Por esa razón, nosotros
                nos reservamos el derecho de hacer cambios en la aplicación o cobrar por
                sus servicios, en cualquier momento y por cualquier motivo. Nunca iremos
                cobrar por la aplicación o sus servicios sin especificar lo que está pagando.
              </p>
            
              <p>
                La aplicación {name} almacena y procesa datos que
                usted nos ha proporcionado, para llevar a cabo nuestro
                servicios. Es su responsabilidad mantener su teléfono y
                acceso seguro a la aplicación. Por lo tanto, le recomendamos que
                no desbloquee ni elimine las restricciones y limitaciones de software impuestas por
                sistema operativo oficial de su dispositivo. Podría hacer su
                teléfono vulnerable a malware/virus/programas maliciosos,
                comprometer las características de seguridad de su teléfono y eso podría significar
                que la aplicación {name} no funcionará correctamente o no funcionará en absoluto.
              </p>
              <div><p>
                  La aplicación utiliza servicios de terceros que declaran su
                  Términos y Condiciones. Enlace a los términos y condiciones del servicio de terceros
                  proveedores utilizados por la aplicación:
                </p><br></br>
                <ul>
                <li><a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">Servicios de Google Play</a></li>
                <li><a href="https://developers.google.com/admob/terms" target="_blank" rel="noopener noreferrer">AdMob</a></li>
                </ul>
                </div><br></br> <p>
                Ciertas funciones de la aplicación requerirán que la aplicación tenga un
                Conexión a Internet. La conexión puede ser Wi-Fi o proporcionada
                por su proveedor de red móvil, pero KODASWARE
                no puede asumir la responsabilidad de que la aplicación no funcione completamente
                si no tiene acceso a Wi-Fi y no le queda ninguna asignación de datos.
              </p> <p></p> <p>
                Si está utilizando la aplicación fuera de un área Wi-Fi, debe
                recuerde que los términos del acuerdo con su
                seguirá aplicándose el proveedor de red móvil. Como resultado, puede
                ser cobrado por su proveedor celular por el costo de los datos para
                la duración de la conexión al acceder a la aplicación, o
                otros cargos de terceros. Al usar la aplicación, estás aceptando
                responsabilidad por dichos cargos, incluida la itinerancia de datos
                cobrado si usa la aplicación fuera de su territorio
                (es decir, región o país) sin deshabilitar el roaming de datos.
              </p> <p>
                KODASWARE no es responsable de ninguna
                pérdida, directa o indirecta, que experimente como resultado de
                cualquier funcionalidad o información proporcionada por la aplicación.
              </p> <p>
                En algún momento, es posible que queramos actualizar la aplicación. La aplicación es
                actualmente disponible en Android - los requisitos para
                el sistema puede cambiar y deberá descargar actualizaciones si desea continuar
                utilizando la aplicación. KODASWARE no promete que
                siempre actualizará la aplicación para que sea relevante para usted
                y/o trabajar con la versión de Android que tienes
                instalado en su dispositivo. Sin embargo, siempre prometes
                aceptar actualizaciones de la aplicación cuando se le ofrezcan. <br></br><br></br>Podemos
                también dejar de proporcionar la aplicación y podemos terminar su uso en cualquier momento,
                sin previo aviso de terminación para usted.
                A menos que le indiquemos lo contrario, en caso de rescisión, <br></br>(a) el
                los derechos y licencias otorgados a usted en estos términos terminarán;
                <br></br>(b) debe dejar de usar la aplicación y (si es necesario) eliminarla
                desde tu dispositivo.<br></br><br></br>
              </p> <p><strong>Cambios a estos Términos y Condiciones</strong></p> <p>
                Podemos actualizar nuestros Términos y Condiciones
                de tiempos en tiempos. Por lo tanto, se recomienda revisar esta página 
                periódicamente para cualquier cambio. Nosotros vamos
                notificarle cualquier cambio mediante la publicación de los nuevos Términos y
                Condiciones en esta página.
              </p> <p>
                Estos términos y condiciones son efectivos a partir del 01-sep-2022
              </p> <p><strong>Contáctenos</strong></p> <p>
                Si tiene alguna pregunta o sugerencia acerca de nuestro
                Términos y Condiciones, no dude en contactarnos
                en info@kodasware.com.</p>
            </>
            <br></br>
            <br></br>
            <br></br>
            <>
            <div id="portuguese"></div>
            <h2>Termos &amp; Condições</h2>
                <p>
                  Ao baixar ou usar o aplicativo, os termos aqui descritos serão 
                  automaticamente aplicados a você - Portanto,
                  leia atentamente antes de usar o aplicativo. Você não é
                  autorizado a copiar ou modificar o aplicativo, qualquer parte do aplicativo ou
                  nossas marcas registradas de qualquer forma. Você não tem permissão para tentar
                  extrair o código-fonte do aplicativo, e você também não deve tentar
                  traduzir o aplicativo para outros idiomas ou fazer versões derivadas.
                  O aplicativo em si e todas as marcas registradas, direitos autorais,
                  direitos de banco de dados e outros direitos de propriedade intelectual relacionados
                  a ele, ainda pertencem à KODASWARE. 
                </p>

                <p>
                  O conteúdo deste aplicativo é baseado em opiniões pessoais adquiridas empiricamente ou
                  obtidos por meio de documentos públicos e governamentais disponibilizados na mídia existente.
                  Não é garantido que as informações acessíveis neste aplicativo sejam precisas,
                  completas ou atualizadas. É de responsabilidade do usuário seguir ou não sua
                  sugestões, conselhos e opiniões, devendo sempre procurar um
                  profissional especializado para solucionar suas dúvidas e problemas de forma legal e
                  de maneira satisfatória.
                </p>

                <p>
                  A KODASWARE está comprometida em garantir que o aplicativo seja
                  o mais útil e eficiente possível. Por esse motivo, nós
                  reservamos o direito de fazer alterações no aplicativo ou cobrar por
                  seus serviços, a qualquer momento e por qualquer motivo. Nós nunca iremos
                  cobrar pelo aplicativo ou seus serviços sem especificar o que você está pagando.
                </p> 
                
                <p>
                  O aplicativo {name} armazena e processa dados que
                  você nos forneceu, para realizar nossos
                  serviços. É sua responsabilidade manter seu telefone e
                  acesso ao aplicativo seguro. Portanto, recomendamos que você 
                  não desbloqueie ou remova restrições e limitações de software impostas pelo
                  sistema operacional oficial do seu dispositivo. Poderia fazer o seu
                  telefone vulnerável a malware/vírus/programas maliciosos,
                  comprometer os recursos de segurança do seu telefone e isso pode significar
                  que o aplicativo {name} não funcionará corretamente ou não funcionará.
                </p>
                <div><p>
                    O aplicativo usa serviços de terceiros que declaram sua
                    Termos e Condições. Link para os Termos e Condições do serviço de terceiros
                    provedores usados ​​pelo aplicativo:
                  </p><br></br>
                  <ul>
                  <li><a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">Google Play Services</a></li>
                  <li><a href="https://developers.google.com/admob/terms" target="_blank" rel="noopener noreferrer">AdMob</a></li>
                  </ul>
                  </div><br></br> <p>
                  Certas funções do aplicativo exigirão que o aplicativo tenha um
                  conexão de internet. A conexão pode ser Wi-Fi ou fornecida
                  pelo seu provedor de rede móvel, mas a KODASWARE
                  não pode assumir a responsabilidade pelo aplicativo não funcionar totalmente
                  se você não tiver acesso ao Wi-Fi e não sobrar alguma franquia de dados.
                </p> <p></p> <p>
                  Se você estiver usando o aplicativo fora de uma área com Wi-Fi, você
                  deve lembrar que os termos do acordo com o seu
                  provedor de rede móvel ainda se aplicará. Como resultado, você pode
                  ser cobrado pelo seu provedor de celular pelo custo dos dados para
                  a duração da conexão ao acessar o aplicativo, ou
                  outros encargos de terceiros. Ao usar o aplicativo, você está aceitando
                  responsabilidade por tais cobranças, incluindo dados de roaming
                  cobrados se você usar o aplicativo fora do seu território
                  (ou seja, região ou país) sem desativar o roaming de dados. 
                </p> <p>
                  A KODASWARE não se responsabiliza por qualquer
                  perda, direta ou indireta, que você experimente como resultado de
                  qualquer funcionalidade ou informação fornecida pelo aplicativo.
                </p> <p>
                  Em algum momento, podemos querer atualizar o aplicativo. O aplicativo é
                  atualmente disponível no Android – os requisitos para o
                  sistema pode mudar e você precisará baixar as atualizações se quiser continuar
                  usando o aplicativo. A KODASWARE não promete que
                  sempre atualizará o aplicativo para que seja relevante para você
                  e/ou funcione com a versão do Android que você possui
                  instalado em seu dispositivo. No entanto, você promete sempre
                  aceitar atualizações do aplicativo quando oferecidas a você. <br></br><br></br>Podemos
                  também parar de fornecer o aplicativo e podemos encerrar seu uso a qualquer momento, 
                  sem aviso de rescisão a você.
                  A menos que lhe digamos o contrário, após qualquer rescisão, <br></br>(a) o
                  os direitos e licenças concedidos a você nestes termos terminarão;
                  <br></br>(b) você deve parar de usar o aplicativo e (se necessário) excluí-lo
                  do seu dispositivo.<br></br><br></br>
                </p> <p><strong>Alterações nestes Termos e Condições</strong></p> <p>
                  Podemos atualizar nossos Termos e Condições
                  de tempos em tempos. Assim, você é aconselhado a rever esta página
                  periodicamente para quaisquer alterações. Nós vamos
                  notificá-lo de quaisquer alterações publicando os novos Termos e
                  Condições nesta página.
                </p> <p>
                  Estes termos e condições estão em vigor a partir de 01-Sep-2022
                </p> <p><strong>Fale conosco</strong></p> <p>
                  Se você tiver alguma dúvida ou sugestão sobre nosso
                  Termos e Condições, não hesite em contactar-nos
                  em info@kodasware.com.</p>
            </>
            <br></br>
            <br></br>
            <br></br>
            </S.Form>
        </S.Container>
    )
}

export default AppTermsOfUse;