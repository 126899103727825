import React      from 'react';   
import * as S     from './styles';

export default class Alert extends React.Component {

render() {

  return (
    <>
<S.DropDownListContainer>
{ ( this.props.id === 'msg01' )
?
<> 
Os homens costumam ser os maiores prejudicados em um processo de divórcio. 
Para diminuir suas perdas é necessário realizar um planejamento antecipado. 
Contudo, <b>ser surpreendido com um pedido de divórcio é algo desastroso</b>. 
Se for um divórcio litigioso acompanhado de uma <u>ordem de separação de corpos</u> será <b>fatal</b>. <br></br><br></br>
Ficará fragilizado e poderá ser vítima de profissionais inescrupulosos que irão se aproveitar para extrair o máximo de seu dinheiro. 
Estará angustiado, necessitando acreditar que tudo aquilo logo terminará.
Infelizmente, isso não irá acontecer e terá que perder tempo e dinheiro para se defender. 
Terá que contratar um advogado com urgência, não tendo tempo para encontrar alguém qualificado e que esteja em sintonia com você.
No final, será derrotado e terá dificuldades de recuperação. <br></br><br></br>
Por isso, é fundamental ter um planejamento e <b>ser o primeiro a solicitar o divórcio</b>. 
</>
: null }

{ ( this.props.id === 'msg02' )
?
<> 
Você será o único a sofrer as consequências de uma sentença. Portanto, é o mais interessado em seu resultado.<br></br><br></br> 
O juiz e os advogados muitas vezes não discutem detalhadamente o processo durante a audiência, devido ao calor da conversação, pressa em finalizá-la ou por outras razões.
Por causa disso, alguns itens ficam incompletos, dando margem para diferentes interpretações. <br></br><br></br>
Portanto, não importando o tempo da audiência, cada item deverá ser discutido, ter <b>um prazo estabelecido e uma consequência</b> definida se ele não for cumprido. <br></br><br></br>
<b>Exemplo:</b><br></br>
Foi decidido na audiência de partilha de bens que o computador pessoal será seu. <br></br> 
Péssima sentença, a parte poderá devolver em qualquer estado e quando quiser. <br></br> 
O correto seria que o computador deve ser entregue até determinada data em perfeita condições de funcionamento, caso contrário outro igual ou superior deverá ser entregue.<br></br><br></br>
<b>A sentença não pode ter margem para interpretação</b>.
</>
: null }

{ ( this.props.id === 'msg03' )
?
<> 
Alimento provisório é um valor estabelecido que deve ser pago até que o valor definitivo possa ser decretado pelo juiz.<br></br><br></br>  
Essa operação poderá demorar meses/anos até que seja concluída. <br></br><br></br> 
O valor definitivo poderá ser diferente do valor temporário anteriormente decretado. <br></br><br></br>
Se for menor, o valor excedente que foi pago jamais será devolvido. 
Se for maior, terá que devolver a diferença com juros, correção e <b><u>MULTA</u></b>. <br></br><br></br> 
Sim, multa! Afinal, você deveria saber com antecedência qual valor definitivo seria decretado. 
Parece insanidade, mas essa é a realidade da justiça. <br></br><br></br> 
<b>Geralmente, os valores definitivos são maiores que os valores temporários</b>. <br></br><br></br> 
Portanto, quanto maior for o tempo de espera, maior será o prejuízo.
</>
: null }

{ ( this.props.id === 'msg04' )
?
<> 
Separação de corpos é um processo que deve ser evitado. Caso ocorra, as consequências serão terríveis e dolorosas.<br></br><br></br>
Será expulso do local onde reside e da guarda de seus filhos. 
Não têm para onde ir? Será seu problema. 
Não têm como pagar um lugar para morar? Também será seu problema.
Onde você morava tinha condomínio e estava em seu nome? 
De novo, será seu problema, e terá que continuar pagando, caso contrário, será processado. 
Qualquer coisa será seu problema.<br></br><br></br> 
Terá que esperar até a audiência de divórcio para que alguma coisa seja decidida. 
Poderá passar meses até que ela ocorra.<br></br><br></br>  
<b>Toda violência, prejuízo, humilhação ou dificuldade que passar por causa desse procedimento, dificilmente serão ressarcidas</b>.<br></br><br></br> 
Registre tudo que ocorrer em fotos, vídeos e áudio. 
Obtenha e mantenha as notas fiscais de todas as suas despesas. 
Faça um diário de tudo que ocorrer com o máximo de detalhes possível.
Consiga testemunhas que estejam dispostas a relatar os acontecimentos registrados.
Utilize esse material para ajuizar um processo contra a pessoa responsável por isso tudo.
</>
: null }
</S.DropDownListContainer>
    </>

  );
 }
}