import React                from 'react';
import * as S               from '../../LifePlan/Home/styles';
import midia2               from '../../../assets/midia2.png';

function TrailerLifePlan() {

    function buttonMidia2() {
        window.open('https://youtu.be/_-C7iGlb6ts', '_blank');
    }

    function linkSite() {
        window.open('https://www.kodasware.com/', '_blank');
        return false;
    }

    return ( 
        <>
        <S.MidiaContainer>
            <S.MidiaItem><S.MidiaButton onClick={buttonMidia2}><img src={midia2} alt=''></img></S.MidiaButton></S.MidiaItem>
        </S.MidiaContainer>

        <S.ContainerCopy><span>2022 &copy;&nbsp;<a href="#" onClick={linkSite}>KODASWARE</a>&nbsp;&nbsp;All rights reserved.</span></S.ContainerCopy>
        </>
     );
}

export default TrailerLifePlan;