import React, { useState }  from 'react';
import * as GV              from '../global/globalVariables';
import Others               from '../components/OthersApps'

function AppToKnowMore({appName}) {
    
    const [app01, setApp01] = useState(false);
    const [app02, setApp02] = useState(false);
    const [app03, setApp03] = useState(false);
    const [app04, setApp04] = useState(false);
    const [app05, setApp05] = useState(false);

    React.useEffect(() => {
        window.scrollTo(0, 0);
        
        //let form     = new URLSearchParams(window.location.search);
        //let language = form.get('lng');

        //if (language !== null)
        //    window.localStorage.setItem(GV.I18N_STORAGE_KEY, language);

        setApp01(true);
        setApp02(true);
        setApp03(true);
        setApp04(true);
        setApp05(true);

        if (appName === GV.APP_DIVORCE_GUIDE)     setApp01(false);   
        if (appName === GV.APP_DIVORCE_PLANNING)  setApp02(false);    
        if (appName === GV.APP_PERSONAL_DIARY)    setApp03(false);   
        if (appName === GV.APP_LIFE_PLAN)         setApp04(false);  
        if (appName === GV.APP_PASSWORD_WALLET)   setApp05(false);  

    }, []);

    return (
        <>
        { app01 && <> <Others appName={GV.APP_DIVORCE_GUIDE}>    </Others>  </> }
        { app02 && <> <Others appName={GV.APP_DIVORCE_PLANNING}> </Others>  </> }
        { app03 && <> <Others appName={GV.APP_PERSONAL_DIARY}>   </Others>  </> }
        { app04 && <> <Others appName={GV.APP_LIFE_PLAN}>        </Others>  </> }
        { app05 && <> <Others appName={GV.APP_PASSWORD_WALLET}>  </Others>  </> }
        
        <br></br><br></br><br></br><br></br>
        </>
    )
}

export default AppToKnowMore;
