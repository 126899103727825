
import React, { useEffect, useState } from 'react';   
import * as S       from './styles';
import me           from '../../../../view/DivorceGuide/Guide/Images/me.png'
import util         from '../../../../Utilities/Util';

function Author() {
    
    const [access, setAccess] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)
        setAccess( util.checkGuideAccess() );
    }, [])

    return (
        <S.Container>
        { ( access )
            ?
            <>
            <S.Form> 

            <S.FormTitle>
                <span>AUTHOR</span><br></br>
            </S.FormTitle>

            <S.ContainerCards>
            <S.FormImage><img src={me} alt=""></img></S.FormImage>
            <S.FormText>
                <span>
                <i>
				Soy el resultado de una guerra silenciosa que tiene lugar ante nosotros, pero no nos damos cuenta ni nos preocupamos hasta que es demasiado tarde.<br></br><br></br>
                <b>No soy médico, psicólogo, jurista ni abogado</b>.<br></br><br></br>
                Soy alguien que, a través de mucho sufrimiento y desilusión, ha adquirido un vasto conocimiento de cómo sobrevivir, pelear y incluso ganar esta guerra invisible y destructiva.<br></br><br></br>
                No doy garantías ni prometo victorias, solo ofrezco la verdad y los mecanismos para sobrevivir, pero la lucha será por vosotros.<br></br><br></br>
                Ofrecer la verdad ya es una gran victoria y ventaja frente a tus oponentes y para tu crecimiento personal, pero también te enseña a evitar muchos errores y sufrimientos innecesarios.<br></br><br></br>
                <b>¡Cree! Ninguno de los <u>expertos</u> a los que acudirá hará esto.</b><br></br><br></br>
                <br></br><br></br>
                </i>
                </span>
            </S.FormText>
            </S.ContainerCards>
            </S.Form>
            </>
            :
            <>
            <br></br>
            <br></br>
            <h3>&nbsp;</h3>
            <br></br>
            </>    }  
        </S.Container>
    )
}

export default Author;