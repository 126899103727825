import styled from 'styled-components';

export const Container = styled.div`
    background:  #FFFFFF;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const ParallaxContainer = styled.div`
    background: none;
    display: flex;
    flex-direction: row;
    width: 100%;

    @media screen and (max-width: 960px) {
        flex-direction: column;
        align-items: center;
    }
`

export const ParallaxPart1 = styled.div`
    background: none;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 960px) {
        width: 100%;
    }
` 

export const ParallaxPart2 = styled.div`
    background: none;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 960px) {
        width: 100%;
    }
` 

export const HeaderParallax = styled.div`
    background: none;
    font-size: 56px;
    font-family: 'Roboto', sans-serif;
    text-decoration: none;
    text-align: center;
    font-weight: bold;
    color: #000000;
    margin-top: 10px;

    @media screen and (max-width: 960px) {
        font-size: 46px;
    }
    }
`