
import React, { useEffect, useState } from 'react';   
import * as S       from './styles';
import guide28      from '../../../../view/DivorceGuide/Guide/Images/guide28.png';
import util         from '../../../../Utilities/Util';

function GuidePart5() {
    
    const [access, setAccess] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)
        setAccess( util.checkGuideAccess() );
    }, [])

    return (
        <S.Container>
        { ( access )
            ?
            <>
            <S.Form> 

            <S.FormTitle>
                <span>PARTE5</span><br></br><br></br><br></br><span>Justicia en la práctica</span><br></br><br></br>
            </S.FormTitle>

            <S.FormText>
                <span>
                Los textos jurídicos son verdaderas obras maestras literarias, capaces de exaltar los valores humanos en su búsqueda de la verdad y la justicia. <br></br><br></br>
                En la práctica, la historia es bastante diferente. Las leyes son injustas, duras y inconsistentes. Cuando son administradas o utilizadas por personas sin moral ni carácter, las leyes son altamente destructivas y contribuyen aún más al aumento de la criminalidad y los desajustes sociales existentes. <br></br><br></br>
                En el mundo real, las familias comparten momentos felices y infelices. Cuando las condiciones de vida se vuelven difíciles o precarias, suelen afrontar estos períodos juntos. Recortan gastos, modifican su nivel de vida y hacen sacrificios para superar las dificultades y salir adelante. <br></br><br></br>
                En el divorcio se rompe este mecanismo  y se modifica la realidad a favor de los vencedores de esta disputa. Comienzan a vivir en un universo paralelo, en el mundo de los <i>"unicornios"</i>, donde la lógica y el sentido común funcionan de manera diferente a la realidad conocida. <br></br><br></br>
                Los perdedores se convierten en eternos proveedores o <i>"esclavos modernos"</i>, siempre en deuda con sus dueños. Estar desempleado o no tener dinero será una situación inaceptable. <br></br><br></br>
                Si incumplen sus obligaciones financieras serán sancionados y destruidos hasta que aparezcan los recursos adeudados <i>"mágicamente"</i>. <br></br><br></br>
                
                <br></br><br></br><br></br>
                <h2>Practicando</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide28} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                Los ejemplos descritos pertenecen al país del autor y no pueden ser traducidos ni utilizados. Cada país tiene su propia legislación con sus respectivos errores y aciertos. <br></br><br></br>
                Sin embargo, es posible comprobar que en la mayoría de los países occidentales las leyes solo favorecen a las mujeres. 
                <br></br><br></br><br></br>

                </span>
            </S.FormText>

            </S.Form>
            </>
            :
            <>
            <br></br>
            <br></br>
            <h3>&nbsp;</h3>
            <br></br>
            </>    }  
        </S.Container>
    )
}

export default GuidePart5;