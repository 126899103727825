import React,{useState}     from 'react';
import * as S               from './styles';
import * as GV              from '../../../global/globalVariables';
import logo                 from '../../../assets/passwordwallet.png';
import imageTop1            from '../../../assets/passwordwallettop1.png';
import imageTop2            from '../../../assets/passwordwallettop1.png';
import image2               from '../../../assets/passwordwallet2.png';
import image3               from '../../../assets/passwordwallet3.png';
import image4               from '../../../assets/passwordwallet4.png';
import image5               from '../../../assets/passwordwallet5.png';
import image6               from '../../../assets/passwordwallet6.png';
import googleplay           from '../../../assets/googleplay.png';
import MenuGuide            from './menu';
import Others               from './others';
import Trailer              from './trailer';
import { i18n }             from '../../../translate/i18n';
import Contact              from '../../../components/Mail';

function HomePasswordWallet() {
    
    const [odd, setOdd] = useState(false);
    
    React.useEffect(() => {
        window.scrollTo(0, 0);
        setOdd( isOdd(randomNumberInRange(1, 9)) );
    }, []);
    
    function randomNumberInRange(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    function isOdd(num) {
        return Math.abs(num % 2) === 1;
    }
    
    function buttonDownload() {
        window.open('https://play.google.com/store/apps/details?id=com.kodasware.passwordwallet', '_self');
    }

    return (
        <S.Container>
            <S.HeaderApp>{i18n.t('header.app5')}</S.HeaderApp>
            <S.LogoImage><img src={logo} alt=''></img></S.LogoImage>
            <MenuGuide></MenuGuide>

            { odd ? <S.TopImage><img src={imageTop1} alt=''></img></S.TopImage> : <S.TopImage><img src={imageTop2} alt=''></img></S.TopImage> }
            
            <div id="about"></div>
            <S.TitleOption>{i18n.t('passwordwallet.menu1')}</S.TitleOption>
            <S.DescOption>{i18n.t('passwordwallet.menu1Desc')}</S.DescOption>
            <S.DescOption>{i18n.t('passwordwallet.menu1bDesc')}</S.DescOption>
            <S.DescOption>{i18n.t('passwordwallet.menu1cDesc')}</S.DescOption>

            <S.AboutImage></S.AboutImage>
            <br></br><br></br><br></br>
            
            <S.DivisionLine></S.DivisionLine>
            <div id="features"></div>
            <S.TitleOption>{i18n.t('passwordwallet.menu2')}</S.TitleOption>
            <S.DescOption>{i18n.t('passwordwallet.menu2Desc')}</S.DescOption>
                
            <S.GuideContainer>
                <S.GuideImage><img src={image2} alt=''></img></S.GuideImage>
                <S.GuideDescTitle>{i18n.t('passwordwallet.menu2Title1')}</S.GuideDescTitle>
                <S.GuideDesc>{i18n.t('passwordwallet.menu2Title1Desc')}</S.GuideDesc>
            </S.GuideContainer>

            <S.GuideContainer>
                <S.GuideImage><img src={image3} alt=''></img></S.GuideImage>
                <S.GuideDescTitle>{i18n.t('passwordwallet.menu2Title2')}</S.GuideDescTitle>
                <S.GuideDesc>{i18n.t('passwordwallet.menu2Title2Desc')}</S.GuideDesc>
            </S.GuideContainer>

            <S.GuideContainer> 
                <S.GuideImage><img src={image4} alt=''></img></S.GuideImage>
                <S.GuideDescTitle>{i18n.t('passwordwallet.menu2Title3')}</S.GuideDescTitle>
                <S.GuideDesc>{i18n.t('passwordwallet.menu2Title3Desc')}</S.GuideDesc>
            </S.GuideContainer>
            
            <S.GuideContainer>
                <S.GuideImage><img src={image5} alt=''></img></S.GuideImage>
                <S.GuideDescTitle>{i18n.t('passwordwallet.menu2Title4')}</S.GuideDescTitle>
                <S.GuideDesc>{i18n.t('passwordwallet.menu2Title4Desc')}</S.GuideDesc>
            </S.GuideContainer>
            
            <S.GuideContainer>
                <S.GuideImage><img src={image6} alt=''></img></S.GuideImage>
                <S.GuideDescTitle>{i18n.t('passwordwallet.menu2Title5')}</S.GuideDescTitle>
                <S.GuideDesc>{i18n.t('passwordwallet.menu2Title5Desc')}</S.GuideDesc>
            </S.GuideContainer>
            <S.DivisionLine></S.DivisionLine>
            <div id="download"></div>
            <S.TitleOption>{i18n.t('passwordwallet.menu3')}</S.TitleOption>
            <S.DescOption>{i18n.t('passwordwallet.menu3Desc')}</S.DescOption>
            <br></br>
            <S.MidiaPlay onClick={buttonDownload}><img src={googleplay} alt=''></img></S.MidiaPlay>
            <S.DivisionLine></S.DivisionLine>
            <div id="contact"></div>
            <S.TitleOption>{i18n.t('passwordwallet.menu4')}</S.TitleOption>
            <S.GuideDescTitle>{i18n.t('passwordwallet.menu4Title')}</S.GuideDescTitle>
            <S.GuideDesc>{i18n.t('passwordwallet.menu4Desc')}</S.GuideDesc>
            <Contact local={GV.CONTACT_LOCAL_APP_PERSONAL_DIARY}></Contact>
            <br></br>
            <br></br>
            <S.DivisionLine></S.DivisionLine>
            <div id="others"></div>
            <S.TitleOption>{i18n.t('passwordwallet.menu5')}</S.TitleOption>

            <Others></Others>

            <Trailer></Trailer>
        </S.Container>
    )
}

export default HomePasswordWallet;