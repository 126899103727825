
import React, { useEffect, useState } from 'react';   
import * as S       from './styles';
import guide32      from '../../../../view/DivorceGuide/Guide/Images/guide32.png';
import guide33      from '../../../../view/DivorceGuide/Guide/Images/guide33.png';
import guide34      from '../../../../view/DivorceGuide/Guide/Images/guide34.png';
import util         from '../../../../Utilities/Util';

function GuidePart7() {
    
    const [access, setAccess] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)
        setAccess( util.checkGuideAccess() );
    }, [])

    return (
        <S.Container>
        { ( access )
            ?
            <>
            <S.Form> 

            <S.FormTitle>
                <span>PARTE7</span><br></br><br></br><br></br><span>Vitória e Planejamento</span><br></br><br></br>
            </S.FormTitle>

            <S.FormText>
                <span>

                Lutar por igualdade é um direito inerente a todos os seres humanos. <br></br><br></br>
                Nossa história está repleta de conflitos nesse sentido. Sempre houveram injustiças e desigualdades que foram combatidas por aqueles que não aceitavam nem concordavam com elas. <br></br><br></br>
                Contudo, quando essa luta perde o equilíbrio, provoca o efeito contrário. Então, aqueles que lutavam originalmente por esses valores acabam se tornando terríveis algozes sem limites ou compaixão. Produzindo dor, sofrimento, desigualdade e injustiça, muitas vezes maiores ou piores que aquelas originalmente combatidas. <br></br><br></br>
                Mesmo sendo vitoriosos em suas reivindicações nunca ficam satisfeitos com os resultados obtidos e continuam exigindo cada vez mais direitos e benefícios ao seu favor. <br></br><br></br>
                As leis e os processos que regulavam a separação dos casais foram combatidos e modificados para eliminar as desigualdades e injustiças que existiam. <br></br><br></br>
                Porém, o ponto de equilíbrio nesse procedimento a muito já foi superado, e agora pende apenas para um dos lados, e essa discrepância não para de crescer. <br></br><br></br>
                Atualmente, os homens são os maiores prejudicados e suas chances de vencer um divórcio não passam de 5% de todos os processos realizados. <br></br><br></br>
                <b><i>Se é que existe alguma vitória quando uma família é desfeita</i></b>.<br></br><br></br>
                Mesmo assim, as leis que favorecem apenas as mulheres não param de crescer, e as poucas que podem produzir algum tipo de controle ou justiça para os homens estão sendo removidas ou desrespeitadas. <br></br><br></br>
                Como a guarda compartilhada que é sistematicamente negada, e a lei de alienação parental que é combatida através de projetos de lei que desejam removê-la do código civil. <br></br><br></br>
                Essa situação tende a piorar cada vez mais, até mesmo homens solteiros e sem filhos já estão sendo afetados e perseguidos. <br></br><br></br>
                Os homens podem apenas planejar suas ações para tentar mitigar suas perdas em um processo de divórcio.
                
                <br></br><br></br><br></br>
                <h2>Vitória</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide33} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                A vitória é algo subjetivo. Nós acreditamos que se trata de vencer alguém ou alguma coisa e ser recompensado por isso. <br></br><br></br>
                Apesar de existir verdade nessa afirmação, seu valor e resultado pode variar devido a situação ou importância que ela representa. <br></br><br></br>
                Por exemplo, ter saúde pode representar uma vitória para quem passou por algum tipo de enfermidade. Como preferir estudar ao invés de roubar é uma enorme vitória para quem vive em um ambiente onde predomina a pobreza e criminalidade. <br></br>
                No divórcio a vitória também é algo subjetivo. <br></br><br></br>
                Passar por esse procedimento e conseguir manter a saúde, integridade e parte de seus recursos financeiros, será sem dúvida uma grande vitória perante um ambiente desfavorável e injusto criado propositalmente para derrotar os homens.  
                <br></br><br></br><br></br> 
                Vitória e derrota no Divórcio:
                <br></br><br></br>

                <table border="1" width="100%">
                <tbody>
                <tr><td bgcolor='#000000' ><b><font color="white">EXTRA JUDICIAL<br></br>CONSENSUAL</font></b></td>
                <td width="70%">Apenas para casais sem filho.<br></br>É o melhor divórcio possível.<br></br><br></br><font color="blue"><b>VITÓRIA</b></font>
                </td></tr>
                <tr><td bgcolor='#000000' ><b><font color="white">EXTRA JUDICIAL<br></br>NÃO CONSENSUAL</font></b></td>
                <td width="70%">Não existe.<br></br>Deverá ser realizado por meio judicial.<br></br><br></br><font color="red"><b>DERROTA</b></font>
                </td></tr>
                <tr><td bgcolor='#000000' ><b><font color="white">JUDICIAL<br></br>CONSENSUAL</font></b></td>
                <td width="70%">Obrigatório para casais com filhos.<br></br>É a melhor forma de realizar o divórcio na justiça.<br></br><br></br><font color="blue"><b>VITÓRIA</b></font>
                </td></tr>
                <tr><td bgcolor='#000000' ><b><font color="white">JUDICIAL<br></br>LITIGIOSO</font></b></td>
                <td width="70%">É o pior divórcio possível para todos.<br></br>É preferível realizar um acordo ruim e torná-lo consensual.<br></br><br></br><font color="red"><b>DERROTA</b></font>
                </td></tr>
                </tbody>
                </table>

                <br></br><br></br><br></br>
                <h2>Planejamento</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide34} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                Atualmente, estar casado e ter filhos é um péssimo negócio. <br></br><br></br>
                Não é possível utilizar a justiça para que seus direitos sejam respeitados, simplesmente porque eles não existem. <br></br><br></br>
                Contudo, é necessário utilizá-la para dissolver o casamento estabelecido. Poderá ser um procedimento caro e penoso, com o final já determinado e conhecido. <br></br><br></br>
                Deve-se possuir argumentos sérios e incontestáveis para se conseguir alguma coisa concreta e favorável, porém o mais comum é ser prejudicado de alguma forma nesse procedimento. <br></br><br></br>
                Os homens estão em desvantagens em relação aos principais atributos que determinam o vencedor de um conflito. 
                <br></br><br></br><br></br>

                <table border="1" width="100%">
                <tbody>
                <tr><td bgcolor='#000000' ><b><font color="white">TEMPO</font></b></td>
                <td width="80%">As mulheres podem solicitar o divórcio a qualquer momento que desejarem. <br></br>
                Não precisam de um planejamento criterioso, nem de elevados recursos financeiros.</td></tr>
                <tr><td bgcolor='#000000' ><b><font color="white">JUSTIÇA</font></b></td>
                <td width="80%">Todo o ambiente jurídico e as leis existentes favorecem exclusivamente as mulheres dando pouco espaço para que os homens possam fazer alguma coisa significativa. <br></br>
                Atualmente, o litigio é uma indústria muito lucrativa.</td></tr>
                <tr><td bgcolor='#000000' ><b><font color="white">ADVOGADO</font></b></td>
                <td width="80%">É um segmento da indústria citada anteriormente. <br></br>
                Sempre irão ganhar independentemente dos resultados obtidos, sem sofrer qualquer consequência pelo trabalho prestado. <br></br>
                Podem fazer muito pouco pelos homens, acabam sendo apenas despachantes. </td></tr>
                </tbody>
                </table>

                <br></br><br></br><br></br>
                <b> A arte da Guerra</b><br></br><br></br>
                <i>Sun Tzu foi um filósofo, estrategista e general chinês, escritor de um tratado militar denominado <b>"A Arte da Guerra"</b></i>.<br></br><br></br>
                No final do século VI a.C., foi contratado pelo rei Hu Lu do reino de Wu para comandar seu exército contra o reino vizinho de Chu, que recentemente havia declarado guerra. <br></br><br></br>
                A China daquela época não era unificada e encontrava-se dividida em vários pequenos reinos rivais que batalhavam entre si com o objetivo de expandir seus territórios. <br></br><br></br>
                Sun Tzu, depois de passar por um teste de avaliação de suas capacidades, tornou-se comandante supremo de um exército de trinta mil homens contra um oponente melhor preparado e numericamente <u>dez vezes superior</u>. <br></br><br></br>
                Em vez de ficar esperando pelo primeiro ataque, ele ignorou os procedimentos militares da época e fez algo improvável e inesperado, ele atacou primeiro. <br></br><br></br>
                Ele não fez um ataque direto, mas deslocou as tropas de seu oponente para locais determinados e atacou sempre de surpresa seus pontos fracos até conseguir enfraquecê-lo e diminuir gradativamente seu contingente, e assim vencer a guerra. <br></br><br></br>
                Sun Tzu nunca perdeu nenhuma das batalhas que enfrentou e descreve suas conquistas e conhecimentos no livro mencionado.
                <br></br><br></br>
                Como já explicado anteriormente, o divórcio tem as mesmas características de uma guerra, e como tal deve ser uma ação elaborada com antecedência, sem sentimentos e com um objetivo definido, realista e totalmente factível, sendo seu planejamento um item obrigatório que requer tempo, disciplina, paciência e dissimulação de seu elaborador. <br></br><br></br>
                Que o divórcio não deve ser realizado antecipadamente por causa de nossos sentimentos, opiniões de amigos ou parentes, e nem por causa de sugestões de profissionais consultados. <br></br><br></br>
                Foi também descrito que o período que antecede a solicitação do divórcio não deve ser desperdiçado com brigas ou confrontos inúteis. <br></br><br></br>
                Ele deve ser utilizado para o aprimoramento do plano estratégico e para obtenção de recursos financeiros e pessoais necessários. <br></br><br></br>
                Que também devemos utilizar esse tempo para vigilância e fiscalização da outra parte, pois em uma guerra é imprescindível conhecer as movimentações do inimigo, pois é essencial não sermos surpreendido por ele. <br></br><br></br>
                Na história descrita, o reino de Chu revelou com antecedência seus objetivos quando fez uma declaração antecipada de guerra, mas devido a sua prepotência e superioridade numérica, acreditava que não haveria resistência por parte de seu oponente e que a vitória era certa, por isso não se preocupou em esconder suas verdadeiras intenções. <br></br><br></br>
                Sun Tzu provou que a vitória é possível mesmo em condições precárias e inferiores, utilizando-se da surpresa como um forte e decisivo aliado.
                <br></br><br></br>
                A pior estratégia, segundo ele, é a <u>medição de forças</u>. <br></br>
                A saída é focar as fraquezas do adversário para desequilibrar suas forças e assim, preparar-se para o ataque. <br></br><br></br>
                Ele explica que, quando estamos em desvantagem em algum atributo, a melhor tática é atrair o adversário para um cenário no qual temos vantagem.<br></br><br></br>
                Os homens não podem vencer esse conflito enquanto lutarem nesse ambiente hostil e desfavorável. É necessário utilizar armas não convencionais e levar a batalha para um local mais propicio, onde as mulheres não podem utilizar os filhos como escudo, nem utilizar as leis sexistas. <br></br><br></br>
                Esse é o segredo da vitória, quem falar o contrário estará mentindo. <br></br><br></br>
                Por isso, não acredite em pessoas que prometem que haverá justiça em seu caso.  Elas raramente acontecem. <br></br><br></br>
                É necessário um planejamento antecipado, acumular recursos financeiros e realizar ações concretas, porém tudo isso tem como objetivo principal apenas o de minimizar suas perdas, o que já será uma grande vitória diante de um cenário injusto e destrutivo. 


                <br></br><br></br><br></br>
                <h2>Dica de Ouro</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide32} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                Entender e utilizar a estratégia militar descrita anteriormente pode diminuir suas perdas e aumentar suas chances no divórcio, como em qualquer outra área de sua vida, onde esse conceito for aplicado. <br></br><br></br>
                Porém, os elementos que produzem vitória ou derrota em uma batalha são circunstânciais e variam em cada caso e os resultados podem variar e serem incertos, mas quando esse conceito é corretamente aplicado, sempre produzirá algum tipo de benefício vantajoso. <br></br><br></br>
                Infelizmente, não é possível adicionar exemplos mais claros e precisos nesse documento relacionados ao divórcio. Os conceitos devem ser entendidos para poderem ser aplicados de acordo com cada caso e de forma confidencial, sem a ciência do oponente. <br></br><br></br>
                Além de conhecer e aplicar esses ensinamentos, algumas dicas podem ser úteis e valerem ouro se forem utilizadas.
                <br></br><br></br><br></br>
                <b>O QUE FAZER?</b>  
                <br></br><br></br>
                Ninguém poderá responder essa pergunta, nem fazer uma escolha em seu lugar. É uma decisão pessoal. Você terá que assumir as consequências de seus atos. Algumas das possibilidades possíveis são: 
                <br></br><br></br>

                <table border="1">
                <tbody>
                <tr><td bgcolor='#000000' ><b><font COLOR="white">ESPERAR</font></b></td><td width="80%">Muitos homens desejam uma família e participar da criação de seus filhos. Muitos investiram grande parte de suas vidas pessoais e recursos financeiros nesse propósito. Quando percebem os sinais que indicam o final da relação, preferem ignorá-los na esperança de que o cenário se modifique com o tempo. Sabem que o divórcio será um rompimento desse desejo. 
                Então, preferem ingenuamente acreditar no bom senso de seus cônjuges. Afinal, um divórcio seria algo prejudicial para todos, principalmente para os filhos. Infelizmente, a maioria dos homens pensam e agem dessa forma e são totalmente destruídos por causa desse posicionamento. As mulheres são hipergâmicas por natureza, isso faz parte de seu extinto, além de serem incentivadas pela sociedade e possuírem o sistema jurídico ao seu favor. Portanto, pensam e agem de forma totalmente oposta ao descrito. Logo, esperar é a pior escolha possível.
                </td></tr>
                <tr><td bgcolor='#000000' ><b><font COLOR="white">DEFENDER</font></b></td><td width="80%">É um estágio superior ao descrito anteriormente. Muitos homens sabem que a relação irá terminar em divórcio, mas preferem esperar que seus cônjuges realizem o primeiro movimento nesse sentido. Muitos não possuem recursos financeiros, nem mesmo tempo de pensar e planejar isso. 
                Estão presos as dívidas e compromissos da família, adiam essa decisão até as coisas melhorarem para eles e assim realizá-lo. Caso aconteça antes do esperado, acreditam que a justiça estará ao seu lado, e verá com bons olhos seu trabalho e sacrifício. Afinal, é um pai presente e trabalhador. Diferente daqueles homens que aparecem nos jornais, que maltratam suas esposas e filhos. Será um engano fatal, a justiça não levará em consideração seu trabalho ou caráter. Qualquer coisa que faça ou tenha feito será tratada como obrigação, e não produzirá qualquer benefício. Irá perder tempo e dinheiro em sua defesa, e tudo que conquistou ou economizou irá desaparecer. 
                </td></tr>
                <tr><td bgcolor='#FFD700' ><b><font COLOR="white">ATACAR</font></b></td><td width="80%">A maioria dos homens não aceita a realidade dos fatos descritos nos quadros anteriores. São passivos e acreditam que as coisas podem ser realizadas pelos meios diplomáticos. Isso explica o baixo índice de homens que solicitam o divórcio. 
                Sim, guerras são destrutivas e custam muito caro, devendo ser evitadas de todas as formas possíveis. Contudo, existem situações em que a diplomacia não faz efeito, e pode ser desastrosa para um dos lados. Pode ser um meio de seu inimigo se armar e ganhar tempo, enquanto finge realizar negociações de paz. <br></br><br></br>
                <font color='#FFD700'><b><u>É preferível atacar do que ter que se defender</u>.</b></font> <br></br><br></br>
                Atacar primeiro não garante vitória, mas pode diminuir suas perdas e produzir cisão nas tropas inimigas. No caso dos homens, onde todos os elementos são contrários a eles, é uma decisão que irá mitigar suas perdas e aumentar suas chances de algum tipo de vitória.
                </td></tr>
                </tbody>
                </table>

                <br></br><br></br><br></br>
                <b>NÃO TER CONDIÇÕES</b>  
                <br></br><br></br>
                A segurança é uma superstição. Não existe na natureza. Nenhum exército ou pessoa jamais estará 100% preparada. Não ter dinheiro é um problema sério e quase uma garantia de derrota em um processo de divórcio. <br></br><br></br>
                Ficar esperando por ele ou que todas as condições ideais aconteçam para realizá-lo, é insanidade e certeza de fracasso. <br></br><br></br>
                Deverá efetuar um planejamento e diversos preparativos com antecedência, mas terá que efetivá-lo tendo condições ou não. A imobilidade não é uma opção. <br></br><br></br>
                Deverá fazer empréstimos, vender seu patrimônio, e etc. Lembrando-se que fazer isso após o divórcio ter sido inicializado será impossível. Seus bens e recursos estarão indisponíveis, até que as decisões sejam promulgadas. <br></br><br></br>
                Estar em uma situação onde o divórcio pode ocorrer a qualquer instante é algo terrível, mas tentar economizar dinheiro nesse momento, é estupidez. Poderá perder centenas de vezes mais o que tentou preservar. Não ter condições financeiras ou pessoais é algo comum nos dias de hoje. <br></br><br></br>
                Contudo, não deve ser paralisado por causa disso. A história humana está repleta de casos reais, de pessoas que não tinham nada, e foram vitoriosas ao enfrentar situações adversas. <br></br><br></br>
                Porém, não existe nenhum exemplo de pessoas que foram vitoriosas sem nada fazerem.
                
                <br></br><br></br><br></br>
                <b>TERRÍVEL CONCLUSÃO</b>  
                <br></br><br></br>
                As mulheres são estrategistas naturais. <br></br><br></br>
                Muitos divórcios são planejados com grande antecedência, alguns inclusive antes do próprio casamento. Elas são capazes de manipular os homens com incrível capacidade e destreza, levando-os a fazerem o que elas querem. <br></br><br></br>
                Elas são hipergâmicas e terminam facilmente com o casamento quando os homens deixam de oferecer as vantagens exigidas. Nenhuma delas aceita sustentar qualquer homem que seja, em nenhuma circunstância, nem tampouco dividir as despesas igualitariamente com eles. <br></br><br></br>
                Todo o dinheiro adquirido por ele deve ser utilizado em benefício da família, enquanto o da mulher pertence exclusivamente a ela. <br></br><br></br>
                Existe exceções, mas elas são cada vez mais raras, mesmo assim, nenhuma delas abre mão dos direitos legais existentes, sendo eles injustos ou não. <br></br><br></br>
                Os homens devem ser eternos fornecedores das mulheres e realizar seus desejos em todos os níveis. Quando deixam de ser vantajosos, são rapidamente descartados servindo apenas como uma fonte de renda.  <br></br><br></br>
                É durante o divórcio que seu verdadeiro caráter e intensões de seu par são revelados. Nesse instante, muitos homens percebem que foram usados e enganados durante anos. Alguns levam mais tempo, mas acabam chegando na mesma terrível conclusão. <br></br><br></br>
                Como os homens são facilmente manipulados, passam a maior parte do tempo trabalhando para resolver os problemas criados pelo casamento, principalmente por suas mulheres. E por causa disso, não são capazes de enxergar a realidade. <br></br><br></br>
                Quando são descartados acabam descobrindo o quanto foram enganados durante o período em que foram casados ou até mesmo antes disso.  <br></br><br></br>
                Mesmo após o divórcio, muitos continuarão sendo usados e manipulados por elas. As leis e os filhos, utilizados como escudo e garantia, tornam isso possível. Nesses casos não será apenas manipulação e obrigação imposta, será pura maldade para satisfazer seus egos e frustrações. <br></br><br></br>
                Muitos serão levados ao suicídio ou a ruina total. Dificilmente irão se recuperar financeiramente, e tudo será feito para que isso aconteça. Afinal, não podem ter a chance de reagir ou revidar, como ocorre em uma guerra, onde o inimigo deve ser totalmente sobrepujado e jamais poderá ser reaparelhado novamente. 
                <br></br><br></br>
                Diversos homens concordam com o divórcio, mas querem primeiro resolver os problemas existentes antes de realizá-lo. Muitos ainda tem empréstimos, financiamentos, dividas e obrigações decorrentes do casamento. <br></br><br></br>
                Também podem estar doentes, desempregados, com incertezas no local de trabalho, e etc.  Se tiverem filhos, estarão preocupados com seu futuro e bem-estar. <br></br><br></br>
                Principalmente, com os recursos financeiros que serão desviados nessa operação. Recursos que poderiam ser mais uteis em benefício dos filhos do que em gastos desnecessários. <br></br><br></br>
                Então, preferem estar com os problemas resolvidos e com as finanças em dia antes de gastar dinheiro com a justiça. Esperam por uma condição ideal ou por um ponto de equilíbrio que possa ser suportado. 
                
                <br></br><br></br>
                As mulheres não agem nem pensam dessa forma. <br></br><br></br>
                <b><u>A SOCIEDADE E A JUSTIÇA PERMITEM QUE ELAS POSSAM SOLICITAR O DIVÓRCIO A QUALQUER MOMENTO QUE QUEIRAM.</u></b> <br></br><br></br>
                Não terão qualquer custo, nem passarão por qualquer constrangimento. <br></br><br></br>
                Serão vítimas e os homens serão vilões. <br></br><br></br>
                Ficarão com os filhos e as propriedades. <br></br><br></br>
                Os homens ficarão com as dívidas e a obrigação de sustentá-los. <br></br><br></br>
                Todo o custo dessa operação será pago pelos homens. <br></br><br></br>
                Elas não terão qualquer prejuízo financeiro. <br></br><br></br>
                Qualquer problema que venha a ocorrer será culpa dos homens e serão eles que terão que resolver. <br></br><br></br>
                <b><u>PORTANTO, <br></br>NÃO ESPERE OU PLANEJE DEMAIS ANTES DE FAZER ALGUMA COISA.</u></b>

                <br></br><br></br>
                </span>
            </S.FormText>

            </S.Form>
            </>
            :
            <>
            <br></br>
            <br></br>
            <h3>&nbsp;</h3>
            <br></br>
            </>    }  
        </S.Container>
    )
}

export default GuidePart7;