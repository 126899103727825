
import React, { useState, useEffect } from 'react';   
import * as S       from './styles';
import guide22      from '../../../../view/DivorceGuide/Guide/Images/guide22.png';
import guide23      from '../../../../view/DivorceGuide/Guide/Images/guide23.png';
import guide24      from '../../../../view/DivorceGuide/Guide/Images/guide24.png';
import guide25      from '../../../../view/DivorceGuide/Guide/Images/guide25.png';
import guide26      from '../../../../view/DivorceGuide/Guide/Images/guide26.png';
import guide27      from '../../../../view/DivorceGuide/Guide/Images/guide27.png';
import chart4       from '../../../../view/DivorceGuide/Guide/Images/chart4.png';
import chart5       from '../../../../view/DivorceGuide/Guide/Images/chart5.png';
import PopUp        from '../../../../view/DivorceGuide/Guide/Alert/';
import util         from '../../../../Utilities/Util';

function GuidePart4() {
    
    const [click1, setClick1] = useState(false);
    const [click2, setClick2] = useState(false);
    const handleClick1 = () => { setClick1(!click1); }
    const handleClick2 = () => { setClick2(!click2); }
    const [access, setAccess] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)
        setAccess( util.checkGuideAccess() );
    }, [])

    return (
        <S.Container>
        { ( access )
            ?
            <>
            <S.Form> 

            <S.FormTitle>
                <span>PARTE4</span><br></br><br></br><br></br><span>Processos e Procedimentos Legais</span><br></br><br></br>
            </S.FormTitle>

            <S.FormText>
                <span>
                Diferentemente do que as pessoas acreditam, o divórcio não é um procedimento isolado de fácil utilização e rápida conclusão. 
                Ele vem acompanhado de diversos elementos secundários que facilitam ou dificultam sua execução. Muitos deles podem gerar desafetos, consequências negativas e transtornos de longo prazo na vida dos envolvidos. 
                Além de produzir custos e prejuízos não planejados que jamais serão repostos. Tudo sempre dependerá das características do casal, dos profissionais contratados, do tipo de divórcio adotado, e principalmente dos integrantes da justiça selecionados para conduzir sua realização. 
                <br></br><br></br>Conhecer os componentes do divórcio é uma tarefa essencial e necessária para evitar enganos e surpresas desnecessárias. 
    
                <br></br><br></br><br></br><br></br>
                <h2>Divórcio</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide22} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                É a dissolução definitiva do casamento.<br></br><br></br>
                Antes do ano de 2010, o casal era primeiramente obrigado a realizar a separação judicial para depois solicitar o divórcio. Atualmente, isso não é mais necessário e o divórcio pode ser diretamente solicitado. <br></br><br></br>
                Contudo, a separação judicial permanece ativa no ambiente jurídico, como opção de casais que não têm interesse na manutenção da sociedade conjugal existente entre eles, mas que por qualquer razão não querem dissolver o vínculo matrimonial através do divórcio. <br></br><br></br>
                Existem dois tipos de divórcio, o consensual e o litigioso, podendo ser realizados judicialmente ou extrajudicialmente.

                <br></br><br></br><br></br>
                <b>União Conjugal</b><br></br><br></br>
                É bastante comum que pessoas se confundam na hora de definir o que é separação e o que é divórcio. Ambos possuem suas diferenças, apesar das duas situações significarem o fim da união do casal. <br></br><br></br>
                A união conjugal é considerada terminada diante dos seguintes casos, como prevê o <u>Código Civil</u>:<br></br><br></br>
                
                <table border="1" >
                <tbody>
                <tr><td>Morte de um dos cônjuges</td></tr>
                <tr><td>Nulidade ou anulação</td></tr>
                <tr><td>Separação Judicial</td></tr>
                <tr><td>Divórcio</td></tr>
                </tbody>
                </table> 
                <br></br><br></br>

                <b>Morte do Cônjuge</b><br></br><br></br>
                A morte de um dos cônjuges dissolve o casamento, mudando o estado civil do cônjuge sobrevivente para viúvo/a. <br></br><br></br>
                Contudo, caso tenha ocorrido anteriormente a esse fato, um divórcio entre as partes, o seu estado civil permanecerá como divorciado(a), e caso tenha ocorrido uma separação judicial, o seu estado civil será modificado para viúvo(a).
                <br></br><br></br><br></br>

                <b>Morte Presumida</b><br></br><br></br>
                Ocorre quando um dos cônjuges desaparece, ou se envolve em uma situação lúgubre, onde provavelmente a morte tenha ocorrido, mas não existe corpo para atestar o fato, como por exemplo, um acidente de avião, uma guerra, e etc. <br></br><br></br>
                O Código Civil anterior não dissolvia o vínculo conjugal e a parte sobrevivente era impedida de realizar outro matrimônio. <br></br><br></br>
                Hoje existe um tramite legal a ser realizado que possibilita o cônjuge restante mudar seu estado civil para viúvo/a, contudo ele possui diversas peculiaridades e um prazo normal de dez anos para sua conclusão.  <br></br><br></br>
                Enquanto não for finalizado, o patrimônio deixado pelo desaparecido não poderá ser dividido entre seus herdeiros, nem o estado civil do cônjuge restante poderá ser modificado. <br></br><br></br>
                Mas ele poderá solicitar o divórcio antes que o processo mencionado seja finalizado, e modificar seu estado civil para divorciado(a), ficando livre para efetuar novo matrimônio.  <br></br><br></br>
                Porém, se efetuar esse procedimento, não terá mais direito ao patrimônio deixado, quando este for liberado.
                <br></br><br></br><br></br>

                <b>Nulidade ou anulação</b><br></br><br></br>
                É quando o casamento não pode ser realizado, ou já foi realizado, mas não pode ser validado, devido algum impedimento legal de um ou de ambos os cônjuges. <br></br><br></br>
                Os impedimentos matrimoniais estão previstos no artigo 1521 do <u>Código Civil</u>: 
                <br></br><br></br>

                <table border="1" >
                <tbody>
                <tr><td>Ascendentes com os descendentes, seja o parentesco natural ou civil</td></tr>
                <tr><td>Afins em linha reta</td></tr>
                <tr><td>Adotante com quem foi cônjuge do adotado e o adotado com quem o foi do adotante</td></tr>
                <tr><td>Irmãos, unilaterais ou bilaterais, e demais colaterais, até o terceiro grau inclusive</td></tr>
                <tr><td>Adotado com o filho do adotante</td></tr>
                <tr><td>Pessoas casadas</td></tr>
                <tr><td>Cônjuge sobrevivente com o condenado por homicídio ou tentativa de homicídio contra o seu consorte</td></tr>
                </tbody>
                </table>
                <br></br><br></br> 

                <b>Separação Judicial</b><br></br><br></br>
                É a dissolução da Sociedade Conjugal. Neste caso as partes deixam de cumprir com as suas obrigações como fidelidade recíproca, vida em comum no domicílio conjugal, mútua assistência, sustento, respeito e consideração mútuos, e etc. <br></br><br></br>
                Pode acontecer de maneira consensual ou litigiosa. <br></br><br></br>
                Porém, as partes <b>não podem contrair novas núpcias</b> por ainda não romper com o vínculo matrimonial, tendo seu estado civil modificado para separado(a) judicialmente.

                <br></br><br></br><br></br><br></br>
                <b>Estatísticas</b>
                <br></br><br></br><br></br> 
                <S.FormChat><img src={chart4} alt=""></img>
                <center><span>Figura 4 - Divórcios realizados<br></br>No Brasil, um a cada três casamentos termina em divórcio.</span></center>
                </S.FormChat>

                <br></br><br></br>
                Números relativos ao divórcio no Brasil:<br></br><br></br> 

                <table border="1" >
                <tbody>
                <tr><td>50%</td> 
                <td>os casamentos terminam em divórcio.</td> </tr>
                <tr><td>70%</td> 
                <td>dos pedidos de divórcio são feitos pelas mulheres.</td></tr>
                <tr><td>90%</td> 
                <td>da guarda dos filhos são das mulheres.</td></tr>
                <tr><td>80%</td> 
                <td>dos filhos sofrem alienação parental.</td></tr>
                </tbody>
                </table>

                <br></br><br></br>
                Números relativos ao divórcio em outros Países:<br></br><br></br> 

                <table border="1" >
                <tbody>
                <tr> 
                <td>53%</td> 
                <td>ESTADOS UNIDOS</td> 
                </tr>
                <tr> 
                <td>55%</td> 
                <td>FRANÇA</td> 
                </tr>
                <tr> 
                <td>58%</td> 
                <td>ESTÔNIA</td> 
                </tr>
                <tr> 
                <td>63%</td> 
                <td>ESPANHA</td> 
                </tr>
                <tr> 
                <td>67%</td> 
                <td>HUNGRIA</td> 
                </tr>
                <tr> 
                <td>68%</td> 
                <td>PORTUGAL</td> 
                </tr>
                <tr> 
                <td>71%</td> 
                <td>BÉLGICA</td> 
                </tr>
                </tbody>
                </table>

                <br></br><br></br>
                Números relativos ao divórcio nos Estados Unidos:<br></br><br></br> 
               
                <table border="1" >
                <tbody> 
                <tr> 
                <td>66%</td> 
                <td>dos divórcios são solicitados por mulheres</td> 
                </tr>
                <tr> 
                <td>4%</td> 
                <td>dos militares solicitam o divórcio por ano</td> 
                </tr>
                <tr> 
                <td>43%</td> 
                <td>das crianças crescem sem a presença do pai</td> 
                </tr>
                <tr> 
                <td>41%</td> 
                <td>do primeiro casamento termina em divórcio</td> 
                </tr>
                <tr> 
                <td>60%</td> 
                <td>do segundo casamento termina em divórcio</td> 
                </tr>
                <tr> 
                <td>73%</td> 
                <td>do terceiro casamento termina em divórcio</td> 
                </tr>
                <tr> 
                <td>8</td> 
                <td>anos é o tempo médio de um casamento</td> 
                </tr>
                <tr> 
                <td>13</td> 
                <td>segundos é o intervalo entre os pedidos de divórcio</td> 
                </tr>
                </tbody>
                </table>

                <br></br><br></br><br></br><br></br>
                <b>Tipos</b>
                <br></br><br></br>
                <b>Divórcio Consensual</b><br></br><br></br>
                É o divórcio amigável, amistoso, onde as partes estão de acordo com todos os pontos e querem efetuar a dissolução jurídica do casamento de forma cordial, tranquila e civilizada. <br></br><br></br>
                Geralmente, trazem nenhuma ou poucas consequências infelizes para as partes e filhos. <br></br><br></br>
                <i><b>É a melhor forma de realizar um divórcio, evitando perda de tempo e prejuízos financeiros para ambos.</b></i> <br></br><br></br>
                Normalmente tudo é resolvido em uma só audiência e a sentença, para posterior elaboração do mandado de averbação, leva em média três meses para ser proferida. <br></br><br></br>
                Muitos casais após esse tipo de divórcio continuam se relacionando e se ajudando mutuamente, algo muito importante para o desenvolvimento físico e mental de seus filhos e para suas vidas.
                
                <br></br><br></br><br></br>
                <b>Divórcio Extrajudicial</b><br></br><br></br>
                Realizado apenas quando o casal não possuir filhos menores ou incapazes, e onde os cônjuges estão de acordo com todas as questões relacionadas ao divórcio. É realizado em cartório sem a presença de um Juiz. Necessita ser redigido por um advogado para a preservação dos direitos das partes envolvidas e evitar qualquer prejuízo futuro. <br></br><br></br>
                É o divórcio mais rápido e simples que existe, poderá ser feito no mesmo dia ou em até 48 horas. Contudo, caso a mulher esteja grávida o divórcio deverá obrigatoriamente ser feito de forma judicial.
                <br></br><br></br>
                <b>ONLINE</b><br></br><br></br>
                É possível realizar o divórcio de forma online, para isso é necessário que cada um dos cônjuges obtenha um certificado digital obtido de forma gratuita em qualquer tabelionato de notas.<br></br><br></br>
                O procedimento será realizado através de chamada de vídeo, onde o tabelião irá ler os termos da escritura de divórcio que foi elaborado pelo advogado das partes. <br></br><br></br>
                No final, todos os integrantes assinam através de certificado digital, o termo mencionado. Tudo será gravado em vídeo e arquivado. 
                <br></br><br></br>

                Devido a pandemia da COVID-19, as audiências judiciais estão sendo realizadas via chamada de vídeo. <br></br><br></br>
                Provavelmente, após o término deste período pandêmico, elas passarão definitivamente a ser realizadas de forma online para a maioria dos casos.
                <br></br><br></br><br></br>
                <b>Divórcio Judicial</b><br></br><br></br>
                É o divórcio realizado na Justiça. Pode ser consensual ou litigioso. É mais caro e demorado que o divórcio extrajudicial. Será um juiz que irá avaliar o pedido e emitir uma sentença descrevendo os direitos e obrigações das partes envolvidas. <br></br><br></br>
                Casais com filhos são obrigados a realizar um divórcio judicial, mesmo que as partes estejam de comum acordo com tudo, porque haverá o acompanhamento do Ministério Público, que irá analisar e proteger o interesse dos filhos menores. 

                <br></br><br></br><br></br>
                <b>Divórcio Litigioso</b><br></br><br></br>
                É uma denominação que um divórcio judicial pode receber. É todo divórcio judicial em que as partes não estão de acordo com algum ou todos os pontos do processo. 
                Cabe ao juiz analisar e emitir um parecer definitivo sobre todos eles, inclusive sobre a guarda dos filhos e pensão. <br></br><br></br>
                É o tipo de divórcio mais caro e demorado que existe, com consequências imprevisíveis para as partes e filhos existentes.<br></br><br></br>
                Embora o prazo para sua averbação leve em média três meses após o requerimento, os demais pedidos cumulados na ação como partilha de bens, guarda, alimentos e etc. podem fazer o processo demorar de dois a cinco anos. 
                Antes de se lançar na disputa judicial é importante ficar atento à questão financeira. <br></br><br></br><u>
                Não é um tipo de divórcio recomendado, é preferível fazer um acordo ruim entre as partes do que tentar um resultado mais satisfatório na justiça</u>.<br></br><br></br>
                Como pode ser muito demorado, e levar anos para sua conclusão, o juiz decreta a guarda temporária dos filhos a uma das partes, e também uma pensão temporária de valor calculado por ele.
                
                <br></br><br></br><br></br>
                <b>Divórcio de União Estável</b><br></br><br></br>
                União estável é o modelo adotado por pessoas que não estão casadas no civil. 
                A dissolução desse tipo de união pode ser feita de forma consensual ou jurídica. 
                A forma jurídica funciona igual a um divórcio normal ou litigioso, seguindo os mesmos protocolos e produzindo os mesmos resultados.
                Após a dissolução da união, as partes retornam para seu estado civil anterior.
                
                <br></br><br></br><br></br><br></br>
                <h2>Partilha</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide23} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                Não é obrigatória a partilha no divórcio, o casal pode manter os bens em condomínio. Caso contrário, a partilha ou divisão dos bens do casal deve ser feito levando em consideração o regime de bens que foi adotado quando o casamento foi realizado. <br></br><br></br>
                Depois, é necessário identificar e qualificar os bens que serão partilhados. Quais são particulares e quais foram adquiridos durante o casamento. 
                Além dos bens, as dívidas contraídas durante o casamento devem ser divididas pelos cônjuges seguindo os mesmos critérios descritos.
                <br></br><br></br>

                <b>Comunhão Universal</b><br></br><br></br>
                Nesse regime de casamento, todos os bens do casal serão divididos, com exceção daqueles recebidos por herança ou doação com cláusula de incomunicabilidade, ou aqueles comprados com o dinheiro da venda desses bens herdados ou doados, ou bens de uso pessoal e profissional. <br></br><br></br>
                Dependendo da quantidade e de como são os bens que o casal possui, eles poderão ser divididos sem a necessidade de serem vendidos, evitando a depreciação do patrimônio e o tempo gasto nesse procedimento. 
                <br></br><br></br>

                <b>Comunhão Parcial</b><br></br><br></br>
                Nesse regime de casamento, os bens adquiridos antes do casamento ou aqueles que foram recebidos por herança ou doação não serão divididos, somente aqueles que foram adquiridos durante o casamento serão divididos, eles serão partilhados em igual proporção (50% para cada um), ainda que a contribuição financeira dos cônjuges para aquisição do patrimônio tenha sido desigual.
                
                <br></br><br></br>
                Art. 1.659 do Código Civil. Excluem-se da comunhão:
                <br></br><br></br>

                <table border="1" >
                <tbody> 
                <tr><td>os bens que cada cônjuge possuir ao casar, e os que lhe sobrevierem, na constância do casamento, por doação ou sucessão, e os sub-rogados em seu lugar</td> </tr>
                <tr><td>os bens adquiridos com valores exclusivamente pertencentes a um dos cônjuges em sub-rogação dos bens particulares</td> </tr>
                <tr><td>as obrigações anteriores ao casamento</td> </tr>
                <tr><td>as obrigações provenientes de atos ilícitos, salvo reversão em proveito do casal</td> </tr>
                <tr><td>os bens de uso pessoal, os livros e instrumentos de profissão</td> </tr>
                <tr><td>os proventos do trabalho pessoal de cada cônjuge</td> </tr>
                <tr><td>Sas pensões, meios-soldos, montepios e outras rendas semelhantes</td> </tr>
                </tbody>
                </table> 

                <br></br><br></br>
                Art. 1.660 do Código Civil. Entram na comunhão:
                <br></br><br></br>

                <table border="1" >
                <tbody> 
                <tr><td>os bens adquiridos na constância do casamento por título oneroso, ainda que só em nome de um dos cônjuges</td> </tr>
                <tr><td>os bens adquiridos por fato eventual, com ou sem o concurso de trabalho ou despesa anterior</td> </tr>
                <tr><td>os bens adquiridos por doação, herança ou legado, em favor de ambos os cônjuges</td> </tr>
                <tr><td>as benfeitorias em bens particulares de cada cônjuge</td> </tr>
                <tr><td>os frutos dos bens comuns, ou dos particulares de cada cônjuge, percebidos na constância do casamento, ou pendentes ao tempo de cessar a comunhão</td> </tr>
                </tbody>
                </table> 

                <br></br><br></br>
                <b>Separação Total</b><br></br><br></br>
                Nesse regime de casamento, os bens adquiridos antes e durante o casamento, não serão divididos, a não ser que estejam em nome de ambos. Cada cônjuge também responderá pelas dividas que contraiu, exceto as dívidas destinadas em prol da família.
                
                <br></br><br></br><br></br> 
                <b>Financiamentos</b><br></br><br></br>
                O divórcio não representa a quitação de qualquer financiamento realizado. Ele continuará existindo e deverá ser pago. <br></br><br></br>
                O valor já quitado do financiamento será dividido igualmente entre os cônjuges, mesmo que apenas um deles tenha efetuado o pagamento ou havido quitações desiguais. <br></br><br></br>
                É uma situação complicada que depende de análise de vários fatores para determinar a melhor solução. <br></br><br></br>
                Dentre algumas possibilidades, destacam-se:<br></br><br></br>
                
                <table border="1" >
                <tbody> 
                <tr><td>Se apenas um dos cônjuges realizou o financiamento, ele fica com o bem e com a obrigação de pagar o restante da dívida, mas deve <i>indenizar</i> o outro cônjuge no valor correspondente à metade do que foi pago durante o casamento.</td> </tr>
                <tr><td>As partes continuam pagando o financiamento até sua quitação, e ambos se tornem proprietários.</td> </tr>
                <tr><td>Um deles assume o financiamento, mas o valor que já foi quitado deverá ser <i>dividido</i>, conforme já descrito. Contudo, essa opção dependerá que a instituição financeira <i>aprove</i> à exclusão de uma das partes, mediante a aprovação de crédito da parte que deseja permanecer.</td> </tr>
                <tr><td>Nenhum dos cônjuges quer manter o bem financiado. Nesse caso, é necessário a alienação do bem com <i>aprovação</i> dá financeira, mas o valor que já foi quitado deverá ser <i>dividido</i>, conforme já descrito. </td> </tr>
                </tbody> 
                </table> 

                <br></br><br></br>
                <b>Sobrepartilha</b><br></br><br></br>
                A sobrepartilha é utilizada especificamente nas ações de divórcio, nos casos em que a separação e a divisão dos bens do casal já foram devidamente concluídas, mas um dos cônjuges descobre que a outra parte possuía bens que não foram postos na partilha. <br></br><br></br>
                Então é necessária a abertura de nova divisão, para que seja incluído o que ficou de fora. Há um prazo para se entrar na Justiça pedindo sobrepartilha. No  Código Civil, de 2002, esse prazo é de 10 anos. 

                <br></br><br></br><br></br><br></br>
                <h2>Guarda dos Filhos</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide24} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                Ocorre uma mudança de vida quando um casal termina um relacionamento. Os deveres mútuos deixam de existir, e cada um poderá fazer o que quiser com sua vida sem precisar prestar contas ao outro. <br></br><br></br>
                Porém, quando existir filhos menores ou incapazes resultantes dessa união, os direitos e deveres dos pais para com eles permanecem inalterados.  É necessário definir como os filhos serão protegidos e educados, como também determinar de que forma essa tarefa será distribuída entre as partes responsáveis. <br></br><br></br>
                É o poder público que define essas funções e responsabilidades, mesmo havendo consenso entre os cônjuges sobre isso. É um juiz que homologa o acordo entre os pais. Ele determina quem detêm a guarda dos filhos, podendo essa função ser compartilhada entre os pais, conforme o que ele decidir. 
                <br></br><br></br><br></br> 

                <b>Guarda Unilateral dos Filhos</b><br></br><br></br>  
                É a guarda onde apenas uma das partes tem todos os direitos sobre os filhos, enquanto a outra tem apenas a obrigação de sustentá-los através de pensão, e nada mais. <br></br><br></br>
                <u><b>AS MULHERES SÃO PROPRIETÁRIAS DOS FILHOS EXISTENTES</b></u>. <br></br><br></br>
                Eventualmente, podem perder a guarda, quando o pai comprovar alguma conduta inapropriada da mãe que afete o bem-estar dos filhos, bem como atitudes irresponsáveis ou agressivas que causem insegurança, perigos físicos ou psicológicos a eles. 
                As provas devem ser consistentes e muitas delas analisadas e comprovadas através de peritos e assistentes sociais designados pelo Juiz. <br></br><br></br>
                É o tipo de guarda preferido pela justiça e também a mais lucrativa para a indústria do divórcio por gerar inúmeros desentendimentos entre as partes, e produzir diversos processos na tentativa de corrigi-los, com pouco ou nenhum resultado concreto para o solicitante, apenas gasto de tempo e dinheiro. 
                
                <br></br><br></br><br></br>
                <b>Guarda Compartilhada </b><br></br><br></br>
                É quando ambos os pais detêm a guarda jurídica dos filhos, sendo que a guarda física pode ser ou não alternada, depende das condições físicas e financeiras das partes. <br></br><br></br>
                Nesta modalidade elas assinam em conjunto qualquer documento relativo aos filhos, como escola, saúde, e etc. Desse modo ambos estão cientes do que acontece com seus filhos. <br></br><br></br>
                <i><b>Nesse tipo de guarda, a obrigação do pai de sustentá-los através de pensão, não é isenta, nem compartilhada.</b></i><br></br><br></br>
                Também é necessário que as partes estejam em perfeita harmonia para que ela seja autorizada pelo Juiz. <br></br><br></br>
                É um tipo de guarda muito importante, principalmente para evitar o prejuízo dos pais na educação de seus filhos, e corrigir os desvios e injustiças da guarda unilateral. <br></br><br></br>
                <i><b>Contudo é apenas mais uma falácia e não resolve os problemas existentes.</b></i>
                
                <br></br><br></br>
                Analisando os fatos :<br></br><br></br>
                1. É necessário muito dinheiro para realizar a guarda compartilhada. <br></br><br></br>
                <b><i>Condição essa que a maioria da população não se enquadra</i></b>.<br></br><br></br>
                2. Terá que existir patrimônio suficiente após a partilha para que o pai possa construir toda estrutura necessária para receber seu filho, quando for seu período de acolhimento. <br></br><br></br>
                <b><i>Condição essa difícil de existir, porque na maioria dos divórcios realizados, 70% do patrimônio é perdido.</i></b>.<br></br><br></br>
                3. A estrutura descrita, deve ser montada ou existir próxima ao local da outra parte, para não cansar ou prejudicar os filhos com o deslocamento, como escolas e outras atividades que já frequentam.<br></br><br></br>
                4. É concedida <b><u>APENAS</u></b> para casais que estão em <u><b>PERFEITA HARMONIA</b></u>.<br></br><br></br>
                <b><i> Que casal que realiza um divórcio está em perfeita harmonia?<br></br><br></br>Caso estejam, por que eles precisariam de uma lei e da autorização de um juiz para estabelecer como iriam cuidar dos filhos? <br></br><br></br>Já não seria algo natural e decidido anteriormente por eles? <br></br><br></br>Logo, qual é a razão de existir uma exigência dessa?</i></b><br></br><br></br>
                5.Seria possível realizar a guarda compartilhada sem o cumprimento dos itens 1, 2 e 3. <br></br><br></br>
                <b><i>Por exemplo, a alternância física não aconteceria, mas ao menos o pai saberia e participaria das decisões pertinentes a vida de seus filhos, como mudar de escola, permissões médicas, viagens e etc.<br></br><br></br> 
                Inclusive, quando sua situação financeira melhorasse, poderia construir essa estrutura e realizar essa alternância.<br></br><br></br>
                Mas esse tipo de guarda sempre é negado por causa do item 4.</i></b><br></br><br></br>
                É um tipo de guarda que não é aplicada a grande maioria dos casais que solicitam o divórcio e possuem filhos. Mantendo a guarda unilateral como padrão preferido e mais utilizado pela justiça.<br></br><br></br><br></br> 
                <i><b>Portanto, da forma como está sendo aplicada pelas autoridades, se torna uma <u>LEI INÓCUA</u>, sem razão para existir.</b></i>

                <br></br><br></br><br></br><br></br>
                <S.FormChat><img src={chart5} alt=""></img>
                <span>Figura 5 - Distribuição da Guarda</span><br></br>
                <span>Os filhos pertencem as mulheres, nada mudou com a guarda compartilhada. Inclusive, a aguarda unilateral para os homens diminuiu.</span> 
                </S.FormChat>
                <br></br><br></br>

                <b>Guarda Alternada</b><br></br><br></br>
                <i><b>É um tipo de guarda que não existe no Código Civil.</b></i> <br></br><br></br>
                Acontece apenas em situações de jurisprudências autorizadas pelo Juiz. <br></br><br></br>
                É um tipo de guarda em que as partes alternam de tempos em tempos a residência dos filhos. Um tempo com o pai, outro com a mãe. <br></br><br></br>
                Segundo especialistas????, a guarda alternada não é aconselhável, podendo ser prejudicial à saúde e higidez psíquica da criança, tornando confusos certos referenciais importantes na fase inicial de sua formação. <br></br><br></br>
                Em outras palavras, segundo eles, <u><b>O ENDEREÇO É MAIS IMPORTANTE QUE A PRESENÇA DE UM PAI NA FORMAÇÃO DO FILHOS</b></u>. <br></br><br></br>
                É aceitável os traumas que já são causados pelo divórcio, mas qualquer trauma causado por uma mudança de residência, não é aceitável. <br></br><br></br>
                Por exemplo, na guarda unilateral quando a criança for passar dois finais de semana por mês com o pai, que é o prazo máximo concedido pela justiça, neste caso o deslocamento não será prejudicial a sua saúde psíquica, mas segundo eles ficar mais tempo com o pai em um endereço diferente será.
                <br></br><br></br><br></br>

                <b>Alienação Parental</b><br></br><br></br>
                <i><b>Alienação Parental é um crime cometido contra os filhos por um dos genitores, por ambos ou por parentes próximos.</b></i> <br></br><br></br>
                O alienador tenta colocar seus filhos contra a parte oposta, denegrindo de forma perversa e planejada sua imagem e atitudes perante eles. <br></br><br></br>
                Ele manipula psicologicamente a criança que passa a mostrar medo, desrespeito ou hostilidade contra a outra parte, seus amigos, novos cônjuges, ou outros membros de sua família, como tios, avós e etc. <br></br><br></br>
                Geralmente a parte guardiã utiliza de sua posição privilegiada para realizar a alienação dos filhos, criando histórias pejorativas e mentirosas que provoquem repudio e descontentamento deles para com a parte alienada. <br></br><br></br>
                É um ato covarde e traiçoeiro, próprio de pessoas sem caráter e problemáticas. Atos muitas vezes dignos de psicopatas pelo seu grau de frieza, destruição e perversidade. <br></br><br></br>
                Essa ação criminosa provoca prejuízos sérios a criança com efeitos a longo prazo, na maioria das vezes irreversíveis.<br></br><br></br>
                Alguns sintomas podem ser identificados na criança vítima desta situação, tais como: ansiedade, nervosismo, agressividade, depressão, transtorno de identidade, desorganização, isolamento, insegurança, dificuldades de aprendizado, sentimento de culpa, desespero, e diversos outros sentimentos destrutivos. <br></br><br></br>
                A parte afetada deve entrar com uma ação de alienação parental contra a parte alienadora. <br></br><br></br>
                Será necessário apresentar provas consistentes, bem como a utilização de especialistas selecionados pelo juiz para validar e atestar a situação relatada. <br></br><br></br>
                É um processo que custa tempo e dinheiro e quando finalizado, o juiz poderá por lei aplicar uma ou mais das seguintes penalidades ao alienador :
                <br></br><br></br>

                <table border="1" >
                <tbody>
                <tr><td>Declarar a ocorrência de alienação parental e advertir o alienador</td> </tr>
                <tr><td>Ampliar o regime de convivência familiar em favor do genitor alienado</td> </tr>
                <tr><td>Estipular multa ao alienador</td> </tr>
                <tr><td>Determinar acompanhamento psicológico e/ou biopsicossocial</td> </tr>
                <tr><td>Determinar a alteração do tipo de guarda ou sua inversão</td> </tr>
                <tr><td>Determinar a fixação cautelar do domicílio da criança ou adolescente</td> </tr>
                <tr><td>Declarar a suspensão da autoridade parental</td> </tr>
                </tbody>
                </table> 

                <br></br><br></br>
                Apesar de ser um crime de natureza hedionda e de consequências destrutivas e muitas vezes irreversíveis, não prevê o encarceramento do alienador, terminando geralmente apenas em advertência ou multa, e nada mais.  <br></br><br></br>
                Além de existir muita tolerância e amenização em favor do criminoso, atualmente esta lei sofre grande pressão para ser revogada, existindo inclusive projetos de lei pendentes com esse propósito. 
                
                <br></br><br></br><br></br><br></br>
                <h2>Pensão Alimentícia</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide25} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                <i><b>Pensão é chamada de “alimentos” no meio jurídico.</b></i> <br></br><br></br>
                É um valor obrigatório a ser pago para o custeio das despesas de quem não tem meios próprios de subsistência. Dentro desse valor que será calculado e designado por um Juiz, estão os gastos com alimentação, saúde, locomoção, vestuário, lazer, educação e qualquer outro que seja necessário para a sobrevivência da parte solicitante. <br></br><br></br>
                É um benefício designado preferencialmente aos filhos e/ou ex-cônjuges, mas também pode ser concedido a parentes idosos ou incapazes, como pais e avós, quando estes solicitarem. <br></br><br></br>
                Quando por alguma razão a parte culpada não poder cumprir com essa obrigação, ela poderá recair sobre o ascendente de mais alto grau deste, conforme determina o Código Civil descrito a seguir:<br></br><br></br>
                <i>Art. 1.698. Se o parente, que deve alimentos em primeiro lugar, não estiver em condições de suportar totalmente o encargo, serão chamados a concorrer os de grau imediato; sendo várias as pessoas obrigadas a prestar alimentos, todas devem concorrer na proporção dos respectivos recursos, e, intentada ação contra uma delas, poderão as demais ser chamadas a integrar a lide</i>.<br></br><br></br>
                Caso não exista nenhum parente na linha reta de sucessão, a pensão pode ser requisitada aos irmãos de grau mais próximo, cabendo ao juiz a decisão final. É possível perceber que o divórcio é um procedimento que afeta outras pessoas ligadas a parte perdedora, mesmo elas não sendo culpadas dos problemas e desajustes do casamento encerrado. <br></br><br></br>
                A pensão é considerada uma obrigação sagrada e deverá ser paga mesmo que o pagador esteja desempregado ou impossibilitado por qualquer razão. <br></br><br></br>
                A responsabilidade pela criação e sustento dos filhos é de ambos os genitores, e não apenas de um deles, nem tampouco de seus ascendentes ou parentes próximos. <br></br><br></br>
                Contudo, a pensão é tratada como uma obrigação moral e de responsabilidade de apenas um deles. A pensão alimentícia é rodeada de discursos politicamente corretos que tentam justificar sua obrigatoriedade. <br></br><br></br>
                <i><b>Sempre coloca a parte vencedora como vítima, e a parte perdedora como vilã</b></i>. <br></br><br></br>
                Já faz tempo que o ponto de equilíbrio entre as partes foi ultrapassado. Não há nada de sagrado nesse procedimento, ele é cruel, desumano e principalmente impositivo.  Comparado apenas a sistemas escravocratas de eras passadas.
                
                <br></br><br></br><br></br>
                <b>Alimentos Provisórios</b><br></br><br></br>
                Existem dois tipos de alimentos: os provisórios e os definitivos. O alimento provisório é um valor determinado pelo juiz, que terá que ser pago aos filhos menores ou incapazes, até que o valor definitivo tenha sido decretado. <br></br><br></br>
                Quando não existir uma ação de <i>"oferta de alimentos"</i>, este valor poderá ser calculado com base em qualquer critério que o Juiz achar conveniente, geralmente com base nos dados e exigências contidas na inicial processo de divórcio.<br></br><br></br>
                
                {click1 ? <><S.AlertButton><button onClick={ handleClick1 } >Fechar Alerta</button></S.AlertButton><PopUp id="msg03"></PopUp></> : <><S.AlertButton><button onClick={ handleClick1 } >Abrir Alerta</button></S.AlertButton></> }

                <br></br><br></br><br></br>
                <b>Alimentos Definitivos</b><br></br><br></br>
                <i><b>É o valor da pensão a ser pago pelo alimentante.</b></i> <br></br><br></br>
                Quando as partes estão em acordo com relação a esse valor, ele já será decretado na própria audiência do divórcio. <br></br><br></br>
                Contudo, quando houver alguma divergência, a parte responsável pela guarda provisória ou definitiva dos filhos terá que requerer uma ação jurídica denominada de <i>"processo de alimentos"</i>, exigindo os alimentos para os filhos menores ou incapazes. <br></br><br></br>
                Entende-se como alimentos os gastos com alimentação, saúde, locomoção, vestuário, lazer, educação e qualquer outro necessário.  Através desse processo, o juiz ouvirá as argumentações das partes relativas a esse assunto e irá decretar um valor definitivo de alimentos.<br></br><br></br>
                Enquanto o valor definitivo não for calculado, a parte que não possui a guarda dos filhos terá que efetuar o pagamento de um <i>valor provisório</i> decretado anteriormente pelo juiz. <br></br><br></br>
                No final, o valor definitivo poderá ser igual ou diferente do valor provisório que foi estimado.
                
                <br></br><br></br><br></br>
                <b>Processo de Alimentos</b><br></br><br></br>
                É o nome dado ao processo que irá determinar o valor definitivo dos alimentos (pensão). Ele é solicitado pela parte que possui a guarda definitiva ou provisória dos filhos, quando não houve acordo relativo a esse valor na audiência de divórcio. <br></br><br></br>
                O juiz irá determinar o valor definitivo com base nas provas e argumentações contidas nesse processo, bem como permitir que a outra parte se manifeste e apresente suas provas e argumentações, antes de sua conclusão final.
                
                <br></br><br></br><br></br>
                <b>Oferta de Alimentos</b><br></br><br></br>
                Segundo a justiça, o pai tem a obrigação e o dever de sustentar os filhos menores ou incapazes. É raro, mas a mãe poderá perder a guarda dos filhos e também ser obrigada a pagar pensão.<br></br><br></br>
                Portanto, o futuro pagador da pensão, seja ele o pai ou a mãe, poderá demonstrar sua responsabilidade perante essa obrigação, encaminhando ao juiz uma ação denominada de <i>"oferta de alimentos"</i>, onde demonstra através de documentos sua real condição financeira, sugerindo um valor provisório de pensão a ser pago até que o valor definitivo seja calculado e decretado. <br></br><br></br>
                O juiz não é obrigado aceitar o valor sugerido, entretanto, se a proposta enviada estiver bem fundamentada, dificilmente ele deixará de acatar a sugestão.
                
                <br></br><br></br><br></br>
                <b>Alimentos Gravídicos</b><br></br><br></br>
                É uma lei que dá direito a mãe gestante de receber ajuda econômica do pai indicado por ela. <br></br><br></br>
                É obrigação dela apresentar os <i>"indícios de paternidade"</i> por meio de fotos, testemunhas, cartas, ou qualquer outro meio utilizado como prova. <br></br><br></br>
                <b>O princípio da presunção da inocência é completamente ignorado.</b><br></br><br></br>
                Uma vez convencido da existência de indícios da paternidade, o juiz irá definir o valor dos alimentos gravídicos que serão pagos até o nascimento da criança, de acordo com as necessidades da mãe e as possibilidades do pai. <br></br><br></br>
                O valor definido deverá ser suficiente para cobrir as despesas adicionais do período de gravidez e decorrentes, da concepção ao parto, inclusive os referentes a alimentação especial, assistência medica e psicológica, exames complementares, internações, parto, medicamentos e qualquer outra que o juiz considere necessária. <br></br><br></br>
                Caso o suposto pai não tenha condições financeiras para cumprir com essa obrigação, o encargo será transferido para os supostos avós paternos e na falta destes, para os parentes até o segundo grau. <br></br><br></br>
                Durante o período de gestação não será permitido a realização de exames médicos, como de DNA, que possam comprovar a paternidade do suposto pai. Após o nascimento com vida, os alimentos gravídicos ficam automaticamente convertidos em pensão alimentícia em favor do menor até que uma das partes solicite a sua revisão ou exoneração. <br></br><br></br>
                A exoneração será permitida se o alimentante comprovar que não é pai biológico da criança nascida. Portanto, seria irregular sua manutenção como devedor de alimentos. <br></br><br></br>
                Nestas duas ações, não aplica o efeito retroativo, ou seja, o dinheiro gasto não será devolvido. Se após prestar os alimentos exigidos o suposto pai conseguir confirmar negativa de paternidade, ele poderá entrar com uma ação de danos materiais indenizatórios contra a genitora, apresentando, documentos que comprovem os gastos que lhes foram impostos indevidamente, podendo ainda, acrescentar nesta ação o pedido de danos morais, cabendo a ele provar os abalos psicológicos que tenha sofrido. <br></br><br></br>
                Contudo, esse tipo de processo não produz nenhum resultado significativo. Na prática, nada irá acontecer com a mulher que produziu intencionalmente essa situação, ela não será presa nem sofrerá qualquer tipo de sanção. 
                
                <br></br><br></br><br></br>
                <b>Pensão entre Cônjuges</b><br></br><br></br>
                O pagamento de pensão alimentícia também poderá ser realizado entre os cônjuges conforme determina o Código Civil descrito a seguir:<br></br><br></br>
                <i>Art. 1.694. Podem os parentes, os cônjuges ou companheiros pedir uns aos outros os alimentos de que necessitem para viver de modo compatível com a sua condição social, inclusive para atender às necessidades de sua educação.<br></br><br></br>
                § 1º Os alimentos devem ser fixados na proporção das necessidades do reclamante e dos recursos da pessoa obrigada.<br></br>
                § 2º Os alimentos serão apenas os indispensáveis à subsistência, quando a situação de necessidade resultar de culpa de quem os pleiteia.</i><br></br><br></br>
                O ex-cônjuge pode solicitar pensão alimentícia em casos que é comprovada sua dependência econômica, bem como outros fatores. Um argumento muito utilizado é declarar ter dedicado anos de sua vida à família, abrindo mão de sua educação, vida pessoal, profissional e outros. <br></br><br></br>
                <i><b>Portanto, seguindo à lógica desse tipo de argumento, a parte perdedora nunca se dedicou à família, nem abriu mão da sua educação, vida pessoal, profissional, e etc. Foi apenas um aproveitador.</b></i>
                
                <br></br><br></br><br></br>
                <b>Valor da Pensão</b><br></br><br></br>
                O valor deve ser definido levando em consideração a receita e possibilidade de quem paga e as necessidades de quem solicita. <br></br><br></br>
                Normalmente, os valores giram em torno de 10%, 20% ou 30% do rendimento bruto ou líquido da parte alimentante. Na verdade, não existe na legislação nada em relação a esses percentuais. <br></br><br></br>
                <i><b>Trata-se de um mito, uma referência utilizada por muitos magistrados</b></i>. <br></br><br></br>
                Não existe uma regra fixa e objetiva para o arbitramento dos alimentos. Portanto, qualquer percentual ou valor pode ser decretado. <br></br><br></br>
                <i>Inclusive, percentuais muito maiores que os descritos.</i> <br></br><br></br>
                Outros elementos podem influenciar na determinação desse percentual ou valor, como filhos de outra união, despesas com ascendentes, saúde, e etc. <br></br><br></br>
                Quando a parte não possuir emprego fixo, sendo empresário ou autônomo, caberá ao juiz analisar sua vida pessoal e financeira e fixar o valor da pensão com base em salários mínimos. <br></br><br></br>
                Esse percentual ou valor deveria ser definido como base no binômio: <b>Necessidade x Possibilidade</b>, modalidade que tenta estabelecer um ponto de equilíbrio, conforme explicita a lei.<br></br><br></br>
                <i>Art. 1.695. São devidos os alimentos quando quem os pretende não tem bens suficientes, nem pode prover, pelo seu trabalho, à própria manutenção, e aquele, de quem se reclamam, pode fornecê-los, sem desfalque do necessário ao seu sustento.</i><br></br><br></br>
                Além disso, o valor da pensão ainda pode ser calculado com base no trinômio: <b>Necessidade x Possibilidade x Proporcionalidade</b>, de modo que nessa modalidade, além das necessidades do alimentado e possibilidades do alimentante, também será considerada a proporcionalidade entre proventos na fixação do valor da pensão. <br></br><br></br>
                A parte interessada, deve juntar os documentos necessários e solicitar ao juiz, que o valor da pensão seja calculado com base na modalidade escolhida que deverá ser especificada e argumentada. 
                
                <br></br><br></br><br></br>
                <b>Revisão do Valor da Pensão</b><br></br><br></br>
                O valor e as condições da pensão podem ser revistos por qualquer uma das partes envolvidas a qualquer momento desejado. <br></br><br></br>
                É um procedimento realizado através de uma ação denominada <i>"revisional de alimentos"</i> a ser encaminhada para a vara responsável pelo divórcio. <br></br><br></br>
                Como qualquer ação, envolve tempo e dinheiro, sem garantias de sucesso, inclusive poderá produzir o efeito contrário ao que se deseja. <br></br><br></br>
                Após fixada a pensão alimentícia em sentença, considera-se que ela não transita em julgado, ou seja, é possível ação revisional tanto para aumento quando para diminuição do valor, desde haja justificativa plausível.<br></br><br></br>
                <i>Art. 15. A decisão judicial sobre alimentos não transita em julgado e pode a qualquer tempo ser revista, em face da modificação da situação financeira dos interessados</i>.<br></br><br></br>
                O alimentante ao perder o emprego ou ter seu padrão de vida modificado, pode ele solicitar uma diminuição do valor decretado. Da mesma forma, se o alimentante melhorar sua situação de vida, o alimentado pode solicitar um aumento do valor recebido.

                <br></br><br></br><br></br>
                <b>Pensão em Pecúnia</b><br></br><br></br>
                É a forma de pagamento da pensão, neste caso o valor que foi definido pelo Juiz, é depositado diretamente na conta do beneficiário ou de seu responsável legal no caso de filhos menores ou incapazes sem que haja desconto no holerite do depositante.
                
                <br></br><br></br><br></br>
                <b>Pensão in Natura</b><br></br><br></br>
                Nesta forma de pagamento, nenhum valor é depositado. O alimentante paga diretamente as contas do alimentado, como por exemplo: pagamento de boletos bancários, de mensalidades escolares, aluguel, contas de água, luz, plano de saúde, taxa condominial e etc.
                
                <br></br><br></br><br></br>
                <b>Duração da Pensão</b><br></br><br></br>
                A duração da obrigatoriedade da pensão pode variar para cada caso. A pensão definida para a ex-cônjuge geralmente tem um prazo de validade estipulado. <br></br><br></br>
                Porém, em alguns casos podem se tornar definitivas. Isso acontece quando a pessoa não tiver mais idade para ingresso no mercado de trabalho ou problemas de saúde. <br></br><br></br>
                No entanto, quando falamos de pensionamento para os filhos, os prazos para seu término podem variar muito e normalmente se enquadram nessas possibilidades: 

                <br></br><br></br> 
                <table border="1" >
                <tbody>
                <tr><td>Até a maioridade, 18 anos</td> </tr>
                <tr><td>Até a conclusão dos estudos em nível superior, em média 24 anos</td> </tr>
                <tr><td>Até a completa inserção no mercado de trabalho (sem tempo definido)</td></tr>
                </tbody>
                </table> 

                <br></br><br></br>
                Portanto, devido a tecnicidade e morosidade da justiça pode durar eternamente, sem prazo definido para sua finalização. <br></br><br></br>
                Ainda na questão dos filhos, vemos que existem outros casos excepcionais, como casos de invalidez, doença, necessidade com medicamentos, e qualquer dificuldade alegada que irá contribuir para a continuidade da pensão indefinidamente. <br></br><br></br>
                A última palavra sempre será do juiz, enquanto ele achar que deve ser paga, o devedor terá de arcar com o valor acordado. Valor este que poderá sempre variar, para mais ou para menos.
                
                <br></br><br></br><br></br>
                <b>Exoneração da Pensão Alimentícia </b><br></br><br></br>
                É uma ação que o alimentante deve ajuizar para que sua obrigação de alimentos deixe de existir. <br></br><br></br>
                Sem esse procedimento, o pagamento obrigatório não irá terminar, mesmo que o filho atinja a maioridade. Somente o juiz poderá decretar o fim da pensão. Mesmo assim, apenas depois de ter recebido o pedido, ouvido e analisado a situação do alimentado. <br></br><br></br>
                Por exemplo, se o filho comprovar que é estudante e necessita da pensão para custear seus estudos, essa obrigação será mantida até que ele complete 24 anos. 
                Mesmo atingindo essa idade, mas se ainda não tiver capacidade de se manter através de seu próprio trabalho e recursos, a pensão será mantida. <br></br><br></br>
                Provavelmente, indefinidamente, pois é um tipo de argumento ou situação criada por pessoas fracas, acomodadas e sem caráter. <br></br><br></br>
                <i><b>Portanto, somente através de uma ação de exoneração aprovada pelo juiz, o pagamento obrigatório da pensão alimentícia será extinto.</b></i>

                <br></br><br></br><br></br><br></br>
                <h2>Não Pagamento da Pensão Alimentícia</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide26} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                Normalmente, o não pagamento ou atraso por três meses poderá acarretar em prisão do alimentante, mas é possível requerer em juízo o pagamento da dívida sob pena de prisão a partir do primeiro mês de atraso. <br></br><br></br>
                <i><b>O dinheiro deverá aparecer por mágica ou decreto, não importando qual seja a condição do devedor.</b></i> <br></br><br></br>
                Ele deverá explicar ao juiz o motivo de sua indisponibilidade, porém não existe nenhum local especifico para efetuar esse procedimento. <br></br><br></br>
                Então, deverá ser realizado através da justiça com o pagamento de custas e advogado, ou seja, <u><b>TERÁ QUE GASTAR DINHEIRO PARA PROVAR QUE NÃO TEM DINHEIRO</b></u>. <br></br><br></br>
                É bem razoável, não tem dinheiro para pagar a pensão, mas terá dinheiro para pagar o processo, e assim provar que não tem dinheiro para pagar a pensão. <br></br><br></br>
                Enquanto esse processo estiver sendo julgado, o alimentante não poderá ser preso nem sofrer sanções. Um processo desse tipo, resulta apenas em parcelamento ou adiamento da dívida, mas ela terá que ser quitada de alguma forma. <br></br><br></br>
                Sobre os pagamentos atrasados incide multa e correção. O cálculo é feito mês a mês, corrigidos pelo índice do IGPM +1% ao mês. Diversas medidas podem ser utilizadas para obrigar o devedor a pagar essa dívida. <br></br><br></br>
                Algumas pessoas poderão achar que tais medidas são extremas e injustas, no entanto, o que se vislumbra é que o alimentado tenha seu direito resguardado. <br></br><br></br>
                Afinal, estar desempregado ou não ter dinheiro é um crime hediondo, pois temos a sorte de residir em um país de primeiro mundo, uma potência econômica, cheia de oportunidades e generosos salários, onde não existe desemprego, ignorância ou lei injustas ou mal formuladas feitas por pessoas que vivem fora da realidade.
                
                <br></br><br></br><br></br>
                <b>Prisão</b><br></br><br></br>
                A medida mais grave consiste na prisão civil. <br></br><br></br>Antes que aconteça, o juiz mandará intimar pessoalmente o devedor para que em três dias, efetue o pagamento das três últimas parcelas vencidas, ou provar que já foram pagas ou justificar sua impossibilidade de pagamento.<br></br><br></br>
                <i>
                § 1º Se o devedor não pagar, nem se escusar, o juiz decretar irá decretar sua prisão pelo prazo de um a três meses.<br></br>
                § 2º O cumprimento da pena não exime o devedor do pagamento das prestações vencidas e vincendas.<br></br>
                § 3º Paga a prestação alimentícia, o juiz suspenderá o cumprimento da ordem de prisão.<br></br><br></br>
                </i>
                A prisão será cumprida em regime fechado por um período de até três meses. <br></br><br></br>
                Caso o devedor esteja empregado, será demitido por justa causa. <br></br><br></br>
                Contudo, o novo Código de Processo Civil (CPC), está mais rígido para o devedor de pensão alimentícia. <br></br><br></br>
                O juiz poderá encaminhar o caso ao Ministério Público como crime de abandono material, podendo o devedor passar de um a quatro anos preso por causa de sua dívida, e ainda ter que pagar uma multa de até dez salários mínimos. <br></br><br></br>
                É bem lógico, não tem dinheiro para pagar a pensão, mas terá para pagar a multa. <br></br><br></br>
                <i>Art. 244 do Código Penal, o crime de abandono material consiste na recusa injustificada do infrator de prover materialmente com o necessário para a subsistência da vítima; pagar pensão alimentícia; ou deixar de socorrer ascendente ou descendente sem justa causa.</i><br></br><br></br>
                <b><u>A PRISÃO NÃO ELIMINA A DÍVIDA</u></b>, após seu cumprimento, o criminoso continuará como devedor, podendo sofrer consequências legais, inclusive com nova prisão. <br></br><br></br>
                Lembrando que ele estará desempregado, mas isso é apenas um pequeno detalhe.
                
                <br></br><br></br><br></br>
                <b>Proteção ao crédito</b><br></br><br></br>
                O devedor de pensão também poderá ter seu nome inscrito nos órgãos de proteção ao crédito (SPC) ou no cadastro do Serasa, e sofrer consequências como não poder solicitar empréstimos, realizar transações com cheque, entre outras. <br></br><br></br>
                Quando o devedor tiver seu nome inscrito nesses órgãos, não conseguirá ser admitido novamente se estiver desempregado, caso esteja, provavelmente perderá o emprego, também não conseguirá fazer empréstimos para quitar a dívida de alimentos ou qualquer outra. <br></br><br></br>
                <i><b>Mais uma prova de bom senso de nossos legisladores.</b></i><br></br><br></br><br></br>
                
                <b>Desconto em Folha de Pagamento</b><br></br><br></br>
                Como norma, aqueles que tem a pensão alimentícia descontada diretamente de seu holerite, tem um percentual limitado em 30% sobre seus rendimentos. O juiz poderá expandir esse limite a um percentual de 50% de seus ganhos líquidos, até que toda dívida seja devidamente quitada.<br></br><br></br> 
                <i>
                Art. 529 da Lei 13105/15<br></br>
                § 3o Sem prejuízo do pagamento dos alimentos vincendos, o débito objeto de execução pode ser descontado dos rendimentos ou rendas do executado, de forma parcelada, nos termos do caput deste artigo, contanto que, somado à parcela devida, não ultrapasse cinquenta por cento de seus ganhos líquidos.</i>
                
                <br></br><br></br><br></br>
                <b>Outras Sanções</b><br></br><br></br>
                Outras medidas além das mencionadas, será requerer a penhora de bens como contas bancárias, veículos, imóveis e etc. <br></br><br></br>
                Também será possível pedir a penhora do fundo de garantia, que pode ser utilizado para pagar os débitos alimentares. <br></br><br></br>
                Poderá ainda ter o passaporte e a carteira de habilitação apreendidos e os cartões de crédito suspensos, ou qualquer outra medida que vise destruir o devedor e tornar o pagamento da dívida impossível. <br></br><br></br>
                <i><b>Como já foi dito anteriormente, não ter recursos financeiros é algo inaceitável em nossa sociedade justa, rica e produtiva</b></i>.

                <br></br><br></br><br></br><br></br>
                <h2>Separação de Corpos</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide27} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                É um mecanismo jurídico utilizado para produzir o afastamento de um dos cônjuges ou companheiro do domicilio onde o casal habita. <br></br><br></br>
                Sua autorização se baseia em dois fatores, quando há ameaça ou consumação de violência física, psicológica ou social de um dos cônjuges contra o outro, ou contra os filhos, dando automaticamente direito de permanÇencia no imóvel e guarda dos filhos para a pessoa que o solicitou. <br></br><br></br>
                Também pode ser voluntariamente solicitado por um dos cônjuges, por razão da convivência ter se tornado insuportável ou qualquer outro motivo, e principalmente para que seu afastamento não configure abandono de lar. <br></br><br></br>
                Um afastamento por mais de dois anos pode configurar usucapião em favor do cônjuge ou companheiro que permaneceu no imóvel. <br></br><br></br>
                O advogado da parte interessada simplesmente redige um documento descrevendo uma situação familiar insustentável, violenta e perigosa para o solicitante e seus filhos, que é recebido e acatado pela justiça, com ou sem provas para autenticar o pedido efetuado. <br></br><br></br>
                <i><b>A separação de corpos é um mecanismo perverso, onde a parte solicitante destrói sem qualquer pudor ou consequência, a vida da outra parte.</b></i><br></br><br></br><br></br>
                
                {click2 ? <><S.AlertButton><button onClick={ handleClick2 } >Fechar Alerta</button></S.AlertButton><PopUp id="msg04"></PopUp></> : <><S.AlertButton><button onClick={ handleClick2 } >Abrir Alerta</button></S.AlertButton></> }

                <br></br><br></br>
                </span>
            </S.FormText>

            </S.Form>
            </>
            :
            <>
            <br></br>
            <br></br>
            <h3>&nbsp;</h3>
            <br></br>
            </>    }  
        </S.Container>
    )
}

export default GuidePart4;