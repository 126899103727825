import React        from 'react';
import { i18n }     from '../translate/i18n';

class Message  extends React.Component {

    getMessage = code =>  { 

        switch(code) {
            case  1: return i18n.t('msgs.code1');
            case  2: return i18n.t('msgs.code2');
            case  3: return i18n.t('msgs.code3');           
            case  4: return i18n.t('msgs.code4'); 
            case  5: return i18n.t('msgs.code5'); 
            case 22: return i18n.t('msgs.code22');
            case 27: return i18n.t('msgs.code27');
            case 28: return i18n.t('msgs.code28'); 
            case 29: return i18n.t('msgs.code29');
            case 99: return i18n.t('msgs.code99');         

            default:
              return 'MESSAGE NOT FOUND (' + code + ")";
          }
     }

}

export default new Message();