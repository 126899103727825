import React,{useState}     from 'react';
import * as S               from '../../Book/Home/styles';
import video1               from '../video/movie1.mp4';
import cover                from '../Images/cover.png';

function BookVideo() {

    const [control, setControl] = useState(false);

    function videoControls() { 
        let vid = document.getElementById("myVideo"); 

        if ( control ) {
            setControl(false)
            vid.pause()
        } else {
            setControl(true)
            vid.play()
        }

      }
      
    return ( 
        <>
        <S.MidiaButton onClick={videoControls}>
            <S.VideoShow id="myVideo" poster={cover}><source src={video1} type="video/mp4"/></S.VideoShow>
        </S.MidiaButton>
        </>
     );
}

export default BookVideo;  
//<S.VideoShow controls controlslist="nodownload noplaybackrate noremoteplayback" disablePictureInPicture poster={cover}><source src={video1} type="video/mp4"/></S.VideoShow>