import { messages as languagePT } from './pt';
import { messages as languageEN } from './en';
import { messages as languageES } from './es';

const messages = {
    ...languageEN,
    ...languageES,
    ...languagePT
}

export { messages }; 