import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'userLogged',
  initialState: { value: 0  },
  reducers: {
    userSiteLogin : state => { state.value = 1 },
    userSiteLogout: state => { state.value = 0 }
  }
})

export const { userSiteLogin, userSiteLogout } = userSlice.actions
export default userSlice.reducer