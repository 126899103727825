import React,{useState}     from 'react';
import * as S               from '../../Book/Home/styles';
import * as G               from '../../../../global/globalStyles';
import * as GV              from '../../../../global/globalVariables';
import imageTop1            from '../../Book/Images/image01.jpg';
import image3               from '../../Book/Images/image03.jpg';
import image4               from '../../Book/Images/image04.jpg';
import image5               from '../../Guide/Images/guide02.png';
import image6               from '../../Guide/Images/guide03.png';
import image7               from '../../Guide/Images/guide24.png';
import MenuBookGuide        from '../../Book/Home/menu';
import Trailer              from '../../Book/Home/trailer';
import Acquire              from '../../Book/Home/acquire';
import Contact              from '../../../../components/Mail';
import { i18n }             from '../../../../translate/i18n';
import VideoGuide           from '../../Book/Home/video';

function BookHome() {
    
    const [counter, setCounter] = useState(0);
    const [size,    setSize   ] = useState(((window.innerWidth)*60)/100);
    const [flag1,   setFlag1  ] = useState(false);
    const [flag2,   setFlag2  ] = useState(false);
    const [flag3,   setFlag3  ] = useState(false);
    const [language           ] = useState(window.localStorage.getItem(GV.I18N_STORAGE_KEY).toLowerCase());
    //const [access,  setAccess ] = useState(false);

    const temp = [image5,image6,image3,image7]
    const msg  = [i18n.t('guide.bookDesc3'),i18n.t('guide.bookDesc4'),i18n.t('guide.bookDesc5'),i18n.t('guide.bookDesc6')]
    
    React.useEffect(() => {
        //window.scrollTo(0, 0);
        //setAccess( util.checkGuideAccess() );

        if (language === 'es-es') setFlag2(true); else
        if (language === 'pt-br') setFlag3(true); else
        setFlag1(true);

        const handleWindowResize = () => {
            setSize(((window.innerWidth)*60)/100)

        };

        window.addEventListener('resize', handleWindowResize);
 
        const timer =
          counter < 4 ? setInterval(() => setCounter(counter + 1), 9000): setCounter(0)
        return () => clearInterval(timer);

    }, [counter]);
    
    return (
        <S.Container>

            <MenuBookGuide></MenuBookGuide>

            <S.TopImageContainer size={size} background={imageTop1}>
                <S.HeaderApp>{i18n.t('header.book1')}</S.HeaderApp>
            </S.TopImageContainer>

            { (flag3) &&
            <>
            <br></br><br></br> <br></br><br></br> 
            <div id="about"></div>
            <S.TitleOption>{i18n.t('guide.menu1')}</S.TitleOption>
            <S.DescOption>
            <span>
            <h3>O autor desse livro compartilha o conhecimento adquirido a respeito do divórcio e suas consequências na vida das pessoas envolvidas, principalmente dos homens.</h3><br></br><br></br> 
            Os homens não são mais julgados com justiça ou bom senso, e sempre terão que pagar as contas, tendo condições ou não de realizar essa tarefa.<br></br>   
            Muitos deles ainda discordam desse cenário e acreditam na justiça, principalmente porque conhecem pessoas que realizaram o divórcio sem qualquer problema.<br></br>   
            Cada caso é único e não significa que será desastroso ou tranquilo para todos, sempre dependerá do contexto existente e principalmente dos valores morais dos envolvidos.<br></br><br></br>   
            <b>Contudo, as leis atuais são rígidas e foram desenvolvidas para derrotar os homens.</b><br></br><br></br><br></br>
            A grande maioria deles não aceita essa realidade preferindo acreditar que tudo isso são apenas casos infelizes e circunstanciais, que são falhas da justiça que eventualmente poderão ser corrigidas.<br></br>   
            Acreditam que com eles tudo irá ocorrer de forma rápida e tranquila, que não se deve perder tempo nem dinheiro com qualquer tipo de estudo ou preparação.<br></br><br></br>    
            <b>No final, será um engano fatal para aqueles que agirem e pensarem dessa forma.</b><br></br><br></br><br></br>
            O guia mostra essa realidade e os elementos envolvidos nessa operação, demonstra as leis e os procedimentos existentes, e fornece algumas soluções que devem ser seguidas para evitar ou reduzir as perdas pessoais e financeiras produzidas pelo divórcio.<br></br><br></br> 
            <h3>Contar com a sorte ou com o bom senso do sistema judical ou com a habilidade do advogado selecionado, não é garantia de sucesso nem de final feliz.</h3>
            <br></br><br></br> <br></br><br></br>  
            </span>
            </S.DescOption>

            <S.TopImageContainer size={size} background={temp[counter]}>
                <S.BookPhrase>{msg[counter]}</S.BookPhrase>
            </S.TopImageContainer>

            <S.DescOption>
            <br></br> 
            <span>
            Compreender o processo de divórcio e as suas potenciais consequências pode ajudar os homens a tomar decisões informadas e a navegar pelas complexidades jurídicas e emocionais envolvidas.<br></br><br></br> 
            <u>Aqui estão algumas razões pelas quais os homens deveriam adquirir este livro:</u><br></br><br></br>
            <h4>1. Compreendendo o Processo Legal:</h4><br></br>
            Estudar e aprender sobre o divórcio pode ajudar os homens a compreender o processo legal envolvido, incluindo as etapas, requisitos e resultados potenciais.<br></br> 
            Este conhecimento pode capacitar os homens a tomar decisões informadas e proteger os seus direitos durante o processo de divórcio.
            <br></br><br></br>
            <h4>2. Consciência das Implicações Financeiras:</h4><br></br>
            O divórcio geralmente envolve a divisão de bens, propriedades e finanças. Ao estudar e aprender sobre o divórcio, os homens podem compreender as implicações financeiras e as potenciais consequências da divisão de bens, pensão alimentícia e outras questões financeiras.<br></br> 
            Esse conhecimento pode ajudar os homens a planejar e tomar decisões sobre seu futuro financeiro.
            <br></br><br></br>
            <h4>3. Preparação Emocional:</h4><br></br>
            O divórcio pode ser emocionalmente desafiador para ambas as partes envolvidas. Estudar e aprender sobre o divórcio pode ajudar os homens a prepararem-se para os aspectos emocionais do processo, incluindo lidar com o stress, gerir emoções e procurar apoio. <br></br>
            Compreender os desafios emocionais pode ajudar os homens a navegar no processo de divórcio com maior resiliência e bem-estar.
            <br></br><br></br>
            <h4>4. Responsabilidades Parentais e Guarda dos Filhos:</h4><br></br>
            Para os homens que têm filhos, estudar e aprender sobre o divórcio pode fornecer informações sobre as responsabilidades parentais, as leis de custódia dos filhos e os acordos de co-parentalidade. <br></br>
            Compreender estes aspectos pode ajudar os homens a proteger os seus direitos parentais e garantir que os melhores interesses dos seus filhos sejam considerados durante o processo de divórcio.
            <br></br><br></br>
            <h4>5. Evitando armadilhas comuns:</h4><br></br>
            Ao estudar e aprender sobre o divórcio, os homens podem tomar consciência das armadilhas e erros comuns que outras pessoas cometeram durante o processo. <br></br>
            Este conhecimento pode ajudar os homens a evitar erros dispendiosos e a tomar decisões mais informadas, levando a um resultado de divórcio mais tranquilo e favorável.
            <br></br><br></br>            
            <br></br><br></br>
            </span>
            </S.DescOption>
            <S.DivisionLine></S.DivisionLine>
            <div id="features"></div>
            <S.TitleOption>{i18n.t('guide.menu6')}</S.TitleOption>
            <S.DescOption>
            <span>
            <i>
            Sou o resultado de uma guerra silenciosa que ocorre diante de nós, mas não damos conta nem importância até que seja tarde.<br></br><br></br>
            <b>Não sou médico, psicólogo, jurista ou advogado</b>.<br></br><br></br>
            Sou alguém que através de muito sofrimento e desilusão adquiriu vasto conhecimento de como sobreviver, lidar e até mesmo vencer essa guerra invisível e destrutiva.<br></br><br></br>
            Não forneço garantias nem prometo vitórias, ofereço apenas a verdade e mecanismos para sobreviver, mas a luta será travada por você.<br></br><br></br>
            Oferecer a verdade já é uma grande vitória e vantagem contra seus adversários e para seu crescimento pessoal, mas também ensino a evitar muitos erros e sofrimentos desnecessários.<br></br><br></br>
            <b>Acredite! Nenhum dos <u>especialistas</u> que você irá procurar, fará isso.</b><br></br><br></br>
            <br></br><br></br>
            </i>
            </span>
            </S.DescOption>
            <S.AboutImage><img src={image4} alt=''></img></S.AboutImage>
            <S.DivisionLine></S.DivisionLine>
            <div id="download"></div>
            <Acquire></Acquire>
            <S.DivisionLine></S.DivisionLine>
            <div id="contact"></div>
            <S.TitleOption>{i18n.t('guide.menu4')}</S.TitleOption>
            <S.GuideDescTitle>{i18n.t('guide.menu4Title')}</S.GuideDescTitle>
            <S.GuideDesc>{i18n.t('guide.menu4Desc')}</S.GuideDesc>
            <Contact local={GV.CONTACT_LOCAL_BOOK_DIVORCE_GUIDE}></Contact>
            <G.ContainerSpaceShort></G.ContainerSpaceShort>
            <Trailer></Trailer>
            </>
            }

            { (flag1) &&
            <>
            <br></br><br></br> <br></br><br></br> 
            <div id="about"></div>
            <S.TitleOption>{i18n.t('guide.menu1')}</S.TitleOption>
            <S.DescOption>
            <span>
            <h3>The author of this book shares the knowledge acquired about divorce and its consequences in the lives of the people involved, especially men.</h3><br></br><br></br>
             Men are no longer judged with justice or common sense, and will always have to pay the bills, whether or not they are able to carry out this task.<br></br>
             Many of them still disagree with this scenario and believe in justice, mainly because they know people who have divorced without any problems.<br></br>
             Each case is unique and does not mean that it will be disastrous or peaceful for everyone, it will always depend on the existing context and mainly on the moral values of those involved.<br></br><br></br>
             <b>However, current laws are strict and were developed to defeat men.</b><br></br><br></br><br></br>
             The vast majority of them do not accept this reality, preferring to believe that all of these are just unfortunate and circumstantial cases, that they are failures of justice that can eventually be corrected.<br></br>
             They believe that with them everything will happen quickly and smoothly, that one should not waste time or money on any type of study or preparation.<br></br><br></br>
             <b>In the end, it will be a fatal mistake for those who act and think this way.</b><br></br><br></br><br></br>
             The guide shows this reality and the elements involved in this operation, demonstrates the existing laws and procedures, and provides some solutions that must be followed to avoid or reduce personal and financial losses caused by divorce.<br></br><br></br>
             <h3>Relying on luck or the common sense of the judicial system or the skill of the lawyer selected is no guarantee of success or a happy ending.</h3>
            <br></br><br></br> <br></br><br></br>  
            </span>
            </S.DescOption>

            <VideoGuide></VideoGuide>

            <S.DescOption>
            <br></br> 
            <span>
            Understanding the divorce process and its potential consequences can help men make informed decisions and navigate the legal and emotional complexities involved.<br></br><br></br>
            <u>Here are some reasons why men should get this book:</u><br></br><br></br>
            <h4>1. Understanding the Legal Process:</h4><br></br>
            Studying and learning about divorce can help men understand the legal process involved, including the steps, requirements, and potential outcomes.<br></br>
            This knowledge can empower men to make informed decisions and protect their rights during the divorce process.
            <br></br><br></br>
            <h4>2. Awareness of Financial Implications:</h4><br></br>
            Divorce generally involves the division of assets, property and finances. By studying and learning about divorce, men can understand the financial implications and potential consequences of property division, alimony, and other financial issues.<br></br>
            This knowledge can help men plan and make decisions about their financial future.
            <br></br><br></br>
            <h4>3. Emotional Preparation:</h4><br></br>
            Divorce can be emotionally challenging for both parties involved. Studying and learning about divorce can help men prepare for the emotional aspects of the process, including dealing with stress, managing emotions and seeking support. <br></br>
            Understanding emotional challenges can help men navigate the divorce process with greater resilience and well-being.
            <br></br><br></br>
            <h4>4. Parental Responsibilities and Child Custody:</h4><br></br>
            For men who have children, studying and learning about divorce can provide information about parental responsibilities, child custody laws, and co-parenting agreements. <br></br>
            Understanding these aspects can help men protect their parental rights and ensure that their children's best interests are considered during the divorce process.
            <br></br><br></br>
            <h4>5. Avoiding common pitfalls:</h4><br></br>
            By studying and learning about divorce, men can become aware of common pitfalls and mistakes that others have made during the process. <br></br>
            This knowledge can help men avoid costly mistakes and make more informed decisions, leading to a smoother, more favorable divorce outcome.
            <br></br><br></br>            
            <br></br><br></br>
            </span>
            </S.DescOption>
            <S.DivisionLine></S.DivisionLine>
            <div id="features"></div>
            <S.TitleOption>{i18n.t('guide.menu6')}</S.TitleOption>
            <S.DescOption>
            <span>
            <i>
            I am the result of a silent war that takes place before us, but we don't realize it or care about it until it's too late.<br></br><br></br>
            <b>I am not a doctor, psychologist, jurist or lawyer</b>.<br></br><br></br>
            I am someone who through much suffering and disappointment has acquired vast knowledge of how to survive, deal with and even win this invisible and destructive war.<br></br><br></br>
            I do not provide guarantees or promise victories, I only offer the truth and mechanisms to survive, but the fight will be fought by you.<br></br><br></br>
            Offering the truth is already a great victory and advantage against your opponents and for your personal growth, but it also teaches you how to avoid many mistakes and unnecessary suffering.<br></br><br></br>
            <b>Believe! None of the <u>specialists</u> you will see will do this.</b><br></br><br></br>
            <br></br><br></br>
            </i>
            </span>
            </S.DescOption>
            <S.AboutImage><img src={image4} alt=''></img></S.AboutImage>
            <S.DivisionLine></S.DivisionLine>
            <div id="download"></div>
            <Acquire></Acquire>
            <S.DivisionLine></S.DivisionLine>
            <div id="contact"></div>
            <S.TitleOption>{i18n.t('guide.menu4')}</S.TitleOption>
            <S.GuideDescTitle>{i18n.t('guide.menu4Title')}</S.GuideDescTitle>
            <S.GuideDesc>{i18n.t('guide.menu4Desc')}</S.GuideDesc>
            <Contact local={GV.CONTACT_LOCAL_BOOK_DIVORCE_GUIDE}></Contact>
            <G.ContainerSpaceShort></G.ContainerSpaceShort>
            <Trailer></Trailer>
            </>
            }

            { (flag2) &&
            <>
            <br></br><br></br> <br></br><br></br> 
            <div id="about"></div>
            <S.TitleOption>{i18n.t('guide.menu1')}</S.TitleOption>
            <S.DescOption>
            <span>
            <h3>El autor de este libro comparte los conocimientos adquiridos sobre el divorcio y sus consecuencias en la vida de las personas involucradas, especialmente los hombres.</h3><br></br><br></br>
             Los hombres ya no son juzgados con justicia ni sentido común, y siempre tendrán que pagar las cuentas, sean o no capaces de realizar esta tarea.<br></br>
             Muchos de ellos todavía no están de acuerdo con este escenario y creen en la justicia, principalmente porque conocen a personas que se han divorciado sin ningún problema.<br></br>
             Cada caso es único y no significa que vaya a ser desastroso o pacífico para todos, siempre dependerá del contexto existente y principalmente de los valores morales de los involucrados.<br></br><br></ br>
             <b>Sin embargo, las leyes actuales son estrictas y fueron desarrolladas para derrotar a los hombres.</b><br></br><br></br><br></br>
             La gran mayoría de ellos no acepta esta realidad, prefiriendo creer que todos estos son sólo casos desafortunados y circunstanciales, que son fallas de la justicia que eventualmente pueden corregirse.<br></br>
             Creen que con ellos todo sucederá rápido y sin problemas, que no se debe perder tiempo ni dinero en ningún tipo de estudio o preparación.<br></br><br></br>
             <b>Al final, será un error fatal para quienes actúan y piensan de esta manera.</b><br></br><br></br><br></br>
             La guía muestra esta realidad y los elementos involucrados en esta operación, demuestra las leyes y procedimientos existentes y brinda algunas soluciones que se deben seguir para evitar o reducir las pérdidas personales y financieras causadas por el divorcio.<br></br><br></br>
             <h3>Confiar en la suerte o en el sentido común del sistema judicial o en la habilidad del abogado seleccionado no es garantía de éxito ni de un final feliz.</h3>
            <br></br><br></br> <br></br><br></br>  
            </span>
            </S.DescOption>

            <S.TopImageContainer size={size} background={temp[counter]}>
                <S.BookPhrase>{msg[counter]}</S.BookPhrase>
            </S.TopImageContainer>

            <S.DescOption>
            <br></br> 
            <span>
            Comprender el proceso de divorcio y sus posibles consecuencias puede ayudar a los hombres a tomar decisiones informadas y navegar por las complejidades legales y emocionales involucradas.<br></br><br></br>
            <u>Aquí hay algunas razones por las que los hombres deberían leer este libro:</u><br></br><br></br>
            <h4>1. Comprender el proceso legal:</h4><br></br>
            Estudiar y aprender sobre el divorcio puede ayudar a los hombres a comprender el proceso legal involucrado, incluidos los pasos, los requisitos y los posibles resultados.<br></br>
            Este conocimiento puede empoderar a los hombres para tomar decisiones informadas y proteger sus derechos durante el proceso de divorcio.
            <br></br><br></br>
            <h4>2. Conciencia de las implicaciones financieras:</h4><br></br>
            El divorcio generalmente implica la división de bienes, propiedades y finanzas. Al estudiar y aprender sobre el divorcio, los hombres pueden comprender las implicaciones financieras y las posibles consecuencias de la división de bienes, la pensión alimenticia y otras cuestiones financieras.<br></br>
            Este conocimiento puede ayudar a los hombres a planificar y tomar decisiones sobre su futuro financiero.
            <br></br><br></br>
            <h4>3. Preparación Emocional:</h4><br></br>
            El divorcio puede ser un desafío emocional para ambas partes involucradas. Estudiar y aprender sobre el divorcio puede ayudar a los hombres a prepararse para los aspectos emocionales del proceso, incluido el manejo del estrés, el manejo de las emociones y la búsqueda de apoyo. <br></br>
            Comprender los desafíos emocionales puede ayudar a los hombres a afrontar el proceso de divorcio con mayor resiliencia y bienestar.
            <br></br><br></br>
            <h4>4. Responsabilidades de los padres y custodia de los hijos:</h4><br></br>
            Para los hombres que tienen hijos, estudiar y aprender sobre el divorcio puede proporcionarles información sobre las responsabilidades parentales, las leyes de custodia de los hijos y los acuerdos de paternidad compartida. <br></br>
            Comprender estos aspectos puede ayudar a los hombres a proteger sus derechos de paternidad y garantizar que se tenga en cuenta el interés superior de sus hijos durante el proceso de divorcio.
            <br></br><br></br>
            <h4>5. Evitar errores comunes:</h4><br></br>
             Al estudiar y aprender sobre el divorcio, los hombres pueden tomar conciencia de los obstáculos y errores comunes que otros han cometido durante el proceso. <br></br>
             Este conocimiento puede ayudar a los hombres a evitar errores costosos y tomar decisiones más informadas, lo que conducirá a un resultado de divorcio más fluido y favorable.
            <br></br><br></br>            
            <br></br><br></br>
            </span>
            </S.DescOption>
            <S.DivisionLine></S.DivisionLine>
            <div id="features"></div>
            <S.TitleOption>{i18n.t('guide.menu6')}</S.TitleOption>
            <S.DescOption>
            <span>
            <i>
            Soy el resultado de una guerra silenciosa que tiene lugar ante nosotros, pero no nos damos cuenta ni nos preocupamos hasta que es demasiado tarde.<br></br><br></br>
            <b>No soy médico, psicólogo, jurista ni abogado</b>.<br></br><br></br>
            Soy alguien que a través de mucho sufrimiento y decepción ha adquirido un vasto conocimiento sobre cómo sobrevivir, afrontar e incluso ganar esta guerra invisible y destructiva.<br></br><br></br>
            No doy garantías ni prometo victorias, solo ofrezco la verdad y mecanismos para sobrevivir, pero la lucha la darás tú.<br></br><br></br>
            Ofrecer la verdad ya es una gran victoria y ventaja frente a tus oponentes y para tu crecimiento personal, pero también te enseña a evitar muchos errores y sufrimientos innecesarios.<br></br><br></br>
            <b>¡Cree! Ninguno de los <u>especialistas</u> que verás hará esto.</b><br></br><br></br>
            <br></br><br></br>
            </i>
            </span>
            </S.DescOption>
            <S.AboutImage><img src={image4} alt=''></img></S.AboutImage>
            <S.DivisionLine></S.DivisionLine>
            <div id="download"></div>
            <Acquire></Acquire>
            <S.DivisionLine></S.DivisionLine>
            <div id="contact"></div>
            <S.TitleOption>{i18n.t('guide.menu4')}</S.TitleOption>
            <S.GuideDescTitle>{i18n.t('guide.menu4Title')}</S.GuideDescTitle>
            <S.GuideDesc>{i18n.t('guide.menu4Desc')}</S.GuideDesc>
            <Contact local={GV.CONTACT_LOCAL_BOOK_DIVORCE_GUIDE}></Contact>
            <G.ContainerSpaceShort></G.ContainerSpaceShort>
            <Trailer></Trailer>
            </>
            }

        </S.Container>
    )
}

export default BookHome;