import React, {useState}                from 'react';
import * as S                           from './styles';
import * as GV                          from '../../../global/globalVariables';
import { i18n }                         from '../../../translate/i18n';
import Counter                          from './counter';

function Statistic() {

    const [access, setAccess] = useState(true);

    const countries1 = [
        { id: '1', name: 'Maldives', rate: '(5.5)' },
        { id: '2', name: 'Kazakhstan', rate: '(4.6)' },
        { id: '3', name: 'Russia', rate: '(3.9)​' },
        { id: '4', name: 'Belarus and Belgium', rate: '(3.7)' },
        { id: '5', name: 'Moldova', rate: '(3.3)​' },
        { id: '6', name: 'China', rate: '(3.2)' },
        { id: '7', name: 'Cuba', rate: '(2.9)' },
        { id: '8', name: 'Ukraine', rate: '(2.8' },
        { id: '9', name: 'Denmark, Latvia, Lithuania, and U.S.A.', rate: '(2.7)' },
      ];

    const countries2 = [
        { id: '1', name: 'Sri Lanka', rate: '(.15)​' },
        { id: '2', name: 'Guatemala and Vietnam', rate: '(.20)' },
        { id: '3', name: 'Saint Vincent and the Grenadines', rate: '(.40)​' },
        { id: '4', name: 'Peru', rate: '(.50)' },
        { id: '5', name: 'South Africa', rate: '(.60)' },
        { id: '6', name: 'Chile, Columbia, Ireland, Malta, Panama, Qatar, Saint Lucia, UAE, and Venezuela', rate: '(.70)' },
      ];

    const causes = [
        { id: '1',  name: i18n.t('guide.divorceCause01') },
        { id: '2',  name: i18n.t('guide.divorceCause02') },
        { id: '3',  name: i18n.t('guide.divorceCause03') },
        { id: '4',  name: i18n.t('guide.divorceCause04') },
        { id: '5',  name: i18n.t('guide.divorceCause05') },
        { id: '6',  name: i18n.t('guide.divorceCause06') },
        { id: '7',  name: i18n.t('guide.divorceCause07') },
        { id: '8',  name: i18n.t('guide.divorceCause08') },
        { id: '9',  name: i18n.t('guide.divorceCause09') },
        { id: '10', name: i18n.t('guide.divorceCause10') },
    ];

    const general = [
        { id: '1',  name: i18n.t('guide.general01') },
        { id: '2',  name: i18n.t('guide.general02') },
        { id: '3',  name: i18n.t('guide.general03') },
        { id: '4',  name: i18n.t('guide.general04') },
        { id: '5',  name: i18n.t('guide.general05') },
        { id: '6',  name: i18n.t('guide.general06') },
        { id: '7',  name: i18n.t('guide.general07') },
        { id: '8',  name: i18n.t('guide.general08') },
        { id: '9',  name: i18n.t('guide.general09') },
        { id: '10', name: i18n.t('guide.general10') },
        { id: '11', name: i18n.t('guide.general11') },
        { id: '12', name: i18n.t('guide.general12') },
        { id: '13', name: i18n.t('guide.general13') },
        { id: '14', name: i18n.t('guide.general14') },
    ];

    React.useEffect(() => {
        //window.scrollTo({ top: 0, behavior: 'smooth' });
  
        setAccess(false);

        let form     = new URLSearchParams(window.location.search);
        let language = form.get('lng');

        if (language === null)
           return;

        if ( (language !== 'es-ES') && (language !== 'pt-BR') && (language !== 'en-US') )
           return;

        setAccess(true);

        let storage = window.localStorage.getItem(GV.I18N_STORAGE_KEY);
 
        if (storage === language) 
           return; 

        window.localStorage.setItem(GV.I18N_STORAGE_KEY, language);
        //window.location.reload();
     
      }, []);
 
    return (
        <S.Container>
        { ( access )
            ?
            <>
            <S.Title><span>{ i18n.t('guide.title') }</span></S.Title>
            <S.ContainerData>
            <S.SideLeft>&nbsp;</S.SideLeft>

            <S.CenterData>
                <S.Description><p>{ i18n.t('guide.description1') }</p></S.Description>
                <br></br>
                <S.SubTitle><span>{ i18n.t('guide.crudeRate') }</span></S.SubTitle>
                <S.Description><p>{ i18n.t('guide.description2') }</p></S.Description>
                <S.Formula><span>{ i18n.t('guide.formula1') }</span></S.Formula>
                <br></br>
                <S.Note><span>{ i18n.t('guide.note') }</span></S.Note>
                <br></br>
                <br></br>
                <S.SubTitle><span>{ i18n.t('guide.crudeDivorceRate') }</span></S.SubTitle>
                <S.Description><p>{ i18n.t('guide.description3') }</p></S.Description>
                <S.Formula><span>{ i18n.t('guide.formula2') }</span></S.Formula>
                <br></br>
                <S.Description><p>{ i18n.t('guide.description4') }</p></S.Description>
                <br></br>
                <br></br>
                <S.SubTitle><span>{ i18n.t('guide.divorceRate') }</span></S.SubTitle>
                <S.Description><p>{ i18n.t('guide.description5') }</p></S.Description>
                <S.List>
                    <ol>
                        {countries1.map(item => (
                        <li key={item.id}>
                            <div>{item.name}&nbsp;{item.rate}</div>
                        </li>
                        ))}
                    </ol> 
                </S.List>
                <br></br>
                <S.Note><span>{ i18n.t('guide.noteList') }</span></S.Note>
                <br></br>
                <br></br>
                <S.Description><p>{ i18n.t('guide.description6') }</p></S.Description>
                <S.List>
                    <ol>
                        {countries2.map(item => (
                        <li key={item.id}>
                            <div>{item.name}&nbsp;{item.rate}</div>
                        </li>
                        ))}
                    </ol> 
                </S.List>
                <br></br>
                <br></br>
                <S.SubTitle><span>{ i18n.t('guide.divorceUSA') }</span></S.SubTitle>
                <S.Description><p>{ i18n.t('guide.description7') }</p></S.Description>
                <br></br>
                <S.CounterType>
                    <S.CounterFrameEmpty>&nbsp;</S.CounterFrameEmpty>
                    <S.CounterFrame>
                        <span id="title_counter">{ i18n.t('guide.numberWeddings') }</span>
                        <span><Counter interval={GV.WEDDING_INTERVAL} value1={GV.WEDDING_VALUE_DAY} value2={GV.WEDDING_VALUE_HOUR} value3={GV.WEDDING_VALUE_MINUTE}></Counter></span>
                    </S.CounterFrame>
                    <S.CounterFrameEmpty>&nbsp;</S.CounterFrameEmpty>
                </S.CounterType>
                <br></br>
                <S.CounterType>
                    <S.CounterFrameEmpty>&nbsp;</S.CounterFrameEmpty>
                    <S.CounterFrame>
                        <span id="title_counter">{ i18n.t('guide.numberDivorces') }</span>
                        <span><Counter interval={GV.DIVORCE_INTERVAL} value1={GV.DIVORCE_VALUE_DAY} value2={GV.DIVORCE_VALUE_HOUR} value3={GV.DIVORCE_VALUE_MINUTE}></Counter></span>
                    </S.CounterFrame>
                    <S.CounterFrameEmpty>&nbsp;</S.CounterFrameEmpty>
                </S.CounterType>
                <br></br>
                <br></br>
                <S.Description><p>{ i18n.t('guide.description8') }</p></S.Description>
                <br></br>
                <br></br>
                <S.SubTitle><span>{ i18n.t('guide.divorceCause') }</span></S.SubTitle>
                <S.Description><p>{ i18n.t('guide.description9') }</p></S.Description>
                <S.List>
                    <ol>
                        {causes.map(item => (
                        <li key={item.id}>
                            <div>{item.name}</div>
                        </li>
                        ))}
                    </ol> 
                </S.List>
                <br></br>
                <br></br>
                <S.SubTitle><span>{ i18n.t('guide.generalDivorce') }</span></S.SubTitle>
                <S.List>
                    <ul>
                        {general.map(item => (
                        <li key={item.id}>
                            <div>{item.name}&nbsp;{item.rate}</div>
                        </li>
                        ))}
                    </ul> 
                </S.List>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                </S.CenterData>
            <S.SideRight>&nbsp;</S.SideRight>
            </S.ContainerData>
            </>
            :
            <>
            <br></br>
            <br></br>
            <h3>&nbsp;</h3>
            <br></br>
            </>    }                
        </S.Container>
    )
}

export default Statistic;
