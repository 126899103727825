import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

export const FormDesc = styled.div`
    background: none;
    width: 100%;
    text-align: center;
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    margin-bottom: 10px;

    @media screen and (max-width: 960px) {
        text-align: left;
    }
`

export const FormImage = styled.div`

        display: flex;
        justify-content: center;  
        align-items: center;    
        background: #FFFFFF;

        @media screen and (max-width: 960px) {

            img {
                width: 100%;
            }
            
        }
`

export const HeaderApp = styled.div`
    background: none;
    font-size: 58px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    text-decoration: none;
    font-weight: bold;
    color: #4F7C96;
    margin-top: 30px;
    margin-bottom: 10px;

    @media screen and (max-width: 960px) {
        font-size: 38px;
    }
    }
`

export const Title = styled.div`
    background: none;
    width: 100%;
    border: none;
    color: #4F7C96;
    text-align: center;
    text-decoration: none; 
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
    font-size: 24px;
    margin-top: 30px;
    margin-bottom: 15px;

    @media screen and (max-width: 960px) {
        text-align: left;
    }
`

export const ContainerData = styled.div`
    background: none;
    width: 100%;
    margin-top:50px;
    display: flex;
    flex-direction: row;
`

export const CenterData = styled.div`
    background: none;
    width: 96%;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const SideLeft = styled.div`
    background: none;
    width: 2%;
`

export const SideRight = styled.div`
    background: none;
    width: 2%;
`