import React, { useState }  from 'react';
import * as S               from '../../LifePlan/Home/styles';
import * as G               from '../../../global/globalVariables';
import flag1                from '../../../assets/gb.png';
import flag2                from '../../../assets/es.png';
import flag3                from '../../../assets/pt.png';
import { i18n }             from '../../../translate/i18n';

function MenuPasswordWallet() {

    const [language,  setLanguage ] = useState(window.localStorage.getItem(G.I18N_STORAGE_KEY));
    const [isDesktop, setIsDesktop] = useState(window.innerWidth >= G.LIMIT_DESKTOP ? true : false);

    React.useEffect(() => {

        function handleResize() {
           
          if ( window.innerWidth > G.LIMIT_DESKTOP ) 
             {
             setIsDesktop(true);
             }
          else 
             { 
             setIsDesktop(false);
             }
        }
    
        window.addEventListener('resize', handleResize);

    })

    function handleChangeLanguage(event) {

        window.localStorage.setItem(G.I18N_STORAGE_KEY, event.target.value);
        window.location.reload();
        let language = window.localStorage.getItem(G.I18N_STORAGE_KEY);
        setLanguage(language);

    }

    function flagLanguage() { 
    
        let language = window.localStorage.getItem(G.I18N_STORAGE_KEY);

        if (language === 'es-ES') return flag2;
        if (language === 'pt-BR') return flag3;
        return flag1;
    }

    return ( 
        <S.Container>
            <S.LanguageApp>
                <img src={flagLanguage()} alt=''></img>   
                <span>&nbsp;&nbsp;</span>
                <select onChange={handleChangeLanguage} value={language}>
                    <option value="en-US">English  </option>
                    <option value="es-ES">Español  </option>
                    <option value="pt-BR">Português</option>
                </select>
                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </S.LanguageApp>

            { (isDesktop) 
            ?
            <S.MenuContainer size={true}>
                <S.MenuOption1><S.MenuLink><a href="#about"   >{i18n.t('guide.menu1')}</a>&nbsp;|&nbsp;</S.MenuLink></S.MenuOption1>
                <S.MenuOption2><S.MenuLink><a href="#features">{i18n.t('guide.menu2')}</a>&nbsp;|&nbsp;</S.MenuLink></S.MenuOption2>
                <S.MenuOption2><S.MenuLink><a href="#download">{i18n.t('guide.menu3')}</a>&nbsp;|&nbsp;</S.MenuLink></S.MenuOption2> 
                <S.MenuOption2><S.MenuLink><a href="#contact" >{i18n.t('guide.menu4')}</a>&nbsp;|&nbsp;</S.MenuLink></S.MenuOption2> 
                <S.MenuOption3><S.MenuLink><a href="#others"  >{i18n.t('guide.menu5')}</a>&nbsp; &nbsp;</S.MenuLink></S.MenuOption3>
            </S.MenuContainer> 
            :
            <>
            <S.MenuContainer size={true}>
                <S.MenuOption1><S.MenuLink><a href="#about"   >{i18n.t('guide.menu1')}</a>&nbsp;|&nbsp;</S.MenuLink></S.MenuOption1>
                <S.MenuOption2><S.MenuLink><a href="#features">{i18n.t('guide.menu2')}</a>&nbsp;|&nbsp;</S.MenuLink></S.MenuOption2>
                <S.MenuOption2><S.MenuLink><a href="#download">{i18n.t('guide.menu3')}</a>&nbsp;|&nbsp;</S.MenuLink></S.MenuOption2> 
            </S.MenuContainer> 

            <S.MenuContainer size={false}>
                <S.MenuOption1><S.MenuLink><a href="#contact" >{i18n.t('guide.menu4')}</a>&nbsp;|&nbsp;</S.MenuLink></S.MenuOption1> 
                <S.MenuOption3><S.MenuLink><a href="#others"  >{i18n.t('guide.menu5')}</a>&nbsp; &nbsp;</S.MenuLink></S.MenuOption3>
            </S.MenuContainer> 
            </>
            }

        </S.Container>
     );
}

export default MenuPasswordWallet;