import React, {useState}                from 'react';
import * as S                           from './styles';
import guide03                          from '../Guide/Images/guide03.png'
import util                             from '../../../Utilities/Util'

function Element4() {

    const [english,    setEnglish]    = useState(false);
    const [spanish,    setSpanish]    = useState(false);
    const [portuguese, setPortuguese] = useState(false);

    React.useEffect(() => {
  
      const result = util.getLanguage();

      setEnglish   (result.english);
      setSpanish   (result.spanish);
      setPortuguese(result.portuguese);

      }, []);
 
    return (
        <S.Container>

            <S.FormImage><img src={guide03} alt=""></img></S.FormImage>

            { (portuguese) &&
            <S.ContainerData>
                <S.SideLeft>&nbsp;</S.SideLeft>
                <S.CenterData>
                    <S.Title>DINHEIRO</S.Title>
                    <S.FormDesc>
<span>É a principal matéria prima em um processo de divórcio.<br></br><br></br>
Não acredite na justiça gratuita.<br></br><br></br>
<b><u>Nada é gratuito</u></b>.<br></br><br></br>
Tudo custa alguma coisa para as pessoas envolvidas.<br></br><br></br>
Os divórcios amigáveis são mais baratos, mas também precisam ser pagos.<br></br><br></br><br></br>
<b>QUANDO O DIVÓRCIO NÃO É AMISTOSO, MUITO DINHEIRO SERÁ NECESSÁRIO</b>.<br></br><br></br>
<b>QUANTO MAIOR FOR O DESAJUSTE ENTRE O CASAL, MAIOR SERÁ O GASTO FINANCEIRO</b>.<br></br><br></br><br></br>
A justiça é toda fragmentada, ou seja, não é possível em um mesmo processo discutir ou reivindicar múltiplos pontos.<br></br><br></br>
Cada um deles terá que ser um processo diferente, com o pagamento de custas e advogado.<br></br><br></br>
Recorrer sobre decisões promulgadas, também gastam recursos financeiros.
Além disso, o juiz tem o direito e o poder de decidir quem pagará às custas de cada processo.<br></br><br></br>
É um sistema deficiente, injusto e ultrapassado, não espere bom senso, compreensão ou compaixão sobre sua situação pessoal e financeira.<br></br><br></br>
Alguém sempre terá que pagar as contas.<br></br><br></br>
A <b><u>indústria do litígio</u></b> é um negócio altamente lucrativo que incentiva a disputa entre as partes, oferecendo ganhos fáceis e rápidos, que na maioria dos casos, nunca irão acontecer.<br></br><br></br>
São apenas promessas vazias para produzir ilusões e acirrar os ânimos entre as partes, e assim alimentar esse sistema com novos processos.<br></br><br></br>
Infelizmente, existem profissionais inescrupulosos especialistas em fazer esse tipo de coisa.<br></br><br></br>
É um momento muito delicado na vida das pessoas, muitas estarão desorientadas, amedrontadas, magoadas e enfurecidas, sendo vítimas perfeitas desse tipo de gente.<br></br><br></br>
O divórcio litigioso é algo terrível e muito dispendioso, tudo deve ser feito para evitá-lo. Quando não for possível, não deve ser realizado com raiva, magoas ou sentimentos inflamados.<br></br><br></br>
Os ânimos já estarão exaltados quando um casamento é desfeito dessa forma, mas na medida do possível terá que ser feito com frieza e objetividade visando atingir resultados realistas que possam ser concretizados.<br></br><br></br><br></br>
<b>NÃO SE DEVE DESPERDIÇAR RECURSOS FINANCEIROS EM LUTAS DESNECESSÁRIAS</b>.<br></br><br></br>
<b>ELES DEVEM SER UTILIZADOS EM SEU BENEFÍCIO E DE SEUS FILHOS</b>.<br></br><br></br></span>
                    </S.FormDesc>
                    <br></br> <br></br> <br></br>                     
                </S.CenterData>
                <S.SideRight>&nbsp;</S.SideRight>
            </S.ContainerData> 
            }

            { (spanish) &&
            <S.ContainerData>
                <S.SideLeft>&nbsp;</S.SideLeft>
                <S.CenterData>
                     <S.Title>DINERO</S.Title>
                     <S.FormDesc>
<span>Es la principal materia prima en un proceso de divorcio.<br></br><br></br>
No creas en la justicia gratuita.<br></br><br></br>
<b><u>Nada es gratis</u></b>.<br></br><br></br>
Todo cuesta algo para las personas involucradas.<br></br><br></br>
Los divorcios amistosos son más baratos, pero también hay que pagarlos.<br></br><br></br><br></br>
<b>CUANDO UN DIVORCIO NO ES AMISTOSO SE NECESITA MUCHO DINERO</b>.<br></br><br></br>
<b>CUANTO MAYOR ES EL ENGAÑO ENTRE LA PAREJA, MAYORES SON LOS GASTOS FINANCIEROS</b>.<br></br><br></br><br></br>
La justicia está toda fragmentada, es decir, no es posible en un mismo proceso discutir o reclamar múltiples puntos.<br></br><br></br>
Cada uno de ellos tendrá que ser un proceso diferente, con pago de costas y abogado.<br></br><br></br>
Recurrir a decisiones promulgadas también gasta recursos financieros.
Además, el juez tiene el derecho y la facultad de decidir quién pagará las costas de cada caso.<br></br><br></br>
Es un sistema deficiente, injusto y obsoleto, no esperes sentido común, comprensión o compasión sobre tu situación personal y financiera.<br></br><br></br>
Alguien siempre tendrá que pagar las facturas.<br></br><br></br>
La <b><u>industria de los litigios</u></b> es un negocio altamente rentable que fomenta las disputas entre las partes, ofreciendo victorias rápidas y fáciles, que en la mayoría de los casos nunca sucederán.<br></br><br></br>
Son solo promesas vacías para producir ilusiones y agitar los ánimos entre las partes, y así alimentar este sistema con nuevos procesos.<br></br><br></br>
Desafortunadamente, hay profesionales sin escrúpulos que se especializan en hacer este tipo de cosas.<br></br><br></br>
Es un momento muy delicado en la vida de las personas, muchos estarán desorientados, asustados, dolidos y enojados, siendo perfectas víctimas de este tipo de personas.<br></br><br></br>
El divorcio en disputa es algo terrible y muy costoso, se debe hacer todo lo posible para evitarlo. Cuando esto no sea posible, no debe hacerse con ira, dolor o sentimientos inflamados.<br></br><br></br>
Los ánimos ya están exaltados cuando se rompe un matrimonio de esta manera, pero en la medida de lo posible, deberá hacerse con frialdad y objetividad para lograr resultados realistas que se puedan lograr.<br></br><br></br>
<b>NO DESPERDICIE RECURSOS FINANCIEROS EN PELEAS INNECESARIAS</b>.<br></br>
<b>DEBEN UTILIZARSE PARA SU BENEFICIO Y EL DE SUS HIJOS</b>.</span>
                     </S.FormDesc>
                     <br></br> <br></br> <br></br>
                </S.CenterData>
                <S.SideRight>&nbsp;</S.SideRight>
            </S.ContainerData> 
            }           

            { (english) &&
            <S.ContainerData>
                <S.SideLeft>&nbsp;</S.SideLeft>
                <S.CenterData>
                    <S.Title>MONEY</S.Title>
                    <S.FormDesc>
<span>It is the main raw material in a divorce process.<br></br><br></br>
Do not believe in free justice.<br></br><br></br>
<b><u>Nothing is free</u></b>.<br></br><br></br>
Everything costs something to the people involved.<br></br><br></br>
Amicable divorces are cheaper, but they also need to be paid for.<br></br><br></br><br></br>
<b>WHEN A DIVORCE IS NOT FRIENDLY, A LOT OF MONEY WILL BE NEEDED</b>.<br></br><br></br>
<b>THE BIGGER THE MISLEADING BETWEEN THE COUPLE, THE BIGGER THE FINANCIAL EXPENSES</b>.<br></br><br></br><br></br>
Justice is all fragmented, it is not possible in the same process to discuss or claim multiple points.<br></br><br></br>
Each of them will have to be a different process with the payment of costs and a lawyer.<br></br><br></br>
Resorting to enacted decisions also spends financial resources.
Furthermore the judge has the right and the power to decide who will pay the costs of each case.<br></br><br></br>
It is a deficient, unfair and outdated system do not expect common sense, understanding or compassion about your personal and financial situation.<br></br><br></br>
Someone will always have to pay the bills.<br></br><br></br>
The <b><u>litigation industry</u></b> is a highly profitable business that encourages disputes between parties offering quick and easy wins which in most cases will never happen.<br></br><br></br>
They are just empty promises to produce illusions and stir up tempers between the parties and thus feed this system with new processes.<br></br><br></br>
Unfortunately there are unscrupulous professionals who specialize in doing this sort of thing.<br></br><br></br>
It is a very delicate moment in people\'s lives many will be disoriented, frightened, hurt and angry, being perfect victims of this type of people.<br></br><br></br>
Disputed divorce is something terrible and very expensive everything must be done to avoid it. When this is not possible it should not be done with anger, hurt or inflamed feelings.<br></br><br></br>
Tempers are already high when a marriage is broken up in this way, but as far as possible it will have to be done with coolness and objectivity in order to achieve realistic results that can be achieved.<br></br><br></br>
<b>DO NOT WASTE FINANCIAL RESOURCES ON UNNECESSARY FIGHTS</b>.<br></br>
<b>THEY SHOULD BE USED FOR YOUR BENEFIT AND THAT OF YOUR CHILDREN</b>.</span>
                    </S.FormDesc>
                    <br></br> <br></br> <br></br>
            </S.CenterData>
            <S.SideRight>&nbsp;</S.SideRight>
        </S.ContainerData> 
            }
        </S.Container>
    )
}

export default Element4;