
import React, { useEffect, useState } from 'react';   
import * as S       from './styles';
import guide32      from '../../../../view/DivorceGuide/Guide/Images/guide32.png';
import guide33      from '../../../../view/DivorceGuide/Guide/Images/guide33.png';
import guide34      from '../../../../view/DivorceGuide/Guide/Images/guide34.png';
import util         from '../../../../Utilities/Util';

function GuidePart7() {
    
    const [access, setAccess] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)
        setAccess( util.checkGuideAccess() );
    }, [])

    return (
        <S.Container>
        { ( access )
            ?
            <>
            <S.Form> 

            <S.FormTitle>
                <span>PARTE7</span><br></br><br></br><br></br><span>Victoria y Planificación</span><br></br><br></br>
            </S.FormTitle>

            <S.FormText>
                <span>

                Luchar por la igualdad es un derecho inherente a todos los seres humanos. <br></br><br></br>
                Nuestra historia está llena de conflictos en este sentido. Siempre ha habido injusticias y desigualdades que fueron combatidas por quienes no las aceptaban o no estaban de acuerdo con ellas. <br></br><br></br>
                Sin embargo, cuando esta lucha pierde el equilibrio tiene el efecto contrario. Así que quienes en un principio lucharon por estos valores acaban convirtiéndose en terribles verdugos sin límites ni compasión. Produciendo dolor, sufrimiento, desigualdad y injusticia, muchas veces mayores o peores que las que originalmente se combatieron. <br></br><br></br>
                Si bien resultan victoriosos en sus reclamos, nunca se conforman con los resultados obtenidos y continúan exigiendo cada vez más derechos y beneficios a su favor. <br></br><br></br>
                Las leyes y procedimientos que regulaban la separación de parejas fueron cuestionadas y modificadas para eliminar las desigualdades y injusticias existentes. <br></br><br></br>
                Sin embargo, el punto de equilibrio en este procedimiento hace tiempo que fue superado y ahora solo se inclina hacia un lado y esta discrepancia no deja de crecer. <br></br><br></br>
                Actualmente, los hombres son los más afectados y sus posibilidades de divorciarse no superan el 5% de todos los procesos realizados. <br></br><br></br>
                <b><i>Si hay alguna victoria cuando una familia se separa</i></b>.<br></br><br></br>
                Aun así, crecen las leyes que sólo favorecen a las mujeres y las pocas que pueden producir algún tipo de control o justicia para los hombres están siendo eliminadas o violadas. <br></br><br></br>
                Como la custodia compartida que se niega sistemáticamente y la ley de alienación parental que se combate a través de proyectos de ley que quieren sacarla del código civil. <br></br><br></br>
                Esta situación tiende a empeorar cada vez más, incluso los hombres solteros sin hijos ya están siendo afectados y perseguidos. <br></br><br></br>
                Los hombres solo pueden planificar sus acciones para tratar de mitigar sus pérdidas en un proceso de divorcio.
                
                <br></br><br></br><br></br>
                <h2>Victoria</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide33} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                Victoria es subjetivo. Creemos que se trata de vencer a alguien o algo y ser recompensado por ello. <br></br><br></br>
                Si bien esta declaración es cierta, su valor y resultado pueden variar debido a la situación o la importancia que representa. <br></br><br></br>
                Por ejemplo, estar saludable puede representar una victoria para alguien que ha tenido algún tipo de enfermedad. Cómo preferir estudiar en lugar de robar es una gran victoria para alguien que vive en un ambiente donde predomina la pobreza y la delincuencia. <br></br>
                En el divorcio, la victoria también es subjetiva. <br></br><br></br>
                Pasar por este trámite y lograr mantener la salud, la integridad y parte de tus recursos económicos sin duda será una gran victoria frente a un ambiente desfavorable y injusto creado a propósito para derrotar a los hombres.
                <br></br><br></br><br></br>
                Victoria y derrota en el divorcio:
               <br></br><br></br>

                <table border="1" width="100%">
                <tbody>
                <tr><td bgcolor='#000000' ><b><font color="white">JUDICIAL<br></br>CONSENSUAL</font></b></td>
                <td width="70%">Obligatorio para parejas con hijos.<br></br>Es la mejor manera de llevar a cabo un divorcio en los tribunales.<br></br><br></br><font color de fuente ="azul"><b>VICTORIA</b></font>
                </td></tr>
                <tr><td bgcolor='#000000' ><b><font color="white">JUICIAL<br></br>LITIGIOSO</font></b></td>
                <td width="70%">Es el peor divorcio posible para todos.<br></br>Es preferible hacer un mal acuerdo y hacerlo consensuado.<br></br><br></ br> <font color="red"><b>DERROTA</b></font>                
                </td></tr>
                </tbody>
                </table>

                <br></br><br></br><br></br>
                <h2>Planificación</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide34} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                Actualmente, estar casado y tener hijos es un mal negocio. <br></br><br></br>
                No es posible usar la justicia para que se respeten tus derechos, simplemente porque no existen. <br></br><br></br>
                Sin embargo, es necesario usarlo para disolver el matrimonio establecido. Puede ser un procedimiento costoso y doloroso, con el final ya determinado y conocido. <br></br><br></br>
                Uno debe tener argumentos serios y incontestables para lograr algo concreto y favorable, pero lo más común es ser perjudicado de alguna manera en este procedimiento. <br></br><br></br>
                Los hombres se encuentran en desventaja en relación a los principales atributos que determinan al ganador de un conflicto.
                <br></br><br></br><br></br>

                <table border="1" width="100%">
                <tbody>
                <tr><td bgcolor='#000000' ><b><font color="white">TIEMPO</font></b></td>
                <td width="80%">Las mujeres pueden solicitar el divorcio en cualquier momento que lo deseen. <br></br>
                No necesitan una planificación cuidadosa ni grandes recursos financieros.</td></tr>
                <tr><td bgcolor='#000000' ><b><font color="white">JUSTICIA</font></b></td>
                <td width="80%">Todo el entorno legal y las leyes existentes favorecen exclusivamente a las mujeres dejando poco espacio para que los hombres hagan algo significativo. <br></br>
                Actualmente, los litigios son una industria muy lucrativa.</td></tr>
                <tr><td bgcolor='#000000' ><b><font color="white">ABOGADO</font></b></td>
                <td width="80%">Es un segmento de la industria antes mencionada. <br></br>
                Siempre ganarán independientemente de los resultados obtenidos, sin sufrir ninguna consecuencia por el trabajo realizado. <br></br>
                Pueden hacer muy poco por los hombres, terminan siendo solo despachadores. </td></tr>
                </tbody>
                </table>

                <br></br><br></br><br></br>
                <b> El arte de la guerra</b><br></br><br></br>
                <i>Sun Tzu fue un filósofo, estratega y general chino, autor de un tratado militar llamado <b>"El arte de la guerra"</b></i>.<br></br><br></ br>
                A finales del siglo VI a. C., fue contratado por el rey Hu Lu del Reino de Wu para comandar su ejército contra el vecino reino de Chu, que recientemente había declarado la guerra. <br></br><br></br>
                China en ese momento no estaba unificada y estaba dividida en varios pequeños reinos rivales que luchaban entre sí para expandir sus territorios. <br></br><br></br>
                Sun Tzu, tras superar una prueba de evaluación de sus capacidades, se convirtió en comandante supremo de un ejército de treinta mil hombres contra un oponente mejor preparado y numéricamente <u>diez veces superior</u>. <br></br><br></br>
                En lugar de esperar el primer ataque, ignoró los procedimientos militares de la época y hizo algo improbable y inesperado, atacó primero. <br></br><br></br>
                No realizó un ataque directo, sino que movió las tropas de su oponente a ciertos lugares y siempre atacaba por sorpresa sus puntos débiles hasta lograr debilitarlo y reducir gradualmente su contingente y así ganar la guerra. <br></br><br></br>
                Sun Tzu nunca perdió ninguna de las batallas que enfrentó y describe sus logros y conocimientos en el libro antes mencionado.
                <br></br><br></br>
                Como se explicó anteriormente, el divorcio tiene las mismas características que una guerra y como tal debe ser una acción preparada de antemano, sin sentimientos y con un objetivo definido, realista y totalmente factible, siendo su planificación un elemento obligatorio que requiere tiempo, disciplina, paciencia y disimulo de su creador. <br></br><br></br>
                Que el divorcio no debe adelantarse por sentimientos nuestros, opiniones de amigos o familiares, ni por sugerencias de profesionales consultados. <br></br><br></br>
                También se describió que el período previo a la solicitud de divorcio no debe desperdiciarse con peleas o enfrentamientos inútiles. <br></br><br></br>
                Debe utilizarse para mejorar el plan estratégico y obtener los recursos financieros y de personal necesarios. <br></br><br></br>
                Que también debemos utilizar este tiempo para la vigilancia y supervisión de la otra parte, porque en una guerra es fundamental conocer los movimientos del enemigo, como lo es para no ser sorprendido por él. <br></br><br></br>
                En la historia descrita, el reino de Chu reveló sus objetivos de antemano cuando hizo una pronta declaración de guerra, pero debido a su arrogancia y superioridad numérica, creyó que no habría resistencia por parte de su oponente y que la victoria estaba asegurada. cierto, por lo que no se molestó en ocultar sus verdaderas intenciones. <br></br><br></br>
                Sun Tzu demostró que la victoria es posible incluso en condiciones precarias y inferiores, usando la sorpresa como un aliado fuerte y decisivo.
                <br></br><br></br>
                La peor estrategia, según él, es la <u>medición de fuerzas</u>. <br></br>
                La salida es centrarse en las debilidades del oponente para desequilibrar sus puntos fuertes y así prepararse para el ataque. <br></br><br></br>
                Explica que cuando estamos en desventaja en algún atributo, la mejor táctica es atraer al oponente a un escenario en el que tenemos una ventaja.<br></br><br></br>
                Los hombres no pueden ganar este conflicto mientras luchen en este ambiente hostil y desfavorable. Es necesario usar armas no convencionales y llevar la batalla a un lugar más propicio, donde las mujeres no puedan usar a sus hijos como escudo, ni usar leyes sexistas. <br></br><br></br>
                Este es el secreto de la victoria, quien diga lo contrario estará mintiendo. <br></br><br></br>
                Por lo tanto, no le creas a las personas que te prometen que habrá justicia en tu caso. Rara vez suceden. <br></br><br></br>
                Es necesaria una planificación previa, acumular recursos económicos y tomar acciones concretas, pero todo ello tiene como principal objetivo únicamente minimizar tus pérdidas, lo que ya será una gran victoria ante un escenario injusto y destructivo.

                <br></br><br></br><br></br>
                <h2>Consejo de Oro</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide32} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                Comprender y utilizar la estrategia militar descrita anteriormente puede disminuir sus pérdidas y aumentar sus posibilidades de divorcio, como en cualquier otra área de su vida donde se aplica este concepto. <br></br><br></br>
                Sin embargo, los elementos que producen la victoria o la derrota en una batalla son circunstanciales y varían en cada caso y los resultados pueden variar y ser inciertos, pero cuando este concepto se aplica correctamente, siempre producirá algún tipo de beneficio ventajoso. <br></br><br></br>
                Desafortunadamente, no es posible agregar ejemplos más claros y precisos en este documento relacionados con el divorcio. Los conceptos deben ser entendidos para que puedan ser aplicados según cada caso y de manera confidencial, sin el conocimiento del oponente. <br></br><br></br>
                Además de conocer y aplicar estas enseñanzas, algunos consejos pueden ser útiles y valer oro si se utilizan.
                <br></br><br></br><br></br>
                <b>¿QUÉ HACER?</b>
                <br></br><br></br>
                Nadie puede responder a esa pregunta o tomar una decisión por usted. Es una decisión personal. Tendrás que asumir las consecuencias de tus actos. Algunas de las posibles posibilidades son:
                <br></br><br></br>

                <table border="1">
                <tbody>
                <tr><td bgcolor='#000000' ><b><font COLOR="white">ESPERA</font></b></td><td width="80%">Muchos hombres quieren una familia y participar en la crianza de sus hijos. Muchos han invertido gran parte de su vida personal y recursos financieros en este propósito. Cuando perciben las señales que indican el final de la relación, prefieren ignorarlas con la esperanza de que el escenario cambie con el tiempo. Saben que el divorcio será una ruptura en ese deseo.
                Entonces, ingenuamente prefieren creer en el sentido común de sus cónyuges. Después de todo, un divorcio sería perjudicial para todos, especialmente para los niños. Desafortunadamente, la mayoría de los hombres piensan y actúan de esta manera y quedan totalmente destruidos por esta ubicación. La mujer es hipergama por naturaleza, esto forma parte de su instinto, además de ser alentada por la sociedad y tener el sistema legal a su favor. Ellas piensan y actúan de forma totalmente opuesta a lo descrito. Por lo tanto, esperar es la peor elección posible.
                </td></tr>
                <tr><td bgcolor='#000000' ><b><font COLOR="white">DEFENDER</font></b></td><td width="80%">Es una etapa superior de lo descrito anteriormente. Muchos hombres saben que la relación terminará en divorcio, pero prefieren esperar a que sus cónyuges den el primer paso en esa dirección. Muchos no tienen los recursos financieros o incluso el tiempo para pensar y planificar esto.
                Están atascados con deudas y compromisos familiares, posponen esta decisión hasta que las cosas mejoren para ellos y así la llevan a cabo. Si sucede antes de lo esperado, creen que la justicia estará de su lado y verán con buenos ojos su trabajo y sacrificio. Al fin y al cabo, es un padre presente y trabajador. A diferencia de esos hombres que aparecen en los diarios que maltratan a sus esposas y hijos. Será un error fatal, la justicia no tendrá en cuenta tu trabajo ni tu carácter. Cualquier cosa que haga o haya hecho será tratada como una obligación y no producirá ningún beneficio. Perderás tiempo y dinero defendiéndote y todo lo que has ganado o ahorrado desaparecerá.
                </td></tr>
                <tr><td bgcolor='#FFD700' ><b><font COLOR="white">ATTACK</font></b></td><td width="80%">La mayoría de los hombres no acepta la realidad de los hechos descritos en las tablas anteriores. Son pasivos y creen que las cosas se pueden lograr por medios diplomáticos. Esto explica la baja tasa de hombres que buscan el divorcio.
                Sí, las guerras son destructivas y costosas y deben evitarse de todas las formas posibles. Sin embargo, hay situaciones en las que la diplomacia no funciona y puede ser desastrosa para un lado. Podría ser una forma de que tu enemigo se arme y gane tiempo, mientras finge llevar a cabo negociaciones de paz. <br></br><br></br>
                <font color='#FFD700'><b><u>Es mejor atacar que tener que defenderse</u>.</b></font> <br></br><br></br>
                Atacar primero no garantiza la victoria, pero puede reducir tus pérdidas y producir divisiones en las tropas enemigas. En el caso de los hombres, donde todos los elementos están en su contra, es una decisión que mitigará sus pérdidas y aumentará sus posibilidades de algún tipo de victoria.
                </td></tr>
                </tbody>
                </table>

                <br></br><br></br><br></br>
                <b>SIN CONDICIONES</b>
                <br></br><br></br>
                La seguridad es una superstición. No existe en la naturaleza. Ningún ejército o persona estará nunca 100% preparado. No tener dinero es un problema grave y casi una garantía de derrota en un proceso de divorcio. <br></br><br></br>
                Esperarlo, o esperar a que se den todas las condiciones ideales, es una locura y un fracaso seguro. <br></br><br></br>
                Tendrás que llevar a cabo la planificación y varios preparativos con antelación, pero tendrás que hacerlo tanto si tienes las condiciones como si no. La inmovilidad no es una opción. <br></br><br></br>
                Tendrá que pedir préstamos, vender sus activos, etc. Recordar que hacer esto después de que se haya inicializado el divorcio será imposible. Sus activos y recursos no estarán disponibles hasta que se promulguen las decisiones. <br></br><br></br>
                Estar en una situación en la que el divorcio podría suceder en cualquier momento es terrible, pero tratar de ahorrar dinero en este momento es una estupidez. Podrías perder cientos de veces lo que intentaste conservar. No tener condiciones económicas o personales es algo común en estos días. <br></br><br></br>
                Sin embargo, no debe paralizarse por esto. La historia humana está llena de casos reales de personas que no tenían nada y salieron victoriosas ante situaciones adversas. <br></br><br></br>
                No hay ningún ejemplo de personas que hayan salido victoriosas sin hacer nada.
                
                <br></br><br></br><br></br>
                <b>TERRIBLE CONCLUSIÓN</b>
                <br></br><br></br>
                Las mujeres son estrategas naturales. <br></br><br></br>
                Muchos divorcios se planifican con mucha antelación, algunos incluso antes de la boda. Son capaces de manipular a los hombres con una habilidad y destreza increíbles, consiguiendo que hagan lo que quieren. <br></br><br></br>
                Son hipergamos y fácilmente terminan casándose cuando los hombres no les brindan las ventajas requeridas. Ninguno de ellas acepta mantener a ningún hombre, bajo ninguna circunstancia, ni compartir los gastos a partes iguales con ellos. <br></br><br></br>
                Todo el dinero adquirido por él debe ser utilizado en beneficio de la familia, mientras que el de la mujer le pertenece exclusivamente a ella. <br></br><br></br>
                Hay excepciones, pero cada vez son más escasas, aun así, ninguna de ellas renuncia a los derechos legales existentes, sean o no injustos. <br></br><br></br>
                Se supone que los hombres son los proveedores eternos de las mujeres y cumplen sus deseos en todos los niveles. Cuando dejan de ser ventajosos, rápidamente se desechan sirviendo únicamente como fuente de ingresos. <br></br><br></br>
                Es durante el divorcio que se revelan su verdadero carácter y las intenciones de su pareja. En ese momento, muchos hombres se dan cuenta de que los han usado y les han mentido durante años. Algunos tardan más, pero terminan llegando a la misma terrible conclusión. <br></br><br></br>
                Debido a que los hombres son fáciles de manipular, pasan la mayor parte de su tiempo trabajando para resolver los problemas creados por su matrimonio, especialmente sus esposas. Y por eso, no son capaces de ver la realidad. <br></br><br></br>
                Cuando son descartados, terminan descubriendo cuánto fueron engañados durante el período en que estuvieron casados ​​o incluso antes. <br></br><br></br>
                Incluso después del divorcio, muchos seguirán siendo utilizados y manipulados por ellas. Las leyes y los niños, usados ​​como escudo y garantía, lo hacen posible. En estos casos no será solo manipulación y obligación impuesta, será pura maldad para satisfacer sus egos y frustraciones. <br></br><br></br>
                Muchos serán llevados al suicidio o a la ruina total. Será difícil que se recuperen económicamente y se hará todo lo posible para que eso suceda. Después de todo, no pueden tener la oportunidad de reaccionar o defenderse, como sucede en una guerra donde el enemigo debe estar totalmente abrumado y nunca más puede ser reparado.
                <br></br><br></br>
                Varios hombres están de acuerdo con el divorcio, pero primero quieren resolver los problemas existentes antes de hacerlo. Muchos todavía tienen préstamos, financiamientos, deudas y obligaciones derivadas del matrimonio. <br></br><br></br>
                También pueden estar enfermos, desempleados, inseguros en el lugar de trabajo, etc. Si tienen hijos, se preocuparán por su futuro y bienestar. <br></br><br></br>
                Principalmente, con los recursos financieros que se desviarán en esta operación. Recursos que pueden ser más útiles en beneficio de los niños que gastos innecesarios. <br></br><br></br>
                Por eso, prefieren tener sus problemas resueltos y sus finanzas al día antes que gastar dinero en asuntos legales. Esperan una condición ideal o un punto de equilibrio que se pueda soportar.
                
                <br></br><br></br>
                Las mujeres no actúan ni piensan de esa manera. <br></br><br></br>
                <b><u>LA SOCIEDAD Y LA JUSTICIA LES PERMITEN PEDIR EL DIVORCIO EN EL MOMENTO QUE QUIERAN.</u></b> <br></br><br></br>
                No tendrán ningún coste, ni pasarán por ningún bochorno. <br></br><br></br>
                Serán víctimas y los hombres serán villanos. <br></br><br></br>
                Tendrán sus hijos y propiedades. <br></br><br></br>
                Los hombres se quedarán con las deudas y la obligación de mantenerlas. <br></br><br></br>
                El costo total de esta operación será pagado por los hombres. <br></br><br></br>
                No sufrirán ninguna pérdida financiera. <br></br><br></br>
                Cualquier problema que se produzca será culpa de los hombres y ellos tendrán a solucionarlo. <br></br><br></br>
                <b><u>Entonces, <br></br>NO ESPERES NI PLANIFIQUES DEMASIADO ANTES DE HACER ALGO.</u></b>

                <br></br><br></br>
                </span>
            </S.FormText>

            </S.Form>
            </>
            :
            <>
            <br></br>
            <br></br>
            <h3>&nbsp;</h3>
            <br></br>
            </>    }  
        </S.Container>
    )
}

export default GuidePart7;