//import React,{useState}     from 'react';
import React                from 'react';
import * as S               from '../../Book/Home/styles';
import * as GV              from '../../../../global/globalVariables';
//import midia2               from '../../../../assets/amazonbooks.png';
import { i18n }             from '../../../../translate/i18n';
import Others               from '../../../../components/OthersApps';

function BookAcquire() {

    //const [language] = useState(window.localStorage.getItem(GV.I18N_STORAGE_KEY).toLowerCase());

   // function buttonMidia2() {

   //     //let language = window.localStorage.getItem(GV.I18N_STORAGE_KEY);
//
 //       if (language === 'es-es') window.open('https://www.amazon.com.es/dp/B0BHLBY2DT', '_blank'); else
  //      if (language === 'pt-br') window.open('https://www.amazon.com.br/dp/B0BHLBY2DT', '_blank'); else
   //     window.open('https://www.amazon.com/dp/B0BHLBY2DT', '_blank');
   // }

    return ( 
        <>
        <S.TitleOption>{i18n.t('guide.menu7')}</S.TitleOption>
        <S.DescOption>{i18n.t('guide.bookDesc1')}</S.DescOption>
        <br></br><br></br>
        <Others appName={GV.APP_DIVORCE_GUIDE}></Others>  
        <br></br><br></br>
        <br></br><br></br>

        </>
     );
}

export default BookAcquire;

/*
<S.DescPromotion>{i18n.t('guide.bookDesc7')}</S.DescPromotion>
{ (language === 'pt-br') &&
<>
<S.DescOption>{i18n.t('guide.bookDesc2')}</S.DescOption>
<S.MidiaButton onClick={buttonMidia2}><img src={midia2} alt=''></img></S.MidiaButton>
</>
}
*/