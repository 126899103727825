 import React                from 'react';
import * as S               from '../../LifePlan/Home/styles';
import midia2               from '../../../assets/midia2.png';
import midia3               from '../../../assets/midia3.png';
import midia4               from '../../../assets/midia4.png';

function TrailerPasswordWallet() {

    function buttonMidia2() {
        window.open('https://www.youtube.com/watch?v=x04nGqmGAV4&t=4s', '_blank');
    }

    function linkSite() {
        window.open('https://www.kodasware.com/', '_blank');
        return false;
    }

    return ( 
        <>
        <S.MidiaContainer>
            <S.MidiaItem><S.MidiaButton onClick={buttonMidia2}><img src={midia2} alt=''></img></S.MidiaButton></S.MidiaItem>
        </S.MidiaContainer>

        <S.ContainerCopy><span>2022 &copy;&nbsp;<a href="#" onClick={linkSite}>KODASWARE</a>&nbsp;&nbsp;All rights reserved.</span></S.ContainerCopy>
        </>
     );
}

export default TrailerPasswordWallet;