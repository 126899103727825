
export const DUPLICATE_NAME                     = 22;
export const NO_PREVIOUS_PAGES                  = 27;
export const NO_PAGES                           = 28;
export const NO_SEARCH_FOUND                    = 29;
export const ERROR_UNDEFINED                    = 99;
export const LIMIT_DESKTOP                      = 960;
//export const LIMIT_DESKTOP                      = 1024;
export const LIMIT_LINES_VIEW                   = 10;

export const MODAL_DESKTOP_POSITION_LEFT        = '25%';
export const MODAL_DESKTOP_POSITION_RIGHT       = '25%';
export const MODAL_MOBILE_POSITION_LEFT         = '5%';
export const MODAL_MOBILE_POSITION_RIGHT        = '5%';

export const I18N_STORAGE_KEY                   = 'i18nextLng';

export const DIVORCE_INTERVAL                   = 30000;
export const DIVORCE_VALUE_DAY                  = 1727;
export const DIVORCE_VALUE_HOUR                 = 72;
export const DIVORCE_VALUE_MINUTE               = 1;

export const WEDDING_INTERVAL                   = 10000;
export const WEDDING_VALUE_DAY                  = 4594;
export const WEDDING_VALUE_HOUR                 = 191;
export const WEDDING_VALUE_MINUTE               = 3;

export const CONTACT_LOCAL_KODASWARE            = 'kodasware';
export const CONTACT_LOCAL_APP_DIVORCE_GUIDE    = 'appdivorceguide';
export const CONTACT_LOCAL_BOOK_DIVORCE_GUIDE   = 'bookdivorceguide';
export const CONTACT_LOCAL_APP_DIVORCE_PLAN     = 'appdivorceplanning';
export const CONTACT_LOCAL_APP_PERSONAL_DIARY   = 'apppersonaldiary';
export const CONTACT_LOCAL_APP_LIFEPLAN         = 'applifeplan';
export const CONTACT_LOCAL_APP_PASSWORD_WALLET  = 'apppasswordwallet';

export const APP_DIVORCE_GUIDE                  = 'Divorce Guide';
export const APP_DIVORCE_PLANNING               = 'Divorce Planning';
export const APP_PERSONAL_DIARY                 = 'Personal Diary';
export const APP_LIFE_PLAN                      = 'Life Plan';
export const APP_PASSWORD_WALLET                = 'Password Wallet';
export const APP_SEILA                          = 'Seila';