
import React, {useEffect, useState }  from 'react';   
import * as S                         from './styles';
import guide                          from '../../../../view/DivorceGuide/Guide/Images/guide.png'
import util                           from '../../../../Utilities/Util';

function GuideIntroduction() {
    
    const [access, setAccess] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)
        setAccess( util.checkGuideAccess() );
    }, [])

    return (
        <S.Container>
        { ( access )
            ?
            <>
            <S.Form> 
           
            <S.FormTitle>
                <span>INTRODUCCIÓN</span><br></br><br></br>
            </S.FormTitle>

            <S.FormText>
                <span>

				La justicia es un concepto abstracto que tiene componentes y representantes insertos en la sociedad que buscan hacer de ella algo real y aplicable en el mundo en que vivimos. Para que esto sea posible, se construyen varios elementos en nuestra realidad física, dándonos una referencia tangible de su existencia y haciéndonos creer que realmente existe. <br></br><br></br>
                Se levantan edificios, se crean reglamentos, se formulan normas y procedimientos, se seleccionan, designan y capacitan personas en lugares creados para llevar a cabo esta tarea, y se ensamblan a su alrededor varios otros componentes secundarios que siguen este mismo guión para proteger y mantener su funcionamiento. . . <br></br><br></br>
                Desde su fundación en el mundo real, esta institución pasa a formar parte de la sociedad donde se inserta, dictando sus normas y deberes. Los nuevos individuos que nazcan después de este evento aceptarán automáticamente estas instituciones como algo normal, presente y necesario. Creyendo que fueron diseñados para resolver y corregir problemas existentes con el objetivo de ayudar a las personas a resolver sus conflictos y producir un equilibrio entre sus acciones, deseos y derechos.<br></br><br></br>
                Sin embargo, solo se representa en la realidad y nunca puede ejercerse plenamente, existiendo solo como un ideal o una meta a alcanzar, ya que ningún hombre o institución tiene la capacidad de producir justicia real.<br></br><br></br>
                Actualmente, está representado por el poder del estado que se encarga de aplicar las leyes. <br></br><br></br>
                Es un sistema complejo y de gran escala que requiere grandes recursos humanos y financieros para su funcionamiento. Sin embargo, los resultados que ofrece a la sociedad son insignificantes y altamente cuestionables. Aún con generosos ingresos económicos, tecnología de punta, personal capacitado y calificado, este sistema es incapaz de ofrecer un servicio de calidad, ni produce la justicia deseada.<br></br><br></ br>
                Las cifras de violencia y criminalidad no dejan de crecer, sobrecargando el sistema judicial con nuevos procesos. Obviamente, impulsado por las víctimas de esta ineficiencia. Es un sistema que gasta mucho y produce poco. Además, pueden pasar años hasta que se produzca un resultado real. Las personas tienen la vida destrozada o paralizada esperándolo, muchas veces de valor inocuo o insignificante por el tiempo transcurrido. Esto favorece a los delincuentes y perjudica aún más a sus víctimas que no obtienen la justicia deseada para compensar o minimizar sus pérdidas y sufrimiento. <br></br><br></br>
                Estos resultados, además de consumir mucho tiempo, también son muy cuestionables, por dos razones: la primera son las leyes existentes. Muchos están desactualizados, son ilógicos, injustos y contradictorios, lo que dificulta demasiado su interpretación y aplicabilidad. La segunda razón está relacionada con el número de procesos. El volumen de casos nuevos que llegan a diario y los que están pendientes de resolución, imposibilitan su diligencia debida. Incluso liberando un proceso por hora, la velocidad seguiría siendo insuficiente, pero hay que señalar que esto sería inválido y altamente dañino. <br></br><br></br>
                No se pueden terminar rápidamente. Los procesos están todos compuestos por textos descriptivos de difícil comprensión y documentos adjuntos a los mismos. En teoría, todo tiene que ser estudiado y evaluado meticulosamente para llegar a una opinión justa y satisfactoria para los involucrados. <br></br><br></br>
                Sin embargo, la cantidad de documentos a procesar y analizar es interminable, haciendo esta tarea prácticamente imposible, incluso para profesionales excepcionales. Un texto mal interpretado, una palabra mal entendida, incluso la omisión de una coma, puede cambiar todo el sentido de una acusación o defensa, produciendo resultados desastrosos. <br></br><br></br>
                Por lo tanto, la justicia no puede convertirse en una fábrica con una línea de producción impulsada por metas o demandas. Necesitas ser consciente y celoso en tus decisiones. Después de todo, las vidas dependen de él y se ven afectadas por él. <br></br><br></br>
                Desafortunadamente, los problemas, distorsiones y limitaciones descritas solo producen una cosa:<br></br><br></br><br></br>
                <center><b>I N J U S T I C I A</b></center><br></br><br></br> 
				El sistema judicial no funciona y probablemente nunca lo hará. La cantidad de fallas y desajustes ya no se puede corregir, y cualquier intento de solución solo produce frustración, gastos o más problemas. Además, no parece existir un interés concreto en solucionar los problemas existentes, incluso los más simples, pues este sistema también se ha convertido en una herramienta de gran poder y generadora de mucho dinero para personas deshonestas, sin escrúpulos y hasta perversas que están directa o indirectamente integrado a él. <br></br><br></br>
                Cuantos más problemas, delitos y desajustes haya en nuestra sociedad, más se buscará este sistema para intentar solucionarlos, promoviendo así un círculo perpetuo sin solución. En consecuencia, generando más poder y riqueza para quienes la manipulan y son favorecidos por ella. <br></br><br></br>
                Es necesario conocer este terrible escenario en el que estamos insertos para poder hacer algo en nuestra defensa. Ya no podemos mantener los ojos cerrados y seguir creyendo y manteniendo instituciones que no funcionan y benefician solo a quienes les interesan.
                <br></br><br></br><br></br><br></br>
                <h2>La Guía</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

				Este documento debe usarse como una brújula que muestra el camino en un entorno desconocido y con niebla para la mayoría de las personas, especialmente para los hombres. Muchos de ellos siguen creyendo en las instituciones y en su recuperación, por lo que son fácilmente engañados y aniquilados por ellas. Creen en la igualdad, en las leyes y en el sentido común de los profesionales implicados. Hace mucho tiempo que estos atributos no existen en la justicia y este desequilibrio no ha dejado de crecer. <br></br><br></br>
                Los hombres son los mayores perdedores, lo máximo que pueden hacer es reducir sus pérdidas, lo que ya es una gran victoria. Son vistos y tratados solo como proveedores.<br></br><br></br>
                Todos los procedimientos y decisiones tomadas serán en realidad solo una gran representación. Los resultados ya estarán listos, pero esto no se puede demostrar abiertamente, por lo que será necesario montar un teatro para enmascarar este hecho, y serán los hombres quienes pagarán todo el costo de esta puesta en escena. <br></br><br></br>
                De hecho, cuando hay carácter y sentido común entre los cónyuges en un proceso de divorcio, todo tiende a transcurrir sin contratiempos y satisfactoriamente, y la justicia dará señales de que funciona y no mostrará su verdadero rostro. Pero cuando eso no suceda, los hombres serán juzgados y condenados según la idea de que las mujeres son víctimas y los hombres villanos. No hay leyes que favorezcan a los hombres, pero hay varias que favorecen solo a las mujeres, y constantemente se crean y aprueban nuevas leyes. <br></br><br></br>
                Los hombres no son juzgados con justicia o sentido común, y siempre tendrán que pagar las facturas, sean o no capaces de llevar a cabo esta tarea. Si no pueden o se niegan, serán arrestados, condenados y destruidos, independientemente del escenario político y social del momento. <br></br><br></br>
                Actualmente, no tener trabajo ni dinero es un crimen atroz y debe ser tratado con extremo rigor. Desgraciadamente, esta es la realidad y los profesionales implicados lo saben. Por tal motivo, ya está todo listo y relacionado, solo se modificarán sus datos en los documentos que se utilizarán. Las audiencias y los resultados serán los mismos, solo que en algunos casos algo será diferente.<br></br><br></br><br></br>
                <center><b><i>Las leyes son estrictas y están diseñadas para derrotar a los hombres.</i></b></center> <br></br><br></br>
                Muchos hombres aún no están de acuerdo con este escenario y creen en la justicia, principalmente porque conocen personas que se divorciaron sin ningún problema. Algunos incluso han realizado este procedimiento más de una vez y no ha pasado nada malo o desastroso, a pesar de que hay niños en la relación. <br></br><br></br>
                Cada caso es único y no significa que será desastroso o pacífico para todos. Siempre dependerá de las circunstancias y principalmente de los valores morales de los involucrados. Pero si sucede lo peor, será verdaderamente aterrador y destructivo. Nadie sabe cómo reaccionarán los demás en situaciones de conflicto. Las personas que llevamos en brazos y en las que invertimos nuestra vida pueden, sin explicación alguna, convertirse en terribles y destructivos verdugos. Hay innumerables casos en la historia de la humanidad que así lo confirman, además de los crímenes, injusticias y barbaridades de todo tipo que suceden a diario. <br></br><br></br>
                La gran mayoría de los hombres no aceptan esta realidad, prefiriendo creer que todo esto son solo casos lamentables y circunstanciales, que son fallas de justicia que eventualmente pueden ser corregidas. Creen que con ellos todo sucederá rápido y sin problemas, que no se debe perder tiempo ni dinero en ningún tipo de preparación.<br></br><br></br>
                Al final, será un error fatal para quienes actúan y piensan de esta manera. <br></br><br></br>
                Por tanto, esta guía muestra esta realidad y los elementos que intervienen en esta operación, demuestra las leyes y procedimientos existentes, y proporciona algunas soluciones que se deben seguir para evitar o reducir las pérdidas personales y económicas que produce el divorcio.
                <br></br><br></br><br></br><br></br>                
				<i><b>EL CONTENIDO DE ESTA GUÍA SE BASA EN LA OPINIÓN PERSONAL ADQUIRIDA EMPÍRICAMENTE U OBTENIDA A TRAVÉS DE DOCUMENTOS PÚBLICOS Y GUBERNAMENTALES DISPONIBLES EN LOS MEDIOS EXISTENTES.
                <br></br><br></br>NINGUNA GARANTÍA DE QUE LA INFORMACIÓN ACCESIBLE EN ESTE DOCUMENTO SEA EXACTA, COMPLETA O ACTUALIZADA.
                <br></br><br></br>SIENDO RESPONSABILIDAD DEL LECTOR SEGUIR O NO SUS SUGERENCIAS, CONSEJOS Y OPINIONES, DEBE <u>SIEMPRE</u> BUSCAR A UN PROFESIONAL AUTORIZADO Y ESPECIALIZADO PARA RESOLVER SUS DUDAS Y PROBLEMAS DE FORMA LEGAL Y SATISFACTORIA.</b></i>
                <br></br><br></br><br></br>

                </span>
            </S.FormText>

            </S.Form>
            </>
            :
            <>
            <br></br>
            <br></br>
            <h3>&nbsp;</h3>
            <br></br>
            </>    }  
        </S.Container>
    )
}

export default GuideIntroduction;