import styled, { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
}
`

export const Container = styled.div`
    z-index: 1;
    width: 100%;
    max-width: 1300px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 20%;
    padding-left:  5%;

    @media screen and (max-width: 960px) {
        padding-right: 5%;
        padding-left:  5%;
    }
`

export const ContainerSpace = styled.div`
    background:  #FFFFFF;
    width: 100%;
    height: 550px;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const ContainerSpaceShort = styled.div`
    background:  #FFFFFF;
    width: 100%;
    height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const Title = styled.div`
    background: none;
    border: none;
    color: #696969;
    text-align: center;
    text-decoration: none;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
    font-size: 2.0rem;
    margin-top: 30px;
`

export const FormText = styled.div`

    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    margin-bottom: 50px;

    @media screen and (max-width: 960px) {
        text-align: left;
        margin-left: 10px;
        margin-right: 10px;
    }
`

export default GlobalStyle;