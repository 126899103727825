import React         from 'react';
import Conditions    from '../../../global/globalTermsAndConditions';
//import * as GV       from '../../../global/globalVariables';

function TermsAndConditions() {
    
    return (
      <>
      <Conditions></Conditions>
      </>
    )
}

export default TermsAndConditions;