import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const Form = styled.div`
    width: 50%;
    margin-top: 10px;

    @media screen and (max-width: 960px) {
        width: 90%;
    }

    ul {
        margin: 0;
        padding: 0;
      }

    li {
        margin-left: 12px;
        font-size: 12px;
        font-family: 'Roboto', sans-serif;
        xfont-weight: bold;
        xcolor:  #6596B2;
    }
`

export const FormImage = styled.div`

        display: flex;
        justify-content: center;  
        align-items: center;    
        background: #FFFFFF;

        @media screen and (max-width: 960px) {

            img {
                width: 100%;
            }
            
        }

`

export const FormChat = styled.div`

        display: flex;
        flex-direction: column;
        //justify-content: center;  
        align-items: center;    
        background: #FFFFFF;

        img {
            border: 2px solid #AEAEAE;
        }

        @media screen and (max-width: 960px) {

            img {
                width: 100%;
            }
            
        }
        
        span {
            color: #707070;
            font-size: 12px;
            font-family: 'Roboto', sans-serif;
            font-weight: bold;
            margin-top: 5px;      
        }
`

export const FormTitle = styled.div`

        font-size: 26px;
        font-family: 'Roboto', sans-serif;
        font-weight: bold;
        color:  #6596B2;
        text-align: center;
        margin-bottom: 60px;
        margin-top: 10px;

    }
`

export const FormText = styled.div`

        font-size: 18px;
        font-family: 'Roboto', sans-serif;
        text-align: left;
        margin-bottom: 50px;

        td[id=cabec]  {
            vertical-align: top;
            background:  #EAEAEA;
            font-weight: bold;
        }
    }
`

export const HomeButton = styled.div`

    display: flex;
    width: 100%;
    align-items: left;
    margin-bottom: 30px;
   }
`

export const LoadContainer = styled.div`
        display: flex;
        justify-content:center; 
        align-items:center;
        height: 100vh; // 100% view height
        width: 100vw; // 100% view width
        position: absolute;
`