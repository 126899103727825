import React    from 'react';
import Polite   from '../../../global/globalPrivacy';
import * as GV  from '../../../global/globalVariables';

function Privacy() {
    
    return (
        <>
        <Polite name={GV.APP_LIFE_PLAN}></Polite>
        </>
    )
}

export default Privacy;