
import React, { useEffect, useState } from 'react';   
import * as S       from './styles';
import util         from '../../../../Utilities/Util';

function Attach() {
    
    const [access, setAccess] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)
        setAccess( util.checkGuideAccess() );
    }, [])

    return (
        <S.Container>
        { ( access )
            ?
            <>
            <S.Form> 

            <S.FormTitle>
                <span>ATTACH</span><br></br>
            </S.FormTitle>

            <S.FormText>
                <span>
				<h2>Important Notice</h2>
                <br></br>
                <i> UNDER NO CIRCUMSTANCES PRODUCE ANY ACT OF PHYSICAL OR PSYCHOLOGICAL VIOLENCE AGAINST THE OTHER PARTY OR ANY PERSON INVOLVED BEFORE OR AFTER THE DIVORCE.<br></br><br></br>
                 DO NOT SEEK REVENGE OR RETALIATION OF ANY KIND. <br></br><br></br>
                 EVEN IF JUSTICE IS DEFICIENT ALWAYS USE LEGAL AND JUDICIAL MEANS TO SOLVE YOUR PROBLEMS.<br></br><br></br>
                 RATHER BE HAPPY AND REBUILD YOUR LIFE.</i>
                <br></br><br></br>
                <br></br><br></br>
                <h2>Actions and Attitudes</h2>
                <br></br>
                Before, during and after the divorce keep a <b><u>diary</u></b> describing all events with date, time, names, details, audio, video, photos or any other relevant documents. <br></br><br></br>
                This will be very important in the future especially in defense of any lawsuit you may receive.<br></br><br></br>
                Always scan all your documents, invoices, emails, etc. Password protect everything and keep it in a safe and inaccessible place.<br></br><br></br>
                The tint on your invoices and/or other documents wears off over time in a few days in some cases so it's important to scan them.<br></br><br></br>
                Always have a copy of everything updated and stored in the <i>"Cloud"</i> of a provider.<br></br><br></br>
                Password protect your computer and cell phone if possible reformat or change equipment.<br></br><br></br>
                Change the password of all your email accounts, preferably create new ones and delete the old ones.<br></br><br></br>
                Close any account or service on the Internet that may defame your character such as: dating sites, pornography, gambling sites, etc.<br></br><br></br>
                Be careful, false and compromising data may be being recorded on your computer without your knowledge in order to demoralize or incriminate you in the future.<br></br><br></br>
                Accounts may be opened in your name on dating sites or anywhere else that could harm you so it's important to have new email accounts. <br></br><br></br>
                However, this does not prevent these types of operations from being carried out so you must be attentive to the emails received.<br></br><br></br>
                Your landline, cell phone, computer or any other communication equipment can be intervened. <br></br><br></br>
                Discuss and exchange confidential information in person via pay phones or any other secure and reliable means.<br></br><br></br>
                If you discover that your team is bugged use this fact to your advantage passing on false information to confuse and/or harm the other party.<br></br><br></br>
                Be careful with spoken or written words they can be used against you too. <br></br><br></br>
                Get as much information as possible about the actions and intentions of the other party if necessary hire an investigative agency to guide you and perform this service. <br></br><br></br>
                Pay attention to people going in and out of your residence especially <u>domestic workers</u>, friends and your spouse's family, etc. <br></br><br></br>
                These people will be used by the party as witnesses in future proceedings. <br></br><br></br>
                They will shamelessly bear false witness and report anything they are ordered to say.<br></br><br></br>
                Do the same, place friends and family inside your residence have as many social relationships as possible with people you trust and record everything on audio, video, and social media. <br></br><br></br>
                Be aware of attitudes or actions directed at you with the aim of taking you out of seriousness.<br></br><br></br>
                Especially after divorce, keep a minimum distance of <u>one meter</u> between you and the other party <u>always</u> with your hands behind your back. <br></br><br></br>
                Never react or try to hold the part if you are attacked, pushed or threatened. If necessary run away and ask for help. 
                Otherwise, anything will be an act of violence produced by you. <br></br><br></br>
                <u>Don't fall into that trap</u>. <br></br><br></br>
                If possible eliminate all debts, loans, financing, invoices and bank receipts in your name. <br></br><br></br>
                It is also important to carry out the divorce with the least number of assets to share.
                For example, if you have a vehicle financed in your name sell it and pay off the debt.
                Otherwise, when the divorce is filed you must continue paying the mortgage and you will not be able to sell it until the Judge decides your fate.<br></br><br></br>
                Do not run after a new relationship just to show that you have someone that you are free and happy.<br></br><br></br>
                Let things happen naturally and at the right time.
                Don't be dominated by your ego, pride or other people's opinions.<br></br><br></br>
                
                <u><b>IMPORTANT</b></u>:<br></br><br></br>
                Never do parental alienation on your children. 
                Do not allow any family member (grandparents, siblings, uncles, cousins, etc.), friends or close people to do this. 
                Never turn your children against the other party. Speak ill of them or discuss the reasons for the divorce with them.
                Do not create or comment on situations where the party may be responsible, even if it is true.
                If they ask you something, tell them that you will only answer when they are adults.
                In situations where extra money is involved such as: trips, rides, toys, etc., never blame the other party or be the villain of the story. 
                If it is not possible to carry it out.
                Say that you will think about it and discuss the situation with the other party. 
                Neither of you should present yourself as a hero or a villain in this story, even if only one of you is responsible for the effectiveness or impediment of it.
                <br></br><br></br>
                Don't be alienating, don't unload your wounds and resentments on your children.
                If the other party does none of this, gather as much testimonial and documentary evidence against them and request a parental alienation lawsuit to protect their children against this type of violence.
                <br></br><br></br>
                <h2>Final Message</h2>
                <br></br>
                <i>IF DIVORCE IS ALREADY A REALITY IN YOUR LIFE.
                IF YOU HAVE LOST THE GUARD OF YOUR CHILDREN, BE HELPED, SAD AND HUMILIATED.
                YOUR FIGHT DOES NOT END HERE. THERE WILL BE OTHER BATTLES WHERE YOU WILL BE THE WINNER.
                TIME WILL CORRECT ALL THAT, BUT IT WILL DEPEND ON YOUR ATTITUDE AND POSTURE BEFORE LIFE.
                REBUILD IT HARD AS IT IS.
                IF MORE PROBLEMS APPEAR AROUND THEM AND MOVE ON.
                THEY WILL MAKE THE WAY, BUT YOU WILL NOT SEE THEM IF YOU STAY OPEN IN VICTIMISM, PRIDE, SADNESS AND IN YOUR PAST.
                TURN THE LITTLE TIME YOU STILL HAVE WITH YOUR CHILDREN INTO SOMETHING SPECIAL INTO THE MOST IMPORTANT THING IN THE WORLD.
                DON'T WANT TO SEDUCE THEM WITH MATERIAL THINGS.
                BEING A PRESENT FATHER HIS BEST FRIEND.
                WHETHER YOU WANT TO OR NOT THE FUTURE WILL ARRIVE.
                IT DEPENDS ON YOU IF IT WILL BE WONDERFUL OR NOT.<br></br><br></br>
                HAVE FAITH, COURAGE AND DETERMINATION.<br></br><br></br>
                GOOD LUCK!</i>
                <br></br><br></br>
                </span>
            </S.FormText>

            </S.Form>
            </>
            :
            <>
            <br></br>
            <br></br>
            <h3>&nbsp;</h3>
            <br></br>
            </>    }  
        </S.Container>
    )
}

export default Attach;