const messages = {
    es: {
        translations: {

            literals: {
                name: 'Nombre',
                message: 'Mensaje',
                email: 'Correo Electrónico',
                password: 'Contraseña',
                language: 'Linguagem',
                policyPrivacy: 'Política de privacidad',
                policyCookie: 'Política de Cookies',
                subject: 'Información',
                country: 'Reino Unido',
                address: 'Dirección',
            },

            header: {
                app2:  'PLANIFICACION DE DIVORCIO',
                app3:  'DIARIO PERSONAL',
                app4:  'PLAN DE VIDA',
                app5:  'PASSWORD WALLET',
                book1: 'GUÍA DE DIVORCIO PARA HOMBRES',
            },

            trailer: {
                title1:  'Enlaces rápidos',
                title2:  'Ayuda y soporte',
                title3:  'Otro',
                option1: 'Términos de uso',
                option2: 'Política de Privacidad',
                option3: 'Contacto',
                option4: 'Aplicaciones',
                option5: 'Página de Inicio',
            },
            
            buttons: {
                ok: 'Ok',
                cancel: 'Cancelar',
                login: 'LOGIN',
                send: 'Mandar',
                clear: 'Borrar'
             },

            titles: {
                cardTitle: 'Desarrollo de software',
                cardDescription: 'Codifica tus Sueños',
                contact: 'Ponerse en contacto',
                product: 'Aplicaciones',
            },

            products: {
                app1Title: 'GUÍA DE DIVORCIO',
                app1SubTitle: 'La Aplicación describe los elementos principales de un divorcio y sus consecuencias.',
                app1Description: 'Fue creado para enseñar, ayudar y alertar a las personas que pretenden divorciarse o que ya están pasando por este trámite, pero no saben qué esperar ni cómo actuar en este momento de sus vidas.',
            
                app2Title: 'PLANIFICACIÓN DE DIVORCIO',
                app2SubTitle: 'Aplicación para planificar y seguir un proceso de divorcio.',
                app2Description: 'Fue desarrollado con el objetivo de proporcionar un software capaz de ayudar a sus usuarios a planificar, realizar, medir y monitorear un divorcio. Utiliza algunos de los conceptos descritos en el libro "El arte de la guerra" en su diseño. También utiliza las metodologías del ciclo PDCA y análisis SWOT en su estructura operativa.',
            
                app3Title: 'DIARIO PERSONAL',
                app3SubTitle: 'Diario personal para registrar sueños, ideas y situaciones ocurridas en tu vida.',
                app3Description: 'La aplicación fue desarrollada para ser un diario digital simple y fácil de usar, donde se pueden registrar las experiencias y eventos importantes de una persona.',
            
                app4Title: 'PLAN DE VIDA',
                app4SubTitle: 'Crear un plan de vida para lograr el estilo de vida deseado.',
                app4Description: 'La aplicación es una herramienta diseñada para ayudarte a crear un plan de vida. Un plan de vida es un documento de viaje personal que le ayuda a describir sus objetivos, prioridades, tomar decisiones y avanzar hacia el estilo de vida que imagina.',
            
                app5Title: 'PASSWORD WALLET',
                app5SubTitle: 'Gestiona y Protege tus Contraseñas y Documentos en un Solo Lugar.',
                app5Description: 'Mantén todos tus documentos y contraseñas seguros con Password Wallet, tu caja fuerte personal de fácil acceso. Con una sola contraseña maestra, puedes almacenar y gestionar de forma segura tus credenciales y documentos importantes. Ideal para quienes buscan practicidad y seguridad, Password Wallet es la solución perfecta para mantener tu información privada al alcance de la mano, pero lejos de miradas indiscretas. Pruébalo hoy y siente la tranquilidad de tener tu vida digital bien protegida.',
            },

            menu: {
               about: 'Acerca',
               contact: 'Contacto',
               application: 'Aplicaciones',
               login: 'Login'
           },

           sections: {
                title: 'Desarrollo de software',
                subtitle: 'Convierte tus proyectos en realidad',
                about: 'Desarrollo de softwares para web, celulares, tabletas y otros dispositivos.',
                contact: '¿Usted tiene una pregunta?',
                message: 'Deja un mensaje.',
                sent: 'Mensaje enviada.'

           },

           guide: {
               title: 'Matrimonios y Divorcios',
               description1: 'Los números que se presentan cuando hablamos de matrimonios o divorcios son estadísticas matemáticas que en muchos casos no reflejan la verdadera realidad de una ciudad, estado o nación. Estos números son inválidos, subestimados o sobreestimados, excedidos o mezclados con otros valores que producen resultados poco realistas. Sin embargo, incluso con estas deficiencias, podemos usar estos valores estadísticos para tener una idea de lo que puede estar sucediendo en las relaciones humanas.',
               crudeRate: 'Tasa Cruda',
               description2: 'Define la frecuencia con la que ocurre una enfermedad o condición en una población en un período de tiempo determinado.',
               formula1: 'Tasa Bruta = (Cantidad ÷ Población) x Constante',
               note: 'Constante es un múltiplo de 10 que define el número de elementos en el grupo.',
               crudeDivorceRate: 'Tasa Bruta de Divorcio',
               description3: 'Es el número de divorcios por cada grupo de 1000 personas en una población. Por ejemplo, si una ciudad tiene una población de 10 000 habitantes y 50 parejas que se divorciaron en un año, tendríamos una tasa bruta de divorcio de 5 divorcios por cada grupo de 1000 personas.',
               formula2: 'Tasa Bruta de Divorcio = (Número de divorcios ÷ Población) x 1000',
               description4: 'La tasa bruta de divorcio puede proporcionar una visión general del matrimonio, pero muestra un valor falso porque utiliza a personas que no pueden casarse en su resultado. Por ejemplo, los niños y solteros son parte del cálculo. En un lugar con una gran cantidad de niños o adultos solteros, la tasa bruta de divorcio puede parecer baja. En un lugar con pocos niños y adultos solteros, la tasa bruta de divorcios puede parecer alta.',
               divorceRate: 'Tasa de Divorcio en el Mundo',
               description5: 'Según los datos de 2020-2021 de la División de Estadísticas del Departamento de Asuntos Económicos y Sociales de las Naciones Unidas, los países con las tasas anuales de divorcio más altas por cada 1000 personas son los siguientes:',
               noteList: 'Los datos son los más recientes disponibles por país. 2018-China; 2019-Cuba; todos los demás 2020-21​.',
               description6: 'Según los datos de 2017-2020, los países con las tasas anuales de divorcio más bajas son los siguientes:',
               numberDivorces: 'Número de Divorcios',
               numberWeddings: 'Número de Matrimonios',
               divorceUSA: 'Divorcio en los EE.UU.',
               description7: 'Según el Centro Nacional de Estadísticas de Salud de los EE. UU., aproximadamente entre 4 y 5 millones de personas se casan cada año en los EE. UU. y aproximadamente entre el 42 y el 53 % de estos matrimonios terminan en divorcio. Mira los números actuales:',
               description8: 'El divorcio ocurre en todo el mundo. Las estadísticas obtenidas en Estados Unidos pueden servir de base para entender cómo funciona en otros países',
               divorceCause: 'El Divorcio y sus causas',
               description9: 'Las razones que llevan a las personas a permanecer juntas son bien conocidas: el amor, el deseo de estar cerca, el sueño de un futuro común. Pero, después de todo, ¿qué impulsa a las parejas a buscar la separación? Un estudio realizado por la Universidad de Denver reveló las principales razones, que se describen a continuación:',
               divorceCause01: 'Falta de compromiso - 75%',
               divorceCause02: 'Infidelidad o relaciones extramatrimoniales - 59,6%', 
               divorceCause03: 'Demasiados conflictos y discusiones - 57,7%',
               divorceCause04: 'Casarse demasiado joven - 45,1%',
               divorceCause05: 'Problemas financieros - 36,1%​​',
               divorceCause06: 'Abuso de sustancias - 34,6%',
               divorceCause07: 'Violencia doméstica - 23,5%',
               divorceCause08: 'Problemas de salud - 18,2%​',
               divorceCause09: 'Falta de apoyo de la familia - 17,3%​',
               divorceCause10: 'Poca o ninguna educación prematrimonial y diferencias religiosas - 13,3%',
               generalDivorce: 'Estadísticas Generales de Divorcio',
               general01: '8 años es el promedio para que un primer matrimonio termine en divorcio.​',
               general02: 'El 20% de los matrimonios terminan dentro de los primeros 5 años.​',
               general03: 'El 32% de los matrimonios terminan dentro de los primeros 10 años.',
               general04: '30 años es la edad promedio de las personas que se divorcian por primera vez.',
               general05: 'El 69% de las mujeres inician el divorcio. Ese número se eleva al 90% para las mujeres con un título universitario.​',
               general06: 'Las mujeres que han tenido seis o más parejas sexuales antes del matrimonio tienen tres veces menos probabilidades de tener un matrimonio estable.',
               general07: 'Mirar y hablar sobre películas románticas reduce las tasas de divorcio en un 13 %.',
               general08: 'Ser amigo cercano de una persona divorciada aumenta la probabilidad de divorciarse en un 147 %.',
               general09: 'Tener fuertes creencias religiosas disminuye la probabilidad de divorcio en un 14%.',
               general10: 'Tener un coeficiente intelectual por debajo del promedio aumenta la probabilidad de divorcio en un 50%.',
               general11: 'El 67% de los estadounidenses cree que el divorcio es mejor para los niños que presenciar los problemas de un matrimonio en problemas.',
               general12: 'Los padres bien casados ​​disminuyen la probabilidad de divorcio de sus hijos en un 14%.',
               general13: 'En los EE. UU., la investigación indica que 22 millones de padres son objeto de comportamientos de alienación parental y 3,8 millones de niños son alienados por uno de los cónyuges.',
               general14: 'Solo dos países consideran ilegal el divorcio: Filipinas y Ciudad del Vaticano.',
               menu1: 'ACERCA',
               menu2: 'RECURSOS',
               menu3: 'DOWNLOAD',
               menu4: 'CONTACTO',
               menu5: 'EXTRA',
               menu6: 'AUTOR',
               menu7: 'ADQUIRIR',
               menu1Desc1: 'Esta aplicación describe los principales elementos de un divorcio y sus consecuencias.',
               menu1Desc2: 'Ele foi criado para ensinar, ayudar e alertar as pessoas que pretendm realizar um divórcio ou que já estão passando por esse procedimento, mas não sabem o que esperar nem como agir nesse momento de sus vidas.',
               menu1Desc3: 'O divórcio representa o fim de uma união. ',
               menu1Desc4: 'Muitas pessoas acreditam que se trata apenas de um ato burocrático, onde seus desejos e direitos serão respeitados, com a liberdade sendo restaurada, produzindo uma vida mais feliz para todos, principalmente para seus solicitantes.',
               menu1Desc5: 'Isso acontece em filmes, livros y novelas, mas no mundo real não é assim. ',
               menu1Desc6: 'Divórcios amigáveis tendem a funcionar como esperado, mas quando há algum desentendimento entre os envolvidos, o cenário muda completamente e pode produzir consequências terríveis e inesperadas para todos.',
               menu1Desc7: 'O aplicativo fornece as informações necessárias para ayudar a sus usuarios a realizar os procedimientos que irão eliminar ou mitigar sus perdas, dores e sofrimentos provenientes de divórcios não consensuais.',
               menu2Desc: 'Diseñado para ser simple, útil y fácil de usar. Se puede acceder a las funciones de forma rápida e intuitiva, eliminando la necesidad de cualquier tipo de manual o instrucciones descriptivas.',
               menu2Title1: 'Múltiples idiomas',
               menu2Title1Desc: 'Para facilitar la comprensión y el uso, la aplicación funciona con varios idiomas que pueden ser seleccionados según la preferencia del usuario.',
               menu2Title2: 'Guía',
               menu2Title2Desc: 'Describe los principales elementos contenidos en un proceso de divorcio y sus respectivas variantes y consecuencias.',
               menu2Title3: 'Estadísticas',
               menu2Title3Desc: 'Muestra las principales estadísticas relacionadas con el divorcio. Contador de matrimonios y divorcios.',
               menu2Title4: 'Incidentes',
               menu2Title4Desc: 'Permite registrar incidencias acaecidas durante y después de la boda con la emisión de un informe.',
               menu2Title5: 'Medidor',
               menu2Title5Desc: 'Permite medir las consecuencias del divorcio en base a los datos facilitados.',

               menu2Title6Desc: 'La aplicación brinda acceso a este libro que puede adquirirlo y leerse directamente en su teléfono celular.',
               menu2Title6bDesc: 'El primer capítulo está disponible para revisión.',
               menu2Title6cDesc: 'Haga clic en la imagen de arriba para obtener más información.',
               menu3Desc: 'La aplicación está disponible para dispositivos Android.',
               menu4Title: '¿TIENES ALGUNA PREGUNTA?',
               menu4Desc: 'Déjanos un mensaje.',
               menu5Desc1: 'A continuación se muestran algunas aplicaciones que pueden ser de su interés.',
               bookDesc1: 'Puedes acceder al libro y utilizarlo directamente en tu teléfono celular, utilizando la siguiente aplicación:',
               bookDesc2: 'Si lo prefieres, el libro también está disponible en AMAZON.',
               bookDesc3: 'NO ESPERES SENTIDO COMÚN O COMPASIÓN',
               bookDesc4: 'TENDRÁS QUE GASTAR DINERO PARA DEMOSTRAR QUE NO TIENES DINERO',
               bookDesc5: 'HOY LOS HOMBRES SON ANIQUILADOS EN EL DIVORCIO',
               bookDesc6: 'RARA VEZ LOS HOMBRES TIENEN DERECHO A LA CUSTODIA DE SUS HIJOS',
               bookDesc7: 'DESCARGA LA APLICACIÓN Y RECIBE UN SÚPER DESCUENTO',
            },

            planning: {
                menu1: 'ACERCA',
                menu2: 'RECURSOS',
                menu3: 'DOWNLOAD',
                menu4: 'CONTACTO',
                menu5: 'EXTRA',
                menu1Desc1: 'Solicitud para planificar y seguir un proceso de divorcio.',
                menu1Desc2: 'Fue desarrollado con el objetivo de proporcionar un software capaz de ayudar a sus usuarios a planificar, realizar, medir y monitorear un divorcio.',
                menu1Desc3: 'Utiliza algunos de los conceptos descritos en el libro "El Arte de la Guerra" en su diseño.',
                menu1Desc4: 'También utiliza en su estructura operativa el ciclo PDCA y metodologías de análisis SWOT.',
                menu1Desc5: 'La planificación consiste en una serie de preparativos para realizar una tarea con el fin de lograr un objetivo.',
                menu1Desc6: 'Muchas personas no saben o no quieren realizar ningún tipo de planificación.',
                menu1Desc7: 'Prefieren creer que todo sucederá rápido y en armonía, respetando sus derechos y cumpliendo sus demandas.',
                menu1Desc8: 'Desafortunadamente esto no sucederá la mayor parte del tiempo.',
                menu1Desc9: 'Confiar en la suerte o en el sentido común del sistema judicial o en la habilidad del abogado seleccionado no es garantía de éxito ni de un final feliz.',
                menu1Desc10:'Todo divorcio debe planificarse antes de su ejecución, incluso si existe consenso entre la pareja.',
                menu1Desc11:'Nadie sabe cómo reaccionarán los demás en situaciones de conflicto o separación.',
                menu1Desc12:'Las personas que llevamos en brazos y en las que invertimos nuestra vida, pueden, sin explicación alguna, convertirse en enemigos terribles y destructivos, donde cualquier cosa puede ser motivo de enfrentamiento o disputa.',
                menu1Desc13:'El divorcio representa el final de un ciclo y el comienzo de otro.',
                menu1Desc14:'La aplicación no puede garantizar ninguna victoria, pero al menos puede reducir tus pérdidas personales y financieras para que una fase de armonía y felicidad tenga más posibilidades de ocurrir en tu nueva vida.',
                
                menu2Desc: 'La aplicación está diseñada para ser simple, útil y fácil de usar. Los recursos disponibles pueden ayudar a sus usuarios a comprender mejor el tema presentado.',
                menu2Title1: 'Múltiples idiomas',
                menu2Title1Desc: 'Para facilitar la comprensión y el uso, la aplicación funciona con varios idiomas que pueden ser seleccionados según la preferencia del usuario.',
                menu2Title2: 'Análisis SWOT',
                menu2Title2Desc: 'Realice un análisis SWOT de sus datos. Es una metodología utilizada para identificar las fortalezas, oportunidades, debilidades y amenazas de un proyecto específico para realizar los ajustes necesarios que puedan garantizar el éxito del emprendimiento. ',
                menu2Title3: 'Estado Financiero',
                menu2Title3Desc: 'Es posible verificar el resultado y el costo de la planificación y su ejecución.',
                menu2Title4: 'Escenario',
                menu2Title4Desc: 'Define el escenario de divorcio basado en la información proporcionada sobre el matrimonio.',
                menu2Title5: 'Seguridad',
                menu2Title5Desc: 'Mantiene sus datos y su planificación a salvo de accesos no autorizados. Acceso mediante PIN o huella digital.',
                menu3Desc: 'La aplicación está disponible para dispositivos Android.',
                menu4Title: '¿TIENES ALGUNA PREGUNTA?',
                menu4Desc: 'Déjanos un mensaje.',
                menu5Desc: 'A continuación se muestran algunas aplicaciones que pueden ser de su interés.',
                                
                menu1ExtraTitle1: 'Ciclo PDCA',
                menu1ExtraTitle2: 'Análisis SWOT',
                menu1ExtraTitle3: 'El arte de la guerra',
                menu1ExtraDesc1:  'Las siglas PDCA significan en inglés: Plan, Do, Check, Act. Es una metodología utilizada para mejorar procesos y resolver problemas. Su propósito es ayudar a ejecutar una estrategia establecida.',
                menu1ExtraDesc2:  'Es una metodología utilizada para identificar las fortalezas, oportunidades, debilidades y amenazas de un proyecto específico con el fin de realizar los ajustes necesarios que puedan garantizar el éxito del emprendimiento.',
                menu1ExtraDesc3:  'Es un tratado militar escrito durante el siglo IV a. por el estratega conocido como "Sun Tzu". El tratado se compone de trece capítulos, cada uno de los cuales aborda un aspecto de la estrategia de guerra, con el fin de componer una visión general de todos los eventos y estrategias que deben abordarse en un combate racional.',
            },

            diary: {
                menu1: 'ACERCA',
                menu2: 'RECURSOS',
                menu3: 'DOWNLOAD',
                menu4: 'CONTACTO',
                menu5: 'EXTRA',
                menu1Desc1: 'Diario personal para registrar sueños, ideas y situaciones que han ocurrido en tu vida.',
                menu1Desc2: 'La aplicación fue desarrollada para ser un diario digital simple y fácil de usar, donde se pueden registrar las experiencias y eventos importantes de una persona.',
                menu1Desc3: 'Además, estos registros se pueden enriquecer con imágenes, sonidos y vídeos.',
                menu1Desc4: 'Se puede acceder a las funciones de forma rápida e intuitiva, sin ningún tipo de manual o instrucciones descriptivas.',
                menu1Desc5: 'Puedes crear registros de lo que sucedió en tu vida y las lecciones que aprendiste.',
                menu1Desc6: '¿Cuántas veces te gustaría recordar exactamente cuándo ocurrió un determinado evento? Con un diario siempre tendrás estos registros a tu disposición.',
                menu1Desc7: 'Escribir un diario es un método poderoso para poner en orden tus pensamientos y sentimientos. Podrás registrarlos sin ningún tipo de restricción y consultarlos cuando lo necesites.',
                menu1Desc8: 'Escribir un diario personal también estimula nuestra creatividad. ',
                menu1Desc9: 'El mismo acto de escribir puede generar inspiración, lo que lleva a nuevas perspectivas y soluciones innovadoras. ',
                menu1Desc10: 'Un diario personal ofrece un refugio seguro para la autoexpresión. ',
                menu1Desc11: 'Nos permite articular libremente nuestros pensamientos, miedos, sueños y deseos más profundos sin juicios ni inhibiciones',
                menu1Desc12: 'Los diarios personales ofrecen un espacio sagrado para la autorreflexión, la autoexpresión y el crecimiento personal. ',
                menu1Desc13: 'Sirven como testimonio de nuestro viaje único, brindando consuelo, claridad e inspiración. ',
                menu1Desc14: 'Al abrazar el poder de los diarios personales, podemos desbloquear la verdadera esencia de la autorreflexión y embarcarnos en un camino transformador hacia el autodescubrimiento y la realización.',
                menu1Desc15: 'En definitiva, esta aplicación es una herramienta que te permite registrar lo más importante que existe.',
                menu1Desc16: 'Tu vida.',
                menu2Desc: 'Diseñado para ser simple, útil y fácil de usar. Se puede acceder a las funciones de forma rápida e intuitiva, eliminando la necesidad de cualquier tipo de manual o instrucciones descriptivas.',
                menu2Title1: 'Múltiples idiomas',
                menu2Title1Desc: 'Para facilitar la comprensión y el uso, la aplicación funciona con varios idiomas que pueden ser seleccionados según la preferencia del usuario.',
                menu2Title2: 'A diario',
                menu2Title2Desc: 'Graba los momentos y eventos más importantes de tu vida para recordarlos en el futuro. Agregue de manera simple y rápida cualquier nota deseada con el título correspondiente, descripcion, fecha y hora de su aparición.',
                menu2Title3: 'Archivos adjuntos',
                menu2Title3Desc: 'Agregar archivos adjuntos a los registros realizados. Pueden ser del tipo: imagen, sonido o vídeo. También es posible colocar en cada uno de ellos descripciones que faciliten su comprensión durante la etapa de presentación.',
                menu2Title4: 'Buscar',
                menu2Title4Desc: 'Es posible buscar y localizar registros realizados por el usuario de forma sencilla y efectiva a través de su título o fecha de ocurrencia.',
                menu2Title5: 'Seguridad',
                menu2Title5Desc: 'Mantiene sus datos a salvo de accesos no autorizados. Acceso mediante PIN o huella digital.',
                menu3Desc: 'La aplicación está disponible para dispositivos Android.',
                menu4Title: '¿TIENES ALGUNA PREGUNTA?',
                menu4Desc: 'Déjanos un mensaje.',
                menu5Desc: 'A continuación se muestran algunas aplicaciones que pueden ser de su interés.',
            },

            lifeplan: {
                menu1: 'ACERCA',
                menu2: 'RECURSOS',
                menu3: 'DOWNLOAD',
                menu4: 'CONTACTO',
                menu5: 'EXTRA',
                menu1Desc: 'La aplicación es una herramienta diseñada para ayudarte a crear un plan de vida.',
                menu1bDesc: 'Un plan de vida es un documento de viaje personal que le ayuda a describir sus objetivos, prioridades, tomar decisiones y avanzar hacia el estilo de vida que imagina.',
                menu1cDesc: 'Sirve como un documento guía para navegar por la vida, brindando claridad, dirección y propósito.',
                menu1dDesc: 'Te ayuda a identificar tus pasiones, valores y prioridades, permitiéndote enfocar tu tiempo, energía y recursos en lo que realmente importa.',
                menu1eDesc: 'Un plan de vida te permite establecer metas significativas y alcanzables. Ya sea que se trate de decidir una carrera profesional, continuar con su educación o elegir un estilo de vida, le ayudará a tomar decisiones que contribuyan a su bienestar y realización general.',
                menu1fDesc: 'Un plan de vida recuerda a las personas su propósito y las motiva a perseverar en tiempos difíciles.',
                menu1gDesc: 'Anima a las personas a evaluarse a sí mismas y mejorar continuamente.',
                menu1hDesc: 'Promueve la reflexión sobre fortalezas, debilidades y áreas de crecimiento.',
                menu1iDesc: 'Alienta a las personas a priorizar el cuidado personal, las relaciones y las actividades de ocio junto con sus objetivos profesionales y financieros.',
                menu1jDesc: 'En resumen, un plan de vida sirve como una herramienta poderosa para que las personas definan su propósito, establezcan metas y naveguen por la vida con intención y pasión.',
                menu2Desc: 'Diseñado para ser simple, útil y fácil de usar. Se puede acceder a las funciones de forma rápida e intuitiva, sin ningún tipo de manual o instrucciones descriptivas.',
                menu2Title1: 'Objetivos',
                menu2Title1Desc: 'Permite describir y enumerar los objetivos a alcanzar y separarlos por categoría.',
                menu2Title2: 'Tareas',
                menu2Title2Desc: 'Le permite describir y enumerar las tareas que deben realizarse para lograr los objetivos.',
                menu2Title3: 'Tablero de deseos',
                menu2Title3Desc: 'Permite crear una tabla de deseos para visualizar y estimular el inconsciente.',
                menu2Title4: 'Motivacional',
                menu2Title4Desc: 'Proporciona frases motivadoras que inspiran a los usuarios a afrontar las adversidades de la vida con más valentía y alcanzar sus metas y objetivos.',
                menu2Title5: 'Seguridad',
                menu2Title5Desc: 'Mantiene los datos protegidos del acceso no autorizado. Acceso mediante PIN o huella digital.',
                menu3Desc: 'La aplicación está disponible para dispositivos Android.',
                menu4Title: '¿TIENES ALGUNA PREGUNTA?',
                menu4Desc: 'Déjanos un mensaje.',
                menu5Desc: 'A continuación se muestran otras aplicaciones que pueden ser de su interés.',
            },

            passwordwallet: {
                menu1: 'ACERCA',
                menu2: 'RECURSOS',
                menu3: 'DOWNLOAD',
                menu4: 'CONTACTO',
                menu5: 'EXTRA',
                menu1Desc: 'Password Wallet es una aplicación esencial para aquellos que buscan seguridad y organización en un solo lugar. Diseñada para facilitar la gestión de tu información más sensible, esta aplicación almacena de forma segura documentos, contraseñas y datos confidenciales utilizando una única contraseña maestra. A través de una interfaz intuitiva y un diseño elegante, puedes acceder rápidamente a todo lo que necesitas sin sacrificar la seguridad.',
                menu1bDesc: 'Con Password Wallet, olvidar una contraseña o perder documentos importantes son preocupaciones del pasado. La aplicación utiliza cifrado avanzado para asegurar que tu información esté protegida contra accesos no autorizados, mientras ofrece una experiencia de usuario fluida y agradable. Además, puedes categorizar tu información en carpetas personalizadas, facilitando la organización y el acceso rápido cuando sea necesario.',
                menu1cDesc: 'Ideal para profesionales que necesitan mantener una gran cantidad de datos al alcance de la mano, o para cualquier persona que valore la seguridad digital, Password Wallet es más que un simple gestor de contraseñas. Es una caja fuerte digital que te acompaña en todo momento, garantizando tranquilidad y mayor productividad. Simplifica tu vida digital con Password Wallet, tu aliado confiable en el mundo digital.',
                menu2Desc: 'Diseñado para ser simple, útil y fácil de usar. Se puede acceder a las funciones de forma rápida e intuitiva, sin ningún tipo de manual o instrucciones descriptivas.',
                menu2Title1: 'Presentación',
                menu2Title1Desc: 'Todos tus datos están organizados y mostrados de forma clara y accesible. Cada entrada está representada por una tarjeta de datos visualmente distinta, que incluye un título y una categoría específica. Este enfoque permite a los usuarios ver rápidamente información relevante, lo que facilita la identificación y el acceso a los datos necesarios con eficiencia y estilo.',
                menu2Title2: 'Ver',
                menu2Title2Desc: 'Con una interfaz intuitiva, la App te permite ampliar cada registro con un simple toque. Cuando selecciona una tarjeta de datos, se muestra su contenido completo, incluidos detalles adicionales almacenados. Esta funcionalidad es esencial para una gestión eficiente de su información, permitiéndole editar, actualizar o eliminar datos según sea necesario.',
                menu2Title3: 'Adjuntos',
                menu2Title3Desc: 'La funcionalidad de archivos adjuntos en la Aplicación enriquece la gestión de su información. Puede agregar fácilmente imágenes a cualquier registro. Cada archivo adjunto puede ir acompañado de una descripción detallada, lo que proporciona contexto adicional y garantiza que todos los aspectos relevantes de su información sean accesibles y estén bien organizados.',
                menu2Title4: 'Organización',
                menu2Title4Desc: 'Organiza tus registros de manera más eficiente con la funcionalidad de categorización. Puede crear categorías personalizadas para clasificar sus datos, lo que facilita la organización y la recuperación rápida de información específica. La búsqueda de categorías le permite filtrar y encontrar rápidamente los registros que necesita, optimizando su flujo de trabajo y aumentando su productividad.',
                menu2Title5: 'Seguridad',
                menu2Title5Desc: 'La aplicación está protegida por una contraseña personal, lo que garantiza que solo usted tenga acceso a su información confidencial. Esta contraseña maestra es la clave para desbloquear todos sus datos, los cuales están encriptados con tecnología de punta para brindar protección adicional contra el acceso no autorizado. Acceso mediante PIN o huella digital.',
                menu3Desc: 'La aplicación está disponible para dispositivos Android.',
                menu4Title: '¿TIENES ALGUNA PREGUNTA?',
                menu4Desc: 'Déjanos un mensaje.',
                menu5Desc: 'A continuación se muestran otras aplicaciones que pueden ser de su interés.',
            },
           msgs: {
               code1: 'CORREO ELECTRÓNICO NO VÁLIDO',
               code2: 'CONTRASEÑA NO VÁLIDA',
               code3: 'EL CORREO ELECTRÓNICO NO EXISTE',
               code4: 'NOMBRE NO VÁLIDO',
               code5: 'DESCRIPCIÓN NO VÁLIDA',
               code22: 'DUPLICATE NAME IS NOT ALLOWED', 
               code27: 'THERE ARE NO PREVIOUS PAGES', 
               code28: 'THERE ARE NO PAGES', 
               code29: 'NO SEARCH FOUND',  
               code99: 'ERROR UNDEFINED'  
           }
        }
    }
}

export { messages };