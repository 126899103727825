
import React, { useEffect, useState } from 'react';   
import * as S       from './styles';
import guide35      from '../../../../view/DivorceGuide/Guide/Images/guide35.png';
import guide36      from '../../../../view/DivorceGuide/Guide/Images/guide36.png';
import guide37      from '../../../../view/DivorceGuide/Guide/Images/guide37.png';
import mgtow        from '../../../../view/DivorceGuide/Guide/Images/mgtow.png';
import util         from '../../../../Utilities/Util';

function GuidePart8() {

    const [access, setAccess] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)
        setAccess( util.checkGuideAccess() );
    }, [])
    
    return (
        <S.Container>
        { ( access )
            ?
            <>
            <S.Form> 

            <S.FormTitle>
                <span>PART 8</span><br></br><br></br><br></br><span>You are not alone</span><br></br><br></br>
            </S.FormTitle>

            <S.FormText>
                <span>

                Building a family is something noble and divine. It is the desire and dream of many men and women. <br></br><br></br>
                The family is the central pillar of a society. <br></br><br></br>
                It is through it that the physical and mental health of people develops. <br></br><br></br>
                Being educated in a structured and happy family significantly increases the chances of success in the formation of adults with high ethical and moral values, who will collaborate and influence the development of a society with these same characteristics. <br></br><br></br>
                Currently, modern society is experiencing an unprecedented crisis of values where the available technology enhances and further aggravates this situation. Human ideals are being forgotten and becoming romantic dreams of the past. <br></br><br></br>
                From the cultural and social revolutions that took place in the last century these values began to be fought and modified. The economic crises collaborated intensely with these changes producing a rupture in the established family nucleus. <br></br><br></br>
                Men could no longer support their families on their income. This task had to be shared with their wives who consequently stopped taking care of their children exclusively and began to join the predominantly male labor market. <br></br><br></br>
                One of the first results of this action was the increase in the supply of available labor, further contributing to the decrease in wages and the problem described. <br></br><br></br>
                Women could not occupy all the available positions and had to be protected and favored with lighter and safer jobs a trend that continues to this day where men occupy the heaviest, dirtiest and most dangerous jobs. <br></br><br></br>
                Women began to invest in their professional careers to earn higher wages and gain financial independence. For this they had to prioritize their careers to the detriment of caring for their children. <br></br><br></br>
                They began to be educated by babysitters, maids, school, television, the Internet, and so on. These new elements became responsible for the physical, psychological and moral development of these new individuals. <br></br><br></br>
                The absence of the father and mother was compensated with material consumption of all kinds of uselessness such as toys, video games, designer clothes, etc. <br></br><br></br>
                Soon, having something became more important than being something. This produced and continues to produce toxic, weak people without moral values who in turn end up producing children with the same deficiencies.<br></br><br></br>            
                
                <i><b>It is an endlessly repetitive cycle that will end only with the collapse of society</b></i>.<br></br><br></br>
                Despite this tragic scenario many men still dream of the initial ideas described devoting most of their lives to the families they have worked so hard to build. <br></br><br></br>
                They work incessantly to pay their bills without having time to realize the reality that surrounds them spending their little free time on useless and worthless things. <br></br><br></br>
                Women, contrary to what has been described do not agree to share their income equally nor do they agree in any case to support any man. <br></br><br></br>
                Unlike the men their income is unknown and belongs exclusively to women and not to the nuclear family as it happens to them. <br></br><br></br>
                Rare are the cases contrary to this statement that when they occur are from women of previous generations or high character inherited from a traditional family upbringing. <br></br><br></br>
                Events of the last few decades have elevated and exposed female hypergamy and have also produced numerous laws and benefits unique to the needs and well-being of women. <br></br><br></br>
                Currently, men are easily discarded and quickly transformed into eternal or long-term providers even single and childless men can already be captured and forced to supply the needs of women. <br></br><br></br>
                Even being discarded and annihilated in divorce proceedings or in the application of unfair and sexist laws many continue to sleep before the denounced scenario. <br></br><br></br>
                In more serious cases they seek suicide or commit acts of violence that further aggravate their situation favoring women and worsening the image of men in society. <br></br><br></br>
                Many men victimize themselves making their recovery difficult even judging themselves responsible for the end of the union. <br></br><br></br>
                Naively and romantically, some seek new relationships in an attempt to remedy their frustrations and rebuild their lives only to end up with the same tragic end or worse than the last. <br></br><br></br>
                Finally, they end up waking up to the reality that surrounds them and trying to find out who is really responsible for their problems. <br></br><br></br>
                They discover that they are not alone and that nothing that happens is the result of chance, bad luck or bad decisions. <br></br><br></br>
                That they are victims of planned actions coming from evil and unscrupulous groups or from people who benefit from this situation and from the social degradation in which we are inserted.         
                <br></br><br></br><br></br>
                <h2>Feminism</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide35} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                It is a political, social and philosophical movement that fights to free women from existing male oppression, through the creation of laws and norms that guarantee rights and opportunities in all sectors of society. <br></br><br></br>
                Throughout human history, many women have suffered abuse and injustice. <br></br><br></br>
                However, many of these crimes did not occur exclusively because of their gender, but because of the evil and human ignorance existing in both sexes. <br></br><br></br>
                If we were to use gender as a cause of suffering or injustice the men would be at the forefront and never be outdone. <br></br><br></br>
                It is true that many women have struggled, suffered and achieved great things not because of their gender, but because of their own merits. Whether intellectual, artistic, professional or of any other nature, but all acquired with work, effort and dedication. <br></br><br></br>
                From the previous century to the present an enormous amount of available literature seeks forcefully to demonstrate that women are despised, mistreated, decriminalized and oppressed by men. <br></br><br></br>
                In fact, since the Paleolithic, women have been protected and supported by them. <br></br><br></br>
                They were the ones who had to walk for days in a hostile environment full of predators and countless dangers behind the hunt that provided the necessary fat and protein for the survival of the community. While women stayed safe by collecting seeds and caring for their young children. <br></br><br></br>
                It was a tough and difficult life for both of them, but women still had a huge advantage over their peers. Even when the community was attacked and conquered by rival groups women still had the option of joining the victors in order to survive. 
                Wwhile the men were left with only death or enslavement that would inevitably lead to the same end. <br></br><br></br>
                This feature has been repeated throughout human evolution. Women have always been spared the need to wage war of the perform military service or do heavy and dangerous work, regardless of culture, geographic region, or historical period. <br></br><br></br>
                This advantage persists to this day. Women have priority of salvation in cases of accidents or tragedies. Women are the first to be released in hostage situations. In these same cases there are still men who volunteer to replace them while the reverse is never true. <br></br><br></br>
                All the suffering and oppression suffered by women in history is infinitely less than all the suffering and injustice suffered by men. <br></br><br></br>
                In addition, it was the men themselves who freed women from these periods of oppression. Many of whom gave their lives to fulfill this task. <br></br><br></br>            
                
                Women played an important role, but it was mostly men who fought and died to build our civilization and they are still the ones who keep it going.<br></br><br></br>
                Currently, women use the period of political stagnation and military stalemate to subjugate and extract the maximum possible rights and benefits from men, either through sexist laws that protect them or indirectly through benefits granted by the state.<br></br><br></br>
                Feminists want it all and more. The important thing is to subjugate men and transform them into providers and servants of women's wants and needs. <br></br><br></br>
                Women do not want to fulfill their duties and roles in society. They consider it an imposition of the patriarchy. They want to do what they want. Have rights without obligation. Freedom without responsibility. Be able to commit atrocities without punishment not giving those same rights to men. <br></br><br></br>
                Anyone who rebels or tries to evade their role as provider will be persecuted and destroyed by justice or by society itself. The same happens with women who declare themselves against this movement. They will be rejected, persecuted and annihilated. <br></br><br></br>
                It is a contradictory, irrational and incoherent movement in its speeches and demands. Being a political instrument planned and used by perverse and greedy groups. This is a blow to the established society. <br></br><br></br>
                It is an irreversible process of global proportions which cannot be interrupted or even mitigated. Steadily, gradually and imperceptibly new laws and procedures are created to further benefit women. <br></br><br></br>
                Many men don't even have the ability to realize or react against it. Men were educated to protect and serve women. Most men consider women's claims valid and legitimate. Men sacrifice their personal, professional, financial and even physical lives in favor of women.
                <br></br><br></br>
                Feminism is a movement contrary to Judeo-Christian morality. It is one of those responsible for dismantling the traditional family and the hard-won moral values that built our civilization. <br></br><br></br>
                It is an offense against all the men and women who fought and died to protect the rights and values won that shaped all that we are today. <br></br><br></br>

                <br></br><br></br><br></br>
                <h2>The Bitter Medicine</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide36} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>
                
                The movement described is like a virus that spreads and contaminates the entire society especially the youngest and least enlightened. <br></br><br></br>
                Its consequences are terrible and painful. Affecting and annihilating mainly the male part of the population. <br></br><br></br>
                Women become healthy carriers of this disease. They are capable of contaminating and corrupting other individuals who come into contact with their ideas and claims. <br></br><br></br>
                Those affected by this virus carry terrible sequels that mainly affect their brains, such as: irrationality, intolerance, inconsistency, etc.
                However, nothing is more disturbed than his hypergamy and hedonia rising to off-the-charts levels. <br></br><br></br>
                Like an epidemic disease it must be fought.
                However, there is no medicine or treatment that can cure or combat it. Except through prevention. <br></br><br></br>
                It is a bitter medicine. Especially for younger people who do not carry out preventive procedures. Being easily captured and annihilated by women affected by this disease. <br></br><br></br>
                Unfortunately, there is still a lot of disagreement in society about this disease and its consequences.
                Many believe that it is something temporary and unimportant. Others think that they will not be affected and that they do not need to worry. Others do not even know that the problem exists. <br></br><br></br>
                These divisions and ignorance only favor its proliferation which has already reached pandemic levels.
                
                <br></br><br></br><br></br>
                <b>MRA - Men’s Rights Activism</b>
                <br></br><br></br>

                The Men's Rights movement is a meeting of people and institutions that address social problems and government actions that exclusively affect men. <br></br><br></br>
                Its objective is to raise awareness and alert society about the abuse and discrimination suffered by men through sexist laws. <br></br><br></br>
                Many critics and academics describe it as a movement opposed to feminism. Many of them also describe this movement as misogynistic and based on the loss of privileges not rights. <br></br><br></br>
                It is a movement strongly criticized and silenced by the media and government institutions. Most of the issues raised are frowned upon and are not discussed by the media. <br></br><br></br>
                For years men were seen as the oppressors of women. So movements like this are not taken seriously and are viewed with suspicion by the general public. <br></br><br></br>
                It is a source of information for those who seek the truth of the facts. Many lies, abuses and atrocities committed against men are revealed by this institution. <br></br><br></br>
                However, during its time in existence this movement as well as its associates <b>did NOT produce significant gains for the benefit of men</b>.
                
                <br></br><br></br><br></br>
                <b>BLUE PILL</b>
                <br></br><br></br>

                It is not a movement. It is a classification. <br></br><br></br>
                It refers to the movie "Matrix" where the hero of the story must choose between two pills. 
                One blue and one red. By taking the first, he would return to his old life believing in the same old things while the other would show him how the real world worked. Changing his life forever. <br></br><br></br>
                Most men in our society classify themselves as "Blue Pill". <br></br><br></br>
                They are unaware of the truth of the facts and believe what the media and institutions say. 
                Everything for them is correct and works correctly. <br></br><br></br>
                They do not care about learning or evolving. They do not have established or defined objectives. They act on impulse and let things happen. They accept living with toxic and oppressive women. 
                They accept the laws and believe in the good faith of the institutions. <br></br><br></br>
                They do not ask questions nor do they have a critical sense. 
                They repeat what their parents did, and what society continues to do.
                
                <br></br><br></br><br></br>
                <b>PURPLE PILL</b>
                <br></br><br></br>

                It is one range above the previous one.
                He also embodies a large part of male society. <br></br><br></br>
                They are traditionalist and conservative men. <br></br><br></br>
                Many of them are already involved in the men's rights movement and are aware of the issues surrounding marriage and divorce.
                They are aware that things are wrong. That the laws do not work and that they are sexist.
                They know that many women are toxic and abusive. <br></br><br></br>
                Despite this knowledge, they still believe in institutions and that they can be fixed.
                They believe that they will find different and special women.
                They think that if they make correct and preventive use of the laws they will be protected. <br></br><br></br>
                The men who are in this classification want to continue playing by the old rules. They have not adapted to the new reality. <br></br><br></br>
                They are sabotaging themselves. It is a matter of time before they are harmed by a woman or a sexist law.
                
                <br></br><br></br><br></br>
                <b>RED PILL</b>
                <br></br><br></br>

                It represents the opposite end of the blue classification. <br></br><br></br>
                Only a select group of men are part of it. <br></br><br></br>
                They know that everything is wrong and that the institutions are bankrupt and beyond recovery. <br></br><br></br>
                They know that justice does not work and that the laws only favor women. <br></br><br></br>
                That women have become toxic and dangerous. So they are no longer trapped in romantic visions of relationships. <br></br><br></br>
                They no longer want to risk or be harmed. They are willing to adapt to this new reality in order to survive. <br></br><br></br>
                Despite this knowledge and attitude many are still attracted to women and can easily be seduced and deceived by them. Consequently making the same mistakes and being captured by the system.
                
                <br></br><br></br><br></br>
                <b>MGTOW</b>
                <br></br><br></br>

                <table width="100%">
                <tbody>
                <tr>
                <td width="30%">Acronym for <u>M</u>en <u>G</u>oing <u>T</u>heir <u>O</u>wn <u> W</u>ay<br></br><br></br>
                It is not a social or political movement, it is a philosophy of life. </td>
                <td width="70%"><S.FormImage><img src={mgtow} alt=""></img></S.FormImage></td>
                </tr>
                </tbody>
                </table>

                <br></br><br></br>
                They do not have people, groups or institutions that represent them. 
                They do not demand anything nor do they want to change anything. They are dispersed. They do not meet in clubs, fairs or congresses.<br></br><br></br>
                They are ordinary people who do not declare to their friends or relatives the life option they have adopted. <br></br><br></br>
                They are people who are aware of the existing problems. They are the "REDPILL" who have adapted to this new reality. <br></br><br></br>
                They will not give an opportunity to be captured by this backward and criminal system. <br></br><br></br>
                They will not feed the industry of divorce, child support, domestic violence, lawyer networks and other mechanisms created to destroy and enslave men and only benefit women and related groups. <br></br><br></br>
                There are three fundamental rules that cannot be broken:
                <br></br><br></br>
                <b>
                1. NOT MARRY;<br></br>
                2. NOT LIVING TOGETHER;<br></br>
                3. DO NOT GET ANY WOMAN PREGNANT.
                </b>

                <br></br><br></br>
                Women no longer seduce or trick MGTOWs. <br></br><br></br>
                They are not dependent on women. 
                They can live their lives without them.<br></br><br></br>
                The most they do is have superficial or sporadic relationships with one of them, but always respecting the three basic rules and not giving them the opportunity to fall into some kind of trap. <br></br><br></br>
                They know that men have been conditioned from childhood to raise and maintain a family. <br></br><br></br>
                Breaking this conditioning is difficult and painful, but extremely necessary to survive today. <br></br><br></br>
                All institutions are corrupted and can no longer help you. <br></br><br></br>
                Their rights will no longer be respected and will be easily annihilated by women. Men no longer have rights only obligations. <br></br><br></br>
                So there is no reason to start a family, build a patrimony or have children. Eventually, they will lose everything they have built including their children who will become alienated and toxic to them. <br></br><br></br>
                It is crazy to feed this system especially with your lives. Also, many men go further. They want to feed the entire created political and social system with as little as possible and preferably with nothing. <br></br><br></br>
                Many begin to lead a simple life without being seduced by consumption, accumulating only what is necessary for their survival and comfort. 
                Some move away from the great centers producing their own food, shelter, and energy further reducing their financial contribution to the described system. <br></br><br></br>
                If this lifestyle were adopted by 20% of men it would cause a collapse in society. There would be an unprecedented drop in tax revenue. 
                The institutions could not survive nor could they support women with the benefits guaranteed by law. <br></br><br></br>
                Even if it is not their goal or intention <u>it is the only existing process that can combat feminism</u>. <br></br><br></br>
                Soon, this movement began to be persecuted and ridiculed by women and institutions. 
                The men who follow this movement are branded as failures, effeminate and any adjective that degrades them. <br></br><br></br>
                As described it is not a social or political movement with leaders and regulations. <br></br><br></br>
                It is a personal position adopted by a man being a philosophy of life followed by him. 
                Those who follow this lifestyle do not identify themselves nor do they discuss their lives with anyone. Therefore they cannot be persecuted. <br></br><br></br>
                The MGTOWs are out of this crazy and dirty game that has been installed in society and that destroys the men and the families they have built.
                <br></br><br></br>

                It is a philosophy built by levels, The main and most important ones are described below:
                <br></br><br></br>

                <table border="1" width="100%">
                <tbody>
                <tr><td bgcolor='#000000' width="20%"><b><font color="white">LEVEL 1</font></b></td><td width="80% ">
                Follow the three rules.<br></br>
                They reject long-term relationships.<br></br>
                Relationships based solely on sex.<br></br>
                <b>DO NOT INTERACT WITH SINGLE MOMS</b>. (fourth rule)<br></br>
                </td></tr>
                <tr><td bgcolor='#000000' width="20%"><b><font color="white">LEVEL 2</font></b></td><td width="80% ">
                They reject both long-term and short-term relationships.<br></br>
                They reject interactions with women including casual sex.<br></br>
                Minimal contact with women simply cordial and professional.<br></br>
                </td></tr>
                <tr><td bgcolor='#000000' width="20%"><b><font color="white">LEVEL3</font></b></td><td width="80%" >
                Level 2 that begins to carry out the economic separation.<br></br>
                They look for informal jobs to avoid taxes.<br></br>
                They try to be in the lowest IR range possible.<br></br>
                They begin to put obstacles to state spending.<br></br>
                </td></tr>
                <tr><td bgcolor='#000000' width="20%"><b><font color="white">LEVEL 4 (GHOST)</font></b></td><td width= "80%">
                They reject interactions with women and society.<br></br>
                They minimize contact with people and urban environments.<br></br>
                They become independent of power, protection and energy.<br></br>
                They can survive without state help.<br></br>
                They are not influenced by social conflicts or urban violence.<br></br>
                They will not be affected by social, economic or political collapses that may occur.<br></br>
                </td></tr>
                </tbody>
                </table>
                <br></br><br></br>

                <br></br><br></br><br></br>
                <h2>End of the game</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide37} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                Divorce is a terrible and scary game of war. It separates people, annihilates heritage, destroys lives. 
                It is a game of marked cards where the winner is already known. <br></br><br></br>
                It is a worldwide <u><b>IRREVERSIBLE</b></u> phenomenon. <br></br><br></br>
                In it, families break up and men annihilate each other and become slaves or servants. 
                All this is sad, dark and horrible. Most people believe that this is normal. Many are in a deep and perpetual sleep. 
                They don't know what's going on around them.<br></br><br></br>
                It takes a lot of pain and suffering to wake up to reality. 
                Even so, many go back to sleep. The truth is not accepted or desired. It's easier to close your eyes to everything. 
                Changing things takes a lot of work and produces a lot of pain. Nobody wants that. 
                It is preferable to extend this situation as long as possible to avoid this affliction. <br></br><br></br>
                Currently, our society is moved by money, consumption, individualism, lies, fear, violence or any other degrading value. 
                Institutions do not work because they are run by people who follow these values. 
                So the truth is something inconvenient that takes work, wastes time and energy and reminds us of what we really should be and do. <br></br><br></br>
                No one wants to experience any discomfort. The consolation of lies and illusion is preferable. 
                It is better to believe that things will change that justice works that good will defeat evil, etc. <br></br><br></br>
                Nevertheless, all these are just illusions created by our mind that make us avoid suffering in the short term. 
                So we prolong our pains in order to make them more diluted and bearable. <br></br><br></br>
                On top of that, our eyes are also closed due to our beliefs. 
                We are surrounded by lies and we have been educated and conditioned to accept them as absolute and unquestionable truths. 
                In this way we are like cattle always led by someone, without having the ability to go our own way.
                We don't even know that we are free to do it. 
                Only exceptional people or people who have gone through a situation of great anguish or suffering can wake up to this reality and realize all this. <br></br><br></br>
                Divorce has this power. It's a painful thing that makes many men wake up. 
                However, despite the damage and pain caused some remain attached to their old beliefs and end up making the same mistakes. 
                Remaining in an <u>infinite loop</u> until they are finally annihilated in whatever other process they undergo. 
                Having at that moment his beliefs definitely shaken. <br></br><br></br>
                When they wake up from this deep sleep, they discover that the institutions are bankrupt. 
                That the laws are unfair and sexist. 
                That immense resources are spent to maintain systems and institutions that do not work. 
                They realize that people are ignorant, dishonest, cowardly and immoral. 
                In addition to several other misfits that prove that today's society is irretrievable. <br></br><br></br>
                There are many honest, intelligent, brave people, with high moral values who are directly or indirectly responsible for avoiding the dismantling of society. 
                However, they cannot prevent its total collapse nor can they correct the problems and moral deviations that are accumulating. <br></br><br></br>
                Many people believe that they can solve this chaos through politics, economics or new technologies. 
                Unfortunately, these elements did nothing more than <u>increase and facilitate</u> the situation described.
                Mainly the technology which became a powerful weapon of control and alienation. <br></br><br></br>
                Soon everyone will be tagged. All remaining individuality and privacy will be removed. <br></br><br></br>
                These same people also believe in the common sense of leaders and institutions. 
                That everything is a matter of time before human virtues overcome the installed degradation. <br></br><br></br>
                
                Terrible mistake, there are no precedents in all of history. 
                No empire, government or organization became just, peaceful or virtuous through the acquisition of common sense by its constituents. 
                They always collapsed or were destroyed before a new one arose in their place. 
                No dictator or leader left office out of charity or altruism. 
                They died or were deposed. 
                Those in control of the situation do not want changes or improvements, unless it is to further increase their dominance and power. <br></br><br></br>
                Therefore, it is feasible to say that the levels of madness, degradation and injustice will increase more and more. 
                Men will be the most <u>damaged and persecuted</u>.
                They will have to support women directly or indirectly whether or not they have the physical or economic conditions to do so. 
                The resources necessary for this task will have to appear by magic. Whatever the labor market situation or the existing political and financial scenario. <br></br><br></br>
                There will be no common sense or charity as there are none today. 
                Under no circumstances will any politician, legislator or person of influence speak out or act against any law or subsidy that benefits women and harms men. 
                Even if it is something unfair, irrational or immoral. <br></br><br></br>
                They will remain inert without doing anything. 
                If anyone has the courage or audacity to try he will be quickly silenced and annihilated. 
                Being branded as a misogynist or worse. Ruining your political or professional career. <br></br><br></br>
                So there is no way to reverse this scenario. It is immutable. 
                Being the feminist movement the great winner of this story. 
                It managed to destroy family and social values conquered for centuries. 
                It will continue to grow and produce new laws, benefits and unjust and insane claims with no one being able to stop it. <br></br><br></br>  
           
                <i><b>There is not nor will there be any law or program that benefits men.</b></i>. <br></br><br></br>
                They can only denounce the abuses and injustices of the current laws, but nothing will be done to reverse this situation. 
                Even women who oppose the feminist movement or who are sympathetic to the issues facing men will also not do anything. 
                No woman gives up existing and future benefits. They do not accept a relationship with a man of lower class or salary. <br></br><br></br>
                The discourse of equality is not valid in the real world. It only serves as an argument to achieve benefits for women. 
                Men are always seen as violent, oppressors and rapists.
                As punishment men must protect and support all women in society. 
                Anyone who tries to escape this fate will be severely penalized. <br></br><br></br>
                Women have also become extremely demanding with men.
                Only a small minority of men is capable of reaching the high levels that today's women demand. 
                The vast majority of men have been left out, serving only as a possible <u>replacement</u> if they don't find their ideal type of man or when they are no longer attractive.
                Many needy and lonely men accept women of this type and end up becoming even weaker and more passive. <br></br><br></br>
                Others are failing to seriously engage with women for fear of being caught and destroyed by sexist laws.
                So, we have a scenario where people no longer form lasting affective bonds nor do they generate children. <br></br><br></br>
                Pregnancy levels are falling all over the world, they will soon reach catastrophic levels. 
                Many women are also preferring to invest in their professional careers giving up their maternal role. 
                When they try they can't reconcile all their goals at the same time.
                So, They blame men for their failure, tiredness and lack of structure. 
                Women do not want to be responsible for mistakes in raising children. <br></br><br></br>
                Divorce becomes a simple solution to this problem. 
                They can maintain their standard of living and educate their children as they wish without being charged or pressured by their spouses. <br></br><br></br>
                After all as has already been exhaustively stated children belong exclusively to women,being able to serve as a shield or argument for the most varied purposes. Mainly to <u>value them</u> in society. <br></br><br></br>
                Some single women prefer to have children through artificial insemination. 
                This <u>adds</u> value to their place in society and generates individuals who will sustain them in the future. 
                In many countries women like these receive various benefits from the government to educate their children. 
                Paid for through taxes collected from married and single men. 
                It is fair to imagine that in the near future the secrecy of these institutions that perform human inseminations will be broken in order to pass this obligation on to the biological parents. <br></br><br></br>
                Women became <u>toxic and oppressive</u> and men became <u>weak and passive</u>. 
                Institutions have become criminal, bad and destructive. It cannot be saved or corrected. <br></br><br></br>
                Today we have the knowledge and technology to live in a rich and happy world. 
                However, we continue to be driven by our egos or by dark interests. <br></br><br></br>
                Men and women are different and can never will be equals, but they are definitely equal in dreams and needs and can be equal in rights and obligations. <br></br><br></br>
                However, as long as there is an imbalance between these forces and above all in the character of people. 
                We will continue to play this dirty game which destroys families and produces lonely, repressed and unhappy people. 
                Where there are only losers. <br></br><br></br><br></br>
                
                <b>What should we do?</b><br></br><br></br>
                If you also think something is wrong. 
                That your dreams and your future are threatened by the fact of being a man. 
                Then: erase your conditioning and wake up to reality. <br></br><br></br>
                Seek the truth, study and be with your mind in the present, but plan your future. <br></br><br></br>
                Search for your salvation. 
                Search for your health, protection and well-being. 
                Search for your personal and financial freedom. <br></br><br></br>
                Do not try to recover or save failed institutions. 
                Do not waste your time or energy on it. <br></br><br></br>
                Do all this in silence. 
                Don't expose yourself. 
                Don't want to educate those who don't want to learn and <u><b>NEVER</b></u> reveal your plans.<br></br><br ></br>
                Have faith, courage and determination.
                <br></br><br></br><br></br>

                In 1983, theaters showed a science fiction movie where a teenager has access to an artificial intelligence that controlled the entire US military system. <br></br><br></br>
                He thought he was just playing a sophisticated computer game. He activates the military offensive protocol. This could trigger a real nuclear war, because the computer didn't know the difference between real and virtual and had been programmed to play and win. <br></br><br></br>
                It was necessary to teach it that there would be no winners in the real world. The movie ends with the computer coming to a factual conclusion.
                <br></br><br></br><br></br>
                As long as there are misfits in laws and in today's society. 
                It is <u><b>NOT</b></u> possible to guarantee a healthy and happy marriage. 
                Divorce will be encouraged and facilitated always harming people. Particularly men and their children who will be the most affected. <br></br><br></br>
                Therefore, the only way to win this game is to follow the conclusion that the computer came to from the described movie:
                <br></br><br></br><br></br>
                <h2><b>It's a strange game, the only way to win is not to play.</b></h2>

                </span>
            </S.FormText>

            </S.Form>
            </>
            :
            <>
            <br></br>
            <br></br>
            <h3>&nbsp;</h3>
            <br></br>
            </>    }  
        </S.Container>
    )
}

export default GuidePart8;