import styled from 'styled-components';


export const Container = styled.div`
    background:  #FFFFFF;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const ParallaxContainer = styled.div`
    background: none;
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 80px;

    @media screen and (max-width: 960px) {
        flex-direction: column;
        align-items: center;
    }
`

export const ParallaxPart1 = styled.div`
    background: none;
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 960px) {
        width: 100%;
    }
` 

export const ParallaxPart2 = styled.div`
    background: none;
    width: 60%;
    display: flex;
    margin-top: 100px;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 960px) {
        //height:450px;
        width: 100%;
        margin-top: 80px;
    }
` 

export const TitleParallax = styled.div`
    background: none;
    font-size: 58px;
    font-family: 'Roboto', sans-serif;
    text-decoration: none;
    font-weight: bold;
    color: #000000;
    text-align: center;

    @media screen and (max-width: 960px) {
        font-size: 42px;
    }

`

export const HeaderParallax = styled.div`
    background: none;
    font-size: 56px;
    font-family: 'Roboto', sans-serif;
    text-decoration: none;
    text-align: center;
    font-weight: bold;
    color: #000000;
    margin-top: 10px;

    @media screen and (max-width: 960px) {
        font-size: 42px;
    }

`

export const SubTitleParallax = styled.div`
    background: none;
    font-size: 36px;
    font-family: 'Roboto', sans-serif;
    text-decoration: none;
    text-align: center;
    font-weight: bold;
    color: #70AD47;

    @media screen and (max-width: 960px) {
        font-size: 26px;
    }

`

export const DescriptionParallax = styled.div`
    background: none;
    font-size: 26px;
    font-family: 'Roboto', sans-serif;
    text-decoration: none;
    text-align: center;
    color: #707070;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 50px;

    @media screen and (max-width: 960px) {
        font-size: 18px;
        margin-left: 10px;
        margin-right: 10px;
    }

`

export const FrameTechImage = styled.img.attrs(({ src }) => ({
    src: src,
    alt: '',
  }))`
    margin-left: 40px;

    @media screen and (max-width: 960px) {
        margin-top: 100px;
        margin-left: 0px;
}
`

export const ContainerTechImage = styled.div`
    background: none;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
        width: 90%;
        }
        
    @media screen and (max-width: 960px) {
        img {
            width: 65%;
            }
`

export const ContainerText = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 180px;
    margin-left: 80px;
    background:  none;
    font-size: 72px;
    font-family: 'Roboto', sans-serif;
    color: #FFFFFF;
    
    @media screen and (max-width: 960px) {
        flex-direction: column;
        font-size: 38px;
        margin-left: 40px;
    }
`

export const TecContainer = styled.div`
    background: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 140px;
    margin-bottom: 140px;

    @media screen and (max-width: 960px) {
        flex-direction: column;
        margin-right: 2px;
        margin-left: 2px;
    }
`

export const OptionContainer = styled.div`
    background: none;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 120px;
    }
`
export const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    background: none;
    font-size: 34px;
    font-family: 'Roboto', sans-serif;
    text-decoration: none;
    font-weight: bold;
    color: #70AD47;
    margin-right: 10px;
    margin-left: 50px;

    @media screen and (max-width: 960px) {
        font-size: 28px;
    }
    }
`

export const LineContainer = styled.div`
    background: #70AD47;
    width: 100%;
    height: 2px;
    margin-right: 50px;
    }
`

export const TextContainer = styled.div`

    display: flex;
    align-items: center;
    flex-direction: column;
    background: none;
    background: #FF0000;
    width: 100%;
    font-size: 28px;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    color: #707070;
    margin-top: 30px;

    span {
        font-size: 16px;
        font-family: 'Roboto', sans-serif;
        }

    @media screen and (max-width: 960px) {
        font-size: 24px;
        margin-left: 5px;
    }
    }
`

export const MidiaButton = styled.button`
    background: none;
    border: none;
    margin-top: 50px;

    &:hover {
        opacity: 0.7;
    }
`