const messages = {
    pt: {
        translations: {

            literals: {
                name: 'Nome',
                message: 'Mensagem',
                email: 'Email',
                password: 'Senha',
                language: 'Linguagem',
                policyPrivacy: 'Política de Privacidade',
                policyCookie: 'Política de Cookies',
                subject: 'Informação',
                country: 'Reino Unido',
                address: 'Endereço',
            },

            header: {
                app2:  'PLANEJAMENTO DO DIVÓRCIO',
                app3:  'DIÁRIO PESSOAL',
                app4:  'PLANO DE VIDA',
                app5:  'PASSWORD WALLET',
                book1: 'GUIA DO DIVÓRCIO PARA HOMENS',
            },

            trailer: {
                title1:  'Links Rápidos',
                title2:  'Ajuda & Suporte',
                title3:  'Outros',
                option1: 'Termos de uso',
                option2: 'Política de Privacidade',
                option3: 'Contato',
                option4: 'Aplicativos',
                option5: 'Página Principal',
            },

            buttons: {
                ok: 'Ok',
                cancel: 'Cancelar',
                login: 'LOGIN',
                send: 'Enviar',
                clear: 'Apagar'
             },

            titles: {
                cardTitle: 'Desenvolvimento de software',
                cardDescription: 'Codifique seus Sonhos',
                contact: 'Entre em Contato',
                product: 'Aplicativos',
            },

            products: {
                app1Title: 'GUIA DO DIVÓRCIO',
                app1SubTitle: 'O aplicativo descreve os principais elementos de um divórcio e suas consequências.',
                app1Description: 'Ele foi criado para ensinar, ajudar e alertar as pessoas que pretendem realizar um divórcio  ou que já estão passando por esse procedimento, mas não sabem o que esperar nem como agir nesse momento de suas vidas.',
               
                app2Title: 'PLANEJAMENTO DE DIVÓRCIO',
                app2SubTitle: 'Aplicativo para planejar e monitorar um processo de divórcio.',
                app2Description: 'Ele foi desenvolvido com o objetivo de fornecer um software capaz de ajudar seus usuários a planejar, realizar, medir e monitorar um divórcio. Ele utiliza alguns dos conceitos descritos no livro "A arte de guerra" em sua concepção. Também utiliza as metodologias de ciclo PDCA e análise SWOT em sua estrutura de funcionamento.',
            
                app3Title: 'DIÁRIO PESSOAL',
                app3SubTitle: 'Diário pessoal para registrar sonhos, ideias e situações que ocorreram em sua vida.',
                app3Description: 'O aplicativo foi desenvolvido para ser um diário digital simples e fácil de usar, onde podem ser registradas experiências e acontecimentos importantes de uma pessoa. ',
            
                app4Title: 'PLANO DE VIDA',
                app4SubTitle: 'Elaborar plano de vida para alcançar o estilo de vida desejado.',
                app4Description: 'O aplicativo é uma ferramenta desenvolvida para ajudá-lo a criar um plano de vida. Um plano de vida é um documento de jornada pessoal que ajuda você a descrever suas  metas, prioridades, tomar decisões e avançar rumo ao estilo de vida que você idealiza.',
            
                app5Title: 'PASSWORD WALLET',
                app5SubTitle: '"Gerencie e Proteja Suas Senhas e Documentos em Um Só Lugar.',
                app5Description: 'Mantenha todos os seus documentos e senhas seguros com a Password Wallet, seu cofre pessoal de fácil acesso. Com uma única senha mestra, você pode armazenar e gerenciar de forma segura suas credenciais e documentos importantes. Ideal para quem busca praticidade e segurança, a Password Wallet é a solução perfeita para manter suas informações privadas ao alcance dos dedos, mas longe de olhares indiscretos. Experimente hoje mesmo e sinta a tranquilidade de ter sua vida digital bem protegida.',
            },

            menu: {
               about: 'Sobre',
               contact: 'Contato',
               application: 'Aplicativos',
               login: 'Login'
           },

           sections: {
                title: 'Desenvolvimento de software',
                subtitle: 'Transorme seus projetos em realidade',
                about: 'Desenvolvimento de softwares para web, celulares, tablets e outros dispositivos.',
                contact: 'Você tem alguma pergunta?',
                message: 'Deixe uma mensagem.',
                sent: 'Mensagem enviada.'
           },

           guide: {
               title: 'Casamentos e Divórcios', 
               description1: 'Os números apresentados quando falamos em casamentos ou divórcios são estatísticas matemáticas que em muitos casos não refletem a verdadeira realidade de uma cidade, estado ou nação. Esses números estão inválidos, sub ou super estimados, ultrapassados ou mesclados com outros valores que produzem resultados irrealistas. Contudo, mesmo com essas deficiências podemos utilizar esses valores estatísticos para termos uma noção do que pode estar ocorrendo nos relacionamentos humanos.',
               crudeRate: 'Taxa Bruta',
               description2: 'Define a frequência em que uma doença ou condição que ocorre em uma população em um determinado período de tempo.​',
               formula1: 'Taxa Bruta = (Quantidade ÷ População) x Constante',
               note: 'Constante é um múltiplo de 10 que define a quantidade de elementos do grupo.',
               crudeDivorceRate: 'Taxa Bruta do Divórcio',
               description3: 'É o número de divórcios para cada grupo de 1000 pessoas de uma população. Por exemplo,  se uma cidade tiver uma população de 10.000 habitantes e 50 casais que efetuaram o divórcio no período de um ano, teremos uma taxa bruta de divórcio de 5 divórcios para cada grupo de 1000 pessoas.',
               formula2: 'Taxa Bruta do Divórcio​ = (Quantidade de divórcios ÷ População) x 1000',
               description4: 'A taxa bruta de divórcio pode fornecer uma visão geral do casamento, mas mostra um falso valor porque utiliza as pessoas que não podem se casar em seu resultado. Por exemplo, crianças e solteiros fazem parte do cálculo. Em um lugar com um grande número de crianças ou adultos solteiros, a taxa bruta de divórcio pode parecer baixa. Em um lugar com poucas crianças e adultos solteiros, a taxa bruta de divórcio pode parecer alta.​',
               divorceRate: 'Taxa de Divórcio no Mundo',
               description5: 'Com base nos dados de 2020-2021 da Divisão de Estatísticas do Departamento de Assuntos Econômicos e Sociais das Nações Unidas, os países com as maiores taxas anuais de divórcio por 1.000 pessoas são os seguintes:',
               noteList: 'Os dados são os mais recentes disponíveis por país. 2018-China; 2019-Cuba; todos os outros 2020-21.',
               description6: 'Com base nos dados de 2017-2020, os países com as menores taxas anuais de divórcio são os seguintes:',
               numberDivorces: 'Número de Divórcios',
               numberWeddings: 'Número de Casamentos',
               divorceUSA: 'Divórcio nos EUA',
               description7: 'De acordo com o Centro Nacional de Estatísticas de Saúde dos Estados Unidos, aproximadamente 4-5 milhões de pessoas se casam todos os anos nos EUA e aproximadamente 42-53% desses casamentos acabam em divórcio. Veja os números atuais:',
               description8: 'O divórcio acontece em todo o mundo. As estatísticas obtidas nos Estados Unidos podem servir de base para entender como ele funciona em outros países.',
               divorceCause: 'Divórcio e suas causas',
               description9: 'As razões que levam as pessoas a ficarem juntas são bem conhecidas: o amor, o desejo de estar perto, o sonho de um futuro comum. Mas, afinal, o que leva os casais a buscarem a separação? Um estudo realizado pela Universidade de Denver revelou os principais motivos, descritos a seguir:',
               divorceCause01: 'Falta de compromisso - 75%',
               divorceCause02: 'Infidelidade ou casos extraconjugais - 59,6%​', 
               divorceCause03: 'Muito conflito e discussão - 57,7%',
               divorceCause04: 'Casar muito jovem - 45,1%',
               divorceCause05: 'Problemas financeiros - 36,1%​',
               divorceCause06: 'Abuso de substâncias - 34,6%',
               divorceCause07: 'Violência doméstica - 23,5%​',
               divorceCause08: 'Problemas de saúde - 18,2%',
               divorceCause09: 'Falta de apoio da família - 17,3%',
               divorceCause10: 'Pouca ou nenhuma educação pré-marital e diferenças religiosas – 13,3%',
               generalDivorce: 'Estatísticas Gerais de Divórcio',
               general01: '8 anos é a média para um primeiro casamento terminar em divórcio.​',
               general02: '20% dos casamentos terminam nos primeiros 5 anos.​',
               general03: '32% dos casamentos terminam nos primeiros 10 anos.',
               general04: '30 anos é a idade média das pessoas que se divorciam pela primeira vez.',
               general05: '69% das mulheres iniciam o divórcio. Esse número sobe para 90% para mulheres com diploma universitário.',
               general06: 'Mulheres que tiveram seis ou mais parceiros sexuais antes do casamento têm três vezes menos chances de ter um casamento estável.',
               general07: 'Assistir e falar sobre filmes de romance reduz as taxas de divórcio em 13%.',
               general08: 'Ser amigo íntimo de um divorciado aumenta a probabilidade de se divorciar em 147%.',
               general09: 'Ter fortes crenças religiosas diminui a probabilidade de divórcio em 14%.',
               general10: 'Ter um QI abaixo da média aumenta a probabilidade de divórcio em 50%.',
               general11: '67% dos americanos acreditam que o divórcio é melhor para as crianças do que testemunhar os problemas de um casamento conturbado.',
               general12: 'Pais bem casados ​​diminuem a probabilidade de divórcio de seus filhos em 14%.',
               general13: 'Nos EUA, pesquisas indicam que 22 milhões de pais são alvos de comportamentos de alienação parental e 3,8 milhões de crianças são alienadas por um dos cônjuges.',
               general14: 'Apenas dois países consideram o divórcio ilegal: as Filipinas e a Cidade do Vaticano.',
               menu1: 'SOBRE',
               menu2: 'RECURSOS',
               menu3: 'DOWNLOAD',
               menu4: 'CONTATO',
               menu5: 'EXTRA',
               menu6: 'AUTOR',
               menu7: 'ADQUIRIR',
               menu1Desc1: 'Esse aplicativo descreve os principais elementos de um divórcio e suas consequências.',
               menu1Desc2: 'Ele foi criado para ensinar, ajudar e alertar as pessoas que pretendem realizar um divórcio  ou que já estão passando por esse procedimento, mas não sabem o que esperar nem como agir nesse momento de suas vidas.',
               menu1Desc3: 'O divórcio representa o fim de uma união. ',
               menu1Desc4: 'Muitas pessoas acreditam que se trata apenas de um ato burocrático, onde seus desejos e direitos serão respeitados, com a liberdade sendo restaurada, produzindo uma vida mais feliz para todos, principalmente para seus solicitantes.',
               menu1Desc5: 'Isso acontece em filmes, livros e novelas, mas no mundo real não é assim. ',
               menu1Desc6: 'Divórcios amigáveis tendem a funcionar como esperado, mas quando há algum desentendimento entre os envolvidos, o cenário muda completamente e pode produzir consequências terríveis e inesperadas para todos.',
               menu1Desc7: 'O aplicativo fornece as informações necessárias para ajudar seus usuários a realizar os procedimentos que irão eliminar ou mitigar suas perdas, dores e sofrimentos provenientes de divórcios não consensuais.',
               menu2Desc: 'Projetado para ser simples, útil e de fácil utilização. As funções podem ser acessadas de forma rápida e intuitiva, dispensando qualquer tipo de manual ou instruções descritivas.',
               menu2Title1: 'Múltiplos idiomas',
               menu2Title1Desc: 'Para facilitar seu entendimento e sua utilização, o aplicativo funciona com diferentes idiomas que podem ser selecionados de acordo com a preferência do usuário.',
               menu2Title2: 'Guia',
               menu2Title2Desc: 'Descreve os principais elementos contidos em um processo de divórcio e suas respectivas variações e consequências.',
               menu2Title3: 'Estatísticas',
               menu2Title3Desc: 'Mostra as principais estatísticas relacionadas ao divórcio. Contador de divórcios e casamentos efetuados.',
               menu2Title4: 'Incidentes',
               menu2Title4Desc: 'Permite registrar os incidentes ocorridos durante e após o casamento com a emissão de relatório.',
               menu2Title5: 'Medidor',
               menu2Title5Desc: 'Permite medir as consequências do divórcio com base nos dados fornecidos.',

               menu2Title6Desc: 'O aplicativo fornece acesso a esse livro que poderá ser adquirido e lido diretamente no celular.',
               menu2Title6bDesc: 'O primeiro capítulo está disponível para avaliação.',
               menu2Title6cDesc: 'Clique na imagem acima para maiores informações.',
               menu3Desc: 'O aplicativo está disponível para dispositivos Android.',
               menu4Title: 'TEM ALGUMA PERGUNTA?',
               menu4Desc: 'Deixe-nos uma mensagem.',
               menu5Desc1: 'Segue abaixo outros aplicativos que possam ser de seu interesse.',
               bookDesc1: 'Você pode acessar o livro e utilizá-lo diretamente no seu celular, através do aplicativo abaixo:',
               bookDesc2: 'Se preferir, o livro também está disponível na AMAZON.',
               bookDesc3: 'NÃO ESPERE BOM SENSO NEM COMPAIXÃO',
               bookDesc4: 'VOCÊ TERÁ QUE GASTAR DINHEIRO PARA PROVAR QUE NÃO TEM DINHEIRO',
               bookDesc5: 'ATUALMENTE, OS HOMENS SÃO ANIQUILADOS NO DIVÓRCIO',
               bookDesc6: 'RARAMENTE OS HOMENS TEM DIREITO A GUARDA DOS FILHOS',
               bookDesc7: 'BAIXE O APLICATIVO E RECEBA UM SUPER DESCONTO',
            },  

            planning: {
                menu1: 'SOBRE',
                menu2: 'RECURSOS',
                menu3: 'DOWNLOAD',
                menu4: 'CONTATO',
                menu5: 'EXTRA',
                menu1Desc1: 'Aplicativo para planejar e acompanhar um processo de divórcio.',
                menu1Desc2: 'Foi desenvolvido com o objetivo de disponibilizar um software capaz de auxiliar seus usuários a planejar, realizar, mensurar e acompanhar um divórcio.',
                menu1Desc3: 'Usa alguns dos conceitos descritos no livro "A Arte da Guerra" em seu design.',
                menu1Desc4: 'Também utiliza o ciclo PDCA e metodologias de análise SWOT em sua estrutura operacional.',
                menu1Desc5: 'Planejar consiste em uma série de preparativos para realizar uma tarefa a fim de atingir um objetivo.',
                menu1Desc6: 'Muita gente não sabe ou não quer realizar nenhum tipo de planejamento.',
                menu1Desc7: 'Preferem acreditar que tudo acontecerá de forma rápida e harmoniosa com seus direitos sendo respeitados e suas demandas atendidas.',
                menu1Desc8: 'Infelizmente isso não acontecerá na maioria das vezes.',
                menu1Desc9: 'Contar com a sorte, com o bom senso do sistema judicial ou com a habilidade do advogado selecionado não é garantia de sucesso ou de final feliz.',
                menu1Desc10:'Todo divórcio deve ser planejado antes de sua execução, mesmo que haja consenso entre o casal.',
                menu1Desc11:'Ninguém sabe como os outros reagirão em situações de conflito ou separação.',
                menu1Desc12:'Pessoas que carregamos nos braços e nas quais investimos nossas vidas, podem, sem qualquer explicação, tornar-se inimigos terríveis e destrutivos, onde qualquer coisa pode ser motivo de confronto ou disputa.',
                menu1Desc13:'O divórcio representa o fim de um ciclo e o início de outro.',
                menu1Desc14:'O aplicativo não pode garantir nenhuma vitória, mas pode pelo menos reduzir suas perdas pessoais e financeiras para que uma fase de harmonia e felicidade tenha mais chances de ocorrer em sua nova vida.',
                
                menu2Desc: 'Projetado para ser simples, útil e fácil de usar. Os recursos disponíveis podem ajudar seus usuários a ter uma melhor compreensão do assunto apresentado.',
                menu2Title1: 'Múltiplos idiomas',
                menu2Title1Desc: 'Para facilitar seu entendimento e sua utilização, o aplicativo funciona com diferentes idiomas que podem ser selecionados de acordo com a preferência do usuário.',
                menu2Title2: 'Análise SWOT',
                menu2Title2Desc: 'Efetua análise SWOT de seus dados. É uma uma metodologia utilizada para identificar as forças, oportunidades, fraquezas e ameaças de um projeto específico para efetuar os ajustes necessários que possam garantir o sucesso do empreendimento. ',
                menu2Title3: 'Demonstrativo Financeiro',
                menu2Title3Desc: 'É possível verificar o resultado e custo do planejamento e sua execução.',
                menu2Title4: 'Cenário',
                menu2Title4Desc: 'Define o cenário do divórcio com base nos dados informados sobre o casamento.',
                menu2Title5: 'Segurança',
                menu2Title5Desc: 'Mantém seus dados e planejamento protegidos contra acesso não autorizado. Acesso via PIN ou impressão digital.',
                menu3Desc: 'O aplicativo está disponível para dispositivos Android.',
                menu4Title: 'TEM ALGUMA PERGUNTA?',
                menu4Desc: 'Deixe-nos uma mensagem.',
                menu5Desc: 'Segue abaixo outros aplicativos que possam ser de seu interesse.',
                menu5Desc1: 'GUIA DO DIVÓRCIO',
                menu5Desc2: 'O aplicativo foi desenvolvido para descrever os principais elementos de um divórcio e suas consequências.',
                
                menu1ExtraTitle1: 'Ciclo PDCA',
                menu1ExtraTitle2: 'Analise SWOT',
                menu1ExtraTitle3: 'A arte da guerra',
                menu1ExtraDesc1:  'A sigla PDCA significa em inglês: Plan, Do, Check, Act. É uma metodologia utilizada para o aprimoramento dos processos e para a solução de problemas. Seu objetivo é auxiliar a execução de uma estratégia estabelecida',
                menu1ExtraDesc2:  'É uma metodologia utilizada para identificar as forças, oportunidades, fraquezas e ameaças de um projeto específico para efetuar os ajustes necessários que possam garantir o sucesso do empreendimento.',
                menu1ExtraDesc3:  'É um tratado militar escrito durante o século IV a.C. pelo estrategista conhecido como "Sun Tzu". O tratado é composto por treze capítulos, cada qual abordando um aspecto da estratégia de guerra, de modo a compor um panorama de todos os eventos e estratégias que devem ser abordados em um combate racional.',
            },
            
            diary: {
                menu1: 'SOBRE',
                menu2: 'RECURSOS',
                menu3: 'DOWNLOAD',
                menu4: 'CONTATO',
                menu5: 'EXTRA',
                menu1Desc1: 'Diário pessoal para registrar sonhos, ideias e situações que ocorreram em sua vida.',
                menu1Desc2: 'O aplicativo foi desenvolvido para ser um diário digital simples e fácil de usar, onde podem ser registradas experiências e acontecimentos importantes de uma pessoa.',
                menu1Desc3: 'Além disso, esses registros podem ser enriquezidos com imagens, sons e videos.',
                menu1Desc4: 'As funções podem ser acessadas de forma rápida e intuitiva, dispensando qualquer tipo de manual ou instruções descritivas.',
                menu1Desc5: 'Você pode criar registros do que ocorreu em sua vida e das lições que aprendeu.',
                menu1Desc6: 'Quantas vezes você não gostaria de lembrar exatamente quando determinado fato ocorreu? Com um diário você terá para sempre esses registros a sua disposição.',
                menu1Desc7: 'Escrever um diário é um poderoso método para colocar em ordem seus pensamentos e sentimentos. Você poderá registra-los sem qualquer tipo de restrição e consultá-los quando precisar.',
                menu1Desc8: 'Escrever em um diário pessoal também estimula nossa criatividade. ',
                menu1Desc9: 'O próprio ato de escrever pode despertar inspiração, levando a novas perspectivas e soluções inovadoras. ',
                menu1Desc10: 'Um diário pessoal oferece um refúgio seguro para a autoexpressão. ',
                menu1Desc11: 'Ele permite articular livremente nossos pensamentos, medos, sonhos e desejos mais profundos, sem julgamento ou inibição',
                menu1Desc12: 'Os diários pessoais oferecem um espaço sagrado para a autorreflexão, a autoexpressão e o crescimento pessoal. ',
                menu1Desc13: 'Eles servem como um testemunho de nossa jornada única, proporcionando consolo, clareza e inspiração. ',
                menu1Desc14: 'Ao abraçar o poder dos diários pessoais, podemos desbloquear a verdadeira essência da autorreflexão e embarcar num caminho transformador em direção à autodescoberta e à realização.',
                menu1Desc15: 'Em resumo, este aplicativo é uma ferramenta que permite registrar a coisa mais importante que existe.',
                menu1Desc16: 'Sua vida.',
                menu2Desc: 'Projetado para ser simples, útil e de fácil utilização. As funções podem ser acessadas de forma rápida e intuitiva, dispensando qualquer tipo de manual ou instruções descritivas.',
                menu2Title1: 'Múltiplos idiomas',
                menu2Title1Desc: 'Para facilitar seu entendimento e sua utilização, o aplicativo funciona com diferentes idiomas que podem ser selecionados de acordo com a preferência do usuário.',
                menu2Title2: 'Diário',
                menu2Title2Desc: 'Registre os momentos e os acontecimentos mais importantes de sua vida para recordá-los no futuro. Adicione de forma simples e rápida qualquer anotação desejada com o título correspondente, descrição, data e hora de sua ocorrência.',
                menu2Title3: 'Anexos',
                menu2Title3Desc: 'Adicione anexos aos registros efetuados. Podem ser do tipo: imagen, som ou video. Também é possível colocar descrições em cada um deles que facilitem sua compreensão durante a etapa de apresentação.',
                menu2Title4: 'Pesquisar',
                menu2Title4Desc: 'É possível pesquisar e localizar os registros efetuados pelo usuário de forma simples e eficaz através de seu título ou data de ocorrência.',
                menu2Title5: 'Segurança',
                menu2Title5Desc: 'Mantém seus dados protegidos contra acesso não autorizado. Acesso via PIN ou impressão digital.',
                menu3Desc: 'O aplicativo está disponível para dispositivos Android.',
                menu4Title: 'TEM ALGUMA PERGUNTA?',
                menu4Desc: 'Deixe-nos uma mensagem.',
                menu5Desc: 'Segue abaixo outros aplicativos que possam ser de seu interesse.',
            },

            lifeplan: {
                menu1: 'SOBRE',
                menu2: 'RECURSOS',
                menu3: 'DOWNLOAD',
                menu4: 'CONTATO',
                menu5: 'EXTRA',

                menu1Desc:  'O aplicativo é uma ferramenta desenvolvida para ajudá-lo a criar um plano de vida.',
                menu1bDesc: 'Um plano de vida é um documento de jornada pessoal que ajuda você a descrever suas  metas, prioridades, tomar decisões e avançar rumo ao estilo de vida que você idealiza.', 
                menu1cDesc: 'Ele serve como um documento orientador de navegação pela vida, fornecendo clareza, direção e propósito.',
                menu1dDesc: 'Ele ajuda identificar as suas paixões, valores e prioridades, permitindo concentrar o seu tempo, energia e recursos naquilo que realmente importa.',
                menu1eDesc: 'Um plano de vida permite que você estabeleca metas significativas e alcançáveis. Quer se trate de decidir um plano de carreira, prosseguir os estudos ou fazer escolhas de estilo de vida, ele irá ajudá-lo a tomar decisões que contribuem para o seu bem-estar e realização geral.',
                menu1fDesc: 'Um plano de vida lembra os indivíduos de seu propósito e os motiva a perseverar em tempos desafiadores.',
                menu1gDesc: 'Ele incentiva as pessoas a se avaliarem e melhorarem continuamente.',
                menu1hDesc: 'Promove a reflexão sobre os pontos fortes, fracos e áreas de crescimento.',
                menu1iDesc: 'Incentiva os indivíduos a priorizar o autocuidado, os relacionamentos e as atividades de lazer juntamente com seus objetivos profissionais e financeiros.', 
                menu1jDesc: 'Em resumo, um plano de vida serve como uma ferramenta poderosa para as pessoas definirem o seu propósito, estabelecerem metas e navegarem pela vida com intenção e paixão.',
                menu2Desc:  'Projetado para ser simples, útil e de fácil utilização. As funções podem ser acessadas de forma rápida e intuitiva, dispensando qualquer tipo de manual ou instruções descritivas.',
                menu2Title1: 'Metas',
                menu2Title1Desc: 'Permite descrever e relacionar os objetivos a serem alcançados e separá-los por categoria.',
                menu2Title2: 'Tarefas',
                menu2Title2Desc: 'Permite descrever e relacionar as tarefas que devem ser realizadas para que os objetivos sejam alcançados.',
                menu2Title3: 'Quadro de Desejos',
                menu2Title3Desc: 'Permite elaborar um quadro de desejos para visualização e estimulção do inconsciente.',
                menu2Title4: 'Motivacional',
                menu2Title4Desc: 'Disponibiliza frases motivacionais que inspiram os usuários a enfrentarem com mais ânimo as adversidades da vida e alcançarem as suas metas e objetivos.',
                menu2Title5: 'Segurança',
                menu2Title5Desc: 'Mantém os dados protegidos contra acesso não autorizado. Acesso via PIN ou impressão digital.',
                menu3Desc: 'O aplicativo está disponível para dispositivos Android.',
                menu4Title: 'TEM ALGUMA PERGUNTA?',
                menu4Desc: 'Deixe-nos uma mensagem.',
                menu5Desc: 'Segue abaixo outros aplicativos que possam ser de seu interesse.',
            },

            passwordwallet: {
                menu1: 'SOBRE',
                menu2: 'RECURSOS',
                menu3: 'DOWNLOAD',
                menu4: 'CONTATO',
                menu5: 'EXTRA',

                menu1Desc: 'Password Wallet é um aplicativo essencial para quem busca segurança e organização em um só lugar. Desenvolvido para facilitar o gerenciamento de suas informações mais sensíveis, este app armazena de forma segura documentos, senhas e dados confidenciais, utilizando uma única senha mestra. Através de uma interface intuitiva e um design elegante, você pode acessar rapidamente tudo o que precisa sem sacrificar a segurança.',
                menu1bDesc: 'Com esse aplicativo, esquecer uma senha ou perder documentos importantes são preocupações do passado. O aplicativo utiliza criptografia avançada para garantir que suas informações estejam protegidas contra acessos não autorizados, enquanto oferece uma experiência de usuário fluída e agradável. Além disso, é possível categorizar suas informações em pastas personalizadas, facilitando a organização e o acesso rápido quando necessário.',
                menu1cDesc: 'Ideal para profissionais que necessitam manter uma vasta quantidade de dados ao alcance das mãos, ou para qualquer pessoa que valorize a segurança digital, Password Wallet é mais que um simples gestor de senhas. Ele é um cofre digital que acompanha você em todos os momentos, garantindo paz de espírito e maior produtividade. Descomplique sua vida digital com o Password Wallet, seu aliado confiável no mundo digital.',
                menu2Desc:  'Projetado para ser simples, útil e de fácil utilização. As funções podem ser acessadas de forma rápida e intuitiva, dispensando qualquer tipo de manual ou instruções descritivas.',
                menu2Title1: 'Apresentação',
                menu2Title1Desc: 'Todos os seus dados são organizados e exibidos de maneira clara e acessível. Cada entrada é representada por um cartão de dados visualmente distinto, que inclui um título e uma categoria específica. Essa abordagem permite que os usuários visualizem rapidamente a informação relevante, facilitando a identificação e o acesso aos dados necessários com eficiência e estilo.',
                menu2Title2: 'Visualização',
                menu2Title2Desc: 'Com uma interface intuitiva, o App possibilita que você expanda cada registro com um simples toque. Ao selecionar um cartão de dados, seu conteúdo completo é exibido, incluindo detalhes adicionais armazenados. Essa funcionalidade é essencial para um gerenciamento eficiente de suas informações, permitindo editar, atualizar ou excluir dados conforme necessário.',
                menu2Title3: 'Anexos',
                menu2Title3Desc: 'A funcionalidade de anexos no App enriquece a gestão de suas informações. Você pode facilmente adicionar imagens a qualquer registro. Cada anexo pode ser acompanhado de uma descrição detalhada, proporcionando contexto adicional e garantindo que todos os aspectos relevantes de suas informações estejam acessíveis e bem organizados.',
                menu2Title4: 'Organização',
                menu2Title4Desc: 'Organize seus registros de forma mais eficiente com a funcionalidade de categorização. Você pode criar categorias personalizadas para classificar seus dados, facilitando a organização e a recuperação rápida de informações específicas. A busca por categorias permite filtrar e encontrar rapidamente os registros necessários, otimizando seu fluxo de trabalho e aumentando sua produtividade.',
                menu2Title5: 'Segurança',
                menu2Title5Desc: 'O aplicativo é protegido por uma senha pessoal, garantindo que apenas você tenha acesso às suas informações confidenciais. Essa senha mestra é a chave para desbloquear todos os seus dados, que são criptografados com tecnologia de ponta para fornecer proteção adicional contra acessos não autorizados. Acesso via PIN ou impressão digital.',
                menu3Desc: 'O aplicativo está disponível para dispositivos Android.',
                menu4Title: 'TEM ALGUMA PERGUNTA?',
                menu4Desc: 'Deixe-nos uma mensagem.',
                menu5Desc: 'Segue abaixo outros aplicativos que possam ser de seu interesse.',
            },

           msgs: {
               code1:  'E-MAIL INVÁLIDO',
               code2:  'PASSWORD INVÁLIDA',
               code3:  'E-MAIL NÃO EXISTE',            
               code4:  'NOME INVÁLIDO',  
               code5:  'DESCRIÇÃO INVÁLIDA',  
               code22: 'DUPLICATE NAME IS NOT ALLOWED', 
               code27: 'THERE ARE NO PREVIOUS PAGES', 
               code28: 'THERE ARE NO PAGES', 
               code29: 'NO SEARCH FOUND',  
               code99: 'ERROR UNDEFINED'  
           }
        }
    }
}

export { messages };