import React        from 'react';
import msg          from '../Utilities/Message';
 
 class Validate extends React.Component {
    
    async ValidateName( formName ) {

        try {    

            if ( !formName )
                return msg.getMessage(1);

            var name = formName.trim();

            if ( name.length === 0 )
                return msg.getMessage(1);
 
            if (this.isValidEmail(formName) === false)   
                return msg.getMessage(1); 

            if ( name !== 'kodasware@kodasware.com' )
                return msg.getMessage(3);

            return null;   

        } catch (error) {
            
            console.log('...ERROR UNDEFINED=[01 ' + error + ']');
            return msg.getMessage(99);
        }
    }

    isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    async validatePassword( FormPswd ) {

        try {    

            if ( !FormPswd )   
               return msg.getMessage(2);

            var name = FormPswd.trim();

            if ( name.length === 0 )
               return msg.getMessage(2);

            if ( name !== 'kendo' )
               return msg.getMessage(2);

            return null;   

        } catch (error) {

            console.log('...ERROR UNDEFINED=[02 ' + error + ']');
            return msg.getMessage(99);
        }
    }

    async validateFormName( formName ) {

        try {    

            if ( !formName )
                return msg.getMessage(4);

            var name = formName.trim();

            if ( name.length === 0 )
                return msg.getMessage(4);
 
            return null;   

        } catch (error) {
            
            console.log('...ERROR UNDEFINED=[03 ' + error + ']');
            return msg.getMessage(99);
        }
    }

    async validateFormMail( formMail ) {

        try {    

            if ( !formMail )
                return msg.getMessage(1);

            var mail = formMail.trim();

            if ( mail.length === 0 )
                return msg.getMessage(1);
 
            if (this.isValidEmail(mail) === false)   
                return msg.getMessage(1); 

            return null;   

        } catch (error) {
            
            console.log('...ERROR UNDEFINED=[04 ' + error + ']');
            return msg.getMessage(99);
        }
    }

    async validateFormDescription( formDesc ) {

        try {    

            if ( !formDesc )
                return msg.getMessage(5);

            var desc = formDesc.trim();

            if ( desc.length === 0 )
                return msg.getMessage(5);
 
            return null;   

        } catch (error) {
            
            console.log('...ERROR UNDEFINED=[05 ' + error + ']');
            return msg.getMessage(99);
        }
    }
}

export default new Validate();