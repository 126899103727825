import React        from 'react';
//import msg          from '../Utilities/Message';
 
 class Signon extends React.Component {
    
    /*
    async ValidateName( formName ) {

        try {    

            if ( !formName )
                return msg.getMessage(1);

            var name = formName.trim();

            if ( name.length === 0 )
                return msg.getMessage(1);
 
            if (this.isValidEmail(formName) === false)   
                return msg.getMessage(1); 

            if ( name !== 'kodasware@kodasware.com' )
                return msg.getMessage(3);

            return null;   

        } catch (error) {
            
            console.log('...ERROR (Signon)=[01 ' + error + ']');
            return msg.getMessage(99);
        }
    }

    isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    async validatePassword( FormPswd ) {

        try {    

            if ( !FormPswd )   
               return msg.getMessage(2);

            var name = FormPswd.trim();

            if ( name.length === 0 )
               return msg.getMessage(2);

            if ( name !== 'kendo' )
               return msg.getMessage(2);

            return null;   

        } catch (error) {

            console.log('...ERROR (Signon)=[02 ' + error + ']');
            return msg.getMessage(99);
        }
    }
    */
}

export default new Signon();