import React    from 'react';
import Polite   from '../../global/globalPrivacy';
import * as GV  from '../../global/globalVariables';

function PrivacyTest() {
    
    return (
        <>
        <Polite name={GV.APP_SEILA}></Polite>
        </>
    )
}

export default PrivacyTest;