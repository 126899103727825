import React             from 'react';   
import * as S            from './styles';
import * as G            from '../../global/globalStyles';
import Navbar            from '../../components/Navbar';
import Trailer           from '../../components/Trailer';
import { i18n }          from '../../translate/i18n';
import Mail              from '../../components/Mail';
import Form              from '../../components/Form';
  
function Contact() {
    
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
      
    return (
        <S.Container>

            <Navbar></Navbar>

            <S.HeaderParallax>{i18n.t('titles.contact')}</S.HeaderParallax>

            <S.ParallaxContainer>

                <S.ParallaxPart1>
                    <Mail></Mail>
                </S.ParallaxPart1>

                <S.ParallaxPart2>
                    <Form></Form>
                </S.ParallaxPart2>

            </S.ParallaxContainer>

            <G.ContainerSpace></G.ContainerSpace>
            
            <Trailer></Trailer>

        </S.Container>
    )
}

export default Contact;