import styled from 'styled-components';


export const DropDownListContainer = styled.div`

    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    background: #FFF;
    border: 2px solid #e5e5e5;
    box-sizing: border-box;
    padding: 10px;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 50px;

`    
