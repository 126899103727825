import React, { useState, useEffect } from 'react';   
import * as S       from './styles';
import guide11      from '../../../../view/DivorceGuide/Guide/Images/guide11.png';
import guide12      from '../../../../view/DivorceGuide/Guide/Images/guide12.png';
import guide13      from '../../../../view/DivorceGuide/Guide/Images/guide13.png';
import guide14      from '../../../../view/DivorceGuide/Guide/Images/guide14.png';
import guide15      from '../../../../view/DivorceGuide/Guide/Images/guide15.png';
import guide16      from '../../../../view/DivorceGuide/Guide/Images/guide16.png';
import guide17      from '../../../../view/DivorceGuide/Guide/Images/guide17.png';
import guide18      from '../../../../view/DivorceGuide/Guide/Images/guide18.png';
import guide19      from '../../../../view/DivorceGuide/Guide/Images/guide19.png';
import guide20      from '../../../../view/DivorceGuide/Guide/Images/guide20.png';
import util         from '../../../../Utilities/Util';

function GuidePart3() {

    const [access, setAccess] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)
        setAccess( util.checkGuideAccess() );
    }, [])

    return (
        <S.Container>
        { ( access )
            ?
            <>
            <S.Form> 

            <S.FormTitle>
				<span>PARTE 3</span><br></br><br></br><br></br><span>Componentes de la justicia</span><br></br><br></br>            
			</S.FormTitle>

            <S.FormText>
                <span>

				La justicia es un concepto abstracto. Es un ideal a alcanzar, algo inmaterial que no se puede ver ni tocar. Lo que existe son instituciones que tratan de dar sustancia a este concepto en el mundo real. <br></br><br></br>
                Por eso, se desarrollan varios componentes, reglas y procedimientos para hacerlo visible y su uso posible para todos. Se agregarán personas a este grupo para controlar y administrar su funcionamiento. Se crearán y distribuirán entre ellos jerarquías, funciones y títulos. <br></br><br></br>
                En definitiva, será un sistema instaurado con el objetivo de intentar reproducir y aplicar en la realidad la justicia soñada y idealizada por la sociedad.<br></br><br></br>
                <b><i> Sin embargo, es solo un intento humano de materializarlo.</i></b><br></br><br></br>
                Ningún Estado, persona o máquina tiene la capacidad de realizar y reproducir la justicia en su esencia. Siempre habrá fallas y distorsiones. Incluyendo la producción de su antónimo <b>a injusticia</b>. <br></br><br></br>
                Además, estas instituciones están controladas por personas. Ellos, a su vez, están movidos por intereses personales, muchas veces distintos del objetivo principal para el que fue creado este sistema y de lo que la sociedad quiere y necesita. <br></br><br></br>
                Con el tiempo, este sistema podría convertirse en algo totalmente diferente al original, tanto en principios como en objetivos. Algo dotado de un gran poder sobre la vida y el destino de las personas, pero que nunca puede ser cuestionado o controlado por ellas. Únicamente por grupos restringidos y desconocidos de personas o instituciones directa o indirectamente vinculadas a ella. <br></br><br></br>
                También puede crecer demasiado, requiriendo grandes recursos financieros para su funcionamiento. Produciendo retrasos y distorsiones en sus resultados. Puede convertirse en un sistema defectuoso, corrupto y que no funcione. Será el único lugar donde las personas podrán buscar compensación y protección de sus derechos. <br></br><br></br> Por eso, es fundamental conocer algunos de sus componentes para saber actuar ante situaciones y eventos generados por este sistema.
                
                <br></br><br></br><br></br><br></br>
                <h2>Derecho Civil</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide11} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                El derecho es el mecanismo creado por la sociedad humana que estudia y aplica las leyes para que se alcance y implemente la justicia en un determinado sector. Es el mecanismo que regula los derechos y obligaciones de carácter privado respecto de las personas, sus derechos y obligaciones, los bienes y sus relaciones con los demás individuos de la sociedad. <br></br><br></br>
                El divorcio es un procedimiento regulado por el derecho civil de cada país, el cual puede variar según la región geográfica, la cultura y la moral de la sociedad que lo definió. 
     
                <br></br><br></br><br></br><br></br>
                <h2>Cónyuges</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide12} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                Son las personas que se encuentran en una relación matrimonial acordada y ratificada por los tribunales. Este acuerdo implica derechos y deberes para ambos que están regulados y definidos por el Código Civil de cada país.<br></br><br></br>
                Para que estos derechos y deberes recíprocos dejen de existir, es necesario que un proceso de divorcio sea aprobado por el tribunal.<br></br><br></br>
                Cuando en la relación matrimonial hay hijos menores o incapaces, los derechos y deberes para con ellos subsisten hasta la mayoría de edad o sentencia judicial. 

                <br></br><br></br><br></br><br></br>
                <h2>Familia</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide13} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

				Es una agrupación humana específica compuesta por antepasados ​​comunes o personas unidas por lazos afectivos que normalmente conviven y trabajan en beneficio propio. <br></br><br></br>
                <i><b>La familia es la unidad básica de la sociedad</b></i>. <br></br><br></br>
                Es a través de ella que los individuos adquieren su estructura psíquica y desarrollan su carácter. Generalmente formado por una pareja heterosexual y sus hijos. <br></br><br></br>
                Actualmente, este concepto se está modificando y las familias se están agrupando en diferentes modalidades, a saber:<br></br><br></br>
                
                <table border="1">
                <tbody>
                <tr><td>&nbsp;<b>Matrimonial</b>&nbsp;</td><td>&nbsp;Familia formada por matrimonio, tanto entre parejas heterosexuales como del mismo sexo.&nbsp;</td></tr >
                <tr><td>&nbsp;<b>Informal</b>&nbsp;</td><td>&nbsp;Familia formada por unión estable, tanto entre parejas heterosexuales como homoafectivas.&nbsp;</td></tr >
                <tr><td>&nbsp;<b>Monoparental</b>&nbsp;</td><td>&nbsp;Familia formada por un solo padre y sus descendientes.&nbsp;</td></tr>
                <tr><td>&nbsp;<b>Anaparental</b>&nbsp;</td><td>&nbsp;Familia formada únicamente por hermanos sin padres.&nbsp;</td></tr>
                <tr><td>&nbsp;<b>Soltera</b>&nbsp;</td><td>&nbsp;Familia formada por una sola persona.&nbsp;</td></tr>
                <tr><td>&nbsp;<b>Homoafectivo</b>&nbsp;</td><td>&nbsp;Familia formada únicamente por personas del mismo sexo.&nbsp;</td></tr>
                <tr><td>&nbsp;<b>Mosaico</b>&nbsp;</td><td>&nbsp;Familia formada por la unión de familias de padres separados.&nbsp;</td></tr>
                <tr><td>&nbsp;<b>Paralelo</b>&nbsp;</td><td>&nbsp;Personas que tienen más de una relación estable.&nbsp;</td></tr>
                <tr><td>&nbsp;<b>Eudemonist</b>&nbsp;</td><td>&nbsp;Familia formada por personas socio-afectivas.&nbsp;</td></tr>
                </tbody>
                </table>

                <br></br><br></br><br></br><br></br>
                <h2>Hijos</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide14} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                Es la designación que se da a los descendientes de una pareja. Representan la continuidad de sus padres y individuos en una sociedad. <br></br><br></br>
                <i><b>Los hijos son los componentes principales y más importantes de una familia</b></i>. <br></br><br></br>
                La familia proporciona el entorno y toda la estructura necesaria para el desarrollo físico y psíquico de los hijos de la pareja. Estos factores serán los responsables del desarrollo emocional positivo y seguro de los niños, para que aprendan a enfrentarse a las diferentes situaciones de la vida. <br></br><br></br>
                Por eso es importante crecer en un hogar donde ambos padres estén presentes y ofrezcan apoyo incondicional, consuelo y protección. Pero cuando una familia se desgarra, son ellos los que más sufren. <br></br><br></br>
                Incluso a nivel inconsciente, sienten que han perdido la protección familiar que tenían, aunque se cree una nueva y mejor estructura. Muchos hijos de padres separados crecerán felices y sin problemas aparentes, pero todos desarrollarán algún tipo de secuela emocional, como inseguridad, ira, depresión, que afectará su vida futura. <br></br><br></br>
                En un mundo injusto y competitivo, estarán en desventaja frente a sus competidores, quienes cuentan con estructuras emocionales más sólidas provenientes de una crianza familiar consistente. <br></br><br></br>
                <b><u>LOS HOMBRES SON LOS MÁS AFECTADOS EN ESTE PROCESO Y MUY POCO TIENE DERECHO A COMPARTIR O CUSTODIA DE SUS HIJOS</u></b>. <br></br><br></br>
                Cuando el padre está ausente o se ve impedido de ejercer su rol de padre, los hijos suelen ser portadores de algún tipo de trastorno emocional que afectará de alguna manera su futuro. <br></br><br></br>
                Esto sucede porque <b><u>EL PADRE TIENE UN PAPEL FUNDAMENTAL EN LA FORMACIÓN DEL CARÁCTER DE LOS HIJOS</u></b>.

                <br></br><br></br><br></br><br></br>
                <h2>Abogado</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide15} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

				A diferencia de los libros y las películas, no podemos ejercer la legítima defensa, es obligatorio utilizar un abogado. <br></br><br></br>
                Cualquiera que sea el tipo de divorcio, amistoso o litigioso, la presencia de un abogado es obligatoria. <br></br><br></br>
                Los divorcios amistosos tienden a ser rápidos y baratos, sin embargo, cuando hay algún tipo de desacuerdo, especialmente si hay niños involucrados, todo el panorama se vuelve aún más incierto. En este caso, el defensor elegido se convierte en un jugador clave en esta disputa.<br></br><br></br>
                <b><i> Es el elemento principal para producir la victoria o la derrota</i></b>. <br></br><br></br>
                Para que haya ganancia o la menor pérdida posible, es necesario seleccionar un profesional altamente calificado.<br></br><br></br> Desafortunadamente, son difíciles de localizar y costosos. <br></br><br></br>
                Encontrar un abogado a través de alguna indicación es una forma de seleccionar a alguien calificado. Sin embargo, se debe tener mucho cuidado en esta operación. Es necesario verificar si el profesional indicado está especializado en justicia de familia y si tiene un índice de ganancia alto o al menos razonable. <br></br><br></br>
                Es fundamental rechazar a los abogados inexpertos, baratos y/o polifacéticos. Los que hacen todo se especializan en nada. <br></br><br></br>Nadie debe poner el destino de su vida y procesos en manos de cualquier profesional que se presente. Especialmente cuando se desconoce su competencia y desempeño. <br></br><br></br>
                Es fundamental rechazar a los familiares que realicen esta actividad, para evitar problemas familiares o vergüenza al momento de desecharlos. <br></br><br></br>
                Tenga en cuenta que ninguno de ellos sufrirá consecuencia alguna por las acciones realizadas y siempre serán remunerados, independientemente de los resultados obtenidos.<br></br><br></br>
                <i><b>Nunca, bajo ningún concepto, busque un abogado para que le asesore en la decisión de llevar a cabo o no un divorcio.</b></i>

                <br></br><br></br>
                Busque un profesional cualificado, <u><b>SOLO</b></u> cuando este trámite ya se haya decidido y no haya vuelta.

                <br></br><br></br><br></br><br></br>
                <h2>Juez de derecho</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide16} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                Es la persona seleccionada por el Estado para mediar y resolver el conflicto existente entre las partes. <br></br><br></br>
                Escuchará a los implicados ya sus testigos, así como estudiará y evaluará los documentos presentados con imparcialidad y de conformidad con las normas y leyes vigentes. Para llevar a cabo esta tarea, tiene poderes administrativos y judiciales. Los administrativos regulan el desarrollo del proceso, tales como: expulsar a cualquier persona de la sala, ordenar la detención de testigos, etc.
                Los judiciales regulan el desarrollo del proceso, tales como: solicitud de documentos y aclaraciones, nombramiento de peritos, emisión de órdenes, emisión de veredictos, etc. <br></br><br></br>
                El juez debe practicar siempre la igualdad de trato de las partes y en la medida de lo posible resolver el problema lo más rápido posible. También debe exigir el cumplimiento de sus órdenes y evitar actos procesales innecesarios.<br></br><br></br>
                Todo es muy hermoso en el papel y en la teoría. Sin embargo, en la práctica todo es diferente. Hace mucho que no existe la igualdad en la justicia. Las leyes son severas y destructivas sólo para un lado y suaves y ventajosas para el otro. <br></br><br></br>
                Hay jueces excepcionales y perspicaces, pero corresponden a una minoría restringida. Lo que predomina son profesionales sin apatía que viven en un universo paralelo, más allá de la realidad que nos rodea. Sus decisiones están modeladas, son irracionales y están respaldadas por leyes injustas y destructivas.
                No hay piedad ni humanidad en ellos.<br></br><br></br> <b>Nunca sufren las consecuencias de sus decisiones</b>.<br></br><br></br>
                <br></br><br></br>                
				
				<b>Rotación de Jueces</b><br></br><br></br>
                Los casos siguen cambiando de juez todo el tiempo. Todo proceso debe ser juzgado por la misma persona hasta su conclusión, pero en la práctica esto no sucede. <br></br><br></br>
                La Justicia considera que los documentos presentados y todo lo que consta en el expediente es suficiente para la toma de decisiones, que puede realizar cualquier persona seleccionada. Esto puede ser beneficioso al cambiar a un profesional mejor calificado. Sin embargo, también podría ocurrir lo contrario. <br></br><br></br>
                El tiempo de finalización y la calidad del resultado se verán muy afectados por esta rotación. El nuevo juez tendrá que estudiar y evaluar el caso nuevamente, puede requerir más aclaraciones u otras acciones, lo que puede requerir tiempo y dinero. <br></br><br></br>
                Incluso puede modificar decretos que ya se han establecido. Se borrará cualquier empatía que pueda haber ocurrido en una audiencia en persona. Como no sabrá las partes, cualquier palabra mal escrita o mal interpretada podría ser responsable de conclusiones erróneas. <br></br><br></br>
                Sin mencionar que es un trabajo duro y agotador y nadie lo hará por voluntad propia. Por lo tanto, es más probable que las decisiones sean estandarizadas. <br></br><br></br>
                El defensor tiene la posibilidad de solicitar en el cuerpo de la causa que sea juzgado por un juez determinado, por razones de afinidad con los datos de la causa. <br></br><br></br>
                Sin embargo, no lo hacen porque, generalmente, el tribunal no cumple con esta solicitud o ellos mismos no quieren estar en desacuerdo con el juez seleccionado para el caso. Depende de usted exigir esta acción de su defensa si cree que el juez inicial estuvo a su favor o demostró ser justo y competente.

                <br></br><br></br><br></br><br></br>                
				<h2>Tiempo</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide17} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                El tiempo es abstracto para nosotros. Es algo que no podemos ver ni manipular, solo podemos sentir sus efectos. A través de esta percepción, podemos crear mecanismos y medidas para establecer la duración de un determinado evento. Y en base a los valores obtenidos determinar si son aceptables o nocivos. <br></br><br></br>
                Cuando hablamos de justicia, los valores medidos son simplemente inaceptables. <br></br><br></br>
                El tiempo que necesita para completar una demanda es muy alto. 
                Las consecuencias de toda esta lentitud del poder judicial es la producción de enormes pérdidas personales y económicas.<br></br><br></br>
                Cuando no hay justicia, no hay castigo. Así, quedan abiertas las puertas para nuevas violaciones y impunidad, generando nuevos procesos, que impactarán aún más el tiempo de la justicia, produciendo un círculo infinito de frustraciones, dejando vidas atadas y indefinidas. <br></br><br></br>
                Cuando finalmente llegan las decisiones, o son inocuas o insuficientes para reparar el daño y el dolor causado. Las víctimas serán las más perjudicadas en este sistema y los infractores los mayores beneficiados. <br></br><br></br>
                Es una inversión total de los valores y los fines para los que se creó la justicia. 

                <br></br><br></br><br></br><br></br>
                <h2>Tarifas y costos</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide18} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

				<i><b>El dinero es la materia prima de la justicia.</b></i><br></br><br></br>
                No es posible realizar ningún procedimiento sin él. Se obtendrá de las partes en litigio, mediante el cobro de honorarios, costas, multas, tasas, emolumentos o cualquier otra denominación. <br></br><br></br>
                Estas tasas son elaboradas por la propia justicia y varían según la región y el órgano jurisdiccional encargado de su creación y recaudación. <br></br><br></br>
                Generalmente, se encapsulan con medidas de protección que los mantienen siempre por encima de las tasas de inflación o cualquier pérdida financiera.
                No existe una estandarización de los sistemas de cobro de estas tasas procesales. Cada escenario cambia su denominación y valor requerido. <br></br><br></br>
                Son creados por leyes específicas para hacerlos legales y aptos para su uso. Están dotados de diversos mecanismos de cobro y sanción que tienen como objetivo evitar su evasión y hacer más eficiente su cobro. <br></br><br></br>
                Estos cargos nunca caducan y siempre están sujetos a multas, intereses y diferentes tipos de corrección. Donde pequeñas cantidades pueden convertirse en números del orden de miles o millones en pocos meses o años, superando cualquier aplicación financiera existente en el planeta. <br></br><br></br>
                <i><b>En resumen, la justicia usa su propia moneda, crea los cargos que necesita a través de leyes exclusivas.</b></i>

                <br></br><br></br><br></br><br></br>
                <h2>Leyes</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide19} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>
                La vida en sociedad necesita reglas de conducta que disciplinen las interacciones entre las personas con el fin de lograr un bien común. <br></br><br></br>
                Es función del Estado regular a los individuos de una sociedad a través de un conjunto de normas escritas y desarrolladas para este fin. <br></br><br></br>
                Llamamos a este conjunto <u><b>LEY</b></u>. <br></br><br></br>
                Pueden variar según el período histórico, la región geográfica y las costumbres de una sociedad. Deben ser administrados por el Estado, el cual debe procurar que todos los miembros a los que representa sean <u>iguales en derechos y obligaciones</u>.
                Así, la justicia puede definirse como el cumplimiento de las leyes de una sociedad.<br></br><br></br>
                Sin embargo, en la práctica, la realidad es bastante diferente. Muchas leyes están desactualizadas y no satisfacen las necesidades actuales. Muchas leyes son <b>ilógicos, irracionales y injustos</b>, provocando incalculables daños materiales y personales. <br></br><br></br>
                No se mide el costo que tiene una ley para la sociedad. Una nueva ley tarda al menos diez años en llegar a un punto de pacificación y interpretación en los tribunales, pero eso no se considera. <br></br><br></br>
                Las leyes son creadas y implementadas sin que su costo o impacto estén debidamente calculados. Una ley puede estar en desacuerdo con otra, generando desgaste financiero y interpretaciones erróneas. <br></br><br></br>
                Las leyes deberían ser simples y igualitarias, pero actualmente se producen sólo para favorecer a las minorías con altos costos para su funcionamiento. Están siendo personalizados para atender y favorecer a grupos específicos.
                Sus creadores son inmunes a los efectos y consecuencias de estas leyes porque se encuentran en una situación política y social privilegiada. <br></br><br></br>
                Gastamos miles de millones al año solo para mantener un sistema que no funciona y no produce resultados satisfactorios. <br></br><br></br>
                Dinero que podría ser utilizado en beneficio de la sociedad en su conjunto. Produciendo resultados verdaderamente satisfactorios, mejorando las condiciones de vida, generando más oportunidades, construyendo un ambiente más justo, pacífico y cordial, reduciendo significativamente las cifras de violencia y delincuencia. <br></br><br></br>
                Incluso a través de números incontestables y evidencias de que esta gigantesca estructura legal no funciona y que probablemente nunca funcionará, preferimos callar y creer que solo es cuestión de ajuste, de endurecer las leyes con mayores penas o de crear nuevas leyes <br></br><br></br><br></br>
                <i><b>Actualmente, las leyes incorporadas al sistema judicial son sexistas y no obedecen a los principios de igualdad y presunción de inocencia, básicos para la creación de justicia en cualquier Estado Democrático de Derecho.</b></i>

                <br></br><br></br><br></br><br></br>
                <h2>Ceremonial</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide20} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

				La justicia tiene su propio lenguaje de comunicación y comportamiento. <br></br><br></br>
                Hay rituales y actitudes que deben ser utilizados entre sus componentes. Es fundamental conocerlos para saber comportarse y actuar frente a estos elementos. <br></br><br></br>
                Algunos casos requieren que las partes comparezcan ante el juez, mientras que otros no. Todos los procesos deben ser presenciales. Es muy cómodo legislar sin la presencia de las partes, no dejarse afectar por sus miradas o apelaciones, ni observar sus reacciones ante las sentencias, sean o no injustas. <br></br><br></br>
                La mayoría de los casos del Tribunal de Familia se componen únicamente de lenguaje escrito que se intercambia entre jueces y abogados. La verdad no importa y no se obtiene a base de testimonios o reacciones cara a cara. <br></br><br></br>
                Sobre el papel todo el mundo puede ser víctima o villano, todo dependerá de lo que se escriba y de la imaginación de quien lo escriba o lea.
                Por lo general, los personajes de estas historias ya se conocen, así como su trama y final. Pero los primeros capítulos del mismo se realizan con la presencia de las partes y sus representantes. <br></br><br></br>
                Desafortunadamente, la justicia nivela a sus profesionales hacia abajo. La mayoría de ellos no necesitan saber hablar, ni tener una elocuencia que pueda cambiar el destino de sus clientes.<br></br><br></br>
                Por lo general, no dicen nada, solo hacen algunas preguntas o responden algunas preguntas estándar. No los defenderán a toda costa, ni disentirán del juez. <br></br><br></br>
                Será una ejecución silenciosa, ya que no pueden hablar las partes, sólo sus defensores, quienes, como ya se ha dicho, no dicen nada. <br></br><br></br>
                Procedimientos a seguir:
                <br></br><br></br>

				<table border="1">
                <tbody>
                <tr><td bgcolor='#EAEAEA' ><b>Puntualidad&nbsp;&nbsp;&nbsp;</b></td><td>Consulte con el abogado el lugar y la hora de la audiencia. <br></br>Solicite por correo electrónico que se documente esta información. <br></br>Llegue al lugar de la audiencia con antelación, a ser posible una hora antes.</td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>Material</b></td><td>Será registrado en la entrada del foro, donde tendrá lugar la audiencia. <br></br>No llevar ningún tipo de arma, como: cuchillo, cortaplumas, tijeras, etc. <br></br>No tome nada que pueda comprometerlo de alguna manera.</td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>Apariencia</b></td><td>Use ropa sencilla, limpia y formal. <br></br>Evite dar señales de riqueza u ostentación. <br></br>Estar limpio, peinado y afeitado. <br></br>Lleva paños húmedos para quitarte el sudor de la cara antes de entrar a la sala de audiencias y otros accesorios necesarios para cuidar tu presentación.</td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>Actitud</b></td><td>Habla solo cuando te lo pidan. <br></br>Mantenga una postura erguida. <br></br>Nunca bajes la cabeza. <br></br>Mira a las personas directamente a los ojos. <br></br>No apartes la mirada de los que preguntan. <br></br>Hable con firmeza y calma. <br></br>No utilices jerga ni blasfemias al hablar. <br></br>Use siempre el título por el cual el juez quiere ser llamado. <br></br>Si no sabe, use <u>señor o señora</u>, pero nunca deje de usar estas palabras. <br></br>Use un lenguaje sencillo y directo, no trate de embellecerlo. <br></br>No tartamudees. Si sucede, respire hondo y hable despacio. <br></br>El abogado de la parte puede hacer acusaciones infundadas o tratar de sacarlo de su mente. <br></br>No juegues a este juego, no muestres reacciones, responde con calma.<br></br>Siempre dice la verdad. <br></br>Si es necesario mentir, tenga cuidado con el <b>lenguaje corporal</b>.</td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>Lista de control</b></td><td>Lleve siempre una lista con todos los elementos que deben abordarse en la audiencia. <br></br>No te quedes sin que se hayan discutido todos ellos, con fecha de conclusión y penalizaciones definidas. <br></br>Deberán ser descritos y completados en la sentencia que dictará el juez al término de la audiencia. <br></br><br></br><b>Nunca dejes de hacer esto</b>. <br></br><br></br>Siempre se olvidan y acaban dejando muchos huecos en la frase. <br></br>No se preocupe por el tiempo de la audiencia. <br></br>Es una situación incómoda y queremos que la audiencia termine pronto.<br></br>Tómese todo el tiempo que considere necesario.<br></br> <b>Tu vida está en juego</b>.<br></br></td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>Defensa</b></td><td>Si el abogado no es apto, rompa todo el protocolo. <br></br>Olvídese de toda la guía descrita y haga su propia defensa. <br></br>Solo usted sufrirá las consecuencias de esta audiencia y nadie más. <br></br><b>Si es necesario, lucha para sobrevivir, nunca te quedes callado.</b></td></tr>
                <tr><td bgcolor='#EAEAEA' ><b>Lenguaje</b></td><td>La justicia utiliza un lenguaje técnico que puede confundir a la gente corriente que no está acostumbrada. <br></br>Es interesante conocerla para poder defenderse. <br></br>Además, la justicia occidental se basa en la cultura grecorromana. <br></br>Debido a esto, muchos términos están escritos en latín, lo que dificulta su comprensión.</td></tr>
                </tbody>
                </table>

                <br></br><br></br>
                <b>Lenguaje Corporal</b><br></br><br></br>
                <u>Señales corporales que indican una mentira</u>:<br></br><br></br>

				<table border="1">
                <tbody>
                <tr><td>&nbsp;Cúbrase o coloque la mano sobre la boca al hablar.&nbsp;</td></tr>
                <tr><td>&nbsp;Evite el contacto visual.&nbsp;</td></tr>
                <tr><td>&nbsp;Pellizca los labios.&nbsp;</td></tr>
                <tr><td>&nbsp;Mira hacia arriba y a la derecha.&nbsp;</td></tr>
                <tr><td>&nbsp;Fruncir el ceño.&nbsp;</td></tr>
                <tr><td>&nbsp;Se vuelve estático. Restringir los movimientos de manos y brazos.</td></tr>
                <tr><td>&nbsp;Tener movimientos rígidos y repetitivos.&nbsp;</td></tr>
                <tr><td>&nbsp;Reducir la cabeza.&nbsp;</td></tr>
                <tr><td>&nbsp;Cambiar el propio cuerpo muchas veces.&nbsp;</td></tr>
                <tr><td>&nbsp;Encoger solo un hombro.&nbsp;</td></tr>
                <tr><td>&nbsp;Alejarse del interlocutor.&nbsp;</td></tr>
                </tbody>
                </table>

                <br></br><br></br>
                </span>
            </S.FormText>

            </S.Form>
            </>
            :
            <>
            <br></br>
            <br></br>
            <h3>&nbsp;</h3>
            <br></br>
            </>    }  
        </S.Container>
    )
}

export default GuidePart3;