
import React, { useEffect, useState } from 'react';   
import * as S       from './styles';
import guide01      from '../../../../view/DivorceGuide/Guide/Images/guide01.png';
import guide02      from '../../../../view/DivorceGuide/Guide/Images/guide02.png';
import guide03      from '../../../../view/DivorceGuide/Guide/Images/guide03.png';
import guide04      from '../../../../view/DivorceGuide/Guide/Images/guide04.png';
import util         from '../../../../Utilities/Util';

function GuidePart1() {
    
    const [access, setAccess] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)
        setAccess( util.checkGuideAccess() );
    }, [])

    return (
        <S.Container>
        { ( access )
            ?
            <>
            <S.Form> 

            <S.FormTitle>
                <span>PARTE1</span><br></br><br></br><br></br><span>As características pessoais</span><br></br><br></br>
            </S.FormTitle>

            <S.FormText>
                <span>

                O que faz uma pessoa desejar ou solicitar um divórcio? É uma resposta de cunho pessoal que apenas os envolvidos podem responder a essa pergunta.<br></br><br></br>
                Contudo, quando houver ameaça ou consumação de violência física, psicológica ou social de um dos cônjuges contra o outro, ou contra os filhos, não é mais uma escolha, é um caso de polícia. A pessoa ameaçada ou agredida deve procurar as autoridades competentes o mais rápido possível e encerrar essa relação destrutiva e perigosa. Mas quando não houver violência desse tipo os motivos podem ser justos e corretos, bem como podem ser injustos e sem sentido fazendo com que seja algo penoso para todos. <br></br><br></br>
                Quando o casal possui respeito mútuo e está em comum acordo, o divórcio realmente é realizado de forma tranquila, e passa a ser apenas uma operação judicial de prazo relativamente curto. Porém, quando o caminho escolhido for outro, ele passa a ser um procedimento de futuro e consequências incertas.<br></br><br></br>
                Muitos fatores irão influenciar e contribuir na escolha do caminho que será utilizado em um processo de divórcio. Poderá depender da modalidade adotada no regime do casamento, se existirem filhos menores ou dependentes, número de bens, localização do órgão público que será responsável pela causa, competência do advogado, e principalmente das características pessoais dos envolvidos como seu caráter, gênero, estado emocional, financeiro, e etc.<br></br><br></br> 
                Portanto, o divórcio é um acontecimento que devido a esses fatores e características poderá ser uma operação tranquila ou algo muito dispendioso, devastador de futuro incerto.
                <br></br><br></br><br></br><br></br>
                <h2>Caráter</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide01} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                É um conjunto de qualidades e defeitos de uma pessoa que vão determinar sua conduta, comportamento e a maneira de agir e reagir diante das situações.<br></br><br></br>
                Pessoas desonestas que não apresentam firmeza de princípios e atitudes são classificadas como de <b><u>PESSOA MAL CARÁTER</u></b>, ao contrário daquelas denominadas como <b><u>PESSOA DE CARÁTER</u></b>, que não são conduzidas por mentiras e/ou interesses pessoais, mas sim, por firmeza moral e atitudes de elevado valor.<br></br><br></br>
                Se um dos cônjuges ou ambos possuir mal caráter, o divórcio será com toda a certeza um procedimento penoso e destrutivo de consequências negativas para os envolvidos e seus filhos. Um divórcio não amistoso coloca as partes sobre enorme tensão emocional, onde o instinto de sobrevivência é ativado. Quando isso acontece, tudo passa a ser uma ameaça e possuir importância irrealista. Qualquer coisa poderá ser motivo de discussão e discórdia, pois a sobrevivência futura dependerá dos benefícios e vantagens adquiridas. O caráter é o fiel da balança que pode impor limites e finalizar essa disputa de forma racional, honesta, realista e principalmente vantajosa para todos. Mas quando o caráter for baixo em um deles, qualquer coisa será permitida para vencer essa disputa e obter o maior número de vantagens e benefícios possíveis.<br></br><br></br>
                Pessoas com as quais convivemos durante anos, podem se tornar destrutivos algozes sem piedade, consideração, respeito ou gratidão. Seu objetivo será apenas a destruição de seu oponente.  Tudo de bom que aconteceu na relação será denegrido e adulterado. Tudo será apagado e esquecido. Nenhum sacrifício ou ação nobre realizada no passado será considerada pela justiça ou pela parte com maior poder.  A verdade e os fatos serão corrompidos e retirados do processo. Todo esse cenário poderá ser inflamado ainda mais de acordo com o defensor escolhido. Existem estudos que demonstram que <b><u>70% DOS DEFENSORES</u></b> possuem o mesmo caráter de seus contratantes. Isso indica que existe uma chance muito significativa da parte mentirosa possuir um defensor de mesmo caráter e personalidade. A produção de provas falsas e incriminatórias, bem como a presença de pessoas que farão falso testemunho, poderá ser uma terrível realidade.<br></br><br></br>
                Acreditar que pessoas de caráter elevado tem vantagem sobre outra de menor caráter é uma destrutiva ilusão, e não tem correspondência com a realidade, apenas nos livros e nas produções cinematográficas isso acontece. No mundo real, a justiça não tem capacidade nem competência para avaliar a nobreza ou a bondade das pessoas. Muito pelo contrário, ela se apega a relatos recebidos, muitas vezes inflamados de mentiras e difamações. Geralmente, desconfia de pessoas benevolentes e honestas, preferindo acreditar na maldade e desonestidade delas. Isso acontece porque infelizmente estamos cercados de acontecimentos e pessoas sem limites, escrúpulos ou caráter. Por isso quando uma pessoa se apresenta como portadora de elevados princípios e de atitudes nobres passa a impressão de mentirosa ou trapaceira, a não ser que possa comprovar através de provas incontestáveis, o contrário desse pensamento. No final de tudo, o divórcio acaba demonstrando o verdadeiro caráter das pessoas envolvidas.<br></br><br></br>  
                <b><i>O caráter é um fator determinante no resultado de um processo de divórcio, mais importante que qualquer outro que possa existir.</i></b>

                <br></br><br></br><br></br><br></br>
                <h2>Estado Emocional</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide02} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                Ao contrário do que as pessoas acreditam não somos seres racionais, somos seres emocionais. Todas nossas escolhas mesmo aquelas que parecem lógicas e racionais são movidas por nossas emoções. Não existe exceção a essa regra segundo a ciência que estuda o funcionamento do cérebro humano. <br></br><br></br>
                O divórcio é um acontecimento doloroso na vida de qualquer pessoa, independentemente de como será realizado, ele representa o fim de uma relação, dos sonhos e dos projetos idealizados pelo casal.  O sentimento presente no divórcio pode ser semelhante ao <b><u>LUTO</u></b> que representa uma tristeza profunda pela perda de alguém. Quem solicita o fim da união passa por um turbilhão de sentimentos, muitas vezes com o sentimento de culpa pela situação estabelecida. O mesmo acontece com quem recebe o pedido, mas neste caso o sentimento resultante é o de raiva ou rejeição. Poucos divórcios são realizados de forma amigável e consensual, mas quando estes ocorrem eles também são revestidos de emoções como perda e frustração. <br></br><br></br>
                O período que antecede ao pedido de divórcio normalmente é cercado de situações e emoções agitadas e negativas. Ninguém emocionalmente equilibrada solicita um divórcio sem que esses acontecimentos tenham anteriormente ocorrido.  É um período normalmente acompanhado de discussões, desrespeito e mentiras entre as partes. Em alguns casos o divórcio é até mesmo mencionado, sugerido ou ameaçado abertamente por um deles durante esse período de conflito mutuo. O medo é um sentimento mediador nessa fase. É um sentimento paralisante que interrompe as ações e os desejos dos envolvidos. O medo do futuro, do que irá acontecer com suas vidas e de seus filhos, pode muitas vezes adiar as decisões do casal ou de um deles. <br></br><br></br>
                Por causa disso, muitas vezes uma situação desagradável é mantida na esperança de que possa ser modificada ou para salvaguardar os filhos de qualquer coisa prejudicial a eles. Também é uma fase bastante perigosa, onde os sentimentos poderão estar inflamados e assim permitir a um deles agir de forma impensada e irracional, principalmente quando se sentirem de alguma forma ameaçados ou encurralados quanto aos seus direitos e vida futura. É uma etapa conturbada, não deve ser utilizada para expor as magoas e os ressentimentos represados, a não ser que seja para salvar ou argumentar sobre a relação na tentativa de buscar soluções positivas para a situação. Isso deve ser feito apenas se houver dialogo e respeito entre as partes. Caso contrário poderá ser o estopim de novas desavenças e hostilidades, propiciando para diminuir ainda mais a harmonia familiar que ainda possa existir.<br></br><br></br>
                Além disso, os envolvidos nessa situação estão muito suscetíveis de receber sugestões e conselhos de pessoas fora da relação, como parentes e amigos. Isso também é perigoso e nem um pouco recomendável.  Geralmente, eles são péssimos conselheiros, principalmente pelo fato de não serem prejudicados nem de sofrer as consequências de um divórcio.  Costumam incendiar a situação e dizer aquilo que a parte quer ouvir. Também é muito comum a parte desprezar as opiniões contrárias e aceitar apenas aquelas que estão em concordância com o que estão sentindo, pensando ou com aquilo que já foi intimamente decidido. <br></br><br></br>
                Raramente, esses aconselhamentos trazem algum benefício para a pessoa ou para o casal que já está em uma situação desarmoniosa. É preferível buscar conselhos de pessoas que já passaram por isso ou de profissionais dessa área, como psicólogos e terapeutas especializados em relacionamentos. <br></br><br></br>
                Hoje em dia muitas pessoas sofrem de algum <b><u>TIPO DE TRANSTORNO MENTAL</u></b> e utilizam medicamentos para controlá-lo. É uma situação que afeta o julgamento e a parte emocional dessas pessoas. Muitas têm dificuldades para tomar decisões ou entender de forma clara sua situação dentro de um casamento ou de sua própria vida. Não sabem o que querem e suas ideias e ações estão sempre mudando de acordo com seu estado emocional e acontecimentos externos que não conseguem interpretar com clareza e objetividade. Tendem a transformar um cenário pacífico ou uma situação corriqueira em algo terrível e sem solução. São pessoas suscetíveis a opiniões e influências externas. <br></br><br></br>
                Geralmente negativas e motivadas por interesses individuais e financeiros de outras pessoas, como amigos invejosos ou advogados. Não medem as consequências de suas ações e acabam utilizando o divórcio como meio de escapar de uma situação desagradável para elas que em muitas vezes não existe ou foi criada por ela mesma. Fazem as coisas por impulso, sem medir as consequências, gerando arrependimento e sequelas desagradáveis para todos. A não ser que haja violência ou agressão, o divórcio não deve ser realizado apressadamente por impulso. Deve ser um ato pensado com base em um objetivo real de mudança de vida. <br></br><br></br>
                Outro ponto interessante e corriqueiro é o fato de o divórcio ser desejado por causa da existência de novas ligações amorosas. Isso acontece por causa da falência na relação ou por motivos diversos que não podem ser explicados de forma simples e genérica. Mas é uma realidade presente e geralmente irreversível. Quando isso acontece, imediatamente a parte contrária passa a ser motivo de chacota e impedimento da felicidade. Passa a ser descartável e qualquer ato amoroso, de bondade ou gratidão realizado no passado é automaticamente esquecido ou desprezado.  Ninguém pertence a ninguém, todos temos o direito de nos apaixonar por outras pessoas e mudar nossos destinos. Contudo, não devemos fazê-lo de forma irresponsável, principalmente quando outras pessoas estão envolvidas. Isso é <u><b>EXTREMAMENTE PERIGOSO</b></u>, não sabemos como essas pessoas irão reagir, nem mesmo elas sabem. O noticiário está lotado de exemplos trágicos de pessoas que perderam o controle por motivos fúteis e muitas vezes sem sentido. Não é possível determinar se a outra parte fez por merecer ou se ela é vítima das circunstâncias, mas é aconselhável finalizar uma relação de forma tranquila e cordial. Se houver filhos envolvidos isso se torna essencial, principalmente porque a outra parte sempre estará ligada a eles de forma direta ou indireta. É uma situação delicada e complicada, não devendo ser realizada com impaciência. <br></br><br></br>
                O divórcio não é tão rápido como é informado as pessoas. Infelizmente a justiça é lenta e burocrática, e provocar brigas e descontentamentos com a outra parte não ajuda em nada, muito pelo contrário, irá prolongar a decisão do juiz ainda mais. A partir do momento que o processo é solicitado, as pessoas envolvidas deixam de ter qualquer compromisso ou deveres conjugais, podendo vivenciar outro relacionamento sem qualquer problema de ordem legal. Ela apenas não poderá se unir judicialmente com seu novo companheiro(a) enquanto o processo não for finalizado e seu estado civil modificado. <br></br><br></br>
                Existem outros motivos emocionais responsáveis por ativar a separação de um casal. Em todos eles o fator emocional estará presente e irá controlar as ações dos participantes. É necessário entender as emoções presentes nessa decisão para que seja possível controla-las na medida do possível e assim possibilitar a realização de um divórcio coerente e dentro de uma realidade tangível.
                
                <br></br><br></br><br></br><br></br>
                <h2>Estado Financeiro</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide03} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                Os divórcios amigáveis são mais rápidos e baratos, mas também precisam ser pagos. Não acredite na justiça gratuita. Nada é gratuito. Tudo custa algum dinheiro para as pessoas envolvidas. Tentar realizar um divórcio não amistoso e sem dinheiro, é <u><b>SUICÍDIO FINANCEIRO</b></u>. Geralmente nesses casos os ânimos estão acirrados e o estado emocional presente prejudica a correta tomada de decisões das partes envolvidas. 
                Normalmente, um deles ou ambos querem ter razão em suas reinvindicações e fazem de tudo para concretiza-las. O valor e os custos envolvidos deixam de ser importantes e o orgulho predomina sob a razão. 
                É o momento ideal para profissionais inescrupulosos ganharem dinheiro. Basta apenas inflamar os ânimos com promessas irrealistas que em sua grande maioria não serão cumpridas. Quanto mais processos existirem maior será o ganho financeiro desses profissionais. Além disso, a justiça é toda fragmentada. Não é possível em um mesmo processo discutir múltiplos pontos incomuns. É necessário um para definir a partilha dos bens, outro para definir o valor da pensão, outro para definir a guarda dos filhos e assim por diante. Quanto maior for o número de problemas e de exigências, maior será o número de processos a serem julgados, com o pagamento de custas e advogados. Recorrer sobre decisões promulgadas, também gastam recursos financeiros. Além disso tudo, o juiz ainda tem o direito e o poder de decidir quem pagará às custas de cada processo existente. <br></br><br></br>
                É um sistema deficiente e injusto. Não espere bom senso nem compreensão e principalmente compaixão sobre sua situação pessoal e financeira.<br></br><br></br>
                <b>Alguém sempre terá que pagar as contas</b>. <br></br><br></br>
                Dá mesma forma que existe uma indústria do casamento, existe uma <u><b>INDÚSTRIA DO LITÍGIO</b></u>, que incentiva a disputa entre as partes, oferecendo ganhos fáceis e rápidos que na maioria dos casos nunca irão acontecer. Infelizmente as pessoas se dão conta disso tarde demais, normalmente depois que seus recursos financeiros forem exauridos com processos e exigências inúteis. E novamente vemos a importância do dinheiro nesse ambiente. Ele é a matéria prima que possibilita a realização de qualquer processo. As pessoas precisam entender que o tempo e o dinheiro gasto em processos judiciais jamais serão recuperados. É comum pessoas perderem seu patrimônio, muitas vezes conquistado com muito trabalho, nesse tipo de procedimento.<br></br><br></br>
                <u><b>É PREFERÍVEL REALIZAR UM ACORDO RUIM</b></u> do que gastar tempo e dinheiro em processos desgastantes e dolorosos que não produzem o retorno ou resultado esperado. Um divórcio litigioso é algo terrível e muito dispendioso, tudo deve ser feito para evitá-lo. Quando isso não for possível, ele não deve ser realizado com raiva, magoas ou sentimentos inflamados. Os ânimos já estarão exaltados com o final do casamento. Então, na medida do possível, terá que ser feito com frieza e objetividade visando atingir resultados realistas que possam ser concretizados. <br></br><br></br>
                Também é muito importante esclarecer e frisar que infelizmente a justiça pode ser <b>ilógica, injusta e irracional</b>. Em muitos casos o dinheiro terá que aparecer por decreto. Não adiantará nada provar através de documentos incontestáveis que você não tem dinheiro, está desempregado ou possui outro tipo de impedimento válido. O dinheiro terá que surgir por mágica e ponto final. Isso é insanidade, mas infelizmente estamos cercados de pessoas assim que determinam as nossas vidas sem qualquer empatia ou consideração. 
                Quando for utilizar a justiça para resgatar seus direitos irá descobrir como o mundo real funciona e poderá ter a chance de encontrar pessoas com esse nível de irracionalidade. A justiça e os resultados podem ser bem diferentes do esperado ou prometido. 
                Portanto, não desperdice seu tempo e principalmente seus recursos financeiros com a justiça. Na medida do possível resolva suas diferenças sem precisar acioná-la. Utilize esses recursos para reconstruir sua vida e não para tentar provar que tem razão. Utilize para desfrutar momentos com seus filhos e amigos, para viajar, estudar e etc.<br></br><br></br> 
                Em muitas vezes é <b><u>PREFERÍVEL SER FELIZ DO QUE TER RAZÃO</u></b>. Não seja dominado por seu <b><u>EGO</u></b>, não gaste seu dinheiro com isso, com algo que não funciona.
                
                <br></br><br></br><br></br><br></br>
                <h2>Gênero</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide04} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                <i><b>O gênero dos cônjuges define o futuro e o resultado em um processo de divórcio.</b></i> <br></br><br></br> 
                Os homens são os maiores perdedores em qualquer reivindicação que façam. As leis favorecem apenas as mulheres que dificilmente perdem algum processo, a não ser que haja distorções muito grosseiras em suas ações e atitudes que não possam ser defendidas nem justificadas. Qualquer pessoa que afirmar o contrário estará mentindo. <br></br><br></br>
                É algo inquestionável e acontece na maioria dos países de cultura ocidental. Existem muitas razões que justificam essa realidade e colocam as mulheres em uma posição privilegiada e intocável em relação aos homens. A principal delas é a maternidade. Essa capacidade biológica retira automaticamente das mulheres qualquer imagem de criminalidade ou imoralidade. Transformando qualquer uma delas em um ser doce e emotivo, que quando comete crimes, faz por causa da emoção, mesmo vitimando filhos e companheiros nessas ações. Devido a isso acabam recebendo penas mais brandas e menores que os homens que cometem crimes semelhantes. <br></br><br></br>
                As estatísticas demonstram que elas são as maiores responsáveis pela violência doméstica, mas raramente é noticiado crimes desse tipo nos meios de comunicação, exceto quando o padrão da normalidade não é seguido e são excessivamente brutais. Mesmo assim esses crimes são tratados como passionais e motivados pelas atitudes do companheiro vitimado, fazendo o crime ser justificado e aplaudido pelo público em geral. Essa mesma mentalidade se aplica ao divórcio e seus respectivos resultados. <br></br><br></br>
                O homem é sempre rotulado com inapto e violento, não sendo capaz de educar seus filhos, cabendo a ele apenas a obrigação de sustentá-los e nada além disso. Qualquer coisa que tenha feito em benefício dos filhos e companheira terá sido mera obrigação e não será utilizada para avaliação de seu caráter ou capacidade. Por isso as mulheres são os clientes preferidos dos advogados. A chance de vitória é próxima do 100% e ainda eles podem inflamar os processos com relatos e reinvindicações absurdas sem que precisem ser comprovadas, pois sabem que serão aceitas ou no mínimo levadas em consideração pela justiça. Ao contrário dos homens, que raramente tem suas reinvindicações atendidas, mesmo munidos de provas contundentes ao seu favor.<br></br><br></br> 
                Os homens estão sendo aniquilados fisicamente, moralmente, mentalmente e juridicamente. É um processo irreversível e acentuado. As leis já passaram do limite da equidade, isonomia e do bom senso, e novas continuam sendo elaboradas e implementadas. É um processo mundial que não está circunscrito ao nosso país ou as nossas leis. <br></br><br></br>
                Atualmente, <b><u>UMA MULHER PODE DESTRUIR A VIDA DE UM HOMEM COM EXTREMA FACILIDADE</u></b>, apenas com uma denúncia contra ele. Sem ter que provar absolutamente nada, basta apenas sua palavra. Afinal, os homens são canalhas, agressivos e estupradores em potenciais por isso devem ser tratados como criminosos. Caso consiga provar sua inocência, nada irá acontecer com a mulher que o denunciou, apesar de ter sido preso, humilhado, passado por todo tipo de constrangimento e sua vida ter sido arruinada. Ele pode apenas ajuizar um processo de danos morais contra ela, que depois de muito tempo resultará em algo não satisfatório, sem jamais compensar ou minimizar toda violência sofrida e as marcas permanentes deixadas.  O número de denúncias falsas registradas contra os homens passou da casa dos milhares em apenas um ano. Esse fenômeno está acontecendo em quase todos os países ocidentais. As denúncias se tornaram falsas porque os acusados de alguma forma conseguiram provar sua inocência, mas isso leva a um questionamento. Aqueles que não puderam fazer isso, serão todos eles realmente culpados? O sistema perdeu toda racionalidade e privilegia apenas um lado. Muitos homens ainda não perceberam isso. Acreditam que essas leis não irão atingi-los e que foram elaboradas para combater a criminalidade e que não devem temer a justiça.<br></br><br></br>
                <i><b> Terrível engano, quando as leis são totalitárias não existe justiça. </b></i><br></br><br></br>
                Uma parcela significativa da população masculina já percebeu essa situação e estão abandonando os relacionamentos com as mulheres para poderem sobreviver. Atitude essa sendo realizada, inclusive por indivíduos mais jovens, que já perceberam essa realidade e não querem sofrer os mesmos abusos e violências cometidas contra seus pais ou pessoas conhecidas. O problema é tão sério que as autoridades já estão preocupadas com as consequências futuras disso tudo. Mas não pensem que haverá um retrocesso nesse sistema. Pelo contrário, novas leis estão sendo planejadas para atingir esse grupo de pessoas. <br></br><br></br>
                Em breve, não ter relacionamentos ou estar solteiro será considerado crime contra às mulheres. 
                <br></br><br></br>
                
                </span>
            </S.FormText>

            </S.Form>
            </>
            :
            <>
            <br></br>
            <br></br>
            <h3>&nbsp;</h3>
            <br></br>
            </>    }  
        </S.Container>
    )
}

export default GuidePart1;