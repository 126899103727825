
import React, { useEffect, useState } from 'react';   
import * as S       from './styles';
import guide05      from '../../../../view/DivorceGuide/Guide/Images/guide05.png';
import guide06      from '../../../../view/DivorceGuide/Guide/Images/guide06.png';
import guide07      from '../../../../view/DivorceGuide/Guide/Images/guide07.png';
import guide08      from '../../../../view/DivorceGuide/Guide/Images/guide08.png';
import guide09      from '../../../../view/DivorceGuide/Guide/Images/guide09.png';
import guide10      from '../../../../view/DivorceGuide/Guide/Images/guide10.png';
import util         from '../../../../Utilities/Util';

function GuidePart2() {
    
    const [access, setAccess] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)
        setAccess( util.checkGuideAccess() );
    }, [])

    return (
        <S.Container>
        { ( access )
            ?
            <>
            <S.Form> 

            <S.FormTitle>
				<span>PART 2</span><br></br><br></br><br></br><span>The reality in which we are inserted</span><br></br><br></br>
             </S.FormTitle>

             <S.FormText>
                <span>
                Reality is a widely debated concept in the field of philosophy. It is something that has different definitions and that varies according to the context where it is studied and debated. 
                However, to facilitate our understanding, we can say that reality is how things really are. <br></br><br></br>
                People in general are unaware of their own reality. They act and make decisions based on illusions created in their minds. They are usually fed and produced by the society where they live. 
                They form their ideas and concepts from movies, soap operas and other existing media. They believe that in a trial everyone fights for truth and justice that their defenders will do anything for them that good always triumphs over evil, etc. <br></br><br></br>
                Unfortunately, the real world is bleak and unfair to most people and only privileges a minority. No one seeks information or seeks the truth. They prefer the consolation of lies and illusion so that they do not have to change their beliefs and customs nor the society in which they are inserted. 
                However, they are compelled to live with the truth when something breaks in their lives. <br></br><br></br>
                It is precisely in those moments of pain and disorientation that they discover that they have been living a lie. 
                They realize that institutions, laws and their components do not work. That the people around you were not trustworthy or friends. Being forced to wake up to a reality they didn't know or wanted to avoid.

                <br></br><br></br><br></br><br></br>
                <h2>Differences between men and women</h2>                
				<br></br><br></br>
                <S.FormImage><img src={guide05} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                <i><b>The laws are sexist and only favor women.</b></i><br></br><br></br>
                Men are the biggest losers in divorce proceedings. They rarely win a lawsuit especially if children are involved. The most they can do is mitigate their losses and that only happens if they are lucky and advance the planning in their favor. 
                When there are children everything will depend on the character of the woman involved in the dispute. Because in such cases men have no right over them. They only have the duty to pay the expenses and be penalized when they do not comply with this obligation. 
                Being unemployed, sick or any other handicap will never be mitigated. Any truce granted by justice must be repaired with interest, correction and a fine after its normalization. <br></br><br></br>
                <b>You will have to spend a lot of money to prove that you have no money</b>.<br></br><br></br>
                After divorce, even in silence the society and institutions will treat men as criminals. Any abuse suffered by the winning party will never be taken into account. There is no place or institution to denounce them. 
                Police stations refuse to record any violation of their rights. Which, as described above, does not exist.<br></br><br></br>
                A new lawsuit is needed to try anything. When accepted, they only generate warnings to the culprit and nothing else.<br></br><br></br>
                Women are currently surrounded by privileges and rights that do not stop growing. It's been a long time since the break-even point was broken. This imbalance will cause more and more suffering to the parties involved in a divorce process, as well as to her children and relatives. <br></br><br></br>
                <i><b>The constitutional principles of equality are no longer respected.</b></i><br></br><br></br>
                Men are just providers in a system of slavery.
                Men and women must be equal in rights and obligations. It is one of the main pillars of legal equality defended as a fundamental right by all democratic nations in the world. <br></br><br></br>
                However, these principles are no longer respected or used. Women present themselves as victims demanding more and more protection laws and policies. <br></br><br></br>
                They argue that they receive lower wages compared to men, but they do not present a single victim of this claim. Strict employment laws already exist to prevent this type of abuse or distortion, but this is ignored by those that require the creation of quotas on available vacancies.<br></br><br></br>
                Currently, they already occupy the best jobs, just look at the existing companies. The shopfloor which is the dirtiest, heaviest and least paid part is mostly occupied by men while the administrative part which is cleaner, quieter and better paid is predominantly occupied by women.<br></br><br></br>
                They say they are the biggest victims of domestic violence. However, all articles, surveys and documentaries that show otherwise are immediately removed from the media. Only one side of this story is accepted as true. While the numbers themselves demonstrate another reality that is never debated or published.<br></br><br></br>
                They receive shorter and lighter sentences compared to men who have committed equally serious crimes. They retire first, although their life expectancy is longer. They benefit from exclusive health programs, unlike men with the same illnesses who must fend for themselves. 
                They are not required to perform military service or defend the country in conflict situations.<br></br><br></br>
                In addition, the justifications used by them <u><b>NEVER BE QUESTIONED OR CRITICIZED</b></u> anyone who does so will be ridiculed or discredited, even if it is another woman.

                <br></br><br></br><br></br><br></br>
				<h2>Litigation Industry</h2>
				<br></br><br></br>
			    <S.FormImage><img src={guide06} alt=""></img></S.FormImage>
				<br></br><br></br><br></br>

                While marriage is about love, divorce is about money. Currently, one in three marriages ends in divorce. It has become a lucrative industry that generates millions a year. In the United States it generates about US$50 billion a year. 
                There is no way to calculate these numbers in other countries, but there is no doubt that they must be very similar numbers. There are a million divorces in the United States every year. 
                That is, one every 36 seconds, almost 2,400 a day and 16,800 a week. The numbers are surprising and show that it is <u><b>A VERY PROFITABLE BUSINESS</b></u>. <br></br><br></br>
                A non-consensual divorce proceeding is <u><b>COST AND TIME CONSUMPTION</b></u> for those involved. Those who go through this experience lose an average of 70% of their assets or greater amounts in the most serious cases. 
                Many disputes are intentionally inflamed and prolonged. This results in the unnecessary fabrication of issues that could be quickly resolved by more peaceful, quick and effective out-of-court means, but this is neither interesting nor profitable.<br></br><br></br>
                There is a litigation industry which follows a production line that must work according to established standards. Everything is done on paper, something is rarely said or requested. It is already ready and written, only your data is modified and everything is done remotely without your presence. 
                The people who define your life don't look you in the eye and don't even know if you have arms or legs. There is no empathy. The human values are not respected or taken into account. There is no humanity, when it exists it is hypocritical. There is only a technicality. <br></br><br></br>
                The results are already defined. They are just waiting to be registered and dispatched so that a new one can occupy the vacant place. Just like a factory production line. If you're lucky, your request will be read and judged correctly. 
                After all, from time to time it is necessary to prove that the system works. It is necessary to demonstrate that the injustices reported by some people are nothing more than unfortunate exceptions.<br></ br>< br></br>
                <br></br><br></br>
                <u><b>Branches</b></u>
                <br></br><br></br>
                Every industry generates direct and indirect jobs because it is necessary to create a whole parallel support and maintenance structure. This allows the emergence of smaller or related segments that supply raw materials, components or complementary services so that this industry can function forming an integrated system. 
                The greater the production, the greater the profit of this entire system. The same is true of the litigation industry which provides and also needs the material and services provided by its associates. <br></br><br></br>
                The wedding industry is a segment that moves billions annually. It is the main supplier of raw materials for the litigation industry which in turn also ends up feeding this industry in an endless circle. <br></br><br></br>
                In every system there is a loss of energy so that the gears can work. Not all marriages end in divorce and not all divorces will result in remarriage. However, the current numbers are still capable of generating huge profits and keeping that wheel turning. 
                In addition, it is also possible to create other similar industries that use and take advantage of production waste. <br></br><br></br>
                Currently we have the fatherhood industry forcing men to support children that are not their own. As well as the rape industry that generates thousands of false accusations and forces men to spend a lot of money to defend themselves against their accusers. 
                New laws and difficulties are periodically created to keep it in perfect working order.<br></br><br></br>
                Finally, it is an evil, profitable and very creative system.
				<br></br><br></br><br></br><br></br>

				<h2>Psychopaths and Sociopaths</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide07} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                Unless the affected party is eating flies, mental disorders have little or no legal relevance. Much will depend on the defender's ability to use this information and quirk to his client's advantage. 
                In addition to lengthening and making the processes more expensive by involving health professionals who will certify and confirm the reported problems. 
                The institutions and the people that compose them are not prepared to deal with this type of people or situations which is why psychopaths and sociopaths are <u><b>IGNORED AND UNKNOWN</b></u> by the courts. <br></br><br></br>
                Contrary to popular belief these people are not necessarily violent. There are levels of these behavioral disorders that can be high or low, but contempt for people and established social norms is a dominant factor at any level. 
                Psychopathy is a genetic predisposition the person is born with this brain alteration which is why they tend to form artificial relationships maintained only out of interest. 
                While sociopathy is a problem caused by environmental factors such as the education received or the traumas suffered. Creating difficulties in relating or forming bonds. <br></br><br></br>
                Both are made up of intelligent, seductive and manipulative people. Due to these attributes they often occupy important positions in companies and institutions and thus influence our lives directly or indirectly. 
                They can be judges, lawyers, doctors, psychologists, teachers, politicians, military, scientists, religious, anyone even with whom we live. They are capable of creating unjust laws, disastrous rules and procedures, aggravating existing problems, driving nations to war or people to suicide. 
                There are no limits to the destruction they can cause. <br></br><br></br>
                According to statistics, lawyers occupy the second place in the ranking of professions with the most psychopaths. Powerful environments such as <u><b>BUSINESS, LEGAL AND POLITICAL</b></u> are the places where you are most likely to find people of this type.
                <br></br><br></br><br></br>
                <u>Some characteristics of a psychopath/sociopath</u>:
                <br></br><br></br>
                <ul>
                <li>&nbsp;Able to manipulate and seduce the people around him.</li><br></br>
                 <li>&nbsp;They lie and cheat all the time, even when they get caught.</li><br></br>
                <li>&nbsp;Neglect the feelings of others, feel no guilt or remorse.</li><br></br>
                <li>&nbsp;They have no moral or ethical conscience, people are used as means to an end.</li><br></br>
                <li>&nbsp;Impulsive behavior, can be aggressive and dangerous.</li><br></br>
                <li>&nbsp;They do not apologize or correct themselves, but present themselves as friendly, polite and helpful.</li><br></br>
                <li>&nbsp;They are self-centered and selfish, only their well-being is important they do not care about the safety of others.</li><br></br>
                <li>&nbsp;They are irresponsible and may not meet financial obligations.</li><br></br>
                <li>&nbsp;They are cowards. They attack those who cannot defend themselves.</li><br></br>
                </ul>
                <br></br>
                Psychopaths make up 4% of the world's population of which 3% are men and 1% are women. They are 20% of the prison population and 86.5% of serial killers. They have a personality disorder and can be present in all social classes. 
                When psychopathy occurs in women its identification seems to be more difficult. It is believed that these women may be under-investigated and even go undiagnosed. <br></br><br></br>
                Since justice privileges women and treats men as criminals it is almost certain that this factor contributes significantly to hiding and benefiting women who engage in this type of conduct. 
                In terms of percentage, the male part of a relationship seems to be more prone to having this disorder. However, since there is no concrete and definitive research on this subject we must as a guarantee assume that this possibility is the same in both sexes. <br></br><br></br>
                Regardless of gender, being attached in any way to that person is terrible for the other party. Worse will be the process of disconnection. These people do not accept losing or being contradicted. 
                They do not allow the released part to rebuild her life, have new relationships or be happy. Generally, psychopathic men use physical violence against their partners to get their wishes respected and unfortunately the news is full of cases of women victimized by them.
                Men also suffer this type of aggression from their partners, but these cases are omitted or little reported by the media, except when they exceed the <i><b>"normality"</b></i> levels supported. 
                As if it were possible to accept or justify any act of violence between men and women. <br></br><br></br>
                In the case of psychopathic women the scenario changes completely. Just as women benefit from justice, women psychopaths find the perfect environment to carry out their wickedness. 
                They acquire unlimited power over their partners, especially if there are children in the relationship who will undoubtedly be used as a weapon and shield in any future claim or confrontation. 
                They are smart, manipulative and will hardly lose any lawsuit filed against them, With extreme ease and without any remorse, they lead their companions to ruin, imprisonment and suicide, but this type of violence is not counted or shown in any statistics.
                <br></br><br></br><br></br><br></br>
                <h2>Scenery</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide08} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                From the end of the Second World War to the present the human society has been undergoing important transformations that modify our way of life and values. <br></br><br></br>
                <b>The family is the main unit of a society</b>. <br></br><br></br>
                It provides education, security, values and moral principles to its members. Before the Middle Ages, the family was just a social convention for reproduction and transfer of power. 
                From this period in history the family becomes an important institution in charge of promoting the education of children and their behavior in the social environment.
                The role of the family in the development of each individual has acquired fundamental importance.
                Now the values, traditions and customs could be transmitted and perpetuated through generations. Its members had someone to turn to for help and protection, unlike earlier times where their own luck would be. <br></br><br></br>
                Currently, there are powerful groups that are fighting for world power. They want to ensure their survival by taking control of global institutions and established governments.
                To do so, they aim to destroy existing laws and moral values. These values were achieved with much sacrifice during the development of human society.
                They try by all means to destroy existing families and prevent new ones from being created. Because, individuals without family are easier to manipulate and have no one to ask for help and protection becoming dependent and at the mercy of the current state. <br></br><br></br>
                These groups create unjust laws, sell invalid and destructive ideas through the media that control, facilitate and encourage adultery and discord between couples, encourage drugs, muting and other nefarious procedures.
                Professionals of high character and knowledge are gradually being replaced by people without consistent qualifications and moral principles. 
                These people were educated in deficient educational institutions and by broken or discordant families. Many are functionally illiterate and cannot interpret simple text.
                Soon they will be running our lives, creating rules and procedures and judging our actions based on their distorted values.<br></br><br></br>
                <i><b> In recent years, in ominous, subtle and invisible ways, the role of father has been eliminated from human society.</b></i> <br></br><br></br>
                Research and statistics show that there is a direct relationship between the absence of a father figure in the education of children and delinquency. This happens because the father has a <u><b>FUNDAMENTAL ROLE IN THE FORMATION OF THE CHARACTER OF THE CHILDREN</b></u>. <br></br><br></br>
                Mothers provide care, love and protection. Fathers provide role models, structure, courage and <u>character</u>. When the father is absent or is prevented from exercising his role as a father the children are usually carriers of some type of emotional disorder that will affect their future in some way. <br></br><br></br>
                Divorce is an important instrument used by the groups described above. In addition to being facilitated and encouraged. It is used to create perpetual discontent between the parties involved, leading to further legal proceedings and future disagreements.
                It also financially destroys one or both parties, preventing their recovery or improvement in the short term or permanently. It causes some kind of trauma in existing children such as insecurity and rejection, making their relationships difficult in adulthood. <br></br><br></br>
                Finally, divorce eliminates or impedes the right of men to fully and consistently exercise their fatherhood. It is made on purpose to incapacitate men like many other things that surround us and of which we are not aware.
                <br></br><br></br><br></br><br></br>
				<h2>Envy</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide09} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>
                We live in a capitalist and competitive society. Opportunities are reduced and we have to work harder to achieve our dreams and goals. Like it or not we are surrounded by people who envy us and oppose our success and happiness.
                They envy our professional career our financial situation our health our marriage, etc. <br></br><br></br>
                Psychologists and sociologists still do not know exactly what envy is. In human history it has been the cause of great problems and conflicts that ended in death and destruction. 
                It is part of the structure of the human psyche and acts on our culture and social organization. <br></br><br></br>
                It is very common for marriages to break up because of him. Having a spouse, children, a joyful and happy family is something that people in our society are very envious of. 
                They just can't stand the happiness of others and don't accept that others have what they don't have. <br></br><br></br>
                It is normal to be surrounded by false friends and relatives who covet our happiness. When a marriage falters for innumerable reasons they are the ones who present themselves as wise advisers always ready to help us.
                Generally, they inflame the problems and advise divorce as a solution.
                Many are even responsible for the appearance of the original problem. They tend to sow suspicion in the minds of spouses, promote heartbreak, encourage betrayal in the name of freedom and pleasure and countless other deviations from family behavior. 
                It sounds like a fictional soap opera, but this is more common than people realize. <br></br><br></br>
                Research from <u>Brown University</u> even reveals that divorce is contagious. That the probability of a person getting divorced increases by <b>75%</b> when they have a direct relationship with a person who is getting divorced or has just gone through that experience.<br></br><br></br>
                <i><b>Divorce should be a personal and individual attitude. It should be carried out for the right reasons.</b></i><br></br><br></br>
                Counseling provided by professionals exempt from the couple such as psychologists and therapists and not by friends or family is recommended, but it is much more advisable to get and see testimonials from strangers who have had good and bad divorces before taking any action.
                Envy produces improper or malicious advice that is capable of encouraging or activating the production of a divorce.

                <br></br><br></br><br></br><br></br>
                <h2>Justice</h2>
                <br></br><br></br>
                <S.FormImage><img src={guide10} alt=""></img></S.FormImage>
                <br></br><br></br><br></br>

                <i><b>Justice is made up of a trinomial, it is illogical, inequitable and irrational.</b></i><br></br><br></br>
                Currently, countless people are imprisoned without trial others remain free even after being sentenced and continue to produce new victims others have their lives destroyed waiting for it others ruined by erroneous or perverse sentences and decisions. <br></br><br></br>
                Lawsuits are piling up and court officials can't handle it. Many get sick trying to make it work others don't care. Justice is slow, shaped by poor procedures, outdated and unfair laws.
                It is made up of groups that benefit from its irrationality and bureaucracy. It is a lucrative industry that continues to grow. The processes are manipulated or forgotten according to personal interests. <br></br><br></br>
                <u><b>JUSTICE WAS CREATED NOT TO WORK AND PRIVILEGED A SMALL PARTY THAT CONTROLS IT</b></u>. <br></br><br></br>
                Difficulties are created to later sell facilities. It is composed of a system that uses large financial resources to function. <br></br><br></br>
                Billions of millions of public coffers are spent annually just on its upkeep. Money that could be used for the benefit of the population, but is used to keep a corrupt deficient and unfair system running which does not achieve the objective for which it was created.
                Being just an illusion created to deceive people and convey a sense of security and support. Make them believe that their problems will be solved. <br></br><br></br>
                Stronghold of psychopaths, people without scruples and without character. Many disguised and covered with titles, powers and stewards, but they are nothing more than mediocre and inept people. 
                Due to the difficulties, time and money required to train judges, lawyers and other legal professionals one has the impression that these are exceptional people with great intelligence and abilities who fight at all costs for the truth and Justice. <br></br><br></br>
                In reality, they are just ordinary people with strengths and weaknesses. They can be brilliant professionals with great brainpower and empathy, but they can also be ignorant and criminals of the worst kind.
                In a country where there is accentuated corruption with high functional illiteracy in higher education with countless moral distortions of all kinds. <br></br><br></br>
                <b><i>What type of professional do you think you are most likely to find on your path?<br></br><br></br>
                 How do you think your claims will be handled and adjudicated?</i></b><br></br><br></br>
                <br></br><br></br>
                </span>
            </S.FormText>

            </S.Form>
            </>
            :
            <>
            <br></br>
            <br></br>
            <h3>&nbsp;</h3>
            <br></br>
            </>    }  
        </S.Container>
    )
}

export default GuidePart2;