import React                           from 'react';
import {BrowserRouter, Routes, Route}  from 'react-router-dom';
import { Provider }                    from 'react-redux';
import store                           from '../store';

import Login                           from '../view/Login';
//import Home                            from '../view/Home';
import Contact                         from '../view/Contact';
import Product                         from '../view/Product';
import Privacy                         from '../view/Police/Privacy';
import PrivacyTest                     from '../view/PoliceTest';

import DivorceGuide                    from '../view/DivorceGuide/Home';
import DivorceGuidePrivacy             from '../view/DivorceGuide/Privacy';
import DivorceGuideTermsOfUse          from '../view/DivorceGuide/TermsOfUse';
import DivorceGuideToKnowMore          from '../view/DivorceGuide/ToKnowMore';
import DivorceGuideStatistic           from '../view/DivorceGuide/Statistic';
import DivorceGuideConditions          from '../view/DivorceGuide/TermsAndConditions';
import DivorceGuideChildren            from '../view/DivorceGuide/Children';
import DivorceGuideElement1            from '../view/DivorceGuide/Element1';
import DivorceGuideElement2            from '../view/DivorceGuide/Element2';
import DivorceGuideElement3            from '../view/DivorceGuide/Element3';
import DivorceGuideElement4            from '../view/DivorceGuide/Element4';
import DivorceGuideElement5            from '../view/DivorceGuide/Element5';
import DivorceGuideElement6            from '../view/DivorceGuide/Element6';
import DivorceGuideElement7            from '../view/DivorceGuide/Element7';
import DivorceGuideElement8            from '../view/DivorceGuide/Element8';

import DivorceGuideIntroduction        from '../view/DivorceGuide/Guide/Introduction';
import DivorceGuidePart1               from '../view/DivorceGuide/Guide/Part1';
import DivorceGuidePart2               from '../view/DivorceGuide/Guide/Part2';
import DivorceGuidePart3               from '../view/DivorceGuide/Guide/Part3';
import DivorceGuidePart4               from '../view/DivorceGuide/Guide/Part4';
import DivorceGuidePart5               from '../view/DivorceGuide/Guide/Part5';
import DivorceGuidePart6               from '../view/DivorceGuide/Guide/Part6';
import DivorceGuidePart7               from '../view/DivorceGuide/Guide/Part7';
import DivorceGuidePart8               from '../view/DivorceGuide/Guide/Part8';
import DivorceGuideAttach              from '../view/DivorceGuide/Guide/Attach';
import DivorceGuideAuthor              from '../view/DivorceGuide/Guide/Author';
import BookGuiadoDivorcio              from '../view/DivorceGuide/Book/Home';

import DivorceGuideEsIntroduction      from '../view/DivorceGuide/GuideES/Introduction';
import DivorceGuideEsPart1             from '../view/DivorceGuide/GuideES/Part1';
import DivorceGuideEsPart2             from '../view/DivorceGuide/GuideES/Part2';
import DivorceGuideEsPart3             from '../view/DivorceGuide/GuideES/Part3';
import DivorceGuideEsPart4             from '../view/DivorceGuide/GuideES/Part4';
import DivorceGuideEsPart5             from '../view/DivorceGuide/GuideES/Part5';
import DivorceGuideEsPart6             from '../view/DivorceGuide/GuideES/Part6';
import DivorceGuideEsPart7             from '../view/DivorceGuide/GuideES/Part7';
import DivorceGuideEsPart8             from '../view/DivorceGuide/GuideES/Part8';
import DivorceGuideEsAttach            from '../view/DivorceGuide/GuideES/Attach';
import DivorceGuideEsAuthor            from '../view/DivorceGuide/GuideES/Author';

import DivorceGuideEnIntroduction      from '../view/DivorceGuide/GuideEN/Introduction';
import DivorceGuideEnPart1             from '../view/DivorceGuide/GuideEN/Part1';
import DivorceGuideEnPart2             from '../view/DivorceGuide/GuideEN/Part2';
import DivorceGuideEnPart3             from '../view/DivorceGuide/GuideEN/Part3';
import DivorceGuideEnPart4             from '../view/DivorceGuide/GuideEN/Part4';
import DivorceGuideEnPart5             from '../view/DivorceGuide/GuideEN/Part5';
import DivorceGuideEnPart6             from '../view/DivorceGuide/GuideEN/Part6';
import DivorceGuideEnPart7             from '../view/DivorceGuide/GuideEN/Part7';
import DivorceGuideEnPart8             from '../view/DivorceGuide/GuideEN/Part8';
import DivorceGuideEnAttach            from '../view/DivorceGuide/GuideEN/Attach';
import DivorceGuideEnAuthor            from '../view/DivorceGuide/GuideEN/Author';

import DivorcePlanning                 from '../view/DivorcePlanning/Home';
import DivorcePlanningPrivacy          from '../view/DivorcePlanning/Privacy';
import DivorcePlanningTermsOfUse       from '../view/DivorcePlanning/TermsOfUse';
import DivorcePlanningToKnowMore       from '../view/DivorcePlanning/ToKnowMore';

import PersonalDiary                   from '../view/PersonalDiary/Home';
import PersonalDiaryPrivacy            from '../view/PersonalDiary/Privacy';
import PersonalDiaryTermsOfUse         from '../view/PersonalDiary/TermsOfUse';
import PersonalDiaryToKnowMore         from '../view/PersonalDiary/ToKnowMore';

import Lifeplan                        from '../view/LifePlan/Home';
import LifeplanPrivacy                 from '../view/LifePlan/Privacy';
import LifeplanTermsOfUse              from '../view/LifePlan/TermsOfUse';
import LifeplanToKnowMore              from '../view/LifePlan/ToKnowMore';

import PasswordWallet                  from '../view/PasswordWallet/Home';
import PasswordWalletPrivacy           from '../view/PasswordWallet/Privacy';
import PasswordWalletTermsOfUse        from '../view/PasswordWallet/TermsOfUse';
import PasswordWalletToKnowMore        from '../view/PasswordWallet/ToKnowMore';

//<Route path="/"                                  element={<Home />   }/>
//<Route path="/login"                             element={<Login />  }/>
//<Route path="/home"                              element={<Home />   }/>
export default function RoutesApp() {

    return(
      <Provider store={store}>

        <BrowserRouter>
          <Routes>

                <Route path="/"                                  element={<Product />}/>
                <Route path="/login"                             element={<Login />  }/>
                <Route path="/home"                              element={<Product />}/>
                <Route path="/contact"                           element={<Contact />}/> 
                <Route path="/product"                           element={<Product />}/> 
                <Route path="/privacy"                           element={<Privacy />}/> 
                <Route path="/seila/privacy"                     element={<PrivacyTest/>}/>

                <Route path="/divorceguide"                      element={<DivorceGuide           />}/>
                <Route path="/divorceguide/home"                 element={<DivorceGuide           />}/>
                <Route path="/divorceguide/privacy"              element={<DivorceGuidePrivacy    />}/>
                <Route path="/divorceguide/termsofuse"           element={<DivorceGuideTermsOfUse />}/>
                <Route path="/divorceguide/toknowmore"           element={<DivorceGuideToKnowMore />}/>
                <Route path="/divorceguide/statistic"            element={<DivorceGuideStatistic  />}/>
                <Route path="/divorceguide/conditions"           element={<DivorceGuideConditions />}/>
                <Route path="/divorceguide/children"             element={<DivorceGuideChildren   />}/>
                <Route path="/divorceguide/element1"             element={<DivorceGuideElement1   />}/>
                <Route path="/divorceguide/element2"             element={<DivorceGuideElement2   />}/>
                <Route path="/divorceguide/element3"             element={<DivorceGuideElement3   />}/>
                <Route path="/divorceguide/element4"             element={<DivorceGuideElement4   />}/>
                <Route path="/divorceguide/element5"             element={<DivorceGuideElement5   />}/>
                <Route path="/divorceguide/element6"             element={<DivorceGuideElement6   />}/>
                <Route path="/divorceguide/element7"             element={<DivorceGuideElement7   />}/>
                <Route path="/divorceguide/element8"             element={<DivorceGuideElement8   />}/>

                <Route path="/divorceguide/guide/introduction"   element={<DivorceGuideIntroduction/>}/>
                <Route path="/divorceguide/guide/part1"          element={<DivorceGuidePart1/>       }/>
                <Route path="/divorceguide/guide/part2"          element={<DivorceGuidePart2/>       }/>
                <Route path="/divorceguide/guide/part3"          element={<DivorceGuidePart3/>       }/>
                <Route path="/divorceguide/guide/part4"          element={<DivorceGuidePart4/>       }/>
                <Route path="/divorceguide/guide/part5"          element={<DivorceGuidePart5/>       }/>
                <Route path="/divorceguide/guide/part6"          element={<DivorceGuidePart6/>       }/>
                <Route path="/divorceguide/guide/part7"          element={<DivorceGuidePart7/>       }/>
                <Route path="/divorceguide/guide/part8"          element={<DivorceGuidePart8/>       }/>
                <Route path="/divorceguide/guide/attach"         element={<DivorceGuideAttach/>      }/>
                <Route path="/divorceguide/guide/author"         element={<DivorceGuideAuthor/>      }/>
                <Route path="/divorceguide/book"                 element={<BookGuiadoDivorcio/>      }/>

                <Route path="/divorceguide/guidees/introduction" element={<DivorceGuideEsIntroduction/>}/>
                <Route path="/divorceguide/guidees/part1"        element={<DivorceGuideEsPart1/>       }/>
                <Route path="/divorceguide/guidees/part2"        element={<DivorceGuideEsPart2/>       }/>
                <Route path="/divorceguide/guidees/part3"        element={<DivorceGuideEsPart3/>       }/>
                <Route path="/divorceguide/guidees/part4"        element={<DivorceGuideEsPart4/>       }/>
                <Route path="/divorceguide/guidees/part5"        element={<DivorceGuideEsPart5/>       }/>
                <Route path="/divorceguide/guidees/part6"        element={<DivorceGuideEsPart6/>       }/>
                <Route path="/divorceguide/guidees/part7"        element={<DivorceGuideEsPart7/>       }/>
                <Route path="/divorceguide/guidees/part8"        element={<DivorceGuideEsPart8/>       }/>
                <Route path="/divorceguide/guidees/attach"       element={<DivorceGuideEsAttach/>      }/>
                <Route path="/divorceguide/guidees/author"       element={<DivorceGuideEsAuthor/>      }/>

                <Route path="/divorceguide/guideen/introduction" element={<DivorceGuideEnIntroduction/>}/>
                <Route path="/divorceguide/guideen/part1"        element={<DivorceGuideEnPart1/>       }/>
                <Route path="/divorceguide/guideen/part2"        element={<DivorceGuideEnPart2/>       }/>
                <Route path="/divorceguide/guideen/part3"        element={<DivorceGuideEnPart3/>       }/>
                <Route path="/divorceguide/guideen/part4"        element={<DivorceGuideEnPart4/>       }/>
                <Route path="/divorceguide/guideen/part5"        element={<DivorceGuideEnPart5/>       }/>
                <Route path="/divorceguide/guideen/part6"        element={<DivorceGuideEnPart6/>       }/>
                <Route path="/divorceguide/guideen/part7"        element={<DivorceGuideEnPart7/>       }/>
                <Route path="/divorceguide/guideen/part8"        element={<DivorceGuideEnPart8/>       }/>
                <Route path="/divorceguide/guideen/attach"       element={<DivorceGuideEnAttach/>      }/>
                <Route path="/divorceguide/guideen/author"       element={<DivorceGuideEnAuthor/>      }/>

                <Route path="/divorceplanning"                   element={<DivorcePlanning           />}/>
                <Route path="/divorceplanning/home"              element={<DivorcePlanning           />}/>
                <Route path="/divorceplanning/privacy"           element={<DivorcePlanningPrivacy    />}/>
                <Route path="/divorceplanning/termsofuse"        element={<DivorcePlanningTermsOfUse />}/>
                <Route path="/divorceplanning/toknowmore"        element={<DivorcePlanningToKnowMore />}/>

                <Route path="/personaldiary"                     element={<PersonalDiary           />}/>
                <Route path="/personaldiary/home"                element={<PersonalDiary           />}/>
                <Route path="/personaldiary/privacy"             element={<PersonalDiaryPrivacy    />}/>
                <Route path="/personaldiary/termsofuse"          element={<PersonalDiaryTermsOfUse />}/>
                <Route path="/personaldiary/toknowmore"          element={<PersonalDiaryToKnowMore />}/>

                <Route path="/lifeplan"                          element={<Lifeplan           />}/>
                <Route path="/lifeplan/home"                     element={<Lifeplan           />}/>
                <Route path="/lifeplan/privacy"                  element={<LifeplanPrivacy    />}/>
                <Route path="/lifeplan/termsofuse"               element={<LifeplanTermsOfUse />}/>
                <Route path="/lifeplan/toknowmore"               element={<LifeplanToKnowMore />}/>
                
                <Route path="/passwordwallet"                    element={<PasswordWallet           />}/>
                <Route path="/passwordwallet/home"               element={<PasswordWallet           />}/>
                <Route path="/passwordwallet/privacy"            element={<PasswordWalletPrivacy    />}/>
                <Route path="/passwordwallet/termsofuse"         element={<PasswordWalletTermsOfUse />}/>
                <Route path="/passwordwallet/toknowmore"         element={<PasswordWalletToKnowMore />}/>
          </Routes>
        </BrowserRouter>

      </Provider>
    )
}