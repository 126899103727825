import styled from 'styled-components';

export const Container = styled.div`
    background: none;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const ContainerForm = styled.div`
    background: none;
    height: 800px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: top;
    margin-top: 1%;

    @media screen and (max-width: 960px) {
        flex-direction: column;
        height: 950px;
    }
`

export const Form = styled.div`
    background:  #FFFFFF;
    height:100%;
    width: 100%;
    margin-right: 2px;

    @media screen and (max-width: 960px) {
        width: 100%;
        margin-left: 2px;
    }
`

export const FormDesc = styled.div`
    background:  #FFFFFF;
    height:50%;
    width: 100%;
    margin-left: 2px;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 960px) {
        width: 100%;
    }
`

export const Title = styled.div`
    background: none;
    border: none;
    color: #000000;
    text-align: center;
    text-decoration: none;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
    font-size: 3.0rem;

    @media screen and (max-width: 960px) {
        margin-top: 90px;
    }
`

export const ContactDescTitle = styled.div`
    background: none;
    text-align: center;
    text-decoration: none;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
    font-size: 1.5rem;
    color: #000000;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
`

export const ContactDesc = styled.div`
    background: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 20px;
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
    font-size: 1.3rem;
    }    
`

export const InputData = styled.div`
    background: none;
    width: 50%;
    display: flex;
    flex-direction: column;
    padding-left: 25%;

    span {
        color: #707070;
        margin-top: 50px;
        margin-bottom: 5px;
        font-size: 20px;
        font-family: 'Roboto', sans-serif;
        font-weight: bold;
        }

    input {
        font-size: 18px;
        font-family: 'Roboto', sans-serif;
        //padding-left: 40px;
        height: 30px;
        }

    textarea {
        font-size: 18px;
        font-family: 'Roboto', sans-serif;
        }

    @media screen and (max-width: 960px) {
        width: 90%;
        padding-left: 5%;
        padding-right: 5%;
    }
`

export const InputWithIcon = styled.input`
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    padding-left: 40px;
    height: 30px;
    position: relative;

    i {
        position: absolute;
        left: 0;
        top: 8px;
        padding: 9px 8px;
    } 
`

export const ContainerButton = styled.div`
    background: none;
    width: 50%;
    margin-top: 60px;
    margin-left: 25%;
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 960px) {
        width: 90%;
        margin-left: 5%;
        margin-right: 5%;
    }
`

export const FormButton = styled.div`
    width: 50%;
    margin-left: 2%;
    margin-right: 2%;

    button {
        width: 100%;
        background:   #70AD47;
        background:   ${ ( {green} ) => ( green ? '#70AD47' : '#000000' ) }; 
        border-width: 0.5px;
        border-color: #385723;
        border-color: ${ ( {green} ) => ( green ? '#70AD47' : '#000000' ) }; 
        font-weight: bold;
        font-size: 20px;
        color: #FFF;
        padding: 20px;
        border-radius: 20px;
        cursor: pointer;
        border-top-style: outset;

        &:hover {
            opacity: 0.7;
        }
    }
`

export const FormTitle = styled.div`
    display: flex;
    flex-direction: row;
    padding-left: 25%;

    span {
        font-size: 20px;
        font-family: 'Roboto', sans-serif;
        font-weight: bold;
        color:  #6596B2;
        }

    }
`